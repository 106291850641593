/**
 * Note that this file is used both in the browser and on the backend, so take care to only import
 * files/utils that are isomorphic
 */
import formatTimestamp from './formatTimestamp';
import normalizePlaceName from './normalizePlaceName';
import { toDurationString } from './toDurationString';
import logger from './IsomorphicLogger';

/**
 * Intelligently converts an array of TripLegs to an array of Places. Useful for generating directions
 * or creating markers. Used both on the backend (for quoting/estimating drive time) and on the frontend (for creating markers)
 *
 * @param {Array<TripLeg>} legs Array of TripLeg objects or their json versions
 * @returns {Array<Place>} Array of Place-esque places, something like {lat, lng, ...} or { isLayover, ...} (other props included)
 */
export function legsToPlaces(legs, { debug = false } = {}) {
	const legPlaces = [];
	let previousLeg;
	legs.forEach((leg, index) => {
		const {
			id: legId,
			legIndex,
			startPlace: startPlaceRaw,
			stopPlace: stopPlaceRaw,
			isLayover,
			layoverMinutes,
			userEstimatedLayoverMinutes,
			userEstimatedLayoverVariable,
		} = leg;

		const startPlace = startPlaceRaw || {};
		const stopPlace = stopPlaceRaw || {};
		const { id: startPlaceId } = startPlace;
		const { id: stopPlaceId } = stopPlace;

		const decoProps = { legId, legIndex, leg, placeType: undefined };
		const startItem = {
			...startPlace,
			...decoProps,
			placeType: 'start',
			id: `start_place_${startPlaceId}_leg_${legId}`,
			placeId: startPlaceId,
			leg,
		};

		const stopItem = {
			...stopPlace,
			...decoProps,
			placeType: 'stop',
			id: `stop_place_${stopPlaceId}_leg_${legId}`,
			placeId: stopPlaceId,
			leg,
		};

		const nextLeg = index + 1 < legs.length ? legs[index + 1] : undefined;
		// const { startPlace: nextStartPlace, stopPlace: nextStopPlace } =
		// 	nextLeg || {};

		// const { id: nextStartPlaceId } = nextStartPlace || {};
		// const { id: nextStopPlaceId } = nextStopPlace || {};

		if (isLayover) {
			legPlaces.push({
				...startPlace,
				...decoProps,
				isLayover,
				layoverMinutes,
				isEstimate: userEstimatedLayoverMinutes && userEstimatedLayoverVariable,
				placeType: 'start',
				leg,
			});

			// if (startPlaceId !== stopPlaceId) {
			// 	legPlaces.push({
			// 		...decoProps,
			// 		isLayover,
			// 		layoverMinutes,
			// 		isEstimate:
			// 			userEstimatedLayoverMinutes && userEstimatedLayoverVariable,
			// 		// Just included for parity with start/stop PlacesList
			// 		placeType: 'stop',
			// 		leg,
			// 	});
			// }

			if (debug) {
				logger.debug(
					`* legsToPlaces: ${legId} # ${legIndex} [LAYOVER] ${
						startItem.name
					} ${layoverMinutes} minutes ${
						startPlaceId !== stopPlaceId && ` > ${stopPlace.name}`
					}`,
				);
			}
		} else {
			let {
				isLayover: previousLegIsLayover,
				startPlace: previousStartPlace,
				// stopPlace: previousStopPlace,
			} = previousLeg || {};
			const { id: previousStartPlaceId } = previousStartPlace || {};
			// const { id: previousStopPlaceId } = previousStopPlace || {};

			if (!previousLegIsLayover || previousStartPlaceId !== startPlaceId) {
				if (debug) {
					logger.debug(
						`* legsToPlaces: ${legId} # ${legIndex} [PLACE] ${startItem.name}`,
					);
				}

				legPlaces.push(startItem);
			}
		}

		if (!nextLeg) {
			legPlaces.push(stopItem);

			if (debug) {
				logger.debug(
					`* legsToPlaces: ${legId} # ${legIndex} [STOP] ${stopItem.name} // ${stopItem.id}`,
				);
			}
		}

		previousLeg = leg;
	});

	return legPlaces;
}

/**
 * Takes an array of TripLeg objects and creates a string like:
 *
 *   >  `Monday · 6:00 AM 📍 360 Nueces St ➡ 24 Diner. 🕒 ~60 mins later ➡ Pershing 🛑 Monday · 7:17 AM`
 *
 * @param {Array<TripLeg>} legs Array of TripLeg-like objects to stringify intelligently
 * @param {boolean} opts.placesOnly [default: false] If true, doesn't include emoji markers at start/end of string and does not include times
 * @returns {string} String like "Monday · 6:00 AM 📍 360 Nueces St ➡ 24 Diner. 🕒 ~60 mins later ➡ Pershing 🛑 Monday · 7:17 AM"
 */
export function stringifyLegs(legs, { placesOnly = false } = {}) {
	if (!legs || !legs.length) {
		return '';
	}

	const { startTime } = legs[0];
	const { stopTime } = legs[legs.length - 1];
	const places = legsToPlaces(legs);

	const startDecorations = placesOnly
		? ''
		: `${formatTimestamp(startTime)} 📍 `;
	const stopDecorations = placesOnly
		? ''
		: ` 🛑 ${(stopTime && formatTimestamp(stopTime)) || ''}`;

	return `${startDecorations}${places
		.map(
			({ isLayover, layoverMinutes, isEstimate, name, addressText }, index) =>
				(isLayover
					? // Could show '+/- {userEstimatedLayoverVariable}' as well to indicate exact fuzziness
					  // but this is good enough to get the gist of the data
					  `${normalizePlaceName(name, addressText)} 🕒 ${
							isEstimate ? '~' : ''
					  }${toDurationString(layoverMinutes)} later`
					: normalizePlaceName(name, addressText)) +
				(index === places.length - 1 /* || places[index + 1].isLayover */
					? // Only show the unicode arrow '➡' if the next place is
					  // NOT the end and NOT a layover
					  ''
					: ' ➡ '),
		)
		.join('')}${stopDecorations}`;
}

export const legChecklist = (legs = [], { showTimes = false } = {}) => {
	return legs.map(
		({
			id,
			legIndex,
			startPlace: { name: name1, id: id1 } = {},
			stopPlace: { name: name2, id: id2 } = {},
			isLayover,
			startTime,
			stopTime,
			estimatedMinutes,
			actualMinutes,
			estimatedMiles,
			actualMiles,
		}) =>
			`${id} # ${legIndex}: (${
				isLayover ? 'LAYOVER' : '*-->--*'
			}) ${name1} > ${name2} (id1 ${id1} // ${id2})${
				showTimes
					? `\n   - ${startTime} - ${stopTime} // ${estimatedMinutes} min vs ${actualMinutes} minutes // ${estimatedMiles} vs ${actualMiles} miles`
					: ''
			}`,
	);
};
