/* eslint-disable no-console */
import MessageTypes from '../utils/MessageTypes';
import DeviceInfo from '../utils/DeviceInfo';
// import { defer } from '../utils/defer';
import FunctionalState from '../components/FunctionalState';
import { stableDefer } from '../utils/defer';
import { server } from '../utils/ServerUtil';
// eslint-disable-next-line import/no-cycle
import { getTimezone } from '../utils/getTimezone';
import { getUtcOffset } from '../utils/getUtcOffset';

// For storing auth token for relogin
const AUTH_TOKEN_STORAGE_KEY = '@rubber/auth-token';

export default class SocketAuthService {
	constructor(hub) {
		this.hub = hub;
		this.authState = new FunctionalState();

		// Conveniently, addMessageHook returns a sendMessage handler to also send messages
		this.sendMessage = hub.addMessageHook(this, ({ type, ...data }) => {
			// console.warn(`Auth intercept type:`, type);
			switch (type) {
				case MessageTypes.AuthorizedMessage:
					this.authorizeSocketCompleted(data);
					break;
				case MessageTypes.UnauthorizedMessage:
					this.authorizeSocketFailed(data);
					break;
				case MessageTypes.DeauthorizationMessage:
					this.deauthorizationResult(data);
					break;
				default:
					return false;
			}
			// Unrecognized returns false (default above) so if we got here,
			// we recognized the message, so tell the hub service to stop checking for handlers
			return true;
		});
	}

	stop() {
		server.setToken(null);
		this.authorization = null;
	}

	hasStoredToken() {
		return !!this.getStoredAuthToken();
	}

	// eslint-disable-next-line class-methods-use-this
	getStoredAuthToken() {
		return window.localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
	}

	// eslint-disable-next-line class-methods-use-this
	storeAuthToken(token) {
		server.setToken(token);

		if (token) {
			window.localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, token);
		} else {
			console.warn(`Deauth, removing token...`);
			window.localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
		}
	}

	async logout() {
		this.logoutPromise = stableDefer();
		this.sendMessage({
			type: MessageTypes.DeauthorizationMessage,
		});

		this.authState.setState(null);
		this.storeAuthToken(false);
		this.authorization = null;

		return this.logoutPromise;
	}

	deauthorizationResult({ status, ...result }) {
		if (this.logoutPromise) {
			this.logoutPromise.resolve();
			this.logoutPromise = null;
		}

		if (!status.success) {
			console.error(`Error deauthorizing socket:`, {
				status,
				...result,
			});
		}
	}

	async authorizeSocket({ type, token, ...props } = {}) {
		const { hub, reconnectAttempted } = this;
		// TODO: Handle various auth types like social, user/pass, etc

		const authPromise = stableDefer({
			timeoutErrorMessage: 'Socket authPromise timeout',
			onTimeout: (message) => {
				if (!reconnectAttempted) {
					console.warn(
						`Timeout when attempting to authorize the socket, disconnecting socket and will reconnect shortly...`,
					);
					this.reconnectAttempted = true;
					hub.disconnect();
					setTimeout(() => {
						console.warn(`Going to attempt reconnect after disconnect...`);
						hub.connect();
					}, 1000);
				} else {
					console.error(
						`${message} (Already attempted to reconnect  - should reload the page)`,
					);
					// window.location.reload();
				}
			},
		});

		authPromise.catch((ex) => {
			console.warn(`precatch:`, ex);
		});

		const deviceInfo = await DeviceInfo.getDeviceInfo();

		const storedToken = token || this.getStoredAuthToken();

		// if (storedToken) {
		// 	console.log('Have auth token, refreshing token...');
		// 	this.sendMessage({
		// 		type: MessageTypes.JWTAuthorizationMessage,
		// 		token: storedToken,
		// 		deviceInfo,
		// 	});
		// } else {
		// 	console.log('No stored auth token, requested anonymous device login...');
		this.sendMessage({
			...props,
			type: type || MessageTypes.AnonymousAuthorizationMessage,
			token: type === MessageTypes.JWTAuthorizationMessage && storedToken,
			deviceInfo,
			timezone: getTimezone(),
			utcOffset: getUtcOffset(),
		});
		// }

		this.authPromise = authPromise;
		return authPromise;
	}

	async authorizeSocketCompleted(data) {
		this.authorization = data;

		const { token } = data;

		// const { user, device } = data;
		// eslint-disable-next-line no-console
		// console.log(
		// 	`Authorized socket as user ${user.id}, device ${device.id}, storing token for later use...`,
		// );

		this.storeAuthToken(token);

		// console.log(
		// 	`Authorized socket data:`, // as user ${user.id}, device ${device.id}, data:`,
		// 	data,
		// );

		if (this.authPromise) {
			this.authPromise.resolve(data);
			this.authPromise = null;
		}

		this.authorizationChanged(data);
	}

	async authorizeSocketFailed({ errorCode, message }) {
		// if (!this.authPromise || this.getStoredAuthToken()) {
		// 	this.storeAuthToken(null);
		// 	// Silently retry with an anonymous login (set token to null)
		// 	this.authorizeSocket();
		// 	return;
		// }

		this.authorizationChanged(null);

		// Authorization already in progress, so don't make a fuss
		if (errorCode === 'auth-required' && this.authPromise) {
			return;
		}

		if (this.authPromise) {
			const error = new Error(`Socket auth failed: ${message}`);
			error.errorCode = errorCode;
			// Fail if no token and still error
			this.authPromise.resolve(error);
			this.authPromise = null;
		} else {
			console.error(`Auth failed, no auth promise, error was:`, {
				errorCode,
				message,
			});
		}
	}

	authorizationChanged(authorization) {
		this.authorization = authorization;
		if (authorization && !this.authorization.account) {
			// HACK HACK HACK
			this.authorization.account = {
				status: undefined,
			};
		}
		// console.log(`Auth changed:`, authorization);
		this.authState.setState(authorization);
	}

	useAuthorization() {
		return this.authState.useState();
	}

	getAuthorization() {
		return this.authState.getValue();
	}

	getAuthState() {
		return this.authState;
	}
}
