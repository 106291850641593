/* eslint-disable no-console */
/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom'; // or 'react-native'

import createStore from 'zustand';

export function useFunctionalState(possibleNullState) {
	const { store } = useMemo(
		() => possibleNullState || { store: () => {} } || {},
		[possibleNullState],
	);
	return store((z) => z && z.internalValue);
}

export default class FunctionalState {
	constructor(value = undefined, changeCallback) {
		this.createStack = new Error('createStack');

		this.value = value;
		this.store = createStore(() => ({ internalValue: value }));

		this.callbacks = [];
		this.changeCallback = changeCallback;
	}

	destroy() {
		this.callbacks = [];
	}

	on(event, cb) {
		if (this.callbacks.includes(cb)) {
			return cb;
		}

		this.callbacks.push(cb);
		// console.warn(
		// 	`++ FunctionalState.on called`,
		// 	event,
		// 	cb,
		// 	this.createStack,
		// 	this.value,
		// );

		return cb;
	}

	off(event, cb) {
		this.callbacks = this.callbacks.filter((x) => x !== cb);

		return cb;
	}

	useState() {
		const { store } = this;
		return store((z) => z && z.internalValue);
	}

	setState(value, { force } = {}) {
		const { value: currentValue, store, changeCallback, callbacks } = this;
		// Since react won't rerender if the exact same,
		// don't bother hitting callbacks if the same
		if (value === currentValue && !force) {
			return;
		}

		// console.log(`set state:`, value);

		unstable_batchedUpdates(() => {
			store.setState({ internalValue: value });
		});
		this.value = value;

		if (changeCallback) {
			changeCallback(value);
		}

		callbacks.forEach((cb) => cb({ value, state: this }));
	}

	setValue(value) {
		this.setState(value);
	}

	getValue() {
		return this.value;
	}

	getState() {
		return this.value;
	}
}
