/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-console */

import {
	ActionPerformed,
	PushNotificationSchema,
	PushNotifications,
	PermissionStatus,
	Token,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

// Import ServerStore here so we can send the token to the server
import { MAP_BRAND_COLOR_RAW_HEX } from '../../utils/mapIconGenerators';
import { ServerStore } from '../ServerStore';
import { mobileDetect } from '../../utils/mobile-detect';
import { iOS } from '../../utils/iOS';

export const CHANNEL_LIST = {
	// see https://github.com/phonegap/phonegap-plugin-push/blob/master/docs/API.md#channel-properties
	// for list of properties valid here

	// trips and trip_offers
	trips: {
		name: 'Rides',
		description: 'Notifications about rides',
		importance: 5, // 5 = highest
		visibility: 1,
		lights: true,
		lightColor: `#${MAP_BRAND_COLOR_RAW_HEX}`,
		vibration: true,
		sound: 'default',
	},

	trips_offers: {
		name: 'Ride Offers',
		description: 'Notifications about ride offers to Drivers',
		importance: 5, // 5 = highest
		visibility: 1,
		lights: true,
		lightColor: `#${MAP_BRAND_COLOR_RAW_HEX}`,
		vibration: true,
		sound: 'firealarm.wav',
	},

	valet: {
		name: 'Valet Tickets',
		description: 'Notifications about your valet ticket and parking sessions',
		importance: 5, // 5 = highest
		visibility: 1,
		lights: true,
		lightColor: `#${MAP_BRAND_COLOR_RAW_HEX}`,
		vibration: true,
		sound: 'default',
	},

	valet_stand_alerts: {
		name: 'Valet Stand Alerts',
		description:
			'Notifications for Valet Stands, including Ticket Requests, parking reminders, and more',
		importance: 5, // 5 = highest
		visibility: 1,
		lights: true,
		lightColor: `#${MAP_BRAND_COLOR_RAW_HEX}`,
		vibration: true,
		sound: 'valetalarm.wav',
	},

	garage: {
		name: 'Parking Garage',
		description: 'Notifications about your parking garage sessions',
		importance: 5, // 5 = highest
		visibility: 1,
		lights: true,
		lightColor: `#${MAP_BRAND_COLOR_RAW_HEX}`,
		vibration: true,
		sound: 'default',
	},

	// tips: {
	// 	name: 'Tips',
	// 	description: 'Tips from Vaya',
	// 	importance: 3,
	// 	visibility: 1,
	// 	lights: true,
	// 	lightColor: '#D48BC8',
	// },

	// devel: {
	// 	name: 'Developers',
	// 	description: 'Notifications from Developers',
	// 	importance: 5,
	// 	visibility: 1,
	// },
};

// Apply id to hash for completeness and compliance with requirements
Object.keys(CHANNEL_LIST).forEach((id) => {
	CHANNEL_LIST[id].id = id;
});

export async function initNativePushPlugin({
	onMessage,
	onMessageClick,
	onPermissionNeeded,
}) {
	// console.log(`Debug, don't do this`);
	// return;
	// // On iOS, when we call PushNotification.init() the first time,
	// // it prompts the user for permission. So, on iOS we want to re-use the
	// // onPermissionNeeded deffer flow we built for FirebasePushPlugin
	// // to use our own custom UI to inform the user before the OS
	// // prompts the user out of the blue.
	// if (iOS) {
	// 	// Check to see if we already have permission to push push notify
	// 	// (only affects iOS - android has it by default)
	// 	const hasPermission =
	// 		(await PushNotifications.checkPermissions()) === PermissionStatus.receive;

	// 	// If we DON'T have permission yet, reuse the onPermissionNeeded flow
	// 	// we built for FirebasePushPlugin to allow the main app to
	// 	// defer permission requests until user granted.
	// 	// (The .init method below is what triggers the permission request,
	// 	// so we don't trigger .init on iOS until onPermissionNeeded resolves)
	// 	if (!hasPermission && onPermissionNeeded) {
	// 		console.warn(
	// 			`NativePushPlugin: don't have permissions already, but have onPermissionNeeded callback, waiting for that before registering...`,
	// 		);
	// 		await onPermissionNeeded();
	// 	}
	// }

	// Request permission to use push notifications
	// iOS will prompt user and return if they granted permission or not
	// Android will just grant without prompting
	const registerResult = await PushNotifications.requestPermissions(); // .then((result) => {
	if (registerResult.receive === 'granted') {
		// Register with Apple / Google to receive push via APNS/FCM
		PushNotifications.register();
	} else {
		// Show some error
		console.error(`Error requesting permission`, registerResult);
		return;
	}
	// });

	PushNotifications.addListener('registration', (token) => {
		// window.alert(`Push registration success, token: ${token.value}`);
		console.log(`[[NativePushPlugin] received push token:`, token.value);
		ServerStore.StorePushToken(token.value);
	});

	PushNotifications.addListener('registrationError', (error) => {
		console.error(`Error on registration:`, error);
	});

	PushNotifications.addListener('pushNotificationReceived', (notification) => {
		// PushNotificationSchema
		console.warn(`[NativePushPlugin] received Native push notification`, {
			...notification,
		});
		if (onMessage) {
			onMessage(notification);
		}
		// window.alert(`Push received: ${JSON.stringify(notification)}`);
	});

	PushNotifications.addListener(
		'pushNotificationActionPerformed',
		({ actionId, notification = {} } = {}) => {
			// ActionPerformed
			// window.alert(`Push action performed: ${JSON.stringify(notification)}`);
			console.log(`[[NativePushPlugin] actionPerformed:`, {
				actionId,
				notification,
			});
			if (onMessageClick) {
				onMessageClick(notification, actionId);
			}
		},
	);

	if (Capacitor.getPlatform() === 'android') {
		try {
			// Sync list of channels to the device
			// based on https://github.com/phonegap/phonegap-plugin-push/blob/master/docs/API.md#example-4
			const currentChannels = await PushNotifications.listChannels();
			// Check and remove any channels that we don't have defined in CHANNEL_LIST
			Array.from(currentChannels).forEach((channel) => {
				// eslint-disable-line no-unused-vars
				// PushPluginChannel autocreated by plugin
				// In the future, we MAY try to see if we can delete this channel, because it does look a bit unprofessional
				// to have that wierd channel name in the app notifications menu.
				// Further testing and development needed to see if we need this channel, because
				// this channel (PushPluginChannel) is used if an alert is sent with an empty channel, so it's basically
				// a wildcard channel. That wildcard behaviour is handled native plugin - we could fork the plugin to change,
				// but we're (I'm) lazy right now.
				if (channel.id !== 'PushPluginChannel') {
					console.log(
						`[channel sync] Found existing channel: ID: ${channel.id} Description: ${channel.description}`,
					);

					const def = CHANNEL_LIST[channel.id];
					if (def) {
						// we're good
						def._exists = true;
					} else {
						console.log(
							`[channel sync] Deleting old channel: ${def.id} Description: ${def.description}`,
						);
						// Not defined in our list, might be old name, remove from device, silently fail/succeed
						// PushNotification.deleteChannel(()=>{},()=>{}, channel.id);
						// Might cause bugs with native code so dont do this for now...
						// Per https://capacitorjs.com/docs/updating/4-0
						// deleteChannel only accepts channel ID
						PushNotifications.deleteChannel(channel.id);
					}
				}
			});

			// Create any channels not already defined on the device from our CHANNEL_LIST
			Object.values(CHANNEL_LIST).forEach((def) => {
				if (!def._exists) {
					console.log(
						`[channel sync] Creating channel: ${def.id} Description: ${def.description}`,
					);
					// eslint-disable-next-line no-loop-func
					PushNotifications.createChannel(def);
				}
			});
		} catch (ex) {
			console.warn(`Error setting up channels:`, ex);
		}
	}
}
