import EventEmitter from 'events';

export default class StaticEvents {
	static on(classRef, event, callback) {
		this.events.on(event, callback);

		if (!this.eventConnections[classRef.name]) {
			this.eventConnections[classRef.name] = [];
		}

		this.eventConnections[classRef.name].push({ event, callback });
	}

	static removeAllListeners(classRef) {
		(this.eventConnections[classRef.name] || []).forEach(
			({ event, callback }) => {
				this.events.off(event, callback);
			},
		);
	}

	static emit(event, ...args) {
		this.events.emit(event, ...args);
		this.events.emit('*', event, ...args);
	}

	static once(event, callback) {
		this.events.once(event, callback);
	}
}
Object.assign(StaticEvents, {
	events: new EventEmitter(),
	eventConnections: {},
});
