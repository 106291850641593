import { useParams, useNavigate } from 'react-router-dom';

import SupportChatButton from 'shared/components/SupportChat/SupportChatButton';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RoomIcon from '@material-ui/icons/Room';
import HomeIcon from '@material-ui/icons/Home';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import AssistantIcon from '@material-ui/icons/Assistant';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAuthRequired } from 'shared/services/BackendService';
import clsx from 'clsx';
import { useAuthorization } from 'shared/services/AuthService';
import { ServerStore } from 'shared/services/ServerStore';
import useSWR from 'swr';

import { useConditionallyHandleErrors } from 'shared/hooks/useConditionallyHandleErrors';

// import DrawerMenuButton from './DrawerMenuButton';
import SimpleSpinner from 'shared/components/SimpleSpinner';

import formatTimestamp from 'shared/utils/formatTimestamp';
import { useGoogleJsApi } from 'shared/hooks/useGoogleLoaded';
import { calculateTripPriceSummary } from 'shared/utils/tripDisplayUtils';
import { useTripPath } from 'shared/hooks/useTripPath';
import { useTripPlaces } from 'shared/hooks/useTripPlaces';
import { secondsToDurationTime } from 'shared/utils/secondsToDurationTime';
import ComposerMapWidget from 'shared/components/ComposerMapWidget';
import { DataListItem } from 'shared/components/DataListItem';
import TripStatus, {
	isCompletedStatus,
	isPostedStatus,
	isStartedStatus,
	isRidingStatus,
} from 'shared/utils/TripStatus';
import normalizePlaceName from 'shared/utils/normalizePlaceName';
import minutesToPayHours from 'shared/utils/minutesToPayHours';
import { ConversationPanelProvider } from 'shared/components/ConversationPanel/ConversationPanel';
import MemberTripProvider, {
	useMemberTripContext,
} from 'shared/services/TripComposerContext';
import { formatDollars } from 'shared/utils/formatDollars';
import SimpleBadge from 'shared/components/SimpleBadge';
import { useTripInfoCollator } from 'shared/hooks/useTripInfoCollator';
import TripStatusBadge from 'shared/components/TripStatusBadge';
import { ListItemLink } from './ListItemLink';
import DrawerNav, { useDrawerNavContext } from './DrawerNav';
import styles from './TripDetailsPage.module.scss';
import PartnerLogoToolbarItem from './PartnerLogoToolbarItem';

// simple polyfill
const pt = (key, text) => text;

function TripDetailsPageContent() {
	useAuthRequired();

	const { tripId } = useParams() || {};

	const navigate = useNavigate();

	const catchErrors = useConditionallyHandleErrors();

	const auth = useAuthorization();
	const {
		account,
		user: { id: currentUserId, isAdmin, isSupport } = {},
	} = auth || {};

	const {
		// id: accountId,
		isPartnerAccount,
		partnerLogoUrl,
	} = account || {};

	// Get JS API to load - normally done in MapWidget, but if this page is the 'cold load' page,
	// directions won't work without this
	useGoogleJsApi();

	// const { data: trip, error } = useSWR(['trip', tripId], () =>
	// 	catchErrors(ServerStore.GetTrip(tripId)),
	// );

	const trip = useMemberTripContext((state) => state.currentTrip);
	const currentTripLoading = useMemberTripContext(
		(state) => state.currentTripLoading,
	);

	const revisedArrivalTime = useMemberTripContext(
		(state) => state.revisedArrivalTime,
	);

	const timeRemainingMinutes = useMemberTripContext(
		(state) => state.timeRemainingMinutes,
	);

	const {
		status: tripStatus,
		id: tripLoaded,
		dropoffTbd,
		dropoffPlaceName,
		pickupPlaceName,

		pickupPlace,
		dropoffPlace,
		pickupPlaceAddressText,
		dropoffPlaceAddressText,

		driver,
		user: {
			id: userId,
			name: userName,
			photoUrl: userPhotoUrl,
			packageName,
			stripeCustomerId,
			tripFeedbackDisabled,
		} = {},

		requestedAt,
		startedAt,
		// pickedupAt,
		// dropoffAt,

		isGuestTrip,

		tipAmount: actualTipAmount = 0.0, // must be a number, can't be undefined

		longPickupDriverPay = 0,
		longDropoffDriverPay = 0,

		driverPayValue,
		driverMileageValue,
		driverOnCallValue,
		driverOnCallMinutes,
		driverWaitingMinutes,

		totalMiles,

		tripLegsSummary,

		userDriverRating,
		userDriverComments,
		driverUserRating,
		driverUserComments,

		specialPricingModifier,
		pricingModifierMessage,

		ridingMinutes,
		totalMemberMinutes,
		memberPayMinutes,
		subscriptionMinutes,

		isFlatRateTrip,
		isFlatRateManuallyAssigned,
		flatRateAssignedBy,
		flatRateAssignedAt,

		flatRateTierLabel,
		flatRateMinuteTier,
		flatRateMileTier,
		flatRateDriverPay,
		flatRateSupportPay,
		flatRateMemberCharge,

		hasMemberCostOverride,
		memberCostOverride,
		memberCostOverrideReason,
		memberCostOverrideBy,
		memberCostOverrideAt,
		memberCostPreOverride,
		memberCostValue,
		createdBy: { name: createdByName } = {},

		status,

		isScheduled,
		scheduledPickupAt,

		estimatedPickupAt,
		pickedupAt,

		estimatedDropoffAt,
		dropoffAt,
	} = trip || {};

	const {
		id: driverId,
		name: driverName,
		photoUrl: driverPhotoUrl,
	} = driver || {};

	// console.log(`details page loading`, currentTripLoading, { tripLoaded, tripStatus });

	const {
		isUpcoming,
		isStarted,
		isRiding,
		isCompleted,
		isCancelation,
		isNoShow,

		pickupTimeDisplay,
		pickupTimeDisplayIsEstimated,
		dropoffTimeDisplay,
		dropoffTimeDisplayIsEstimated,
	} = useTripInfoCollator(trip);

	// console.log(`details page trip debug`, {
	// 	isScheduled,
	// 	bigStatusFlags: {
	// 		isUpcoming,
	// 		isStarted,
	// 		isRiding,
	// 		isCompleted,
	// 		isCancelation,
	// 		isNoShow,
	// 	},
	// 	status,
	// 	scheduledPickupAt,
	// 	estimatedPickupAt,
	// 	estimatedDropoffAt,
	// 	pickedupAt,
	// 	dropoffAt,
	// 	revisedArrivalTime,
	// 	timeRemainingMinutes,
	// 	pickupTimeDisplay,
	// 	pickupTimeDisplayIsEstimated,
	// 	dropoffTimeDisplay,
	// 	dropoffTimeDisplayIsEstimated,
	// 	driverId,
	// 	driverName,
	// });

	const [, waypoints] = useTripPlaces(trip);

	const { resalePrice, actualResalePreSubscription } = useMemo(
		() => calculateTripPriceSummary(trip),
		[trip],
	);

	const tripDate = pickupTimeDisplay;

	const tripDateInfo = useMemo(
		() =>
			tripDate && !Number.isNaN(new Date(tripDate).getTime())
				? formatTimestamp(new Date(tripDate), {
						returnObject: true,
				  })
				: undefined,
		[tripDate],
	);

	return (
		<div className={styles.root}>
			{currentTripLoading && <SimpleSpinner fullPage={true} />}
			<AppBar
				position="static"
				color="default"
				className={styles.appBar}
			>
				<Tooltip title="Go back">
					<IconButton
						className={clsx(styles.iconButton)}
						aria-label="menu"
						onClick={() => navigate('/trips')}
					>
						<ChevronLeftIcon />
					</IconButton>
				</Tooltip>

				<PartnerLogoToolbarItem url={partnerLogoUrl} />

				<IconButton className={clsx(styles.hiddenButton)}>
					{/* This button exists only to center the logo item more
					evenly, can be removed if a different button actually needs
					to go here later */}
					<MenuIcon />
				</IconButton>
			</AppBar>

			<div className={styles.map}>
				<ComposerMapWidget
					// Try out the WebGL map here in the partner app
					googleMapId={'8674a09e8af6eb1'}
					zoomControlClassName={styles.zoomControl}
					staticPadding={{ top: 0, bottom: 0 }}
					hideSingleMarker={true}
					className={styles.mapContainer}
					disableCustomMapStyle={true}
				/>
			</div>

			<div className={styles.tripStops}>
				{!tripLoaded ? (
					<h4>
						<center>
							<i>Loading...</i>
						</center>
					</h4>
				) : (
					<ul>
						<DataListItem
							isDateHeader={true}
							primaryText={
								tripDateInfo
									? `${tripDateInfo.day}, ${tripDateInfo.date}`
									: pt('no-date', 'No Date Provided')
							}
							secondaryText={pt('trip-date', 'Trip Date')}
							primaryLabel={''}
						/>

						<li className={styles.statusLine}>
							<TripStatusBadge status={status} />
						</li>

						<DataListItem
							isPickup
							primaryText={normalizePlaceName(
								pickupPlaceName,
								pickupPlaceAddressText,
							)}
							secondaryText={`${pickupPlaceAddressText}`.replace(
								', USA',
								'',
							)}
							primaryLabel={
								pickupTimeDisplayIsEstimated
									? 'Pickup Estimate'
									: 'Picked up at'
							}
							secondaryLabel={new Date(
								pickupTimeDisplay,
							).toLocaleTimeString()}
						/>

						{(waypoints || [])
							.filter((x) => x.id)
							.map(
								(
									{
										id,
										leg,
										leg: {
											isStarted,
											isDone,
											isLayover,
											layoverMinutes,
											userEstimatedLayoverMinutes,
											userEstimatedLayoverVariable,
											startTime,
											stopTime,
										} = {},
										placeType,
										name,
										addressText,
										markerColor,
									},
									index,
								) => (
									<DataListItem
										key={id}
										isWaypoint
										markerColor={markerColor}
										primaryText={normalizePlaceName(
											name,
											addressText,
										)}
										secondaryText={`${addressText}`.replace(
											', USA',
											'',
										)}
										primaryLabel={
											isLayover
												? pt(
														'layover-time',
														'Stay here for',
												  )
												: pt(
														'arrival-time',
														'Arrive by',
												  )
										}
										secondaryLabel={
											isLayover
												? `${secondsToDurationTime(
														layoverMinutes *
															60,
														{
															onlyHoursMinutes: true,
															disableZeroPadHours: true,
														},
												  )} hrs`
												: new Date(
														startTime,
												  ).toLocaleString()
										}
									/>
								),
							)}

						<DataListItem
							isDropoff
							primaryText={normalizePlaceName(
								dropoffPlaceName,
								dropoffPlaceAddressText,
							)}
							secondaryText={`${dropoffPlaceAddressText}`.replace(
								', USA',
								'',
							)}
							primaryLabel={
								dropoffTimeDisplayIsEstimated
									? 'Drop off Estimate'
									: 'Drop off'
							}
							secondaryLabel={new Date(
								dropoffTimeDisplayIsEstimated &&
								!Number.isNaN(
									new Date(revisedArrivalTime).getTime(),
								)
									? revisedArrivalTime
									: dropoffTimeDisplay,
							).toLocaleTimeString()}
						/>

						<DataListItem
							className={styles.total}
							primaryText={userName}
							secondaryText={
								isGuestTrip
									? pt('guest', 'Guest')
									: pt('member', 'Member')
							}
							primaryLabel={
								isCompleted
									? 'Member Price'
									: 'Estimated Price'
							}
							secondaryLabel={
								isCompleted &&
								!actualResalePreSubscription ? (
									<i>Processing</i>
								) : (
									`$${formatDollars(
										isCompleted
											? actualResalePreSubscription
											: resalePrice,
									)}`
								)
							}
						/>

						<DataListItem
							className={clsx(styles.drive, styles.total)}
							primaryText={driverName}
							secondaryText={pt('driver', 'Driver')}
							// primaryLabel={'Earned'}
							secondaryLabel={''}
						/>
					</ul>
				)}
			</div>
		</div>
	);
}

export default function TripDetailsPage() {
	const isAuthorized = useAuthRequired();
	const { tripId } = useParams() || {};

	if (!isAuthorized) {
		// eslint-disable-next-line no-console
		console.warn(
			`TripDetailsPage page NOT authorized, not rendering, waiting for redirect from BackendService hook`,
		);
		return <SimpleSpinner fullPage={true} />;
	}

	return (
		<ConversationPanelProvider>
			<MemberTripProvider
				requireExplicitUser={true}
				onlyNonActive={true}
				tripIdOverride={tripId}
			>
				<TripDetailsPageContent />

				<DrawerNav alwaysHideDrawer={true} />
				{/* <DrawerMenuButton alwaysVisible={true} /> */}
				<SupportChatButton lightMode={true} />
			</MemberTripProvider>
		</ConversationPanelProvider>
	);
}
