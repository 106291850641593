/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, {
	useCallback,
	useMemo,
	useRef,
	useState,
	useEffect,
} from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';
import history, { HistoryHack } from 'shared/utils/history';

import PersonIcon from '@material-ui/icons/Person';

import useSWR from 'swr';

import { MessageTypes } from 'shared/utils/MessageTypes';
import BackendService, {
	useMessageHook,
	usePoolSubscription,
} from 'shared/services/BackendService';
import { useHistory } from 'react-router-dom';
import { useIsMounted } from 'shared/hooks/useIsMounted';
import { ServerStore } from 'shared/services/ServerStore';

import { usePushNotificationHook } from 'shared/services/push/PushNotifyService';
import { useAuthorization } from 'shared/services/AuthService';
import HeaderWidget from 'shared/components/HeaderWidget';

import { toast } from 'shared/components/SharedToast';
import { useConditionallyHandleErrors } from 'shared/hooks/useConditionallyHandleErrors';
import styles from './ConversationWidget.module.scss';

import MessageList from './MessageList';
import ComposerWidget from './ComposerWidget';
import { ChannelTypes } from '../../utils/ChatEnums';

function MessageListWidget({ loading, ...props }) {
	return (
		<div className={styles.listWrapper}>
			{loading ? (
				<div className={styles.loadingWrap}>
					<CircularProgress />
				</div>
			) : (
				''
			)}
			<MessageList {...props} />
		</div>
	);
}

export default function ConversationWidget({
	conversationId,
	channelSelectAllowed = true,
	lightMode = false,
	hideBackButton,
	onBackClicked,
	headerIcon,
	doublePaneView,
	hideForegroundNotifications,
	onNewMessage = () => {},
	disableHead,
	// Used server-side for audit logging
	pageLevelInteraction = false,
	hideHeader = false,
	titleString: inputTitleString,
	actionMenuOverride = undefined,
	headerContentComponent = undefined,
	headerClassName = '',
	widgetMode = false,
}) {
	const debugPrefix = `[${conversationId}]`;

	const auth = useAuthorization();
	const { user: authUser } = auth || {};
	const { id: currentUserId } = authUser || {};

	// Used for paging as user scrolls up
	const firstEpoch = useRef();
	const accumulatedMessages = useRef();
	const [hasOlderMessages, setHasOlderMessages] = useState();

	const messageBatchSize = 50;

	// This ref is stable while the widget is loaded - which means that we WON'T
	// force useSWR to reload the messages every render. Instead, the message list is
	// kept up-to-date via the message hooks and websocket.
	// However, because useSWR is so good at caching, when the user leaves this page
	// and opens the same convo again, even if new messages are present, then
	// they see the same messages. However, this ref fixes that - because the ref
	// changes value when page unmounts and remounts, so it forces the cache to be dirty
	// for useSWR and it then loads a fresh set of messages for the same convo from the server.
	const pageSessionRef = useRef(Date.now());

	const mountedRef = useIsMounted();

	const {
		data: messageMeta,
		error: messageMetaError,
		revalidate: refreshMessageMeta,
	} = useSWR(
		['conversation', conversationId, pageSessionRef.current],
		(unused, id) => {
			if (!id) {
				return Promise.resolve({});
			}
			const props = {
				numMessages: accumulatedMessages.current || messageBatchSize,
				pageLevelInteraction,
			};
			console.warn(`${debugPrefix} Requesting messageMeta for ${id}`, props);
			return ServerStore.GetConversation(id, props).then((response) => {
				if (!mountedRef.current) {
					return FormData;
				}
				console.log(`${debugPrefix} << got`, response);
				const {
					firstEpoch: originalFirstEpoch,
					hasOlderMessages: originalHasOlderMessages,
					...data
				} = response;
				firstEpoch.current = originalFirstEpoch;
				setHasOlderMessages(originalHasOlderMessages);
				return data;
			});
		},
	);

	// console.log(`Got messageMeta:`, messageMeta);
	const {
		convoUser: { lastSeenMessageId: lastSeenMessageIdFromServer } = {},
		conversation: { convoType, name: convoName, user } = {},
		messages: originalMessagesList = [],
		numNewMessages,
	} = messageMeta || {};

	if (numNewMessages > 0) {
		console.warn(`${debugPrefix} need to load new messages:`, numNewMessages);
	}

	window.refreshMessageMeta = refreshMessageMeta;

	const { id: userId, email, phoneNum, name: userName } = user || {};

	// console.log(`Got convo user info:`, {
	// 	lastSeenMessageId,
	// 	lastSeenAt,
	// 	lastSeenMessageEpoch,
	// 	messageMeta,
	// });

	const loading = !messageMeta && !messageMetaError; // .loadDone;
	const titleString =
		inputTitleString || (loading ? '' : convoType ? convoName : userName);

	const [messages, setMessages] = useState([]);

	const [lastSeenMessageId, setLastSeenMessageId] = useState();
	const lastSeenSyncGuard = useRef();
	useEffect(() => {
		if (
			!loading &&
			lastSeenMessageId !== lastSeenMessageIdFromServer &&
			!lastSeenSyncGuard.current
		) {
			setLastSeenMessageId(lastSeenMessageIdFromServer);
			lastSeenSyncGuard.current = true;

			if (numNewMessages > 0 && onNewMessage) {
				onNewMessage();
			}
		}
	}, [
		loading,
		lastSeenMessageId,
		lastSeenMessageIdFromServer,
		numNewMessages,
		onNewMessage,
	]);

	const loadedRef = useRef();
	useEffect(() => {
		if (
			!loading &&
			loadedRef.current !== conversationId &&
			messages.length !== originalMessagesList.length
		) {
			setMessages(originalMessagesList);
			// accumulatedMessages.current = originalMessagesList.length;
			loadedRef.current = conversationId;
		}
	}, [messages, originalMessagesList, setMessages, loading, conversationId]);

	// Keep in sync as much as possible
	useEffect(() => {
		accumulatedMessages.current = messages.length;
	}, [messages.length]);

	const onLastSeenChanged = useCallback(
		(
			{ epoch: newLastSeenMessageEpoch, id: newLastSeenMessageId } = {},
			{ manual = false } = {},
		) => {
			const props = {
				conversationId,
				lastSeenMessageId: newLastSeenMessageId || '',
				// Epoch of 0 would make all messages in conversation unread,
				// which is what we want if no message given - which would happen
				// if user marks first msg in convo unread
				lastSeenMessageEpoch: newLastSeenMessageEpoch || 0,
			};
			// console.warn(`UpdateConversationUser using props:`, props);
			ServerStore.UpdateConversationUser(props).then(() => {
				if (manual) {
					window.history.go(-1);
				}
			});
		},
		[conversationId],
	);

	usePushNotificationHook(
		({
			// Data is straight from our backend models/conversation.js in socketNotifyNewMessage()
			data: {
				type,
				conversationId: notificationConvoId,
				messageId,
				// eslint-disable-next-line no-shadow
				userId,
			} = {},
		}) => {
			if (type === 'conversation.new-message') {
				// Allow clients of this widget to permit foreground notifications
				// Used in ConversationPanel
				if (hideForegroundNotifications === false) {
					return false;
				}

				if (notificationConvoId === conversationId) {
					// console.log(`Push notifify intercept:`, {
					// 	userId,
					// 	currentUserId,
					// 	hideForegroundNotifications,
					// });
					if (onNewMessage && userId !== currentUserId) {
						onNewMessage({
							id: messageId,
							fromPushNotification: true,
						});
					}

					// Stop the notification from showing in foreground
					// because this is the page that is currently open
					return true;
				}
			}

			// Fall thru, allow foreground notifications to show about OTHER conversations
			// or other types of notifications
			return false;
		},
	);

	// Subscribe to socket messages about this conversation
	// (e.g. connect to the 'conversation' socket pool on the server for `conversationId`)
	usePoolSubscription('conversation', conversationId);

	const onSocketMessage = useCallback(
		({ type, ...data }) => {
			console.log(`ConversationWidget messageHooks got type:`, type, data);

			if (type === MessageTypes.ConversationMessagesReceived) {
				const { message, conversationId: incomingConversationId } = data;
				if (incomingConversationId !== conversationId) {
					// console.warn(
					// 	` // Not for current conversation: `,
					// 	incomingConversationId,
					// );
					return false;
				}

				if (onNewMessage && message.user && message.user.id !== currentUserId) {
					onNewMessage(message);
				}

				if (
					message.clientMessageId &&
					messages.some(
						({ clientMessageId }) =>
							clientMessageId && clientMessageId === message.clientMessageId,
					)
				) {
					// console.warn(
					// 	` // clientMessageId already in list, will use status update when ready`,
					// );
					return false;
				}

				if (messages.some(({ id }) => id === message.id)) {
					// console.warn(` // message already in list`);
					return false;
				}

				// Test this...
				message.inbound = message.user
					? message.user.id !== currentUserId
					: message.inbound;

				console.warn(
					`${debugPrefix} [Socket] ++ New message: ${message.id}:`,
					message.text,
					data,
				);

				messages.push(message);

				// Update unread marker
				onLastSeenChanged(message);

				// copy to make react re-render
				setMessages([...messages]);
				accumulatedMessages.current = messages.length;
				// return true;
			}

			if (type === MessageTypes.MessageStatusUpdated) {
				const {
					conversationId: incomingConversationId,
					messageId,
					status,
					statusMessage,
					channelType,
				} = data;

				console.warn(`++ Got message status update over socket: `, data);

				if (incomingConversationId !== conversationId) {
					console.warn(
						` // Not for current conversation: `,
						incomingConversationId,
					);
					return false;
				}

				const msg = messages.find(({ id }) => id === messageId);
				if (msg) {
					// Updates list by reference, so just update these props
					Object.assign(msg, {
						status,
						statusMessage,
						channelType,
					});

					// copy to make react re-render
					setMessages([...messages]);
					accumulatedMessages.current = messages.length;
				} else {
					console.warn(
						`${debugPrefix} Got status update for message not in our list: ${messageId}`,
					);
				}

				// return true;
			}

			return false; // don't intercept
		},
		[
			conversationId,
			currentUserId,
			debugPrefix,
			messages,
			onLastSeenChanged,
			onNewMessage,
		],
	);

	useMessageHook(onSocketMessage);

	const lastMessage = useMemo(
		() => (messages && messages.length && messages[messages.length - 1]) || {},
		[messages],
	);

	const handleErrors = useConditionallyHandleErrors();

	const onSendMessage = useCallback(
		async ({ text, channelType, subject, botResponseData = {} }) => {
			const clientMessageId = uuid();
			const message = {
				id: Date.now(),
				timestamp: new Date(),
				channelType,
				text,
				channelData: {
					subject: channelType === 'email' ? subject : null,
				},
				botResponseData,
				user: { name: '', sending: true },
				status: 'sending',
				clientMessageId,
				// Mark temporary so we don't try to send our fake `Date.now()`
				// ID to the server to "mark it read"
				temporaryMessage: true,
			};

			// Mark messages as interacted with to disable future interaction
			if (
				channelType === ChannelTypes.Bot &&
				lastMessage &&
				lastMessage.isBotMessage
			) {
				Object.assign(messages[messages.length - 1] || {}, {
					hasBotInteraction: true,
				});
			}

			messages.push(message);

			// copy to make react re-render
			setMessages([...messages]);

			// Do this here instead of in onLastSeenChanged()
			// because onLastSeenChanged is also used directly by MessageList
			// and if we changed the ID in onLastSeenChanged(),
			// it would "erase" the visible marker as soon as the list loaded.
			setLastSeenMessageId(null);

			const newMessage = await handleErrors(
				ServerStore.SendMessage({
					conversationId,
					text,
					subject,
					channelType,
					clientMessageId,
					phoneNumRole: convoType === 'driverMember' ? convoType : 'main',
					botResponseData,
					// demoChatMode: false,
					// demoSendMode: true,
					// demoReplyMode: true,
				}),
			);

			if (newMessage) {
				console.log(`${debugPrefix} Got message from server:`, newMessage);

				// Update unread marker
				onLastSeenChanged(newMessage);

				Object.assign(message, newMessage);
				setMessages([...messages]);
			} else {
				console.log(`Error, removing message`);

				// Remove tmp message we added above
				const list = messages.filter(
					(x) => x.clientMessageId !== message.clientMessageId,
				);
				// copy to make react re-render
				setMessages([...list]);
			}
		},
		[
			conversationId,
			convoType,
			debugPrefix,
			handleErrors,
			lastMessage,
			messages,
			onLastSeenChanged,
		],
	);

	const timerRef = useRef();
	const loadingMessageGuard = useRef();
	const onMoreMessagesNeeded = useCallback(() => {
		if (loadingMessageGuard.current) {
			return;
		}

		loadingMessageGuard.current = true;

		setMessages([{ id: Date.now(), loading: true }, ...messages]);

		// console.log(` - onMoreMessagesNeeded`);
		clearTimeout(timerRef.current);
		// timerRef.current = setTimeout(() => {
		// Render a loading spinner - will go away
		// with next setMessages() call
		// console.log(
		// 	` - onMoreMessagesNeeded ... requesting from epoch: `,
		// 	firstEpoch.current,
		// );
		ServerStore.GetConversation(conversationId, {
			messagesBeforeEpoch: firstEpoch.current,
			numMessages: messageBatchSize,
		}).then(
			({
				messages: newMessages,
				firstEpoch: newFirstEpoch,
				hasOlderMessages: newHasOlderMessages,
			}) => {
				console.warn(`${debugPrefix} + Got older messages:`, {
					newMessages,
					newFirstEpoch,
					newHasOlderMessages,
				});

				setHasOlderMessages(newHasOlderMessages);
				setMessages([...newMessages, ...messages.filter((x) => !x.loading)]);
				firstEpoch.current = newFirstEpoch;
				loadingMessageGuard.current = false;
			},
		);
		// }, 250);
	}, [conversationId, debugPrefix, messages]);

	// console.log(`[ConversationWidget] Render:`, messages);

	// Used for onClick handler to mark the convo as "Read" on first interaction
	const firstInteractionGuard = useRef(false);
	const updateLastSeen = useCallback(() => {
		if (!firstInteractionGuard.current && conversationId) {
			firstInteractionGuard.current = true;
			onLastSeenChanged(lastMessage);
		}
	}, [conversationId, lastMessage, onLastSeenChanged]);

	const {
		// channelType: lastChannelType,
		disableComposerPendingBotInteraction,
		channelData: { subject: lastSubject } = {},
	} = lastMessage;

	// TODO: Fix this
	const lastChannelType = 'sms';

	const actionMenu = useMemo(
		() =>
			convoType
				? []
				: [
						{
							text: 'User Details',
							icon: PersonIcon,
							// onClick: onUnfollow,
							component: 'a',
							// rel: 'nofollow noreferrer',
							// target: '_blank',
							href: `/account/user/${userId}`,
						},
						// {
						// 	text: 'Share',
						// 	// icon: ShareIcon,
						// 	// onClick: onShare,
						// },
				  ],
		[convoType, userId],
	);

	// console.log('headerContentComponent', {
	// 	headerContentComponent,
	// 	type: typeof headerContentComponent,
	// });

	return (
		<div
			className={clsx(
				styles.root,
				doublePaneView && styles.doublePaneView,
				widgetMode && styles.widgetMode,
				'ConversationWiget-root',
			)}
			onClick={updateLastSeen}
		>
			{conversationId && (
				<>
					{!hideHeader && (
						<HeaderWidget
							lightMode={lightMode}
							disableHead={disableHead}
							title={titleString}
							loading={loading}
							actions={actionMenuOverride || actionMenu}
							position={'relative'}
							headerIcon={headerIcon}
							className={headerClassName}
							contentComponent={
								typeof headerContentComponent === 'function'
									? headerContentComponent({ titleString })
									: headerContentComponent
							}
							onBackClicked={
								onBackClicked ||
								(() =>
									history.push(
										`${window.location.href}`.includes('backToDashboard=true')
											? '/dashboard'
											: '/conversations',
									))
							}
							hideBackButton={hideBackButton || doublePaneView}
							menuTooltip="Actions related to this conversation"
							titleComponent={<>{titleString}</>}
						/>
					)}

					<MessageListWidget
						lightMode={lightMode}
						loading={loading}
						messages={messages}
						hasMoreMessages={hasOlderMessages}
						onMoreMessagesNeeded={onMoreMessagesNeeded}
						lastSeenMessageId={lastSeenMessageId}
						onLastSeenChanged={onLastSeenChanged}
						onSendMessage={onSendMessage} // for bot message replies
					/>

					{disableComposerPendingBotInteraction ? (
						''
					) : (
						<ComposerWidget
							channelSelectAllowed={channelSelectAllowed}
							lightMode={lightMode}
							isEmailAllowed={!!email}
							isSmsAllowed={!!phoneNum}
							loading={loading}
							lastChannelType={lastChannelType}
							lastSubject={lastSubject}
							onSendMessage={onSendMessage}
							conversationId={conversationId}
						/>
					)}
				</>
			)}
		</div>
	);
}
