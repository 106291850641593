/* eslint-disable no-unused-vars */
/* eslint-disable no-console, no-nested-ternary, no-multi-assign, no-return-assign */
import { EventEmitter } from 'events';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { Capacitor } from '@capacitor/core';
import { Http } from '@capacitor-community/http';
import { Device } from '@capacitor/device';
import { datadogLogs } from './DatadogBrowserLogs';
import WebSocketMessageWrapper from '../utils/WebSocketMessageWrapper';
import BackendService from './BackendService'; // eslint-disable-line import/no-cycle
import DeviceInfo from '../utils/DeviceInfo';
// eslint-disable-next-line import/no-cycle
import AuthService from './AuthService';
import AppConfig from '../config-public';

// Why react thinks this is wrong, idk - it IS in regular deps...
// eslint-disable-next-line  import/no-extraneous-dependencies
import nanoid from '../utils/nanoid';

import { server } from '../utils/ServerUtil';
import gtag from '../utils/GoogleAnalytics';
import LogRocket from './LogRocket';

const isNativeApp = ['android', 'ios'].includes(Capacitor.getPlatform());

// Setup axios to retry idempotent requests
// using https://github.com/softonic/axios-retry
axiosRetry(axios, { retries: 3 });

// Attach the session recording URL to ALL datadog logs:
// - The 'addLoggerGlobalContext' call ensures it gets on logs captured from console (e.g. errors/stack traces)
// - The global 'logRocketRecordingUrl' field is sent to the server as --sessionRecordingUrl for every request
//   so the backend can log the recording as well in logs
let logRocketRecordingUrl;
if (AppConfig.buildEnv !== 'dev') {
	LogRocket.getSessionURL((sessionURL) => {
		logRocketRecordingUrl = sessionURL;
		datadogLogs.addLoggerGlobalContext(
			'sessionRecordingUrl',
			logRocketRecordingUrl,
		);
	});
}

// Only set to true for development
const SERVERLESS_TESTING = false; // (process.env.NODE_ENV === 'production') ? false : true;

// For caching battery queries for a short time
const batteryInfoCache = {};

const { apiHost, apiVayaValetIo, tcpPort } = AppConfig;

const TX_PREFIX_ID_CACHE_KEY = '@rubber/tx-prefix-id';
let txDeviceId =
	global.window && global.window.localStorage.getItem(TX_PREFIX_ID_CACHE_KEY);
if (!txDeviceId) {
	txDeviceId = nanoid();
	if (global.window) {
		global.window.localStorage.setItem(TX_PREFIX_ID_CACHE_KEY, txDeviceId);
	}
}

const txSessionCounterKey = '@rubber/session-counter';
let txSessionCounter = parseInt(
	(global.window && window.localStorage.getItem(txSessionCounterKey)) || '0',
	10,
);
txSessionCounter++;
if (Number.isNaN(txSessionCounter)) {
	txSessionCounter = 1;
}
if (global.window) {
	window.localStorage.setItem(txSessionCounterKey, txSessionCounter);
}

//  Use this prefix for clientId transactions for this session
// const transactionPrefix = parseInt(uuid().split(/-/).slice(2, 4).join(''), 16);
export const transactionPrefix = `d${txDeviceId}.s${txSessionCounter}`;

let transactionCounter = 0;
export const createClientTransactionId = () =>
	`${transactionPrefix}.tx${transactionCounter++}`;

// Log for inclusion in Sentry logs
// console.log(
// 	`[ServerStore] Session transactionPrefix:`,
// 	`${transactionPrefix}.X`,
// );

let ip = apiHost;
if (ip.includes(`localhost:${tcpPort}`)) {
	ip = `${global.window && global.window.location.hostname}:${tcpPort}`;
}

const isNgrok =
	global.window && global.window.location.hostname.includes(`.ngrok.io`);

if (isNgrok) {
	ip = AppConfig.backendTunnelHost;
}

const currentProto =
	(global.window && global.window.location.protocol) || 'https:';

const apiRootFactory = (hostName) =>
	!isNativeApp
		? `${
				`${hostName}`.includes('vayadriving.com') ? 'https:' : currentProto
		  }//${hostName}`
		: `https://${hostName}`;

let apiRoot = apiRootFactory(ip);

// Add feature flag to optionally route traffic to api.vayavalet.io
const { posthog } = global.window || {};
if (posthog) {
	posthog.onFeatureFlags(() => {
		// feature flags are guaranteed to be available at this point
		if (posthog.isFeatureEnabled(`api-vayavalet-io-${AppConfig.buildEnv}`)) {
			// Enable routing API thru api.vayavalet.io on CloudFlare
			apiRoot = apiRootFactory(apiVayaValetIo);
		}
	});
}

// Trying this...
if (AppConfig.buildEnv === 'prod') {
	apiRoot = apiRootFactory(apiVayaValetIo);
}

// console.log({ apiHost, tcpPort, AppConfig, ip, apiRoot });

// // For local storage of auth token
// // Same token - share with AuthService.js
// const TOKEN_KEY = '@rubber/auth-token';
// const storeToken = (token) => {
// 	// console.warn(" *** SET TOKEN: ", token);
// 	if (token !== null && token !== undefined && `${token}`.trim()) {
// 		window.localStorage.setItem(TOKEN_KEY, token);
// 	} else {
// 		window.localStorage.removeItem(TOKEN_KEY, token);
// 	}
// };
// const getToken = () => {
// 	const token = window.localStorage.getItem(TOKEN_KEY);

// 	// console.warn(" *** GET TOKEN: ", token);
// 	return token;
// };

// Check for HTTP plugin support on old app versions - this flag is used below
let canNativeHttp = false; // !!Http;
if (isNativeApp) {
	const failure = (ex) =>
		console.error(
			`Error attempting native HTTP methods, falling back to browser HTTP on native app`,
			ex,
		);

	try {
		Http.get({
			url: 'https://api.vayadriving.com/api/v1/version',
			method: 'GET',
		})
			.catch((ex) => {
				failure(ex);
			})
			.then((data) => {
				const apiVersion = data?.data?.version;
				canNativeHttp = !!apiVersion;
				console.info(
					`Native HTTP plugin success, got proper version response, API is version '${apiVersion}'`,
					data,
				);
			});
	} catch (ex) {
		failure(ex);
	}
}

export class ServerStore {
	static _events = new EventEmitter();

	static currentUser = null;

	static on(event, callback) {
		ServerStore._events.on(event, callback);
	}

	static off(event, callback) {
		ServerStore._events.off(event, callback);
	}

	static emit(event, data) {
		ServerStore._events.emit(event, data);
	}

	static server() {
		return server;
	}

	static getCommonRequestAnnotations(
		{ clientTransactionId: clientTransactionIdInput } = {},
		{ asHeaders = false } = {},
	) {
		const clientTransactionId =
			clientTransactionIdInput || createClientTransactionId();

		if (asHeaders) {
			// Picked up by logRequest middleware on backend
			return {
				'X-Client-Ver': AppConfig.version,
				'X-Client-Tx': clientTransactionId,
				...(logRocketRecordingUrl
					? { 'X-Session-Rec': logRocketRecordingUrl || '' }
					: {}),
			};
		}

		// Fallback for injection into things like file upload forms, etc
		return {
			// For our audit log and console logging on the backend
			'--clientTransactionId': clientTransactionId,
			// For checking/diagnosing errors (e.g. why is the client sending this data? Maybe it's out of date...)
			'--clientVersion': AppConfig.version,
			// For DataDog integration
			...(logRocketRecordingUrl
				? { '--sessionRecordingUrl': logRocketRecordingUrl || '' }
				: {}),
		};
	}

	static getUtcOffset() {
		// getTimezoneOffset returns a negative number when there is a POSITIVE
		// UTC offset. WHY???
		return -1 * new Date().getTimezoneOffset();
		// return -300;
	}

	static getTimezone() {
		try {
			return Intl.DateTimeFormat().resolvedOptions().timeZone;
		} catch (ex) {
			console.error(
				`Caught exception when trying to get IANA timezone name using Intl framework:`,
				ex,
			);
			return undefined;
		}
	}

	// Random delay used to simulate network congestion / slow server response times
	static async randomTestingDelay() {
		// Never leave this on in production again...
		if (
			process.env.NODE_ENV === 'production' ||
			process.env.REACT_APP_STAGING === 'true'
		) {
			return;
		}

		// Only delay in testing
		await new Promise((resolve) =>
			setTimeout(() => resolve(), 500 * Math.random() + 650),
		);
	}

	/**
	 * Extracts the standard API `errors` array from the HTTP Error result
	 * @param {Error} result HTTP Error result
	 * @returns Array of strings - the error messages given by the backend
	 */
	static extractApiErrors(result) {
		const { response: { status, statusText, headers, data } = {} } =
			result || {};

		const { context, errors, error: { message } = {} } = data || {};

		// console.log(`extractApiErrors:`, {
		// 	result,
		// 	res: result.response,
		// 	data: result.response && result.response.data,
		// });

		let list = errors;
		if (!errors || !errors.length) {
			if (message) {
				list = [message];
			} else {
				list = [result.message ? result.message : result];
			}
		}

		// Add status/etc to support destructuring,
		// currently used by AuthService to expose
		// status code given when error received,
		// because BE exposes HTTP 412 when validation required,
		// and login page needs the status code to find that out.
		list.status = status;
		list.statusText = statusText;
		list.headers = headers;

		// Added to propagate custom Stripe context error to UI,
		// but can be used for anything else.
		// The 'context' prop is stuffed into errors by apiRouteAdapters.js in 'sendError' on the server
		list.context = context;
		list.response = result?.response;

		return list;
	}

	/**
	 * Monday SSO
	 * @param {string} mondayUserId Monday User ID
	 * @param {string} email Monday Email
	 * @param {string} phoneNum Monday Phone Num
	 * @param {string} name Optional name
	 * @returns Object shaped like { data: "<token>" } or Error instance
	 */
	static MondaySSO = async ({ mondayUserId, email, phoneNum, name }) =>
		// request() will fail this request if requireToken is not set to false because
		// obviously the user needs to login to have a token
		ServerStore._post(
			'auth/sso/monday',
			{
				mondayUserId,
				email,
				phoneNum,
				name,
				deviceInfo: await DeviceInfo.getDeviceInfo(),
			},
			{ requireToken: false },
		);

	/**
	 * Anonymous SSO based on Device ID
	 * Will disallow if user is already "signed up" with same device Id
	 * and instead require redirect.
	 * @returns Object shaped like { data: "<token>" } or Error instance
	 */
	static AnonymousSSO = async () =>
		// request() will fail this request if requireToken is not set to false because
		// obviously the user needs to login to have a token
		ServerStore._post(
			'auth/sso/anon',
			{
				deviceInfo: await DeviceInfo.getDeviceInfo(),
			},
			{ requireToken: false },
		);

	/**
	 * Signup to the server
	 * @param {object} packet Signup Packet
	 * @returns Object shaped like { data: "<token>" } or Error instance
	 */
	static signup = async (packet) =>
		// request() will fail this request if requireToken is not set to false because
		// obviously the user needs to login to have a token
		ServerStore._post(
			'auth/signup',
			{
				...packet,
				deviceInfo: await DeviceInfo.getDeviceInfo(),
			},
			{ requireToken: false },
		);

	/**
	 * Login to the server
	 * @param {string} email Email
	 * @param {string} password Password
	 * @returns Object shaped like { data: "<token>" } or Error instance
	 */
	static login = async (email, password, inviteId, tenant) =>
		// request() will fail this request if requireToken is not set to false because
		// obviously the user needs to login to have a token
		ServerStore._post(
			'auth/login',
			{
				email,
				password,
				deviceInfo: await DeviceInfo.getDeviceInfo(),
				inviteId,
				tenant,
			},
			{ requireToken: false },
		);

	static async forgotPassword({ email, phoneNum }) {
		return ServerStore._post(
			'auth/forgot-password',
			{ email, phoneNum, deviceInfo: await DeviceInfo.getDeviceInfo() },
			{
				requireToken: false,
			},
		);
	}

	static async resetPassword({ userId, code, password, tenant }) {
		return ServerStore._post(
			'auth/reset-password',
			{
				userId,
				code,
				password,
				deviceInfo: await DeviceInfo.getDeviceInfo(),
				tenant,
			},
			{
				requireToken: false,
			},
		);
	}

	static async acceptInvite({ inviteId, password, tenant }) {
		return ServerStore._post(
			'auth/accept-invite',
			{
				inviteId,
				password,
				deviceInfo: await DeviceInfo.getDeviceInfo(),
				tenant,
			},
			{
				requireToken: false,
			},
		);
	}

	static async getAccountPartnerMeta({ userId, email = undefined }) {
		return ServerStore._get(
			'auth/account-partner-meta',
			{ userId, email },
			{
				requireToken: false,
			},
		);
	}

	static async getTenantMeta({ tenant }) {
		return ServerStore._get(
			'auth/tenant-meta',
			{ tenant },
			{
				requireToken: false,
			},
		);
	}

	static async pinAuth({ tenant, pin }) {
		return ServerStore._post(
			'auth/tenant/pin',
			{ tenant, pin, deviceInfo: await DeviceInfo.getDeviceInfo() },
			{
				requireToken: false,
			},
		);
	}

	static async backendVersion() {
		return ServerStore._get(
			'version',
			{},
			{
				requireToken: false,
			},
		);
	}

	// /**
	//  * Verifies the given token is valid and not expired
	//  * @param {string} token Token to verify
	//  * @returns {object} like `{
	//  *	"userId": "string",
	//  *	"role": {}
	//  *	}`
	//  */
	// static verifyToken = async (token) =>
	// 	// NOTE: VERY important that requireToken is false here,
	// 	// because otherwise this will cause a cyclical loop because request() calls AuthService.checkAuthorizationStatus() internally,
	// 	// which then calls this method verifyToken(), which calls request() - which calls checkAuthorizationStatus if requireToken is true
	// 	ServerStore._post(
	// 		'auth/token/validate',
	// 		{
	// 			token,
	// 			deviceInfo: await DeviceInfo.getDeviceInfo(),
	// 		},
	// 		{ requireToken: false },
	// 	);

	/**
	 * Exchanges old token for fresh token with revised expiration
	 * @param {string} token Token to verify
	 * @returns {object} { data: "<fresh token>" }
	 */
	static refreshToken = async (token) =>
		// NOTE: VERY important that requireToken is false here,
		// because otherwise this will cause a cyclical loop because request() calls AuthService.checkAuthorizationStatus() internally,
		// which then calls this method verifyToken(), which calls request() - which calls checkAuthorizationStatus if requireToken is true
		ServerStore._post(
			'auth/token/refresh',
			{
				token,
				deviceInfo: await DeviceInfo.getDeviceInfo(),
			},
			{ requireToken: false },
		);

	static async _wrapWs(
		method,
		endpoint,
		data = null,
		// autoRetry = true, retryCount = 0,
		{ tokenRequired = true, ...opts } = {},
	) {
		if (tokenRequired) {
			await BackendService.waitForToken();

			if (!server.token) {
				console.error(`No token set, cannot make a request to:`, {
					method,
					endpoint,
					data,
				});
				throw new Error(`Server Utility has no token, request will fail`);
			}
		}

		// return server.call(`/api/v1/${endpoint}`, data, { ...opts, method });

		const { autoRetry = true, retryCount = 0 } = opts;
		return WebSocketMessageWrapper(BackendService, method, endpoint, data, {
			autoRetry,
			retryCount,
			sessionRecordingUrl: logRocketRecordingUrl,
			clientTransactionId: createClientTransactionId(),
		});
	}

	static async _wrapSs(
		method,
		endpoint,
		request,
		{ noTokenRequired = false, ...opts } = {},
	) {
		if (!noTokenRequired && !(await ServerStore.autoLogin())) {
			return null;
		}

		const dt1 = Date.now();
		const result = await ServerStore.server()[method](
			`/api/v1/${endpoint}`,
			request,
			opts,
		);
		const dt2 = Date.now();

		const diff = dt2 - dt1;
		console.log(` * [${method.toUpperCase()}] /${endpoint} (${diff}ms):`, {
			request,
			result,
		});

		return result;
	}

	/**
	 * This is a simple generic requestor that wraps axios so we can encode
	 * the query string for GET requests and catch errors.
	 *
	 * @param {string} method Standard HTTP Method like GET/PUT/POST/PATCH/DELETE etc
	 * @param {string} endpoint [required] API endpoint (don't include anything
	 * 		from API_ROOT, like '/api/v1', etc)
	 * @param {object} data [optional] Object containing values to GET or POST, etc.
	 * 		request() will URL-encode for GET
	 * @param {object} options [optional] Options to pass to the underlying axios request
	 * 		- see axios docs for valid options
	 * @param {boolean} options.requireToken [optional] Defaults to `true`. This option
	 * 		is not passed to axios. It's enforced in request(),
	 * 	and if set to `true`, it will wait for AuthService to validate and check
	 * 		the token and error out if AuthService doesn't return a success response.
	 * @returns The `data` property from the axios response or an `Error` object if
	 * 		there was an exception. TBD if we want different error handling
	 */
	static _wrap = async (
		method,
		endpoint,
		data = {},
		{
			url: urlInput, // added for use with TicketListWorkerClient
			requireToken = true,
			datadogDoNotTransport = false,
			keepalive,
			...options
		} = {},
	) => {
		const dt1 = Date.now();

		// if (data.stayDrafted) {
		// 	console.warn(`Got stayDrafted`, data);
		// }
		let { headers = {} } = options || {};

		const clientTransactionId =
			data.clientTransactionId || createClientTransactionId();

		// Annotate our data for server transport
		Object.assign(
			headers,
			ServerStore.getCommonRequestAnnotations(
				{
					clientTransactionId,
				},
				{ asHeaders: true },
			),
		);

		// console.log(
		// 	`[clientTransactionId:${data.clientTransactionId}] ${method} ${endpoint}:`,
		// 	data,
		// );

		let url = urlInput || `${apiRoot}/api/v1/${endpoint}`;
		if (
			method === 'GET' &&
			Object.keys(data).length &&
			(!isNativeApp || !canNativeHttp)
		) {
			Object.entries(data).forEach(([key, value]) => {
				if (value === undefined || value === null) {
					// Remove null/undefined values from the query string
					// so we don't confuse backend
					// eslint-disable-next-line no-param-reassign
					delete data[key];
				}
			});

			url += `?${new URLSearchParams(data).toString()}`;
		}

		let axiosOpts;

		try {
			let token;
			// if (requireToken) {
			if (!`${url}`.includes(`/auth/token/`)) {
				// console.log(`token required for ${url}, waiting for token..`);

				// Note as docs for checkAuthorizationStatus say, this only hits the backend
				// once to validate the token if we have a token. If no token stored,
				// or the token is invalid, it returns false every time until valid token (login)
				token =
					data.token || requireToken
						? await AuthService.checkAuthorizationStatus()
						: // New: If we don't require token, still supply a token
						  // that way our backend can authorize the request even if not required
						  AuthService.getToken();

				if (!token && requireToken) {
					// eslint-disable-next-line no-console
					console.error(`No token/not authorized, cannot request ${url}`);
					return new Error(`Not authorized`);
				}

				// Add our auth header OVER TOP OF any headers given in options
				if (token) {
					headers = {
						...headers,
						Authorization: token,
					};
				}
			}

			if (ServerStore.consoleLogRequests) {
				// if (endpoint === 'set-driver-online') {
				console.warn(
					` * [${clientTransactionId}] [${method.toUpperCase()}] /${endpoint}`,
					data,
				);
				// }
			}

			let result;
			// ONLY use `keepalive` when you KNOW the page is being unloaded or near-unloaded
			// because this WILL BLOCK THE ENTIRE UI
			if (keepalive) {
				// Resorting to sync XHR if keepalive set.
				// Why?
				// - sendBeacon won't allow application/json content type
				// - encoding data as application/x-www-form-urlencoded looses second-level objects for JSON objects
				// - fetch doesn't support keepalive and CORS preflight
				const client = new XMLHttpRequest();
				client.open('POST', url, false); // third parameter indicates sync xhr
				client.setRequestHeader(
					'Content-Type',
					'application/json;charset=UTF-8',
				);

				// console.log(`Attaching headers`, headers);

				// Attach our custom headers from above
				Object.entries(headers).forEach(([header, value]) =>
					client.setRequestHeader(header, value),
				);

				client.send(JSON.stringify(data));

				// sendBeacon doesn't return anything, just setting this to null to be explicit
				result = null;
			} else if (isNativeApp && canNativeHttp) {
				const nativeOpts = {
					url,
					...options,
					headers: headers || {},
				};

				if (method === 'GET') {
					// [method === 'GET' ? 'params' : 'data']: data,
					nativeOpts.params = Object.fromEntries(
						// Cast all params for GETs to strings for the native HTTP plugin,
						// otherwise it literally will crash on iOS if you pass in a boolean value,
						// saying it can't cast a boolean to string
						Object.entries(data)
							// prevent undefined/nulls from being stringified as "undefined" / "null"
							.filter((e) => e[1] !== null && e[1] !== undefined)
							.map(([key, value]) => [key, `${value}`]),
					);
				} else {
					nativeOpts.headers['Content-Type'] =
						'application/json; charset=utf-8';

					// The native HTTP plugin doesn't properly stringify our JSON for us,
					// so we have to do it here because otherwise even simple JSON requests
					// like 'login' with the sub-object 'deviceInfo' don't get jsonified right.
					nativeOpts.data = JSON.stringify(data);
				}

				// Actually execute the native HTTP plugin
				const fullResponse = await Http[method.toLowerCase()](nativeOpts);

				// Log the native requests because they do NOT show up in devtools' Network tab
				// because they run native code instead of browser code
				console.log(`Native HTTP Request: [${method}] ${url}`, {
					nativeOpts,
					fullResponse,
				});

				const { status, data: nativeResponse } = fullResponse || {};

				result = nativeResponse;

				// The native plugin does not throw errors like Axios or the browser does,
				// so throw them here so they can be caught by the error handling code
				// that we already have in place
				if (!`${status}`.startsWith('20')) {
					const error = new Error(
						result?.error?.message || `HTTP ${status} response`,
					);
					// Add the .response prop so extractApiErrors can find
					// the response which is conveniently shaped just as it expects already
					error.response = result;
					throw error;
				}
			} else {
				axiosOpts = {
					method,
					url,
					data: method === 'GET' ? undefined : data,
					...options,
					headers,
				};

				// console.log(` * request debug:`, axiosOpts);

				const { data: axiosResponse } = await axios(axiosOpts);
				result = axiosResponse;
			}

			const dt2 = Date.now();

			const diff = dt2 - dt1;
			if (ServerStore.consoleLogRequests) {
				// if (endpoint === 'set-driver-online') {
				console.warn(
					` * [${clientTransactionId}] [${method.toUpperCase()}] /${endpoint} (${diff}ms):`,
					{
						request: data,
						result,
					},
				);
				// }
			}

			if (!datadogDoNotTransport) {
				// Move out of the current stack so we don't block results
				setTimeout(async () => {
					// Try to get battery info just for the fun of it...
					let battery;
					if (!ServerStore.failedAtBattery) {
						try {
							if (isNativeApp) {
								if (
									!batteryInfoCache.at ||
									Date.now() - batteryInfoCache.at > 60 * 1000
								) {
									batteryInfoCache.data = await Device.getBatteryInfo();
									batteryInfoCache.at = Date.now();
								}
								battery = batteryInfoCache.data;
							}
						} catch (ex) {
							ServerStore.failedAtBattery = ex;
							console.warn(
								`Error getting battery info, will not try again:`,
								ex,
							);
						}
					}

					const context = {
						...ServerStore.customDataDogContext,
						requestDurationMs: diff,
						clientTransactionId,
						request: data,
						result,
						txDeviceId,
						txSessionCounter,
						battery,
						appVersion: AppConfig.version,
						appBuildTime: AppConfig.buildTime,
					};

					datadogLogs.logger.debug(
						`[${method.toUpperCase()}] /${endpoint}`,
						context,
					);
					// console.log(`* dd debug, context:`, context);
				}, 0);
			}

			return result;
		} catch (ex) {
			// TBD: Better error logging/handling
			// eslint-disable-next-line no-console
			console.error(
				`Error requesting ${url}`,
				ex,
				// Fix https://sentry.io/organizations/vaya/issues/2742574934/?project=6031794
				ex && ex.response && ex.response.data,
				{
					request: data,
					axiosOpts,
					code: ex.code,
					message: ex.message,
				},
			);

			if (ex.message !== 'Network Error') {
				datadogLogs.logger.error(
					`Error on [${method.toUpperCase()}] /${endpoint}`,
					{
						...ServerStore.customDataDogContext,
						exception: ex,
						request: data,
						clientTransactionId,
						responseData: ex && ex.response && ex.response.data,
						axiosOpts,
						txDeviceId,
						txSessionCounter,
						appVersion: AppConfig.version,
						appBuildTime: AppConfig.buildTime,
					},
				);
			}

			if (ex.message === 'Network Error') {
				return new ServerStore.NetworkError(ex);
			}

			return new ServerStore.RequestError(ex);
		}
	};

	static NetworkError = class extends Error {
		constructor(exception) {
			super(exception.message);
			this.response = exception.response;
			this.originalException = exception;
			this.isNetworkError = true;
		}
	};

	static RequestError = class extends Error {
		constructor(exception) {
			super(exception.message);
			this.response = exception.response;
			this.originalException = exception;
			this.isRequestError = true;
		}
	};

	static _get = (endpoint, data = {}, opts = { autoRetry: true }) =>
		ServerStore._wrap(
			'GET',
			endpoint,
			data || {},
			// Enable autoRetry by default on all GET requests
			{ autoRetry: true, ...(opts || {}) },
		);

	static _post = (endpoint, ...args) =>
		ServerStore._wrap('POST', endpoint, ...args);

	static _delete = (endpoint, ...args) =>
		ServerStore._wrap('DELETE', endpoint, ...args);

	static async countMetric(metric, value = 1) {
		ServerStore.metric(
			`${metric}.count`,
			value,
			{},
			true /* dontSendToMixpanel */,
		);

		// For now, just dumps to mixpanel and fakes it (must sum() serverside later) in local metric
		// if (mixpanel) {
		// 	if (
		// 		ServerStore.currentUser &&
		// 		ServerStore.currentUser.id === HIDE_USER_ID_FROM_MIXPANEL
		// 	)
		// 		return;

		// 	mixpanel.people.increment(metric, value);

		// 	// // special-case spending count for
		// 	// // logging as shown in https://developer.mixpanel.com/docs/javascript#section-tracking-revenue
		// 	// // This metric is currently logged in MarketUtils in BuyItemButton.processPurchaseToken
		// 	// if (metric === 'game.count.dollars_spent') {
		// 	// 	mixpanel.people.track_charge(value);
		// 	// }
		// }
	}

	static metric(metric, value, data = {}) {
		// }, dontSendToMixpanel = false) {
		(ServerStore.metrics || (ServerStore.metrics = [])).push({
			// NB user, cat, and level all applied server-side to this item
			// based on the auth token and cat state in the db
			datetime: new Date(),
			epoch: Date.now(),

			metric,
			value,
			data,
		});
		ServerStore._touchMetricInterval();

		// // Upload to mixpanel as well
		// if (mixpanel && !dontSendToMixpanel) {
		// 	if (
		// 		!ServerStore.currentUser ||
		// 		ServerStore.currentUser.id !== HIDE_USER_ID_FROM_MIXPANEL
		// 	) {
		// 		let props;
		// 		if (
		// 			(value !== undefined && value !== null) ||
		// 			Object.keys(data || {}).length > 0
		// 		) {
		// 			props = { value, ...(data || {}) };
		// 		}

		// 		mixpanel.track(metric, props);
		// 	}
		// }

		gtag('event', metric);

		return {
			flush:
				ServerStore._flushMetrics ||
				(ServerStore._flushMetrics = ServerStore.postMetrics.bind(this)),
		};
	}

	static _touchMetricInterval() {
		if (ServerStore._metricInterval) return;

		ServerStore._metricInterval = setInterval(() => {
			ServerStore.postMetrics();
		}, 1000);
	}

	static async postMetrics(keepalive = false) {
		if (SERVERLESS_TESTING || Date.now()) {
			return;
		}

		const metrics = ServerStore.metrics || [];
		if (metrics.length > 0) {
			const deviceInfo = await DeviceInfo.getDeviceInfo();

			// Make a copy and then reset .metrics instead of resetting after tx
			// because the tx is async and doesn't block the rest of the program,
			// so metrics could be added (and then lost) during the tx if we waited
			// to reset after the post finished.
			const batch = metrics.slice();
			ServerStore.metrics = [];
			// If not logged in yet, post to an unauth'd route
			const preAuth = ServerStore.currentUser ? '' : '/pre';
			// NB: Not using { autoRetry: true } arg on server.post
			// because we just catch errors and re-buffer the metrics for later posting
			// at the next call of the _metricInterval interval timer
			await ServerStore.post(
				`/metrics${preAuth}`,
				{
					deviceId: deviceInfo.deviceId,
					batch,
				},
				{
					// Options in this hash passed directly to fetch()
					// Per https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch:
					// 		The keepalive option can be used to allow the request to outlive the page.
					// 		Fetch with the keepalive flag is a replacement for the Navigator.sendBeacon() API.
					// Since we could be calling postMetrics() in onbeforeonload (or other page-ending circumstances),
					// this ensures that the metrics hit the server.
					// We have to use fetch() instead of sendBeacon() because we need headers
					// to contain our auth data so the correct user is tracked with the metrics as well (if logged in)
					keepalive,
				},
			).catch((error) => {
				// Put metrics back on the stack if an error occurred
				ServerStore.metrics.unshift(...batch);
				console.warn('Error posting metrics to server:', error);
			});
		}
	}

	// static async UpdateMessageMeta(rec, patch) {
	// 	return ServerStore._post('message-meta', { messageId: rec.id, ...patch });
	// }

	static async GetConversationsList({ convoType = undefined }) {
		return ServerStore._get('conversations', { convoType });
	}

	static async GetConversation(
		conversationId,
		{
			// numMessages is number of initial messages to retrieve
			numMessages = 100,
			// Additional messages can be retrieved by providing the epoch in messagesBeforeEpoch which will load messages older than that epoch (in numMessages blocks)
			// You can get the epoch from messages[0].epoch
			messagesBeforeEpoch = null,
			pageLevelInteraction,
		} = {},
	) {
		return ServerStore._get('conversation', {
			conversationId,
			numMessages,
			messagesBeforeEpoch,
			pageLevelInteraction,
		});
	}

	static async UpdateConversation(props) {
		return ServerStore._post('conversation', props);
	}

	static async UpdateConversationUser(props) {
		return ServerStore._post('conversation-user', props);
	}

	static async SendMessage(props) {
		return ServerStore._post('message', props);
	}

	// static async UpdateMessageMeta(rec, patch) {
	// 	return ServerStore._post('message-meta', { messageId: rec.id, ...patch });
	// }

	static async RemoveAttachment(attachmentId) {
		return ServerStore._post('remove-attachment', {
			attachmentId,
		});
	}

	static async DraftAttachments(conversationId) {
		return ServerStore._get('draft-attachments', {
			conversationId,
		});
	}

	static async GetConversationUser({ name, phoneNum, email }) {
		return ServerStore._post('upsert-conversation-user', {
			name,
			phoneNum,
			email,
		});
	}

	static async FindLatestConversation(userId) {
		return ServerStore._post('find-latest-conversation', {
			userId,
		});
	}

	static async UpdateUserProps(patch) {
		return ServerStore._post('user-props', patch);
	}

	static async GetUsers(query) {
		return ServerStore._get('users', query);
	}

	static async GetUser(userId, props = {}) {
		return ServerStore._get('user', { userId, ...props });
	}

	static async UpdateUser(userId, patch) {
		return ServerStore._post('user', { userId, ...patch });
	}

	static async CreateUser(patch) {
		return ServerStore._post('users', patch);
	}

	static async GetCurrentTrip({
		currentTripAsDriver = false,
		userId,
		onlyNonActive = false,
		updateUserCurrentTrip = false,
	}) {
		return ServerStore._get('trip', {
			currentTrip: true,
			currentTripAsDriver,
			userId,
			onlyNonActive,
			updateUserCurrentTrip,
		});
	}

	static async GetTrip(tripId, { updateUserCurrentTrip = false } = {}) {
		return ServerStore._get('trip', { tripId, updateUserCurrentTrip });
	}

	static async GetTrips({
		accountId,
		userId,
		upcoming,
		adminMapFilter,
		partnerLevelTrips,
	}) {
		return ServerStore._get('trips', {
			accountId,
			userId,
			upcoming,
			adminMapFilter,
			partnerLevelTrips,
		});
	}

	static CreateTrip = (tripData) => ServerStore._post('create-trip', tripData);

	static ModifyTripQuote = ({ id, ...tripData }) =>
		ServerStore._post('modify-trip-quote', {
			tripId: id,
			...tripData,
		});

	static AcceptTripQuote = (tripId) =>
		ServerStore._post('accept-trip-quote', { tripId });

	static CancelTripRequest = (tripId) =>
		ServerStore._post('cancel-trip-request', { tripId });

	static MarkTripRejected = (tripId, opts) =>
		ServerStore._post('mark-driver-rejected', { tripId, ...opts });

	static MarkDriverAccepted = (tripId) =>
		ServerStore._post('mark-driver-accepted', { tripId });

	static StartScheduledTrip = (tripId, opts) =>
		ServerStore._post('start-scheduled-trip', { tripId, ...opts });

	static MarkDriverArrived = (tripId, opts) =>
		ServerStore._post('mark-driver-arrived', { tripId, ...opts });

	static MarkTripRiding = (tripId, opts) =>
		ServerStore._post('mark-trip-riding', { tripId, ...opts });

	static MarkTripRidingStopped = (tripId, opts) =>
		ServerStore._post('mark-trip-riding-stopped', { tripId, ...opts });

	static MarkTripDropoff = (tripId, opts) =>
		ServerStore._post('mark-trip-dropoff', { tripId, ...opts });

	static MarkTripDraft = (tripId) =>
		ServerStore._post('mark-trip-draft', { tripId });

	static StartEditingWhileActive = (tripId) =>
		ServerStore._post('start-editing-while-active', { tripId });

	static AcceptActiveEditChanges = (tripId) =>
		ServerStore._post('accept-active-edit-changes', { tripId });

	static UndoActiveEditChanges = (tripId) =>
		ServerStore._post('undo-active-edit-changes', { tripId });

	static AdminSetTripStatus = (tripId, status) =>
		ServerStore._post('admin-set-trip-status', {
			tripId,
			status,
		});

	static SubmitDriverFeedback = (
		tripId,
		{ driverUserRating, driverUserComments },
	) =>
		ServerStore._post('add-driver-feedback', {
			tripId,
			driverUserRating,
			driverUserComments,
		});

	static SubmitUserFeedback = (
		tripId,
		{ userDriverRating, userDriverComments, tipAmount },
	) =>
		ServerStore._post('mark-user-completed', {
			tripId,
			userDriverRating,
			userDriverComments,
			tipAmount,
		});

	static async StoreGpsLocation(
		{
			lat,
			lng,
			accuracy,
			speed,
			sensorType,

			timestamp: timestampInput,

			...props
		},
		{ datadogDoNotTransport } = {},
	) {
		const timestamp =
			timestampInput && !Number.isNaN(timestampInput)
				? timestampInput
				: Date.now(); // ts right away for accuracy

		const { deviceClass, appType } = await DeviceInfo.getDeviceInfo();
		// return ServerStore._post(
		return ServerStore._wrapWs(
			'POST',
			'store-gps-location',
			{
				...props,
				lat,
				lng,
				accuracy,
				speed,
				timestamp,
				sensor: `${deviceClass}.${sensorType || appType}`,
			},
			{ datadogDoNotTransport },
		);
	}

	static async StorePushToken(pushToken) {
		const deviceInfo = await DeviceInfo.getDeviceInfo();
		return ServerStore._post('store-push-token', {
			pushToken,
			deviceInfo,
		});
	}

	static GetDriverHeatmapGrid = (gridSizeMiles = 0.25) =>
		ServerStore._get('driver-heatmap', { gridSizeMiles });

	static GetDriverOnline = async (userId) =>
		ServerStore._get('driver-online', {
			userId,
		});

	static SetDriverOnline = async (flag, userId) =>
		ServerStore._post('set-driver-online', {
			flag,
			userId,
		});

	static GetSupportOnline = async (userId) =>
		ServerStore._get('support-online', {
			userId,
		});

	static SetSupportOnline = async (flag, userId) =>
		ServerStore._post('set-support-online', {
			flag,
			userId,
		});

	static GetBestPlacesForUser = async ({ userId }) =>
		ServerStore._get('best-places-for-user', { userId });

	static GetAccountList = ({ query, statusFilter = '' }) =>
		ServerStore._get('accounts', {
			status: statusFilter,
			query,
		});

	static GetAccount = (accountId) => ServerStore._get('account', { accountId });

	static ModifyAccount = ({ accountId, status, ...props }) =>
		ServerStore._post('modify-account', {
			accountId,
			status,
			...props,
		});

	static MergeAccounts = ({ sourceAccountId, destAccountId, ...props }) =>
		ServerStore._post('merge-accounts', {
			sourceAccountId,
			destAccountId,
			...props,
		});

	static MoveUserToAccount = ({ userId, destAccountId, ...props }) =>
		ServerStore._post('move-user-to-account', {
			userId,
			destAccountId,
			...props,
		});

	// static InitSubscription = ({ priceId }) =>
	// 	ServerStore._post('init-subscription', { priceId });

	static InitCheckoutSession = ({ priceId }) =>
		ServerStore._post('init-checkout-session', { priceId });

	static InitBillingPortalSession = ({ accountId } = {}) =>
		ServerStore._post('init-billing-portal-session', {
			accountId,
		});

	static GetDashboardTotals = ({ userId, isSupportLog = false } = {}) =>
		ServerStore._get('dashboard-totals', {
			userId,
			isSupportLog,
			offset: ServerStore.getUtcOffset(),
		});

	static GetPayrollDetails = ({ userId, startDate, endDate } = {}) =>
		ServerStore._get('payroll-details', {
			userId,
			startDate,
			endDate,
			offset: ServerStore.getUtcOffset(),
		});

	static GetUserConciergeInfo = (userId) =>
		ServerStore._get('user-concierge-info', { userId });

	static UpdateUserConciergeInfo = (userId, conciergeInfo) =>
		ServerStore._post('update-user-concierge-info', {
			userId,
			conciergeInfo,
		});

	static AddPlace = (tripId, { placeType, place }) =>
		ServerStore._post('add-place', {
			tripId,
			placeType,
			place,
		});

	static RemovePlace = (tripId, { placeType = 'stop', stopId }) =>
		ServerStore._post('remove-place', {
			tripId,
			placeType,
			stopId,
		});

	static GetMakes = () => ServerStore._get('vehicles/makes');

	static GetModelsForMake = (make) =>
		ServerStore._get('vehicles/models', { make });

	static GetYearsForMakeAndModel = (make, model) =>
		ServerStore._get('vehicles/years', { make, model });

	static GetColorsForYearsAndMakeAndModel = (year, make, model) =>
		ServerStore._get('vehicles/colors', { year, make, model });

	static AddVehicle = ({ make, model, year }) =>
		ServerStore._post('vehicles', { make, model, year });

	static GetUserSupportItems = (userId) =>
		ServerStore._get('user-support-items', { userId });

	static CreateUserSupportItem = (userId, data) =>
		ServerStore._post('create-user-support-item', {
			userId,
			...data,
		});

	static ModifyUserSupportItem = (itemId, data) =>
		ServerStore._post('modify-user-support-item', {
			itemId,
			...data,
		});

	static ModifyPreferredDrivers = (props) =>
		ServerStore._post('modify-preferred-drivers', props);

	static CreateOutboundCall = (props) =>
		ServerStore._post('create-outbound-call', props);

	static ForwardCall = (props) => ServerStore._post('forward-call', props);

	static HangupCall = (props) => ServerStore._post('hangup-call', props);

	static GetActiveCalls = (props) => ServerStore._get('active-calls', props);

	static DeleteUser = (userId) => ServerStore._post('delete-user', { userId });

	static SetActiveVehicle = (props) =>
		ServerStore._post('set-active-vehicle', props);

	static RemoveUserVehicle = (props) =>
		ServerStore._post('remove-user-vehicle', props);

	static ModifyUserVehicle = (props) =>
		ServerStore._post('modify-user-vehicle', props);

	static GetBatch = (batchId) => ServerStore._get('batch', { batchId });

	static GetBatchList = () => ServerStore._get('batches');

	static ApproveBatch = (batchId) =>
		ServerStore._post('approve-batch', { batchId });

	static DeleteBatch = (batchId) =>
		ServerStore._post('delete-batch', { batchId });

	static AuditLog = (
		actionId,
		action,
		comments,
		context,
		{ keepalive = false } = {},
	) =>
		ServerStore._post(
			'audit-log',
			{
				actionId,
				action,
				comments,
				context,
			},
			{ keepalive },
		);

	static GetWorkShifts = (query) => ServerStore._get('work-shifts', query);

	static GetWorkShiftAvailability = (query) =>
		ServerStore._get('work-shift-availability', query);

	static CreateWorkShift = (query) =>
		ServerStore._post('create-work-shift', query);

	static ModifyWorkShift = (query) =>
		ServerStore._post('modify-work-shift', query);

	static RemoveWorkShift = (query) =>
		ServerStore._post('remove-work-shift', query);

	static CreateShiftSignup = (query) =>
		ServerStore._post('create-shift-signup', query);

	static RemoveShiftSignup = (query) =>
		ServerStore._post('create-shift-signup', query);

	static CreateShiftAvailability = (query) =>
		ServerStore._post('create-shift-availability', query);

	static ModifyShiftAvailability = (query) =>
		ServerStore._post('modify-shift-availability', query);

	static RemoveShiftAvailability = (query) =>
		ServerStore._post('remove-shift-availability', query);

	static UpdateUserCurrentTrip = (tripId) =>
		ServerStore._post('update-user-current-trip', { tripId });

	static UpsertQuote = (data) => ServerStore._post('orders/quote', data);

	static ValidateVoucher = (voucher) =>
		ServerStore._get(`orders/validate-voucher`, { voucher });

	static CancelQuote = (orderId) =>
		ServerStore._post('orders/quote/cancel', { orderId });

	static ConvertQuoteToTrip = (data) =>
		ServerStore._post('orders/quote/convert', data);

	static GetOrder = (orderId) => ServerStore._get(`orders/${orderId}`);

	static GetQuote = (quoteId) => ServerStore._get(`orders/quote/${quoteId}`);

	static QuoteBookingConversion = (props) =>
		ServerStore._post('quote-booking-conversion', props);

	static ValetJobDemo = (props) =>
		ServerStore._post('orders/valet-job-demo', props);

	static GetAdminDriverList = (props) =>
		ServerStore._get(`admin/ops/drivers`, props);

	static GetAdminTrips = (props) => ServerStore._get(`admin/ops/trips`, props);

	static GetAdminMembers = (props) =>
		ServerStore._get(`admin/ops/members`, props);

	static ValetJobsGetList = () => ServerStore._get(`valet/jobs`);

	static ValetJobsGetJob = (jobId) => ServerStore._get(`valet/jobs/${jobId}`);

	static ValetJobsCreate = (data) => ServerStore._post(`valet/jobs`, data);

	static ValetJobsChangeStatus = (jobId, status, props) =>
		ServerStore._post(`valet/jobs`, { jobId, status, props });

	static ValetJobsPhoneLookup = (phoneNum) =>
		ServerStore._get(`valet/phone-lookup`, { phoneNum });

	static RemoveUserFromConversation = (conversationId, userId) =>
		ServerStore._post('remove-user-from-conversation', {
			conversationId,
			userId,
		});

	static UpsertConversationFromPhoneNum = (phoneNum) =>
		ServerStore._post('UpsertConversationFromPhoneNum', { phoneNum });

	static GaragePlateLogs = (search) =>
		ServerStore._get(`garage/plate-logs`, { search });

	static GetOrder = (orderId) => ServerStore._get(`orders/${orderId}`);

	static GetOrderList = () => ServerStore._get(`orders`);

	static UpsertOrder = (data) => ServerStore._post('orders', data);

	static UpsertOrder = (data) => ServerStore._post('orders', data);

	static GetRide = (rideId) => ServerStore._get(`rides/${rideId}`);

	static AdminUpsertRide = (data) => ServerStore._post('rides/admin', data);

	static AdminListRides = (data) => ServerStore._get('rides/admin/list', data);

	static AdminGetPeople = (data) => ServerStore._get('admin/ops/people', data);

	static AdminGetTagList = (data) => ServerStore._get('admin/ops/tags', data);

	static AdminUpsertTag = (data) => ServerStore._post('admin/ops/tags', data);

	static GetReportList = (params) => ServerStore._get(`reports`, params);

	static GetReport = (reportId, params) =>
		ServerStore._get(`reports/${reportId}`, { reportId, ...params });

	static GetReportMeta = (reportId, params) =>
		ServerStore._get(`reports/${reportId}/meta`, { reportId, ...params });

	static UpdateMessageInteractionTagging = (props) =>
		ServerStore._post('updateMessageInteractionTagging', props);
}

// window.store = ServerStore;

// ServerStore.consoleLogRequests = true;
