/* eslint-disable no-console */
import React, {
	useRef,
	useState,
	useEffect,
	useCallback,
	useMemo,
} from 'react';
import { Capacitor } from '@capacitor/core';
import Dropzone from 'react-dropzone';
import RichTextEditor from 'react-rte';
import clsx from 'clsx';
import axios from 'axios';

import {
	TextareaAutosize,
	IconButton,
	Tooltip,
	MenuItem,
	FormControl,
	Select,
	ButtonBase,
	CircularProgress,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import CancelIcon from '@material-ui/icons/Cancel';

import { ServerStore } from 'shared/services/ServerStore';
import AppConfig from 'shared/config';
import { later } from 'shared/utils/later';
import styles from './ComposerWidget.module.scss';
import InjectAttachmentUrl, {
	scaledSize,
	PREVIEW_SOURCE_TYPE,
} from './InjectAttachmentUrl';

const rteToolbarConfig = {
	display: [
		'BLOCK_TYPE_DROPDOWN',
		'INLINE_STYLE_BUTTONS',
		'BLOCK_TYPE_BUTTONS',
		'LINK_BUTTONS',
		// 'HISTORY_BUTTONS',
	],
	INLINE_STYLE_BUTTONS: [
		{
			label: 'Bold',
			style: 'BOLD',
			className: 'custom-css-class',
		},
		{ label: 'Italic', style: 'ITALIC' },
		// {label: 'Underline', style: 'UNDERLINE'}
	],
	BLOCK_TYPE_DROPDOWN: [
		{ label: 'Normal', style: 'unstyled' },
		{ label: 'Heading Large', style: 'header-one' },
		{ label: 'Heading Medium', style: 'header-two' },
		{ label: 'Heading Small', style: 'header-three' },
	],
	BLOCK_TYPE_BUTTONS: [
		{ label: 'UL', style: 'unordered-list-item' },
		{ label: 'OL', style: 'ordered-list-item' },
	],
};

// eslint-disable-next-line no-unused-vars
const autoReplies = [
	'Yes',
	'No',
	'Thanks',
	'Ok',
	'On my way',
	'Here',
	"I'm here, take your time",
	'See you soon',
].map((text) => ({ text }));

function UploadWidget({ onUpload, results, maxSize, accept, onCancel }) {
	return (
		<div className={styles.uploadWidget}>
			{results.uploading ? ( // eslint-disable-line no-nested-ternary
				<>
					<section>
						<div>
							<CircularProgress style={{ color: 'white' }} />
							<br />
							Uploading...
						</div>
					</section>
				</>
			) : results.success ? ( // eslint-disable-line no-nested-ternary
				<>
					<section>
						<div>
							<span role="img" aria-label="success">
								&#9989;
							</span>{' '}
							Success
						</div>
					</section>
				</>
			) : results.failure ? (
				<>
					<section>
						<div>
							<span role="img" aria-label="failure">
								&#10060;
							</span>{' '}
							{results.error && results.error.message
								? results.error.message
								: ''}
						</div>
					</section>
				</>
			) : (
				<Dropzone
					onDrop={onUpload}
					maxSize={maxSize}
					accept={accept}
					multiple={false}
				>
					{({ getRootProps, getInputProps, isDragActive }) => (
						<section>
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								{isDragActive ? (
									<p>Drop the files here ...</p>
								) : (
									<>
										<AttachFileIcon />
										<p>
											Drag and drop some files here, or click to select files
										</p>
									</>
								)}
							</div>
						</section>
					)}
				</Dropzone>
			)}

			<ButtonBase onClick={onCancel} className={styles.cancelButton}>
				Cancel
			</ButtonBase>
		</div>
	);
}

export default function ComposerWidget({
	channelSelectAllowed = true,
	lightMode,
	loading,
	onSendMessage = () => {},
	// lastChannelType,
	lastSubject,
	conversationId,
	isSmsAllowed = true,
	isEmailAllowed = true,
}) {
	// Disable attachments until backend is ready
	const isAttachmentsAllowed = true;

	const [attachments, setAttachments] = useState([]);

	const [channel, setChannel] = useState('auto');
	const onChangeChannel = useCallback((e) => {
		setChannel(e.target.value);
	}, []);

	const [subject, setSubject] = useState('');
	const onSubjectChanged = useCallback((e) => {
		setSubject(e.target.value);
	}, []);

	const [messageText, setMessageText] = useState('');
	const onMessageTextChanged = useCallback((e) => {
		setMessageText(e.target.value);
	}, []);

	const [rteValue, setRteValue] = useState(RichTextEditor.createEmptyValue());

	const onRteChange = useCallback((value) => {
		setRteValue(value);
		setMessageText(value.toString('markdown'));
	}, []);

	const inputsContainerRef = useRef();
	const sendMessageText = useCallback(
		(text) => {
			setAttachments([]);

			onSendMessage({
				channelType: channel,
				text: `${text}`.replace(/(\n|\s+)$/gm, ''),
				subject: channel === 'sms' ? null : subject,
			});
			// setSubject('');
			setMessageText('');
			setRteValue(RichTextEditor.createEmptyValue());

			// setTimeout(() => {
			if (inputsContainerRef.current && ['sms', 'auto'].includes(channel)) {
				// The <TextareaAutosize> uses two textareas internally,
				// and the one we DON'T want to focus on has the
				// 'readonly' attribute ... so the OTHER one is what we want.
				const [textarea] = Array.from(
					inputsContainerRef.current.getElementsByTagName('textarea'),
				).filter((x) => !x.readonly);

				console.warn(`>>>>> found textarea nodes:`, textarea);
				if (textarea) {
					textarea.focus();
				}
			} else {
				console.error(`not focusing because:`, {
					inputsContainerRef: inputsContainerRef.current,
					channel,
				});
			}
		},
		[channel, onSendMessage, subject],
	);

	const onSendMessageClicked = useCallback(() => {
		if (!messageText) {
			// alert('Nothing to send');
			return;
		}

		sendMessageText(messageText);
		// }, 500);
	}, [messageText, sendMessageText]);

	// Import external type so we can change when user changes mode
	// const extChanType = useRef();
	// if (lastChannelType && extChanType.current !== lastChannelType) {
	// 	setChannel(lastChannelType);
	// 	extChanType.current = lastChannelType;
	// }

	const extSubject = useRef();
	if (lastSubject && extSubject.current !== lastSubject) {
		setSubject(
			lastSubject.startsWith('Re:') ? lastSubject : `Re: ${lastSubject}`,
		);
		extSubject.current = lastSubject;
	}

	const draftGuard = useRef();
	useEffect(() => {
		if (draftGuard.current || !conversationId) {
			return () => {};
		}
		draftGuard.current = true;

		let mounted = true;
		ServerStore.DraftAttachments(conversationId)
			.then(({ draftAttachments }) => {
				if (!mounted) {
					return;
				}
				setAttachments(Array.from(draftAttachments || []));
			})
			.catch((error) => {
				console.error(`Error getting drafts:`, error);
			});

		return () => {
			mounted = false;
		};
	}, [conversationId]);

	const [uploadWidgetVisible, setUploadWidgetVisible] = useState(false);
	const [fileUploadResult, setFileUploadResult] = useState({});
	const [uploadAccept, setUploadAccept] = useState('');
	const [uploadMaxSize, setUploadMaxSize] = useState();

	const showUploadWidget = useCallback((flag = true, accept = '', maxSize) => {
		setFileUploadResult({});
		setUploadWidgetVisible(!!flag);
		setUploadAccept(accept);
		setUploadMaxSize(maxSize);
	}, []);

	const removeAttachment = useCallback(
		(id) => {
			setAttachments(attachments.filter((a) => a.id !== id));
			ServerStore.RemoveAttachment(id);
		},
		[attachments],
	);

	const uploadFileToApi = useCallback(
		(files) => {
			setFileUploadResult({ uploading: true });

			const formData = new window.FormData();
			formData.set('conversationId', conversationId);
			formData.append('file', files[0]);

			Object.entries(ServerStore.getCommonRequestAnnotations()).forEach(
				([key, value]) => formData.append(key, value),
			);

			axios
				.post(
					`//${AppConfig.apiHost}/api/v1/chats/UploadAttachment`,
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
							Authorization: ServerStore.server().token,
						},
					},
				)
				.then((result) => {
					const { data: attachment } = result;
					// console.log(`Got attachment object from server:`, attachment);
					setFileUploadResult({ success: true });
					setAttachments([...attachments, attachment]);
					setTimeout(() => showUploadWidget(false), 500);
				})
				.catch((error) => {
					console.error(`caught error upload:`, error);
					setFileUploadResult({
						failure: true,
						error,
					});
				});
		},
		[attachments, conversationId, showUploadWidget],
	);

	// Handle the `paste` event
	// Thanks to https://htmldom.dev/paste-an-image-from-the-clipboard/
	const pasteHandler = useCallback(
		(evt) => {
			// Get the data of clipboard
			const clipboardItems = evt.clipboardData.items;
			const items = [].slice.call(clipboardItems).filter((item) => {
				// Filter the image items only
				return item.type.indexOf('image') !== -1;
			});

			if (items.length === 0) {
				// eslint-disable-next-line no-alert
				// window.alert('Sorry, can only handle pasting images at this time');
				return;
			}

			const item = items[0];
			// Get the blob of image
			const blob = item.getAsFile();

			// Use the same upload logic that file uploads use,
			// since the pasted blob looks just like a file manually uploaded
			uploadFileToApi([blob]);
		},
		[uploadFileToApi],
	);

	useEffect(() => {
		document.addEventListener('paste', pasteHandler);
		return () => document.removeEventListener('paste', pasteHandler);
	}, [pasteHandler]);

	const onAddAttachmentClicked = useCallback(() => {
		showUploadWidget(!uploadWidgetVisible, '');
	}, [showUploadWidget, uploadWidgetVisible]);

	const onAttachImageClicked = useCallback(() => {
		// Max image size for media on Twilio is 5 MB
		showUploadWidget(!uploadWidgetVisible, 'image/*,video/*', 5 * 1024 * 1024);
	}, [showUploadWidget, uploadWidgetVisible]);

	const onMessageKeyDown = useCallback(
		(evt) => {
			const { key, shiftKey } = evt;
			if (!shiftKey && key === 'Enter') {
				// Wait till textbox completely updates
				later(async () => onSendMessageClicked(), 100);
				evt.preventDefault();
			}
		},
		[onSendMessageClicked],
	);

	// eslint-disable-next-line no-unused-vars
	const autoReply = useCallback(
		(text) => {
			sendMessageText(text);
		},
		[sendMessageText],
	);

	const isIOS = useMemo(() => Capacitor.getPlatform() === 'ios', []);

	return (
		<div
			className={clsx(
				styles.root,
				lightMode && styles.lightMode,
				isIOS && styles.isIOS,
			)}
		>
			{uploadWidgetVisible ? (
				<UploadWidget
					onCancel={() => showUploadWidget(false)}
					onUpload={uploadFileToApi}
					results={fileUploadResult}
					accept={uploadAccept}
					maxSize={uploadMaxSize || 256 * 10124 * 1024}
				/>
			) : (
				''
			)}

			{/* Disabling auto reply right now due to low usage and high erroneous button presses  */}
			{/* <div className={styles.autoReplies}>
				{autoReplies.map(({ text }) => (
					<ButtonBase key={text} onClick={() => autoReply(text)}>
						{text}
					</ButtonBase>
				))}
			</div> */}

			<div className={styles.inputs} ref={inputsContainerRef}>
				{channelSelectAllowed && isSmsAllowed && isEmailAllowed ? (
					<Tooltip title="Channel defines how the messages is sent to the user in this chat">
						<div className={styles.preInputWidget}>
							<FormControl className={styles.channelSelect} disabled={loading}>
								<Select value={channel} onChange={onChangeChannel} tabIndex="1">
									<MenuItem value={'auto'}>Auto</MenuItem>
									<MenuItem value={'sms'}>Text</MenuItem>
									<MenuItem value={'email'}>Email</MenuItem>
								</Select>
							</FormControl>
						</div>
					</Tooltip>
				) : (
					''
				)}

				{channel === 'email' ? (
					<>
						{isAttachmentsAllowed ? (
							<Tooltip title="Add attachment">
								{/* Span wrapper needed to still fire events
							for tooltip to display messages
							even if button is disabled due to loading */}
								<span>
									<IconButton
										onClick={onAddAttachmentClicked}
										disabled={loading}
										tabIndex="2"
									>
										<AttachFileIcon />
									</IconButton>
								</span>
							</Tooltip>
						) : (
							''
						)}
						<TextareaAutosize
							maxRows={2}
							placeholder={'Subject'}
							value={subject}
							onChange={onSubjectChanged}
							disabled={loading}
							className={styles.subjectField}
							tabIndex="3"
						/>
					</>
				) : (
					<>
						{isAttachmentsAllowed ? (
							<Tooltip title="Attach Image">
								{/* Span wrapper needed to still fire events
					for tooltip to display messages
					even if button is disabled due to loading */}
								<span>
									<IconButton
										className={styles.addImageBtn}
										onClick={onAttachImageClicked}
										disabled={loading}
										tabIndex="2"
									>
										<InsertPhotoIcon />
									</IconButton>
								</span>
							</Tooltip>
						) : (
							''
						)}

						<TextareaAutosize
							maxRows={10}
							placeholder={'Type a message here'}
							value={messageText}
							onChange={onMessageTextChanged}
							disabled={loading}
							tabIndex="3"
							onKeyPress={onMessageKeyDown}
						/>
					</>
				)}

				{/* <Tooltip title="Send Message"> */}
				{/* Span wrapper needed to still fire events
				for tooltip to display messages
				even if button is disabled due to loading */}
				<span>
					<IconButton
						onClick={onSendMessageClicked}
						className={clsx(
							styles.sendMessageBtn,
							messageText && styles.enabled,
						)}
						disabled={loading || !messageText}
						tabIndex="10"
					>
						<SendIcon />
					</IconButton>
				</span>
				{/* </Tooltip> */}
			</div>

			<div className={styles.inputs}>
				{channel === 'email' ? (
					<>
						<RichTextEditor
							className={styles.rteEditor}
							value={rteValue}
							onChange={onRteChange}
							toolbarConfig={rteToolbarConfig}
							placeholder="Type your email here"
							tabIndex="4"
						/>
					</>
				) : (
					''
				)}
			</div>

			<div className={clsx(styles.inputs, styles.attachmentsRow)}>
				{attachments.map(
					({
						id,
						name,
						mimeType,
						width,
						height,
						sources: {
							[PREVIEW_SOURCE_TYPE]: {
								width: previewWidth,
								height: previewHeight,
							} = {},
						} = {},
					}) => (
						<Tooltip key={id} title={`Remove "${name}" from this message`}>
							<ButtonBase
								className={styles.attachment}
								onClick={() => removeAttachment(id)}
							>
								<div
									className={clsx(
										styles.icon,
										`${mimeType}`.startsWith('image') && styles.imageIcon,
									)}
								>
									{`${mimeType}`.startsWith('image') ? (
										<InjectAttachmentUrl
											key={id}
											id={id}
											name={name}
											render={({ previewUrl, onPreviewError }) => (
												<img
													alt={name}
													onError={onPreviewError}
													src={previewUrl}
													width={scaledSize(
														previewWidth || width,
														previewHeight || height,
														'width',
													)}
													height={scaledSize(
														previewWidth || width,
														previewHeight || height,
														'height',
													)}
												/>
											)}
										/>
									) : (
										<AttachFileIcon />
									)}
									<div className={styles.cancelIcon}>
										<CancelIcon />
									</div>
								</div>

								<span className={styles.label}>{name}</span>
							</ButtonBase>
						</Tooltip>
					),
				)}
			</div>
		</div>
	);
}
