/* eslint-disable no-console, no-unused-vars */
import {
	useMemo,
	useRef,
	createContext,
	useState,
	useContext,
	forwardRef,
} from 'react';
import PinIcon from '@material-ui/icons/PinDrop';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
	useLocation,
	useHref,
	useLinkClickHandler,
} from 'react-router-dom';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import ForumIcon from '@material-ui/icons/Forum';
import MoneyIcon from '@material-ui/icons/AttachMoney';

import SettingsIcon from '@material-ui/icons/Settings';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import HistoryIcon from '@material-ui/icons/History';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import HomeIcon from '@material-ui/icons/Home';
import EventIcon from '@material-ui/icons/EventAvailable';

import BackendService from 'shared/services/BackendService';
import AccountStatus from 'shared/utils/AccountStatus';
import { useBreakpoint } from 'shared/utils/breakpoints';
import { iOS } from 'shared/utils/iOS';
import AuthService, {
	useAuthorization,
} from 'shared/services/AuthService';
import GeoService from 'shared/services/GeoService';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { SimpleBanner } from 'shared/components/SimpleBanner/SimpleBanner';
import styles from './DrawerNav.module.scss';

import { ListItemLink } from './ListItemLink';

const DrawerNavContext = createContext();

export const useDrawerNavContext = () => {
	const context = useContext(DrawerNavContext);
	if (context === undefined) {
		console.error(
			`useDrawerNavContext must be used inside a <DrawerNavContextProvider>`,
		);
	}
	return context;
};

// Polyfill for pt from our i18n util in case we ever want to do this in the miniapps
const pt = (key, text) => text;

export default function DrawerNav({ alwaysHideDrawer = false }) {
	const theme = useTheme();

	const { drawerOpen, setDrawerOpen } = useDrawerNavContext();

	const {
		user: { id: userId, isDriver, isMember, isAdmin, isSupport } = {},
		account: { status } = {},
	} = useAuthorization() || {};

	// console.log(`drawer nav: `, {
	// 	isDriver,
	// 	isMember,
	// 	isAdmin,
	// 	isSupport,
	// });

	// console.log(`drawernav userid`, userId);

	const onLogoutClicked = () => {
		GeoService.getGpsAdapter().stop();
		BackendService.logout();
		AuthService.clearToken();
		setTimeout(() => {
			window.location.href = '/login?_logout';
		}, 100);
	};

	const { pathname } = useLocation();
	// console.log(`DrawNav:`, { location, isDriver, isMember });
	// const isDriverDashboard = pathname.includes(`/drivers`);
	// const isMemberDashboard = pathname.includes(`/members`);

	const drawer = useMemo(
		() => (
			<div
				className={styles.drawerContent}
				onClick={() => setDrawerOpen(false)}
			>
				<div className={styles.toolbar}>
					<img
						src={'/logo192.png'}
						className={styles.logo}
						alt="Vaya Logo"
					/>
					{/* vaya</SimpleBanner> */}
				</div>
				<List>
					<Divider />

					<ListItemLink href="/book">
						<ListItemIcon>
							<LocalTaxiIcon />
						</ListItemIcon>
						<ListItemText
							primary={pt('book-rides', 'Book Rides')}
						/>
					</ListItemLink>

					{/* <ListItemLink href="/scheduled-trips">
						<ListItemIcon>
							<WatchLaterIcon />
						</ListItemIcon>
						<ListItemText
							primary={pt(
								'upcoming-rides',
								'Upcoming Rides',
							)}
						/>
					</ListItemLink> */}

					<ListItemLink href="/trips">
						<ListItemIcon>
							<PinIcon />
						</ListItemIcon>
						<ListItemText
							primary={pt('rides-list', 'Rides List')}
						/>
					</ListItemLink>

					<Divider />
					{/* 
					<ListItemLink href="/settings">
						<ListItemIcon>
							<SettingsIcon />
						</ListItemIcon>
						<ListItemText
							primary={pt('settings', 'Settings')}
						/>
					</ListItemLink> */}

					<ListItem button onClick={onLogoutClicked}>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						<ListItemText primary={pt('logout', 'Logout')} />
					</ListItem>
				</List>
			</div>
		),
		[setDrawerOpen],
	);

	const isPermanent = !useBreakpoint('small') && !alwaysHideDrawer;
	const isPermanentRef = useRef();
	if (isPermanent !== isPermanentRef.current) {
		isPermanentRef.current = isPermanent;
		if (isPermanent) {
			document.body.style.setProperty(
				'--drawer-nav-permanent-width',
				'var(--drawer-nav-width, 240px)',
			);
		} else {
			document.body.style.removeProperty(
				'--drawer-nav-permanent-width',
			);
		}
	}

	if (alwaysHideDrawer) {
		return (
			<nav className={clsx(styles.drawer)} aria-label="navigation">
				<SwipeableDrawer
					disableBackdropTransition={!iOS}
					// Disabling discovery because of android back gesture
					disableDiscovery={true} // iOS}
					swipeAreaWidth={0} // 44}
					variant="temporary"
					anchor={theme.direction === 'rtl' ? 'right' : 'left'}
					open={drawerOpen}
					onClose={() => setDrawerOpen(false)}
					onOpen={() => setDrawerOpen(true)}
					classes={{
						paper: styles.drawerPaper,
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{drawer}
				</SwipeableDrawer>
			</nav>
		);
	}

	return (
		<nav className={clsx(styles.drawer)} aria-label="navigation">
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Hidden smUp implementation="css">
				<SwipeableDrawer
					disableBackdropTransition={!iOS}
					// Disabling discovery because of android back gesture
					disableDiscovery={true} // iOS}
					swipeAreaWidth={0} // 44}
					variant="temporary"
					anchor={theme.direction === 'rtl' ? 'right' : 'left'}
					open={drawerOpen}
					onClose={() => setDrawerOpen(false)}
					onOpen={() => setDrawerOpen(true)}
					classes={{
						paper: styles.drawerPaper,
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{drawer}
				</SwipeableDrawer>
			</Hidden>
			<Hidden xsDown implementation="css">
				<Drawer
					classes={{
						paper: styles.drawerPaper,
					}}
					variant="permanent"
					open
				>
					{drawer}
				</Drawer>
			</Hidden>
		</nav>
	);
}

export function DrawerNavContextProvider({ children }) {
	const [drawerOpen, setDrawerOpen] = useState(false);

	const toggleDrawer = () => {
		setDrawerOpen(!drawerOpen);
		// console.log(`Toggle drawer open, drawer was:`, drawerOpen);
	};

	// window.toggleDrawer = toggleDrawer;

	return (
		<DrawerNavContext.Provider
			value={{
				drawerOpen,
				setDrawerOpen,
				toggleDrawer,
			}}
		>
			{children}
		</DrawerNavContext.Provider>
	);
}
