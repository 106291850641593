/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-console */
import { v4 as createUuid } from 'uuid';
import { EventEmitter } from 'events';
import DeviceInfo from 'shared/utils/DeviceInfo';
import { defer } from 'shared/utils/defer';
import { Capacitor } from '@capacitor/core';
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';
import AppConfig from 'shared/config-public';
import GpsStreamTransmitter from 'shared/utils/GpsStreamTransmitter';
import { ServerStore, transactionPrefix } from '../services/ServerStore';
import { MAP_BRAND_COLOR_RAW_HEX } from './mapIconGenerators';

// import gpsReplayData from '../test-data/gps-trip-154b79ba-96e5-4504-9385-3a4f0720e7f3.json';

// Not importing due to not wanting to upload a .5MB of JSON to prod just for testing
const gpsReplayData = [];

// console.log(`Loaded replay data: `, gpsReplayData);

const CONSENT_KEY = '@rubber/location-informed-consent';

// If both browser and native plugins are watching GPS,
// don't send a browser update to server if native plugin (foreground)
// just sent one in less than X ms ago
const MIN_NATIVE_BROWSER_OVERLAP = 1000;

const uuidSeenCache = {};

// Your custom Collection of Subscription instances.
// based on https://transistorsoft.github.io/capacitor-background-geolocation/interfaces/subscription.html
const BgLocationListenerSubscriptions = [];

// Your custom method to push a Subscription instance.
const trackSubscription = (subscription) => {
	// console.log(`trackSubscription:`, subscription);
	BgLocationListenerSubscriptions.push(subscription);
};

// Your custom method to iterate your SUBSCRIPTIONS and .remove each.
const unsubscribeNativeListeners = () => {
	BgLocationListenerSubscriptions.forEach((subscription) => {
		if (subscription) {
			subscription.remove();
		}
	});
};

const normalizeNativeLocation = ({
	uuid,
	timestamp,
	coords: {
		latitude: lat,
		longitude: lng,
		accuracy,
		speed,
		speed_accuracy: speedAccuracy,
		altitude,
		altitude_accuracy: altitudeAccuracy,
		heading,
		heading_accuracy: headingAccuracy,
	},
	activity: { type: activityType, confidence: activityConfidence } = {},
	battery: { is_charging: batteryIsCharging, level: batteryLevel } = {},
}) => ({
	lat,
	lng,
	accuracy,

	// 1 m/s = 2.37 mph approx - ref https://www.google.com/search?q=1+m%2Fs+to+mph
	speedMph: Math.round(Math.max(0, (speed || 0) * 2.23694)),

	timestamp:
		timestamp && !Number.isNaN(new Date(timestamp).getTime())
			? new Date(timestamp).getTime()
			: Date.now(),

	uuid: uuid || createUuid(),

	speed,
	speedAccuracy,
	heading,
	headingAccuracy,
	altitude,
	altitudeAccuracy,
	activityType,
	activityConfidence,
	batteryIsCharging,
	batteryLevel,
});

const baseNativeConfig = {
	// Geolocation Config
	desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
	distanceFilter: 1,
	preventSuspend: true,
	// Activity Recognition
	stopTimeout: 1,
	// Application config
	debug: false, // <-- enable this hear sounds for background-geolocation life-cycle.
	logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
	stopOnTerminate: false, // <-- Allow the background-service to continue tracking when user closes the app.
	startOnBoot: false, // <-- Auto start tracking when device is powered-up.
	// HTTP / SQLite config
	batchSync: false, // <-- [Default: false] Set true to sync locations to server in a single HTTP request.
	autoSync: true, // <-- [Default: true] Set true to sync each location to server as it arrives.

	foregroundService: true,
	notification: {
		channelName: 'Vaya GPS',
		title: 'Vaya is using your location',
		text: 'Safety and enhanced member experiences are important to Vaya',
		//   "Vaya uses your location while you're wait ing for a ride or riding in the car in order to keep you safe and enhance your ride experience",
		smallIcon: 'drawable/push_icon',
		// largeIcon: 'drawable/push_icon',
		color: `#${MAP_BRAND_COLOR_RAW_HEX}`,
		// Notification strongly weighted to bottom of list; notification-bar icon hidden
		// https://transistorsoft.github.io/capacitor-background-geolocation/interfaces/notification.html#priority
		priority: BackgroundGeolocation.NOTIFICATION_PRIORITY_MIN,
		// TODO: Custom layout? https://transistorsoft.github.io/capacitor-background-geolocation/interfaces/notification.html#layout
		// https://github.com/transistorsoft/capacitor-background-geolocation/wiki/Android-Custom-Notification-Layout
	},

	// Update 20230905 for valet stuff
	// Changing auth req from 'Always' to 'WhenInUse' so we start more gently.
	// Then when we need live tracking, we can then request 'Always' later
	// Docs: https://transistorsoft.github.io/capacitor-background-geolocation/interfaces/config.html#locationauthorizationrequest
	// locationAuthorizationRequest: 'Always',
	locationAuthorizationRequest: 'WhenInUse',

	// Android 11+ requires:
	backgroundPermissionRationale: {
		title:
			"Allow {applicationName} to access to this device's location in the background?",
		message:
			'In order to accurately members and drivers and to ensure your safety in the background, please enable {backgroundPermissionOptionLabel} location permission',
		positiveAction: 'Change to {backgroundPermissionOptionLabel}',
		negativeAction: 'Cancel',
	},
	// // customize post properties
	// postTemplate: {
	// 	lat: '@latitude',
	// 	lng: '@longitude',
	// 	accuracy: '@accuracy',
	// 	// Added to attempt server-side walk/run/drive detection
	// 	speed: '@speed',
	// 	altitude: '@altitude',
	// 	bearing: '@bearing',
	// 	// built above from deviceInfo()
	// 	sensor,
	// },
};

if (Capacitor.getPlatform() !== 'web') {
	/*
		extras: {
			// <-- Optional meta-data appended to each recorded location.
			sensor,
			// For correlating on the server to a given device and via datadog
			clientTransactionId: `${transactionPrefix}.native`,
		},
		url: gpsWorkerPostUrl, // `${urlRoot}/api/v1/store-gps-location`,
		// syncUrl: `${server.urlRoot}/api/v1/store-gps-location`,
		// syncThreshold: 1,
		headers: {
			// We should have token by now because
			Authorization: token,
		},

	*/

	// Changed the GpsLogger to call '.ready' immediately on app loading this file.
	// See note about iOS at https://transistorsoft.github.io/capacitor-background-geolocation/classes/backgroundgeolocation.html#ready
	// Also, note that this does NOT cause a permission prompt yet or start the logging,
	// this just sets the base-install config.
	// We can and will change the "live" values via BackgroundGeolocation.setConfig later,
	// for things like url, auth token, etc.
	BackgroundGeolocation.ready(baseNativeConfig);
}

export class GpsLogger extends EventEmitter {
	static LOCATION_CHANGED = 'LocationChanged';

	static ERROR_STATE_CHANGED = 'ErrorStateChanged';

	start = async () => {
		clearTimeout(this.stopTid);
		if (this._started) {
			return { success: 'Already started' };
		}
		this._started = true;

		this.foregroundNativeLastTimestamp = null;
		this.browserLocationLastTimestamp = null;

		// console.warn('[GpsLogger.start]'); // , new Error().stack);

		// Only pauses first boot (or if they never give consent, we never resolve)
		const consent = await this.getUserConsent();
		if (!consent) {
			return { error: 'No User Consent' };
		}

		// console.log('[GpsLogger.start] has consent');

		// We could connect browser even on web to ensure foreground backup streaming even
		// if BG plugin doesn't stream in FG correctly...TBD
		await this.connectBrowserLogger();

		// Boot appros plugins once consent is given
		if (Capacitor.getPlatform() !== 'web') {
			await this.connectNativeLogger();

			// Have to reconnect on change because token/URL will change,
			// and background GPS needs that
			GpsStreamTransmitter.on('changed', this.restartNativeLogger);
		}

		// Start watching right away
		this.startWatching();

		return { started: true };
	};

	isStarted() {
		return this._started;
	}

	restartNativeLogger = () => {
		this.stopNativeLogger();
		this.connectNativeLogger();
	};

	stop = async () => {
		// if (!this._started) {
		// 	console.warn(`Already stopped`);
		// 	return;
		// }
		this._started = false;

		this.clearErrorState();

		GpsStreamTransmitter.off('changed', this.restartNativeLogger);

		if (GpsLogger.verboseLogging) {
			console.warn('[GpsLogger.stop]');
		}
		// console.warn('[GpsLogger.stop]', new Error('stopping gps').stack);

		// if (Capacitor.getPlatform() !== 'web') {
		// 	this.stopWatchingNativeLocation();
		// } else {
		// 	this.stopWatchingBrowserLocation();
		// }

		// Stop all watching
		if (!this.stopAttempts) {
			this.stopAttempts = 0;
		}
		await this.stopWatching();

		if (Capacitor.getPlatform() !== 'web') {
			clearTimeout(this.stopTid);
			try {
				await this.stopNativeLogger();

				// Stop AGAIN just to be sure
				// setTimeout(() => this.stop(), 2000);

				this.stopAttempts = 0;
			} catch (ex) {
				this.stopAttempts += 1;
				if (ex) {
					console.warn(`Error stopping GPS:`, ex);
				}
				// Try to stop again
				if (this.stopAttempts < 10) {
					console.warn(`Error stopping GPS, trying again shortly`);
					this.stopTid = setTimeout(() => this.stop(), 1000);
				}
			}
		}
	};

	startWatching = async () => {
		await this.startWatchingBrowserLocation();

		if (Capacitor.getPlatform() !== 'web') {
			// Start streaming GPS updates right away
			// https://transistorsoft.github.io/capacitor-background-geolocation/classes/backgroundgeolocation.html#changepace
			try {
				await BackgroundGeolocation.changePace(true).catch((ex) => {
					if (ex) {
						console.warn(`Error changing BackgroundGeolocation pace:`, ex);
					}
				});
			} catch (ex) {
				console.error(
					`Uncaught Error changing BackgroundGeolocation pace:`,
					ex,
				);
			}
		}
	};

	stopWatching = async () => {
		await this.stopWatchingBrowserLocation();

		if (Capacitor.getPlatform() !== 'web') {
			try {
				await BackgroundGeolocation.changePace(false).catch((ex) => {
					if (ex) {
						console.warn(`Error changing BackgroundGeolocation pace:`, ex);
					}
				});
			} catch (ex) {
				console.error(
					`Uncaught Error changing BackgroundGeolocation pace:`,
					ex,
				);
			}
		}
	};

	hasUserConsent = () => {
		const consentStatus = window.localStorage.getItem(CONSENT_KEY);

		if (consentStatus === 'no') {
			return false;
		}

		if (consentStatus === 'yes') {
			return true;
		}

		return undefined;
	};

	getUserConsent = async () => {
		const consent = this.hasUserConsent();
		if (consent !== undefined) {
			return consent;
		}

		await this.onPermissionNeeded();

		if (!this._started) {
			console.log(`Got onPermissionNeeded but not started`);
			// Could have been stopped while waiting
			return undefined;
		}

		console.log(`getUserConsent true path`);

		// onPermissionNeeded ONLY returns IF the user clicks Allow.
		// If they click NO, it never resolves.
		// So...we can safely set this to yes...
		this.setConsentFlag(true);
		return true;
	};

	setConsentFlag(consent) {
		window.localStorage.setItem(CONSENT_KEY, consent ? 'yes' : 'no');
	}

	// eslint-disable-next-line class-methods-use-this
	resetConsentValue() {
		window.localStorage.removeItem(CONSENT_KEY);
	}

	// Used first time we need GPS to inform user as to WHY we want GPS
	onPermissionNeeded = async () => {
		// console.log(`GpsLogger.onPermissionNeeded: mark1`);

		// The app could re-define this callback ...
		if (this.permissionNeededCallback) {
			// console.log(
			// 	`GpsLogger.onPermissionNeeded: mark2 - has callback`,
			// 	this.permissionNeededCallback,
			// );

			const result = await this.permissionNeededCallback();

			// console.log(
			// 	`GpsLogger.onPermissionNeeded: mark3 - callback result`,
			// 	result,
			// );

			if (result) {
				// console.log(`GpsLogger.onPermissionNeeded: mark4 - return true`);
				// Only return (allow request) if permissionNeededCallback returns true,
				// if returns a falsy value, don't return and delay via defer() (possibly forever)
				return true;
			}
		}

		if (this._permission) {
			// console.log(
			// 	`GpsLogger.onPermissionNeeded: mark5 - has this._permission promise`,
			// );
			return this._permission;
		}

		this._permission = defer();
		// console.log(
		// 	`GpsLogger.onPermissionNeeded: mark6 - create _permission promise`,
		// );

		// The getUserConsent() function will await this function,
		// so block until this deferred promise is resolved
		return this._permission;
	};

	// At some point, our game can check if permission is needed,
	// show a UI prompt, and if user agrees, then it will call permissionGiven() ...
	async ifPermissionNeeded(callback) {
		// We have not yet came online and tried to get permission, so store callback
		if (!this._permission && callback) {
			// console.warn(
			// 	`GpsLogger.ifPermissionNeeded: mark1 - no this._permission, storing callback`,
			// );
			this.permissionNeededCallback = callback;
		}

		// We've already tried to get permission,
		// so execute callback and resolve deferred promise (if callback returns true)
		if (this._permission && callback) {
			// console.log(
			// 	`GpsLogger.ifPermissionNeeded: mark2 - have this._permission, calling callback`,
			// );
			const result = await callback();

			// console.log(`GpsLogger.ifPermissionNeeded: mark3 - got result`, result);

			if (result) {
				this.permissionGiven();
				return false;
			}
		}

		return true;
	}

	// This will resolve the blocking promise given in onPermissionNeeded().
	permissionGiven() {
		console.log(`GpsLogger.permissionGiven: resolving this._permission`);
		this._permission.resolve();
		this._permission = null;
	}

	async connectNativeLogger() {
		this.nativeError = null;

		if (!BackgroundGeolocation) {
			console.warn(
				"[connectNativeLogger] BackgroundGeolocation not found, using browser logger instead, but won't work in background probably",
			);
			return this.connectBrowserLogger();
		}

		const { token, userId } = GpsStreamTransmitter;
		if (!token) {
			console.warn(
				`[connectNativeLogger] Refusing to connect native logger because no token set on GpsStreamTransmitter so probably not authenticated to backend yet, and we wouldn't be able to store GPS without a token in the background so just stopping here. Besides, we'll reconnect once authorized anyway, so, cheers!`,
			);
			return undefined;
		}

		// Remove any listeners already attached (say, from previous restarts)
		unsubscribeNativeListeners();

		const appVer = AppConfig.version;
		const appPlatform = Capacitor.getPlatform();

		const { deviceClass, appType } = await DeviceInfo.getDeviceInfo();
		const sensor = `${deviceClass}.${appType}`;
		const startPromise = defer();

		const { apiHost, tcpPort } = AppConfig;

		let ip = apiHost;
		if (ip.includes(`localhost:${tcpPort}`)) {
			ip = `${window.location.hostname}:${tcpPort}`;
		}

		const isNgrok = window.location.hostname.includes(`.ngrok.io`);
		if (isNgrok) {
			ip = AppConfig.backendTunnelHost;
		}

		// console.warn(`createSocket:`, { ip, isNgrok, apiHost, tcpPort });

		// Prod uses nginx frontend to handle SSL, dev doesn't
		const proto = AppConfig.buildEnv === 'prod' || isNgrok ? 'https' : 'http';
		const urlRoot = `${proto}://${ip}`;

		// This handler fires whenever bgGeo receives a location update.
		trackSubscription(
			BackgroundGeolocation.onLocation(
				(location) => {
					const { uuid, sample } = location || {};

					if (sample) {
						// Ignore samples
						// Ref: https://transistorsoft.github.io/capacitor-background-geolocation/interfaces/location.html#sample
						// From docs: [The 'sample' prop is] true if the plugin is currently waiting for the best possible location to arrive. Samples are recorded when the plugin is transitioning between motion-states (moving vs stationary) or BackgroundGeolocation.getCurrentPosition. If you're manually posting location to your server, you should not persist these "samples".
						return;
					}

					if (uuid && uuidSeenCache[uuid]) {
						// Seeing LOTS of duplicates on iOS for some reason
						// Just drop until issue solved
						// Ref: https://github.com/transistorsoft/capacitor-background-geolocation/issues/38
						return;
					}

					// Store timestamp for later cache clearing (if needed)
					uuidSeenCache[uuid] = Date.now();

					this.foregroundNativeLastTimestamp = Date.now();
					this.clearErrorState();

					const normalPacket = normalizeNativeLocation(location);

					const storageProps = {
						...normalPacket,
						sensorType: 'native',
						appVer,
						appPlatform,
					};

					console.log(
						'[BackgroundGeolocation.onLocation]',
						location,
						storageProps,
					);

					// console.log(`New location live from BackgroundGeolocation:`, {
					// 	lat,
					// 	lng,
					// });

					this.logNormalLocation(storageProps, 'native');

					// ServerStore.StoreGpsLocation(storageProps, {
					// 	datadogDoNotTransport: true,
					// }).catch((ex) => console.warn(ex));
				},
				(error) => {
					// <-- Location errors
					console.log('[BackgroundGeolocation.onLocation] ERROR:', error);
					this.logNativeGpsError(error);
				},
			),
		);

		// // This handler fires when movement states changes (stationary->moving; moving->stationary)
		// BackgroundGeolocation.onMotionChange((location) => {
		// 	console.log('[BackgroundGeolocation.onMotionChange]', location);
		// });

		// // This event fires when a change in motion activity is detected
		// BackgroundGeolocation.onActivityChange((event) => {
		// 	console.log('[BackgroundGeolocation.onActivityChange]', event);
		// });

		// // This event fires when the user toggles location-services authorization
		// BackgroundGeolocation.onProviderChange((event) => {
		// 	console.log('[BackgroundGeolocation.onProviderChange]', event);
		// });

		// //
		// 2.  Execute #ready method (required)
		//
		let bgConfig;

		const gpsWorkerPostUrl = `${GpsStreamTransmitter.reflectPathForUser(
			userId,
		)}/store`;

		console.info(
			`Connecting Native GPS Logger with GPS Worker Path: ${gpsWorkerPostUrl}`,
		);

		// .ready above set initial config, this just set the dynamic props
		BackgroundGeolocation.setConfig(
			(bgConfig = {
				...baseNativeConfig,
				extras: {
					// <-- Optional meta-data appended to each recorded location.
					sensor,
					// For correlating on the server to a given device and via datadog
					clientTransactionId: `${transactionPrefix}.native`,
				},
				url: gpsWorkerPostUrl, // `${urlRoot}/api/v1/store-gps-location`,
				// syncUrl: `${server.urlRoot}/api/v1/store-gps-location`,
				// syncThreshold: 1,
				headers: {
					// We should have token by now because
					Authorization: token,
				},
			}),
		)
			.then(async (state) => {
				console.log(`Received background GPS state after config start:`, state);

				console.log(
					'BackgroundGeolocation is configured and ready to use ',
					state.enabled,
					urlRoot,
					bgConfig,
				);

				if (!state.enabled) {
					// //
					// 3. Start tracking!
					//
					await BackgroundGeolocation.start()
						.then(() => {
							console.log('[start] success');
						})
						.catch((ex) => {
							console.error(
								`[start] Error calling BackgroundGeolocation.start:`,
								{
									message: ex.message,
									originalException: ex,
									...ex,
								},
							);
						});
				}

				const normalPacket = await this.requestNewLocationReading();

				startPromise.resolve({
					location: normalPacket,
				});
			})
			.catch((error) => {
				console.warn('[BackgroundGeolocation ready] ERROR: ', error);
				this.logNativeGpsError(error);
				startPromise.reject(error);
			});

		return startPromise;
	}

	// Update 20230905 for valet stuff
	// Changing auth req from 'Always' to 'WhenInUse' so we start more gently.
	// Then when we need live tracking, we can then request 'Always' later
	// Docs: https://transistorsoft.github.io/capacitor-background-geolocation/interfaces/config.html#locationauthorizationrequest
	upgradeToAlwaysAllow() {
		// Simply update `locationAuthorizationRequest` to "Always" -- the SDK
		// will cause iOS to immediately show the authorization upgrade dialog
		// for "Change to Always Allow":
		BackgroundGeolocation.setConfig({
			locationAuthorizationRequest: 'Always',
		});
	}

	async logNativeGpsError(error) {
		if (error === undefined) {
			return;
		}
		this.foregroundNativeLastTimestamp = null;

		// From https://transistorsoft.github.io/capacitor-background-geolocation/index.html#locationerror
		const nativeErrorCodes = {
			0: `Location unknown`,
			1: `Location permission denied`,
			2: `Network error`,
			408: `Location timeout`,
			499: `Location request cancelled`,
		};
		const nativeErrorMsg =
			nativeErrorCodes[error] || error || 'Unknown Error Code';
		console.log(`logNativeGpsError:`, { rawError: error, nativeErrorMsg });

		this.loggerInvalid(
			`Unable to connect to GPS on your phone (${nativeErrorMsg})`,
			{
				error,
				message: nativeErrorMsg,
			},
		);

		this.nativeError = { error, message: nativeErrorMsg };
	}

	// eslint-disable-next-line class-methods-use-this
	async stopNativeLogger() {
		unsubscribeNativeListeners();
		this.foregroundNativeLastTimestamp = null;
		return BackgroundGeolocation.stop();
	}

	async connectBrowserLogger() {
		this._browserLoggerStartPromise = defer();
		if (window.location.protocol !== 'https:') {
			if (GpsLogger.verboseLogging) {
				console.log(
					'[GpsLogger.connectBrowserLogger] Not HTTPS, not geolocating',
				);
			}

			if (Capacitor.getPlatform() === 'web' && !this.loggedBrowserHttpsError) {
				this.loggedBrowserHttpsError = true;

				this.loggerInvalid('Not running over HTTPS', {
					error: 'synthetic-https-error',
					message: 'Not HTTPS',
					url: window.location.href,
				});
			}

			return { error: 'Not HTTPS' };
		}

		this.browserLoggerStarted = true;
		if (GpsLogger.verboseLogging) {
			console.log(`connectBrowserLogger: Starting browser geolocation logging`);
		}

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				this.startWatchingBrowserLocation,
				this.browserLocationError,
			);
		} else if (Capacitor.getPlatform() === 'web') {
			if (window.location.protocol === 'https:')
				console.error(
					"[GpsLogger.connectBrowserLogger] Running HTTPS but your browser does not appear support Geolocation, won't log current position",
				);
			else
				console.warn(
					"[GpsLogger.connectBrowserLogger] Not running HTTPS and didn't get the geolocation API, so geolocation won't work",
				);
		}

		// Set a failsafe timeout on the promise so we return quickly
		setTimeout(() => {
			if (this._browserLoggerStartPromise) {
				this._browserLoggerStartPromise.resolve();
				this._browserLoggerStartPromise = null;
			}
		}, 200);

		return this._browserLoggerStartPromise;
	}

	async inspectGpsAccess() {
		// Already have a location this session? Obviously we have GPS access
		if (this.latestNormalLocation) {
			return 'granted';
		}

		if (Capacitor.getPlatform() === 'web') {
			// 'state' will be one one of: 'granted', 'prompt', 'denied'
			// See: https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API/Using_the_Permissions_API
			const { state } = await navigator.permissions.query({
				name: 'geolocation',
			});

			return state;
		}

		const event = await BackgroundGeolocation.getProviderState();
		console.log('BackgroundGeolocation.getProviderState: ', event);

		switch (event.status) {
			case BackgroundGeolocation.AUTHORIZATION_STATUS_WHEN_IN_USE:
			case BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS:
				return 'granted';

			case BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED:
			default:
				return 'denied';
		}
	}

	async requestNewLocationReading() {
		let hasError = null;
		let location;
		if (Capacitor.getPlatform() === 'web') {
			location = await new Promise((resolve) =>
				navigator.geolocation.getCurrentPosition(resolve, (error) => {
					hasError = error;
					this.browserLocationError(error);
					resolve(undefined);
				}),
			);
			this.browserLocationLastTimestamp = Date.now();
		} else {
			location = await BackgroundGeolocation.getCurrentPosition({
				timeout: 30, // 30 second timeout to fetch location
				maximumAge: 5000, // Accept the last-known-location if not older than 5000 ms.
				desiredAccuracy: 10, // Try to fetch a location with an accuracy of `10` meters.
				samples: 3, // How many location samples to attempt.
			}).catch((error) => {
				hasError = error;
				this.logNativeGpsError(error);
			});
			this.foregroundNativeLastTimestamp = Date.now();
		}

		if (!location || hasError) {
			console.warn(`requestNewLocationReading: No location given from device`);
			if (this._browserLoggerStartPromise) {
				this._browserLoggerStartPromise.reject(
					'requestNewLocationReading: No location given from device',
				);
			}
			// console.log(`hasError`, hasError, { hasError, type: typeof hasError });
			return hasError instanceof global.GeolocationPositionError
				? new Error(hasError.message)
				: new Error(`${hasError}` || 'No location given');
		}

		const normalPacket = normalizeNativeLocation(location);

		this.logNormalLocation(
			{
				timestamp: Date.now(),
				...normalPacket,
			},
			'native',
		);

		this.clearErrorState();

		return normalPacket;
	}

	startWatchingBrowserLocation = (position) => {
		if (!this.browserLoggerStarted) {
			if (GpsLogger.verboseLogging) {
				console.warn(
					`startWatchingBrowserLocation got response but this.browserLoggerStarted is not true, returning`,
				);
			}
			return;
		}

		// Clear out any previous watchers
		this.stopWatchingBrowserLocation();

		// Reset this flag since we clearly do want to start
		this.browserLoggerStarted = true;

		if (position) {
			console.log(
				`startWatchingBrowserLocation received initial position`,
				position,
			);
			this.browserLocationReceived(position);
		}

		this.watchTid = navigator.geolocation.watchPosition(
			this.browserLocationReceived,
			this.browserLocationError,
			{ enableHighAccuracy: true, maximumAge: 1000, timeout: 60000 },
		);

		// console.log(`startWatchingBrowserLocation started watchTid`, this.watchTid);
	};

	stopWatchingBrowserLocation() {
		if (this.watchTid) {
			navigator.geolocation.clearWatch(this.watchTid);
			this.watchTid = null;
		}

		// console.warn(`GpsLogger stopWatchingBrowserLocation called`);

		this.browserLoggerStarted = false;
		// this.loggedBrowserHttpsError = false;
	}

	logNormalLocation(normalPacket, sensorType) {
		// Store for checking by gps audit in inspectGpsAccess
		this.latestNormalLocation = { timestamp: Date.now(), ...normalPacket };

		this.emit(GpsLogger.LOCATION_CHANGED, normalPacket);
		// console.log(`GpsLogger.logNormalLocation: emitting packet`, normalPacket);

		GpsStreamTransmitter.storeGpsLocation({
			...normalPacket,
			sensorType,
		}).catch((ex) => console.warn(ex));
	}

	browserLocationReceived = (position) => {
		// console.log(`browserLocationReceived:`, position);
		if (!position) {
			return undefined;
		}

		const normalPacket = normalizeNativeLocation(position);

		// const [lat, lng, accuracy] = [
		// 	position.coords.latitude,
		// 	position.coords.longitude,
		// 	position.coords.accuracy,
		// ];

		// console.log(`browserLocationReceived:`, normalPacket);

		this.browserLocationLastTimestamp = Date.now();
		this.clearErrorState();

		if (this._browserLoggerStartPromise) {
			console.log(
				'[GpsLogger.browserLocationReceived] starting location',
				normalPacket,
			);

			this._browserLoggerStartPromise.resolve({
				location: normalPacket,
			});

			this._browserLoggerStartPromise = null;
		}

		if (
			Date.now() - this.foregroundNativeLastTimestamp <
			MIN_NATIVE_BROWSER_OVERLAP
		) {
			console.log(
				`* Got browser location within ${MIN_NATIVE_BROWSER_OVERLAP} ms of native, not logging`,
			);
		} else {
			this.logNormalLocation(normalPacket, 'browser');
		}

		return normalPacket;
	};

	browserLocationError = (error) => {
		this.browserLocationLastTimestamp = null;
		console.warn('[GpsLogger.browserLocationError]', error);
		if (this.foregroundNativeLastTimestamp) {
			const delta = (Date.now() - this.foregroundNativeLastTimestamp) / 1000;
			console.warn(
				`GpsLogger not setting error state due to browser location error because we've received a Native location from the GPS plugin, so browser errors are acceptable. (Last native location was ${delta} sec ago)`,
			);
			return;
		}

		const message = error && error.message ? error.message : error;
		this.loggerInvalid(
			`Unable to connect to your GPS ${message ? `(${message})` : ''}`,
			{
				error,
				message: error && error.message,
			},
		);

		if (this._browserLoggerStartPromise) {
			this._browserLoggerStartPromise.resolve({ error });
			this._browserLoggerStartPromise = null;
		}
	};

	async loggerInvalid(message, context) {
		if (
			`${message}`
				.toLocaleLowerCase()
				.includes(
					'geolocation has been disabled in this document by permissions policy',
				)
		) {
			// Iframe policy error, ignore
			return;
		}

		if (GpsLogger.verboseLogging) {
			console.log(`GpsLogger reached invalid state`, { message, context });
		}

		ServerStore.AuditLog(
			`gps.logger.error`,
			`Client Encountered GPS Error`,
			message,
			{
				...context,
				logSeverity: 'ERROR',
			},
		);

		if (this.onErrorState) {
			this.onErrorState(message, context);
		}

		this.emit(GpsLogger.ERROR_STATE_CHANGED, { error: { message, context } });

		this.hasErrorState = { message, context };
	}

	async clearErrorState() {
		if (this.hasErrorState) {
			this.hasErrorState = null;

			this.emit(GpsLogger.ERROR_STATE_CHANGED, { error: null });

			if (this.onErrorStateCleared) {
				this.onErrorStateCleared();
			}
		}
	}

	onLoggerInvalidated(callback) {
		this.onErrorState = callback;
	}

	onLoggerRestored(callback) {
		this.onErrorStateCleared = callback;
	}

	startGpsReplay(delay = 1000 / 4) {
		// this.wasStarted = this._started;
		// this.stop();

		if (this.replayTimer) {
			clearInterval(this.replayTimer);
		} else {
			this.gpsReplayIndex = 0;
		}

		console.log(
			`startGpsReplay: Starting replay of ${gpsReplayData.length} points at index ${this.gpsReplayIndex}...`,
		);

		this.replayTimer = setInterval(() => {
			this.tickGpsReplay();
		}, delay);
	}

	tickGpsReplay() {
		this.gpsReplayIndex++;
		if (this.gpsReplayIndex >= gpsReplayData.length) {
			console.warn(`Reached the end of the GPS replay data, stopping replay`, {
				index: this.gpsReplayIndex,
			});
			this.stopGpsReplay();
			return;
		}

		const currentPoint = gpsReplayData[this.gpsReplayIndex];

		const {
			timestamp,
			lat,
			lng,
			speed,
			heading,
			altitude,
			sensor,
			batteryLevel,
			accuracy = 5,
		} = currentPoint || {};

		console.log(`tickGpsReplay[${this.gpsReplayIndex}]`, currentPoint);

		this.logNormalLocation(
			{
				timestamp: Date.now(),
				lat,
				lng,
				accuracy,
				speed,
			},
			'browser.gps-replay',
		);
	}

	stopGpsReplay() {
		clearInterval(this.replayTimer);
		delete this.replayTimer;
		console.log(`stopGpsReplay: stopped.`);

		// if (this.wasStarted) {
		// 	this.start();
		// 	delete this.wasStarted;
		// }
	}
}

GpsLogger.verboseLogging = AppConfig.buildEnv !== 'dev';

// Start the logger
GpsLogger.instance = new GpsLogger();
if (global.window) {
	global.window.GpsLogger = GpsLogger.instance;
	global.window.BackgroundGeolocation = BackgroundGeolocation;
}
