import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

export default function SimpleSpinner({
	fullPage = false,

	style = {},
	className = '',
}) {
	const fp = {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		flexDirection: 'row',
		minHeight: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		color: 'white',
		zIndex: 2999,
	};

	const WrapIcon = ({ children }) => {
		if (fullPage) {
			return (
				<Backdrop open={true} style={{ zIndex: 2998 }}>
					{children}
				</Backdrop>
			);
		}
		return children;
	};

	return (
		<div
			className={className}
			style={{
				color: 'black',
				...(style || {}),
				...(fullPage ? fp : {}),
			}}
		>
			<WrapIcon>
				<CircularProgress />
			</WrapIcon>
		</div>
	);
}
