import { useEffect } from 'react';
import { iOS, iMac } from 'shared/utils/iOS';

export function useRootHelper() {
	return useEffect(() => {
		if (iOS && !iMac) {
			return () => {};
		}

		const root = document.getElementById('root');

		const resize = () => {
			root.style.height = `${window.innerHeight}px`;
		};
		resize();
		window.addEventListener('resize', resize);

		return () => window.removeEventListener('resize', resize);
	});
}
