/* eslint-disable no-nested-ternary */
import clsx from 'clsx';
import AppButton from 'shared/components/AppButton';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/TimeToLeave';
import UndoIcon from '@material-ui/icons/Undo';
import {
	IconButton,
	ButtonBase,
	FormControl,
	InputLabel,
	FormControlLabel,
	CircularProgress,
} from '@material-ui/core';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import { toast } from 'shared/components/SharedToast';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { useState, useMemo, useCallback } from 'react';
import { useAuthRequired } from 'shared/services/BackendService';

import SupportChatButton from 'shared/components/SupportChat/SupportChatButton';

import { formatSimpleLocalTime } from 'shared/utils/formatSimpleLocalTime';

import MemberTripProvider, {
	useMemberTripContext,
} from 'shared/services/TripComposerContext';
import {
	calculateTripPriceSummary,
	convertPickupDropoffTimezones,
} from 'shared/utils/tripDisplayUtils';

import { ConversationPanelProvider } from 'shared/components/ConversationPanel/ConversationPanel';
import SimpleSpinner from 'shared/components/SimpleSpinner';
import styles from './BookingPage.module.scss';
import PlaceSelectDialog from './PlaceSelectDialog';
import DrawerMenuButton from './DrawerMenuButton';
import PartnerPageWrapper from './PartnerPageWrapper';
import PersonSelectDialog from './PersonSelectDialog';
import TripListProvider from './TripListProvider';
import TripCountSummary from './TripCountSummary';

function BookingPageContent() {
	const navigate = useNavigate();

	const {
		userOverrideName,
		currentTripLoading,
		currentTrip,
		currentTrip: {
			id: tripId,
			estimatedPickupWaitTimeMins: waitTimeMins,
			estimatedMemberCost,
			dropoffPlace,
			pickupPlace,
			scheduledPickupAt,
			isScheduled,

			cannotDispatch,
			cannotDispatchReason,
		} = {},

		updateScheduledConfig,

		setPlaceField,
		setExplicitUserId,
		explicitUserId,

		clearCurrentTrip,
		confirmRideRequest,

		swapDropoffPoints,
	} = useMemberTripContext();

	// console.log(`dispatch status`, {
	// 	cannotDispatch,
	// 	cannotDispatchReason,
	// });

	const { estimatedMemberCostPreSubscription } = useMemo(
		() => calculateTripPriceSummary(currentTrip, { debug: false }),
		[currentTrip],
	);

	const {
		scheduledPickup,
		convertedDropoffTime,
		pickupTimezoneDisplay,
		dropoffTimezoneDisplay,
	} = convertPickupDropoffTimezones(currentTrip);

	const [member, setMemberState] = useState();
	const setMember = useCallback(
		(user) => {
			setExplicitUserId(user ? user.id : undefined);
			setMemberState(user);
		},
		[setExplicitUserId],
	);

	const setPickupTime = useCallback(
		(value) => {
			const date = new Date(value);
			if (!value || Number.isNaN(date.getTime())) {
				updateScheduledConfig({
					isScheduled: false,
					scheduledPickupAt: null,
				});
			} else {
				updateScheduledConfig({
					isScheduled: !!value,
					scheduledPickupAt: date,
				});
			}
		},
		[updateScheduledConfig],
	);

	// console.log(`schedule debug`, {
	// 	scheduledPickupAt,
	// 	isScheduled,
	// 	scheduledPickup,
	// 	convertedPickupTime,
	// 	pickupTimezoneDisplay,
	// });

	// eslint-disable-next-line no-nested-ternary
	const pickupTimeDisplayText = !isScheduled
		? 'Now'
		: scheduledPickup
		? scheduledPickup.time
		: 'Loading...';

	const [placeDialogOpen, setPlaceDialogOpen] = useState();
	const [placeDialogType, setPlaceDialogTye] = useState('start');
	const [memberDialogOpen, setMemberDialogOpen] = useState();

	// Generate a list of the next 24 hours in 30 minute increments, starting from
	// now (at the next 30 minute mark)
	const timeOptions = useMemo(() => {
		const minuteGrid = 30;
		const now = Date.now();
		const nowInMinutes = Math.round(now / 1000 / 60);
		const roundMins = (mins) =>
			Math.round(
				parseFloat(
					(Math.ceil(mins / minuteGrid) * minuteGrid).toFixed(2),
				),
			);

		const nowStart = roundMins(nowInMinutes);
		const options = [];
		const maxHoursFromNowAsMinutes = 24 * 60;
		for (
			let i = nowStart;
			i < nowStart + maxHoursFromNowAsMinutes;
			i += minuteGrid
		) {
			const d = new Date(i * 60 * 1000);

			options.push({
				value: d,
				text: `${d.toLocaleTimeString()}`.replace(/:00\s/, ' '),
			});
		}

		return [{ value: 'now', text: 'Now' }, ...options];
	}, []);

	// Holds the anchor for the time menu
	const [anchorEl, setAnchorEl] = useState(null);

	// Computes the proper "current selected menu item" based on trip  time
	const selectedTimeIndex = useMemo(
		() =>
			Math.max(
				0,
				timeOptions.indexOf(
					timeOptions.find(
						(x) => x.text === pickupTimeDisplayText,
					),
				) || 0,
			),
		[pickupTimeDisplayText, timeOptions],
	);

	// Opens the time menu by actually setting the anchor el
	const openTimeMenu = useCallback((event) => {
		setAnchorEl(event.currentTarget);
	}, []);

	// Handles clicks on the menu by updating the trip using our helper above
	const onTimeMenuClicked = useCallback(
		(event, index) => {
			setPickupTime(timeOptions[index].value);
			setAnchorEl(null);
		},
		[setPickupTime, timeOptions],
	);

	// Close the menu without changing time
	const onTimeMenuClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const confirmBooking = useCallback(async () => {
		const result = await confirmRideRequest();
		// eslint-disable-next-line no-console
		console.log(`confirm result`, result);
		const url = `/trip/${tripId}`;
		toast.success(`Ride booked!`);
		navigate(url);
	}, [confirmRideRequest, navigate, tripId]);

	return (
		<>
			<PlaceSelectDialog
				forUserId={explicitUserId}
				open={placeDialogOpen}
				placeholder={
					placeDialogType === 'pickup'
						? `Select pickup location`
						: `Select dropoff location`
				}
				onSelect={(place) => {
					// console.log(`selected:`, place);
					if (placeDialogType === 'pickup') {
						setPlaceField('start', place);
					} else {
						setPlaceField('dropoff', place);
					}
					setPlaceDialogOpen(false);
				}}
				onCancel={() => setPlaceDialogOpen(false)}
			/>
			<PersonSelectDialog
				open={memberDialogOpen}
				onSelect={(person) => {
					setMember(person);
					setMemberDialogOpen(false);
					toast.info(`Selected ${person.name}`);
				}}
				onCancel={() => setMemberDialogOpen(false)}
			/>
			<form className={styles.root}>
				<div className={styles.dropoffLocation}>
					<FormControl>
						<ButtonBase
							onClick={() => {
								setMemberDialogOpen(true);
							}}
						>
							<InputLabel shrink={!!member}>
								{member ? 'Member' : 'Choose Member'}
							</InputLabel>
							<FormControlLabel
								label={
									userOverrideName === null ? (
										<i>Loading...</i>
									) : (
										member && member.name
									)
								}
								control={<></>}
							/>
						</ButtonBase>
					</FormControl>
				</div>
				<div className={styles.pickupLocation}>
					<FormControl>
						<ButtonBase
							onClick={() => {
								setPlaceDialogTye('pickup');
								setPlaceDialogOpen(true);
							}}
						>
							<InputLabel shrink={!!pickupPlace}>
								{pickupPlace
									? 'Pickup'
									: 'Pickup Location'}
							</InputLabel>
							<FormControlLabel
								label={pickupPlace && pickupPlace.name}
								control={<></>}
							/>
						</ButtonBase>
					</FormControl>
				</div>
				<div className={styles.dropoffLocation}>
					<FormControl>
						<ButtonBase
							onClick={() => {
								setPlaceDialogTye('dropoff');
								setPlaceDialogOpen(true);
							}}
						>
							<InputLabel shrink={!!dropoffPlace}>
								{dropoffPlace
									? 'Dropoff'
									: 'Dropoff Location'}
							</InputLabel>
							<FormControlLabel
								label={dropoffPlace && dropoffPlace.name}
								control={<></>}
							/>
						</ButtonBase>
					</FormControl>

					{pickupPlace && dropoffPlace && tripId && (
						<IconButton
							className={clsx(
								styles.iconButton,
								styles.swapButton,
							)}
							aria-label="swap places"
							onClick={() => swapDropoffPoints('dropoff')}
						>
							<SwapVertIcon />
						</IconButton>
					)}
				</div>
				<div className={styles.chooseTime}>
					<FormControl>
						<ButtonBase onClick={openTimeMenu}>
							<InputLabel shrink>Pickup Time</InputLabel>
							<FormControlLabel
								label={
									pickupTimeDisplayText +
									(pickupTimezoneDisplay
										? ` (${pickupTimezoneDisplay})`
										: '')
								}
								control={<></>}
							/>
						</ButtonBase>
					</FormControl>

					<Menu
						id="lock-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={onTimeMenuClose}
					>
						{(timeOptions || []).map(
							({ value, text }, index) => (
								<MenuItem
									key={value}
									selected={index === selectedTimeIndex}
									onClick={(event) =>
										onTimeMenuClicked(event, index)
									}
								>
									{text}
								</MenuItem>
							),
						)}
					</Menu>
				</div>
				<div className={styles.quoteDisplay}>
					{tripId && !currentTripLoading ? (
						cannotDispatch ? (
							<div
								className={clsx(
									styles.wrap,
									styles.cannotDispatch,
								)}
							>
								<h3 className={styles.title}>
									{`${cannotDispatchReason}`.includes(
										'nearby drivers',
									)
										? `No drivers near ${pickupPlace.name}`
										: cannotDispatchReason}
								</h3>
							</div>
						) : (
							<div className={styles.wrap}>
								<h3 className={styles.dollar}>
									{tripId ? (
										<>
											$
											{Math.round(
												estimatedMemberCost ||
													estimatedMemberCostPreSubscription ||
													0,
											)}
										</>
									) : (
										''
									)}
									<label>Est Price</label>
								</h3>
								<h1 className={styles.time}>
									{waitTimeMins ? (
										<span className={styles.value}>
											{waitTimeMins || ''}{' '}
											<span
												className={styles.suffix}
											>
												min
											</span>
										</span>
									) : (
										''
									)}
									<label>Time till Arrival</label>
								</h1>
								<h3 className={styles.time}>
									{tripId ? (
										<>
											{formatSimpleLocalTime(
												convertedDropoffTime ||
													new Date(),
											)}
											{dropoffTimezoneDisplay
												? ` (${dropoffTimezoneDisplay})`
												: ''}
										</>
									) : (
										''
									)}

									<label>Arrival at Dropoff</label>
								</h3>
							</div>
						)
					) : (
						<div
							className={clsx(
								styles.wrap,
								styles.emptyQuoteState,
							)}
						>
							<h3 className={styles.title}>
								{currentTripLoading ? (
									<CircularProgress />
								) : (
									<>
										Fill in the form above to see
										estimated time and cost
									</>
								)}
							</h3>
						</div>
					)}
				</div>
				{tripId && !cannotDispatch && (
					<div className={styles.ctaButtons}>
						<AppButton onClick={confirmBooking}>
							<CheckIcon />
							Confirm Ride
						</AppButton>

						<AppButton
							onClick={clearCurrentTrip}
							isSecondaryCta
						>
							<UndoIcon />
							Cancel
						</AppButton>
					</div>
				)}
			</form>
		</>
	);
}

export default function BookingPage() {
	const isAuthorized = useAuthRequired();
	if (!isAuthorized) {
		// eslint-disable-next-line no-console
		console.warn(
			`Booking page NOT authorized, not rendering, waiting for redirect from BackendService hook`,
		);
		return <SimpleSpinner fullPage={true} />;
	}

	return (
		<TripListProvider>
			<ConversationPanelProvider>
				<MemberTripProvider
					requireExplicitUser={true}
					onlyNonActive={true}
				>
					<PartnerPageWrapper
						className={styles.wrapper}
						pageTitleText={'Request Rides for Members'}
					>
						<DrawerMenuButton alwaysVisible={true} />
						<TripCountSummary />
						<BookingPageContent />
						<SupportChatButton lightMode={true} />
					</PartnerPageWrapper>
				</MemberTripProvider>
			</ConversationPanelProvider>
		</TripListProvider>
	);
}
