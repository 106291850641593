import logger from './IsomorphicLogger';

export function getTimezone() {
	// return 'America/New_York'; // just for testing

	try {
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	} catch (ex) {
		logger.error(
			`Caught exception when trying to get IANA timezone name using Intl framework:`,
			{
				message: ex.message,
				stack: ex.stack,
				originalException: ex,
			},
		);
		return undefined;
	}
}
