/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import {
	FixedSizeList,
	VariableSizeList as VirtualList,
	areEqual,
} from 'react-window';
import memoize from 'memoize-one';
import AutoSizer from 'react-virtualized-auto-sizer';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import Measure from 'react-measure';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import {
	useState,
	useEffect,
	useCallback,
	useRef,
	useMemo,
	memo,
} from 'react';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MagnifyingGlassIcon from '@material-ui/icons/Search';
import BackIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import DirectionsIcon from '@material-ui/icons/Directions';
import ButtonBase from '@material-ui/core/ButtonBase';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { useBreakpoint } from 'shared/utils/breakpoints';
import CustomSkeleton from 'shared/components/CustomSkelton';

import useSWR from 'swr';
import { ServerStore } from 'shared/services/ServerStore';
import { useFunctionalState } from 'shared/components/FunctionalState';
import {
	findParent,
	getAttributeSafe,
	hasAttributeSafe,
} from 'shared/utils/documentEventHelpers';
import use1rem from 'shared/utils/use1rem';
import styles from './GenericSelectDialog.module.scss';
import AlphaLetterBar from './AlphaLetterBar';

function isInView(
	elem,
	container,
	viewportPadding = { top: 0, left: 0, right: 0, bottom: 0 },
) {
	let bounding = elem.getBoundingClientRect();
	const { top = 0, left = 0, bottom = 0, right = 0 } = viewportPadding;
	return (
		bounding.top >= top &&
		bounding.left >= left &&
		bounding.bottom <=
			(container
				? container.clientHeight
				: window.innerHeight ||
				  document.documentElement.clientHeight) -
				bottom &&
		(bounding.right <= container
			? container.clientWidth
			: window.innerWidth || document.documentElement.clientWidth) -
			right
	);
}

function firstChildInView(
	parent,
	viewportPadding = { top: 0, left: 0, right: 0, bottom: 0 },
) {
	const { children = [] } = parent || {};
	for (let i = 0; i < children.length; i++) {
		if (isInView(children[i], parent, viewportPadding)) {
			return children[i];
		}
	}

	return undefined;
}

// If list items are expensive to render,
// Consider using React.memo or shouldComponentUpdate to avoid unnecessary re-renders.
// https://reactjs.org/docs/react-api.html#reactmemo
// https://reactjs.org/docs/react-api.html#reactpurecomponent
const ListRow = memo(({ data, index, style }) => {
	// Data passed to List as "itemData" is available as props.data
	const { items, RenderDataRow, onSelect } = data;
	const item = items[index];

	return (
		<div style={style}>
			<RenderDataRow {...item} onClick={() => onSelect(item)} />
		</div>
	);
}, areEqual);

// This helper function memoizes incoming props,
// To avoid causing unnecessary re-renders pure Row components.
// This is only needed since we are passing multiple props with a wrapper object.
// If we were only passing a single, stable value (e.g. items),
// We could just pass the value directly.
const createListRowData = memoize((items, onSelect, RenderDataRow) => ({
	items,
	onSelect,
	RenderDataRow,
}));

export default function GenericSelectDialog({
	isVisible,
	placeholder = 'Select an item',
	onCancel,
	onSelect,
	rowComponent: RenderDataRow,
	dataQueryFn = (/* queryText */) => {},
	showAlphaTabs = false,
	alphaLabelField = 'name',
}) {
	const [activeRow, setActiveRow] = useState(); // { id: defaultUserId });
	const isSmallScreen = useBreakpoint('small');

	const [searchText, setSearchText] = useState();

	const onBackClick = () => {
		onCancel();
	};

	const onEndButtonClick = () => {
		if (searchText) {
			setSearchText('');
		}
	};
	const [searchTextDebounced] = useDebounce(searchText, 500);

	const {
		data: dataList,
		isValidating,
		error,
	} = useSWR(
		[searchTextDebounced, 'generic-select-dialog', placeholder],
		dataQueryFn,
	);

	const dataLoading = isValidating || dataList === undefined;
	useEffect(() => {
		if (error && !dataLoading) {
			const apiErrors = ServerStore.extractApiErrors(error);
			toast.error(apiErrors ? apiErrors.join(', ') : error);
		}
	}, [dataLoading, error]);

	const rowToLetter = useCallback(
		(row) => {
			const text = row[alphaLabelField || 'name'];
			// console.log(`rowToLetter debug`, {
			// 	row,
			// 	alphaLabelField,
			// 	text,
			// });
			return `${`${text || ''}`.substring(0, 1)}`.toUpperCase();
		},
		[alphaLabelField],
	);

	const [currentLetter, setCurrentLetter] = useState('A');
	const lastSelectedLetter = useRef('A');
	const selectAlphaTab = useCallback(
		(letter) => {
			// todo
			// console.log(`selectAlphaTab`, letter);
			if (lastSelectedLetter.current === letter) {
				return;
			}

			const row = (dataList || []).find(
				(x) => rowToLetter(x) === letter,
			);
			if (!row) {
				console.warn(
					`Could not find row for letter`,
					letter,
					dataList,
				);
				return;
			}

			console.log(`found row for letter ${letter}`, row);

			setActiveRow(row);
		},
		[dataList, rowToLetter],
	);

	const rem1 = use1rem();
	const onListScroll = useCallback(
		(scrollEvent) => {
			const { target } = scrollEvent;
			const list = findParent(target, (node) =>
				`${node.className}`.includes('MuiList-root'),
			);
			if (!list) {
				return;
			}
			const padding = rem1 * 3.625; // List has 3.625rem padding at top;

			const rowAtTop = firstChildInView(list, { top: padding });

			const rowItem = findParent(rowAtTop, (node) =>
				hasAttributeSafe(node, 'data-row-id'),
			);
			const rowId = getAttributeSafe(rowItem, 'data-row-id');
			const row = (dataList || []).find((x) => x.id === rowId) || {};
			const letter = rowToLetter(row || {});

			// console.log(`row at top`, rowId, {
			// 	letter,
			// 	row,
			// 	rowId,
			// 	rowItem,
			// 	rowAtTop,
			// 	list,
			// });

			lastSelectedLetter.current = letter;
			setCurrentLetter(letter);
		},
		[dataList, rem1, rowToLetter],
	);

	// Bundle additional data to list items using the "itemData" prop.
	// It will be accessible to item renderers as props.data.
	// Memoize this data to avoid bypassing shouldComponentUpdate().
	// const itemData = createListRowData(dataList, onSelect, RenderDataRow);

	const itemData = useMemo(
		() => ({
			items: dataList,
			onSelect,
			RenderDataRow,
		}),
		[RenderDataRow, dataList, onSelect],
	);

	// TODO: Add up/down arrow handling later
	return (
		<Dialog
			open={!!isVisible}
			onClose={onCancel}
			fullScreen={!!isSmallScreen}
			className={clsx(
				styles.root,
				isVisible && styles.visible,
				isSmallScreen && styles.isSmallScreen,
			)}
		>
			{/* {isVisible && <Head title={placeholder} />} */}
			<Paper component="form" className={styles.searchRoot}>
				<IconButton
					className={clsx(styles.iconButton, styles.backButton)}
					aria-label="menu"
					onClick={onBackClick}
				>
					<BackIcon />
				</IconButton>
				<InputBase
					className={styles.input}
					placeholder={placeholder}
					inputProps={{
						'aria-label': placeholder,
					}}
					value={searchText || ''}
					onChange={(e) => setSearchText(e.target.value)}
				/>
				<IconButton
					className={styles.iconButton}
					aria-label="search"
					onClick={onEndButtonClick}
				>
					{searchText ? <CloseIcon /> : <MagnifyingGlassIcon />}
				</IconButton>
			</Paper>
			{showAlphaTabs && (
				<AlphaLetterBar
					onChange={selectAlphaTab}
					currentValue={currentLetter}
				/>
			)}
			<List
				className={clsx(
					styles.listRoot,
					showAlphaTabs && styles.showAlphaTabs,
				)}
				onScroll={onListScroll}
			>
				{dataLoading && (
					<>
						<ListItem>
							<ListItemIcon>
								<CustomSkeleton>
									<MagnifyingGlassIcon />
								</CustomSkeleton>
							</ListItemIcon>
							<CustomSkeleton>
								<ListItemText
									primary="Just pick me up"
									secondary="another line"
								/>
							</CustomSkeleton>
						</ListItem>
						<ListItem>
							<ListItemIcon>
								<CustomSkeleton>
									<MagnifyingGlassIcon />
								</CustomSkeleton>
							</ListItemIcon>
							<CustomSkeleton>
								<ListItemText
									primary="Just pick me up a bit longer"
									secondary="another line"
								/>
							</CustomSkeleton>
						</ListItem>
						<ListItem>
							<ListItemIcon>
								<CustomSkeleton>
									<MagnifyingGlassIcon />
								</CustomSkeleton>
							</ListItemIcon>
							<CustomSkeleton>
								<ListItemText
									primary="Just pick me"
									secondary="another line"
								/>
							</CustomSkeleton>
						</ListItem>
					</>
				)}
				{/* {!dataLoading &&
					(dataList || []).map((row) => (
						<ScrollIntoViewIfNeeded
							key={row.id}
							active={activeRow === row}
							className={styles.scrollIntoViewIfNeeded}
							options={{
								block: 'start',
								scrollMode: 'always',
							}}
							data-row-id={row.id}
						>
							<RenderDataRow
								{...row}
								onClick={() => onSelect(row)}
							/>
						</ScrollIntoViewIfNeeded>
					))} */}
				{!dataLoading && (dataList || []).length ? (
					<AutoSizer>
						{({ height, width }) => (
							<FixedSizeList
								height={height + 8}
								itemCount={dataList.length}
								itemData={itemData}
								itemSize={38}
								width={width}
							>
								{ListRow}
							</FixedSizeList>
						)}
					</AutoSizer>
				) : (
					''
				)}
			</List>
		</Dialog>
	);
}
