/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import clsx from 'clsx';
import { useState, useRef, useCallback, forwardRef } from 'react';

import ChatBubbleLeftIcon from '@material-ui/icons/ChatBubble';
import CloseIcon from '@material-ui/icons/Close';

import {
	Tooltip,
	Zoom,
	ButtonBase,
	ClickAwayListener,
} from '@material-ui/core';
import { useBreakpoint } from 'shared/utils/breakpoints';
import { useHistory } from 'react-router-dom';
import history from 'shared/utils/history';
import styles from './SupportChatButton.module.scss';

import SupportChatPopup from './SupportChatPopup';

// const SupportChatPopupRefed = forwardRef((props, ref) => (
// 	<SupportChatPopup {...props} innerRef={ref} />
// ));

export default function SupportChatButton({ lightMode }) {
	const [hasNewMessages, setHasNewMessages] = useState();
	const [isPanelOpen, setIsPanelOpen] = useState(false);

	const onNewMessage = useCallback(() => setHasNewMessages(true), []);

	const openPanel = useCallback((flag) => {
		if (flag) {
			setHasNewMessages(false);
		}
		setIsPanelOpen(flag);
	}, []);

	const isSmall = useBreakpoint('small');

	const toggleConvoPanel = useCallback(() => {
		if (isSmall) {
			history.push('/support-chat');
			return;
		}
		openPanel(!isPanelOpen);
	}, [isPanelOpen, isSmall, openPanel]);

	return (
		<>
			<SupportChatPopup
				position={SupportChatPopup.BottomRight}
				onNewMessage={onNewMessage}
				widgetProps={{ lightMode }}
				isOpen={isPanelOpen}
				onClose={() => openPanel(false)}
			/>
			<Zoom
				in={true}
				timeout={{
					enter: 600,
					exit: 300,
				}}
				unmountOnExit
			>
				<Tooltip title={'Chat with your personal Concierge staff'}>
					<ButtonBase
						className={clsx(
							styles.root,
							hasNewMessages && styles.hasNewMessages,
							// styles.hasNewMessages,
							'SupportChatButton-root',
						)}
						onClick={toggleConvoPanel}
					>
						{isPanelOpen ? (
							<CloseIcon />
						) : (
							<>
								<ChatBubbleLeftIcon />
								<div className={styles.mark}>V</div>
							</>
						)}
					</ButtonBase>
				</Tooltip>
			</Zoom>
		</>
	);
}
