/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import useSWR from 'swr';

import { ServerStore } from 'shared/services/ServerStore';
import AuthService, { useAuthorization } from 'shared/services/AuthService';

import ConversationWidget from 'shared/components/ConversationWidget';

import PulsingCoverLoader from '../PulsingCoverLoader';
import styles from './SupportChatWidget.module.scss';

const useLatestConversation = () => {
	const { user: { id } = {} } = useAuthorization();
	const { data: { conversation } = {}, error } = useSWR(
		[id, 'FindLatestConversation'],
		(userId) => userId && ServerStore.FindLatestConversation(),
	);
	if (error) {
		console.error(`Error loading latest conversation`, {
			error,
			userId: id,
		});
	}
	return { conversation, error };
};

export default function SupportChatWidget({
	lightMode = false,
	className,
	onNewMessage,
	widgetProps,
	hideBackButton = true,
	onBackClicked,
}) {
	const { conversation, conversation: { id: conversationId } = {} } =
		useLatestConversation();

	// const auth = useAuthorization();
	// const { user: { id: currentUserId } = {}, user } = auth || {};
	// // const currentUserId = undefined;

	// console.log(`Rendering with`, {
	// 	currentUserId,
	// 	user,
	// });

	return (
		<div
			className={clsx(styles.root, lightMode && styles.lightMode, className)}
		>
			{/* <h1>{currentUserId || 'Loading...'}</h1>
			<pre>
				<code>{JSON.stringify(conversation || {}, null, 4)}</code>
			</pre> */}
			<PulsingCoverLoader close={!!conversationId} />
			{conversationId && (
				<ConversationWidget
					conversationId={conversationId}
					hideHeader={false}
					titleString={'Vaya Concierge Chat'}
					lightMode={lightMode}
					onNewMessage={onNewMessage}
					hideBackButton={hideBackButton}
					onBackClicked={onBackClicked}
					// hideForegroundNotifications={isPanelOpen}
					disableHead={true}
					channelSelectAllowed={false}
					{...widgetProps}
				/>
			)}
		</div>
	);
}
