import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useDrawerNavContext } from './DrawerNav';
import styles from './DrawerMenuButton.module.scss';

export default function DrawerMenuButton({ className, alwaysVisible }) {
	const { toggleDrawer } = useDrawerNavContext();
	return (
		<div
			className={clsx(
				styles.root,
				className,
				alwaysVisible && styles.alwaysVisible,
			)}
		>
			<Tooltip title="Open side menu">
				<IconButton
					className={clsx(styles.iconButton)}
					aria-label="menu"
					onClick={toggleDrawer}
				>
					<MenuIcon />
				</IconButton>
			</Tooltip>
		</div>
	);
}
