/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useCallback, useRef } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
	List,
	ListItem,
	Typography,
	ClickAwayListener,
} from '@material-ui/core';
import use1rem from 'shared/utils/use1rem';
import BottomPanel, { useWindowRelativeSize } from '../BottomPanel/BottomPanel';
import styles from './ActionPanel.module.scss';

export function ActionPanel({ actions, controlRef }) {
	const canMaximizePanel = false;
	const rem1 = use1rem();

	// Make max open the exact size of the list, since each item is 2rem high + padding,
	// and 1rem padding at top of panel for arrow
	const maxOpenHeight =
		rem1 * 2.5 * (actions ? actions.length : 0) + rem1 * 1.35;

	const panelActionsRef = useRef({});

	const closePanel = useCallback(() => {
		if (panelActionsRef.current && panelActionsRef.current.close) {
			panelActionsRef.current.close();
		}
	}, [panelActionsRef]);

	const actionHelper = (action, ...args) => {
		if (panelActionsRef.current && panelActionsRef.current[action]) {
			// Due to use of ClickAwayListener, have to open AFTER clicking,
			// because otherwise it's closed as soon as opened
			setTimeout(() => panelActionsRef.current[action](...args));
		}
	};

	if (controlRef) {
		// eslint-disable-next-line no-param-reassign
		controlRef.current = {
			open: () => actionHelper('open'),
			close: () => actionHelper('close'),
			toggle: () => actionHelper('toggle'),
		};
	}

	return (
		<div className={styles.root}>
			<ClickAwayListener onClickAway={closePanel}>
				<BottomPanel
					maxOpenHeight={maxOpenHeight}
					className={styles.bottomPanel}
					actionsRef={panelActionsRef}
					// Shadow is 2rem wide, so put ~2rem below bottom
					// of screen to prevent shadow bleed onscreen when closed
					closedPanelSize={-(rem1 * 2.5)}
					clampDragToLimits={false}
					disableDrag={!canMaximizePanel}
					hideDragHandle={!canMaximizePanel}
					dragHandleContent={<ButtonBase />}
				>
					<List dense>
						{actions.map(({ icon, label, onClick, className }) => (
							<ListItem
								key={label}
								button
								onClick={onClick}
								className={className}
							>
								<div className={styles.icon}>{icon}</div>
								<Typography variant="body2">{label}</Typography>
							</ListItem>
						))}
					</List>
				</BottomPanel>
			</ClickAwayListener>
		</div>
	);
}
