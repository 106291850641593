import React from 'react';
import clsx from 'clsx';
import styles from './DataListItem.module.scss';

export function DataListItem({
	primaryText,
	secondaryText,
	primaryLabel,
	secondaryLabel,
	className,
	markerColor = 'white',
	isDateHeader,
	isPickup,
	isDropoff,
	isWaypoint,
}) {
	return (
		<li
			className={clsx(
				styles.dataListItem,
				isDateHeader && styles.dateHeader,
				className,
				isPickup && styles.pickup,
				isWaypoint && styles.waypoint,
				isDropoff && styles.dropoff,
			)}
			style={{ '--marker-color': markerColor }}
		>
			<div className={clsx(styles.place, 'primaryText-group')}>
				<div className={clsx(styles.name, 'primaryText-root')}>
					{primaryText}
				</div>
				<div className={clsx(styles.address, 'secondaryText-root')}>
					{secondaryText}
				</div>
			</div>
			<div className={clsx(styles.label, 'secondaryLabel-group')}>
				<label>{primaryLabel}</label>
				<div className={clsx(styles.time, 'secondaryLabel-root')}>
					{secondaryLabel}
				</div>
			</div>
		</li>
	);
}
