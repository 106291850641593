/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { Capacitor } from '@capacitor/core';
import use1rem from 'shared/utils/use1rem';
import React, {
	useState,
	useEffect,
	useCallback,
	useRef,
	useMemo,
} from 'react';
import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import Tooltip from '@material-ui/core/Tooltip';

import { useEnableManagedViewportHelper } from 'shared/hooks/useEnableManagedViewportHelper';

/* eslint-disable no-unused-vars, no-console */
import {
	GoogleMap,
	useGoogleMap,
	useJsApiLoader,
	Marker,
	Polyline,
	HeatmapLayer,
	Circle,
} from '@react-google-maps/api';

import createZContext from 'zustand';

import GeoService, {
	useTrackingMapValues,
	useSingleMarkerLocation,
	// useManagedViewport,
	useCurrentHeatmap,
	useCurrentGpsLocation,
} from 'shared/services/GeoService';

import AppConfig from 'shared/config-public';
import {
	getRubberIcon,
	MAP_BRAND_COLOR_RAW_HEX,
	trackingIconProps,
} from 'shared/utils/mapIconGenerators';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useDriverGps } from 'shared/utils/GpsStreamReceiver';

import { useCarIcon } from 'shared/hooks/useCarIcon';
import { useFunctionalState } from 'shared/components/FunctionalState';
import TripStatus from 'shared/utils/TripStatus';

import { useGoogleJsApi } from 'shared/hooks/useGoogleLoaded';

import { useMemberTripContext } from 'shared/services/TripComposerContext';

import styles from './ComposerMapWidget.module.scss';
import { mapStyle } from './mapStyle';

// Basic B&W map style made with https://mapstyle.withgoogle.com/
// import { mapStyle } from './mapStyle';

// Isolate the zoom control into it's own Component
// so that any prop changes from useEnableManagedViewportHelper()
// don't force a re-render of the other siblings
const ResetZoomControl = React.memo(function ResetZoomControl({
	enableManagedViewport,
	setEnableManagedViewport,
	className,
}) {
	const showForInteraction = useFunctionalState(
		GeoService.gpsInteractionNeeded,
	);

	const topSafeArea = useMemberTripContext((state) => state.topSafeArea);

	// console.log(`re-render ResetZoomControl`, {
	// 	topSafeArea,
	// 	enableManagedViewport,
	// });

	return (
		<>
			<div
				className={clsx(
					styles.ResetZoomControl,
					(!!showForInteraction || !enableManagedViewport) && styles.visible,
					className,
					'ResetZoomControl-root',
				)}
				style={{ '--top-safe-area': `${topSafeArea || 0}px` }}
			>
				<Tooltip title="Reset map position to default settings">
					<IconButton
						aria-label="reset zoom"
						onClick={() => {
							if (showForInteraction) {
								showForInteraction.startCallback();
							} else {
								GeoService.forceRequestSingleLocation();
							}
							setEnableManagedViewport(true);
						}}
					>
						<GpsFixedIcon />
					</IconButton>
				</Tooltip>
			</div>
		</>
	);
});

export const DEFAULT_ZOOM = 14;
// export const DEFAULT_ZOOM = 17;

// export const useViewportPaddingStore = createZContext((set) => ({
// 	padding: { top: 0, bottom: 0, left: 0, right: 0 },
// 	setPadding: (padding) => set((state) => ({ padding })),
// }));

const { serviceAreas } = AppConfig;
const serviceCenterPoint = serviceAreas[0]; // lat/lng and radius/dispatchRadiusMeters

// Used when managing the viewport
const VIEWPORT_PADDING = 32; // px

/**
 * Given a ref for a map (or a map itself), this will automatically pan and fit
 * the viewport of the map as needed by the GeoService. We use this hook to manage
 * the viewport because react-google-maps doesn't provide a property to receive viewport
 * data from a state, so we have to directly update the underlying map ourselves.
 * @param {any} mapOrRef Ref (with .current set to a Google Map) or a Google Map object itself
 * @returns {function} Returns a function that can be used to force an update to the viewport (e.g. after get setting the map)
 */
export function useManagedViewport(mapOrRef, viewport, paddingInput = {}) {
	// console.log(`useManagedViewport:`, { mapOrRef, viewport, paddingInput });

	const rem1 = use1rem();

	useEffect(() => {
		const map =
			mapOrRef && mapOrRef.current !== undefined ? mapOrRef.current : mapOrRef;
		if (map && viewport) {
			// console.warn(`* viewport changed: `, viewport, paddingInput);

			const { top = 0, bottom = 0, left = 0, right = 0 } = paddingInput || {};
			const padding = {
				top:
					VIEWPORT_PADDING +
					top +
					(Capacitor.getPlatform() !== 'web'
						? // Add appros padding to compensate for statusbar on native app
						  rem1 * 2.5
						: 0),
				right: VIEWPORT_PADDING + right,
				bottom: VIEWPORT_PADDING + bottom,
				left: VIEWPORT_PADDING + left,
			};

			// console.warn(`updateViewport:`, { viewport, padding });
			map.panToBounds(viewport, padding);
			map.fitBounds(viewport, padding);
		} else {
			// console.warn(`* viewport NOT changed:`, { map, viewport });
		}
	}, [mapOrRef, paddingInput, rem1, viewport]);
}

export function ViewportAdapter({ viewport, padding }) {
	const map = useGoogleMap();

	useManagedViewport(map, viewport, padding);

	return <></>;
}

export const PATH_Z_INDEX = 5;
export const CIRCLE_Z_INDEX = 10;
export const MARKER_Z_INDEX = 100;

// Blue-sky GPS is max 5.0m accuracy (eg best we can hope for is 15 feet of accuracy)
const MIN_CIRCLE_ACCURACY = 4.0;

// For drawing accuracy circle ....
const getAccuracyCircleOptions = (radius) => ({
	strokeColor: '#0000ff55',
	strokeOpacity: 0.8,
	strokeWeight: 2,
	fillColor: '#0000ff',
	fillOpacity: 0.125,
	clickable: false,
	draggable: false,
	editable: false,
	visible: true,
	zIndex: CIRCLE_Z_INDEX,
	radius, // override
});

// Isolate our dynamic context into a single component we can memoize
// so that state changes don't force re-render of the wrapping widgets
// such as the ResetZoomControl or the GoogleMap component itself
const MapContent = React.memo(function MapContent({
	enableManagedViewport,
	staticTripPath,
	staticPadding,
	trip: tripInput,
	showDriverGps,
}) {
	const map = useGoogleMap();
	window.map = map;

	const gps = useCurrentGpsLocation();

	const tripPath = useMemberTripContext((state) => state.tripPath);

	const padding = useMemberTripContext((state) => state.mapViewportPadding);

	const contextTripId = useMemberTripContext(
		(state) => state.currentTrip && state.currentTrip.id,
	);

	const isContextTripActive = useMemberTripContext(
		(state) => state.isTripActive,
	);

	const tripDriver = useMemberTripContext(
		(state) => state.currentTrip && state.currentTrip.driver,
	);

	const tripStatus = useMemberTripContext(
		(state) => state.currentTrip && state.currentTrip.status,
	);

	// console.log(`ComposerMapWidget:`, { bounds, tripPath, paths, gps });

	// const driverPhoto = useMemberTripContext(
	// 	(state) =>
	// 		state.currentTrip &&
	// 		state.currentTrip.driver &&
	// 		state.currentTrip.driver.photoUrl,
	// );

	// const driverGps = useMemberTripContext(
	// 	(state) => state.currentTrip && state.currentTrip.pickupPlace,
	// );

	const tripId = tripInput ? tripInput.id : contextTripId;
	const driver = tripInput ? tripInput.driver : tripDriver;

	const liveDriverLocation = useDriverGps({ id: tripId, driver });

	const driverGps =
		showDriverGps ||
		[TripStatus.DriverArrived].includes(tripInput?.status || tripStatus)
			? liveDriverLocation
			: {};

	const isTripActive =
		showDriverGps || tripInput?.isTripActive || isContextTripActive;
	// console.log(driverGps);

	const carIcon = useCarIcon(driverGps?.heading || 0);

	const { bounds, paths, pathMarkers } = staticTripPath || tripPath || {};

	// console.log(`driver car debug`, {
	// 	isTripActive,
	// 	carIcon,
	// 	driverGps,
	// 	tripStatus,
	// 	isContextTripActive,
	// 	liveDriverLocation,
	// 	tripId,
	// 	driver,
	// });

	return (
		<>
			{bounds && (
				<ViewportAdapter
					viewport={enableManagedViewport ? bounds : undefined}
					padding={staticPadding || padding}
				/>
			)}

			{isTripActive && carIcon && driverGps && driverGps.lat && driverGps.lng && (
				<>
					<Marker position={driverGps} icon={carIcon} zIndex={MARKER_Z_INDEX} />

					{driverGps.accuracy > MIN_CIRCLE_ACCURACY ? (
						<Circle
							center={driverGps}
							options={getAccuracyCircleOptions(driverGps.accuracy)}
						/>
					) : (
						<></>
					)}
				</>
			)}

			{gps && gps.lat && gps.lng && (
				<>
					<Marker
						position={gps}
						icon={getRubberIcon(trackingIconProps)}
						zIndex={MARKER_Z_INDEX}
					/>

					{gps.accuracy > MIN_CIRCLE_ACCURACY ? (
						<Circle
							center={gps}
							options={getAccuracyCircleOptions(gps.accuracy)}
						/>
					) : (
						<></>
					)}
				</>
			)}

			{paths &&
				paths.map(({ id, path, pathColor }) => (
					<Polyline
						key={id}
						options={{
							strokeColor: pathColor || `#${MAP_BRAND_COLOR_RAW_HEX}`,
						}}
						path={path}
						zIndex={MARKER_Z_INDEX}
					/>
				))}

			{pathMarkers &&
				pathMarkers.map(
					({ id, position, icon }) =>
						position.lat &&
						position.lng && (
							<Marker
								key={id}
								position={position}
								icon={icon || getRubberIcon()}
								label={icon && icon.label ? icon.label : undefined}
								zIndex={MARKER_Z_INDEX}
							/>
						),
				)}
		</>
	);
});

export default function ComposerMapWidget({
	staticTripPath,
	staticPadding,
	className,
	zoomControlClassName,
	trip,
	showDriverGps,
	disableCustomMapStyle = false,
	googleMapId: googleMapIdInput,
}) {
	const { googleMapId } = AppConfig;
	const { isLoaded, loadError } = useGoogleJsApi();
	if (loadError) {
		console.error(`Google Maps load error:`, loadError);
	}

	const { enableManagedViewport, setEnableManagedViewport } =
		useEnableManagedViewportHelper(styles.mapContainer);

	const contextPathHasBounds = useMemberTripContext(
		(state) => state.pathHasBounds,
	);

	const gps = useCurrentGpsLocation();

	const pathHasBounds = !!(
		(staticTripPath && staticTripPath.bounds) ||
		contextPathHasBounds
	);

	const handleMapClickEvent = useCallback((evt) => {
		console.log(`Map clicked`, { evt, placeId: evt.placeId });
	}, []);

	return isLoaded ? (
		<>
			<ResetZoomControl
				enableManagedViewport={enableManagedViewport}
				setEnableManagedViewport={setEnableManagedViewport}
				className={zoomControlClassName}
			/>
			<GoogleMap
				onDragStart={() => {
					setEnableManagedViewport(false);
					const { startCallback } =
						GeoService.gpsInteractionNeeded.getValue() || {};
					if (startCallback) {
						startCallback();
					}
				}}
				mapContainerClassName={clsx(styles.mapContainer, className)}
				// If path has bounds, the center will instead be controlled
				// by our ViewportAdapter based on the path bounds
				center={
					pathHasBounds || !enableManagedViewport
						? undefined
						: gps && gps.lat && gps.lng
						? gps
						: serviceCenterPoint.location
				}
				// If path has bounds, the zoom will be overridden
				// by our ViewportAdapter based on the path bounds
				zoom={DEFAULT_ZOOM}
				options={{
					styles:
						!disableCustomMapStyle && !googleMapId && !googleMapIdInput
							? mapStyle
							: undefined,
					mapId: googleMapIdInput || googleMapId,
					disableDefaultUI: true,
					gestureHandling: 'greedy',
					// tilt: 45,
					// heading: 0,
					zoom: DEFAULT_ZOOM,
				}}
				onClick={handleMapClickEvent}
			>
				<MapContent
					enableManagedViewport={enableManagedViewport}
					staticTripPath={staticTripPath}
					staticPadding={staticPadding}
					trip={trip}
					showDriverGps={showDriverGps}
				/>
			</GoogleMap>
		</>
	) : (
		<div className={styles.loadingMsg}>
			<CircularProgress />
		</div>
	);
}
