import { zeroPad } from './zeroPad';
import { stripTrailingZeros } from './stripTrailingZeros';

export function secondsToDurationTime(
	sec,
	{
		onlyHoursMinutes = false,
		disableZeroPadHours = false,
		hideMinutesIfZero = false,
	} = {},
) {
	if (Number.isNaN(sec)) {
		return '00:00:00';
	}

	const ms = sec * 1000;
	const hours = Math.floor(ms / (60 * 60 * 1000));
	const mins = Math.floor((ms / (60 * 1000)) % 60);
	const secs = Math.floor((ms / 1000) % 60);
	const milli = Math.floor(ms % 1000);

	if (onlyHoursMinutes) {
		if (hideMinutesIfZero && !mins) {
			return disableZeroPadHours ? hours : zeroPad(hours);
		}

		return `${disableZeroPadHours ? hours : zeroPad(hours)}:${zeroPad(mins)}`;
	}

	return `${disableZeroPadHours ? hours : zeroPad(hours)}:${zeroPad(
		mins,
	)}:${zeroPad(secs)}${milli > 0 ? '.' : ''}${stripTrailingZeros(milli)}`;
}
