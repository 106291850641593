/* eslint-disable no-console, camelcase */
import { v4 as uuid } from 'uuid';
import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { Howl } from 'howler';
import { ServerStore } from '../ServerStore';
// import BackendService from '../../services/BackendService';
import { defer } from '../../utils/defer';
import FunctionalState from '../../components/FunctionalState';
import { promiseMap } from '../../utils/promise-map';
import fireAlarmWav from '../../sounds/firealarm.wav';
import schoolBellWav from '../../sounds/schoolbell.wav';
import valetAlarmWav from '../../sounds/valetalarm.wav';
import AppConfig from '../../config-public';
import { trapAsyncErrors } from '../../utils/later';
import { initNativePushPlugin } from './NativePushPlugin';
import { initFirebasePushPlugin } from './FirebasePushPlugin';
import AuthService from '../AuthService';

// For use in clients if needed so we don't have to add a 2nd version of howler
export { Howl };

// TODO: http://localhost:3000/#/account/user/dde8a0b1-f2b6-4607-9b45-b969fa7b03b6

export const foregroundNotificationState = new FunctionalState();
export class PushNotifyService {
	constructor() {
		// console.log(`[PushNotifyService] creating service...`);

		// We init AFTER user logs in because, regardless of Capacitor or Firebase (Web),
		// we must store a "push token", and we store that on OUR server
		// directly linked to the current user and current device. We don't
		// know what user is logged in until, well, after the logging in,
		// so no use in setting up push (and getting a token), until we can
		// properly store the token, which only is possible after getting logged in.
		let booted = false;
		const boot = () => {
			const currentAuthState = AuthService.isAuthorizedState.getValue();
			if (!booted && currentAuthState) {
				booted = true;
				// console.log(
				// 	`[PushNotifyService] boot event received, starting up... currentAuthState:`,
				// 	currentAuthState,
				// );
				this.init();
				this.enableBackgroundClickAcceptance();
			}
		};

		AuthService.isAuthorizedState.on('changed', boot);
		// Call right away in case we're authorized. Don't have to guard against multiple calls here
		// because 'boot' guards internally
		boot();
	}

	// Set a flag so any callback references stored elsewhere know to just silently go away
	destroy() {
		this.isDestroyed = true;
	}

	init() {
		if (Capacitor.getPlatform() !== 'web') {
			console.log(
				`[PushNotifyService] Starting Capacitor plugin push functionality`,
			);

			// Wait to start the notification service in any case till about ~X sec in
			// to allow the native app plugins time to set up
			setTimeout(() => {
				trapAsyncErrors(() => {
					initNativePushPlugin(this).catch((ex) => {
						ServerStore.AuditLog(
							`app.capacitor.encountered-plugin-init-error.push-plugin`,
							`Native App Encountered Error Initing Push Notifications`,
							`Error message: ${ex.message}`,
							{
								message: ex.message,
								stack: ex.stack,
								originalException: ex,
							},
						);
					});
				});
			}, 10 * 1000);
		} else if (
			`${window.location.href}`.includes('https') ||
			AppConfig.buildEnv !== 'dev'
		) {
			console.log(
				`[PushNotifyService] Starting browser-based push functionality`,
			);
			initFirebasePushPlugin(this);
		}
	}

	messageHooks = [];

	addMessageHook(classRef, callback) {
		if (classRef && callback) {
			this.messageHooks.push({ classRef, callback });
		}
		return (message) => this.sendMessage(message);
	}

	removeMessageHooks(classRef) {
		this.messageHooks = this.messageHooks.filter(
			(h) => h.classRef !== classRef,
		);
	}

	// Both NativePushPlugin and FirebasePushPlugin will call this when new message is received
	// **IN THE FOREGROUND** and ONLY IN FOREGROUND
	// E.g. if tab is in background or app is in background, the service worker / plugin will
	// handle notification display
	onMessage = async (data) => {
		if (this.isDestroyed) {
			return;
		}

		console.warn(`onMessage:`, data);

		ServerStore.metric('app.push_service.message.received', null, data);

		const { data: { foregroundSound } = {} } = data;
		const validSounds = { fireAlarmWav, schoolBellWav, valetAlarmWav };
		const presetSound = validSounds[foregroundSound];
		if (presetSound) {
			const sound = new Howl({
				src: [presetSound],
			});
			sound.play();
		} else if (foregroundSound) {
			console.warn(`Unknown data.foregroundSound:`, foregroundSound);
		}

		let consumed;
		await promiseMap(this.messageHooks, async ({ callback }) => {
			if (!consumed && (await callback(data))) {
				console.log(`* Push message consumed by callback`, callback);
				consumed = true;
			}
		});

		if (consumed) {
			return;
		}

		console.log(`* Push message NOT consumed by callback, setting state`);

		// if (this.customMessageDisplay) {
		// 	if (await this.customMessageDisplay(data)) {
		// 		this.onMessageClick(data);
		// 	}
		// } else {
		foregroundNotificationState.setState(data || {});
		// }
	};

	// Called by WelcomeScene and KittyFlySleep (our two main starting points) when they;re ready to accept clicks
	enableBackgroundClickAcceptance() {
		this._enableBackgroundClickAcceptance = true;

		if (this._pendingBackgroundClick) {
			const pendingData = this._pendingBackgroundClick;
			this._pendingBackgroundClick = null;

			console.log(
				'[PushNotifyService.enableBackgroundClickAcceptance] accepted, found pending click, processing:',
				pendingData,
			);
			this.onMessageClick(pendingData);
			return true;
		}

		return null;
	}

	onMessageClick = async (payload, actionId) => {
		if (this.isDestroyed || !payload) {
			return;
		}
		console.log(`PushNotifyService.onMessageClick: `, {
			payload,
			actionId,
		});

		// TODO - do something relevant here.
		// console.log("Yay, message clicked:", data);

		if (payload.clickedInBackground && !this._enableBackgroundClickAcceptance) {
			// If this click is from a background handler (e.g. service worker while page is NOT loaded, or Capacitor while app is unloaded)
			// then we wait for our app to finish booting and the game to start accepting clicks.
			console.log(
				'[PushNotifyService.onMessageClick] not accepting background clicks yet, storing for later:',
				payload,
			);

			this._pendingBackgroundClick = payload;
			return;
		}

		const { data: { url: uri } = {} } = payload;

		if (uri) {
			if (uri.startsWith(`http`)) {
				// if (
				// 	uri.includes('vayadriving.com/') ||
				// 	uri.includes('/localhost') ||
				// 	uri.includes('ngrok.com/')
				// ) {
				// 	// const parsed = new URL(uri);
				// 	// const { pathname } = parsed;
				// 	console.log(`Just setting hash since it's an app URL`, {
				// 		uri,
				// 		// pathname,
				// 		// parsed,
				// 	});
				// 	window.location.href = uri;
				// } else {
				// 	console.log(`Redirecting since not a local URL:`, uri);
				// 	window.location.href = uri;
				// }

				// Right now, just redirect because Capacitor will take care of allowing
				// it to open inside the web view vs external browser based on the capacitor.config.ts setup
				window.location.href = uri;
				// In the future, we could emit an event and use react-router-dom to be nice, but I'm lazy.
			} else {
				try {
					const { event, ...externalOpts } = JSON.parse(
						atob(decodeURIComponent(uri.replace(/^.*#alert:/, ''))),
					);
					console.log(
						'[PushNotifyService.onMessageClick] parsed click_action, got event=',
						event,
						', externalOpts=',
						externalOpts,
					);

					ServerStore.metric('app.push_service.message.clicked.success', null, {
						event,
						externalOpts,
					});

					ServerStore.emit(event, externalOpts);
				} catch (e) {
					console.error(
						'[PushNotifyService.onMessageClick] Error processing data uri:',
						e,
						uri,
						payload,
					);

					ServerStore.metric(
						'app.push_service.message.clicked.processing_error',
						null,
						{ error: e, uri, payload },
					);
				}
			}
		} else {
			console.warn(
				'[PushNotifyService.onMessageClick] no url in data:',
				payload,
			);
			ServerStore.metric(
				'app.push_service.message.clicked.no_click_action',
				null,
				payload,
			);
		}
	};

	// Only used in web app, not PhoneGap
	// Update: Also planning on using with iOS (PhoneGap)
	onPermissionNeeded = async () => {
		if (this.isDestroyed) {
			return undefined;
		}

		console.log(`[PushNotifyService] onPermissionNeeded entered`, {
			hasCallback: this.permissionNeededCallback,
		});

		// The game could define this callback ...
		if (
			this.permissionNeededCallback &&
			(await this.permissionNeededCallback())
		) {
			// Only return (allow request) if permissionNeededCallback returns true,
			// if returns a falsy value, don't return and delay via defer() (possibly forever)
			return undefined;
		}

		// We do want to return this here even though we don't return elsewhere (consistent return)
		// because this causes the caller to await this promise next instead of stopping awaiting
		// eslint-disable-next-line consistent-return
		if (this._permission) {
			console.log(
				`[PushNotifyService] onPermissionNeeded has running _permission flag`,
				{
					flg: this._permission,
				},
			);
			return this._permission;
		}

		this._permission = defer();

		console.log(
			`[PushNotifyService] onPermissionNeeded creating running flag and waiting for someone to resolve it`,
			{
				flg: this._permission,
			},
		);

		// The FirebasePushPlugin will await this function,
		// so block until this deferred promise is resolved
		return this._permission;
	};

	// At some point, our game can check if permission is needed,
	// show a UI prompt, and if user agrees, then it will call permissionGiven() ...
	async ifPermissionNeeded(callback) {
		// Firebase has not yet came online and told us we needed permission, so store callback
		if (!this._permission && callback) {
			this.permissionNeededCallback = callback;
		}

		// Firebase has told us we needed permission already,
		// so execute callback and resolve deferred promise (if callback returns true)
		if (this._permission && callback && (await callback())) {
			this.permissionGiven();
			return false;
		}

		return true;
	}

	// This will resolve the blocking promise given in onPermissionNeeded().
	// Firebase will be waiting on this promise to resolve before actually showing the native notify permission request
	permissionGiven() {
		console.log(`[PushNotifyService] permissionGiven, resolving running flags`);
		this._permission.resolve();
		this._permission = null;
	}

	static hashIsAlert(urlFragment) {
		return urlFragment.match(/^#?\/?alert:/);
	}

	handleAlertHash(hashString) {
		const click_action = hashString.replace(/^#?\/?alert:/, '');

		this.onMessageClick({
			// Note: this is the ONLY property of the alert we emulate here.
			// If we find we need more data from the alert (like the ID, etc)
			// we must modify our service worker to encode it in the URL accordingly and then we
			// must update this code above to extract it and put it here in this hash.
			click_action,

			// Used by PushNotifyService to handle properly
			clickedInBackground: true,

			// Add flag so our logging metrics know where this came from
			fromFirebaseServiceWorkerBackground: true,
		});
	}
}

// Start the service
PushNotifyService.instance = new PushNotifyService();

export function usePushNotificationHook(callback) {
	useEffect(() => {
		const classRef = `messageHook${uuid()}`;
		PushNotifyService.instance.addMessageHook(classRef, callback);
		return () => PushNotifyService.instance.removeMessageHooks(classRef);
	});
}
