// From https://secure.jadeworld.com/JADETech/JADE2020/OnlineDocumentation/content/resources/encyclosys2/jadetimezone_class/ianawindowstimezonemapping.htm
// Converted to usable structures for export at the end of this file.
const rawData = `Etc/GMT+0 UTC
Etc/GMT+12	Dateline Standard Time
Etc/GMT+11	UTC-11
Pacific/Pago_Pago	UTC-11
Pacific/Niue	UTC-11
Pacific/Midway	UTC-11
America/Adak	Aleutian Standard Time
Pacific/Honolulu	Hawaiian Standard Time
Pacific/Rarotonga	Hawaiian Standard Time
Pacific/Tahiti	Hawaiian Standard Time
Pacific/Johnston	Hawaiian Standard Time
Etc/GMT+10	Hawaiian Standard Time
Pacific/Marquesas	Marquesas Standard Time
America/Anchorage	Alaskan Standard Time
America/Juneau	Alaskan Standard Time
America/Metlakatla	Alaskan Standard Time
America/Nome	Alaskan Standard Time
America/Sitka	Alaskan Standard Time
America/Yakutat	Alaskan Standard Time
Etc/GMT+9	UTC-09
Pacific/Gambier	UTC-09
America/Tijuana	Pacific Standard Time (Mexico)
America/Santa_Isabel	Pacific Standard Time (Mexico)
Etc/GMT+8	UTC-08
Pacific/Pitcairn	UTC-08
America/Los_Angeles	Pacific Standard Time
America/Vancouver	Pacific Standard Time
America/Dawson	Pacific Standard Time
America/Whitehorse	Pacific Standard Time
PST8PDT	Pacific Standard Time
America/Phoenix	US Mountain Standard Time
America/Dawson_Creek	US Mountain Standard Time
America/Creston	US Mountain Standard Time
America/Fort_Nelson	US Mountain Standard Time
America/Hermosillo	US Mountain Standard Time
Etc/GMT+7	US Mountain Standard Time
America/Chihuahua	Mountain Standard Time (Mexico)
America/Mazatlan	Mountain Standard Time (Mexico)
America/Denver	Mountain Standard Time
America/Edmonton	Mountain Standard Time
America/Cambridge_Bay	Mountain Standard Time
America/Inuvik	Mountain Standard Time
America/Yellowknife	Mountain Standard Time
America/Ojinaga	Mountain Standard Time
America/Boise	Mountain Standard Time
MST7MDT	Mountain Standard Time
America/Guatemala	Central America Standard Time
America/Belize	Central America Standard Time
America/Costa_Rica	Central America Standard Time
Pacific/Galapagos	Central America Standard Time
America/Tegucigalpa	Central America Standard Time
America/Managua	Central America Standard Time
America/El_Salvador	Central America Standard Time
Etc/GMT+6	Central America Standard Time
America/Chicago	Central Standard Time
America/Winnipeg	Central Standard Time
America/Rainy_River	Central Standard Time
America/Rankin_Inlet	Central Standard Time
America/Resolute	Central Standard Time
America/Matamoros	Central Standard Time
America/Indiana/Knox	Central Standard Time
America/Indiana/Tell_City	Central Standard Time
America/Menominee	Central Standard Time
America/North_Dakota/Beulah	Central Standard Time
America/North_Dakota/Center	Central Standard Time
America/North_Dakota/New_Salem	Central Standard Time
CST6CDT	Central Standard Time
Pacific/Easter	Easter Island Standard Time
America/Mexico_City	Central Standard Time (Mexico)
America/Bahia_Banderas	Central Standard Time (Mexico)
America/Merida	Central Standard Time (Mexico)
America/Monterrey	Central Standard Time (Mexico)
America/Regina	Canada Central Standard Time
America/Swift_Current	Canada Central Standard Time
America/Bogota	SA Pacific Standard Time
America/Rio_Branco	SA Pacific Standard Time
America/Eirunepe	SA Pacific Standard Time
America/Coral_Harbour	SA Pacific Standard Time
America/Guayaquil	SA Pacific Standard Time
America/Jamaica	SA Pacific Standard Time
America/Cayman	SA Pacific Standard Time
America/Panama	SA Pacific Standard Time
America/Lima	SA Pacific Standard Time
Etc/GMT+5	SA Pacific Standard Time
America/Cancun	Eastern Standard Time (Mexico)
America/New_York	Eastern Standard Time
America/Nassau	Eastern Standard Time
America/Toronto	Eastern Standard Time
America/Iqaluit	Eastern Standard Time
America/Montreal	Eastern Standard Time
America/Nipigon	Eastern Standard Time
America/Pangnirtung	Eastern Standard Time
America/Thunder_Bay	Eastern Standard Time
America/Detroit	Eastern Standard Time
America/Indiana/Petersburg	Eastern Standard Time
America/Indiana/Vincennes	Eastern Standard Time
America/Indiana/Winamac	Eastern Standard Time
America/Kentucky/Monticello	Eastern Standard Time
America/Louisville	Eastern Standard Time
EST5EDT	Eastern Standard Time
America/Port-au-Prince	Haiti Standard Time
America/Havana	Cuba Standard Time
America/Indianapolis	Eastern Standard Time
America/Indiana/Indianapolis	Eastern Standard Time
America/Indiana/Marengo	Eastern Standard Time
America/Indiana/Vevay	Eastern Standard Time
America/Grand_Turk	Turks And Caicos Standard Time
America/Asuncion	Paraguay Standard Time
America/Halifax	Atlantic Standard Time
Atlantic/Bermuda	Atlantic Standard Time
America/Glace_Bay	Atlantic Standard Time
America/Goose_Bay	Atlantic Standard Time
America/Moncton	Atlantic Standard Time
America/Thule	Atlantic Standard Time
America/Caracas	Venezuela Standard Time
America/Cuiaba	Central Brazilian Standard Time
America/Campo_Grande	Central Brazilian Standard Time
America/La_Paz	SA Western Standard Time
America/Antigua	SA Western Standard Time
America/Anguilla	SA Western Standard Time
America/Aruba	SA Western Standard Time
America/Barbados	SA Western Standard Time
America/St_Barthelemy	SA Western Standard Time
America/Kralendijk	SA Western Standard Time
America/Manaus	SA Western Standard Time
America/Boa_Vista	SA Western Standard Time
America/Porto_Velho	SA Western Standard Time
America/Blanc-Sablon	SA Western Standard Time
America/Curacao	SA Western Standard Time
America/Dominica	SA Western Standard Time
America/Santo_Domingo	SA Western Standard Time
America/Grenada	SA Western Standard Time
America/Guadeloupe	SA Western Standard Time
America/Guyana	SA Western Standard Time
America/St_Kitts	SA Western Standard Time
America/St_Lucia	SA Western Standard Time
America/Marigot	SA Western Standard Time
America/Martinique	SA Western Standard Time
America/Montserrat	SA Western Standard Time
America/Puerto_Rico	SA Western Standard Time
America/Lower_Princes	SA Western Standard Time
America/Port_of_Spain	SA Western Standard Time
America/St_Vincent	SA Western Standard Time
America/Tortola	SA Western Standard Time
America/St_Thomas	SA Western Standard Time
Etc/GMT+4	SA Western Standard Time
America/Santiago	Pacific SA Standard Time
America/St_Johns	Newfoundland Standard Time
America/Araguaina	Tocantins Standard Time
America/Sao_Paulo	E. South America Standard Time
America/Cayenne	SA Eastern Standard Time
Antarctica/Rothera	SA Eastern Standard Time
Antarctica/Palmer	SA Eastern Standard Time
America/Fortaleza	SA Eastern Standard Time
America/Belem	SA Eastern Standard Time
America/Maceio	SA Eastern Standard Time
America/Recife	SA Eastern Standard Time
America/Santarem	SA Eastern Standard Time
Atlantic/Stanley	SA Eastern Standard Time
America/Paramaribo	SA Eastern Standard Time
Etc/GMT+3	SA Eastern Standard Time
America/Buenos_Aires	Argentina Standard Time
America/Argentina/La_Rioja	Argentina Standard Time
America/Argentina/Rio_Gallegos	Argentina Standard Time
America/Argentina/Salta	Argentina Standard Time
America/Argentina/San_Juan	Argentina Standard Time
America/Argentina/San_Luis	Argentina Standard Time
America/Argentina/Tucuman	Argentina Standard Time
America/Argentina/Ushuaia	Argentina Standard Time
America/Catamarca	Argentina Standard Time
America/Cordoba	Argentina Standard Time
America/Jujuy	Argentina Standard Time
America/Mendoza	Argentina Standard Time
America/Godthab	Greenland Standard Time
America/Montevideo	Montevideo Standard Time
America/Punta_Arenas	Magallanes Standard Time
America/Miquelon	Saint Pierre Standard Time
America/Bahia	Bahia Standard Time
Etc/GMT+2	UTC-02
America/Noronha	UTC-02
Atlantic/South_Georgia	UTC-02
Atlantic/Azores	Azores Standard Time
America/Scoresbysund	Azores Standard Time
Atlantic/Cape_Verde	Cape Verde Standard Time
Etc/GMT+1	Cape Verde Standard Time
Etc/GMT	UTC
America/Danmarkshavn	UTC
Etc/UTC	UTC
Europe/London	GMT Standard Time
Atlantic/Canary	GMT Standard Time
Atlantic/Faeroe	GMT Standard Time
Europe/Guernsey	GMT Standard Time
Europe/Dublin	GMT Standard Time
Europe/Isle_of_Man	GMT Standard Time
Europe/Jersey	GMT Standard Time
Europe/Lisbon	GMT Standard Time
Atlantic/Madeira	GMT Standard Time
Atlantic/Reykjavik	Greenwich Standard Time
Africa/Ouagadougou	Greenwich Standard Time
Africa/Abidjan	Greenwich Standard Time
Africa/Accra	Greenwich Standard Time
Africa/Banjul	Greenwich Standard Time
Africa/Conakry	Greenwich Standard Time
Africa/Bissau	Greenwich Standard Time
Africa/Monrovia	Greenwich Standard Time
Africa/Bamako	Greenwich Standard Time
Africa/Nouakchott	Greenwich Standard Time
Atlantic/St_Helena	Greenwich Standard Time
Africa/Freetown	Greenwich Standard Time
Africa/Dakar	Greenwich Standard Time
Africa/Lome	Greenwich Standard Time
Africa/Sao_Tome	Sao Tome Standard Time
Africa/Casablanca	Morocco Standard Time
Africa/El_Aaiun	Morocco Standard Time
Europe/Berlin	W. Europe Standard Time
Europe/Andorra	W. Europe Standard Time
Europe/Vienna	W. Europe Standard Time
Europe/Zurich	W. Europe Standard Time
Europe/Busingen	W. Europe Standard Time
Europe/Gibraltar	W. Europe Standard Time
Europe/Rome	W. Europe Standard Time
Europe/Vaduz	W. Europe Standard Time
Europe/Luxembourg	W. Europe Standard Time
Europe/Monaco	W. Europe Standard Time
Europe/Malta	W. Europe Standard Time
Europe/Amsterdam	W. Europe Standard Time
Europe/Oslo	W. Europe Standard Time
Europe/Stockholm	W. Europe Standard Time
Arctic/Longyearbyen	W. Europe Standard Time
Europe/San_Marino	W. Europe Standard Time
Europe/Vatican	W. Europe Standard Time
Europe/Budapest	Central Europe Standard Time
Europe/Tirane	Central Europe Standard Time
Europe/Prague	Central Europe Standard Time
Europe/Podgorica	Central Europe Standard Time
Europe/Belgrade	Central Europe Standard Time
Europe/Ljubljana	Central Europe Standard Time
Europe/Bratislava	Central Europe Standard Time
Europe/Paris	Romance Standard Time
Europe/Brussels	Romance Standard Time
Europe/Copenhagen	Romance Standard Time
Europe/Madrid	Romance Standard Time
Africa/Ceuta	Romance Standard Time
Europe/Warsaw	Central European Standard Time
Europe/Sarajevo	Central European Standard Time
Europe/Zagreb	Central European Standard Time
Europe/Skopje	Central European Standard Time
Africa/Lagos	W. Central Africa Standard Time
Africa/Luanda	W. Central Africa Standard Time
Africa/Porto-Novo	W. Central Africa Standard Time
Africa/Kinshasa	W. Central Africa Standard Time
Africa/Bangui	W. Central Africa Standard Time
Africa/Brazzaville	W. Central Africa Standard Time
Africa/Douala	W. Central Africa Standard Time
Africa/Algiers	W. Central Africa Standard Time
Africa/Libreville	W. Central Africa Standard Time
Africa/Malabo	W. Central Africa Standard Time
Africa/Niamey	W. Central Africa Standard Time
Africa/Ndjamena	W. Central Africa Standard Time
Africa/Tunis	W. Central Africa Standard Time
Etc/GMT-1	W. Central Africa Standard Time
Asia/Amman	Jordan Standard Time
Europe/Bucharest	GTB Standard Time
Asia/Nicosia	GTB Standard Time
Asia/Famagusta	GTB Standard Time
Europe/Athens	GTB Standard Time
Asia/Beirut	Middle East Standard Time
Africa/Cairo	Egypt Standard Time
Europe/Chisinau	E. Europe Standard Time
Asia/Damascus	Syria Standard Time
Asia/Hebron	West Bank Standard Time
Asia/Gaza	West Bank Standard Time
Africa/Johannesburg	South Africa Standard Time
Africa/Bujumbura	South Africa Standard Time
Africa/Gaborone	South Africa Standard Time
Africa/Lubumbashi	South Africa Standard Time
Africa/Maseru	South Africa Standard Time
Africa/Blantyre	South Africa Standard Time
Africa/Maputo	South Africa Standard Time
Africa/Kigali	South Africa Standard Time
Africa/Mbabane	South Africa Standard Time
Africa/Lusaka	South Africa Standard Time
Africa/Harare	South Africa Standard Time
Etc/GMT-2	South Africa Standard Time
Europe/Kiev	FLE Standard Time
Europe/Mariehamn	FLE Standard Time
Europe/Sofia	FLE Standard Time
Europe/Tallinn	FLE Standard Time
Europe/Helsinki	FLE Standard Time
Europe/Vilnius	FLE Standard Time
Europe/Riga	FLE Standard Time
Europe/Uzhgorod	FLE Standard Time
Europe/Zaporozhye	FLE Standard Time
Asia/Jerusalem	Israel Standard Time
Europe/Kaliningrad	Kaliningrad Standard Time
Africa/Khartoum	Sudan Standard Time
Africa/Tripoli	Libya Standard Time
Africa/Windhoek	Namibia Standard Time
Asia/Baghdad	Arabic Standard Time
Europe/Istanbul	Turkey Standard Time
Asia/Riyadh	Arab Standard Time
Asia/Bahrain	Arab Standard Time
Asia/Kuwait	Arab Standard Time
Asia/Qatar	Arab Standard Time
Asia/Aden	Arab Standard Time
Europe/Minsk	Belarus Standard Time
Europe/Moscow	Russian Standard Time
Europe/Kirov	Russian Standard Time
Europe/Simferopol	Russian Standard Time
Africa/Nairobi	E. Africa Standard Time
Antarctica/Syowa	E. Africa Standard Time
Africa/Djibouti	E. Africa Standard Time
Africa/Asmera	E. Africa Standard Time
Africa/Addis_Ababa	E. Africa Standard Time
Indian/Comoro	E. Africa Standard Time
Indian/Antananarivo	E. Africa Standard Time
Africa/Mogadishu	E. Africa Standard Time
Africa/Juba	E. Africa Standard Time
Africa/Dar_es_Salaam	E. Africa Standard Time
Africa/Kampala	E. Africa Standard Time
Indian/Mayotte	E. Africa Standard Time
Etc/GMT-3	E. Africa Standard Time
Asia/Tehran	Iran Standard Time
Asia/Dubai	Arabian Standard Time
Asia/Muscat	Arabian Standard Time
Etc/GMT-4	Arabian Standard Time
Europe/Astrakhan	Astrakhan Standard Time
Europe/Ulyanovsk	Astrakhan Standard Time
Asia/Baku	Azerbaijan Standard Time
Europe/Samara	Russia Time Zone 3
Indian/Mauritius	Mauritius Standard Time
Indian/Reunion	Mauritius Standard Time
Indian/Mahe	Mauritius Standard Time
Europe/Saratov	Saratov Standard Time
Asia/Tbilisi	Georgian Standard Time
Europe/Volgograd	Volgograd Standard Time
Asia/Yerevan	Caucasus Standard Time
Asia/Kabul	Afghanistan Standard Time
Asia/Tashkent	West Asia Standard Time
Antarctica/Mawson	West Asia Standard Time
Asia/Oral	West Asia Standard Time
Asia/Aqtau	West Asia Standard Time
Asia/Aqtobe	West Asia Standard Time
Asia/Atyrau	West Asia Standard Time
Indian/Maldives	West Asia Standard Time
Indian/Kerguelen	West Asia Standard Time
Asia/Dushanbe	West Asia Standard Time
Asia/Ashgabat	West Asia Standard Time
Asia/Samarkand	West Asia Standard Time
Etc/GMT-5	West Asia Standard Time
Asia/Yekaterinburg	Ekaterinburg Standard Time
Asia/Karachi	Pakistan Standard Time
Asia/Qyzylorda	Qyzylorda Standard Time
Asia/Calcutta	India Standard Time
Asia/Colombo	Sri Lanka Standard Time
Asia/Katmandu	Nepal Standard Time
Asia/Almaty	Central Asia Standard Time
Antarctica/Vostok	Central Asia Standard Time
Asia/Urumqi	Central Asia Standard Time
Indian/Chagos	Central Asia Standard Time
Asia/Bishkek	Central Asia Standard Time
Asia/Qostanay	Central Asia Standard Time
Etc/GMT-6	Central Asia Standard Time
Asia/Dhaka	Bangladesh Standard Time
Asia/Thimphu	Bangladesh Standard Time
Asia/Omsk	Omsk Standard Time
Asia/Rangoon	Myanmar Standard Time
Indian/Cocos	Myanmar Standard Time
Asia/Bangkok	SE Asia Standard Time
Antarctica/Davis	SE Asia Standard Time
Indian/Christmas	SE Asia Standard Time
Asia/Jakarta	SE Asia Standard Time
Asia/Pontianak	SE Asia Standard Time
Asia/Phnom_Penh	SE Asia Standard Time
Asia/Vientiane	SE Asia Standard Time
Asia/Saigon	SE Asia Standard Time
Etc/GMT-7	SE Asia Standard Time
Asia/Barnaul	Altai Standard Time
Asia/Hovd	W. Mongolia Standard Time
Asia/Krasnoyarsk	North Asia Standard Time
Asia/Novokuznetsk	North Asia Standard Time
Asia/Novosibirsk	N. Central Asia Standard Time
Asia/Tomsk	Tomsk Standard Time
Asia/Shanghai	China Standard Time
Asia/Hong_Kong	China Standard Time
Asia/Macau	China Standard Time
Asia/Irkutsk	North Asia East Standard Time
Asia/Singapore	Singapore Standard Time
Antarctica/Casey	Singapore Standard Time
Asia/Brunei	Singapore Standard Time
Asia/Makassar	Singapore Standard Time
Asia/Kuala_Lumpur	Singapore Standard Time
Asia/Kuching	Singapore Standard Time
Asia/Manila	Singapore Standard Time
Etc/GMT-8	Singapore Standard Time
Australia/Perth	W. Australia Standard Time
Asia/Taipei	Taipei Standard Time
Asia/Ulaanbaatar	Ulaanbaatar Standard Time
Asia/Choibalsan	Ulaanbaatar Standard Time
Australia/Eucla	Aus Central W. Standard Time
Asia/Chita	Transbaikal Standard Time
Asia/Tokyo	Tokyo Standard Time
Asia/Jayapura	Tokyo Standard Time
Pacific/Palau	Tokyo Standard Time
Asia/Dili	Tokyo Standard Time
Etc/GMT-9	Tokyo Standard Time
Asia/Pyongyang	North Korea Standard Time
Asia/Seoul	Korea Standard Time
Asia/Yakutsk	Yakutsk Standard Time
Asia/Khandyga	Yakutsk Standard Time
Australia/Adelaide	Cen. Australia Standard Time
Australia/Broken_Hill	Cen. Australia Standard Time
Australia/Darwin	AUS Central Standard Time
Australia/Brisbane	E. Australia Standard Time
Australia/Lindeman	E. Australia Standard Time
Australia/Sydney	AUS Eastern Standard Time
Australia/Melbourne	AUS Eastern Standard Time
Pacific/Port_Moresby	West Pacific Standard Time
Antarctica/DumontDUrville	West Pacific Standard Time
Pacific/Truk	West Pacific Standard Time
Pacific/Guam	West Pacific Standard Time
Pacific/Saipan	West Pacific Standard Time
Etc/GMT-10	West Pacific Standard Time
Australia/Hobart	Tasmania Standard Time
Australia/Currie	Tasmania Standard Time
Asia/Vladivostok	Vladivostok Standard Time
Asia/Ust-Nera	Vladivostok Standard Time
Australia/Lord_Howe	Lord Howe Standard Time
Pacific/Bougainville	Bougainville Standard Time
Asia/Srednekolymsk	Russia Time Zone 10
Asia/Magadan	Magadan Standard Time
Pacific/Norfolk	Norfolk Standard Time
Asia/Sakhalin	Sakhalin Standard Time
Pacific/Guadalcanal	Central Pacific Standard Time
Antarctica/Macquarie	Central Pacific Standard Time
Pacific/Ponape	Central Pacific Standard Time
Pacific/Kosrae	Central Pacific Standard Time
Pacific/Noumea	Central Pacific Standard Time
Pacific/Efate	Central Pacific Standard Time
Etc/GMT-11	Central Pacific Standard Time
Asia/Kamchatka	Russia Time Zone 11
Asia/Anadyr	Russia Time Zone 11
Pacific/Auckland	New Zealand Standard Time
Antarctica/McMurdo	New Zealand Standard Time
Etc/GMT-12	UTC+12
Pacific/Tarawa	UTC+12
Pacific/Majuro	UTC+12
Pacific/Kwajalein	UTC+12
Pacific/Nauru	UTC+12
Pacific/Funafuti	UTC+12
Pacific/Wake	UTC+12
Pacific/Wallis	UTC+12
Pacific/Fiji	Fiji Standard Time
Pacific/Chatham	Chatham Islands Standard Time
Etc/GMT-13	UTC+13
Pacific/Enderbury	UTC+13
Pacific/Fakaofo	UTC+13
Pacific/Tongatapu	Tonga Standard Time
Pacific/Apia	Samoa Standard Time
Pacific/Kiritimati	Line Islands Standard Time
Etc/GMT-14	Line Islands Standard Time
`;

/**
 * Keyed to IANA Timezone Name, like 'America/Chicago',
 * and contains a structure for each IANA timezone giving:
 *
 * * `shortName` - a string like `CST` or `EST`
 * * `longName`- a string like 'Central Standard Time' or 'Eastern Standard Time'
 * * `iana` - The IANA zone name, just to keep things tiday
 *
 * - Also check out: `shortNameToIana` to give it a `shortName` and get the same data above
 * - Also check out: `longNameToIana` to give it a `longName` and get the same data above
 *
 */
export const ianaToFriendlyNames = {};

/**
 * Keyed to the short timezone names, like 'EST' or 'CST',
 * and contains a structure for each key giving:
 *
 * * `shortName` - a string like `CST` or `EST`
 * * `longName`- a string like 'Central Standard Time' or 'Eastern Standard Time'
 * * `iana` - The IANA zone name, just to keep things tiday
 *
 * - Also check out: `shortNameToIana` to give it a `shortName` and get the same data above
 * - Also check out: `ianaToFriendlyNames` to give it a `iana` name and get the same data above
 *
 */
export const shortNameToIana = {};

/**
 * Keyed to Long Timezone Name, like 'Eastern Standard Time',
 * and contains a structure for each key giving:
 *
 * * `shortName` - a string like `CST` or `EST`
 * * `longName`- a string like 'Central Standard Time' or 'Eastern Standard Time'
 * * `iana` - The IANA zone name, just to keep things tiday
 *
 * - Also check out: `ianaToFriendlyNames` to give it a `iana` name and get the same data above
 * - Also check out: `shortNameToIana` to give it a `shortName` and get the same data above
 *
 */
export const longNameToIana = {};

// This whole loop takes roughly 2-10ms to process so safe to do on
// every import of the file, no need to cache.
rawData.split('\n').forEach((row) => {
	const [iana, longName] = row.split('\t');
	if (iana && longName) {
		let shortName;
		if (longName.endsWith('(Mexico)')) {
			const firstLetter = longName.slice(1, 1);
			shortName = `${firstLetter}ST (Mexico)}`;
		} else if (longName.includes('Standard Time')) {
			shortName = longName
				.split(' ')
				.map((word) => word.charAt(0).toUpperCase())
				.join('');
		} else if (longName.includes('Russia Time Zone')) {
			shortName = longName.replace('Russia Time Zone', 'RTZ');
		} else if (longName.startsWith('UTC')) {
			shortName = longName;
		} else {
			// Should never happen - if this does,
			// it probably means we changed the raw data so need to adapt to a new name
			throw new Error(`Unknown long name format: ${longName}`);
		}

		const data = {
			iana,
			longName,
			shortName,
		};

		ianaToFriendlyNames[iana] = data;
		shortNameToIana[shortName] = data;
		longNameToIana[longName] = data;
	}
});
