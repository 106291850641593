const AccountStatus = {
	// Account/user created, but still waiting on info (?)
	Draft: 'draft',
	// Have info (no longer draft), but user has not formally applied - put in by Admin/Sales
	Lead: 'lead',
	// User has submitted the application, someone needs to reach out (FirstContact)
	Applied: 'applied',
	// First contact, indefinite until moving to InterviewInvited
	FirstContact: 'first_contact',
	// -NDA
	// -BG
	// Invited to schedule interview, waiting on user to schedule
	InterviewInvited: 'interview_invited',
	// Interview scheduled, waiting on completion of interview
	InterviewScheduled: 'interview_scheduled',
	// Application pending admin approval
	PendingReview: 'pending_review',
	// Account accepted as a Member, invited to signup (put in a CC)
	Accepted: 'accepted',
	// Reviewers declined membership, terminal status
	Declined: 'declined',
	// Member has added CC to the account, account is now active
	Active: 'active',
	// Member has paused their account
	Paused: 'paused',
	// Member has canceled their account
	Canceled: 'canceled',
	// Billing issues - Unrecoverable, this is like "Accepted" where the user
	// has to do something to activate the account, but not quite as rosey of a message.
	// This state is mainly entered if the CC charge fails or if the user removes a CC
	BillingProblems: 'billing_problems',
};

export const mapAccountStatusToMondayStatusName = (
	accountStatus,
	{
		bgCheckSent,
		bgCheckCompleted,
		bgCheckPassed,
		ndaSent,
		ndaCompleted,
		// testRideScheduled,
		testRideCompleted,
	} = {},
	syncRole = 'member',
) => {
	// return 'Draft';
	return {
		[AccountStatus.Draft]: 'Draft',
		[AccountStatus.Lead]: 'Lead',
		[AccountStatus.Applied]: 'Waiting List',
		[AccountStatus.FirstContact]:
			// eslint-disable-next-line no-nested-ternary
			syncRole === 'driver'
				? bgCheckSent &&
				  bgCheckCompleted &&
				  bgCheckPassed &&
				  ndaSent &&
				  ndaCompleted
					? 'Need Interviewed'
					: 'Need Paperwork'
				: 'First Contact',

		[AccountStatus.InterviewScheduled]: 'Need Interviewed',
		[AccountStatus.InterviewInvited]: 'Need Interviewed',
		[AccountStatus.PendingReview]: 'Need Test Ride',
		[AccountStatus.Accepted]: 'Accepted',
		[AccountStatus.Declined]: 'Rejected',
		[AccountStatus.Active]:
			syncRole === 'driver' && !testRideCompleted ? 'Need Test Ride' : 'Active',
		[AccountStatus.Paused]: 'Stuck',
		[AccountStatus.Canceled]: 'Rejected',
		[AccountStatus.BillingProblems]: 'Stuck',

		// 'Working on it',
	}[accountStatus];
};

export const AccountStatusNames = {};
Object.entries(AccountStatus).forEach(([key, value]) => {
	AccountStatusNames[value] = key;
});

export const AccountStatusTransitions = {
	[AccountStatus.Draft]: [AccountStatus.Applied],
	[AccountStatus.Draft]: [AccountStatus.Lead],
	[AccountStatus.Lead]: [AccountStatus.Applied],
	[AccountStatus.Applied]: [
		AccountStatus.FirstContact,
		AccountStatus.InterviewInvited,
		AccountStatus.Accepted,
		AccountStatus.Declined,
		AccountStatus.Draft,
	],
	[AccountStatus.FirstContact]: [
		AccountStatus.InterviewInvited,
		AccountStatus.Accepted,
		AccountStatus.Declined,
		AccountStatus.Draft,
	],
	[AccountStatus.InterviewInvited]: [
		AccountStatus.InterviewScheduled,
		AccountStatus.Accepted,
		AccountStatus.Declined,
		AccountStatus.Draft,
	],
	[AccountStatus.InterviewScheduled]: [
		AccountStatus.PendingReview,
		AccountStatus.Accepted,
		AccountStatus.Declined,
	],
	[AccountStatus.PendingReview]: [
		AccountStatus.Accepted,
		AccountStatus.Declined,
	],
	[AccountStatus.Accepted]: [
		AccountStatus.Active,
		AccountStatus.Paused,
		AccountStatus.Canceled,
	],
	[AccountStatus.Active]: [AccountStatus.Paused, AccountStatus.Canceled],
	[AccountStatus.Paused]: [AccountStatus.Active, AccountStatus.Canceled],
	[AccountStatus.Canceled]: [
		AccountStatus.Active,
		AccountStatus.Paused,
		AccountStatus.Draft,
	],
	[AccountStatus.Declined]: [
		AccountStatus.Applied,
		AccountStatus.Canceled,
		AccountStatus.Accepted,
		AccountStatus.InterviewScheduled,
		AccountStatus.PendingReview,
	],
};

export const isValidAccountStatus = (status) =>
	Object.values(AccountStatus).includes(status);

export const isValidAccountStatusTransition = (fromStatus, toStatus) =>
	AccountStatusTransitions[fromStatus] &&
	AccountStatusTransitions[fromStatus].includes(toStatus);

export default AccountStatus;
