// based on https://analytics.google.com/analytics/web/#/a243284w194695237p190093932/admin/tracking/tracking-code/
import AppConfig from '../config-public';
import LogRocket from '../services/LogRocket';

const { gaId } = AppConfig;

function loadSdkAsynchronously() {
	// Disable GA in staging/prod
	if (
		process.env.NODE_ENV !== 'production' ||
		process.env.REACT_APP_STAGING === 'true' ||
		AppConfig.buildEnv === 'dev' ||
		!global.window
	) {
		return () => {};
	}

	setTimeout(() => {
		((d, s, id) => {
			const element = d.getElementsByTagName(s)[0];
			const fjs = element;
			let js = element;
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			js.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
			fjs.parentNode.insertBefore(js, fjs);
		})(document, 'script', 'google-analytics');
	}, 1);

	window.dataLayer = window.dataLayer || [];
	function gtag(...args) {
		window.dataLayer.push(args);
	}
	gtag('js', new Date());

	gtag('config', gaId, { app_name: 'Vaya' });

	return gtag;

	// TODO
	// gtag('set', {'user_id': 'USER_ID'}); // Set the user ID using signed-in user_id.
}

const gtag = (() => {
	if (AppConfig.buildEnv !== 'dev') {
		// eslint-disable-next-line no-shadow
		const gtag = loadSdkAsynchronously();

		LogRocket.getSessionURL((sessionURL) => {
			gtag('send', {
				hitType: 'event',
				eventCategory: 'LogRocket',
				eventAction: sessionURL,
			});
		});

		return gtag;
	}

	return () => {};
})();

export default gtag;
