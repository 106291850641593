import { useRef, useCallback, useMemo } from 'react';
import { useCurrentGpsLocation } from 'shared/services/GeoService';
import { useGoogleJsApi, useGoogleMaps } from 'shared/hooks/useGoogleLoaded';

export function useGooglePlaces({ errorCallback } = {}) {
	const { assertLoaded, toLatLngRef } = useGoogleJsApi();

	// const maps = google && google.maps;
	const maps = useGoogleMaps();

	const PLACES_OK_STATUS =
		maps && maps.places && maps.places.PlacesServiceStatus.OK;

	const { lat, lng } = useCurrentGpsLocation() || {};

	const backgroundMap = useMemo(
		() => maps && new maps.Map(document.createElement('div')),
		[maps],
	);

	const placesService = useMemo(
		() => backgroundMap && maps && new maps.places.PlacesService(backgroundMap),
		[backgroundMap, maps],
	);

	const autocompleteService = useMemo(
		() => maps && new maps.places.AutocompleteService(),
		[maps],
	);

	// console.log(`useGooglePlaces:`, {
	// 	google,
	// 	maps,
	// 	autocompleteService,
	// 	gqp: autocompleteService
	// 		? autocompleteService.getQueryPredictions
	// 		: 'autocompleteService is null',
	// });

	const sessionTokenRef = useRef();
	const stopAutocompleteSession = useCallback(() => {
		const { current: token } = sessionTokenRef;
		sessionTokenRef.current = null;
		return token;
	}, []);

	const startAutocompleteSession = useCallback(() => {
		const token = maps && new maps.places.AutocompleteSessionToken();

		sessionTokenRef.current = token;
		return token;
	}, [maps]);

	const startOrGetAutocompleteSession = useCallback(() => {
		const { current: token } = sessionTokenRef;
		if (!token) {
			return startAutocompleteSession();
		}

		return token;
	}, [startAutocompleteSession]);

	const placesAutocomplete = useCallback(
		async (input) => {
			if (!input) {
				return undefined;
			}
			await assertLoaded();

			if (!autocompleteService) {
				return undefined;
			}

			// For tokens, ref: https://developers.google.com/maps/documentation/javascript/places-autocomplete#session_tokens
			const sessionToken = startOrGetAutocompleteSession();
			return new Promise((resolve) =>
				autocompleteService.getQueryPredictions(
					{
						input,
						sessionToken,
						location: toLatLngRef({ lat, lng }),
						radius: 60 * 1000, // 60km
						// fields: ['place_id', 'description', 'structured_formatting'],
					},
					(predictions, status) => resolve({ predictions, status }),
				),
			);
		},
		[
			assertLoaded,
			autocompleteService,
			lat,
			lng,
			startOrGetAutocompleteSession,
			toLatLngRef,
			// maps,
		],
	);

	const getPlaceDetails = useCallback(
		async (googlePlaceId) => {
			await assertLoaded();

			const sessionToken = stopAutocompleteSession();
			const request = {
				placeId: googlePlaceId,
				fields: ['name', 'geometry', 'formatted_address'],
				sessionToken,
			};

			return new Promise((resolve) =>
				placesService.getDetails(request, (place, status) => {
					if (status === PLACES_OK_STATUS) {
						const {
							geometry: { location, viewport },
							formatted_address: addressText,
							name,
						} = place;
						resolve({
							name,
							location,
							viewport,
							addressText,
							placeId: googlePlaceId,
						});
					} else {
						if (errorCallback) {
							errorCallback(status);
						}
						resolve({ error: status });
					}
				}),
			);
		},
		[
			PLACES_OK_STATUS,
			assertLoaded,
			errorCallback,
			placesService,
			stopAutocompleteSession,
		],
	);

	return {
		PLACES_OK_STATUS,
		placesAutocomplete,
		getPlaceDetails,
		stopAutocompleteSession,
	};
}
