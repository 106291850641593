import retry from 'async-retry';
import AppConfig from '../config-public';

class ServerUtil {
	/**
	 * Creates an instance of ServerUtil.
	 * @param {string} urlRoot - URL root to use for all requests by this object
	 * @memberof ServerUtil
	 */
	constructor(urlRoot) {
		// if(process.env.NODE_ENV !== 'production')
		// console.log("[ServerUtil] Using API server at ", urlRoot);

		this.urlRoot = urlRoot;
	}

	/**
	 * Set/clear the token. If set, passed as the Authorization header on every request
	 *
	 * @param {string} token
	 * @memberof ServerUtil
	 */
	setToken(token) {
		this.token = token;
	}

	/**
	 * Set/clear the pending callback. If set, when a request starts, pendingCallback will be called with `true`. When request finishes, it will be called with `false`
	 *
	 * @param {Function|null} cb
	 * @memberof ServerUtil
	 */
	setPendingCallback(cb) {
		this.pendingCallback = cb;
	}

	/**
	 * Call HTTP GET on `endpoint`, with optional args passed in the query string, returning Promise of {Object} - results from server
	 *
	 * @param {string} endpoint - URL on server to call
	 * @param {object} [arg={}]
	 * @returns {Promise} Resolves when server returns with result
	 * @memberof ServerUtil
	 */
	get(endpoint, arg = {}, opts = {}) {
		return this.call(endpoint, arg, {
			...opts,
			method: 'GET',
		});
	}

	/**
	 * Call HTTP POST on `endpoint`, with optional args passed in the body of request, returning Promise of {Object} - results from server
	 *
	 * @param {string} endpoint - URL on server to call
	 * @param {object} [arg={}]
	 * @returns {Promise} Resolves when server returns with result
	 * @memberof ServerUtil
	 */
	post(endpoint, arg = {}, opts = {}) {
		return this.call(endpoint, arg, {
			...opts,
			method: 'POST',
		});
	}

	/**
	 * Call HTTP PATCH on `endpoint`, with optional args passed in the body of request, returning Promise of {Object} - results from server
	 *
	 * @param {string} endpoint - URL on server to call
	 * @param {object} [arg={}]
	 * @returns {Promise} Resolves when server returns with result
	 * @memberof ServerUtil
	 */
	patch(endpoint, arg = {}, opts = {}) {
		return this.call(endpoint, arg, {
			...opts,
			method: 'PATCH',
		});
	}

	/**
	 * Call HTTP DELETE on `endpoint`, with optional args passed in the body of request, returning Promise of {Object} - results from server
	 *
	 * @param {string} endpoint - URL on server to call
	 * @param {object} [arg={}]
	 * @returns {Promise} Resolves when server returns with result
	 * @memberof ServerUtil
	 */
	delete(endpoint, arg = {}, opts = {}) {
		return this.call(endpoint, arg, {
			...opts,
			method: 'DELETE',
		});
	}

	/**
	 * Internal use only
	 *
	 * @param {*} httpData
	 * @returns
	 * @memberof ServerUtil
	 */
	// eslint-disable-next-line class-methods-use-this
	_encodeFields(httpData) {
		if (!httpData) return [];

		const fields = [];
		Object.keys(httpData).forEach((key) => {
			const val = httpData[key];
			if (Array.isArray(val)) {
				val.forEach((v, x) => {
					fields.push(
						`${encodeURIComponent(`${key}[${x}]`)}=${encodeURIComponent(v)}`,
					);
				});
			} else if (val && typeof val === 'object') {
				Object.keys(val).forEach((vk) => {
					fields.push(
						`${encodeURIComponent(`${key}[${vk}]`)}=${encodeURIComponent(
							JSON.stringify(val[vk]),
						)}`,
					);
				});
			} else if (val !== undefined && val !== null) {
				fields.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
			}
		});
		return fields;
	}

	/**
	 * Call HTTP POST (by default) on `endpoint`, with optional args passed in the body of request, returning Promise of {Object} - results from server
	 * If `options.method=='GET'`, then `args` will be passed by query string instead.
	 *
	 * @param {string} endpoint - URL on server to call
	 * @param {object} [arg={}] Args for the call
	 * @param {object} [options={method:'POST'}] Only .method is supported at the moment, defining the HTTP method to use
	 * @returns {Promise} Resolves when server returns with result
	 * @memberof ServerUtil
	 */
	async call(endpoint, args = {}, options = { method: 'POST' }) {
		// eslint-disable-next-line no-param-reassign
		options = Object.assign(
			{},
			{
				method: 'POST',
			},
			options || {},
		);

		// Allow {{pending-indicator}} to render a progress bar
		if (this.pendingCallback) this.pendingCallback(true);

		const { token } = this; // this.get('feathers.authToken'); // required to authenticate request to the endpoint
		const urlBase = this.urlRoot + endpoint; // config.feathers.socketUrl + endpoint;
		const httpData = args; // token ? Object.assign( {}, args,  { token }) : args;

		let fetchUrl = urlBase;
		if (options.method === 'GET') {
			const fields = this._encodeFields(httpData);

			if (fields.length) fetchUrl += `?${fields.join('&')}`;
		}

		// fetch() doesn't support keepalive and CORS Preflight at the same time,
		// so we will use sendBeacon if keepalive is set
		if (options.keepalive) {
			// Add Authorization token into body for posting (checked in server/plugins/auth.js)
			// if(token) {
			// 	httpData.Authorization = token;
			// }
			//
			// // Encode the data as a blob so we can set the content-type header as json
			// const data = new Blob([ this._encodeFields(httpData).join('&') ], { type: 'application/x-www-form-urlencoded;charset=UTF-8' });
			// const data = new Blob([ JSON.stringify(httpData) ], { type : 'application/json' });
			//
			// Send the beacon
			// navigator.sendBeacon(fetchUrl, data);

			// Resorting to sync XHR if keepalive set.
			// Why?
			// - sendBeacon won't allow application/json content type
			// - encoding data as application/x-www-form-urlencoded looses second-level objects for JSON objects
			// - fetch doesn't support keepalive and CORS preflight
			const client = new XMLHttpRequest();
			client.open('POST', fetchUrl, false); // third parameter indicates sync xhr
			client.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
			client.setRequestHeader('Authorization', token);
			client.send(JSON.stringify(httpData));

			// sendBeacon doesn't return anything, so just resolve incase anyone expects a promise here
			return Promise.resolve();
		}

		const authHeader = {};
		if (token) {
			authHeader.Authorization = token;
		}

		// this._permission = defer();

		const { autoRetry } = options;
		// eslint-disable-next-line no-param-reassign
		if (autoRetry) delete options.autoRetry;

		const retryArgs = {
			// Allow autoRetry:true || autoRetry: { retries: Number }
			// eslint-disable-next-line no-nested-ternary
			retries: autoRetry ? (autoRetry.retries ? autoRetry.retries : 10) : 0,
		};

		const errorCatcher = (err /* , bail */) => {
			// Allow {{pending-indicator}} to render a progress bar
			if (this.pendingCallback) this.pendingCallback(false);

			// Errors from Stripe are propogated in success() as res.error,
			// so we should never get this error() handler here
			// eslint-disable-next-line no-console
			console.error(
				'[service:middleware.call/catch]',
				{ endpoint, httpData, options, token },
				' failure: ',
				err && err.statusText ? { reason: err.statusText, error: err } : err,
			); // eslint-disable-line no-console
			// console.re.error("[custom-adapter.customServiceCall]", { modelName, callMethod }, " failure: ", err && err.statusText ? { reason: err.statusText, error: err } : err ); // eslint-disable-line no-console
			// alert("Problem replaying events");

			// bail && bail("Caught something...check console");
			// return err;
			throw err;
		};

		// console.log("[DEBUG:ServerUtil] autoRetry:", autoRetry," for ", fetchUrl, retryArgs);

		const body =
			options.method === 'GET' ? undefined : JSON.stringify(httpData);

		// console.log("[ServerUtil]", options.method, fetchUrl, ":", body);

		let retryCount = 0;
		return retry(async (bail) => {
			retryCount++;
			if (retryCount > 1) {
				// eslint-disable-next-line no-console
				console.warn('[ServerUtil] autoRetry #', retryCount, ': ', fetchUrl);
			}

			const res = await fetch(fetchUrl, {
				// Spread options hash instead of cherry-picking
				// so we can pass in arbitrary options. Right now,
				// this is used for {keepalive:true} in ServerStore.postMetrics
				...options,
				// data:   httpData,
				mode: 'cors', // no-cors, cors, *same-origin
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				credentials: 'same-origin', // include, same-origin, *omit
				headers: {
					'Content-Type': 'application/json; charset=utf-8',
					...authHeader,
					// "Content-Type": "application/x-www-form-urlencoded",
				},
				redirect: 'follow', // manual, *follow, error
				referrerPolicy: 'no-referrer', // no-referrer, *client
				body, // body data type must match "Content-Type" header
			}); // .catch(err => errorCatcher);

			// Add auto-retry for >501 and <600 (e.g. don't retry 501, bit other 5xx can retry)
			if (res.status >= 500 && res.status <= 599) {
				if (autoRetry) {
					// Per https://github.com/zeit/async-retry#usage,
					// anything throws, we retry
					throw new Error('Retry, pretty please.');
				} else {
					// console.error("[ERROR RESULT FROM SERVER]", res, await res.json());
					let json;
					try {
						json = await res.json();
					} catch (e) {
						// silently ignore
					}
					bail(
						new Error(
							json && json.message
								? json.message
								: `Error Status ${res.status} from server`,
						),
					);
					return res;
				}
			}
			// // Return 501's right to the client
			// if(res.status === 500) {
			// 	bail(new Error("Internal Server Error"));
			// 	return res;
			// } else
			// Return 403's right to the client
			else if (res.status === 403) {
				bail(new Error('Unauthorized'));
				return res;
			}

			// Got here? Good, should be able to parse and return data
			const json = await res.json();

			// Allow {{pending-indicator}} to render a progress bar
			if (this.pendingCallback) this.pendingCallback(false);

			if (json && json.error) {
				console.error("[service:middleware.call/json]", { endpoint, httpData, options, token }, " failure: ", json.error ); // eslint-disable-line
			}

			return json;
		}, retryArgs).catch(errorCatcher);
	}
}

const { apiHost, tcpPort } = AppConfig;

const hostname = `${global.window && global.window.location.hostname}`;

let ip = apiHost;
if (ip.includes(`localhost:${tcpPort}`)) {
	ip = `${hostname}:${tcpPort}`;
}

const isNgrok = `${hostname}`.includes(`.ngrok.io`);
if (isNgrok) {
	ip = AppConfig.backendTunnelHost;
}

export const url =
	process.env.NODE_ENV === 'production' ||
	`${AppConfig.apiHost}`.includes(`vayadriving.com`)
		? `https://${AppConfig.apiHost}`
		: `${window.location.protocol}//${ip}`;

export const server = new ServerUtil(url);

// export const server = new ServerUtil(
// 	'https://api.vayadriving.com'
// );

export default ServerUtil;
// window.server = server;
