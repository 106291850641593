/* eslint-disable no-console, no-unused-vars */
import {
	useMemo,
	useRef,
	createContext,
	useState,
	useContext,
	forwardRef,
} from 'react';

import {
	useLocation,
	useHref,
	useLinkClickHandler,
} from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';

// See also https://material-ui.com/guides/composition/#caveat-with-inlining

/**
 * Creates a ListItem that contains a react-router-dom Link element that also properly forwards the ref.
 * Can handle either local hrefs (like /accounts) or absolute hrefs ("https://..."). If absolute href, uses native browser <a...> tags instead of react-router-dom links
 */
export const ListItemLink = forwardRef(
	(
		{ href: to, children, replace, state, target, onClick, ...props },
		ref,
	) => {
		let href = useHref(to);
		let handleClick = useLinkClickHandler(to, {
			replace,
			state,
			target,
		});

		return (
			<ListItem
				button
				component={'a'} // href.startsWith('/') ? Link : 'a'}
				{...props}
				// to={href}
				href={href}
				ref={ref}
				onClick={(event) => {
					if (onClick) {
						onClick(event);
					}
					if (!event.defaultPrevented) {
						handleClick(event);
					}
				}}
			>
				{children}
			</ListItem>
		);
	},
);
