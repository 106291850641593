/**
 * Utility to process elements of an array in sequence, `await`ing your
 * callback for each element before processing the next element.
 *
 * Think of it basically like `Array.map` but for async callbacks.
 *
 * Returns the results of each callback, just like `Array.map` would do.
 *
 * The difference between `promiseMap` and `Promise.all` is that `Promise.all`
 * runs all promises at once, whereas this processes items in sequence.
 *
 * @param {Array} list Array or `Promise` for an Array (will `await` the `list` value internally before processing)
 * @param {function} next Async called to process each element of the array, passed two args: `element` and `index`, where `element` is the current value of the array at row `index`. Return values are collected and returned in a list in the same order processed. This callback must finish before the next element is processed.
 * @param {bool|string} debug [default=`null`] If a truthy value is passed, will log to console along with a progress message. False by default.
 * @returns {Promise} `Promise` that resolves to an `Array` containing the return values from the `next` callback for each element.
 */
export async function promiseMap(
	list = null,
	next = (/* d, idx */) => {},
	debug = null,
) {
	const all = Array.from((await list) || []);
	const total = all.length;
	const done = [];

	let p = Promise.resolve();
	all.forEach((d, idx) => {
		p = p
			.then(() => next(d, idx))
			.then((result) => {
				done.push(result);
				if (debug) {
					// eslint-disable-next-line no-console
					console.log(`[promiseAll:${debug}] Done ${done.length} / ${total}`);
				}
			});
	});
	await p;
	return done;
}
