/* eslint-disable prettier/prettier */
/**
 * @namespace MessageTypes
 * Reusable type name definitions for messages both from websockets and emitted internally
 */
export const MessageTypes = {
	// Ping/Pong to keep nginx from killing sockets
	PingMessage:                        "ping_message",          
	PongMessage:                        "pong_message",         

	// Allow clients to sub/unsub from adapter pools.
	// Currently used for sub/unsub to conversations
	ClientPoolSubscribe:                "client_pool_sub_message",          
	ClientPoolUnsubscribe:              "client_pool_unsub_message",         
	
	// New Inbound Messages
	ConversationMessagesReceived:       "conversation_messages_received",
	MessageStatusUpdated:               "message_status_updated",
	ConversationUserUpdated:            "conversation_user_updated",

	ChatTypingInProgress:               "chat_typing_in_progress",
	ChatTypingStopped:                  "chat_typing_stopped",

	// Sent when AI finishes classifying the conversation
	ConversationFollowupStatusChanged:  "conversation_followup_status_changed",

	// Attachment completed processing, etc
	AttachmentStatusUpdated:            "attachment_status_updated",

	// // TODO: Remove these if we don't have IOT device, just use hub in cloud?
	// StatusMessage:                      "status_message",          
	// GetStatusMessage:                   "get_system_status_message",     
	// SystemStatusMessage:                "system_state_message",          

	// // TODO: Remove these if we don't have IOT device, just use hub in cloud?
	// GetWorkspaceMessage:                  "get_workspace_message",           
	// WorkspaceMessage:                     "workspace_message",               
	// SyncWorkspaceMessage:                 "sync_proj_message",             

	// TODO: Remove this from clients?
	DebugSocketMessage:                 "debug_socket_message",          

	// Client implementation in shared TimeSyncUtil class
	TimeSyncMessage:                    "time_sync_message",             

	// Used during the auth flow BEFORE attempting auth to validate the PIN
	// and decide (a) if it's a valid PIN and (b) if it's "just" a workspace
	// PIN (which requires further auth), or if it's a user PIN which
	// then the client can re-transmit down as a PINAuthorizationMessage
	PINValidationMessage:               "pin_validation_message",         

	// Wraps HTTP calls ... users could call over HTTP, but if we already have
	// socket set up, this lets them re-use REST API.
	// Currently, as of this writing, they have to auth over socket anyway,
	// and socket doesn't require setup/teardown of an HTTP session for every call
	WebSocketMessageWrapper:            "websocket_message_wrapper",     

	//= ===================================================================
	// Authorization-related Messages
	//= ===================================================================

	// TBD: Disable anon?
	AnonymousAuthorizationMessage:      "anonymous_auth_msg",             
	// User/pas auth because social is annoying in dev
	UserPassAuthorizationMessage:       "userpass_auth_msg",              
	// Signup flow
	UserPassSignupMessage:              "userpass_signup_msg",            
	// Like user/pass, must be done after workspace identified though
	SocialAuthorizationMessage:         "social_auth_message",           
	// *  PIN is both manual-entry or QR-code
	// Enables easier user sign-in on mobile devices.
	// Note: This is only used if the PIN code is a "user" pin code (TBD).
	// "Workspace" PIN codes are used to identify the workspace,
	// then auth is done AFTER that...
	PINAuthorizationMessage:            "pin_authorization_message",     
	
	// Re-auth with existing stored token (refresh?)
	JWTAuthorizationMessage:            "jwt_auth_message",              
	
	// * Reply back telling client they are authd
	AuthorizedMessage:                  "authorized_message",            
	// * Auth failure
	UnauthorizedMessage:                "unauthorized_message",
	// * Client log-out
	DeauthorizationMessage:             "deauth_message",       
	// * Accepting an email invite (authorizes the socket)
	AcceptInviteMessage:                "accept_invite_message",

	//= ===================================================================
	// Common messages for RTC
	//= ===================================================================
	RTCCallInitMessage:                 "rtc_call_init_message",
	RTCSignalingMessage:                "rtc_signaling_message",
	RTCCallEndMessage:                  "rtc_call_end_message",

	//= ===================================================================
	// JobManager and worker nodes
	//= ===================================================================
	JobAvailable:                       "job_available_message",
	JobAccept:                          "job_accepted_message",
	JobWorkerPing:                      "job_worker_ping_message",
	JobWorkerDead:                      "job_worker_dead_message",
	JobCompleted:                       "job_completed_message",
	JobServerError:                     "job_server_error_message",
	JobCapacityChanged:                 "job_capacity_changed_message",


	//= ===================================================================
	// Misc Messages
	//= ===================================================================
	TripStatusChanged:                  "trip_status_changed_nessage",     
	TripDriverGpsUpdate:                "trip_driver_gps_update_message",     
	TripRiderGpsUpdate:                 "trip_rider_gps_update_message",     
	TripGpsUpdate:                      "trip_gps_update_message",
	UserGpsUpdate:                      "user_gps_update_message",
	
	// Message type for our CloudFlare GPS workers
	GPSStreamMessage:                   "gps_stream_message",

	// Used to move from /account-pending to /members once stripe confirms payments
	AccountStatusChanged:               "account_status_changed",

	// Used when system config changes
	SystemConfigChanged:                "system_config_changed",

	// Notify a user (admin/support) when call changes for them
	CallStatusChanged:                  "call_status_changed",

	// From foreground sensors
	SensorStreamData:                   "sensor_stream_data",

	// Outbound for eventual monitoring 
	AuditLogEvent:                      "audit_log_event",

	// For use with Monday Views
	MondayItemSubscribeRequest:         "monday_item_subscribe_request",
	MondayItemSubscriptionUpdate:       "monday_item_subscription_update",
	MondayItemSubscriptionConfirmation: "monday_item_subscription_confirmation",
	MondayItemUpdateEvent:              "monday_item_update_event",
	MondayItemUnsubscribeRequest:       "monday_item_unsubscribe_request",

	// For use with Garage parking module
	GarageCameraFound:                  "garage_camera_found",
	GarageCameraImageIngested:          "garage_camera_image_ingested",
	GaragePlateLogCreated:              "garage_plate_log_created",

	// From RideLog model
	RideLogCreated:                     "ride_log_created",

	// Ride added/removed for a user - typically this will be broadcast to rider/driver when ride added
	RideListUpdated:                    "ride_list_updated",
	// Broadcast by RideStateEngine to a relevant parties
	RideStatusChanged:                  "ride_status_changed",
	// Broadcast to relevant parties when a ride has been edited (inside updateRideFromJson)
	RideModified:                       "ride_modified",


	//= ===================================================================
	// Valet Parking project
	//= ===================================================================
	
	// When ticket...changes status...made by the logs
	ValetTicketStatusChanged:            "valet_ticket_status_changed",

	// Added for RxDb replication
	ValetTicketUpdated:                 "valet_ticket_updated",

	// When a log is created...
	ValetTicketLogCreated:               "valet_ticket_log_created",

	// When the ticket number changes...so we can keep clients in-sync
	ValetStandNextTicketNum:             "valet_stand_next_ticket_num",

	// When stand opens/closes so UIs can all update
	ValetStandSessionChanged:            "valet_stand_session_changed",

	// When stand settings change - also so UIs can update
	ValetStandSettingsChanged:           "valet_stand_settings_changed",

	// When we receive webhooks from stripe for payments
	ValetTicketPaymentUpdated:           "valet_ticket_payment_updated",

	// When we receive webhooks from stripe about the reader itself
	ValetStripeReaderUpdated:            "valet_stripe_reader_updated",

	// Send by ParkReminderCron along with push notifications
	ValetTicketParkingReminder:          "ValetTicketParkingReminder",
	
	//= ===================================================================
	// Garage Controller project
	//= ===================================================================
	// GarageController -> Provision to a tenant
	GCtrlTenantProvision:               "gctrl_tenant_provision",
	// GarageController -> Release from a tenant
	GCtrlTenantRelease:                 "gctrl_tenant_release",
	
	// GarageController -> Software Update requested (e.g. controller should
	// contact the cloud and download latest tarball and install and switch to new software
	// when possible)
	// TODO ....
	GCtrlSoftwareUpdate:                "gctrl_software_update",
	
	// GarageController -> Config updated in the UI, controller should call home for new config
	GCtrlConfigUpdated:                 "gctrl_config_updated",
	
	// GarageController -> "Call home" and open a tunnel on the requested port to the local SSH port
	// Used for tech support / debugging remotely
	// TODO ....
	GCtrlOpenTunnelRequest:             "gctrl_open_tunnel_request",
	
	// GarageController -> Open a gate remotely via the UI
	GCtrlRemoteGateOpen:                "gctrl_remote_gate_open",
	
	// GarageController -> Streaming logs from on-prem to the cloud for ... logging
	// TODO ....
	GCtrlLogTelemetry:                  "gctrl_log_telemetry",

	// TODO: Write these up....
	GCtrlIdentitySignal:                "gctrl_identity_signal",
	GCtrlAccessDetermination:           "gctrl_access_determination",
	
	GCtrlVehicleAtGate:                 "gctrl_vehicle_at_gate",
	
	GCtrlGateOpened:                    "gctrl_gate_opened",
	GCtrlGateClosed:                    "gctrl_gate_closed",
	
	GCtrlLaneImage:                     "gctrl_lane_image",

	GCtrlHealthStatus:                  "gctrl_health_status",

	//= ===================================================================
	// Notepad realtime updates
	//= ===================================================================

	NotepadLockChanged:                 "notepad_lock_changed",
	NotepadContentChanged:              "notepad_content_changed",
	NotepadListChanged:                 "notepad_list_changed",

	// RTC Devel Testing
	RegisterSocketAsRTC:                "socket_register_rtc",
	UnregisterSocketAsRTC:              "socket_unregister_rtc",



	//= ===================================================================
	// Notifications module
	//= ===================================================================

	NotificationChanged:				"notification_changed",

};

/* eslint-enable prettier/prettier */
export default MessageTypes;

export function isAuthorizationMessage(type) {
	return [
		// TBD: Disable anon?
		MessageTypes.AnonymousAuthorizationMessage,
		// User/pas auth because social is annoying in dev
		MessageTypes.UserPassAuthorizationMessage,
		// Signup flow
		MessageTypes.UserPassSignupMessage,
		// PIN is both manual-entry or QR-code
		// Enables easier user sign-in on mobile devices.
		// Note: This is only used if the PIN code is a "user" pin code (TBD).
		// "Workspace" PIN codes are used to identify the workspace,
		// then auth is done AFTER that...
		MessageTypes.PINAuthorizationMessage,
		// Like user/pass, must be done after workspace identified though
		MessageTypes.SocialAuthorizationMessage,
		// Re-auth with existing stored token (refresh?)
		MessageTypes.JWTAuthorizationMessage,
		// Reply back telling client they are authd
		MessageTypes.AuthorizedMessage,
		// Auth failure
		MessageTypes.UnauthorizedMessage,
		// Client log-out
		MessageTypes.DeauthorizationMessage,
	].includes(type);
}

export function isPublicMessage(type) {
	return [
		MessageTypes.TimeSyncMessage,
		MessageTypes.GetStatusMessage,
		MessageTypes.PINValidationMessage,
	].includes(type);
}

export function isWebRtcMessage(type) {
	return [
		MessageTypes.RTCCallInitMessage,
		MessageTypes.RTCCallEndMessage,
		MessageTypes.RTCSignalingMessage,
	].includes(type);
}

export function isJobMessage(type) {
	return [
		MessageTypes.JobAvailable,
		MessageTypes.JobAccept,
		MessageTypes.JobWorkerPing,
		MessageTypes.JobWorkerDead,
		MessageTypes.JobCompleted,
		MessageTypes.JobServerError,
		MessageTypes.JobCapacityChanged,
	].includes(type);
}

export function isPoolSubMessage(type) {
	return [
		MessageTypes.ClientPoolSubscribe,
		MessageTypes.ClientPoolUnsubscribe,
	].includes(type);
}

// const SwitcherMessages = [
// 	MessageTypes.GetCamerasListMessage,
// 	MessageTypes.CamerasListMessage,
// 	MessageTypes.SwitcherCameraStatusMessage,
// 	MessageTypes.SwitchLiveCameraMessage,
// ];

// export function isSwitcherMessage(type) {
// 	return SwitcherMessages.includes(type);
// }

// // TODO: Depreciate these - move this functionality elsewhere
// export function isAdminMessage(type) {
// 	return [
// 		...SwitcherMessages,
// 		MessageTypes.RegisterAdminMessage,
// 		MessageTypes.UnregisterAdminMessage,
// 		MessageTypes.PeopleListMessage,
// 		MessageTypes.FaceListMessage,
// 		MessageTypes.PersonCreatedMessage,
// 		MessageTypes.MergePeopleMessage,
// 		MessageTypes.UpdatePersonMessage,
// 		MessageTypes.DeleteFacesMessages,
// 	].includes(type);
// }

// export function isCameraMessage(type) {
// 	return [
// 		MessageTypes.RegisterCameraMessage,
// 		MessageTypes.CameraRegisteredMessage,
// 		MessageTypes.ClientCameraStatusMessage,
// 		MessageTypes.FrameAnalysisMessage,
// 		MessageTypes.UnregisterCameraMessage,
// 		MessageTypes.VideoEncoderStatusMessage,
// 		MessageTypes.EncodedBlobHeaderMessage,
// 		MessageTypes.EventServiceStatusMessage,
// 	].includes(type);
// }
