/* eslint-disable no-unused-vars */
import { SecurityLevels } from './SecurityLevels';

export const SystemApps = {
	Users: 'users',
	Chats: 'chats',
	ChatBots: 'chatbots',
	Rides: 'rides',
	Garage: 'garage',
	Valet: 'valet',
	Viribuz: 'viribuz',
	Billing: 'billing',
	Schedules: 'schedules',
	CuEx: 'cuex',
	BizRepMgmt: 'biz_rep_mgmt',
	PinkAi: 'pink_ai',
};

export const SystemAppList = [
	{
		id: SystemApps.Users,
		label: 'People',
		href: '/users',
		icon: 'UserGroupIcon', // HeroIcon name
		description: `Customer/Account directory and profiles`,
	},

	{
		id: SystemApps.Chats,
		label: 'Chats',
		href: '/chats',
		icon: 'ChatBubbleLeftRightIcon', // HeroIcon named
		description: `Chat with customers of the organization`,
	},

	{
		id: SystemApps.ChatBots,
		label: 'Chat Bots',
		href: '/chatbots',
		icon: 'BoltIcon', // HeroIcon named
		description: `Chat with customers of the organization`,
	},

	{
		id: SystemApps.Rides,
		label: 'Rides',
		href: '/rides',
		icon: 'GlobeAltIcon', // HeroIcon name
		description: `Ride administration and management`,
		billing: {
			//
		},
	},

	// {
	// 	id: 'drivers',
	// 	name: `Driver's Dashboard`,
	// 	href: '/drivers',
	// 	icon: 'TruckIcon', // HeroIcon name
	// 	description: `Driver app for driving/viewing Rides`,
	// },
	// {
	// 	id: 'riders',
	// 	name: `Rider's Dashboard`,
	// 	href: '/riders',
	// 	icon: 'GlobeAltIcon', // HeroIcon name
	// 	description: `Rider app for booking/viewing Rides`,
	// },

	{
		id: SystemApps.Garage,
		label: 'Garage',
		href: '/garage',
		icon: 'VideoCameraIcon', // HeroIcon name
		description: `Garage parking management`,
	},

	{
		id: SystemApps.Valet,
		label: 'Valet',
		href: '/valet',
		icon: 'BellIcon', // HeroIcon name
		description: `Valet parking management`,
		billing: {
			// TODO
		},
		// Auth service uses this flag to add a custom token
		// to our auth packet so user can auth to firestore on client
		firestoreEnabled: true,
	},

	{
		id: SystemApps.Billing,
		label: 'Billing',
		href: '/billing',
		icon: 'CurrencyDollarIcon', // HeroIcon name
		description: `Payments and invoices`,
	},

	{
		id: SystemApps.Viribuz,
		label: 'Viribuz',
		href: '/viribuz',
		icon: 'GlobeAltIcon', // HeroIcon name
		description: `Viribuz Custom Apps`,
	},

	{
		id: SystemApps.CuEx,
		label: 'CuEx',
		href: '/cuex',
		icon: 'UserGroupIcon', // HeroIcon name
		description: `Custom Tailed Experience Apps`,
	},

	{
		id: SystemApps.BizRepMgmt,
		label: 'Biz Reputation',
		href: '/biz_rep_mgmt',
		icon: 'UserGroupIcon', // HeroIcon name
		description: `Business Reputation Management`,
	},

	{
		id: SystemApps.PinkAi,
		label: 'Pink AI',
		href: '/pink',
		icon: 'SparklesIcon', // HeroIcon name
		description: `Pink AI`,
	},
];

export const AppRoles = {
	ChatAdmin: `${SystemApps.Chats}.admin`,
	ChatSupport: `${SystemApps.Chats}.support`,
	// TBD diff between manager and user
	ChatBotManager: `${SystemApps.Chats}.bot.manager`,
	ChatBotUser: `${SystemApps.Chats}.bot.user`,
	// ---
	GarageSupport: `${SystemApps.Garage}.support`,
	//-
	ValetAdmin: `${SystemApps.Valet}.employee.admin`,
	ValetManager: `${SystemApps.Valet}.employee.manager`,
	ValetCaptain: `${SystemApps.Valet}.employee.captain`,
	Valet: `${SystemApps.Valet}.employee.valet`,
	// ---
	RideManager: `${SystemApps.Rides}.manager`,
	RideDriver: `${SystemApps.Rides}.driver`,
	RideRider: `${SystemApps.Rides}.rider`,
	// ---
	ValetAccountManager: `${SystemApps.Valet}.account.manager`,
	ValetAccountUser: `${SystemApps.Valet}.account.user`,
	ValetAccountReportOnly: `${SystemApps.Valet}.account.user.report-only`,
	//-
	ValetResident: `${SystemApps.Valet}.account.resident`,
	ValetValidator: `${SystemApps.Valet}.account.validator`,
	//-
	ViribuzAdmin: `${SystemApps.Viribuz}.admin`,
	ViribuzEmployee: `${SystemApps.Viribuz}.employee`,
	ViribuzCustomer: `${SystemApps.Viribuz}.customer`,
	//-
	ValetShortTermResident: `${SystemApps.Valet}.short-term-resident`,
	ValetCustomer: `${SystemApps.Valet}.customer`,

	// Auto-upserting support for CuEx users
	CuExUser: `${SystemApps.CuEx}.user`,

	// Business Rep Mgmt
	BizRepMgmtSuperUser: `${SystemApps.BizRepMgmt}.super_user`,
	BizRepMgmtAdmin: `${SystemApps.BizRepMgmt}.admin`,
	BizRepMgmtUser: `${SystemApps.BizRepMgmt}.user`,

	// Pink AI
	PinkAiSuperUser: `${SystemApps.PinkAi}.super_user`,
	PinkAiAdmin: `${SystemApps.PinkAi}.admin`,
	PinkAiUser: `${SystemApps.PinkAi}.user`,
};

// Note: If any role includes a "signupDescription",
// then if the associated App is enabled for the tenant AND the user has NO appRoles assigned,
// the next time thru the login flow, they will be able to elect to sign up for that role.
// They will have the option to skip, but they will be required to choose a role the next time thru the login flow
// for that tenant if they skip.
export const AppRolesList = [
	{
		id: AppRoles.RideManager,
		label: 'Ride Manager',
		icon: 'TruckIcon',
		description: 'User is a manager for the Rides App',
		systemApp: SystemApps.Rides,
		isInternalRole: true, // non-public role - only for internal use
		// signupDescription: 'I want to **drive** with Vaya Driving',
		// roleLoginDescription: `I am a **driver** with Vaya Driving`,
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel === SecurityLevels.Administrator &&
			tenantApps.includes(SystemApps.Rides),

		defaultSlug: '/rides',

		profilePath: (userId) => undefined, // `/rides/drivers/${userId}`,

		color: '#eab308', // tw-yellow-500
	},

	{
		id: AppRoles.ValetAdmin,
		label: 'Valet Administrator',
		icon: 'StarIcon',
		systemApp: SystemApps.Valet,
		isInternalRole: true, // non-public role - only for internal use
		description:
			'User has access to configure add/remove/manage client accounts, including validators and assist in management of all aspects of the valet operations',
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Valet),

		defaultSlug: '/valet/stands',

		// If this prop not set on an app role, we assume it's ".User"
		securityLevel: SecurityLevels.Administrator,

		profilePath: (userId) => `/valet/admin/employees/${userId}`,

		color: '#ef4444', // tw-red-500
	},
	{
		id: AppRoles.ValetManager,
		label: 'Valet Location Manager',
		icon: 'ClipboardDocumentCheckIcon',
		systemApp: SystemApps.Valet,
		isInternalRole: true, // non-public role - only for internal use
		description:
			'User manages one or more valet stands, and can open/close work shifts and make adjustments or run reports as needed',
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Valet),

		defaultSlug: '/valet/stands',

		profilePath: (userId) => `/valet/admin/employees/${userId}`,

		color: '#f97316', // tw-orange-500
	},

	{
		id: AppRoles.ChatAdmin,
		label: 'Chat Admin',
		icon: 'ChatBubbleLeftRightIcon',
		description:
			'User who can modify the chat system settings and have full access to chats',
		systemApp: SystemApps.Chats,
		isInternalRole: true, // non-public role - only for internal use
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Chats),

		defaultSlug: '/chats',

		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#6b7280', // tw-gray-500
	},
	{
		id: AppRoles.ChatBotManager,
		label: 'Chat Bot Manager',
		icon: 'BoltIcon',
		description: 'User who can add and modify chat bots and their settings',
		systemApp: SystemApps.ChatBots,
		isInternalRole: true, // non-public role - only for internal use
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Chats),

		defaultSlug: '/chatbots',

		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#6b7280', // tw-gray-500
	},

	{
		id: AppRoles.ChatSupport,
		label: 'Chat Support',
		icon: 'ChatBubbleLeftRightIcon',
		description:
			'User can access the company side of the chat system and chat with people as a representative of the company',
		systemApp: SystemApps.Chats,
		isInternalRole: true, // non-public role - only for internal use
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Chats),

		defaultSlug: '/chats',

		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#6b7280', // tw-gray-500
	},

	{
		id: AppRoles.ChatBotUser,
		label: 'Chat Bot User',
		icon: 'BoltIcon',
		description: 'User who can view and use chat bots and their settings',
		systemApp: SystemApps.ChatBots,
		isInternalRole: true, // non-public role - only for internal use
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Chats),

		defaultSlug: '/chatbots',

		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#6b7280', // tw-gray-500
	},
	{
		id: AppRoles.RideDriver,
		label: 'Driver',
		icon: 'TruckIcon',
		description: 'User is a driver for the Rides app',
		systemApp: SystemApps.Rides,
		signupDescription: 'I want to **drive** with Vaya Driving',
		roleLoginDescription: `I am a **driver** with Vaya Driving`,
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Rides),

		defaultSlug: '/drivers',

		profilePath: (userId) => `/rides/drivers/${userId}`,

		color: '#eab308', // tw-yellow-500
	},
	{
		id: AppRoles.RideRider,
		label: 'Rider',
		icon: 'GlobeAltIcon',
		description: 'User is a rider in the Rides app',
		systemApp: SystemApps.Rides,
		signupDescription: 'I want to **take rides as a rider** with Vaya drivers',
		roleLoginDescription: `I am a **member** of Vaya Driving and I take rides with **private drivers**`,
		visible: ({ tenantApps, securityLevel }) =>
			tenantApps.includes(SystemApps.Rides),

		defaultSlug: '/riders',

		profilePath: (userId) => `/rides/members/${userId}`,

		color: '#22c55e', // tw-green-500
	},
	{
		id: AppRoles.GarageSupport,
		label: 'Garage Support',
		icon: 'LifebuoyIcon',
		description:
			'User can access the garage management app and manage client accounts and assist with any parking management issues that may arise',
		roleLoginDescription: `I work at a **parking garage**`,
		systemApp: SystemApps.Garage,
		isInternalRole: true, // non-public role - only for internal use
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Garage),

		defaultSlug: '/garage',

		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#6b7280', // tw-gray-500
	},
	// {
	// 	id: AppRoles.ValetSupport,
	// 	label: 'Valet Support',
	// 	icon: 'LifebuoyIcon',
	// 	description:
	// 		'User can access the valet management app and manage client accounts and assist with any parking management issues that may arise',
	// 	visible: ({ tenantApps, securityLevel }) =>
	// 		securityLevel !== SecurityLevels.Public &&
	// 		tenantApps.includes(SystemApps.Valet),

	// 	// TBD for clients
	// 	defaultSlug: '/valet/admin',

	// 	profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

	// 	color: '#eab308', // tw-yellow-500
	// },

	{
		id: AppRoles.ValetAccountManager,
		label: 'Valet Client Manager',
		icon: 'BanknotesIcon',
		systemApp: SystemApps.Valet,
		isInternalRole: true, // non-public role - only for internal use
		description:
			'User can log in from the client account to run reports and access all information about their account with this parking management tenant, including modifying billing/ACH settings for their client account (limited to the Account they are a member of.)',
		roleLoginDescription: `I want to see information about valet activity at my company`,
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Valet),

		// TBD for clients - just putting this here to have *something*
		defaultSlug: '/valet/clients',

		// TBD - Since they are "under" an account, need to look up accountId first somehow
		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#6366f1', // tw-indigo-500
	},
	{
		id: AppRoles.ValetValidator,
		label: 'Valet Validator',
		icon: 'ClipboardDocumentCheckIcon',
		systemApp: SystemApps.Valet,
		description:
			'User is allowed to log into the Valet Validation portal and validate tickets',
		roleLoginDescription: `I want to **validate Valet tickets** on behalf of my company`,
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Valet),

		defaultSlug: '/valet/validations',

		// TBD - Since they are "under" an account, need to look up accountId first somehow
		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#6366f1', // tw-indigo-500
	},
	{
		id: AppRoles.ValetCaptain,
		label: 'Valet Captain',
		icon: 'AcademicCapIcon',
		systemApp: SystemApps.Valet,
		isInternalRole: true, // non-public role - only for internal use
		description:
			'User can be a valet captain or more valet stands, and can open/close work shifts and make adjustments or run reports as needed',
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Valet),

		defaultSlug: '/valet/stands',

		profilePath: (userId) => `/valet/admin/employees/${userId}`,

		color: '#eab308', // tw-yellow-500
	},

	// Moved "under" captain and location manager so those guys (if they share the roles)
	// will land on stand first and not this route
	{
		id: AppRoles.ValetAccountUser,
		label: 'Valet Client User',
		icon: 'DocumentChartBarIcon',
		systemApp: SystemApps.Valet,

		description:
			'User can log in from the client account to run reports about their client account and perform validations (limited to the Account they are a member of.)',
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Valet),

		// TBD for clients - just putting this here to have *something*
		defaultSlug: '/valet/clients',

		// TBD - Since they are "under" an account, need to look up accountId first somehow
		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#6366f1', // tw-indigo-500
	},

	// Moved "under" captain and location manager so those guys (if they share the roles)
	// will land on stand first and not this route
	{
		id: AppRoles.ValetAccountReportOnly,
		label: 'Valet Client User - Reporting Only',
		icon: 'DocumentChartBarIcon',
		systemApp: SystemApps.Valet,

		description:
			'User can log in from the client account to run reports, they will not have access to anything other than the reports tab (limited to the Account they are a member of.)',
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Valet),

		// TBD for clients - just putting this here to have *something*
		defaultSlug: '/valet/clients',

		// TBD - Since they are "under" an account, need to look up accountId first somehow
		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#eab308', // tw-indigo-500
	},

	{
		id: AppRoles.Valet,
		label: 'Valet',
		icon: 'TicketIcon',
		systemApp: SystemApps.Valet,
		isInternalRole: true, // non-public role - only for internal use
		description:
			'User is a valet at one or more valet stands and can intake/retrieve vehicles and chat with customers as needed',
		roleLoginDescription: `I work for a **valet company** as a valet employee`,
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Valet),

		defaultSlug: '/valet/stands',

		profilePath: (userId) => `/valet/admin/employees/${userId}`,

		color: '#22c55e', // tw-green-500
	},

	{
		id: AppRoles.ViribuzAdmin,
		label: 'Viribuz Admin',
		icon: 'ClipboardDocumentCheckIcon',
		systemApp: SystemApps.Viribuz,
		isInternalRole: true, // non-public role - only for internal use
		description:
			'User is a Viribuz admin and allowed to access all Viribuz custom apps',
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Viribuz),

		defaultSlug: '/viribuz/clients',

		profilePath: (userId) => `/valet/admin/users/${userId}`,

		color: '#22c55e', // tw-green-500
	},

	{
		id: AppRoles.ViribuzEmployee,
		label: 'Viribuz Employee',
		icon: 'ClipboardDocumentCheckIcon',
		systemApp: SystemApps.Viribuz,
		isInternalRole: true, // non-public role - only for internal use
		description:
			'User is a Viribuz employee and allowed basic access to Viribuz custom apps',
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Viribuz),

		defaultSlug: '/viribuz/admin',

		profilePath: (userId) => `/valet/admin/users/${userId}`,

		color: '#22c55e', // tw-green-500
	},

	{
		id: AppRoles.BizRepMgmtSuperUser,
		label: 'Business Rep Mgmt Super User',
		icon: 'BoltIcon',
		description: 'User who can access and see all Biz Rep Mgmt client accounts',
		systemApp: SystemApps.BizRepMgmt,
		isInternalRole: true, // non-public role - only for internal use
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.BizRepMgmt),

		defaultSlug: '/biz_rep_mgmt/admin',

		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#6b7280', // tw-gray-500
	},

	{
		id: AppRoles.BizRepMgmtAdmin,
		label: 'Business Rep Mgmt Admin',
		icon: 'BoltIcon',
		description: 'Owner of a specific Biz Rep Mgmt client account',
		systemApp: SystemApps.BizRepMgmt,
		isInternalRole: true, // non-public role - only for internal use
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.BizRepMgmt),

		defaultSlug: '/biz_rep_mgmt/',

		profilePath: (userId) => `/biz_rep_mgmt/users/${userId}`,

		color: '#6b7280', // tw-gray-500
	},

	{
		id: AppRoles.BizRepMgmtUser,
		label: 'Business Rep Mgmt User',
		icon: 'BoltIcon',
		description: 'User who can access their own Biz Rep Mgmt account',
		systemApp: SystemApps.BizRepMgmt,
		isInternalRole: true, // non-public role - only for internal use
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.BizRepMgmt),

		defaultSlug: '/biz_rep_mgmt/',

		profilePath: (userId) => `/biz_rep_mgmt/users/${userId}`,

		color: '#6b7280', // tw-gray-500
	},

	{
		id: AppRoles.PinkAiSuperUser,
		label: 'Pink AI Super User',
		icon: 'SparklesIcon',
		description: 'User who can access and see all Pink AI client accounts',
		systemApp: SystemApps.PinkAi,
		isInternalRole: true, // non-public role - only for internal use
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.PinkAi),

		defaultSlug: '/pink/admin',

		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#6b7280', // tw-gray-500
	},

	{
		id: AppRoles.PinkAiAdmin,
		label: 'Business Rep Mgmt Admin',
		icon: 'SparklesIcon',
		description: 'Owner of a specific Pink AI account',
		systemApp: SystemApps.PinkAi,
		isInternalRole: true, // non-public role - only for internal use
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.PinkAi),

		defaultSlug: '/pink/',

		profilePath: (userId) => `/pink/users/${userId}`,

		color: '#6b7280', // tw-gray-500
	},

	{
		id: AppRoles.PinkAiUser,
		label: 'Pink AI User',
		icon: 'SparklesIcon',
		description: 'User who can access their own Pink AI account',
		systemApp: SystemApps.PinkAi,
		isInternalRole: true, // non-public role - only for internal use
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.BizRepMgmt),

		defaultSlug: '/pink/',

		profilePath: (userId) => `/pink/users/${userId}`,

		color: '#6b7280', // tw-gray-500
	},

	{
		id: AppRoles.ViribuzCustomer,
		label: 'Viribuz Customer',
		icon: 'ClipboardDocumentCheckIcon',
		systemApp: SystemApps.Viribuz,
		description:
			'User is a Viribuz Customer and only has access to specific locations',
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Viribuz),

		defaultSlug: '/viribuz/clients',

		profilePath: (userId) => `/valet/admin/users/${userId}`,

		color: '#22c55e', // tw-green-500
	},

	{
		id: AppRoles.CuExUser,
		label: 'User',
		icon: 'UserGroupIcon',
		description: 'User is a user of a CuEx app',
		systemApp: SystemApps.CuEx,
		signupDescription: 'I want to use this app',
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public, // &&
		// tenantApps.includes(SystemApps.Rides),

		defaultSlug: '/cuex',

		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#6b7280', // tw-gray-500
	},

	{
		// Add this role for customers automatically
		id: AppRoles.ValetShortTermResident,
		label: 'Valet Short-Term Guest Resident',
		icon: 'UserIcon',
		systemApp: SystemApps.Valet,
		description: 'User can access the customer short-term residential features',
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Valet),

		defaultSlug: '/valet/customers',

		// TBD for Valet
		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#22c55e', // tw-green-500
	},

	{
		// Add this role for customers automatically
		id: AppRoles.ValetCustomer,
		label: 'Valet Customer',
		icon: 'UserIcon',
		systemApp: SystemApps.Valet,
		description: 'User can access the valet portal',
		signupDescription:
			"I'm a customer of a company or I live in a building that uses Vaya Valet",
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Valet),

		defaultSlug: '/valet/customers',

		// TBD for Valet
		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#22c55e', // tw-green-500
	},
	{
		id: AppRoles.ValetResident,
		label: 'Valet Resident',
		icon: 'HomeIcon',
		systemApp: SystemApps.Valet,
		description: 'User is a resident at a Valet Account location',
		// signupDescription: 'I live in a building with valet parking',
		roleLoginDescription: `I live in a building with valet parking`,
		visible: ({ tenantApps, securityLevel }) =>
			securityLevel !== SecurityLevels.Public &&
			tenantApps.includes(SystemApps.Valet),

		defaultSlug: '/valet/residents',

		// TBD - Since they are "under" an account, need to look up accountId first somehow
		profilePath: (userId) => undefined, // fallback to `/users/${userId}`,

		color: '#22c55e', // tw-green-500
	},
];

export const AppRoleListIndex = {};
AppRolesList.forEach((meta, idx) => {
	// eslint-disable-next-line no-param-reassign
	meta.index = idx;
	AppRoleListIndex[meta.id] = meta;
});

/**
 * An array of internal app role IDs (roles with meta .isInternalRole = true)
 * @type {Array<string>}
 */
export const InternalAppRoles = AppRolesList.filter(
	({ isInternalRole }) => isInternalRole,
).map(({ id }) => id);

/**
 * An array of external app role IDs (roles with meta .isInternalRole = false)
 * @type {Array<string>}
 */
export const ExternalAppRoles = AppRolesList.filter(
	({ isInternalRole }) => !isInternalRole,
).map(({ id }) => id);
