/* eslint-disable no-console */
import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import {
	// eslint-disable-next-line camelcase
	unstable_batchedUpdates,
} from 'react-dom';
import TripStatus from 'shared/utils/TripStatus';
import { useCarIconGenerator } from 'shared/hooks/useCarIcon';
import { useTripGps } from 'shared/utils/GpsStreamReceiver';
import { useGoogleJsApi, useGoogleMaps } from 'shared/hooks/useGoogleLoaded';
import { TrackingPath } from 'shared/hooks/TrackingPath';
import { useInstanceCache } from 'shared/hooks/useInstanceCache';

// Default GPS values for our refs below
const EMPTY_GPS = {
	lat: undefined,
	lng: undefined,
	heading: undefined,
};

export const useInjectTrackingPath = (
	tripPath,
	currentTrip,
	{
		gpsStreamSource = 'driver',
		// Allow callers to use original bounds
		// for example, when editing a trip while trip is active
		useOriginalBounds = false,
	},
) => {
	const { bounds, paths, pathMarkers } = tripPath || {};
	const { id: tripId, activeTripLeg /* driver, user */ } = currentTrip || {};
	// const { id: driverId } = driver || {};
	// const { id: userId } = user || {};
	const {
		id: legId,
		startPlace: origin,
		stopPlace: destination,
		status: legStatus,
	} = activeTripLeg || {};

	// We use this condition several places below to actually active the tracking.
	// If the leg is not live, this hook just functions like a pass-thru for tripPath
	const isLegLive = legStatus === TripStatus.Riding;

	// This is the state we'll use for communicating externally (our
	// return value is injectedTripPath)
	const [injectedTripPath, setInjectedTripPath] = useState({
		bounds,
		paths,
		pathMarkers,
		// this is a monkey-patched prop, used to updated
		// arrival times in TripComposerContext, etc
		timeRemaining: undefined,
	});

	// Need the 'google' ref to pass into TrackingPath
	const { google } = useGoogleJsApi();

	// Use the maps ref just to ensure the entire Google Maps
	// library has completely loaded
	const maps = useGoogleMaps();

	// Build our options for constructing a TrackingPath
	// The keys are the same ones expected by the TrackingPath constructor,
	// but must memoize so we can provide a stable value reference to
	// the useInstanceCache hook
	const pathOptions = useMemo(
		() => ({
			origin,
			destination,
			loggingContextProps: {
				tripId,
				legId,
				gpsStreamSource,
			},
			// Used to create a unique ID for use as a key when rendering
			// the <Polyline> components in the map widgets
			pathIdPrefix: `tracking_path_trip_${tripId}_leg_${legId}`,
			// We really only need to pass 'google' to the path,
			// but we make it conditional on maps so we re-memoize
			// this options object when maps changes (e.g. loads)
			google: maps && google,
		}),
		[origin, destination, google, maps, gpsStreamSource, legId, tripId],
	);

	// The TrackingPath will only be recreated if this key changes.
	// Any consumer of the useInjectTrackingPath will use the same TrackingPath instance
	// if the key string generated is the same
	const pathKey = useMemo(
		() =>
			// Special case key here - if the key is `undefined` then the useInstanceCache()
			// hook won't create an instance of our class. We use this to delay creation
			// of a TrackingPath until Google Maps is completely loaded (e.g. `maps` is truthy)
			google &&
			maps &&
			// We also don't want to create a path if no active trip
			tripId &&
			// ... and no path if no active leg
			legId &&
			// ... and only doing tracking if the leg is ACTUALLY riding right now
			isLegLive
				? // maps is included here, even though it's only as an object, so we can use it as a key
				  // for when it transitions
				  `track_trip_${tripId}_leg_${legId}_stream_${gpsStreamSource}_maps_${!!maps}`
				: undefined,
		[google, gpsStreamSource, isLegLive, legId, maps, tripId],
	);

	// Create a stable instance of the TrackingPath based on the key above
	const trackingPath = useInstanceCache({
		key: pathKey,
		Class: TrackingPath,
		options: pathOptions,
	});

	// Subscribe to a GPS stream, but not with the react sate - instead, get the zustand store
	// so can do passive updates via a ref, as documented here:
	// https://github.com/pmndrs/zustand#transient-updates-for-often-occuring-state-changes
	const remoteGps = useTripGps(currentTrip, gpsStreamSource, {
		// Return the zustand store ref instead of the reactive state,
		// so we can subscribe to it instead
		selector: false,
		// Don't start listening to the websocket if the leg isn't live
		keyCondition: !!pathKey && !!isLegLive,
	});

	// console.warn(`useInjectTrackingPath: debug:`, {
	// 	isLegLive,
	// 	remoteGps,
	// 	trackingPath,
	// 	pathKey,
	// 	pathOptions,
	// 	currentTrip,
	// 	gpsStreamSource,
	// });

	// Use two refs to detect changes in location so we don't recalc if nothing is changed
	const currentRemoteGpsRef = useRef(
		remoteGps ? remoteGps.getState() : EMPTY_GPS,
	);
	const lastTrackedGpsRef = useRef(EMPTY_GPS);

	// This lets us ignore GPS changes if we're awaiting the path update below
	const lockUpdatesRef = useRef();

	// This ref will be set to false when the last useEffect exits, so we don't
	// perform async state updates after unmounting
	const mountedRef = useRef(true);

	const carIconGenerator = useCarIconGenerator();

	// This worker will be run via setTimeout (below) every Xms to check the
	// currentRemoteGpsRef for changes - if changed, run it thru the TrackingPath
	// updateTrackingProgressFromGps() routine and patch the changes into the
	// provided tripPath so we can return it and render it on a map
	const syncWorker = useCallback(
		async ({ immediateUpdate = false } = {}) => {
			if (!trackingPath) {
				if (pathKey !== undefined) {
					console.error(
						`TrackingPath instance not yet created for key '${pathKey}' - did something go wrong?`,
					);
				} else {
					console.warn(
						`TrackingPath not yet created, probably waiting on Google to load`,
						{ maps, google },
					);
				}

				return;
			}

			const { current: { lat: previousLat, lng: previousLng } = {} } =
				lastTrackedGpsRef;

			const { current: { lat, lng, heading } = {} } = currentRemoteGpsRef;

			// Update previous prop so we don't rerender if not needed
			lastTrackedGpsRef.current = currentRemoteGpsRef.current;

			// No change in location? Don't bother recalculating
			// Note: We really don't care if heading changes, since heading can't change
			// unless lat/lng physically changes. (In other words, for heading to change
			// independent of lat/lng, the GPS receiver would have to be spinning in place,
			// which is not relevant for the purposes of our tracking path here)
			if (!immediateUpdate && previousLat === lat && previousLng === lng) {
				return;
			}

			// Since we have to await the update below, we don't want to trigger
			// another update while awaiting the first, so just skip this point change
			if (!immediateUpdate && lockUpdatesRef.current) {
				return;
			}

			lockUpdatesRef.current = true;

			// Allow the path to complete initial setup if this is the first time thru
			if (!trackingPath.isSetup()) {
				// Get directions and convert to usable internal structures
				await trackingPath.setupPath();
			}

			// This is the core method that does all the heavy lifting of
			// recalculating the before/after paths on our current trip leg
			// and updating the marker location/icon rotation for our rendering methods,
			// as well as updating the time remaining estimation
			const progress = await trackingPath.updateTrackingProgressFromGps({
				lat,
				lng,
				heading,
			});

			console.warn(`updated tracking progress`, progress, {
				lat,
				lng,
				heading,
			});

			const {
				timeRemaining = 0,
				currentMarker = {},
				currentIcon = {},
				currentIconAngle = 0,
				paths: trackingPaths,
				bounds: trackingBounds = {},
			} = progress || {};

			// Explicitly batch updates since updating state from outside a react lifecycle event
			// ref: https://github.com/pmndrs/zustand#calling-actions-outside-a-react-event-handler
			unstable_batchedUpdates(() => {
				// Unlock updates for the next time syncWorker runs
				lockUpdatesRef.current = false;

				// Don't update our state if we unmounted while recalculating
				if (!mountedRef.current) {
					return;
				}

				// This is the crucial bit that maps the result from the TrackingPath
				// update routine onto the data from the useTripPath() hook so that it
				// is compatible with the format expected by our map widgets, and so
				// it will render seamlessly without the maps having to know or care
				// about our live tracking data.
				const revisedTripPath = trackingPaths
					? {
							bounds: useOriginalBounds ? bounds : trackingBounds,
							paths: [...paths, ...trackingPaths],
							pathMarkers: [
								...pathMarkers,
								{
									id: pathKey,
									markerType: 'live',
									position: currentMarker,
									icon: carIconGenerator
										? carIconGenerator(heading || currentIconAngle)
										: currentIcon,
								},
							],
							// this is a monkey-patched prop, not used elsewhere...yet
							timeRemaining,
					  }
					: tripPath;

				setInjectedTripPath(revisedTripPath);

				console.log(`useInjectTrackingPath: revisedTripPath`, revisedTripPath);
			});
		},
		[
			bounds,
			carIconGenerator,
			google,
			maps,
			pathKey,
			pathMarkers,
			paths,
			trackingPath,
			tripPath,
			useOriginalBounds,
		],
	);

	const immediateSyncTimer = useRef();

	const firstMountImmediateUpdateRef = useRef(true);

	// Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
	// This is the crucial piece the wires the remote GPS stream into the tracking path
	// by subscribing to the zustand store as well as starting our worker function, above,
	// to pickup the changes via the currentRemoteGpsRef
	useEffect(() => {
		mountedRef.current = true;

		// console.log(`useInjectTrackingPath // onMount:`, {
		// 	isLegLive,
		// 	trackingPath,
		// 	remoteGps,
		// });

		// We only want to run GPS tracking if the leg is ACTUALLY active
		if (!isLegLive || !trackingPath || !remoteGps) {
			return () => {
				mountedRef.current = false;
			};
		}

		// Start our sync worker - won't do anything if GPS doesn't change,
		// and locks as long as needed to recompute path, so safe to "poll"
		// for changes at a somewhat-fast pace, even if nothing changed.
		const timerId = setInterval(syncWorker, 1000);

		// Subscribe to our GPS stream and just write it into a ref,
		// which will be checked internally by syncWorker above
		const unsubscribe = remoteGps.subscribe((state) => {
			currentRemoteGpsRef.current = state;
			console.log(`useInjectTrackingPath // got GPS update:`, state);
		});

		// Load the current GPS into the ref because it probably wasn't available
		// when the ref was constructed
		currentRemoteGpsRef.current = remoteGps.getState();

		// Force update on change
		// Passing useOriginalBounds just to make it a dependent of this effect
		if (firstMountImmediateUpdateRef.current) {
			clearTimeout(immediateSyncTimer.current);
			immediateSyncTimer.current = setTimeout(() => {
				firstMountImmediateUpdateRef.current = false;
				syncWorker({ immediateUpdate: true, useOriginalBounds });
			}, 250);
		}

		// console.log(`useInjectTrackingPath // subscribed to remoteGps`, {
		// 	currentValue: currentRemoteGpsRef.current,
		// 	remoteGps,
		// 	unsubscribe,
		// 	timerId,
		// });

		return () => {
			// Mark unmounted so we don't update the injectedTripPath state
			// above in our interval if we were caught waiting for async work
			// when we unmounted
			mountedRef.current = false;

			// Unsubscribe from the GPS stream and stop our worker
			unsubscribe();
			clearInterval(timerId);
		};
	}, [
		isLegLive,
		legStatus,
		remoteGps,
		syncWorker,
		trackingPath,
		useOriginalBounds,
	]);

	// Only return our injected trip path if the leg is actually live,
	// otherwise, we just pass thru the tripPath given
	return isLegLive ? injectedTripPath : tripPath;
};
