/**
 * If param `name` is given and NOT "Current Location" or "Here", `name` will be returned.
 * Otherwise, first line of `addressText` (e.g. before first comma) will be returned.
 *
 * @param {string} name Possible display name
 * @param {string} addressText Address text
 * @returns {string} Display name for the place or first line of address
 */
export default function normalizePlaceName(
	name,
	addressText,
	autoSplit = true,
) {
	// eslint-disable-next-line no-nested-ternary
	return !name || name === 'Current Location' || name === 'Here'
		? autoSplit
			? `${addressText || ''}`.split(',')[0]
			: ''
		: name;
}
