import clamp from './clamp';

export default function exponentialDelayFactory({
	initialDelay = 500,
	maxDelay = 5000,
	multiplier = 1.33,
	// Seed the factory from disk if needed
	startingAttemptCount = 0,
} = {}) {
	let attempt = startingAttemptCount;
	const generator = () => {
		attempt += 1;
		const delay = Math.floor(
			clamp(initialDelay * multiplier ** (attempt - 1), initialDelay, maxDelay),
		);

		// console.warn(`exponentialDelayFactory: attempt=${attempt}, delay=${delay}`);
		return delay;
	};

	generator.reset = () => {
		attempt = 0;
	};

	generator.reduce = () => {
		attempt = Math.max(0, attempt - 1);
	};

	generator.attempts = () => attempt;

	return generator;
}
