import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';
import SimpleBadge from 'shared/components/SimpleBadge';
import { Link } from 'react-router-dom';
import { useTripListContext } from './TripListProvider';
import styles from './TripCountSummary.module.scss';

export default function TripCountSummary() {
	const { isLoading, counts } = useTripListContext();

	const { isStarted, isCompleted } = counts || {};

	if (isLoading) {
		return (
			<div className={clsx(styles.root, styles.loading)}>
				<CircularProgress size={20} />
			</div>
		);
	}

	return (
		<div className={styles.root}>
			<Link to={'/trips'}>
				{isStarted > 0 ? (
					<SimpleBadge className={styles.active}>
						<b>{isStarted}</b> 🚘
					</SimpleBadge>
				) : (
					''
				)}
				<SimpleBadge className={styles.completed}>
					{isCompleted} ✅
				</SimpleBadge>
			</Link>
		</div>
	);
}
