/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useCallback, useState, useEffect, useRef } from 'react';

import { ServerStore } from 'shared/services/ServerStore';
import { useAuthorization } from 'shared/services/AuthService';

import PublicAppConfig from 'shared/config-public';
import PulsingCoverLoader from 'shared/components/PulsingCoverLoader';
import { useAnonymousSSO } from 'shared/hooks/useAnonymousSSO';
import styles from './SupportChatPage.module.scss';
import SupportChatWidget from './SupportChatWidget';
import SupportChatPopup from './SupportChatPopup';

export default function SupportChatPage() {
	const isEmbedded = `${window.location.href}`.includes('embeddedChat=1');
	useEffect(() => {
		if (isEmbedded) {
			document.body.classList.add(styles.embeddedPageView);
		}
		return () => {
			if (isEmbedded) {
				document.body.classList.remove(styles.embeddedPageView);
			}
		};
	});

	useAnonymousSSO();

	const onBackClicked = useCallback(() => {
		if (!isEmbedded) {
			window.history.go(-1);
		}

		// Send message the parent window, praying it's listening
		window.parent.postMessage(
			{ type: 'close' },
			PublicAppConfig.buildEnv === 'prod' ? 'https://vayadriving.com' : '*',
		);
	}, [isEmbedded]);

	return (
		<div className={styles.embeddedPage}>
			<SupportChatWidget
				hideBackButton={false}
				onBackClicked={onBackClicked}
				lightMode={true}
				className={styles.widget}
			/>
		</div>
	);
}
