import {
	useContext,
	useCallback,
	useState,
	useRef,
	useEffect,
	createContext,
} from 'react';

// Styles here h/t to https://codepen.io/tmac/pen/QgVRKb
const stylesheet = `
	.page-loading-spinner {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 9999;
	}
	
	.page-loading-spinner .progress-bar {
		height: 4px;
		background-color: rgba(5, 114, 206, 0.2);
		width: 100%;
		overflow: hidden;
	}
	
	.page-loading-spinner .progress-bar-value {
		width: 100%;
		height: 100%;
		background-color: rgb(5, 114, 206);
		animation: indeterminateAnimation 1s infinite linear;
		transform-origin: 0% 50%;
	}
	
	@keyframes indeterminateAnimation {
		0% {
			transform:  translateX(0) scaleX(0);
		}
		40% {
			transform:  translateX(0) scaleX(0.4);
		}
		100% {
			transform:  translateX(100%) scaleX(0.5);
		}
	}
`;

export function DataLoadingSpinner() {
	useEffect(() => {
		const style = document.createElement('style');
		style.id = 'loading-spinner-provider';
		style.innerHTML = stylesheet;
		const insertLocation = document.querySelector('script');
		insertLocation.parentNode.insertBefore(style, insertLocation);

		return () => insertLocation.parentNode.removeChild(style);
	});

	return (
		<div className="page-loading-spinner">
			<div className="progress-bar">
				<div className="progress-bar-value"></div>
			</div>
		</div>
	);
}

const Context = createContext();
export default function LoadingSpinnerProvider({ children }) {
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
	const delayRef = useRef();

	const clearLoadingSpinnerTimer = useCallback(() => {
		clearTimeout(delayRef.current);
		setShowLoadingSpinner(false);
	}, []);

	const showDelayedLoadingSpinner = useCallback(
		(delay = 300) => {
			clearLoadingSpinnerTimer();
			delayRef.current = setTimeout(() => setShowLoadingSpinner(true), delay);
			return () => {
				clearLoadingSpinnerTimer();
				setShowLoadingSpinner(false);
			};
		},
		[clearLoadingSpinnerTimer],
	);

	return (
		<Context.Provider
			value={{
				setShowLoadingSpinner,
				showLoadingSpinner,
				showDelayedLoadingSpinner,
				clearLoadingSpinnerTimer,
			}}
		>
			{children}
			{showLoadingSpinner && <DataLoadingSpinner fullPage={true} />}
		</Context.Provider>
	);
}

export function useLoadingSpinner() {
	const state = useContext(Context) || {};
	if (state === undefined) {
		throw new Error(
			`Must call useShowLoadingSpinner from within a LoadingSpinnerProvider`,
		);
	}
	return state;
}
