/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useState, useRef, useCallback, useLayoutEffect } from 'react';

const TRY_DELAY = 1000;
const MAX_ATTEMPTS = 60;

export function useEnableManagedViewportHelper(
	containerClassName,
	rootEl = document,
) {
	const [enableManagedViewport, setEnableManagedViewport] = useState(true);

	// We have to use 'capturing' event listeners to catch wheel events FIRST
	// (but we don't stop propagation) so that we can disable our viewport
	// auto-management when the USER interacts with the map (not just when the
	// map auto-zooms due to viewport adjustments)
	// h/t https://stackoverflow.com/a/18861027
	useLayoutEffect(() => {
		const wheelEvent = () => setEnableManagedViewport(false);

		let isDown = false;
		const onDown = () => {
			isDown = true;
		};
		const onUp = () => {
			isDown = false;
		};
		const onMove = () => {
			if (isDown) {
				wheelEvent();
			}
		};

		const attachEvents = (el) => {
			const blockFlag = true;
			// NOTE: This VERY LIKELY will trigger `[Violation]` warnings in the console, just FYI.
			// Something like "Added non-passive event listener to a scroll-blocking
			// 'mousewheel' event. Consider marking event handler as 'passive' to make
			// the page more responsive." Just ignore the warning.
			el.addEventListener('mousewheel', wheelEvent, blockFlag);
			el.addEventListener('DOMMouseScroll', wheelEvent, blockFlag);
			el.addEventListener('touchstart', onDown, blockFlag);
			el.addEventListener('mousedown', onDown, blockFlag);
			el.addEventListener('touchmove', onMove, blockFlag);
			el.addEventListener('mousemove', onMove, blockFlag);
			el.addEventListener('touchend', onUp, blockFlag);
			el.addEventListener('mouseup', onUp, blockFlag);
		};

		let timer;
		let div;
		let attemptCount = 0;
		const tryAttaching = () => {
			attemptCount++;
			timer = setTimeout(() => {
				div = rootEl.querySelector(
					`.${containerClassName
						// Necessary because SASS modules generates classnames sometimes with '+' signs in them,
						// but the '+' is not a valid CSS class name, so we must escape for querySelector()
						.replace(/\+/g, '\\+')}`,
				);
				if (div) {
					attachEvents(div);
				} else {
					if (attemptCount > MAX_ATTEMPTS) {
						console.error(
							`Tried ${attemptCount} times to find ${containerClassName}, no luck. Won't try again.`,
						);
						return;
					}
					tryAttaching();
				}
			}, TRY_DELAY);
		};

		tryAttaching();

		return () => {
			clearTimeout(timer);
			if (div) {
				div.removeEventListener('mousewheel', wheelEvent);
				div.removeEventListener('DOMMouseScroll', wheelEvent);
				div.removeEventListener('touchstart', onDown);
				div.removeEventListener('mousedown', onDown);
				div.removeEventListener('touchmove', onMove);
				div.removeEventListener('mousemove', onMove);
				div.removeEventListener('touchend', onUp);
				div.removeEventListener('mouseup', onUp);
			}
		};
	}, [containerClassName, rootEl]);

	return {
		enableManagedViewport,
		setEnableManagedViewport,
	};
}
