/* eslint-disable no-console */
import { useParams } from 'react-router-dom';

import SupportChatButton from 'shared/components/SupportChat/SupportChatButton';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RoomIcon from '@material-ui/icons/Room';
import HomeIcon from '@material-ui/icons/Home';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import AssistantIcon from '@material-ui/icons/Assistant';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { useEffect, useCallback } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BackendService, {
	useAuthRequired,
	useMessageHook,
	usePoolSubscription,
} from 'shared/services/BackendService';
import { MessageTypes } from 'shared/utils/MessageTypes';
import clsx from 'clsx';
import { useAuthorization } from 'shared/services/AuthService';
import { ServerStore } from 'shared/services/ServerStore';
import useSWR from 'swr';
import { useConditionallyHandleErrors } from 'shared/hooks/useConditionallyHandleErrors';

// import DrawerMenuButton from './DrawerMenuButton';
import SimpleSpinner from 'shared/components/SimpleSpinner';
import SimpleBadge from 'shared/components/SimpleBadge';
import TripStatusBadge from 'shared/components/TripStatusBadge';
import { useDebounce } from 'use-debounce';
import styles from './TripsPage.module.scss';
import DrawerNav, { useDrawerNavContext } from './DrawerNav';
import { ListItemLink } from './ListItemLink';
import PartnerLogoToolbarItem from './PartnerLogoToolbarItem';

import TripListProvider, { useTripListContext } from './TripListProvider';

function TripsPageContent() {
	const { toggleDrawer } = useDrawerNavContext();

	const { account } = useAuthorization() || {};
	const { partnerLogoUrl } = account || {};

	const { trips, isLoading } = useTripListContext();

	// console.log(`TripPage trips`, {
	// 	activeTrips,
	// 	data,
	// 	isValidating,
	// });

	return (
		<div className={clsx(styles.root)}>
			{isLoading && <SimpleSpinner fullPage={true} />}

			<DrawerNav alwaysHideDrawer={true} />
			<SupportChatButton lightMode={true} />

			<AppBar
				position="static"
				color="default"
				className={styles.appBar}
			>
				<Tooltip title="Open side menu">
					<IconButton
						className={clsx(styles.iconButton)}
						aria-label="menu"
						onClick={toggleDrawer}
					>
						<MenuIcon />
					</IconButton>
				</Tooltip>

				<PartnerLogoToolbarItem url={partnerLogoUrl} />

				<IconButton className={clsx(styles.hiddenButton)}>
					{/* This button exists only to center the logo item more
					evenly, can be removed if a different button actually needs
					to go here later */}
					<MenuIcon />
				</IconButton>
			</AppBar>
			<List dense>
				{(trips || []).map(
					({
						// eslint-disable-next-line no-shadow
						id: tripId,
						user: { name: userName } = {},
						tripLegsSummary,
						status,
					}) => (
						<ListItemLink
							key={tripId}
							href={`/trip/${tripId}`}
						>
							<TripStatusBadge status={status} />
							<ListItemText
								primary={userName}
								secondary={tripLegsSummary}
							/>
						</ListItemLink>
					),
				)}
			</List>
		</div>
	);
}

export default function TripsPage() {
	const isAuthorized = useAuthRequired();
	if (!isAuthorized) {
		// eslint-disable-next-line no-console
		console.warn(
			`TripsPage NOT authorized, not rendering, waiting for redirect from BackendService hook`,
		);
		return <SimpleSpinner fullPage={true} />;
	}

	return (
		<TripListProvider>
			<TripsPageContent />
		</TripListProvider>
	);
}
