/* eslint-disable func-names */
// Docs at https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/

function loadSdkAsynchronously() {
	// // Disable in staging/prod
	// if (
	// 	process.env.NODE_ENV !== 'production' ||
	// 	process.env.REACT_APP_STAGING === 'true'
	// ) {
	// 	return () => {};
	// }

	/* eslint-disable */
	!(function (f, b, e, v, n, t, s) {
		if (f.fbq) return;
		n = f.fbq = function () {
			n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
		};
		if (!f._fbq) f._fbq = n;
		n.push = n;
		n.loaded = !0;
		n.version = '2.0';
		n.queue = [];
		t = b.createElement(e);
		t.async = !0;
		t.src = v;
		s = b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t, s);
	})(
		window,
		document,
		'script',
		'https://connect.facebook.net/en_US/fbevents.js',
	);
	/* eslint-enable */
	const { fbq } = window;

	fbq('init', '205708201753906');
	fbq('track', 'PageView');

	const container = document.createElement('div');
	container.innerHTML = `
		<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=205708201753906&ev=PageView&noscript=1" />
	`;
	document.body.appendChild(container);

	return fbq;
}

const fbq = loadSdkAsynchronously();

export default fbq;
