/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useEffect } from 'react';
import decode from 'jwt-decode';
import { elideJsonMessage } from 'shared/utils/elideJsonMessage';
// import {
// 	firebaseAuth,
// 	firebaseDb,
// } from 'shared/services/push/FirebasePushPlugin';
import { datadogLogs } from './DatadogBrowserLogs';
import LogRocket from './LogRocket';
// import createStore from 'zustand';
import FunctionalState, {
	useFunctionalState,
} from '../components/FunctionalState';

// import { cache as swrCache } from 'swr';
import { defer, stableDefer } from '../utils/defer';

// ES6 handles cycles just fine, and we need ApiService to verify token
// eslint-disable-next-line import/no-cycle
import { ServerStore } from './ServerStore';
// eslint-disable-next-line import/no-cycle
import BackendService, { getCurrentPath } from './BackendService';
import { assertRequired } from '../utils/assertRequired';
import { jsonSafeStringify } from '../utils/jsonSafeStringify';
import AppConfig from '../config-public';

/**
 * Keys to use for persisting data in localStorage between page loads
 */
const TOKEN_CACHE_KEY = `@rubber/auth-token`;
const AUTHORIZATION_CACHE_KEY = '@rubber/authorization';
const PRE_LOGIN_PATH_KEY = `@rubber/pre-login-path`;
const PRE_LOGIN_TOKEN_KEY = '@rubber/pre-login-token';

/**
 * Where to go after logging in if no previous page requested auth
 */
const DEFAULT_PRE_LOGIN_PATH = '/';

/**
 * NB: If expiration ever changes on the backend, make sure we update this
 */
const TOKEN_EXPIRATION_MINUTES = 60;

/**
 * Refresh tokens at X percent of TOKEN_EXPIRATION_MINUTES
 */
const TOKEN_EXPIRATION_RATIO = 0.9;

const processErrorResponse = (response) => {
	// Handle errors
	if (response instanceof Error) {
		const errors = ServerStore.extractApiErrors(response);

		// eslint-disable-next-line no-console
		console.error(`Error from server`, errors);

		const errorString = errors.join(', ');

		// Handle network errors differently than other errors
		const isNetworkError = response instanceof ServerStore.NetworkError;

		if (!isNetworkError) {
			console.error(`Full error text: ${errorString}`);
		}

		return {
			error: response,
			message: isNetworkError
				? "It looks like you can't connect to Vaya, are you online?"
				: errorString,
		};
	}

	return undefined;
};

export default class AuthService {
	static getAuthorizationState = (valueOnly = false) => {
		const state =
			AuthService.authorizationState &&
			AuthService.authorizationState.getState();
		if (state) {
			return state;
		}
		const cache = global.window
			? global.window.localStorage.getItem(AUTHORIZATION_CACHE_KEY) || '{}'
			: '{}';
		let value = {};
		try {
			value = JSON.parse(cache);
		} catch (ex) {
			console.warn(`Error parsing authorization cache:`, ex);
		}

		// if (valueOnly) {
		// 	return value;
		// }

		// this.authorizationState.setValue(value);
		return value;
	};

	// React state to hold true/false for authorization state
	// Start as undefined so checkAuthorizationStatus knows to verify
	static isAuthorizedState = new FunctionalState(undefined);

	// We only connect to firebase with a fresh token, not cached, so even though
	// the useAuthorization hook will return valid creds, our UI has to wait to use firebase until this is truthy
	static firebaseConnectedState = new FunctionalState(false);

	// Authenticated user's information, from the 'profile-service/profile' endpoint
	static authorizationState = new FunctionalState(
		AuthService.getAuthorizationState(),
	);

	// Auth token for logged-in user
	static tokenState = new FunctionalState(undefined);

	/**
	 * Gets the current value of the authorization flag (does not check with the server - use checkAuthorizationStatus for that)
	 * @returns true/false indicating if the user is authorized (logged in)
	 */
	static isAuthorized() {
		return this.isAuthorizedState.getValue();
	}

	/**
	 * Update the authorization state.
	 * @param {bool} loggedIn True/false indicating if user is logged in
	 */
	static setIsAuthorized(loggedIn) {
		// console.warn(`setIsAuthorized=`, loggedIn);
		this.isAuthorizedState.setValue(loggedIn);
	}

	/**
	 * Login to the server, get a token, returns { success } or { error }
	 * @param {string} email Email
	 * @param {string} password Password
	 * @returns { success: true } or { error: Error }
	 */
	static async login(email, password, tenant) {
		const result = await ServerStore.login(email, password, undefined, tenant);
		if (result instanceof Error) {
			console.log(`Auth error:`, {
				...result.response,
			});
			const list = ServerStore.extractApiErrors(result);
			const [firstError] = list;
			const { status, statusText } = list;

			const errorReason = firstError || 'Error logging in';

			this.setIsAuthorized(false);
			return {
				error: result,
				errorReason,
				status,
				statusText,
			};
		}

		this.authorizeSession(result);

		return { success: true, ...result };
	}

	/**
	 * Request password reset
	 * @param {string} email Email
	 * @param {string} phoneNum Phone Number
	 * @returns { success: true } or { error: Error }
	 */
	static async forgotPassword({ email, phoneNum }) {
		const result = await ServerStore.forgotPassword({ email, phoneNum });
		if (result instanceof Error) {
			const { message, error } = processErrorResponse(result);

			this.setIsAuthorized(false);
			return {
				error,
				message,
			};
		}

		return { success: true, ...result };
	}

	/**
	 * Send code + userId + new password down to server, get a token, returns { success } or { error }
	 * @param {string} email Email
	 * @param {string} code Code given in reset email/SMS
	 * @param {string} userId User ID from reset email
	 * @returns { success: true } or { error: Error }
	 */
	static async resetPassword({ userId, code, password, tenant }) {
		const result = await ServerStore.resetPassword({
			userId,
			code,
			password,
			tenant,
		});
		if (result instanceof Error) {
			const { message, error } = processErrorResponse(result);

			this.setIsAuthorized(false);
			return {
				error,
				message,
			};
		}

		this.authorizeSession(result);

		return { success: true, ...result };
	}

	/**
	 * Accept invite on the server, get a token, returns { success } or { error }
	 * @param {string} email Email
	 * @param {string} password Password
	 * @returns { success: true } or { error: Error }
	 */
	static async acceptInvite(inviteId, password, tenant) {
		const result = await ServerStore.acceptInvite({
			inviteId,
			password,
			tenant,
		});
		if (result instanceof Error) {
			console.log(`Accept invite error:`, {
				...result.response,
			});
			const list = ServerStore.extractApiErrors(result);
			const [firstError] = list;
			const { status, statusText } = list;

			const errorReason = firstError || 'Error accepting invite';

			this.setIsAuthorized(false);
			return {
				error: result,
				errorReason,
				status,
				statusText,
			};
		}

		this.authorizeSession(result);

		return { success: true, ...result };
	}

	/**
	 * Login to the server with a token instead of user/pass { success } or { error }
	 * @param {string} token Token
	 * @returns { success: true } or { error: Error }
	 */
	static async loginWithToken(token) {
		const result = await this.refreshToken(token, {
			errorFallthru: true,
		});

		if (result instanceof Error) {
			console.log(`Token login error:`, {
				...result.response,
			});
			const list = ServerStore.extractApiErrors(result);
			const [firstError] = list;
			const { status, statusText } = list;

			const errorReason = firstError || 'Error validating token';

			this.setIsAuthorized(false);
			return {
				error: result,
				errorReason,
				status,
				statusText,
			};
		}

		if (!result) {
			// Error should have already been logged, so just handle quietly
			if (this.authPromise) {
				this.authPromise.resolve(false);
			}

			// Set to false instead of undefined so we don't have to wait for a response next time
			this.setIsAuthorized(false);
			return false;
		}

		// Setup up the session properly
		this.authorizeSession(result);

		// this.setIsAuthorized(true);

		// // Connect the socket
		// BackendService.connect();

		return { success: true, ...result };
	}

	static async loginWithPin({ tenant, pin }) {
		assertRequired({ tenant, pin });
		const result = await ServerStore.pinAuth({ pin, tenant });
		if (result instanceof Error) {
			console.log(`PIN Auth error:`, {
				...result.response,
			});
			const list = ServerStore.extractApiErrors(result);
			const [firstError] = list;
			const { status, statusText } = list;

			const errorReason = firstError || 'Error logging in with PIN';

			this.setIsAuthorized(false);
			return {
				error: result,
				errorReason,
				status,
				statusText,
			};
		}

		this.authorizeSession(result);

		return { success: true, ...result };
	}

	/**
	 * Login to the server with a monday UserId (if not already present in current token)
	 * Note: Will automatically create a user if none exists for any of the attributes.
	 * However, user will be given an error message until a Vaya admin tags
	 * them accordingly as canMondaySso (automatically enabled if user already exists
	 * and is a Vaya admin or Vaya support already)
	 *
	 * Note: This method is really only relevant for custom Monday views.
	 * These fields can all be retrieved via `useMondayMe()` from utils/useMondayHooks.js
	 *
	 * If token already present AND the token/cached auth contains the given mondayUserId
	 * then nothing is done, just returns.
	 *
	 * If token is already present AND token/cached auth does NOT match given mondayUserId
	 * then socket is closed/disconnected and re-auth and fallthru
	 *
	 * Fall thru: (nothing matches): Auth with server (auto-create user),
	 * and if successful (no error on waiting for canMondaySso) then connect socket.
	 *
	 * @param {string} mondayUserId Monday User ID
	 * @param {string} email Monday Email
	 * @param {string} phoneNum Monday Phone Num
	 * @param {string} name Optional name
	 * @returns { success: true } or { error: Error }
	 */
	static async mondaySso({
		mondayUserId: mondayUserIdInput,
		email,
		phoneNum,
		name,
	}) {
		const token = this.getToken();

		// Token and Cached versions of this ID are stored as strings,
		// but Monday often provides integers, so stringify first so compare does not fail
		const mondayUserId = `${mondayUserIdInput}`;
		// const mondayUserId = mondayUserIdInput;

		// Already have an auth token - verify that the current token
		// matches the mondayUserId we want to
		if (token) {
			const cachedAuthorization = this.authorizationState.getState();
			const decodedToken = decode(token) || {};

			// Token must have mondayUserId
			if (
				decodedToken.mondayUserId === mondayUserId &&
				cachedAuthorization.user &&
				cachedAuthorization.user.mondayUserId === decodedToken.mondayUserId
			) {
				if (this.isAuthorizedState.getValue() === mondayUserId) {
					// if (this.isAuthorized()) {
					console.warn(
						`mondaySso token and auth match,  already authed, returning success`,
					);
					return { success: true };
				}

				// console.log(`Authorization cached, setting true:`, {
				// 	cachedAuthorization,
				// 	decodedToken,
				// });

				// Ensure internal states up-to-date and socket is connected
				// this.authorizeSession(cachedAuthorization);

				// Guard with this promise so if checkAuthorizationStatus is called
				// multiple times while the API is responding, then we don't
				// make multiple calls to the API - each caller to this method
				// can wait on this same promise.
				if (this.authPromise) {
					return this.authPromise;
				}

				console.warn(`mondaySso token and auth match, refreshing token...`);

				this.authPromise = stableDefer({
					timeoutErrorMessage: 'Monday SSO authPromise timeout',
					onTimeout: () => {
						console.warn(
							`Monday SSO authPromise timed out, calling clearToken()`,
						);
						this.clearToken({
							reloadCode: 'mondaySsoTimeout',
							failureMessage: 'SSO from Monday timed out',
						});
					},
					timeout: 2500,
				});

				// Token expires every TOKEN_EXPIRATION_MINUTES minutes, so refresh it if we can.
				// Returns false if token is invalid or any other token problems.
				// Returns null of no token present - but we shouldn't get here anyway if no token.
				// Will clear this.authPromise internally via authorizeSession()
				const result = await this.refreshToken(token, {
					errorFallthru: true,
				});
				if (result) {
					return { success: true };
				}

				// // Error should have already been logged, so just handle quietly
				// 	this.authPromise.resolve(false);

				// 	// Set to false instead of undefined so we don't have to wait for a response next time
				// 	this.setIsAuthorized(false);
				// 	return false;
				// }
			}

			console.warn(`mondaySso fallthru with token:`, {
				mondayUserId,
				tokenMondayUserId: decodedToken.mondayUserId,
				cachedAuthMondayUserId:
					cachedAuthorization.user && cachedAuthorization.user.mondayUserId,
			});

			// If we fall thru to here, it means that we were
			// already authed with a different token, so we need to
			// clear that out (without reloading page!)
			this.clearToken({ forceReload: false });
		} else {
			console.warn(`mondaySso fallthru, no token`);
		}

		const result = await ServerStore.MondaySSO({
			mondayUserId,
			email,
			phoneNum,
			name,
		});

		if (result instanceof Error) {
			// const { response: { status } = {} } = refreshResult;
			const errors = ServerStore.extractApiErrors(result);
			const { status } = errors;
			console.log(`Error executing monday SSO, got status:`, {
				status,
				errors,
				result,
			});

			return { status, error: errors };
		}

		// Update internal states, cache auth, and connect socket (disconnected in clearToken above)
		this.authorizeSession(result);

		return { success: true };
	}

	/**
	 * Login to the server with a deviceId anonymously.
	 *
	 * Once a user signs up from this deviceId, this ID will be locked to that user via the guestIdent prop,
	 * and further unauthenticated anonymous logins will be likely rejected or at least require 2FA.
	 *
	 * @returns { success: true } or { error: Error }
	 */
	static async anonymousSso() {
		const token = this.getToken();

		// Already have an auth token - verify that the current token
		// matches the mondayUserId we want to
		if (token) {
			const cachedAuthorization = this.authorizationState.getState();
			const decodedToken = decode(token) || {};

			if (
				cachedAuthorization.user &&
				cachedAuthorization.user.id === decodedToken.userId
			) {
				// console.log(`Authorization cached, setting true:`, {
				// 	cachedAuthorization,
				// 	decodedToken,
				// });

				// Set all relevant flags and connect the backend socket
				this.authorizeSession(cachedAuthorization);
				return token;
			}

			console.warn(`Authorization cache failed:`, {
				cachedAuthorization,
				decodedToken,
			});

			// Guard with this promise so if checkAuthorizationStatus is called
			// multiple times while the API is responding, then we don't
			// make multiple calls to the API - each caller to this method
			// can wait on this same promise.
			if (this.authPromise) {
				return this.authPromise;
			}

			this.authPromise = stableDefer({
				timeoutErrorMessage: 'Anon authPromise timeout',
				onTimeout: () => {
					console.warn(
						`AuthService.anonymousSso timed out, calling clearToken()`,
					);
					this.clearToken({
						reloadCode: 'anonymousSsoTimeout',
						failureMessage: 'Anonymous SSO timed out',
					});
				},
				timeout: 2500,
			});

			// Token expires every TOKEN_EXPIRATION_MINUTES minutes, so refresh it if we can.
			// Returns false if token is invalid or any other token problems.
			// Returns null of no token present - but we shouldn't get here anyway if no token.
			// Will clear this.authPromise internally via authorizeSession()
			const result = await this.refreshToken();
			if (!result) {
				// Error should have already been logged, so just handle quietly
				this.authPromise.resolve(false);

				// Set to false instead of undefined so we don't have to wait for a response next time
				this.setIsAuthorized(false);
				return false;
			}
		}

		const result = await ServerStore.AnonymousSSO();

		if (result instanceof Error) {
			// const { response: { status } = {} } = refreshResult;
			const errors = ServerStore.extractApiErrors(result);
			const { status } = errors;
			console.log(`Error executing anonymous SSO, got status:`, {
				status,
				errors,
				result,
			});

			return { status, error: errors };
		}

		// Update internal states, cache auth, and connect socket (disconnected in clearToken above)
		this.authorizeSession(result);

		return { success: true };
	}

	/**
	 * Signup to the server, get a token, returns { success } or { error }
	 * @param {packet} object Signup packet
	 * @returns { success: true } or { error: Error }
	 */
	static async signup(packet) {
		const result = await ServerStore.signup(packet);
		if (result instanceof Error) {
			console.log(`Auth error:`, {
				...result.response,
			});
			const list = ServerStore.extractApiErrors(result);
			const [firstError] = list;
			const { status, statusText } = list;

			const errorReason = firstError || 'Error logging in';

			this.setIsAuthorized(false);
			return {
				error: result,
				errorReason,
				status,
				statusText,
			};
		}

		this.authorizeSession(result);

		return { success: true };
	}

	/**
	 * Utility to check local storage and if we already have a token, check with the server to see if the token is valid.
	 *
	 * Designed to be called multiple times with no effect - only the first time hits the server,
	 * after that, just returns the same true (assuming token is present and valid). If missing a token,
	 * will always return false. If token present but not valid, will try to verify every time and return false
	 * if token is still invalid until token is removed (via `clearToken` or server returns a valid response.)
	 *
	 * We hit the server every time if we have a token but it's not valid because anything COULD cause a temporary error,
	 * like network down, temporary API problems, etc. So we don't want to clear the token on first error so we
	 * don't force the user to reauth - we want to let them retry (even if that means reloading the page) without
	 * having to go thru the auth flow again - and we do that by preserving the token until we explicitly need to clear it
	 * on log out or some other logic TBD.
	 *
	 * @returns Promise that resolves to true or false. True only if we have a token AND the server has validated the token for this session
	 */
	static async checkAuthorizationStatus() {
		if (this.isAuthorized() === undefined) {
			if (this.pendingRefresh) {
				console.log(
					`AuthService.checkAuthorizationStatus: Found pending refresh promise, waiting until refresh is done till we check for a token`,
				);
				await this.pendingRefresh;
			} else {
				// console.warn(`No pending refresh, continuing to check auth status`);
			}

			let token = this.getToken();
			if (!token && global.window) {
				const { token: queryToken } = Object.fromEntries(
					new URLSearchParams(
						global.window.location?.search?.slice(1),
					).entries(),
				);
				if (queryToken) {
					console.log(
						`Not already authorized in browser, but loaded token from query params:`,
						queryToken,
					);
					token = queryToken;
					this.setToken(token);
				}
			}

			// No token? No need to check with the API, we know we're not authorized
			if (!token) {
				// Set to false instead of undefined so we don't have to wait for a response next time
				this.setIsAuthorized(false);
				return false;
			}

			const cachedAuthorization = this.authorizationState.getState();
			const decodedToken = decode(token) || {};

			if (
				cachedAuthorization.user &&
				cachedAuthorization.user.id === decodedToken.userId
			) {
				// console.log(`Authorization cached, setting true:`, {
				// 	cachedAuthorization,
				// 	decodedToken,
				// });

				// Set all relevant flags and connect the backend socket
				// By setting cached, we don't authorize with firebase (yet) until refresh comes back,
				// because firebase has an expiration which throws errors if we try to use it
				this.authorizeSession(cachedAuthorization, { cached: true });

				// Refresh token but don't log out on errors.
				// Might reevaluate later.
				// We refresh token here but don't "await" because the session is already authorized above.
				// However, we want to refresh in case something relevant to authorization
				// changed on the server (such as tenant settings, roles on the user, etc)
				// and various parts of the app use our authorization state to render/allow access
				// conditionally based on the data in our authorization object, so we
				// allow use of the cached result (above) while refreshing it here.
				// The FunctionalState that holds "authorizationState" will force re-render
				// of any components that mount and use our data between the authorize (above)
				// and the results of the refresh (whenever this call completes)
				this.refreshToken(token, {
					errorFallthru: true,
				});

				return token;
			}

			console.warn(`Authorization cache failed:`, {
				cachedAuthorization,
				decodedToken,
			});

			// Guard with this promise so if checkAuthorizationStatus is called
			// multiple times while the API is responding, then we don't
			// make multiple calls to the API - each caller to this method
			// can wait on this same promise.
			if (this.authPromise) {
				return this.authPromise;
			}

			this.authPromise = stableDefer({
				timeoutErrorMessage: 'HTTP authPromise timeout',
				onTimeout: () => {
					console.warn(
						`AuthService.checkAuthorizationStatus timed out, calling clearToken()`,
					);
					this.clearToken({
						reloadCode: 'checkAuthorizationStatusTimeout',
						failureMessage: 'checkAuthorizationStatus timed out',
					});
				},
				timeout: 2500 * 1000,
			});

			// Token expires every TOKEN_EXPIRATION_MINUTES minutes, so refresh it if we can.
			// Returns false if token is invalid or any other token problems.
			// Returns null of no token present - but we shouldn't get here anyway if no token.
			// Will clear this.authPromise internally via authorizeSession()
			const result = await this.refreshToken(token);
			if (!result) {
				// Error should have already been logged, so just handle quietly
				this.authPromise.resolve(false);

				// Set to false instead of undefined so we don't have to wait for a response next time
				this.setIsAuthorized(false);
				return false;
			}
		}

		// Already authorized...
		return this.getToken();
	}

	/**
	 * Refreshes the old token and stores the new token
	 * @returns {string} Fresh token
	 */
	static async refreshToken(inputToken, { errorFallthru = false } = {}) {
		if (this.pendingRefresh) {
			return this.pendingRefresh;
		}

		const token = inputToken || this.getToken();
		// console.warn(`refreshToken: using token:`, token);
		if (!token) {
			return null;
		}

		this.pendingRefresh = defer();
		const resolvePending = (result) => {
			this.pendingRefresh.resolve(result);
			this.pendingRefresh = null;
		};

		// console.warn(`refreshToken: stored refresh promise:`, this.pendingRefresh);

		const refreshResult = await ServerStore.refreshToken(token);

		// console.debug(`refreshToken: got result:`, refreshResult);

		if (refreshResult instanceof Error) {
			// const { response: { status } = {} } = refreshResult;
			const errors = ServerStore.extractApiErrors(refreshResult);
			const { status } = errors;
			console.log(`Error refreshing token, got status:`, {
				status,
				errors,
				refreshResult,
			});

			if (errorFallthru) {
				console.warn(`Got error from refresh:`, refreshResult);
				resolvePending(refreshResult);
				return refreshResult;
			}

			// 40X errors are unrecoverable errors, so just remove the token
			if (status >= 400 && status <= 499) {
				this.clearToken({
					reloadCode: 'refreshTokenFailure',
					failureMessage: Array.from(errors || []).join(', '),
				});
				resolvePending(false);
				return false;
			}

			// Not an expired token, log the info to the console
			// TBD Find better logging
			console.warn(`Got error from refresh:`, refreshResult);
			resolvePending(false);

			return false;
		}

		// Keep puppeteer logs clean
		if (window.navigator.userAgent === 'vaya-puppeteer-report-export-worker') {
			console.log(
				`Refresh token success:`,
				elideJsonMessage(jsonSafeStringify(refreshResult, 0)),
			);
		} else if (AppConfig.buildEnv === 'prod') {
			console.log(`Refresh token success:`, refreshResult);
		}

		// Set all appros flags to authorized and connect backend socket
		this.authorizeSession(refreshResult);

		resolvePending(refreshResult);

		return refreshResult;
	}

	static authorizeSession(result, { cached } = {}) {
		const { token, tenant, tenantUser, user, account, firebaseToken } = result;
		this.setToken(token);

		// Context is cleared on logout because clearToken refreshes the page entirely
		ServerStore.customDataDogContext = {
			userId: user?.id,
			userName: user?.name,
			userEmail: user?.email,
			accountId: account?.id,
			tenantId: tenant?.id,
			tenantName: tenant?.name,
			appRoles: tenantUser?.appRoles,
			// Preserve anything set pre-login
			...ServerStore.customDataDogContext,
		};

		// Context is cleared on logout because clearToken refreshes the page entirely
		datadogLogs.setLoggerGlobalContext(ServerStore.customDataDogContext);

		// if (firebaseToken) {
		// 	if (cached) {
		// 		if (AppConfig.buildEnv === 'dev') {
		// 			console.log(
		// 				`!NOT using firebase token because it came from cache, will wait for refresh`,
		// 			);
		// 		}
		// 	} else {
		// 		// console.log(`using fb token`, firebaseToken);
		// 		firebaseAuth
		// 			.signInWithCustomToken(firebaseToken)
		// 			.then(async (userCredential) => {
		// 				// Signed in
		// 				// eslint-disable-next-line no-shadow
		// 				const { user } = userCredential;
		// 				console.log(`++ Signed in to firebase:`, user);

		// 				this.firebaseConnectedState.setValue(user);

		// 				// const testStandTicketsRef = firebaseDb.collection(
		// 				// 	'dev/apps/valet/vstnd_test/tickets',
		// 				// );

		// 				// const tkRef1 = testStandTicketsRef.doc('vtk_1');
		// 				// // const tkRef1 = db.doc('dev/alovelace');

		// 				// tkRef1.onSnapshot((doc) => {
		// 				// 	console.log(`>> Got doc snapshot:`, doc.data());
		// 				// });

		// 				// tkRef1.set({
		// 				// 	foo: 'bar',
		// 				// 	date: new Date(),
		// 				// });

		// 				// console.log(`>> Waiting for ticket list...`);
		// 				// const snapshot = await testStandTicketsRef.get();
		// 				// console.log(`Got snapshot`, snapshot);
		// 				// snapshot.forEach((doc) => {
		// 				// 	console.log(' >> >>> ', doc.id, '=>', doc.data());
		// 				// });
		// 			})
		// 			.catch((error) => {
		// 				this.firebaseConnectedState.setValue(false);

		// 				const errorCode = error.code;
		// 				const errorMessage = error.message;
		// 				console.error(`Error signing in to firebase:`, {
		// 					errorCode,
		// 					errorMessage,
		// 				});
		// 			});

		// 		// firebaseAuth
		// 		// 	.signInWithEmailAndPassword(token, )
		// 		// 	.then((userCredential) => {
		// 		// 		// Signed in
		// 		// 		// const { user } = ;
		// 		// 		console.log(`Signed in to firebase:`, userCredential);
		// 		// 	})
		// 		// 	.catch((error) => {
		// 		// 		const errorCode = error.code;
		// 		// 		const errorMessage = error.message;
		// 		// 		console.error(`Error signing in to firebase:`, {
		// 		// 			errorCode,
		// 		// 			errorMessage,
		// 		// 		});
		// 		// 	});
		// 	}
		// } else {
		// 	console.warn(`No firebase token in auth result`, result);
		// }

		// datadogRum.setGlobalContextProperty('tenant', {
		// 	id: tenant?.id,
		// 	name: tenant?.name,
		// });

		// datadogRum.setUser({
		// 	id: user?.id,
		// 	name: user?.name,
		// 	email: user?.email,
		// 	tenantId: tenant?.id,
		// 	tenantName: tenant?.name,
		// 	appRoles: tenantUser?.appRoles,
		// });

		// Identify user to LogRocket
		if (AppConfig.buildEnv !== 'dev') {
			LogRocket.identify(user && user.id, {
				name: user?.name,
				email: user?.email,

				// Add your own custom user variables here, ie:
				tenantId: tenant?.id,
				tenantName: tenant?.name,
				appRoles: tenantUser?.appRoles,

				subscriptionType: account?.packageName,
				accountId: account?.id,
			});
		}

		// Notify any callers that are waiting and clear the 'guard'
		if (this.authPromise) {
			this.authPromise.resolve(token);
			this.authPromise = null;
		}

		this.setAuthorizationState(result);

		this.setIsAuthorized(true);

		// Connect the socket
		// Safe to call multiple times, it's a NOOP if already started/connected
		BackendService.connect();
	}

	/**
	 * Set a cron that runs while page is loaded to keep token up to date, just a few minutes before it expires
	 */
	static startRefreshTimer() {
		setInterval(
			() => AuthService.refreshToken(),
			TOKEN_EXPIRATION_MINUTES * 60 * 1000 * TOKEN_EXPIRATION_RATIO,
		);
	}

	/**
	 * Returns current/stored token. No validity checks done. Use checkAuthorizationStatus() to ensure token is good.
	 * @returns Current token. Assumed to be valid.
	 */
	static getToken() {
		const token = this.tokenState.getValue();
		if (token) {
			return token;
		}

		let cachedToken = window.localStorage.getItem(TOKEN_CACHE_KEY);

		// Some bad stringification sometimes..
		if (cachedToken === 'undefined') {
			cachedToken = undefined;
		}

		this.tokenState.setValue(cachedToken);
		return cachedToken;
	}

	/**
	 * Returns the current adminId for the current user - used to detect if the user is being shadowed
	 * @returns {string} adminId for current user
	 */
	static getAdminId() {
		return this.adminId;
	}

	/**
	 * Update the stored/cached token. Primarily internal use for AuthService
	 * @param {string} token New token
	 */
	static setToken(token) {
		window.localStorage.setItem(TOKEN_CACHE_KEY, token);
		this.tokenState.setValue(token);
		if (!token) {
			this.adminId = null;
		} else {
			const decodedToken = decode(token);
			const { aid: adminId } = decodedToken || {};
			this.adminId = adminId;
			// console.log(`Loaded auth token:`, token, decodedToken);
		}
	}

	/**
	 * Clear/remove the stored/cached token. Use to effect a "logout".
	 */
	static clearToken({
		forceReload = true,
		reloadCode = '',
		failureMessage,
	} = {}) {
		const token = this.getToken();
		if (token) {
			// Clear local storage for this user
			window.localStorage.removeItem(TOKEN_CACHE_KEY);
			window.localStorage.removeItem(AUTHORIZATION_CACHE_KEY);
			// Note the use of session storage to localize this key to
			// this window/tab and not just the app
			window.sessionStorage.removeItem(PRE_LOGIN_PATH_KEY);
			window.sessionStorage.removeItem(PRE_LOGIN_TOKEN_KEY);

			// Clear internal state vars
			this.tokenState.setValue(null);
			this.authorizationState.setState({});
			this.setIsAuthorized(false);

			if (window.posthog) {
				window.posthog.reset();
			}

			// Disconnect socket
			BackendService.disconnect();

			// Force clear SWR cache
			// swrCache.clear();
		}

		// By default, we will also reload the page to ensure state is fresh
		if (forceReload) {
			console.warn(`AuthService.clearToken, reloading`);

			// Add date to cache bust
			window.location.href = `/login?_=${Date.now()}&utm_source=clearToken&reloadCode=${reloadCode}&failureMessage=${encodeURIComponent(
				failureMessage,
			)}`;

			// Because some people still sees cache issues..
			setTimeout(() => window.location.reload(), 100);
		}
	}

	/**
	 * Useful for super admins switching tenants
	 */
	static clearAuthCache() {
		window.localStorage.removeItem(AUTHORIZATION_CACHE_KEY);
	}

	static setAuthorizationState(authorization) {
		// console.log(`setAuthorizationState`, authorization);
		this.authorizationState.setState(authorization);
		window.localStorage.setItem(
			AUTHORIZATION_CACHE_KEY,
			JSON.stringify(authorization),
		);

		const { user, tenant } = authorization || {};
		if (window.posthog && user) {
			window.posthog.identify(user.id);
			window.posthog.people.set({
				email: user.email,
				name: user.name,
				phoneNum: user.phoneNum,
				tenantId: tenant && tenant.id,
			});
		}
	}

	static modifyUserFlagsLocally({ isDriverOnline, isSupportOnline }) {
		const authorization = this.authorizationState.getValue();
		if (authorization) {
			const { user } = authorization;
			const mutatedUser = {
				...user,
				...(isDriverOnline !== undefined && { isDriverOnline }),
				...(isSupportOnline !== undefined && { isSupportOnline }),
			};
			this.setAuthorizationState({
				...authorization,
				user: mutatedUser,
			});

			console.log(`Modified local user state with flags:`, mutatedUser);
		} else {
			console.warn(`Not authorized yet, cannot mutate local auth store`);
		}
	}

	/**
	 * Used by <AppRoute> to store path for returning to the private page
	 * upon successful login
	 * @param {string} currentPath Path
	 */
	static storePreLoginPath(path) {
		const currentPath = path || getCurrentPath();

		try {
			const urlSearch = window.location.search;
			const hashSearch = `?${window.location.hash.split('?')[1]}`;
			const tokenContainer = `${hashSearch}`.includes('token=')
				? hashSearch
				: urlSearch;

			const { token } = Object.fromEntries(new URLSearchParams(tokenContainer));
			window.sessionStorage.setItem(PRE_LOGIN_TOKEN_KEY, token || '');

			if (token) {
				console.log(`Stored pre-login token`, token);
			} else {
				// console.log(`No pre-login token found, cleared storage`, {
				// 	urlSearch,
				// 	hashSearch,
				// 	tokenContainer,
				// });
			}
		} catch (ex) {
			// ignore
		}
		this.preLoginPath = currentPath;

		if (global.window) {
			// Note the use of 'sessionStorage' to localize this key to this window/tab and not just the app's domain
			global.window.sessionStorage.setItem(PRE_LOGIN_PATH_KEY, currentPath);
		}
	}

	static getPreLoginToken() {
		const token = window.sessionStorage.getItem(PRE_LOGIN_TOKEN_KEY);
		if (!token || token === 'undefined') {
			return undefined;
		}

		return token;
	}

	static clearPreLoginToken() {
		return window.sessionStorage.removeItem(PRE_LOGIN_TOKEN_KEY);
	}

	/**
	 * Used by LoginPage to know where to go
	 * @returns Last path required for login
	 */
	static getPreLoginPath() {
		let candidatePath = null;

		if (this.preLoginPath) {
			candidatePath = this.preLoginPath;
		} else {
			// Note the use of session storage to localize this key to this window/tab and not just the app
			const cached = window.sessionStorage.getItem(PRE_LOGIN_PATH_KEY);
			if (cached) {
				candidatePath = cached;
			}
		}

		if (
			!candidatePath ||
			`${candidatePath}`.includes('/login') ||
			`${candidatePath}`.includes('/support-chat')
		) {
			return BackendService.defaultRoute || DEFAULT_PRE_LOGIN_PATH;
		}

		return candidatePath;
	}
}

// Export our React hook to get authorization state
export const useIsAuthorized = (checkStatusIfUndefined = false) => {
	const value = useFunctionalState(AuthService.isAuthorizedState);

	// console.warn(`useIsAuthorized with arg:`, { checkStatusIfUndefined, value });

	useEffect(() => {
		if (checkStatusIfUndefined && value === undefined) {
			// This will change isAuthenticated from `undefined` to true/false, forcing re-render
			AuthService.checkAuthorizationStatus();
		}
	}, [checkStatusIfUndefined, value]);

	return value;
};

// Export our authorization information (current user, etc)
export const useAuthorization = () => {
	useIsAuthorized(true); // force check if not already loaded
	return useFunctionalState(AuthService.authorizationState);
};

// Export our firebase authorization flag
export const useFirebaseConnected = () => {
	return useFunctionalState(AuthService.firebaseConnectedState);
};

// Start the timer to keep tokens fresh. NOOP if no token or not logged in
// Re-enabled to keep firebase token fresh during session
AuthService.startRefreshTimer();

if (process.env.NODE_ENV === 'development') {
	window.AuthService = AuthService;
}
