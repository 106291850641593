/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MagnifyingGlassIcon from '@material-ui/icons/Search';
import BackIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import DirectionsIcon from '@material-ui/icons/Directions';
import ButtonBase from '@material-ui/core/ButtonBase';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { useBreakpoint } from 'shared/utils/breakpoints';
import CustomSkeleton from 'shared/components/CustomSkelton';

import useSWR from 'swr';
import { ServerStore } from 'shared/services/ServerStore';
import { useFunctionalState } from 'shared/components/FunctionalState';
import {
	findParent,
	getAttributeSafe,
	hasAttributeSafe,
} from 'shared/utils/documentEventHelpers';
import use1rem from 'shared/utils/use1rem';

import RoomIcon from '@material-ui/icons/Room';
import HomeIcon from '@material-ui/icons/Home';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import AssistantIcon from '@material-ui/icons/Assistant';
import { simplifyPlaceAddress } from 'shared/utils/simplifyPlaceAddress';
import PlaceSearchProvider, {
	usePlaceSearchContext,
} from 'shared/services/PlaceSearchContext';
import styles from './PlaceSelectDialog.module.scss';

function PlaceSelectDialogContent({
	placeholder,
	onPlaceSelect,
	onCancelClicked,
}) {
	const placeText = usePlaceSearchContext((x) => x.placeText);

	const setPlaceText = usePlaceSearchContext(
		(state) => state.setPlaceText,
	);
	const placesListLoading = usePlaceSearchContext(
		(state) => state.placesListLoading,
	);
	const getPlaceDetails = usePlaceSearchContext(
		(state) => state.getPlaceDetails,
	);
	const placeResultsFromFieldName = usePlaceSearchContext(
		(state) => state.fieldType,
	);

	const placeResults = usePlaceSearchContext(
		(state) => state.placeResults,
	);

	// console.log(`place results`, placeResults);

	return (
		<>
			<Paper component="form" className={styles.searchRoot}>
				<IconButton
					className={clsx(styles.iconButton, styles.backButton)}
					aria-label="menu"
					onClick={onCancelClicked}
				>
					<BackIcon />
				</IconButton>
				<InputBase
					className={styles.input}
					placeholder={placeholder}
					inputProps={{
						'aria-label': placeholder,
					}}
					value={placeText || ''}
					onChange={(e) => setPlaceText(e.target.value)}
				/>
				<IconButton
					className={styles.iconButton}
					aria-label="search"
					onClick={() => setPlaceText('')}
				>
					{placeText ? <CloseIcon /> : <MagnifyingGlassIcon />}
				</IconButton>
			</Paper>
			<List className={styles.listRoot}>
				{placesListLoading && (
					<>
						<ListItem>
							<ListItemIcon>
								<CustomSkeleton>
									<RoomIcon />
								</CustomSkeleton>
							</ListItemIcon>
							<CustomSkeleton>
								<ListItemText
									primary="Just pick me up"
									secondary="another line"
								/>
							</CustomSkeleton>
						</ListItem>
						<ListItem>
							<ListItemIcon>
								<CustomSkeleton>
									<RoomIcon />
								</CustomSkeleton>
							</ListItemIcon>
							<CustomSkeleton>
								<ListItemText
									primary="Just pick me up a bit longer"
									secondary="another line"
								/>
							</CustomSkeleton>
						</ListItem>
						<ListItem>
							<ListItemIcon>
								<CustomSkeleton>
									<RoomIcon />
								</CustomSkeleton>
							</ListItemIcon>
							<CustomSkeleton>
								<ListItemText
									primary="Just pick me"
									secondary="another line"
								/>
							</CustomSkeleton>
						</ListItem>
					</>
				)}
				{!placesListLoading &&
					(placeResults || []).map(
						({
							googlePlaceId,
							searchResult,
							description,
							mainText,
							secondaryText,
							id,
							lat,
							lng,
							name,
							addressText,
							shortcutId,
							dropoffTbd,
							currentLocation,
						}) => (
							<ListItem
								// key={
								// 	mainText +
								// 	secondaryText +
								// 	addressText +
								// 	name +
								// 	description +
								// 	dropoffTbd +
								// 	shortcutId +
								// 	currentLocation
								// }
								key={
									id ||
									googlePlaceId ||
									(lat && lng && lat + lng) ||
									description
								}
								button
								onClick={async () => {
									if (searchResult && !googlePlaceId) {
										setPlaceText(description);
									} else {
										onPlaceSelect({
											dropoffTbd,
											lat,
											lng,
											addressText,
											name,
											searchResult,
											googlePlaceId,
											mainText,
											secondaryText,
											currentLocation,
											details:
												googlePlaceId &&
												(await getPlaceDetails(
													googlePlaceId,
												)),
										});
									}
								}}
							>
								<ListItemIcon>
									{dropoffTbd && <AssistantIcon />}
									{shortcutId && <HomeIcon />}
									{currentLocation && <GpsFixedIcon />}
									{!dropoffTbd &&
										!shortcutId &&
										!currentLocation && <RoomIcon />}
								</ListItemIcon>
								{dropoffTbd ? (
									<ListItemText
										primary="Just pick me up"
										secondary="Destination can be added later"
									/>
								) : (
									<ListItemText
										primary={
											searchResult
												? mainText || description
												: name
										}
										secondary={simplifyPlaceAddress(
											searchResult
												? secondaryText
												: addressText,
										)}
									/>
								)}
							</ListItem>
						),
					)}
			</List>
		</>
	);
}

export default function PlaceSelectDialog({
	open,
	placeholder = 'Select an item',
	onCancel,
	onSelect,
	forUserId,
}) {
	const isSmallScreen = useBreakpoint('small');
	return (
		<Dialog
			open={!!open}
			onClose={onCancel}
			fullScreen={!!isSmallScreen}
			className={clsx(
				styles.root,
				open && styles.visible,
				isSmallScreen && styles.isSmallScreen,
			)}
		>
			<PlaceSearchProvider userIdOverride={forUserId}>
				<PlaceSelectDialogContent
					placeholder={placeholder}
					onPlaceSelect={onSelect}
					onCancelClicked={onCancel}
				/>
			</PlaceSearchProvider>
		</Dialog>
	);
}
