/* eslint-disable no-console */
export function findParent(startNode, selector = (node) => !!node) {
	let el = startNode;
	if (!el) {
		return undefined;
	}

	if (selector(el)) {
		return el;
	}

	while (el.parentNode) {
		el = el.parentNode;
		if (!el || el === document) {
			return el;
		}

		if (selector(el)) {
			return el;
		}
	}
	return null;
}

export function hasAttributeSafe(node, attributeName) {
	if (!node) {
		return undefined;
	}
	if (typeof node.hasAttribute !== 'function') {
		console.warn(
			`hasAttributeSafe received node that has no .hasAttribute function:`,
			node,
		);
		return undefined;
	}

	return node.hasAttribute(attributeName);
}

export function getAttributeSafe(node, attributeName) {
	if (!node) {
		return undefined;
	}
	if (typeof node.getAttribute !== 'function') {
		console.warn(
			`getAttributeSafe received node that has no .getAttribute function:`,
			node,
		);
		return undefined;
	}

	return node.getAttribute(attributeName);
}

export function eventToCoordinates(ev) {
	let pointEvent;
	if (ev instanceof window.TouchEvent) {
		const { changedTouches, targetTouches } = ev;
		const [firstTouch] = changedTouches || targetTouches;
		pointEvent = firstTouch || {};
	} else {
		pointEvent = ev || {};
	}

	const { clientX, clientY, pageX, pageY, screenX, screenY } = pointEvent || {};
	return {
		clientX,
		clientY,
		pageX,
		pageY,
		screenX,
		screenY,
	};
}

// Need eventToTarget because MouseMove/TouchMove events don't change their
// .target prop to indicate which element they are over...? weird
export function eventToTarget(
	ev,
	coordinateGetter = (result = {}) => ({
		x: result.clientX,
		y: result.clientY,
	}),
) {
	const { x, y } = coordinateGetter(eventToCoordinates(ev));
	if (!x || !y || Number.isNaN(x) || Number.isNaN(y)) {
		return undefined;
	}
	return window.document.elementFromPoint(x, y);
}
