/* eslint-disable func-names */
// based on code from https://www.linkedin.com/campaignmanager/accounts/508956691/website-tracking/conversions/sources > "Manage Insight Tag" > See tag

function loadSdkAsynchronously() {
	// Disable in staging/prod
	if (
		process.env.NODE_ENV !== 'production' ||
		process.env.REACT_APP_STAGING === 'true'
	) {
		return () => {};
	}

	// // eslint-disable-next-line camelcase
	// let _linkedin_partner_id = '3594202';
	// window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
	// window._linkedin_data_partner_ids.push(_linkedin_partner_id);

	// (function (l) {
	// 	if (!l) {
	// 		window.lintrk = function (a, b) {
	// 			window.lintrk.q.push([a, b]);
	// 		};
	// 		window.lintrk.q = [];
	// 	}
	// 	let s = document.getElementsByTagName('script')[0];
	// 	let b = document.createElement('script');
	// 	b.type = 'text/javascript';
	// 	b.async = true;
	// 	b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
	// 	s.parentNode.insertBefore(b, s);
	// })(window.lintrk);

	// const container = document.createElement('div');
	// container.innerHTML = `
	// 	<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=3594202&fmt=gif" />
	// `;
	// document.body.appendChild(container);

	// return window.lintrk;

	// Disabling due to errors in prod
	return () => {};
}

const linkedInInsights = loadSdkAsynchronously();

export default linkedInInsights;
