/* eslint-disable no-nested-ternary */
import useSWR from 'swr';
import AuthService, { useAuthorization } from 'shared/services/AuthService';

const swrOptions = {
	revalidateIfStale: false,
	revalidateOnFocus: false,
	revalidateOnReconnect: false,
};

function getCurrentPath() {
	const currentUrl = `${global.window && global.window.location.href}`;
	if (currentUrl.includes('#')) {
		// Assume hash routing (current app)
		return window.location.hash.substring(1);
	}

	// Assume browser router (miniapps)
	return global.window && global.window.location.pathname;
}

/**
 * SSO to backend using anonymous user
 * @returns Object like `{ error, success, loading, ... }`
 */

export function useAnonymousSSO({ disableSso = false } = {}) {
	AuthService.storePreLoginPath(getCurrentPath());

	const { user } = useAuthorization();

	const {
		data: authResult,
		error: swrError,
		revalidating: apiLoading,
	} = useSWR(
		[disableSso, user, 'anon-sso'],
		(disabled, userAuth) =>
			userAuth ? { success: true } : disabled ? {} : AuthService.anonymousSso(),
		swrOptions,
	);

	const { status, error: apiError, success } = authResult || {};

	const error = apiError || swrError || undefined;
	const result = {
		error,
		errors: {
			swrError,
			apiError: {
				status,
				error: apiError,
			},
		},
		success,
		loading: apiLoading || (!error && !authResult),
	};

	if (result.error) {
		AuthService.clearToken({ forceReload: false });
		// eslint-disable-next-line no-console
		console.error(`Error executing anonymous SSO:`, result);
	} else {
		// console.warn(`useAnonymousSSO final result:`, result);
	}

	return result;
}
