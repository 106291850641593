/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import useSWR from 'swr';
import React, {
	useEffect,
	useRef,
	// createContext,
	useState,
	useMemo,
	useCallback,
} from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import { ServerStore } from 'shared/services/ServerStore';
import { useGooglePlaces } from 'shared/hooks/useGooglePlaces';
import { useConditionallyHandleErrors } from 'shared/hooks/useConditionallyHandleErrors';
import { showToast } from 'shared/utils/showReactToast';
import { useAuthorization } from 'shared/services/AuthService';
import { useIsMounted } from '../hooks/useIsMounted';

const PlaceSearchContext = createContext();

export const CURRENT_LOCATION_PLACE = {
	id: 'meta.currentLocation',
	currentLocation: true,
	name: 'Current Location',
};

export const JUST_PICK_ME_UP_PLACE = {
	id: 'meta.dropoffTbd',
	dropoffTbd: true,
	name: 'Just pick me up',
};

export const usePlaceSearchContext = (selector) => {
	const state = useContextSelector(PlaceSearchContext, selector || ((x) => x));
	if (state === undefined && selector === undefined) {
		throw new Error(
			`Must call usePlaceSearchContext inside a PlaceSearchProvider`,
		);
	}
	return state;
};

export function usePlaceRecommendations(userIdOverride) {
	const conditionallyHandleErrors = useConditionallyHandleErrors();
	const { token: authToken } = useAuthorization();

	// console.log(`conditionallyHandleErrors=`, conditionallyHandleErrors);

	// Place recommendations from backend for current user
	const {
		data: { places: placeRecommendations = [] } = {},
		isValidating: recommendationsLoading,
		error,
	} = useSWR(['GetBestPlacesForUser', userIdOverride], (unused, userId) =>
		// Only load if logged in - e.g. if used on home page of marketing, dont load if not logged in
		authToken && userId !== '-'
			? conditionallyHandleErrors(
					ServerStore.GetBestPlacesForUser({
						userId,
					}),
			  )
			: {},
	);

	if (error) {
		console.error(`usePlaceRecommendations loading error:`, error);
	}
	// console.log(`usePlaceRecommendations:`, {
	// 	placeRecommendations,
	// 	recommendationsLoading,
	// 	error,
	// });

	// useEffect(() => {
	// 	ServerStore.GetBestPlacesForUser({
	// 		userIdOverride,
	// 	}).then((res) => {
	// 		console.log(`try 2 res`, res);
	// 	});
	// }, [userIdOverride]);

	return { placeRecommendations, recommendationsLoading };
}

export default function PlaceSearchProvider({
	fieldType = 'dropoff',
	defaultPlaceText,
	userIdOverride,
	children,
	allowCurrentLocation = true,
	// allowDropoffTbd = true,
	preloadedPlaces = [],
}) {
	const mountedRef = useIsMounted();
	// Completely disabling dropoff-TBD until trip legs is modified to support that
	const allowDropoffTbd = false;

	const [placeText, setPlaceText] = useState();

	const { placeRecommendations } = usePlaceRecommendations(userIdOverride);

	const lastDefaultRef = useRef();
	useEffect(() => {
		if (mountedRef.current) {
			if (lastDefaultRef.current !== defaultPlaceText) {
				setPlaceText(defaultPlaceText);
			}
		}
	}, [defaultPlaceText, mountedRef]);

	const [queryText] = useDebounce(placeText, 250);

	// console.log(`text:`, { placeText, defaultPlaceText, queryText });

	const {
		placesAutocomplete,
		getPlaceDetails,
		stopAutocompleteSession,
		PLACES_OK_STATUS,
	} = useGooglePlaces();

	const [placesListLoading, setPlacesListLoading] = useState(false);
	// const setPlacesListLoading = useCallback((flag) => {
	// 	console.warn(`setPlacesListLoading:`, flag);
	// 	setPlacesListLoading_(flag);
	// }, []);

	const autocompleteQueryRef = useRef();
	const autocompleteResultsCache = useRef();

	const setQueryText = useCallback(
		(text) => {
			if (mountedRef.current) {
				setPlaceText(text);
				if (autocompleteQueryRef.current !== text) {
					setPlacesListLoading(true);
				}
			}
		},
		[mountedRef],
	);

	const autocomplete = useCallback(
		async (valueInput) => {
			const value = valueInput && `${valueInput}`.trim();
			const { current: lastSearchValue } = autocompleteQueryRef;
			const { current: lastQueryResults } = autocompleteResultsCache;
			if (lastSearchValue === value) {
				return lastQueryResults;
			}

			autocompleteQueryRef.current = value;

			// 'Current Location' is a 'display' string for when
			// we're really just using GPS for user pickup.
			// And, of course, 'Just pick me up' is also a 'display'
			// string for trip.dropoffTbd
			if (
				!value ||
				value === CURRENT_LOCATION_PLACE.name ||
				value === JUST_PICK_ME_UP_PLACE.name
			) {
				stopAutocompleteSession();
				// setPlacesList(getPlacesForField(field));
				if (mountedRef.current) {
					setPlacesListLoading(false);
				}
				autocompleteResultsCache.current = undefined;
				return undefined;
			}

			// console.log(`Places Autocomplete:`, value);

			if (mountedRef.current) {
				setPlacesListLoading(true);
			}
			const { predictions, status } = (await placesAutocomplete(value)) || {};
			if (status !== PLACES_OK_STATUS || !predictions) {
				// eslint-disable-next-line no-alert
				// window.alert(status);
				if (status === 'ZERO_RESULTS') {
					showToast(`No places found matching "${value}"`, 'warn');
				} else if (status) {
					showToast(status, 'error');
				}
				// setPlacesListLoading(false);
				// autocompleteResultsCache.current = undefined;
				// return undefined;

				const resultsList = [];
				if (mountedRef.current) {
					setPlacesListLoading(false);
				}
				autocompleteResultsCache.current = resultsList;
				return resultsList;
			}
			toast.dismiss();

			const resultsList = predictions.map((result) => {
				const {
					place_id: googlePlaceId,
					description,
					structured_formatting: {
						main_text: mainText,
						secondary_text: secondaryText,
					} = {},
				} = result;
				return {
					searchResult: true,
					googlePlaceId,
					description,
					mainText,
					secondaryText,
					rawGoogleResult: result,
				};
			});

			// console.warn(
			// 	`Got autocomplete res:`,
			// 	predictions,
			// 	resultsList,
			// );
			if (mountedRef.current) {
				setPlacesListLoading(false);
			}

			autocompleteResultsCache.current = resultsList;
			return resultsList;
		},
		[PLACES_OK_STATUS, mountedRef, placesAutocomplete, stopAutocompleteSession],
	);

	const { data: searchResults, error: searchError } = useSWR(
		[queryText, 'places'],
		(text) => autocomplete(text),
	);

	if (searchError) {
		console.error(`Places search error:`, searchError);
	}

	const placeResults = useMemo(
		() =>
			(placeText && searchResults) ||
			(fieldType === 'start'
				? userIdOverride || !allowCurrentLocation
					? [...preloadedPlaces, ...placeRecommendations]
					: [
							CURRENT_LOCATION_PLACE,
							...preloadedPlaces,
							...placeRecommendations,
					  ]
				: !allowDropoffTbd || typeof fieldType === 'object'
				? [...preloadedPlaces, ...placeRecommendations]
				: [JUST_PICK_ME_UP_PLACE, ...preloadedPlaces, ...placeRecommendations]),
		[
			placeText,
			searchResults,
			fieldType,
			userIdOverride,
			allowCurrentLocation,
			preloadedPlaces,
			placeRecommendations,
			allowDropoffTbd,
		],
	);
	// console.log(`placeResults`, placeResults);

	const state = {
		placeText,
		placeResults,
		placesListLoading,
		fieldType,
		setPlaceText: setQueryText,
		userIdOverride,
		getPlaceDetails,
		stopAutocompleteSession,
		preloadedPlaces,
	};

	// console.log(`PlaceSearchContext.Provider state:`, state);

	return (
		<PlaceSearchContext.Provider value={state}>
			{children}
		</PlaceSearchContext.Provider>
	);
}
