/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import clsx from 'clsx';
import {
	useState,
	useEffect,
	useRef,
	useCallback,
	createContext,
	useContext,
	useMemo,
} from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandIcon from '@material-ui/icons/OpenInNew';
import history from 'shared/utils/history';
import use1rem from 'shared/utils/use1rem';
import { useBreakpoint } from 'shared/utils/breakpoints';
import BottomPanel, {
	useWindowRelativeSize,
	useDragContext,
} from 'shared/components/BottomPanel/BottomPanel';
import ConversationWidget from 'shared/components/ConversationWidget';
import styles from './ConversationPanel.module.scss';

/**
 * Context for use with useConversationPanel
 */
const ConversationPanelContext = createContext();

/**
 * Get access to the things
 * @returns An object like { ... }
 */
export function useConversationPanel() {
	const context = useContext(ConversationPanelContext);
	if (context === undefined) {
		throw new Error(
			`useConversationPanel must be called from inside a ConversationPanelProvider`,
		);
	}
	return context;
}

export function ConversationPanelProvider({ children }) {
	const [showFloatingChatButton, setShowFloatingChatButton] = useState(true);

	const [conversationId, setConversationId] = useState();
	const [hasNewMessages, setHasNewMessages] = useState();
	const [isPanelOpen, setIsPanelOpen] = useState(false);

	const canMaximizePanel = true;

	const rem1 = use1rem();
	const panelActionsRef = useRef({});

	const onNewMessage = useCallback(() => setHasNewMessages(true), []);

	const onOpenClose = useCallback((flag) => {
		if (flag) {
			setHasNewMessages(false);
		}
		setIsPanelOpen(flag);
	}, []);

	const actionHelper = useCallback((action, ...args) => {
		if (panelActionsRef.current && panelActionsRef.current[action]) {
			panelActionsRef.current[action](...args);
		}
	}, []);

	const contextState = useMemo(
		() => ({
			hasNewMessages,
			conversationId,
			setConversationId,
			showFloatingChatButton,
			setShowFloatingChatButton,
			openConvoPanel: () => actionHelper('open'),
			closeConvoPanel: () => actionHelper('close'),
			toggleConvoPanel: () => actionHelper('toggle'),
		}),
		[actionHelper, conversationId, hasNewMessages, showFloatingChatButton],
	);

	if (process.env.NODE_ENV === 'development') {
		window.ConversationPanelContextState = contextState;
	}

	// console.log(`Conversation panel debug:`, {
	// 	conversationId,
	// 	showFloatingChatButton,
	// });

	const isSmall = useBreakpoint('small');

	const maxOpenHeight = useWindowRelativeSize(
		useCallback((x) => (isSmall ? x : x * 0.75), [isSmall]),
	);

	const onClickAway = useCallback(
		() => isPanelOpen && actionHelper('close'),
		[actionHelper, isPanelOpen],
	);

	return (
		<ConversationPanelContext.Provider value={contextState}>
			{children}
			<ClickAwayListener onClickAway={onClickAway}>
				<div className={styles.root}>
					{conversationId ? (
						<BottomPanel
							maxOpenHeight={maxOpenHeight}
							className={clsx(
								styles.bottomPanel,
								// Only show shadow if panel is open and not small
								// If always visible, it will "leak" at bottom of screen when closed
								!isSmall && isPanelOpen && styles.needsShadow,
							)}
							actionsRef={panelActionsRef}
							onOpenClose={onOpenClose}
							closedPanelSize={0}
							clampDragToLimits={false}
							disableDrag={!canMaximizePanel}
							hideDragHandle={!canMaximizePanel}
							dragHandleContent={<ButtonBase />}
							disableContentHeightInterpolation={true}
							disableSpringOpen={true}
							dragHandleOnly={true}
						>
							<ConversationWidgetAdapter
								conversationId={conversationId}
								onNewMessage={onNewMessage}
								isPanelOpen={isPanelOpen}
							/>
						</BottomPanel>
					) : (
						''
					)}
				</div>
			</ClickAwayListener>
		</ConversationPanelContext.Provider>
	);
}

export function useHideFloatingChatButton(flag = true) {
	const { setShowFloatingChatButton } = useConversationPanel();
	useEffect(() => {
		setShowFloatingChatButton(!flag);
		return () => setShowFloatingChatButton(true);
	});
}

function ConversationWidgetAdapter({
	conversationId,
	onNewMessage,
	isPanelOpen,
}) {
	// const { movementAmount } = useDragContext();
	// const panelCompletelyOpen = movementAmount === 1;
	// console.log(`ConversationWidgetAdapter:`, {
	// 	movementAmount,
	// 	panelCompletelyOpen,
	// });
	// if (panelCompletelyOpen) {
	// if (!conversationId) {
	// 	return <div className={styles.panelFiller}></div>;
	// }

	return (
		// Set to 0 because the wrapper panel will compensate automatically
		<div className={styles.convoWidgetAdapter}>
			<ConversationWidget
				conversationId={conversationId}
				onNewMessage={onNewMessage}
				// hideBackButton={true}
				hideForegroundNotifications={isPanelOpen}
				disableHead={true}
				headerIcon={<ExpandIcon />}
				onBackClicked={() =>
					history.push(`/conversation/${conversationId}?backToDashboard=true`)
				}
			/>
		</div>
	);
	// }

	// return <div className={styles.panelFiller}></div>;
}
