// h/t https://stackoverflow.com/a/8358141
// Updated to my OCD levels
export default function formatPhone(phoneNum, intlCode = '') {
	const cleaned = `${phoneNum}`.replace(/[^+\d]/g, '');
	if (!cleaned) {
		return '';
	}
	let match = cleaned.match(/^(\+?1|)?(\d{3})(\d{3})(\d{4})$/);
	if (!match) {
		let matchItl = cleaned.match(/^(\+\d{3})(\d+)$/);
		if (!matchItl) {
			return '';
		}

		const [, countryCode, digits] = matchItl;
		const pairs = [];
		let numbers = [...digits];

		// If remaining # of digits after country code divisible by 4 (e.g. Guate)
		// then format as two groups of 4, otherwise group in groups of 3
		let slotLength = numbers.length % 4 === 0 ? 4 : 3;
		while (numbers.length) {
			const firstDigits = numbers.slice(0, slotLength).join('');
			numbers = numbers.slice(slotLength);
			pairs.push(firstDigits);
		}
		return [countryCode, ...pairs].join('-');
	}

	return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
}
