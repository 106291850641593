/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import EventEmitter from 'eventemitter3';
import DeviceInfo from 'shared/utils/DeviceInfo';
import GpsWorkerClient, { gpsWorkerRoot } from 'shared/utils/GpsWorkerClient';
import AuthService from 'shared/services/AuthService';
import AppConfig from 'shared/config-public';
import { MessageTypes } from 'shared/utils/MessageTypes';
import { later } from 'shared/utils/later';
import { defer } from 'shared/utils/defer';

// Max messages we keep when socket disconnected before we start dropping older messages
const MAX_BUFFER_LENGTH = 256;

export default class GpsStreamTransmitter {
	static emitter = new EventEmitter();

	static on(...args) {
		this.emitter.on(...args);
	}

	static off(...args) {
		this.emitter.off(...args);
	}

	static emit(...args) {
		this.emitter.emit(...args);
	}

	static start() {
		AuthService.authorizationState.on(
			'changed',
			GpsStreamTransmitter.authChanged,
		);

		this.authChanged(AuthService.authorizationState);
	}

	static stop() {
		AuthService.authorizationState.off(
			'changed',
			GpsStreamTransmitter.authChanged,
		);
	}

	static authChanged = ({ value }) => {
		const { user, token } = value || {};
		const { id: userId } = user || {};
		const { userId: oldUserId, token: oldToken } = this;
		this.userId = userId;
		this.token = token;

		if (oldUserId !== userId || oldToken !== token) {
			this.reconnectSocket();
		}

		this.emit('changed');
	};

	static reflectPathForUser(userId, { absolute = true } = {}) {
		const reflectPath = `/api/reflect/${userId || this.userId}`;
		if (absolute) {
			return `${gpsWorkerRoot}${reflectPath}`;
		}

		return reflectPath;
	}

	static reconnectSocket() {
		if (this.socket) {
			this.socket.stop();
			this.socket = null;
		}

		if (global.navigator && !global.navigator.onLine) {
			console.warn(`Device reports offline, not attempting socket connection`);
			return;
		}

		// if (AppConfig.buildEnv === 'dev') {
		// 	console.error(
		// 		`GpsStreamTransmitter.reconnectSocket: Not reconnecting in dev - to reconnect, remove this block`,
		// 	);
		// 	return;
		// }

		const { userId, token } = this;
		if (userId && token) {
			const path = `${this.reflectPathForUser(userId, {
				absolute: false,
			})}/websocket`;

			// console.log(`GpsStreamTransmitter creating socket:`, {
			// 	userId,
			// 	token,
			// 	path,
			// });

			this.cf = null;
			this.wanIp = null;

			this.socket = new GpsWorkerClient({
				path,
				token,
				name: 'GpsStreamTransmitter',
				onConnected: (data) => this.onConnected(data),
				onDisconnected: () => this.onDisconnected(),
			});
		}
	}

	static onConnected(data) {
		const { cf, ip } = data;
		this.wanIp = ip;
		this.cf = cf;

		if (this.pendingCf) {
			this.pendingCf.resolve(cf);
		}

		this.isConnected = true;

		if (this.pendingMessages) {
			this.pendingMessages.forEach((msg) => this.storeGpsLocation(msg));
			this.pendingMessages = null;
		}
	}

	static onDisconnected() {
		this.isConnected = false;
	}

	static getCf() {
		if (!this.cf) {
			if (this.pendingCf) {
				return this.pendingCf;
			}

			this.pendingCf = defer();
			return this.pendingCf;
		}

		return this.cf;
	}

	static async storeGpsLocation(data) {
		if (!this.isConnected) {
			if (!this.pendingMessages) {
				this.pendingMessages = [];
			}

			this.pendingMessages.push(data);
			if (this.pendingMessages.length > MAX_BUFFER_LENGTH) {
				this.pendingMessages.shift(); // remove oldest
			}
			return;
		}

		if (data.sensorType) {
			const { deviceClass, appType } = await DeviceInfo.getDeviceInfo();
			// eslint-disable-next-line no-param-reassign
			data.sensor = `${deviceClass}.${data.sensorType || appType}`;
			// eslint-disable-next-line no-param-reassign
			delete data.sensorType;
		}

		if (this.socket) {
			this.socket.sendMessage({
				type: MessageTypes.GPSStreamMessage,
				...data,
			});
		} else {
			console.warn(`No socket to transmit to`);
		}
	}
}

// Connect immediately to worker if auth, else listen for auth changes
later(() => GpsStreamTransmitter.start());
