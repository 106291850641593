import { useState, useEffect } from 'react';

export function useImage(src) {
	const [image, setImage] = useState();

	useEffect(() => {
		if (!src) {
			return;
		}
		// eslint-disable-next-line no-undef
		const img = new Image();
		img.onload = () => {
			setImage(img);
		};

		img.src = src;
	}, [src]);

	return image;
}
