import normalizeLatLng from 'shared/utils/normalizeLatLng';

export const toLatLngRef = ({ lat, lng }) =>
	new window.google.maps.LatLng(lat, lng);

export default class DirectionsUtil {
	static setup(google) {
		this.google = google;
		this.geocoder = new google.maps.Geocoder();
		this.directions = new google.maps.DirectionsService();
		this.cachedDirections = {};
	}

	static isSetup() {
		return !!this.google;
	}

	/**
	 * Return cached or get directions (and cache) between these two points
	 * @param {object} origin Object with { lat, lng } keys
	 * @param {object} destination Object with { lat, lng } keys
	 * @param {boolean} ignoreCache Default false, set to true to force to re-request for new directions
	 * @returns Google maps directions result (possibly cached)
	 */
	static async getCachedDirections(origin, destination, ignoreCache = false) {
		if (!origin || !destination) {
			return undefined;
		}

		const { google, directions } = this;

		const key = [
			...Object.values(normalizeLatLng(origin || {})),
			...Object.values(normalizeLatLng(destination || {})),
		].join(':');

		if (!ignoreCache && this.cachedDirections[key]) {
			return this.cachedDirections[key];
		}

		return new Promise((resolve) => {
			// console.log(`USING this.directions...`);
			const request = {
				origin: toLatLngRef(origin),
				destination: toLatLngRef(destination),
				travelMode: google.maps.TravelMode.DRIVING,
			};

			directions.route(request, (result, status) => {
				if (status === google.maps.DirectionsStatus.OK) {
					this.cachedDirections[key] = result;

					// Used for onSnapFailed when tracking
					// eslint-disable-next-line no-param-reassign
					result.cachedRequest = {
						origin,
						destination,
					};

					// console.log('Got directions:', result);

					resolve(result);
				} else {
					resolve({
						error: `Error fetching directions: ${result}`,
						status,
						origin,
						destination,
						request,
					});
				}
			});
		});
	}

	// h/t https://stackoverflow.com/a/16180970 for this logic
	static directionsToPath(directions) {
		if (!directions) {
			return {};
		}

		if (!directions.routes) {
			// eslint-disable-next-line no-console
			console.warn(`No directions.routes property`, directions);
			return {};
		}

		const { google } = this;

		const polyline = new google.maps.Polyline({
			path: [],
		});
		const bounds = new google.maps.LatLngBounds();
		const points = [];

		let totalTime = 0;
		const { legs } = directions.routes[0];
		for (let i = 0; i < legs.length; i++) {
			const { steps } = legs[i];
			for (let j = 0; j < steps.length; j++) {
				const {
					path: nextSegment,
					duration: { value: seconds },
				} = steps[j];

				const avgSecondsPerSegment = seconds / (nextSegment.length || 1);

				for (let k = 0; k < nextSegment.length; k++) {
					const point = nextSegment[k];
					polyline.getPath().push(point);
					bounds.extend(point);

					totalTime += avgSecondsPerSegment;
					const [lat, lng] = [point.lat(), point.lng()];
					points.push({
						lat,
						lng,
						point,
						seconds: avgSecondsPerSegment,
						totalTime,
					});
				}
			}
		}

		return { path: polyline.getPath(), bounds, points };
	}
}
