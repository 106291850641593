/* eslint-disable no-console */
import {
	MAP_BRAND_COLOR_RAW_HEX,
	MAP_PATH_INACTIVE_COLOR,
	getTrackingIcon,
} from 'shared/utils/mapIconGenerators';
import { pointFocusHelper } from 'shared/hooks/useTripPath';
import DirectionsUtil, { toLatLngRef } from 'shared/hooks/DirectionsUtil';

// When matching a given GPS reading to a path, if the nearest point matched
// is more than  SNAP_DISTANCE (meters) away from the path,
// render the tracking marker at the actual GPS reading - otherwise, the tracking
// marker will be rendered at a properly-interpolated position on the path
const SNAP_DISTANCE = 125; // meters

export class TrackingPath {
	constructor({
		origin,
		destination,
		loggingContextProps = {
			// loggingContextProps is currently only used for AuditLog,
			// when transmitting the `gps.tracking_path.directions_rerouted` actionId.
			// The server only allows the following props in that audit log:
			// ['tripId', 'legId', 'gpsStreamSource', 'lat', 'lng', 'heading']
			// ... and lat/lng/heading are injected automatically below. The rest
			// must be provided at time of creation.
			tripId: '',
			legId: '',
			gpsStreamSource: '',
		},
		focusSizeMiles = 0.5,
		google = window.google,
		pathIdPrefix = 'TrackingPath',
		activePathColor = `#${MAP_BRAND_COLOR_RAW_HEX}`,
		inactivePathColor = `#${MAP_PATH_INACTIVE_COLOR}`,
	}) {
		if (!destination) {
			throw new Error(
				`.destination is required, because TrackingPath uses it in case of re-routing`,
			);
		}

		if (!origin) {
			throw new Error(`No .origin - cannot automatically calculate route`);
		}

		if (!google) {
			throw new Error(
				`Google javascript libraries must be loaded prior to creating a TrackingPath`,
			);
		}

		this.google = google;
		this.source = {
			origin,
			destination,
		};
		this.pathOptions = {
			focusSizeMiles,
			pathIdPrefix,
			activePathColor,
			inactivePathColor,
			loggingContextProps,
		};
		this.data = undefined;

		// DirectionsUtil needs google too...
		if (!DirectionsUtil.isSetup()) {
			DirectionsUtil.setup(google);
		}
	}

	isSetup() {
		return !!this.data;
	}

	async setupPath(newOrigin = undefined) {
		const {
			google,
			source: { origin, destination },
			pathOptions: { pathIdPrefix, activePathColor },
		} = this;

		/**
		 * In order to be able to match a tracking GPS point
		 * or interpolate a distance reading into the path, we must
		 * pre-process the given direction path (a set of lat/lng points)
		 * and add some meta data to each point:
		 * - distance (total distance on the path, summed up as the distance between every point)
		 * - ref - a google LatLng object for the lat/lng of the point - makes later calcs easier
		 */
		const directions = await DirectionsUtil.getCachedDirections(
			newOrigin || origin,
			destination,
		);

		if (!directions) {
			console.error(
				`No directions received from google, are there errors above? We asked for:`,
				{ newOrigin, origin, destination },
			);
			return;
		}

		// First, convert the directions object to a path and bounds objects
		const { path, bounds, points } =
			DirectionsUtil.directionsToPath(directions);

		// Next, add the distance and ref attributes to every point on the path
		const internalPath = [];
		let distanceSum = 0;
		let previousPoint = null;
		for (let i = 0; i < points.length; i++) {
			const { lat, lng, point: ref, seconds, totalTime } = points[i];
			if (i === 0) {
				previousPoint = ref;
				internalPath.push({
					lat,
					lng,
					seconds,
					totalTime,
					distance: 0,
					ref: previousPoint,
				});
			} else {
				const currentRef = ref;
				// in meters
				const distance =
					distanceSum +
					google.maps.geometry.spherical.computeDistanceBetween(
						previousPoint,
						currentRef,
					);
				previousPoint = currentRef;
				distanceSum = distance;
				internalPath.push({
					lat,
					lng,
					seconds,
					totalTime,
					distance,
					ref: currentRef,
				});
			}
		}

		// console.log(`setDirections:`, { points, internalPath });

		this.internalPath = internalPath;
		this.data = {
			currentMarker: origin,
			currentIcon: getTrackingIcon({
				rotation: 0,
			}),
			paths: [
				{
					path,
					pathColor: activePathColor,
					id: `${pathIdPrefix}.ActivePath`,
				},
			],
			bounds,
			timeRemaining: internalPath[internalPath.length - 1].totalTime,
		};

		// return this.data;
	}

	// Much of the logic of this updateTrackingProgress() routine is based on
	// the work shown here: https://dev.to/zerquix18/let-s-play-with-google-maps-and-react-making-a-car-move-through-the-road-like-on-uber-part-2-295e
	async updateTrackingProgressFromGps({ lat, lng, heading }) {
		const {
			google,
			source: { destination },
			data,
			internalPath,
			pathOptions: {
				focusSizeMiles,
				pathIdPrefix,
				activePathColor,
				inactivePathColor,
				// loggingContextProps,
			},
		} = this || {};

		if (!internalPath) {
			console.warn(`Unable to updateTrackingProgress, no internalPath stored`);
			return data;
		}

		// Recompute the viewport based on the updated path
		const bounds = new google.maps.LatLngBounds();

		// Current GPS
		const position = toLatLngRef({ lat, lng });

		/**
		 * Inorder to update the tracking based on a given lat/lng,
		 * we need to find the nearest point on our given "expected path"
		 * most closely matches the given lat/lng - then later,
		 * we'll use the distance of "how closely" it matches
		 * to interpolate a proper point along the path for rendering.
		 */
		let nearestNextPoint = null;
		let nearestDistance = 100000;
		let nearestIndex = internalPath.length;

		internalPath.forEach((point, index) => {
			const dist = google.maps.geometry.spherical.computeDistanceBetween(
				point.ref,
				position,
			);
			if (dist < nearestDistance) {
				nearestDistance = dist;
				nearestNextPoint = point;
				nearestIndex = index;
			}
		});

		const inactivePoints = internalPath.slice(0, nearestIndex);

		// Get every point after the nearest point matched
		let pointsRemaining = internalPath.slice(nearestIndex);

		// No points: Bieeeee
		if (!pointsRemaining.length) {
			pointFocusHelper(bounds, destination, {
				focusSizeMiles,
			});

			this.data = {
				...data,
				paths: [],
				currentMarker: destination,
				timeRemaining: 0,
				bounds,
			};

			return this.data; // it's the end!
		}

		// Get the point JUST before the point matched so we can do interpolation
		const lastPoint = internalPath[nearestIndex > 0 ? nearestIndex - 1 : 0];

		const { ref: lastLineLatLng } = lastPoint;
		const { ref: nextLineLatLng } = nearestNextPoint;

		// Interpolate a "good" point at the distance matched by our given lat/lng.
		const totalDistance = nearestNextPoint.distance - lastPoint.distance;
		const percentage = nearestDistance / totalDistance;

		const interpolatedPosition = google.maps.geometry.spherical.interpolate(
			lastLineLatLng,
			nextLineLatLng,
			percentage,
		);

		// Calculate a good angle based on the interpolated point (not tracked point)
		const angle = google.maps.geometry.spherical.computeHeading(
			interpolatedPosition,
			nextLineLatLng,
		);

		const timeRemaining = pointsRemaining.reduce(
			(total, { seconds }) => total + seconds,
			0,
		);

		// Put that interpolated point on the start of the line
		pointsRemaining = [].concat(interpolatedPosition, pointsRemaining);

		// Only first X points for vp bounds
		// pointsRemaining
		// 	.slice(0, PATH_VP_POINTS)
		// 	.forEach((point) => bounds.extend(point));
		const interpolatedLatLng = {
			lat: interpolatedPosition.lat(),
			lng: interpolatedPosition.lng(),
		};

		pointFocusHelper(bounds, interpolatedLatLng, {
			focusSizeMiles,
		});

		// console.log(`Snap debug:`, { nearestDistance, SNAP_DISTANCE });
		const currentMarkerLocation =
			nearestDistance > SNAP_DISTANCE / 4 ? { lat, lng } : interpolatedLatLng;

		pointFocusHelper(bounds, currentMarkerLocation, {
			focusSizeMiles,
		});

		console.log(`tracking heading debug: ${heading || angle}`, {
			heading,
			angle,
		});
		this.data = {
			// If the nearest point matched is more than  SNAP_DISTANCE away from the path,
			// render the tracking marker at the actual GPS reading - otherwise, the tracking
			// marker will be rendered at a properly-interpolated position on the path
			currentMarker: currentMarkerLocation,
			currentIconAngle: angle, // for useCarIcon
			currentIcon: getTrackingIcon({
				rotation: heading || angle,
			}),
			paths: [
				{
					path: inactivePoints,
					pathColor: inactivePathColor,
					id: `${pathIdPrefix}.InactivePath`,
				},
				{
					path: pointsRemaining,
					pathColor: activePathColor,
					id: `${pathIdPrefix}.ActivePath`,
				},
			],
			bounds,
			timeRemaining,
		};

		return this.data;

		// TODO: Reneable this code eventually! need to test first

		// if (nearestDistance <= SNAP_DISTANCE) {
		// 	return this.data;
		// }

		// // Attempt to recalculate directions if snap failed
		// console.warn(
		// 	`Snapping GPS to current path failed, requesting new directions from current point to original destination and updating path...`,
		// 	{ nearestDistance, SNAP_DISTANCE },
		// );

		// // ServerStore.AuditLog(
		// // 	'gps.tracking_path.directions_rerouted',
		// // 	`Tracking Path Exceeded Existing Route, Rerouted`,
		// // 	`Live tracking path exceeded initial route by ${nearestDistance} meters, rerouted`,
		// // 	{ ...loggingContextProps, lat, lng, heading },
		// // );

		// return this.setupPath({ lat, lng });
	}
}
