import React from 'react';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { IconButton } from '@material-ui/core';
import CustomSkeleton from 'shared/components/CustomSkelton';
import MoreMenu from 'shared/components/MoreMenu';
import styles from './HeaderWidget.module.scss';
// import Head from '../../client/src/components/Head';

// Hack because we're deprecating the old app anyway...
const Head = () => {};

export default function HeaderWidget({
	loading,
	title,
	pageTitle,
	actions,
	menuTooltip,
	hideBackButton = false,
	position = 'fixed',
	onBackClicked,
	headerIcon,
	extraChildren = <></>,
	titleComponent = <></>,
	disableHead = false,
	contentComponent = undefined,
	className = '',
}) {
	return (
		<div
			className={clsx(
				'HeaderWidget-root',
				className,
				styles.root,
				styles[`position-${position}`],
			)}
		>
			{!disableHead && (
				<Head title={loading ? 'Loading...' : pageTitle || title} />
			)}
			<div className={clsx(styles.bar, 'HeaderWidget-bar')}>
				{contentComponent || (
					<>
						<IconButton
							onClick={onBackClicked} // || (() => window.history.go(-1))}
							style={{
								opacity: hideBackButton || !onBackClicked ? 0 : 1,
							}}
						>
							{headerIcon || <ChevronLeftIcon />}
						</IconButton>

						{titleComponent || (
							<CustomSkeleton active={loading}>
								<h3
									// For compat with AdminDashboard
									className="WidgetDragHandle cursor-pointer"
								>
									{loading ? 'Loading ...' : title}
								</h3>
							</CustomSkeleton>
						)}

						{actions && actions.length ? (
							<MoreMenu
								tooltip={menuTooltip}
								className={styles.moreMenu}
								actions={actions}
							/>
						) : (
							<div className={styles.missingMoreMenu} />
						)}
					</>
				)}
			</div>
			{extraChildren}
		</div>
	);
}
