export const shortDayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const longDayNames = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

export const dayName = (d, shortFormatNames = false) =>
	(shortFormatNames ? shortDayNames : longDayNames)[d.getDay()];

export const monthName = (d, shortFormatNames = false) =>
	(shortFormatNames
		? [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec',
		  ]
		: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
		  ])[d.getMonth()];

export default function formatTimestamp(
	timestamp,
	{
		shortFormatNames = true,
		dontShortenDate = true,
		alwaysIncludeYear = false,
		dateOnly = false,
		timeOnly = false,
		dot = '·',
		returnObject = false,
		includeSeconds = false,
		format24hr = false,
		// This is the default when shortFormatNames is set, has no effect if shortFormatNames is false
		includeDayName = false,
	} = {},
) {
	const d = new Date(timestamp);
	if (!timestamp || Number.isNaN(d.getTime())) {
		return '';
	}

	const dist = Math.abs(new Date() - d);
	const lessThanOneWeek = dist < 1000 * 60 * 60 * 24 * 7;

	const day = dayName(d, shortFormatNames);
	const month = monthName(d, shortFormatNames);

	const hourNum = d.getHours();
	const minNum = d.getMinutes();
	const secNum = d.getSeconds();
	const seconds = `${secNum < 10 ? '0' : ''}${secNum}`;
	const minutes = `${minNum < 10 ? '0' : ''}${minNum}`;
	const hours = format24hr
		? hourNum
		: `${
				// eslint-disable-next-line no-nested-ternary
				hourNum === 0 ? 12 : hourNum <= 12 ? hourNum : hourNum - 12
		  }`;
	const amPm = hourNum < 12 ? 'AM' : 'PM';
	const date = `${month} ${d.getDate()}${
		!alwaysIncludeYear && d.getFullYear() === new Date().getFullYear()
			? ''
			: `, ${d.getFullYear()}`
	}`;
	const time = `${hours}:${minutes}${includeSeconds ? `:${seconds}` : ''}${
		format24hr ? '' : ` ${amPm}`
	}`;

	const dayDate = `${day}, ${date}`;

	if (returnObject) {
		return {
			invalidDate: Number.isNaN(d.getTime()),
			day,
			dayNum: d.getDate(),
			date,
			dayDate,
			month,
			year: d.getFullYear(),
			time,
			lessThanOneWeek,
			dateOnly,
			shortFormatNames,
			dot,
			hours,
			minutes,
			seconds,
			amPm,
			d,
		};
	}

	if (timeOnly) {
		return time;
	}

	if (lessThanOneWeek && !dontShortenDate) {
		return dateOnly ? day : `${day} ${dot} ${time}`;
	}

	if (dateOnly) {
		if (shortFormatNames) {
			if (includeDayName) {
				return dayDate;
			}

			return date;
		}
		return dayDate;
	}

	return `${dayDate} ${dot} ${time}`;
}

// Just for debugging
// window.formatTimestamp = formatTimestamp;
