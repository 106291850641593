export const RideStatus = {
	// User has started a ride request but not finished filling in all details
	Draft: 'draft',

	// Used ONLY for Das drivers to allow them to preview before setting to quoted.
	DriverPreview: 'quote.driver_preview',

	// Line details entered and user has asked for quote - TBD transitioning
	// --
	// Update for DAS:
	// For DAS - now 'DriverPreview' is starting state, and after driver reviews the quote,
	// it goes to 'Quoted' to be sent to the user via SMS for accepting.
	// Then when rider accepts quote, it moves all the way to DriverAccepted
	Quoted: 'quote.quoted',

	// For DAS - 'QuoteAccepted' is set after driver reviews the quote (in 'Quoted' state)
	// and this wil send the DAS ride to the user (via SMS) for accepting.
	// Then when the rider accepts the quote, it goes to PaymentPending if no payment
	// method on file, or it goes to DriverAccepted (again, for DAS only) if payment method on file.
	// Quote accepted - some future state will start dispatching/fulfilling
	//---
	// Updated to the above:
	// For DAS - now 'DriverPreview' is starting state, and after driver reviews the quote,
	// it goes to 'Quoted' to be sent to the user via SMS for accepting.
	// Then when rider accepts quote, it moves all the way to DriverAccepted
	QuoteAccepted: 'quote.accepted',

	// New state introduced inorder to support Crypto payments.
	// Block dispatch by sitting in this state until crypto lands on the blockchain.
	// See coinbase-charge model and TripCryptoProcessor worker.
	// This state is auto-set by TripStateEngine when trying to transition into PendingDriverAcceptance
	// if the trip doesn't have a completed crypto charge.
	// As soon as the crypto charge clears, TripCryptoProcessor will kick off dispatch by
	// re-calling TripStateEngine to set PendingDriverAcceptance again.
	PaymentPending: 'quote.payment_pending',
	PaymentReceived: 'quote.paid',

	// While dispatching (finding a driver)...
	Dispatching: 'dispatch.dispatching',
	// Once dispatch is successful, it's "Ready" to fulfill (if scheduled),
	// or goes right to active state if on-demand
	DriverAccepted: 'dispatch.accepted',

	// Ride is in this transient "ride_pending" state when it's ~30 minutes (ish) before the ride,
	// this triggers some notifications. This also is used to mark waypoints after the active waypoint.
	RidePending: 'ride_pending',

	// Driver started trip and is heading to pickup
	Arriving: 'active.arriving',
	// Driver waiting for rider
	Arrived: 'active.arrived',
	// Rider is with the driver
	Riding: 'active.riding',
	// Trip stopped/paused/etc
	Stopped: 'active.stopped',

	// Happy path final state for fulfillment
	RideCompleted: 'end.ride_completed',

	// Error state - NOT cancellation - use a cancel state below if cancelling
	DispatchFailed: 'end.dispatch.fulfillment_error',

	// Driver canceled the trip - TBD: When would we ever let drivers cancel...?
	DriverCanceled: 'end.driver_canceled',

	// User canceled the trip
	UserCanceled: 'end.user_canceled',

	// User never showed for their trip (different than user choosing to cancel - this
	// means user did not communicate with Concierge staff asking to cancel or communicate with driver)
	UserNoShow: 'end.user_no_show',

	// Driver never showed for their trip (different than choosing to cancel - this
	// means user did not communicate with Concierge staff asking to cancel or communicate with rider)
	DriverNoShow: 'end.driver_no_show',

	// Quote sat for more than X hours with no user interaction
	QuoteExpired: 'end.quote_expired',

	// User said "no thanks" (etc) to quote
	QuoteCanceled: 'end.quote_canceled',
};

export const RideStatusOptions = [
	{
		id: RideStatus.Draft,
		label: 'Draft',
		description: 'Ride request started but not finished filling in all details',
	},

	{
		id: RideStatus.DriverPreview,
		label: 'Quote Review for Drivers',
		description:
			'Ride has been added by a driver for one of their personal riders but the driver has not yet finished reviewing the quote.',
	},

	{
		id: RideStatus.Quoted,
		label: 'Quoted',
		description:
			'Ride request is completed enough for a quote and quote has been provided to user',
	},
	{
		id: RideStatus.QuoteAccepted,
		label: 'Quote Accepted',
		description:
			'Rider has accepted the quote and is expecting the driver to show up at the proper time',
	},

	{
		id: RideStatus.PaymentPending,
		label: 'Payment Pending',
		description:
			'The ride requires pre-payment (e.x. crypto ride) and cannot be dispatched until payment received',
	},

	{
		id: RideStatus.PaymentReceived,
		label: 'Payment Received',
		description: 'Payment received for a pre-paid ride (e.g. crypto ride)',
	},

	{
		id: RideStatus.Dispatching,
		label: 'Dispatching',
		description:
			'Ride is waiting for a driver assignment and/or driver to accept the assignment',
	},
	{
		id: RideStatus.DriverAccepted,
		label: 'Driver Accepted',
		description: 'Driver has accepted the ride assignment and will do the ride',
	},

	{
		id: RideStatus.RidePending,
		label: 'Ride Pending',
		description: `It's time for the ride and the driver should start the trip soon to go pick up the rider`,
	},
	{
		id: RideStatus.Arriving,
		label: 'Active: Driver Arriving',
		description: `Driver is actively going to pick up the rider`,
	},
	{
		id: RideStatus.Arrived,
		label: 'Active: Arrived at Pickup',
		description: `Driver has arrived at the pickup location and is waiting for the rider`,
	},
	{
		id: RideStatus.Riding,
		label: 'Active: Riding',
		description: `Rider has gotten into the vehicle and is riding with the driver`,
	},
	{
		id: RideStatus.Stopped,
		label: 'Active: Ride Paused',
		description: `The driver/rider has paused the trip for a stop`,
	},

	{
		id: RideStatus.RideCompleted,
		label: 'Done: Ride Completed',
		description: `Driver has dropped off the rider`,
	},

	{
		id: RideStatus.DispatchFailed,
		label: 'Done: Dispatch Failed',
		description: `No drivers for this ride request`,
	},
	{
		id: RideStatus.DriverCanceled,
		label: 'Done: Driver Canceled',
		description: `Driver has canceled the trip`,
	},
	{
		id: RideStatus.UserCanceled,
		label: 'Done: Rider Canceled',
		description: `Rider has canceled the trip`,
	},
	{
		id: RideStatus.UserNoShow,
		label: 'Done: Rider No Show',
		description: `The rider never showed up for the pick up`,
	},
	{
		id: RideStatus.DriverNoShow,
		label: 'Done: Driver No Show',
		description: `Driver never showed up for the pickup`,
	},
	{
		id: RideStatus.QuoteExpired,
		label: 'Done: Quote Expired',
		description: `The rider has not accepted the quote in time`,
	},
	{
		id: RideStatus.QuoteCanceled,
		label: 'Done: Quote Canceled',
		description: `Rider decided to not accept the quote (said "no thanks")`,
	},
];

export const getStatusLabel = (status) =>
	(RideStatusOptions.find((x) => x.id === status) || {}).label;

export const RideStatusColors = {
	[RideStatus.Draft]: '#6b7280', // tw-gray-500
	[RideStatus.DriverPreview]: '#f97316', // tw-gray-500
	[RideStatus.Quoted]: '#eab308', // tw-yellow-500
	[RideStatus.QuoteAccepted]: '#6366f1', // tw-indigo-500

	[RideStatus.PaymentPending]: '#eab308', // tw-yellow-500
	[RideStatus.PaymentReceived]: '#86efac', // tw-emerald-300

	[RideStatus.Dispatching]: '#f97316', // tw-orange-500
	[RideStatus.DriverAccepted]: '#a855f7', // tw-purple-500

	[RideStatus.RidePending]: '#f97316', // tw-orange-500
	[RideStatus.Arriving]: '#22c55e', // tw-green-500
	[RideStatus.Arrived]: '#854d0e', // tw-yellow-800
	[RideStatus.Riding]: '#6366f1', // tw-indigo-500
	[RideStatus.Stopped]: '#eab308', // tw-yellow-500
	[RideStatus.RideCompleted]: '#14532d', // tw-green-900

	[RideStatus.DispatchFailed]: '#ef4444', // tw-red-500
	[RideStatus.DriverCanceled]: '#ef4444', // tw-red-500
	[RideStatus.UserCanceled]: '#ef4444', // tw-red-500
	[RideStatus.UserNoShow]: '#ef4444', // tw-red-500
	[RideStatus.DriverNoShow]: '#ef4444', // tw-red-500
	[RideStatus.QuoteExpired]: '#6b7280', // tw-gray-500
	[RideStatus.QuoteCanceled]: '#6b7280', // tw-gray-500
	_unknown: '#6b7280', // tw-gray-500
};

export const rideStatusToColor = (status) => {
	const color = RideStatusColors[status];
	return color || RideStatusColors._unknown;
};

export const RideStatusEmojis = {
	[RideStatus.Draft]: '⬜️',
	[RideStatus.DriverPreview]: '🟧',
	[RideStatus.Quoted]: '🟨',
	[RideStatus.QuoteAccepted]: '🟦',
	[RideStatus.PaymentPending]: '🟨',
	[RideStatus.PaymentReceived]: '🟩',

	[RideStatus.Dispatching]: '🟧',
	[RideStatus.DriverAccepted]: '🟪',

	[RideStatus.RidePending]: '🟧',
	[RideStatus.Arriving]: '🟨',
	[RideStatus.Arrived]: '🟫',
	[RideStatus.Riding]: '🟦',
	[RideStatus.Stopped]: '🟨',
	[RideStatus.RideCompleted]: '🟩',

	[RideStatus.DispatchFailed]: '🟥',
	[RideStatus.DriverCanceled]: '🟥',
	[RideStatus.UserCanceled]: '🟥',
	[RideStatus.UserNoShow]: '🟥',
	[RideStatus.DriverNoShow]: '🟥',
	[RideStatus.QuoteExpired]: '⬜️',
	[RideStatus.QuoteCanceled]: '⬜️',
	_unknown: '⬜️',

	// 🟥🟧🟨🟩🟦🟪⬛️🟫⬜️
};

export const rideStatusToEmoji = (status) => {
	const emoji = RideStatusEmojis[status];
	return emoji || RideStatusEmojis._unknown;
};

export const dumpStatusToString = (status) => {
	return `${rideStatusToEmoji(status)} ${getStatusLabel(status)}`;
};

export const RideStatusTransitions = {
	[RideStatus.Draft]: [
		RideStatus.Quoted,
		RideStatus.Dispatching,
		RideStatus.UserCanceled,
		RideStatus.QuoteCanceled,
		RideStatus.QuoteExpired,
		RideStatus.DriverPreview,
	],
	[RideStatus.DriverPreview]: [
		RideStatus.Quoted,
		RideStatus.QuoteAccepted,
		RideStatus.DriverAccepted,
		RideStatus.Dispatching,
		RideStatus.UserCanceled,
		RideStatus.QuoteCanceled,
		RideStatus.QuoteExpired,
		RideStatus.Draft,
	],
	[RideStatus.Quoted]: [
		RideStatus.DriverPreview,
		RideStatus.Dispatching,
		RideStatus.DriverAccepted,
		RideStatus.Draft,
		RideStatus.UserCanceled,
		RideStatus.QuoteCanceled,
		RideStatus.QuoteExpired,
		RideStatus.QuoteAccepted,
	],
	[RideStatus.QuoteAccepted]: [
		RideStatus.Dispatching,
		RideStatus.Draft,
		RideStatus.UserCanceled,
		RideStatus.QuoteCanceled,
		RideStatus.QuoteExpired,
		RideStatus.DriverAccepted,
		RideStatus.Arriving,
	],
	[RideStatus.Dispatching]: [
		RideStatus.DriverAccepted,
		RideStatus.UserCanceled,
		RideStatus.DispatchFailed,
		RideStatus.Draft,
	],
	[RideStatus.DriverAccepted]: [
		RideStatus.DriverPreview,
		RideStatus.RidePending,
		RideStatus.Arriving,
		RideStatus.UserCanceled,
		RideStatus.DriverCanceled, // TBD if we allow this?
		RideStatus.Draft,
		RideStatus.UserNoShow,
		RideStatus.DriverNoShow,
	],
	[RideStatus.RidePending]: [
		RideStatus.Arriving,
		RideStatus.Arrived,
		RideStatus.Riding,
		RideStatus.UserCanceled,
		RideStatus.DriverCanceled, // TBD if we allow this?
		RideStatus.UserNoShow,
	],
	[RideStatus.Arriving]: [
		RideStatus.Arrived,
		RideStatus.Riding,
		RideStatus.UserCanceled,
		RideStatus.DriverCanceled, // TBD if we allow this?
		RideStatus.UserNoShow,
	],
	[RideStatus.Arrived]: [
		RideStatus.Riding,
		RideStatus.UserCanceled,
		RideStatus.UserNoShow,
		RideStatus.DriverCanceled, // TBD if we allow this?
	],
	[RideStatus.Riding]: [RideStatus.Stopped, RideStatus.RideCompleted],
	[RideStatus.Stopped]: [RideStatus.Riding, RideStatus.RideCompleted],
	[RideStatus.RideCompleted]: [RideStatus.Draft],
	[RideStatus.DispatchFailed]: [RideStatus.Draft],
	[RideStatus.DriverCanceled]: [RideStatus.Draft],
	[RideStatus.UserCanceled]: [RideStatus.Draft],
	[RideStatus.UserNoShow]: [RideStatus.Draft],
	[RideStatus.DriverNoShow]: [RideStatus.Draft],
	[RideStatus.QuoteExpired]: [RideStatus.Draft],
	[RideStatus.QuoteCanceled]: [RideStatus.Draft],
};

export const STARTED_STATES = [
	RideStatus.RidePending,
	RideStatus.Arriving,
	RideStatus.Arrived,
	RideStatus.Riding,
	RideStatus.Stopped,
];

export const RIDING_STATES = [RideStatus.Riding, RideStatus.Stopped];

export const RIDING_OR_ARRIVED_STATES = [...RIDING_STATES, RideStatus.Arrived];

export const COMPLETED_STATES = [
	RideStatus.RideCompleted,
	RideStatus.DispatchFailed,
	RideStatus.DriverCanceled,
	RideStatus.UserCanceled,
	RideStatus.UserNoShow,
	RideStatus.DriverNoShow,
	RideStatus.QuoteExpired,
	RideStatus.QuoteCanceled,
];

export const QUOTE_DRAFT_STATES = [
	RideStatus.Draft,
	RideStatus.DriverPreview,
	RideStatus.Quoted,
];

export const DRIVER_EMPTY_STATES = [
	RideStatus.DriverCanceled,
	RideStatus.UserCanceled,
	RideStatus.QuoteCanceled,
	RideStatus.QuoteExpired,
	RideStatus.UserNoShow,
	RideStatus.DriverNoShow,
];

export const isValidRideStatus = (status) =>
	Object.values(RideStatus).includes(status);

export const isValidRideStatusTransition = (fromStatus, toStatus) =>
	RideStatusTransitions[fromStatus] &&
	RideStatusTransitions[fromStatus].includes(toStatus);

export const isDriverEmptyStatus = (status) =>
	!status || DRIVER_EMPTY_STATES.includes(status);

export const isStartedStatus = (status) => STARTED_STATES.includes(status);

export const isRidingStatus = (status) => RIDING_STATES.includes(status);

export const isRidingOrArrivedStatus = (status) =>
	RIDING_OR_ARRIVED_STATES.includes(status);

export const isPostedStatus = (status) =>
	status && !QUOTE_DRAFT_STATES.includes(status);

export const isQuotedDraft = (status) => QUOTE_DRAFT_STATES.includes(status);

export const isCompletedStatus = (status) => COMPLETED_STATES.includes(status);

export const RIDES_TIME_CLOCK_LOG_TYPE = 'rides';

export const RideDriverStatus = {
	Draft: 'draft',
	Lead: 'lead',
	InterviewResponseNeeded: 'interviewing.pending_response',
	Interviewing: 'interviewing',
	Active: 'active',
	Canceled: 'canceled',
};

export const RideDriverStatusOptions = [
	{
		id: RideDriverStatus.Draft,
		label: 'Draft',
		description:
			'Not officially applied, simply a name in our system at this point',
	},
	{
		id: RideDriverStatus.Lead,
		label: 'Lead',
		description:
			'Applied or referred as a driver, but not yet invited to interview',
	},
	{
		id: RideDriverStatus.InterviewResponseNeeded,
		label: 'Interview - Response Needed',
		description:
			'In the interview process, but we are waiting on a response from the driver',
	},
	{
		id: RideDriverStatus.Interviewing,
		label: 'Interviewing',
		description: 'Interviewing in-process',
	},
	{
		id: RideDriverStatus.Active,
		label: 'Active',
		description:
			'This driver has passed all checks and interviews and has an account in good standing, permitted to drive members',
	},
	{
		id: RideDriverStatus.Canceled,
		label: 'Canceled',
		description: 'This driver has been removed from service',
	},
];

export const RideDriverStatusColors = {
	[RideDriverStatus.Draft]: '#6b7280', // tw-gray-500
	[RideDriverStatus.Lead]: '#6366f1', // tw-indigo-500
	[RideDriverStatus.Interviewing]: '#a855f7', // tw-purple-500
	[RideDriverStatus.InterviewResponseNeeded]: '#eab308', // tw-yellow-500
	[RideDriverStatus.Active]: '#22c55e', // tw-green-500
	[RideDriverStatus.Canceled]: '#ef4444', // tw-red-500

	_unknown: '#6b7280', // tw-gray-500
};

export const getDriverStatusLabel = (status) =>
	(RideDriverStatusOptions.find((x) => x.id === status) || {}).label;

// Does not include Draft or Void because those are "dead" statuses - not relevant
export const RideDriverStatusCategory = {
	Active: [RideDriverStatus.Active],
	Interviewing: [
		RideDriverStatus.Interviewing,
		RideDriverStatus.InterviewResponseNeeded,
	],
	Leads: [RideDriverStatus.Draft, RideDriverStatus.Lead],
	Done: [RideDriverStatus.Canceled],
	Any: [
		RideDriverStatus.Draft,
		RideDriverStatus.Lead,
		RideDriverStatus.Interviewing,
		RideDriverStatus.InterviewResponseNeeded,
		RideDriverStatus.Active,
		RideDriverStatus.Canceled,
	],
};

// Originally outlined in https://docs.google.com/spreadsheets/d/1INCc1oZ2FMCGI2BD7PNlPHbNBRONQw4gQ-1F1gKTzJA/edit#gid=0
export const RideDriverLevelOptions = [
	{
		id: 1,
		label: 'Level 1 - Green',
		color: '#22c55e',
		twColor: 'tw-green-500',
		description: 'Welcome to the team',
		requirements: {
			trips: 0,
			miles: 0,
			quality: 0,
		},
		rewards: {
			mile: 2,
			minute: 0.5,
			unlock: 'Welcome to the team!',
		},
	},
	{
		id: 2,
		label: 'Level 2 - Blue',
		color: '#0ea5e9',
		twColor: 'tw-sky-500',
		description: '15+ trips / 150+ miles / 1+ Quality',
		requirements: {
			trips: 15,
			miles: 150,
			quality: 1,
		},
		rewards: {
			mile: 2.2,
			minute: 0.6,
			unlock: 'Personal business cards',
		},
	},
	{
		id: 3,
		label: 'Level 3 - Yellow',
		color: '#eab308',
		twColor: 'tw-yellow-500',
		description: '30+ trips / 300+ miles / 2+ Quality',
		requirements: {
			trips: 30,
			miles: 300,
			quality: 2,
		},
		rewards: {
			mile: 2.5,
			minute: 0.7,
			unlock: 'Set-your-own-rate',
		},
	},
	{
		id: 4,
		label: 'Level 4 - Orange',
		color: '#f97316',
		twColor: 'tw-orange-500',
		description: '75+ trips / 750+ miles / 3+ Quality',
		requirements: {
			trips: 75,
			miles: 750,
			quality: 3,
		},
		rewards: {
			mile: 2.7,
			minute: 0.9,
			unlock: 'Inter-city assignments',
		},
	},
	{
		id: 5,
		label: 'Level 5 - Red',
		color: '#ef4444',
		twColor: 'tw-red-500',
		description: '150+ trips / 1,500+ miles / 4+ Quality',
		requirements: {
			trips: 150,
			miles: 1500,
			quality: 4,
		},
		rewards: {
			mile: 3,
			minute: 1,
			unlock: 'Personalized/branded webpage',
		},
	},
];

// Given level 1-5, return meta from list
export const getDriverLevelMeta = (level) => RideDriverLevelOptions[level - 1];

export const RideMemberStatus = {
	Draft: 'draft',
	Lead: 'lead',
	Interviewing: 'interviewing',
	BillingSetupRequired: 'billing_setup_required',
	Active: 'active',
	Canceled: 'canceled',
};

export const RideMemberStatusOptions = [
	{
		id: RideMemberStatus.Draft,
		label: 'Draft',
		description:
			'Not officially applied, simply a name in our system at this point',
	},
	{
		id: RideMemberStatus.Lead,
		label: 'Lead',
		description:
			'Applied or referred as a member, but not yet invited to interview',
	},
	{
		id: RideMemberStatus.Interviewing,
		label: 'Interviewing',
		description: 'Interviewing in-process',
	},
	{
		id: RideMemberStatus.BillingSetupRequired,
		label: 'Billing Setup Required',
		description:
			'In the interview process, but we are waiting on a response from the driver',
	},
	{
		id: RideMemberStatus.Active,
		label: 'Active',
		description:
			'This member has passed all checks and interviews and has an account in good standing, permitted to take rides',
	},
	{
		id: RideMemberStatus.Canceled,
		label: 'Canceled',
		description: 'This member has been removed from service',
	},
];

export const RideMemberStatusColors = {
	[RideMemberStatus.Draft]: '#6b7280', // tw-gray-500
	[RideMemberStatus.Lead]: '#6366f1', // tw-indigo-500
	[RideMemberStatus.Interviewing]: '#a855f7', // tw-purple-500
	[RideMemberStatus.BillingSetupRequired]: '#eab308', // tw-yellow-500
	[RideMemberStatus.Active]: '#22c55e', // tw-green-500
	[RideMemberStatus.Canceled]: '#ef4444', // tw-red-500

	_unknown: '#6b7280', // tw-gray-500
};

export const getMemberStatusLabel = (status) =>
	(RideMemberStatusOptions.find((x) => x.id === status) || {}).label;

// Does not include Draft or Void because those are "dead" statuses - not relevant
export const RideMemberStatusCategory = {
	Active: [RideMemberStatus.Active],
	Interviewing: [
		RideMemberStatus.Interviewing,
		RideMemberStatus.BillingSetupRequired,
	],
	Leads: [RideMemberStatus.Draft, RideMemberStatus.Lead],
	Done: [RideMemberStatus.Canceled],
	Any: [
		RideMemberStatus.Draft,
		RideMemberStatus.Lead,
		RideMemberStatus.Interviewing,
		RideMemberStatus.BillingSetupRequired,
		RideMemberStatus.Active,
		RideMemberStatus.Canceled,
	],
};

// Account for approx waiting time
export const ESTIMATED_WAITING_TIME_INCLUDED = 10;
