/* eslint-disable no-console */
import { useCallback } from 'react';
import { ServerStore } from 'shared/services/ServerStore';
import { useLoadingSpinner } from 'shared/components/LoadingSpinnerProvider';
import { toast } from 'react-toastify';
import FunctionalState from '../components/FunctionalState';

const NETWORK_ERROR_TOAST_ID = 'network.error';

export const WaitingForDataCounter = new FunctionalState(0);

export function clearNetworkErrorToasts() {
	toast.dismiss(NETWORK_ERROR_TOAST_ID);
}

export function useConditionallyHandleErrors({
	undoHandler,
	slowLoadDelay = 500,
	showLoadingSpinnerWhenSlow = true,
	// Combining 'disableToast' with a createErrorReturn function
	// can allow you to handle errors your own way
	createErrorReturn = (/* errorString, { errors = [], response = {} } */) =>
		false,
	disableToast = false,
	throwFreshErrors = false,
} = {}) {
	const { showDelayedLoadingSpinner, clearLoadingSpinnerTimer } =
		useLoadingSpinner();

	return useCallback(
		async (responseOrPromise, undoCallbackOrData) => {
			// Only show spinner if requests take longer than `slowLoadDelay`ms
			if (
				showLoadingSpinnerWhenSlow &&
				typeof showDelayedLoadingSpinner === 'function'
			) {
				showDelayedLoadingSpinner(slowLoadDelay || 500);
			}

			WaitingForDataCounter.setValue(WaitingForDataCounter.getValue() + 1);

			let response;
			try {
				// Only attach a listener to .catch if it exists on the value
				if (typeof responseOrPromise?.catch === 'function') {
					responseOrPromise?.catch((ex) => {
						response = ex;
					});
				}
				// Wait for the promise to actually resolve
				response = await responseOrPromise;
			} catch (ex) {
				response = ex;
			}

			WaitingForDataCounter.setValue(WaitingForDataCounter.getValue() - 1);

			// Now that we have response, cancel the spinner and clear it out if visible
			if (
				showLoadingSpinnerWhenSlow &&
				typeof clearLoadingSpinnerTimer === 'function'
			) {
				clearLoadingSpinnerTimer();
			}

			// Handle errors
			if (response instanceof Error) {
				const errors = ServerStore.extractApiErrors(response);
				const { context, headers } = errors || {};

				// const errorPath = response?.response?.config?.url || 'error';

				// eslint-disable-next-line no-console
				console.error(`Error from server`, errors);

				const errorString = errors.join(', ');

				// Handle network errors differently than other errors
				const isNetworkError = response instanceof ServerStore.NetworkError;

				if (!isNetworkError) {
					console.error(`Full error text: ${errorString}`);
				}

				const humanError = isNetworkError
					? "It looks like you can't connect to Vaya, are you online?"
					: errorString;

				if (!disableToast) {
					toast.error(humanError, {
						// For network errors, we want to clear on next valid response
						// (which likely indicates network is restored) so use special toastId for clearing later
						toastId: isNetworkError ? NETWORK_ERROR_TOAST_ID : errorString,
						autoClose: 10 * 1000,
					});
				}

				// Undo the previous change since we have an error
				if (typeof undoHandler === 'function') {
					await undoHandler(undoCallbackOrData);
				} else if (typeof undoCallbackOrData === 'function') {
					await undoCallbackOrData();
				}

				if (throwFreshErrors) {
					response.message = humanError;
					throw response;
				}

				return createErrorReturn
					? createErrorReturn(
							isNetworkError
								? "It looks like you can't connect to Vaya, are you online?"
								: errorString,
							{ errors, response, context, headers },
					  )
					: false;
			}

			// Valid response (no errors) so clear any network-error toasts
			// toast.dismiss(NETWORK_ERROR_TOAST_ID);
			clearNetworkErrorToasts();

			return response;
		},
		[
			throwFreshErrors,
			createErrorReturn,
			disableToast,
			clearLoadingSpinnerTimer,
			showDelayedLoadingSpinner,
			showLoadingSpinnerWhenSlow,
			slowLoadDelay,
			undoHandler,
		],
	);
}
