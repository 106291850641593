import TripStatus, {
	isCompletedStatus,
	isPostedStatus,
	isStartedStatus,
	isRidingStatus,
} from 'shared/utils/TripStatus';
import { useMemo } from 'react';
import { toTitleCase } from 'shared/utils/toTitleCase';

export function getStatusSummaryFlags(status) {
	return {
		isUpcoming:
			isPostedStatus(status) &&
			!isStartedStatus(status) &&
			!isCompletedStatus(status),
		isStarted: isStartedStatus(status), // includes riding up to completed
		isRiding: isRidingStatus(status), // only riding
		isCompleted: isCompletedStatus(status),
		isCancelation: status === TripStatus.UserCanceled,
		isNoShow: status === TripStatus.NoShow,
	};
}

export function useTripInfoCollator(trip) {
	const {
		status,

		isScheduled,
		scheduledPickupAt,

		estimatedPickupAt,
		pickedupAt,

		estimatedDropoffAt,
		dropoffAt,
	} = trip || {};

	const {
		isUpcoming,
		isStarted,
		isRiding,
		isCompleted,
		isCancelation,
		isNoShow,
		statusTitle,
	} = useMemo(
		() => ({
			...getStatusSummaryFlags(status),
			statusTitle: toTitleCase(`${status}`.replace(/_/g, ' ')),
		}),
		[status],
	);

	const {
		pickupTimeDisplay,
		pickupTimeDisplayIsEstimated,
		dropoffTimeDisplay,
		dropoffTimeDisplayIsEstimated,
	} = useMemo(
		() => ({
			pickupTimeDisplayIsEstimated: !(isCompleted || isRiding),
			// eslint-disable-next-line no-nested-ternary
			pickupTimeDisplay: !(isCompleted || isRiding)
				? isScheduled
					? scheduledPickupAt
					: estimatedPickupAt
				: pickedupAt,
			dropoffTimeDisplayIsEstimated: !isCompleted,
			dropoffTimeDisplay: !isCompleted ? estimatedDropoffAt : dropoffAt,
		}),
		[
			dropoffAt,
			estimatedDropoffAt,
			estimatedPickupAt,
			isCompleted,
			isRiding,
			isScheduled,
			pickedupAt,
			scheduledPickupAt,
		],
	);

	return {
		statusTitle,

		isUpcoming,
		isStarted,
		isRiding,
		isCompleted,
		isCancelation,
		isNoShow,

		pickupTimeDisplay,
		pickupTimeDisplayIsEstimated,
		dropoffTimeDisplay,
		dropoffTimeDisplayIsEstimated,
	};
}
