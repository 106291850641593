// h/t https://stackoverflow.com/a/43376967
// import Logger from './Logger';

export const toTitleCase = (phrase) => {
	if (!phrase) {
		return phrase;
	}
	return `${phrase}`
		.replace(/([a-z])([A-Z])/g, '$1 $2')
		.toLowerCase()
		.replace(/[-_]/g, ' ')
		.split(/[.\s]+/)
		.map((x) => `${x}`.trim())
		.filter(Boolean)
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ')
		.trim();
};

// async function main() {
// 	const sample =
// 		'haveYouorTheFamilyMemberIdentifiedAboveWhoWasExposedToWaterAtCampLejeuneAnd/OrMCASNewRiverBeenDiagnosedWithAnyOfTheFollowingDiseasesAnd/OrConditions';
// 	const result = toTitleCase(sample);
// 	Logger.debug(`\n < Input: ${sample}\n > Output: ${result}`);
// }

// if (process.argv[1] === __filename) {
// 	main()
// 		.then(() => process.exit())
// 		.catch((E) => Logger.error(E));
// }
