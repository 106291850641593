import { customAlphabet } from 'nanoid';

// Per https://zelark.github.io/nano-id-cc/
// At 25 character-long IDs, ~128 million years needed, in order to have a 1% probability of at least one collision (generating 1,000 IDs/second)
const alphabet = '0123456789abcdefghijklmnopqrstuvwxyz';

export const customNanoidFactory = (alpha, length) =>
	customAlphabet(alpha, length);

export const customNanoid = (length) => customAlphabet(alphabet, length);

const nanoid = customNanoid(25);

export default nanoid;
