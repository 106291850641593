const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;
const radiusConversion = 69.0;

// Used for adding spacing to bounding boxes around points, etc
export function distToLatLng(miles, lat) {
	// Latitude-to-miles ratio is consistent, whereas
	// longitude-to-miles depends on the latitude of the point
	const latDelta = miles / radiusConversion;
	const lngDelta = miles / (radiusConversion * Math.cos(degreesToRadians(lat)));

	return [latDelta, lngDelta];
}
