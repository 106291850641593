import { forwardRef } from 'react';
// import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
// import styles from './AppButton.module.scss';

// Using forwardRef so we can use AppButton inside things like Tooltip wrappers where it needs a ref
const AppButton = forwardRef(
	({ className, children, disabled, isSecondaryCta, ...props }, ref) => {
		return (
			<Button
				{...props}
				ref={ref}
				disabled={disabled}
				className={clsx(
					// styles.root,
					// isSecondaryCta && styles.secondaryCta,
					// disabled && styles.disabled,
					className,
					'AppButton-root',
				)}
			>
				{children}
			</Button>
		);
	},
);

export default AppButton;
