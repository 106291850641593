/* eslint-disable no-unused-vars */
// import { phone as validateAndNormalize } from 'phone';
import Logger from './Logger';
import formatPhone from './formatPhone';

export default function normalizePhone(phone) {
	if (!phone) {
		return '';
	}

	if (typeof phone === 'object') {
		if (phone.phone) {
			// eslint-disable-next-line no-param-reassign
			phone.phone = normalizePhone(phone.phone);
		}

		return phone;
	}

	let digitsOnly = `${phone}`.startsWith('+1') ? `${phone}`.substr(2) : phone;
	digitsOnly = `${digitsOnly}`.replace(/[^\d]/g, '');

	const digitOne = digitsOnly.length === 10 ? '1' : '';

	return `+${digitOne}${digitsOnly}`;
	// const { phoneNumber } = validateAndNormalize(phone, {
	// 	country: 'USA',
	// 	validateMobilePrefix: false,
	// 	// strictDetection: false,
	// });
	// return phoneNumber || '';
}

async function main() {
	// const input = '+502 5633 8067';
	// const norm = normalizePhone(input);
	// const formatted = formatPhone(norm);
	// Logger.debug({ input, norm, formatted });

	const tests = [
		'123456789',
		'012345678',
		'2345678901',
		'+1294823993',
		'765 215 0511',
		'123',
		'123456',
		'1234567890',
		'Josiah Bryan',
	];

	tests.forEach((input) => {
		const value = input;
		const norm = normalizePhone(`${value}`.replace(/^[01]/g, ''));
		const formatted = formatPhone(norm);
		Logger.debug([input, norm, formatted]);
	});
}

if (global.process?.argv[1] === __filename) {
	main()
		.then(() => process.exit())
		.catch((E) => Logger.error(E));
}
