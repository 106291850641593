/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import clsx from 'clsx';
import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import {
	getRubberIcon,
	PATH_MAP_PIN,
	MAP_PATH_INACTIVE_COLOR,
	trackingIconProps,
} from 'shared/utils/mapIconGenerators';
import styles from 'shared/services/GeoService.module.scss';
import { legsToPlaces } from 'shared/utils/tripLegPlaceHelpers';
import normalizePlaceName from 'shared/utils/normalizePlaceName';
import polyline from '@mapbox/polyline';
import { lerp } from 'shared/utils/lerp';
import { toLatLngRef } from 'shared/services/GeoService';
import { useGoogleMaps } from 'shared/hooks/useGoogleLoaded';
import { distToLatLng } from 'shared/utils/distToLatLng';

export const MARKER_COLORS = {
	origin: '#00826B',
	waypoint: '#E1954E',
	destination: '#D868BD',
};

const WAYPOINT_GRADIENT = [
	// Generated using https://mycolor.space/gradient3?ori=to+right+top&hex=%2300826B&hex2=%23E1954E&hex3=%23D868BD&submit=submit
	// from the origin/waypoint/destination colors
	// '#3c8b5d',
	// '#62924e',
	'#889642',
	'#ae983e',
	'#c79241',
	'#de8a4d',
	'#f18160',
	'#f77474',
	// '#f56a8b',
	// '#eb66a4',
];

export const pointFocusHelper = (
	unifiedBounds,
	{ lat, lng },
	{ focusSizeMiles = 0.125 } = {},
) => {
	const [latGridSize, lngGridSize] = distToLatLng(focusSizeMiles, lat);

	// console.warn(
	// 	`pointFocusHelper:`,
	// 	{ lat, lng },
	// 	{
	// 		latGridSize,
	// 		lngGridSize,
	// 	},
	// );
	unifiedBounds.extend(toLatLngRef({ lat, lng }));

	// These two .extend() calls basically add `gridSize` miles to each side
	// of the `location`. At time of writing, that means we have 0.25 miles
	// add to each side of the point, resulting in a half-mile-wide/tall
	// bounding rectangle
	unifiedBounds.extend(
		toLatLngRef({
			lat: lat - latGridSize,
			lng: lng - lngGridSize,
		}),
	);
	unifiedBounds.extend(
		toLatLngRef({
			lat: lat + latGridSize,
			lng: lng + lngGridSize,
		}),
	);
};

const lerpColors = (hex1, hex2, amount = 0.5) => {
	const alpha = [
		parseInt(hex1.substring(1, 3), 16),
		parseInt(hex1.substring(3, 5), 16),
		parseInt(hex1.substring(5, 8), 16),
	];

	const beta = [
		parseInt(hex2.substring(1, 3), 16),
		parseInt(hex2.substring(3, 5), 16),
		parseInt(hex2.substring(5, 8), 16),
	];

	const mixed = alpha.map((a, idx) => Math.round(lerp(a, beta[idx], amount)));

	// console.warn(`lerpColors`, { alpha, mixed, beta, amount });

	return `#${mixed.map((x) => x.toString(16)).join('')}`;
};

export const sampleWaypointGradient = (idx, total) => {
	const percent = Math.min(1, Math.max(0, idx / (total || 1)));

	const position = lerp(0, WAYPOINT_GRADIENT.length - 1, percent);
	const colorA = WAYPOINT_GRADIENT[Math.floor(position)];
	const colorB = WAYPOINT_GRADIENT[Math.ceil(position)];

	// If the lerp in the gradient falls between two explicit
	// gradient stops (above), lerp the two colors above and below
	// the position into a single color based on how far along
	// between the two spots that the lerped position falls.
	// Of course, if the stops are identical, don't waste time lerping,
	// just use the first color.
	const color =
		Math.ceil(position) === position
			? colorA
			: lerpColors(colorA, colorB, Math.ceil(position) - position);

	// console.log(`sampleWaypointGradient`, {
	// 	idx,
	// 	total,
	// 	percent,
	// 	WAYPOINT_GRADIENT,
	// 	position,
	// 	pFloor: Math.floor(position),
	// 	pCeil: Math.ceil(position),
	// 	colorA,
	// 	colorB,
	// 	color,
	// });

	return color;
};

const placeToMarker = (
	{ legId, legIndex, id: placeId, name, addressText, lat, lng },
	markerType = 'waypoint',
	markerColor = undefined,
) => ({
	id: placeId,
	markerType,
	position: { lat, lng },
	icon: getRubberIcon({
		path: PATH_MAP_PIN,
		scale: 0.5,
		strokeWeight: 2,
		fillColor: markerColor || MARKER_COLORS[markerType],
		label:
			!name && !addressText
				? ''
				: {
						text: normalizePlaceName(name, addressText),
						color: '#ffffff',
						fontWeight: 'bold',
						className: clsx(styles.mapLabels, 'TripPath-label'),
						fontSize: '.5rem',
				  },
	}),
});

export const markerTypeFromIndex = (index, array) => {
	if (index === 0) {
		return 'origin';
	}

	if (index === array.length - 1) {
		return 'destination';
	}

	return 'waypoint';
};

export function useTripPath(
	currentTrip = {},
	{
		focusLegId = undefined,
		hideFocusedLeg = undefined,
		focusOnLegStart = false,
		focusSizeMiles = 0.25,
		editingWhileActive = false,
	} = {},
) {
	const { id: tripId, tripLegs = [], dropoffTbd } = currentTrip || {};

	const maps = useGoogleMaps();

	// // TODO: Refactor this to not useSWR assuming our polyline server-side encoding
	// // stands up to use, because none of the polyline stuff is async.
	// // We should be able to just useMemo to generate our path/bounds instead of pulling in useSWR
	// const { data: { paths, bounds, error: directionsError } = {} } =
	// 	useSWR(
	// 		// [tripId, pickupPlace, dropoffPlace, tripLegs, dropoffTbd],
	// 		// async (id, place1, place2, legs, isDropoffTbd) => {
	// 		[
	// 			tripId,
	// 			dropoffTbd,
	// 			// This generates a flat key to better use for
	// 			// change detection
	// 			...(tripLegs
	// 				? tripLegs.map((x) => x.id + x.updatedAt)
	// 				: []),
	// 			maps,
	// 		],
	// 		async (id, tbd) => {
	// 			if (!id) {
	// 				// console.log(`No tripId yet, can't get directions`, {
	// 				// 	id,
	// 				// 	// place1,
	// 				// 	// place2,
	// 				// 	tripLegs,
	// 				// });
	// 				return undefined;
	// 			}
	// 			if (tbd) {
	// 				return undefined;
	// 			}

	// 			if (!maps) {
	// 				console.warn(
	// 					`usetripPath: Waiting for google maps to load ...`,
	// 				);
	// 				return undefined;
	// 			}

	// 			let unifiedBounds = maps && new maps.LatLngBounds();

	// 			// let unifiedBounds = null;

	// 			const legPaths = await promiseMap(
	// 				tripLegs.filter((x) => !x.isLayover),
	// 				async ({
	// 					id: legId,
	// 					legIndex,
	// 					startPlace,
	// 					stopPlace,
	// 					estimatedPolyline,
	// 					actualPolyline,
	// 				}) => {
	// 					if (!estimatedPolyline && !actualPolyline) {
	// 						console.warn(
	// 							`No polyline stored for leg ${legId} # ${legIndex}, can't create a path`,
	// 						);
	// 						return undefined;
	// 					}

	// 					const points = polyline.decode(
	// 						actualPolyline || estimatedPolyline,
	// 						5,
	// 					);

	// 					const pathGenerator =
	// 						maps &&
	// 						new maps.Polyline({
	// 							path: [],
	// 						});

	// 					points.forEach(([lat, lng]) => {
	// 						const point = toLatLngRef({ lat, lng });
	// 						pathGenerator.getPath().push(point);
	// 						unifiedBounds.extend(point);
	// 					});

	// 					return {
	// 						legId,
	// 						path: pathGenerator.getPath(),
	// 					};

	// 					// // console.log(`Getting directions:`, {
	// 					// // 	legId,
	// 					// // 	legIndex,
	// 					// // 	startPlace,
	// 					// // 	stopPlace,
	// 					// // });
	// 					// const directions =
	// 					// 	await GeoService.getCachedDirections(
	// 					// 		startPlace,
	// 					// 		stopPlace,
	// 					// 	);

	// 					// if (!directions) {
	// 					// 	console.warn(`No directions`);
	// 					// 	return { error: 'No Directions' };
	// 					// }

	// 					// const {
	// 					// 	path,
	// 					// 	bounds: pathBounds,
	// 					// 	error,
	// 					// } = await GeoService.directionsToPath(directions);

	// 					// if (!unifiedBounds) {
	// 					// 	unifiedBounds = pathBounds;
	// 					// } else {
	// 					// 	// Expand our base bounds to include the other bounds
	// 					// 	unifiedBounds =
	// 					// 		unifiedBounds.union(pathBounds);
	// 					// }

	// 					// if (error) {
	// 					// 	console.error(
	// 					// 		`Error generating directions for trip leg ${legId}`,
	// 					// 		{
	// 					// 			tripId: id,
	// 					// 			legId,
	// 					// 			error,
	// 					// 			startPlace,
	// 					// 			stopPlace,
	// 					// 		},
	// 					// 	);
	// 					// }

	// 					// return {
	// 					// 	legId,
	// 					// 	path,
	// 					// 	error,
	// 					// };
	// 				},
	// 			).catch((ex) => {
	// 				console.error(`Error creating path for trip`, {
	// 					message: ex.message,
	// 					stack: ex.stack,
	// 					tripLegs: tripLegs.filter((x) => !x.isLayover),
	// 				});
	// 			});

	// 			return {
	// 				paths: legPaths.filter((x) => x),
	// 				bounds: unifiedBounds,
	// 			};

	// 			// console.log(`Getting directions:`, {
	// 			// 	id,
	// 			// 	place1,
	// 			// 	place2,
	// 			// 	legs,
	// 			// });
	// 			// const directions = await GeoService.getCachedDirections(
	// 			// 	place1,
	// 			// 	place2,
	// 			// );
	// 			// if (!directions) {
	// 			// 	console.warn(`No directions`);
	// 			// 	return { error: 'No Directions' };
	// 			// }
	// 			// return GeoService.directionsToPath(directions);
	// 		},
	// 	);

	// We should be able to just useMemo to generate our path/bounds instead of pulling in useSWR
	const {
		paths,
		bounds,
		error: directionsError,
	} = useMemo(
		() => {
			// if (!tripId) {
			// 	console.log(`No tripId yet, can't get directions`, {
			// 		tripId,
			// 		// place1,
			// 		// place2,
			// 		tripLegs,
			// 	});
			// 	return undefined;
			// }
			if (dropoffTbd) {
				return undefined;
			}

			if (!maps) {
				// console.log(`useTripPath: Waiting for google maps to load ...`);
				return undefined;
			}

			if (!tripId) {
				return undefined;
			}

			let unifiedBounds = maps && new maps.LatLngBounds();

			// let unifiedBounds = null;

			// For highlighting the NEXT leg after a layover
			let layoverHighlightLegId;

			const indexOfFocus = tripLegs.indexOf(
				tripLegs.find((x) => x.id === focusLegId),
			);

			const legPaths = tripLegs
				// .filter((x) => !x.isLayover)
				.map(
					(
						{
							id: legId,
							legIndex,
							startPlace,
							stopPlace,
							estimatedPolyline,
							actualPolyline,
							actualPolylineFiltered,
							isLayover,
						},
						index,
					) => {
						if (isLayover && index > 0) {
							if (focusLegId === legId) {
								// The 'focusOnLegStart' is meaningless here for layovers
								const { lat, lng } = startPlace || {};
								pointFocusHelper(
									unifiedBounds,
									{ lat, lng },
									{ focusSizeMiles },
								);

								const { id: nextLegId } =
									index < tripLegs.length ? tripLegs[index + 1] : {};

								layoverHighlightLegId = nextLegId;
							}

							// console.log(`usePath focus debug (layovers)`, {
							// 	legId,
							// 	unifiedBounds,
							// 	focusLegId,
							// 	isLayover,
							// });

							return undefined;
						}

						if (
							!editingWhileActive &&
							focusLegId &&
							hideFocusedLeg &&
							focusLegId === legId
						) {
							// skip leg in this set of paths, let the user render
							// this in a different manner (e.g. live tracking)
							return undefined;
						}

						if (
							!estimatedPolyline &&
							!actualPolyline &&
							!actualPolylineFiltered
						) {
							console.warn(
								`No polyline stored for leg ${legId} # ${legIndex}, can't create a path`,
							);
							return undefined;
						}

						const points = polyline.decode(
							actualPolylineFiltered || actualPolyline || estimatedPolyline,
							5,
						);

						const pathGenerator =
							maps &&
							new maps.Polyline({
								path: [],
							});

						let useBounds = true;
						if (!editingWhileActive) {
							if (!focusLegId || focusLegId === legId) {
								if (focusOnLegStart) {
									useBounds = false;

									const [[lat, lng]] = points;
									pointFocusHelper(
										unifiedBounds,
										{ lat, lng },
										{ focusSizeMiles },
									);
								} else {
									useBounds = true;
								}
							} else if (focusLegId) {
								useBounds = false;
							}
						}

						// console.log(`usePath focus debug`, {
						// 	legId,
						// 	useBounds,
						// 	focusLegId,
						// 	focusOnLegStart,
						// 	editingWhileActive,
						// });

						points.forEach(([lat, lng]) => {
							const point = toLatLngRef({ lat, lng });
							if (useBounds) {
								unifiedBounds.extend(point);
							}
							if (editingWhileActive && focusLegId && focusLegId === legId) {
								return;
							}
							pathGenerator.getPath().push(point);
						});

						let pathColor;

						if (layoverHighlightLegId === legId) {
							// Next layover leg ?
							pathColor = MARKER_COLORS.origin;
							// console.log(
							// 	`#${index}: pathColor: layoverHighlightLegId match`,
							// 	pathColor,
							// );
						} else if (focusLegId === legId) {
							// Focused current leg
							pathColor = sampleWaypointGradient(index + 1, tripLegs.length);
							// console.log(
							// 	`#${index}: pathColor: focusLegId matched`,
							// 	pathColor,
							// );
						} else if (focusLegId && index < indexOfFocus) {
							// leg(s) before focused current leg
							pathColor = `#${MAP_PATH_INACTIVE_COLOR}`;
							// console.log(
							// 	`#${index}: pathColor: has focus leg and this leg is old`,
							// 	pathColor,
							// );
						} else if (focusLegId) {
							// has focused legs at all?
							pathColor = MARKER_COLORS.destination;
							// console.log(
							// 	`#${index}: pathColor: has focus leg but nothing else matches`,
							// 	pathColor,
							// );
						} else {
							// leg the path default color
							pathColor = undefined;
							// console.log(
							// 	`#${index}: pathColor: fallthru`,
							// 	pathColor,
							// );
						}

						return {
							id: legId,
							path: pathGenerator.getPath(),
							pathColor,
						};
					},
				);

			return {
				paths: legPaths.filter((x) => x),
				bounds: unifiedBounds,
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			editingWhileActive,
			dropoffTbd,
			maps,
			tripId,
			focusLegId,
			hideFocusedLeg,
			focusOnLegStart,
			focusSizeMiles,
			// This generates a flat key to better use for
			// change detection
			// eslint-disable-next-line react-hooks/exhaustive-deps
			tripLegs && tripLegs.length
				? tripLegs.map((x) => x.id + x.updatedAt).join('')
				: '',
		],
	) || {};

	// if (directionsError) {
	// 	console.error(
	// 		`Error creating unified trip path for trip ${tripId}`,
	// 		{
	// 			tripId,
	// 			directionsError,
	// 		},
	// 	);
	// }

	const places = useMemo(
		() =>
			tripId && tripLegs && tripLegs.length
				? legsToPlaces(tripLegs, {
						// debug: true,
				  })
				: [],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			tripId,
			// This generates a flat key to better use for
			// change detection
			// eslint-disable-next-line react-hooks/exhaustive-deps
			tripLegs && tripLegs.length
				? tripLegs.map((x) => x.id + x.updatedAt).join('')
				: '',
		],
	);

	const pathMarkers = useMemo(
		() =>
			!tripId
				? []
				: places
						.map((place, index) => {
							const markerType = markerTypeFromIndex(index, places);

							return placeToMarker(
								place,
								markerType,
								markerType === 'waypoint'
									? sampleWaypointGradient(index, places.length - 1)
									: undefined,
							);
						})
						.filter((x) => x),
		[places, tripId],
	);

	const tripPath = { tripLegs, places, paths, pathMarkers, bounds };
	// console.log(`useTripPath: generated:`, tripPath);
	return tripPath;

	// return { paths, pathMarkers, bounds };
}
