/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';

import {
	eventToTarget,
	findParent,
	hasAttributeSafe,
	getAttributeSafe,
} from 'shared/utils/documentEventHelpers';

import styles from './AlphaLetterBar.module.scss';

const alphaLetters = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
];

const LETTER_ATTRIBUTE = 'data-letter';
function eventToLetter(ev) {
	const target = eventToTarget(ev);
	const letterParent = findParent(target, (node) => {
		if (node === document) {
			return false;
		}
		return hasAttributeSafe(node, LETTER_ATTRIBUTE);
	});

	if (!letterParent || letterParent === document) {
		return undefined;
	}

	return getAttributeSafe(letterParent, LETTER_ATTRIBUTE);
}

export default function AlphaLetterBar({ onChange, currentValue }) {
	const [activeLetter, setActiveLetterInternal] = useState(currentValue);
	const selectedLetterRef = useRef();
	const setActiveLetter = useCallback(
		(letter) => {
			if (selectedLetterRef.current === letter) {
				return;
			}

			selectedLetterRef.current = letter;
			setActiveLetterInternal(letter);

			if (typeof onChange === 'function') {
				onChange(letter);
			}
		},
		[onChange],
	);

	const lastCurrentValue = useRef();
	useEffect(() => {
		if (currentValue !== lastCurrentValue.current) {
			lastCurrentValue.current = currentValue;
			setActiveLetter(currentValue);
		}
	}, [currentValue, setActiveLetter]);

	const rootRef = useRef();

	useEffect(() => {
		const { current: el } = rootRef;
		if (!el) {
			return () => {};
		}

		let isDown = false;
		const onDown = () => {
			isDown = true;
		};

		const onUp = () => {
			isDown = false;
		};

		const onMove = (ev) => {
			if (isDown) {
				const letter = eventToLetter(ev);
				if (!letter) {
					return;
				}
				// console.log(`move event`, { letter, ev });
				setActiveLetter(letter);
			}
		};

		// TODO: Do we want to support wheel events? TBD, for now, too much work
		const wheelEvent = (ev) => {
			// console.log(`wheelEvent:`, ev);
			// const letter = eventToLetter(ev);
			// 	if (!letter) {
			// 		return letter;
			// 	}
			// 	console.log(`wheel event`, { letter, ev });
			// 	setActiveLetter(letter);
		};

		el.addEventListener('mousewheel', wheelEvent, true);
		el.addEventListener('DOMMouseScroll', wheelEvent, true);
		el.addEventListener('touchstart', onDown, true);
		el.addEventListener('mousedown', onDown, true);
		el.addEventListener('touchmove', onMove, true);
		el.addEventListener('mousemove', onMove, true);
		el.addEventListener('touchend', onUp, true);
		el.addEventListener('mouseup', onUp, true);

		return () => {
			if (el) {
				el.removeEventListener('mousewheel', wheelEvent);
				el.removeEventListener('DOMMouseScroll', wheelEvent);
				el.removeEventListener('touchstart', onDown);
				el.removeEventListener('mousedown', onDown);
				el.removeEventListener('touchmove', onMove);
				el.removeEventListener('mousemove', onMove);
				el.removeEventListener('touchend', onUp);
				el.removeEventListener('mouseup', onUp);
			}
		};
	});

	return (
		<div className={styles.root} ref={rootRef}>
			{alphaLetters.map((letter) => (
				<ButtonBase
					key={letter}
					className={clsx(
						styles.letter,
						activeLetter === letter && styles.activeLetter,
					)}
					onClick={() => setActiveLetter(letter)}
					{...{ [LETTER_ATTRIBUTE]: letter }}
				>
					<label>{letter}</label>
				</ButtonBase>
			))}
		</div>
	);
}
