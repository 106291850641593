/* eslint-disable no-console */
import { useEffect, useCallback } from 'react';
import { MessageTypes } from 'shared/utils/MessageTypes';
import BackendService from 'shared/services/BackendService';
import TripStatus from 'shared/utils/TripStatus';

const fmt = (value, precision = 10) => {
	return value ? value.toFixed(precision) : value;
};

const SENSOR_DUMP_SECONDS = 5;

// For versioning later changes
const SENSOR_PACKET_VERSION = 1;

const toLatLngRef = ({ latitude, longitude } = {}) =>
	window.google && window.google.maps
		? new window.google.maps.LatLng(latitude, longitude)
		: undefined;

const computeSpeed = (current, previous) => {
	const meters =
		window.google && window.google.maps && previous && current
			? window.google.maps.geometry.spherical.computeDistanceBetween(
					toLatLngRef(previous),
					toLatLngRef(current),
			  )
			: undefined;
	const sec = (current.ts - previous.ts) / 1000;
	const metersPerSec = meters ? meters / sec : -1;
	return metersPerSec;
};

export default class SensorLogger {
	static start(tripId) {
		// Don't restart for same trip
		if (this.tripId === tripId) {
			return;
		}

		if (this.tripId) {
			this.stop();
		}

		this.started = true;

		this.tripId = tripId;
		this.readings = [];

		// console.warn(`SensorLogger.start, tripId:`, tripId);

		// Request permission for iOS 13+ devices
		if (
			window.DeviceMotionEvent &&
			typeof window.DeviceMotionEvent.requestPermission === 'function'
		) {
			window.DeviceMotionEvent.requestPermission();
		}

		window.addEventListener('devicemotion', this.handleMotion);
		// window.addEventListener('deviceorientation', handleOrientation);

		// Grab GPS with high-speed readings
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(startingPosition) => {
					this.currentGps = {
						ts: Date.now(),
						...startingPosition.coords,
					};

					this.watchTid = navigator.geolocation.watchPosition(
						(position) => {
							const ts = Date.now();
							this.previousGps = {
								ts,
								...this.currentGps,
							};
							this.currentGps = {
								ts,
								...position.coords,
							};
							this.currentSpeed = computeSpeed(
								this.currentGps,
								this.previousGps,
							);
						},
						(error) =>
							console.warn(`Trouble getting GPS in SensorLogger:`, error),
						{
							enableHighAccuracy: true,
							maximumAge: 1000 / 30,
						},
					);
				},
				(error) => console.warn(`Trouble starting GPS in SensorLogger:`, error),
			);
		}

		// Dump readings to backend every X seconds
		this.timer = setInterval(
			() => this.sendPacket(),
			SENSOR_DUMP_SECONDS * 1000,
		);
	}

	static stop() {
		this.started = false;
		this.tripId = null;

		if (this.readings && this.readings.length) {
			this.sendPacket();
		}

		if (this.watchTid) {
			navigator.geolocation.clearWatch(this.watchTid);
			this.watchTid = null;
		}

		window.removeEventListener('devicemotion', this.handleMotion);
		clearInterval(this.timer);
	}

	static sendPacket() {
		const { readings, tripId } = this;
		this.readings = [];

		if (!tripId || !readings || !readings.length) {
			return;
		}

		console.log(
			`Dumping ${readings.length} readings to the server for trip ${tripId}`,
		);

		BackendService.sendMessage({
			type: MessageTypes.SensorStreamData,
			timestamp: Date.now(),
			tripId,
			readings,
		});
	}

	static handleMotion = (event) => {
		const gz = event.rotationRate.alpha;
		const gx = event.rotationRate.beta;
		const gy = event.rotationRate.gamma;

		const ax = fmt(event.acceleration.x);
		const ay = fmt(event.acceleration.y);
		const az = fmt(event.acceleration.z);

		const ai = fmt(event.interval, 2);

		const { latitude: lat = -1, longitude: lng = -1 } = this.currentGps || {};
		const speed = this.currentSpeed || -1;

		this.readings.push(
			SENSOR_PACKET_VERSION,
			Date.now(),
			gx,
			gy,
			gz,
			ax,
			ay,
			az,
			ai,
			lat,
			lng,
			speed,
		);

		if (this.readings.length % 100 === 0) {
			console.log(
				`Added a new sensor reading, now at ${this.readings.length} readings...`,
			);
		}
	};
}

export function useSensorLogger(currentTrip) {
	// Create callback because it must be started from a user gesture
	const startSensorLogger = useCallback(() => {
		if (currentTrip && [TripStatus.Riding].includes(currentTrip.status)) {
			// console.log(
			// 	`useSensorLogger: Document clicked, starting sensor logger for trip ${currentTrip.id}`,
			// );
			// Okay to call multiple times with same TripId
			SensorLogger.start(currentTrip.id);
		} else {
			if (SensorLogger.started) {
				console.log(
					`useSensorLogger: Stopping logger because no trip or trip is status ${
						currentTrip && currentTrip.status
					}`,
				);
			}
			SensorLogger.stop();
		}
	}, [currentTrip]);

	// Start/stop SensorLogger only when needed
	useEffect(() => {
		document.body.addEventListener('click', startSensorLogger);

		return () => {
			document.body.removeEventListener('click', startSensorLogger);
			SensorLogger.stop();
		};
	});
}
