const OrderStatus = {
	// User has started a ride request but not finished filling in all details
	Draft: 'draft',

	// Line details entered and user has asked for quote - TBD transitioning
	Quoted: 'quoted',
	// Quote accepted - some future state will start dispatching/fulfilling
	QuoteAccepted: 'quote_accepted',

	// New state introduced inorder to support Crypto payments.
	// Block dispatch by sitting in this state until crypto lands on the blockchain.
	// See coinbase-charge model and TripCryptoProcessor worker.
	// This state is auto-set by TripStateEngine when trying to transition into PendingDriverAcceptance
	// if the trip doesn't have a completed crypto charge.
	// As soon as the crypto charge clears, TripCryptoProcessor will kick off dispatch by
	// re-calling TripStateEngine to set PendingDriverAcceptance again.
	PaymentPending: 'payment_pending',

	// While dispatching (finding a driver), this line is "processing"
	FulfillmentProcessing: 'fulfillment_processing',
	// Once dispatch is successful, it's "Ready" to fulfill (if scheduled),
	// or goes right to active state if on-demand
	FulfillmentReady: 'fulfillment_ready',
	// Fulfillment active (this line has active trips)
	FulfillmentActive: 'fulfillment_active',
	// Happy path final state for fulfillment
	FulfillmentCompleted: 'fulfillment_completed',

	// Ready for invoicing - this means that the invoice engine has finished
	// calculating pricing and ready for charging
	// (Admins could still potentially edit the invoice)
	InvoiceReady: 'invoice_ready',
	// Happy path final state
	InvoicePaid: 'invoice_paid',

	// Failure to invoice (e.g. CC error?)
	InvoiceFailed: 'invoice_error',
	// Error state - NOT cancellation - use a cancel state below if cancelling
	FulfillmentFailed: 'fulfillment_error',
	// Driver canceled the trip - TBD: When would we ever let drivers cancel...?
	VendorCanceled: 'driver_canceled',
	// User canceled the trip
	UserCanceled: 'user_canceled',
	// User never showed for their trip (different than user choosing to cancel - this
	// means user did not communicate with Concierge staff asking to cancel or communicate with driver)
	UserNoShow: 'user_no_show',
	// Quote sat for more than X hours with no user interaction
	QuoteExpired: 'quote_expired',
	// User said "no thanks" (etc) to quote
	QuoteCanceled: 'quote_canceled',
};

export const OrderStatusEmojis = {
	[OrderStatus.Draft]: '⬜️',
	[OrderStatus.Quoted]: '🟨',
	[OrderStatus.QuoteAccepted]: '🟦',
	[OrderStatus.PaymentPending]: '🟨',
	[OrderStatus.FulfillmentProcessing]: '🟧',
	[OrderStatus.FulfillmentReady]: '🟪',
	[OrderStatus.FulfillmentActive]: '🟧',
	[OrderStatus.FulfillmentCompleted]: '🟨',
	[OrderStatus.InvoiceReady]: '🟨',
	[OrderStatus.InvoicePaid]: '🟩',
	[OrderStatus.InvoiceFailed]: '🟥',
	[OrderStatus.FulfillmentFailed]: '🟥',
	[OrderStatus.VendorCanceled]: '🟥',
	[OrderStatus.UserCanceled]: '🟥',
	[OrderStatus.UserNoShow]: '🟥',
	[OrderStatus.QuoteExpired]: '⬜️',
	[OrderStatus.QuoteCanceled]: '⬜️',
	_unknown: '⬜️',

	// 🟥🟧🟨🟩🟦🟪⬛️🟫⬜️
};

export const orderStatusToEmoji = (status) => {
	const emoji = OrderStatusEmojis[status];
	return emoji || OrderStatusEmojis._unknown;
};

export const dumpStatusToString = (status) => {
	return `${orderStatusToEmoji(status)} ${status}`;
};

// export const OrderTransitions = {
// 	[OrderStatus.Draft]: [
// 		OrderStatus.Quoted,
// 		OrderStatus.PendingDriverAcceptance,
// 		OrderStatus.UserCanceled,
// 		OrderStatus.QuoteCanceled,
// 		OrderStatus.QuoteExpired,
// 	],
// 	[OrderStatus.Quoted]: [
// 		OrderStatus.PendingDriverAcceptance,
// 		OrderStatus.ScheduledPendingDriverAcceptance,
// 		OrderStatus.Draft,
// 		OrderStatus.UserCanceled,
// 		OrderStatus.QuoteCanceled,
// 		OrderStatus.QuoteExpired,
// 	],
// 	[OrderStatus.ScheduledPendingDriverAcceptance]: [
// 		OrderStatus.ScheduledDriverAccepted,
// 		OrderStatus.DriverAccepted,
// 		OrderStatus.DriverRejected,
// 		OrderStatus.UserCanceled,
// 		OrderStatus.Draft,
// 	],
// 	[OrderStatus.ScheduledDriverAccepted]: [
// 		OrderStatus.ScheduledTripPending,
// 		OrderStatus.ScheduledDriverArriving,
// 		OrderStatus.UserCanceled,
// 		OrderStatus.DriverCanceled, // TBD if we allow this?
// 		OrderStatus.Draft,
// 		OrderStatus.NoShow,
// 	],
// 	[OrderStatus.ScheduledTripPending]: [
// 		OrderStatus.ScheduledDriverArriving,
// 		OrderStatus.DriverArrived,
// 		OrderStatus.Riding,
// 		OrderStatus.UserCanceled,
// 		OrderStatus.DriverCanceled, // TBD if we allow this?
// 		OrderStatus.NoShow,
// 	],
// 	[OrderStatus.ScheduledDriverArriving]: [
// 		OrderStatus.DriverArrived,
// 		OrderStatus.Riding,
// 		OrderStatus.UserCanceled,
// 		OrderStatus.DriverCanceled, // TBD if we allow this?
// 		OrderStatus.NoShow,
// 	],
// 	[OrderStatus.PendingDriverAcceptance]: [
// 		OrderStatus.DriverAccepted,
// 		OrderStatus.DriverRejected,
// 		OrderStatus.UserCanceled,
// 	],
// 	[OrderStatus.DriverAccepted]: [
// 		OrderStatus.Riding,
// 		OrderStatus.DriverArrived,
// 		OrderStatus.UserCanceled,
// 		OrderStatus.DriverCanceled, // TBD if we allow this?
// 		OrderStatus.NoShow,
// 	],
// 	[OrderStatus.DriverArrived]: [
// 		OrderStatus.Riding,
// 		OrderStatus.UserCanceled,
// 		OrderStatus.NoShow,
// 	],
// 	[OrderStatus.Riding]: [OrderStatus.RidingStopped, OrderStatus.RideCompleted],
// 	[OrderStatus.RidingStopped]: [OrderStatus.Riding, OrderStatus.RideCompleted],
// 	[OrderStatus.RideCompleted]: [OrderStatus.UserCompleted, OrderStatus.Draft],
// 	[OrderStatus.UserCanceled]: [OrderStatus.Draft],
// 	[OrderStatus.NoShow]: [OrderStatus.Draft],
// 	[OrderStatus.QuoteCanceled]: [OrderStatus.Draft],
// };

// export const STARTED_STATES = [
// 	OrderStatus.ScheduledDriverArriving,
// 	OrderStatus.DriverAccepted,
// 	OrderStatus.DriverArrived,
// 	OrderStatus.Riding,
// 	OrderStatus.RidingStopped,
// ];

// export const RIDING_STATES = [OrderStatus.Riding, OrderStatus.RidingStopped];

// export const RIDING_OR_ARRIVED_STATES = [
// 	...RIDING_STATES,
// 	OrderStatus.DriverArrived,
// ];

// export const COMPLETED_STATES = [
// 	OrderStatus.DriverCanceled,
// 	OrderStatus.UserCanceled,
// 	OrderStatus.RideCompleted,
// 	OrderStatus.UserCompleted,
// 	OrderStatus.QuoteCanceled,
// 	OrderStatus.QuoteExpired,
// 	OrderStatus.NoShow,
// ];

// export const QUOTE_DRAFT_STATES = [OrderStatus.Draft, OrderStatus.Quoted];

// export const DRIVER_EMPTY_STATES = [
// 	// OrderStatus.ScheduledDriverAccepted,
// 	OrderStatus.DriverRejected,
// 	OrderStatus.DriverCanceled,
// 	OrderStatus.UserCanceled,
// 	// OrderStatus.RideCompleted,
// 	OrderStatus.QuoteCanceled,
// 	OrderStatus.QuoteExpired,
// 	OrderStatus.NoShow,
// ];

export const isValidOrderStatus = (status) =>
	Object.values(OrderStatus).includes(status);

// export const isValidOrderStatusTransition = (fromStatus, toStatus) =>
// 	OrderStatusTransitions[fromStatus] &&
// 	OrderStatusTransitions[fromStatus].includes(toStatus);

// export const isDriverEmptyStatus = (status) =>
// 	!status || DRIVER_EMPTY_STATES.includes(status);

// export const isStartedStatus = (status) => STARTED_STATES.includes(status);

// export const isRidingStatus = (status) => RIDING_STATES.includes(status);

// export const isRidingOrArrivedStatus = (status) =>
// 	RIDING_OR_ARRIVED_STATES.includes(status);

// export const isPostedStatus = (status) =>
// 	status && !QUOTE_DRAFT_STATES.includes(status);

// export const isQuotedDraft = (status) => QUOTE_DRAFT_STATES.includes(status);

// export const isCompletedStatus = (status) => COMPLETED_STATES.includes(status);
export default OrderStatus;
