/* eslint-disable no-unused-vars,import/no-cycle, global-require */
/* eslint-disable no-unreachable */
/* eslint-disable no-console */
import { v4 as uuid } from 'uuid';
import React, { useEffect, useCallback, useRef } from 'react';
import StaticEvents from '../utils/StaticEvents';
import MessageTypes from '../utils/MessageTypes';
import SocketClient from '../utils/SocketClient';
import { promiseMap } from '../utils/promise-map';
import { defer, stableDefer } from '../utils/defer';
import DeviceInfo from '../utils/DeviceInfo';
import FunctionalState, {
	useFunctionalState,
} from '../components/FunctionalState';
import AppConfig from '../config-public';
import history from '../utils/history';
import AccountStatus from '../utils/AccountStatus';
// eslint-disable-next-line import/no-cycle
import SocketAuthService from './SocketAuthService';
// eslint-disable-next-line import/no-cycle
import AuthService, { useIsAuthorized, useAuthorization } from './AuthService';
import { ServerStore } from './ServerStore';
// import TimeService from './TimeService';
// import { ServerStore } from '../ServerStore'; // eslint-disable-line import/no-cycle

// magic ...fast enough to catch it, long enough for slow devices
// const HTTPS_WARNING_TIME = 2500;

const LOGIN_REDIR_KEY = '@rubber/pre-login-path';

// For guarding against multiple reloads when versions change
const BE_VER_CACHE_KEY = '@vaya/backend-ver-last-seen';

// Check backend for new version every 5 minutes and reload frontend if version changes
// - but only reload once of the same version because it could just be out of sync (e.g. netlify didn't
// detect changes, etc)
const checkBackendVersion = () => {
	// console.log(`checkBackendVersion:  Going to check version...`);
	ServerStore.backendVersion()
		.then(({ version }) => {
			// console.log(`checkBackendVersion: Loaded version ${version}...`);

			const lastSeenVer = window.localStorage.getItem(BE_VER_CACHE_KEY);
			if (lastSeenVer === version) {
				return;
			}

			window.localStorage.setItem(BE_VER_CACHE_KEY, version);
			if (version !== AppConfig.version) {
				console.warn(
					`checkBackendVersion: Found version change on server: backend=${version}, we are ${AppConfig.version} - reloading once to see if that loads new software, will not reload again while backend continues to report ${version}`,
				);
				window.location.reload();
			} else {
				console.log(
					`checkBackendVersion: Got new version from backend, but all good! We're running the same version on the frontend: ${version}`,
				);
			}
		})
		.catch((ex) => {
			console.warn(
				`checkBackendVersion: Error downloading version from server:`,
				ex,
			);
		});
};

if (global.window && !global.window.DISABLE_VERSION_CHECK) {
	// // Valid user gesture events according to https://html.spec.whatwg.org/multipage/interaction.html#user-activation-processing-model
	// const userInteractionEvents = [
	// 	'click',
	// 	'contextmenu',
	// 	'dblclick',
	// 	'mouseup',
	// 	// 'pointerup',
	// 	'reset',
	// 	'submit',
	// 	// 'touchend',
	// ];

	// // Don't block this service start (GeoService) by waiting for the GpsLogger
	// // because it could be waiting for browser permission, or it anything else
	// const startCallback = () => {
	// 	// this.gpsLogger.startGpsReplay(1000);
	// 	userInteractionEvents.forEach((eventType) =>
	// 		document.body.removeEventListener(eventType, startCallback),
	// 	);
	// };

	// userInteractionEvents.forEach((eventType) =>
	// 	// NOTE: This VERY LIKELY will trigger `[Violation]` warnings in the console, just FYI.
	// 	// Something like "Added non-passive event listener to a scroll-blocking
	// 	// 'mousewheel' event. Consider marking event handler as 'passive' to make
	// 	// the page more responsive." Just ignore the warning.
	// 	document.body.addEventListener(eventType, startCallback, true),
	// );

	global.window.beVerCheckCronTimer = setInterval(
		checkBackendVersion,
		1000 * 60 * 5,
	);
	// global.window.beVerCheckInitialTimer = setTimeout(
	// 	checkBackendVersion,
	// 	1000 * 10,
	// );

	global.window.stopVersionCheck = () => {
		clearInterval(global.window.beVerCheckCronTimer);
		// clearTimeout(global.window.beVerCheckInitialTimer);
	};
}

export function getCurrentPath() {
	const currentUrl = `${global.window && global.window.location.href}`;
	if (currentUrl.includes('#')) {
		// Assume hash routing (current app)
		return window.location.hash.substring(1);
	}

	// Assume browser router (miniapps)
	return (
		global.window &&
		[global.window.location.pathname, global.window.location.search].join('')
	);
}

export default class BackendService extends StaticEvents {
	static ActiveStates = {
		Disconnected: 'Disconnected',
		Connecting: 'Connecting',
		LoginRequired: 'LoginRequired',
		VerifyingPin: 'VerifyingPin',
		Authorizing: 'Authorizing',
		FinishSignupRequired: 'FinishSignupRequired',
		Authorized: 'Authorized',
		LoginError: 'LoginError',
	};

	static ACTIVE_STATE_CHANGED = 'ACTIVE_STATE_CHANGED';

	static activeState = new FunctionalState(
		this.ActiveStates.Disconnected,
		(value) => {
			// console.warn(`* New ActiveState for BackendService:`, value);
			this.emit(this.ACTIVE_STATE_CHANGED, value);

			BackendService.latestActiveState = value;
		},
	);

	static rtcRegistrationState = new FunctionalState({});

	static poolSubscriptionCounts = {};

	static poolUnsubscribeTimers = {};

	static messageHooks = [];

	// Handles time sync with hub
	// static timeService = new TimeService(BackendService);

	// Manages authorization-related logic and provides auth hooks for React
	static authService = new SocketAuthService(BackendService);

	static waitForTokenPromise = stableDefer({
		timeout: -1, // disable
	});

	static waitForReadyPromise = stableDefer({
		autoStart: false,
		timeoutErrorMessage: 'waitForReadyPromise timeout',
		callback: () => {
			if (!this.started) {
				setTimeout(() => {
					this.connect();
				}, 100);
			}
		},
	});

	static waitForToken() {
		return this.waitForTokenPromise.getValue();
	}

	static waitForReady() {
		return this.waitForReadyPromise.getValue();
	}

	// Disabling for now because it causes more trouble than it's worth
	// static useRoleFilter(
	// 	roleFilter,
	// 	{ path: pathForError, dontRedirect = false },
	// ) {
	// 	if (!roleFilter) {
	// 		// console.log(`[BackendService.useRoleFilter] no role filter`);
	// 		return true;
	// 	}

	// 	const auth = this.useAuthorization();

	// 	if (!auth || !auth.user) {
	// 		// Waiting on auth to load ...
	// 		// console.log(
	// 		// 	`[BackendService.useRoleFilter] Waiting on auth to load...`,
	// 		// 	auth,
	// 		// );
	// 		return undefined;
	// 	}

	// 	const {
	// 		user: { isAdmin, isMember, isDriver } = {},
	// 		account: { status } = {},
	// 	} = auth;

	// 	const {
	// 		isAdmin: adminRequired,
	// 		isMember: memberRequired,
	// 		isDriver: driverRequired,
	// 	} = roleFilter;

	// 	// console.log(`[BackendService.useRoleFilter] comparing `, {
	// 	// 	is: {
	// 	// 		isAdmin,
	// 	// 		isMember,
	// 	// 		isDriver,
	// 	// 	},
	// 	// 	required: {
	// 	// 		adminRequired,
	// 	// 		memberRequired,
	// 	// 		driverRequired,
	// 	// 	},
	// 	// 	auth,
	// 	// });

	// 	const setError = (error) => {
	// 		if (dontRedirect) {
	// 			return false;
	// 		}

	// 		this.logout();
	// 		console.error(error);

	// 		this.loginError = error;

	// 		this.activeState.setState(this.ActiveStates.LoginError);

	// 		window.sessionStorage.removeItem(LOGIN_REDIR_KEY);

	// 		setTimeout(() => history.push(`/login/error`), 100);

	// 		return error;
	// 	};

	// 	if (status !== AccountStatus.Active) {
	// 		setTimeout(() => history.push(`/account-pending`), 100);

	// 		return false;
	// 	}

	// 	if (memberRequired && !isMember && !isAdmin) {
	// 		return setError(
	// 			new Error(`Only members can access this page (${pathForError})`),
	// 		);
	// 	}

	// 	if (!memberRequired && driverRequired && !isDriver && !isAdmin) {
	// 		return setError(
	// 			new Error(`Only drivers can access this page (${pathForError})`),
	// 		);
	// 	}

	// 	if (adminRequired && !isAdmin) {
	// 		return setError(
	// 			new Error(
	// 				`Only account administrators can access this page (${pathForError})`,
	// 			),
	// 		);
	// 	}

	// 	// Passed all checks
	// 	return true;
	// }

	static getActiveState() {
		return this.activeState.getValue();
	}

	static connectPromise = stableDefer({
		callback: () => {
			if (!this.started) {
				this.connect();
			}
		},
		autoStart: false,
		timeoutErrorMessage: `Connect timeout`,
	});

	static waitForConnect() {
		return this.connectPromise.getValue();
	}

	static async connect() {
		if (this.started) {
			// console.warn(`BackendService already started`);
			return;
		}

		this.started = true;

		// Called when socket error happens, if given
		this.onConnectionError = null;

		// console.warn(`BackendService: Starting base services ...`);

		// Connect to the hub
		this.createSocket();

		// Just for debugging
		window.BackendService = this;
	}

	static getSSLPage() {
		let ip = AppConfig.apiHost;
		const { protocol, host } = window.location;

		if (ip.includes(`localhost:${AppConfig.tcpPort}`)) {
			ip = `${window.location.hostname}:${AppConfig.tcpPort}`;
		}

		// Add pin into redirect only if user has already tried calling
		// .validatePinData ...
		const { latestPinData: pin } = this;
		const pinRedirect = !pin
			? ''
			: `#/pin/${encodeURI(
					pin.startsWith('http')
						? pin.replace(/^https?:\/\/.*pin\/(\d+)/, '$1')
						: pin,
			  )}`;

		const returnUrl = `${protocol}//${host}${pinRedirect}`;
		return `https://${ip}/ssl.html#${returnUrl}`;
	}

	static createSocket() {
		if (this.socket) {
			console.error(
				`BackendService has socket already, disconnect before re-creating socket`,
			);
			return;
		}

		const { onConnectionError } = this;

		const { apiHost, tcpPort } = AppConfig;

		let ip = apiHost;
		if (ip.includes(`localhost:${tcpPort}`)) {
			ip = `${window.location.hostname}:${tcpPort}`;
		}

		const isNgrok = window.location.hostname.includes(`.ngrok.io`);
		if (isNgrok) {
			ip = AppConfig.backendTunnelHost;
		}

		// console.warn(`createSocket:`, { ip, isNgrok, apiHost, tcpPort });

		// let startTime;

		this.connectedDeadmanTimer = setTimeout(() => {
			this.disconnect();
			console.error(`BackendService: Timeout connecting to ${ip}`);
		}, 5000 * 10);

		// Prod uses nginx frontend to handle SSL, dev doesn't
		const proto =
			AppConfig.buildEnv === 'prod' ||
			window.location.protocol === 'https:' ||
			isNgrok
				? 'wss'
				: 'ws';
		this.socket = new SocketClient(`${proto}://${ip}/app`);
		this.socket.on(SocketClient.CONNECTING, () => {
			console.log(`BaseBackendService: Connecting to ${ip} ...`);

			this.isConnected = false;
			this.isReady = false;
			this.loginReturnUrl = null;
			this.currentAuthPromise = null;

			// startTime = Date.now();
			if (!this.connectCount) {
				this.connectCount = 0;
			}

			this.connectCount += 1;

			this.activeState.setState(this.ActiveStates.Connecting);
		});
		this.socket.on(SocketClient.MESSAGE, this.onSocketMessage);
		this.socket.on(SocketClient.CONNECTED, this.onSocketConnected);
		this.socket.on(SocketClient.CLOSED, (ex) => {
			// const timeDiff = Date.now() - startTime;
			// const code = timeDiff < HTTPS_WARNING_TIME ? 'SSL' : 'UNKNOWN';

			this.activeState.setState(this.ActiveStates.Disconnected);

			// if (code === 'SSL') {
			// 	if (this.connectCount > 7) {
			// 		this.socket.cancelReconnect();

			// 		console.warn(
			// 			`SSL Certificate for hub unrecognized. Visit ${this.getSSLPage()} to accept`,
			// 			{ timeDiff },
			// 		);

			// 		history.push('/ssl-reroute');
			// 	} else {
			// 		console.warn(
			// 			`Quick disconnect, might be SSL error, going to allow a retry then error out`,
			// 		);
			// 	}
			// } else
			if (onConnectionError) {
				onConnectionError({
					// code,
					ip,
					message: `Cannot connect to API servers at ${ip}`,
					// code === 'SSL'
					// 	? 'SSL Certificate Needs Accepted'
					// 	: `Cannot connect to Hub at ${ip}`,
				});
			} else {
				console.warn(`Encountered closed socket for unknown reason:`, ex);
				// , {
				// 	HTTPS_WARNING_TIME,
				// 	timeDiff,
				// });
				// history.push('/');
			}
		});
	}

	static disconnect() {
		if (!this.started) {
			return;
		}

		console.warn(`BackendService stopping ...`);
		this.started = false;

		clearTimeout(this.connectedDeadmanTimer);

		this.activeState.setState(this.ActiveStates.Disconnected);

		// if (this.timeService) {
		// 	this.timeService.stop();
		// }

		if (this.socket) {
			this.socket.disableReconnect();
			this.socket.close();
			this.socket.removeAllListeners();
			this.socket = null;
		}

		this.currentAuthPromise = null;

		console.log(`BackendService stopped`);
	}

	static isReconnecting() {
		return this.socket ? this.socket.isReconnecting() : false;
	}

	static onSocketConnected = async () => {
		// Reset for SSL detection above
		this.connectCount = 0;

		// console.log(`BackendService: Socket Connected!`);
		clearTimeout(this.connectedDeadmanTimer);

		// Sync a local clock instance with the hub's clock for video event sync
		// await this.timeService.start();

		// console.log(`BackendService: Time online`);

		this.isConnected = true;
		this.connectPromise.resolve(true);

		// Update: Socket should not nav, we only connect socket if authed...

		// // If user has logged in before (stored token)
		// // then no need to flag "LoginRequired".
		// // Conversely, if no login token, user needs to choose how to authenticate again
		// if (!this.reauthorize()) {
		// 	this.setLoginRequired();
		// } else {
		// 	// if (process.env.NODE_ENV !== 'development') {
		// 	// history.push('/dashboard');

		// 	this.gotoLastPreLoginPage();
		// }

		if (!this.reauthorize()) {
			console.error(
				`No stored token after socket connected - why did we try to connect the socket?`,
			);
		}
	};

	static hasStoredToken() {
		return this.authService.hasStoredToken();
	}

	static reauthorize({ quiet = false } = {}) {
		if (!this.hasStoredToken()) {
			return false;
		}

		return this.authorize({
			quiet,
			type: MessageTypes.JWTAuthorizationMessage,
		});
	}

	static async authorize({ quiet, ...authData } = {}) {
		if (!authData || !authData.type) {
			throw new Error(`Invalid authData`);
		}

		if (this.currentAuthPromise) {
			return this.currentAuthPromise;
		}

		this.currentAuthPromise = defer();

		this.activeState.setState(this.ActiveStates.Authorizing);

		let error;
		this.loginError = null;
		const result = await this.authService
			.authorizeSocket(authData)
			.catch((ex) => {
				error = ex;
			});

		if (result instanceof Error) {
			error = result;
		}

		if (error) {
			this.currentAuthPromise?.resolve(false);
			this.currentAuthPromise = null;

			console.error(`Caught error authorizing (${error.errorCode}):`, error);

			if (quiet) {
				this.logout({ quiet: true });
				return false;
			}

			// if (this.readyPromise) {
			// 	this.readyPromise.reject(error);
			// 	clearTimeout(this.readyDeadmanTimer);
			// }
			// this.waitForReadyPromise.reject(error);

			this.loginError = error;

			this.activeState.setState(this.ActiveStates.LoginError);
			this.logout({ forceRedirect: false });

			return false;
		}

		this.waitForTokenPromise.resolve(true);

		const { userSignupRequired } = result;
		this.bootCompleted({ userSignupRequired, quiet });

		this.currentAuthPromise.resolve(true);
		this.currentAuthPromise = null;

		return true;
	}

	static bootCompleted({ userSignupRequired, quiet } = {}) {
		this.isReady = true;
		this.waitForReadyPromise.resolve(true);

		// // LoginPage will intercept this active state and handle it
		// if (userSignupRequired) {
		// 	this.activeState.setState(this.ActiveStates.FinishSignupRequired);
		// 	return;
		// }

		this.activeState.setState(this.ActiveStates.Authorized);
		// if (!quiet) {
		// 	this.gotoLastPreLoginPage();
		// }

		// Sometimes we've found we don't get a time, so force sync
		// this.timeService.timeSync.primeBestOffset();

		// // Register every socket as RTC ... do this AFTER authorizing the socket
		// this.sendMessage({
		// 	type: MessageTypes.RegisterSocketAsRTC,
		// });
	}

	static addMessageHook(classRef, callback) {
		if (classRef && callback) {
			this.messageHooks.push({ classRef, callback });
		}
		return (message) => this.sendMessage(message);
	}

	static removeMessageHooks(classRef) {
		this.messageHooks = this.messageHooks.filter(
			(h) => h.classRef !== classRef,
		);
	}

	static onSocketMessage = async (data) => {
		const { type } = data;

		// if (
		// 	![
		// 		MessageTypes.TimeSyncMessage,
		// 		MessageTypes.PingMessage,
		// 		MessageTypes.AuthorizedMessage,
		// 	].includes(type)
		// ) {
		// 	console.warn(`<< IN << `, type, data);
		// }

		let consumed;
		await promiseMap(this.messageHooks, async ({ callback }) => {
			// console.warn(`<< IN << calling hook `, callback);
			if (!consumed && (await callback(data))) {
				consumed = true;
			}
		});

		if (consumed) {
			// console.warn(`<< IN << consumed `, type, data);
			return;
		}

		switch (type) {
			case MessageTypes.PingMessage:
				this.sendMessage({
					type: MessageTypes.PongMessage,
				});
				break;
			case MessageTypes.PINValidationMessage:
				this.handleVerifyPinResponse(data);
				break;
			case MessageTypes.DebugSocketMessage:
				// IGNORE
				break;
			case MessageTypes.RegisterSocketAsRTC:
				this.rtcRegistrationState.setValue(data?.rtc);
				break;
			case MessageTypes.AccountStatusChanged:
				console.warn(`Updating local Account status from socket:`, data);
				// Update internal auth state based on socket
				// Used for when stripe notifies us of subscription changes,
				// this keeps the UI immediately in sync with account status changes
				this.authService.authorizationChanged({
					...this.authService.getAuthorization(),
					// data.account is the full jsonified account object
					account: data.account,
				});
				break;
			default:
				// if (process.env.NODE_ENV !== 'production') {
				// 	console.warn(`Unknown message type from server:`, data);
				// }
				break;
		}
	};

	static async validatePinData(pinData) {
		this.latestPinData = pinData;
		this.activeState.setState(this.ActiveStates.VerifyingPin);
		const deviceInfo = await DeviceInfo.getDeviceInfo();
		this.pinValidationPromise = stableDefer({
			timeout: 1000 * 30,
			timeoutErrorMessage: 'validatePinData timeout',
		});
		this.sendMessage({
			type: MessageTypes.PINValidationMessage,
			pinData,
			deviceInfo,
		});

		return this.pinValidationPromise;
	}

	static handleVerifyPinResponse({
		token,
		tokenType,
		name,
		imageUrl,
		...response
	}) {
		if (!token || !tokenType) {
			this.loginError = response;
			this.activeState.setState(this.ActiveStates.LoginError);
			if (this.pinValidationPromise) {
				this.pinValidationPromise.resolve(
					new Error(response.error || 'Invalid PIN verification response'),
				);
				this.pinValidationPromise = null;
			}

			if (!response.error) {
				console.error(`Unknown PIN verification response:`, response);
			}
			return;
		}

		this.pinToken = token;
		this.pinTokenType = tokenType;
		this.pinName = name;
		this.pinImageUrl = imageUrl;

		// TODO
		console.warn(`Got good pin verification:`, {
			token,
			tokenType,
		});

		// User token, so authorize socket immediately with the token
		this.authorize({
			type: MessageTypes.JWTAuthorizationMessage,
			token,
		});

		if (this.pinValidationPromise) {
			this.pinValidationPromise.resolve({
				token,
				tokenType,
			});
			this.pinValidationPromise = null;
		} else {
			console.warn(`No pending pinValidationPromise?`);
		}
	}

	static getUser() {
		return (this.authService.authorization || {}).user || {};
	}

	static getUserDisplay() {
		const { email, name } = this.getUser();
		return email || name;
	}

	static sendMessage(message) {
		if (this.socket) {
			const { type } = message;
			// if (
			// 	type !== MessageTypes.TimeSyncMessage &&
			// 	type !== MessageTypes.PongMessage
			// ) {
			// 	console.warn(`>> OUT >> `, message.type);
			// }

			this.socket.send(message);
		} else {
			console.warn(`No socket connected, cannot send message:`, message);
			if (!this.isReconnecting()) {
				this.createSocket();
			}
		}
	}

	static getSocket() {
		return this.socket;
	}

	// static useAuthorization() {
	// 	if (!this.started) {
	// 		this.connect();
	// 	}

	// 	return this.authService.useAuthorization();
	// }

	static getAuthService() {
		return this.authService;
	}

	// static getCurrentTime() {
	// 	return this.timeService.currentTime();
	// }

	static logout({ /* forceRedirect = true, */ quiet = false } = {}) {
		this.authService.logout();
		this.pinToken = null;
		this.pinTokenType = null;
		this.clearLoginRedir();
		// if (!quiet) {
		// 	console.log(`Logout, redirecting to login page...`);
		// 	// this.setLoginRequired({ forceRedirect });
		// 	history.push('/');
		// }
	}

	static setLoginRequired({ forceRedirect = false } = {}) {
		this.activeState.setState(this.ActiveStates.LoginRequired);

		this.tryRedirToLoginPage({ forceRedirect });
	}

	static tryRedirToLoginPage({ forceRedirect = false, path = '' } = {}) {
		const currentPage = getCurrentPath(); // window.location.hash.substring(1);
		if (
			forceRedirect ||
			(!currentPage.startsWith('/login') &&
				!currentPage.startsWith('/privacy') &&
				!currentPage.startsWith('/terms') &&
				currentPage !== '/')
		) {
			this.storePageAsLoginRedir(currentPage);
			history.push(`/login${path}`);
			console.warn(`tryRedirToLoginPage`);
		}
	}

	static clearLoginRedir() {
		// Note the use of session storage to localize this key to this window/tab and not just the app
		window.sessionStorage.removeItem(LOGIN_REDIR_KEY);
	}

	static storePageAsLoginRedir(page) {
		const currentPage = page || getCurrentPath();
		// console.warn(`storePageAsLoginRedir currentPage:`, currentPage);
		if (!currentPage.includes('/login') && !currentPage.includes('/signup')) {
			// Note the use of session storage to localize this key to this window/tab and not just the app
			// window.sessionStorage.setItem(LOGIN_REDIR_KEY, currentPage);
			return AuthService.storePreLoginPath(currentPage);
		}
		return undefined;
	}

	static gotoLastPreLoginPage() {
		// if (process.env.NODE_ENV !== 'development') {
		// 	console.warn(`Going to /dashboard because JB/G.`);
		// 	history.push('/dashboard');
		// 	return true;
		// }

		// Note the use of session storage to localize this key to this window/tab and not just the app
		const loginRedir = AuthService.getPreLoginPath(); // window.sessionStorage.getItem(LOGIN_REDIR_KEY);

		if (loginRedir) {
			// this.clearLoginRedir();
		}

		// Disable this for now because causes errors with Cypress and we don't use it anyway as far as I know
		// if (loginRedir) {
		// 	const {
		// 		PushNotifyService,
		// 	} = require('../services/push/PushNotifyService');

		// 	if (PushNotifyService.hashIsAlert(loginRedir)) {
		// 		console.log(
		// 			'Login success, found ALERT in preLoginUrl, passing to PushNotifyService ',
		// 			history.preLoginUrl,
		// 		);
		// 		PushNotifyService.instance.handleAlertHash(loginRedir);
		// 		return true;
		// 	}
		// }

		if (
			loginRedir &&
			!loginRedir.includes('/ssl-reroute') &&
			!loginRedir.includes('/login') &&
			loginRedir !== '/'
		) {
			history.push(loginRedir);
			console.warn(
				`gotoLastPreLoginPage requested, pushing stored path `,
				loginRedir,
			);
			return true;
		}

		console.error(
			`gotoLastPreLoginPage requested, but no page in localStorage`,
		);

		console.warn(
			`Going to ${BackendService.defaultRoute || '/'} since no pre-login page.`,
		);
		history.push(BackendService.defaultRoute || '/');

		return false;
	}
}

export function useMessageHook(callback) {
	useEffect(() => {
		const classRef = `messageHook${uuid()}`;
		BackendService.addMessageHook(classRef, callback);
		return () => BackendService.removeMessageHooks(classRef);
	}, [callback]);
}

export function useUserDetails() {
	const auth = useAuthorization();
	const { user } = auth || {};
	return user || {};
}

export function useDisplayName(user) {
	const authUser = useUserDetails();
	const { name, email } = user || authUser || {};
	return name ? name.split(/\s/)[0] : `${email}`.split(/@/)[0];
}

export function useAccountDetails() {
	const auth = useAuthorization();
	const { account } = auth || {};
	return account || {};
}

export function useAuthRequired({
	requireActiveAccount = false,
	loginRoute = '/login',
	loginConfig = {}, // can use this to pass to the login route things like challenge type to start with
} = {}) {
	// const isAuth = await AuthService.checkAuthorizationStatus();
	// const activeState = this.useActiveState();
	const isAuthorized = useIsAuthorized(true);

	const { account } = useAuthorization() || {};
	const { status } = account || {};

	const { token } = Object.fromEntries(new URLSearchParams());

	// console.log(`useAuthRequired hook:`, {
	// 	isAuthorized,
	// 	token,
	// 	account,
	// 	status,
	// });

	// eslint-disable-next-line react-hooks/rules-of-hooks
	React.useEffect(() => {
		if (isAuthorized !== undefined && !isAuthorized) {
			BackendService.storePageAsLoginRedir();

			const extraParams = new URLSearchParams(
				JSON.parse(
					JSON.stringify({
						...Object.fromEntries(
							new URLSearchParams(window.location.search?.slice(1)),
						),
						...loginConfig,
					}),
				),
			).toString();

			const route = `${loginRoute}?_useAuthRequired=1${
				extraParams ? `&${extraParams}` : ''
			}`;
			history.push(route);
			console.warn(`Need to show login page, sending to`, route);
		} else if (requireActiveAccount && status !== AccountStatus.Active) {
			history.push('/account-pending');
		}
	}, [
		isAuthorized,
		loginConfig,
		loginRoute,
		requireActiveAccount,
		status,
		token,
	]);

	return isAuthorized;
}

export function useActiveState() {
	return useFunctionalState(BackendService.activeState);
}

BackendService.useActiveState = useActiveState;

export function useSocketAuthorization() {
	return useFunctionalState(BackendService.authService.authState);
}

/**
 * Subscribes the current socket to a given pool on the backend,
 * unsubscribes on unmount.
 * Useful in conjunction with `useMessageHook` to conditionally
 * get access to other pools of messages other than the default 'user' pool.
 * Note that the backend enforces role-based or other allowances
 * to which users can actually subscribe to a given pool,
 * so this may have no effect if user is not authorized for the
 * requested pool.
 * @param {string} pool Type of the pool, eg 'user'
 * @param {string} poolId ID of the pool, eg a userId
 */
export function usePoolSubscription(
	pool,
	poolId,
	{
		subscribeType = MessageTypes.ClientPoolSubscribe,
		unsubscribeType = MessageTypes.ClientPoolUnsubscribe,
		payload = {},
	} = {},
) {
	const { activeState, poolSubscriptionCounts, poolUnsubscribeTimers } =
		BackendService;

	const poolKey = `${pool}:${poolId}`;
	if (!poolSubscriptionCounts[poolKey]) {
		poolSubscriptionCounts[poolKey] = 0;
	}

	// We can only subscribe if the socket is already authorized,
	// and if we send the subscription too early, then it will just be ignored.
	// So we wait for the activeState to changed to Authorized before
	// sending the subscription (or send it right away if already authorized).
	// Authorization of the socket happens automatically on connection, and since
	// this hook will often be used immediately on mounting, there often is a race
	// between what hits first - this effect or the socket authorization, so that's
	// why we have to cover both cases.
	const conditionalActivate = useCallback(() => {
		const requestId = uuid();

		// console.log(
		// 	`${loggingPrefix}Got new activeState value:`,
		// 	activeState.getValue(),
		// );

		if (activeState.getValue() === BackendService.ActiveStates.Authorized) {
			if (!pool) {
				// Silently reject
				return;
			}

			if (!poolId) {
				// console.warn(`Cannot subscribe to pool '${pool}' without a valid poolId`);
				return;
			}

			// console.log(
			// 	`usePoolSubscription: + Subscribing to pool: ${pool}:${poolId}`,
			// );

			poolSubscriptionCounts[poolKey]++;

			BackendService.sendMessage({
				...payload,
				type: subscribeType,
				pool,
				poolId,
			});
		}
	}, [
		activeState,
		payload,
		pool,
		poolId,
		poolKey,
		poolSubscriptionCounts,
		subscribeType,
	]);

	useEffect(() => {
		// This "timer" method for sub/unsub was supposed to reduce the flip/flop of subscribe/unsubscribe/subscribe,
		// but it's not quite stable - it doesn't properly seem to cancel the unsubscribe, so not sure why.
		// For now, leaving it as-is
		// if (poolUnsubscribeTimers[poolKey]) {
		// 	clearTimeout(poolUnsubscribeTimers[poolKey]);
		// }
		conditionalActivate();
		activeState.on('changed', conditionalActivate);

		return () => {
			activeState.off('changed', conditionalActivate);
			if (pool && poolId) {
				// poolUnsubscribeTimers[poolKey] = setTimeout(() => {
				poolUnsubscribeTimers[poolKey] = null;
				// console.log(
				// 	`usePoolSubscription: - UNSUBSCRIBE to pool: ${pool}:${poolId}`,
				// );

				poolSubscriptionCounts[poolKey]--;

				if (poolSubscriptionCounts[poolKey] <= 0) {
					poolSubscriptionCounts[poolKey] = 0;
					BackendService.sendMessage({
						...payload,
						type: unsubscribeType,
						pool,
						poolId,
					});
				} else {
					// console.log(
					// 	`NOT unsubscribing from ${poolKey} because ${poolSubscriptionCounts[poolKey]} subscriptions still mounted`,
					// );
				}
				// }, 1000);
			}
		};
	}, [
		activeState,
		conditionalActivate,
		payload,
		pool,
		poolId,
		poolKey,
		poolSubscriptionCounts,
		poolUnsubscribeTimers,
		unsubscribeType,
	]);
}
