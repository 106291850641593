import { jsonSafeStringify } from './jsonSafeStringify';

/**
 * Stringifies the object given then truncates it to `maxLength` characters and adds a suffix denoting the
 * KB truncated, like "(and X KB more...)"
 *
 * Example output: '{ foo: true, name: "foo... (and 2 KB more...)'
 *
 * @param {object} object Any jsonifiable object
 * @param {number} maxLength [default=1024] Max number of characters to print before adding " (and X KB more...)"
 * @returns {string} String like "{ <your json object here> (and <number> KB more...)"
 */
export const elideJsonMessage = (object, maxLength = 1024) => {
	const json = typeof object === 'string' ? object : jsonSafeStringify(object);
	const message = `${json.substring(0, maxLength)}${
		json.length > maxLength
			? ` ... (and ${Math.ceil((json.length - maxLength) / 1024)} KB more...) `
			: ''
	}`;
	return message;
};
