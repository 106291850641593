/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import use1rem from 'shared/utils/use1rem';
import { zonedTimeToUtc, getTimezoneOffset } from 'date-fns-tz';
import useSWR from 'swr';
import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';
import { createContext, useContextSelector } from 'use-context-selector';

import TripStatus, { isStartedStatus } from 'shared/utils/TripStatus';
import { MessageTypes } from 'shared/utils/MessageTypes';

import { ServerStore } from 'shared/services/ServerStore';
import GeoService, { useManagedGpsTracking } from 'shared/services/GeoService';
import BackendService, {
	useMessageHook,
	usePoolSubscription,
} from 'shared/services/BackendService';

import ActionPanel from 'shared/components/ActionPanel';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { convertUTCfromTimezoneEnteredToTimezoneIntended } from 'shared/utils/shiftUtcDateTimezone';
import { useSensorLogger } from 'shared/services/SensorLogger';
import linkedInInsights from 'shared/utils/LinkedInInsights';
import fbq from 'shared/utils/FacebookPixel';
import {
	getRubberIcon,
	PATH_MAP_PIN,
	trackingIconProps,
} from 'shared/utils/mapIconGenerators';
import { useConditionallyHandleErrors } from 'shared/hooks/useConditionallyHandleErrors';
import { later } from 'shared/utils/later';

import { useTripPath } from 'shared/hooks/useTripPath';
import { useInjectTrackingPath } from 'shared/hooks/useInjectTrackingPath';

import PublicAppConfig from 'shared/config-public';
import formatPhone from 'shared/utils/formatPhone';
import { getTimezone } from 'shared/utils/getTimezone';
import {
	getPlaceTextForField,
	createLegSwapPatch,
	createPlaceFieldPatch,
	createLayoverLegPatch,
	createDeleteLegPatch,
} from 'shared/utils/tripLegTransformations';

import { useAuthorization } from 'shared/services/AuthService';

import PlaceSearchProvider from 'shared/services/PlaceSearchContext';
import { useConversationPanel } from '../components/ConversationPanel/ConversationPanel';

const EMPTY_TRIP = {
	tripLegs: [],
	status: TripStatus.Draft,
	pickupPlace: undefined,
	dropoffPlace: undefined,
	dropoffTbd: false,
	pickupPlaceName: 'Current Location',
	pickupPlaceAddressText: '',
	dropoffPlaceName: '',
	dropoffPlaceAddressText: '',
};

const EMPTY_TRIP_OVERRIDE = {
	pickupPlaceName: '',
	pickupPlace: undefined,
};

const EMPTY_TRIP_STATUSES = [
	TripStatus.DriverCanceled,
	TripStatus.UserCanceled,
	TripStatus.UserCompleted,
	TripStatus.QuoteCanceled,
	TripStatus.QuoteExpired,
	TripStatus.NoShow,
];

export const BAD_LAYOVER_TIME_TOAST_ID = 'BAD_LAYOVER_TIME_TOAST_ID';

export const TripEditorModes = {
	// Simple is basically "empty" state
	SimpleMode: 'SimpleMode',
	// Full mode is editing all the options
	FullMode: 'FullMode',

	// Edit state is the default state - does simple or full mode for editing
	EditState: 'EditState',
	// This is the "sub" state when someone wants to select a place
	PlacesState: 'PlacesState',
	// Riding state is when a trip is active
	RidingState: 'RidingState',
};

const MemberTripContext = createContext({});

export const useMemberTripContext = (selector = (x) => x) => {
	// const context = useContext(MemberTripContext);
	const context = useContextSelector(MemberTripContext, selector);
	// if (context === undefined) {
	// 	throw new Error(
	// 		`useMemberTripContext must be used inside a <MemberTripProvider>`,
	// 	);
	// }
	return context;
};

const shouldShowFloatingChatButton = ({ status } = {}) =>
	[
		TripStatus.DriverAccepted,
		TripStatus.DriverArrived,
		TripStatus.RidingStopped,
	].includes(status);

const fetchTrip = async (query) => {
	// Simulate REALLY slow network link
	// await new Promise((resolve) => setTimeout(resolve, 5000));

	const { userIdRequired, userId, tripId, onlyInactive } = query || {};
	let trip;
	if (tripId) {
		trip = await ServerStore.GetTrip(tripId);
	} else if (!userIdRequired || userId) {
		trip = await ServerStore.GetCurrentTrip({
			userId,
			onlyNonActive: onlyInactive,
		});
	}

	const { status } = trip || {};
	const isEmptyTrip = status && EMPTY_TRIP_STATUSES.includes(status);

	console.log(`fetchTrip:`, { query, status, isEmptyTrip, trip });

	return !trip || isEmptyTrip ? { noCurrentTrip: true, query } : trip;
};

export default function MemberTripProvider({
	userIdOverride: userIdOverrideInput,
	tripIdOverride: tripIdOverrideInput,
	requireExplicitUser = false,
	onlyNonActive = false,
	children,
}) {
	const [explicitUserId, setExplicitUserId] = useState();
	const userIdOverride = explicitUserId || userIdOverrideInput;

	const [explicitTripId, setExplicitTripId] = useState();
	// const { activePanel, setActivePanel } = usePanelContext();
	// const updatePanelParams = (params) => {};
	// setActivePanel(Panels.Members, params, true);

	// const { showToast } = useToastContext();

	const toastAccumulator = useRef([]);
	const toastSubscriber = useCallback((toastId) => {
		toastAccumulator.current.push(toastId);
		return toastId;
	}, []);

	const clearSubscribedToasts = useCallback(() => {
		toastAccumulator.current.forEach((toastId) => toast.dismiss(toastId));
	}, []);

	const actionPanelControlRef = useRef();

	const contextEmptyTrip = useMemo(
		() => ({
			...EMPTY_TRIP,
			...(userIdOverride || requireExplicitUser
				? EMPTY_TRIP_OVERRIDE
				: {
						pickupPlace: { currentLocation: true },
				  }),
		}),
		[requireExplicitUser, userIdOverride],
	);

	// console.warn(
	// 	`* TripComposerContext: userIdOverride = `,
	// 	userIdOverride,
	// 	`contextEmptyTrip=`,
	// 	contextEmptyTrip,
	// );

	const { user: { id: authUserId } = {}, user: authUser } =
		useAuthorization() || {};

	let undoCurrentTrip; // define here, set later

	const undoHandler = useCallback(
		(undoData) => undoCurrentTrip(undoData),
		[undoCurrentTrip],
	);

	const conditionallyHandleErrors = useConditionallyHandleErrors({
		undoHandler,
	});

	const { data: { user: contextUser } = {} } = useSWR(
		['context-user', userIdOverride || authUserId],
		(unused, userId) => conditionallyHandleErrors(ServerStore.GetUser(userId)),
	);

	const [placesListLoading, setPlacesListLoading] = useState(false);
	const [placeResultsFromFieldName, setPlaceResultsFromFieldName] =
		useState('dropoff');
	const [panelMaxedAtQuoteRequest, setPanelMaxedAtQuoteRequest] = useState();

	// const { panelMaximized, setPanelMaximized } = useMainPanelControl();

	const { conversationId, setConversationId, setShowFloatingChatButton } =
		useConversationPanel();

	const tripIdOverride = explicitTripId || tripIdOverrideInput;

	const [currentTripLoading, setCurrentTripLoading] = useState(false);
	// Get current trip from backend for current user
	const {
		data: currentTrip,
		mutate: setCurrentTrip,
		error: tripLoadError,
		isValidating,
	} = useSWR(
		[
			requireExplicitUser,
			userIdOverride,
			tripIdOverride,
			onlyNonActive,
			'member-current-trip',
		],
		(userIdRequired, userId, tripId, onlyInactive) =>
			conditionallyHandleErrors(
				fetchTrip({ userIdRequired, userId, tripId, onlyInactive }),
			).then(({ noCurrentTrip, ...trip }) => {
				// console.log(`member-current-trip asked for `, {
				// 	userId,
				// 	userIdOverride,
				// });
				if (noCurrentTrip) {
					return { ...contextEmptyTrip, userIdRequired };
				}

				return trip;
			}),
	);

	// console.log(`useSWR currentTrip`, {
	// 	currentTrip,
	// 	isValidating,
	// 	tripLoadError,
	// });

	// Used by conditionallyHandleErrors above
	undoCurrentTrip = (data) => {
		setCurrentTrip(data, false);
	};

	// Destructure a number of props for use below
	const {
		status: tripStatus,
		tripLegs,
		dropoffTbd,
		activeTripLeg,
		updatedAt,
		isStarted: tripIsStarted,
		isPosted: tripIsPosted,
	} = currentTrip || {};
	const { id: activeLegId, status: legStatus } = activeTripLeg || {};

	// For debugging access
	window.currentTrip = currentTrip;

	const isTripActive = isStartedStatus(tripStatus);

	// const [tripEditorState, setTripEditorState] = useState(
	// 	TripEditorModes.EditState,
	// );

	const [explicitTripEditorMode, setExplicitTripEditorMode] = useState();
	const [explicitTripEditorState, setExplicitTripEditorState] = useState();

	const deactivateExplicitTripEditorState = useCallback(
		() => setExplicitTripEditorState(undefined),
		[],
	);

	const deactivatePlaceListState = useCallback(() => {
		if (tripIsPosted) {
			setExplicitTripEditorState(TripEditorModes.EditState);
			setExplicitTripEditorMode(TripEditorModes.FullMode);
		} else {
			deactivateExplicitTripEditorState();
		}
	}, [deactivateExplicitTripEditorState, tripIsPosted]);

	// SimpleMode - no trip/draft|editState(tbd) && currentLocation start && 0x legs && falsey dropoff
	// QuickQuoteMode - same as SimpleMode && dropoff set
	// FullMode - same as SimpleMode && dropoff && (scheduled time || >0 legs || ??)

	const baseEditorStateValidation =
		!currentTrip ||
		!currentTrip.id ||
		(currentTrip &&
			currentTrip.id &&
			[TripStatus.Draft, TripStatus.Quoted].includes(currentTrip.status));

	// const isRidingState = !baseEditorStateValidation && TripEditorModes.RidingState;

	const tripEditorState =
		explicitTripEditorState ||
		(!baseEditorStateValidation && TripEditorModes.RidingState) ||
		TripEditorModes.EditState;

	const derivedSimpleModeFlag =
		baseEditorStateValidation &&
		(!currentTrip ||
			((!currentTrip.legs || !currentTrip.legs.length) &&
				!currentTrip.dropoffPlace)) &&
		TripEditorModes.SimpleMode;

	const derivedFullModeFlag =
		baseEditorStateValidation &&
		(!currentTrip ||
			((!currentTrip.legs || !currentTrip.legs.length) &&
				(currentTrip.dropoffPlace || currentTrip.dropoffTbd))) &&
		//  &&
		// (currentTrip.scheduledPickupAt ||
		// 	(currentTrip.legs && currentTrip.legs.length)) &&
		TripEditorModes.FullMode;

	// UI can set mode explicitly via setTripEditorMode or default to derived mode flags
	const tripEditorMode =
		explicitTripEditorMode || derivedSimpleModeFlag || derivedFullModeFlag;

	const editingWhileActive =
		explicitTripEditorMode === TripEditorModes.FullMode &&
		tripEditorState === TripEditorModes.EditState &&
		tripIsPosted;

	// Update remaining time when tracking changes
	const lastTimeRef = useRef();
	useEffect(() => {
		// const state = GeoService.trackingMapValues;
		// const cb = () => {
		// 	const { timeRemaining } = state.getValue() || {};
		// 	if (timeRemaining && timeRemaining !== lastTimeRef.current) {
		// 		const revisedTrip = reviseTripEstimate(
		// 			currentTrip,
		// 			timeRemaining,
		// 		);

		// 		// If not in a reviseable state (Pickup/Riding), return is null,
		// 		// i.e. no update needed
		// 		if (revisedTrip) {
		// 			// Since we're changing the trip client side, we don't
		// 			// want to refetch the trip (hence the 'false') since
		// 			// our data here is newer than the server-side data
		// 			setCurrentTrip(revisedTrip, false);
		// 		}
		// 	}

		// 	lastTimeRef.current = timeRemaining;
		// };

		const showFloatingChat = shouldShowFloatingChatButton({
			status: tripStatus,
		});

		// console.log(`MemberTripContext debug:`, {
		// 	status,
		// 	showFloatingChat,
		// 	shouldSetConvo,
		// });

		// if (currentTrip && currentTrip.conversation) {
		// 	const convoId = currentTrip.conversation.id;
		// 	if (convoId !== conversationId && shouldSetConvo) {
		// 		setConversationId(convoId);
		// 	}
		// } else if (conversationId) {
		// 	setConversationId(undefined);
		// }

		setShowFloatingChatButton(showFloatingChat);

		// // Our lovely FunctionalState helpfully notifies us via a 'changed'
		// // event when it changes, so we just listen for that
		// state.on('changed', cb);
		// cb();

		// return () => {
		// 	state.off('changed', cb);
		// };
	}, [currentTrip, setCurrentTrip, setShowFloatingChatButton, tripStatus]);

	const shouldSetConvo = [
		TripStatus.ScheduledDriverArriving,
		TripStatus.DriverAccepted,
		TripStatus.DriverArrived,
		TripStatus.Riding,
		TripStatus.RidingStopped,
	].includes(currentTrip && currentTrip.status);

	const tripConversationId =
		currentTrip && currentTrip.conversation && currentTrip.conversation.id;

	const convoIdRef = useRef();
	useEffect(() => {
		if (
			shouldSetConvo &&
			convoIdRef.current !== tripConversationId + shouldSetConvo &&
			conversationId !== tripConversationId
		) {
			setConversationId(tripConversationId);
			convoIdRef.current = tripConversationId + shouldSetConvo;
		}
	}, [conversationId, setConversationId, shouldSetConvo, tripConversationId]);

	// Turn on/off Sensor logging (accel/gyro) based on trip status
	useSensorLogger(currentTrip);

	// // Sync directions display in GeoService with the currentTrip
	// useManagedDirections(currentTrip);

	// Turn on/off live GPS tracking automatically based on user and trip status
	useManagedGpsTracking(currentTrip);

	// Update the marker to the members last-logged location.
	// We'll update the marker via websocket from MessageTypes.TripDriverGpsUpdate, below
	const markerGuardRef = useRef();
	useEffect(() => {
		if (
			currentTrip &&
			currentTrip.driver &&
			currentTrip.driver.lat &&
			[TripStatus.DriverAccepted, TripStatus.DriverArrived].includes(
				currentTrip.status,
			) &&
			!markerGuardRef.current
		) {
			markerGuardRef.current = true;
			GeoService.updateSingleMarkerLocation({
				...currentTrip.driver,
				icon: trackingIconProps,
			});
		}
	}, [currentTrip]);

	const previousLocationRef = useRef({});

	useEffect(() => {
		return () => setShowFloatingChatButton(true);
	}, [setShowFloatingChatButton]);

	// If using a userId (e.g. as support to view another user's dashboard)
	// then subscribe to the socket updates for that user (e.g. driver's trip tracking, etc).
	// Has no effect if userIdOverride (2nd arg) is falsey
	usePoolSubscription('user', userIdOverride);

	// Required if the authed user is NOT the member,
	// so that the authed user gets updates about the trip
	usePoolSubscription('trip', currentTrip?.id);

	// Hook into the websocket to listen for TripStatus changes.
	// Note: We're NOT returning true here in case somewhere else we want to listen
	// as well.
	const onSocketMessage = useCallback(
		async ({
			type,
			trip,
			lat,
			lng,
			speed: remoteSpeed,
			heading: remoteHeading,
			accuracy: remoteLocationAccuracy,
			timestamp,
		}) => {
			if (type === MessageTypes.TripStatusChanged) {
				const { status, id: tripId } = trip;
				if (
					!tripId ||
					[
						// TripStatus.ScheduledPendingDriverAcceptance,
						TripStatus.ScheduledDriverAccepted,
					].includes(status)
				) {
					return true;
				}

				const isEmptyTrip = EMPTY_TRIP_STATUSES.includes(status);

				setShowFloatingChatButton(
					shouldShowFloatingChatButton({
						status: isEmptyTrip ? TripStatus.Draft : status,
					}),
				);

				// if (isEmptyTrip) {
				// 	updatePanelParams({ emptyTrip: 'true' });
				// }

				console.warn(`TripComposerContext got trip update over socket`, {
					status,
					tripId,
					isEmptyTrip,
					tripFromServer: trip,
				});
				setCurrentTrip(
					isEmptyTrip
						? {
								...contextEmptyTrip,
						  }
						: trip,
					false,
				);
			}

			// // Only happens during testing when I force driver/rider to be the same
			// if (type === MessageTypes.TripRiderGpsUpdate) {
			// 	return true;
			// }

			// if (type === MessageTypes.TripDriverGpsUpdate) {
			// 	await GeoService.start();
			// 	await GeoService.waitForGoogle();

			// 	const updateStates = [
			// 		TripStatus.DriverAccepted,
			// 		TripStatus.Riding,
			// 		TripStatus.RidingStopped,
			// 	];

			// 	/**
			// 	 * By using an "understimation" factor, we keep the users's track of the driver
			// 	 * from jumping backwards if we overestimate the speed and then receive a new
			// 	 * update over the socket
			// 	 */
			// 	const underEstimateFactor = 0.95;
			// 	const speed =
			// 		remoteSpeed ||
			// 		estimateSpeed(
			// 			{ timestamp, lat, lng },
			// 			previousLocationRef.current,
			// 		) * underEstimateFactor;

			// 	previousLocationRef.current = {
			// 		timestamp,
			// 		lat,
			// 		lng,
			// 	};

			// 	if (updateStates.includes(tripStatus)) {
			// 		// Hide location marker
			// 		GeoService.updateSingleMarkerLocation(undefined);

			// 		const updates = {
			// 			lat,
			// 			lng,
			// 			// Disable speed to see if that improves UI update factor
			// 			// speed,
			// 			heading: remoteHeading,
			// 			accuracy: remoteLocationAccuracy,
			// 			// speed: 13.4112 * 2, // fake speed
			// 		};

			// 		console.log(
			// 			`Giving GeoService.updateTrackingProgressFromGps updates:`,
			// 			updates,
			// 		);

			// 		// This is what updates the tracking progress along the direction route
			// 		GeoService.updateTrackingProgressFromGps(updates);
			// 	} else if (status === TripStatus.DriverArrived) {
			// 		// This is what displays a marker for the driver.
			// 		// This does NOT update the tracking of the drivers direction progress....
			// 		GeoService.updateSingleMarkerLocation({
			// 			lat,
			// 			lng,
			// 			icon: trackingIconProps,
			// 			accuracy: remoteLocationAccuracy,
			// 		});
			// 	} else {
			// 		// GeoService.updateSingleMarkerLocation(undefined);
			// 		// this.updateCurrentGpsLocation(this.currentGpsLocation.getValue());
			// 		GeoService.updateSingleMarkerLocation({
			// 			...GeoService.getCurrentGpsLocationValue(),
			// 			icon: trackingIconProps,
			// 		});
			// 	}

			// 	return true;
			// }

			return false;
		},
		[contextEmptyTrip, setCurrentTrip, setShowFloatingChatButton],
	);
	useMessageHook(onSocketMessage);

	// Which field is user editing
	const setTripFieldSelection = useCallback((field) => {
		setPlaceResultsFromFieldName(field);
		// setPlacesList(getPlacesForField(field));
	}, []);

	const clearCurrentTrip = useCallback(
		async ({ restorePanelAsMaximized = undefined } = {}) => {
			// Reset for single line input
			setTripFieldSelection('dropoff');
			setCurrentTripLoading(true);

			console.log(`clearCurrentTrip:`, currentTrip);
			if (currentTrip.id) {
				// Allow UI to transition
				setCurrentTrip(
					{
						...currentTrip,
						status: TripStatus.Draft,
					},
					false,
				);

				const canGoToDraft = [TripStatus.Draft, TripStatus.Quoted].includes(
					currentTrip.status,
				);

				// setPanelMaximized(restorePanelAsMaximized || panelMaxedAtQuoteRequest);

				// const method = canGoToDraft
				// 	? 'MarkTripDraft'
				// 	: 'CancelTripRequest';

				const method = 'CancelTripRequest';

				const updatedTrip = await conditionallyHandleErrors(
					ServerStore[method](currentTrip.id),
					{ ...currentTrip },
				);

				if (updatedTrip) {
					setCurrentTrip(
						EMPTY_TRIP_STATUSES.includes(updatedTrip.status)
							? {
									...contextEmptyTrip,
							  }
							: // : updatedTrip,
							  {
									...updatedTrip,
									...contextEmptyTrip,
							  },
						false,
					);
				}
			} else {
				setCurrentTrip(
					{
						...contextEmptyTrip,
					},
					false,
				);
			}

			setCurrentTripLoading(true);

			// updatePanelParams({ emptyTrip: 'true' });

			GeoService.setDirections(undefined);
		},
		[
			conditionallyHandleErrors,
			contextEmptyTrip,
			currentTrip,
			setCurrentTrip,
			setTripFieldSelection,
		],
	);

	const markTripDraft = useCallback(async () => {
		// Reset for single line input
		setTripFieldSelection('dropoff');
		setCurrentTripLoading(true);

		console.log(`markTripDraft:`, currentTrip);
		if (currentTrip.id) {
			const canGoToDraft = [
				TripStatus.Draft,
				TripStatus.Quoted,
				TripStatus.PaymentPending,
			].includes(currentTrip.status);

			if (!canGoToDraft) {
				console.warn(`Status can't go to draft: ${currentTrip.status}`);
				return;
			}

			// Allow UI to transition
			setCurrentTrip(
				{
					...currentTrip,
					status: TripStatus.Draft,
				},
				false,
			);

			const updatedCurrentTrip = await conditionallyHandleErrors(
				ServerStore.MarkTripDraft(currentTrip.id),
				{ ...currentTrip },
			);

			if (updatedCurrentTrip) {
				console.log(`Modify trip results:`, {
					currentTrip,
					updatedCurrentTrip,
				});
				await setCurrentTrip(updatedCurrentTrip, false);
			}
		}
	}, [
		conditionallyHandleErrors,
		currentTrip,
		setCurrentTrip,
		setTripFieldSelection,
	]);

	const cancelAction = useCallback(
		(...args) => {
			const canGoToDraft = [TripStatus.Draft, TripStatus.Quoted].includes(
				currentTrip.status,
			);

			if (canGoToDraft) {
				return clearCurrentTrip(...args);
			}

			return actionPanelControlRef.current.open();
		},
		[clearCurrentTrip, currentTrip?.status],
	);

	// // Just for testing
	// window.openAp = () => actionPanelControlRef.current.open();

	const thanksForScheduledRide = useCallback(() => {
		// setPanelMaximized(false);
		setCurrentTrip(
			{
				...contextEmptyTrip,
			},
			false,
		);
		GeoService.setDirections(undefined);

		// This clears the ID on the server..
		ServerStore.UpdateUserCurrentTrip(null);
	}, [contextEmptyTrip, setCurrentTrip]);

	const createOrModifyTrip = useCallback(
		async (updatedTripInput, undoData) => {
			// Automatically uses proper auth or override
			const { id: userId, accountId } = contextUser;

			setCurrentTripLoading(true);

			// Ensure the accountId/userId always matches either
			// the logged-in user or the userIdOverride
			const updatedTrip = {
				...updatedTripInput,
				accountId,
				userId,
			};

			// Allow UI to transition
			setCurrentTrip(updatedTrip, false);
			// showToast(null);
			clearSubscribedToasts();

			const updatedCurrentTrip = conditionallyHandleErrors(
				ServerStore[updatedTrip.id ? 'ModifyTripQuote' : 'CreateTrip'](
					updatedTrip,
				),
				undoData,
			);

			if (updatedCurrentTrip) {
				console.log(`Modify trip results:`, {
					currentTrip,
					updatedCurrentTrip,
				});
				await setCurrentTrip(updatedCurrentTrip, false);
			}

			setCurrentTripLoading(false);
		},
		[
			clearSubscribedToasts,
			conditionallyHandleErrors,
			contextUser,
			currentTrip,
			setCurrentTrip,
		],
	);

	const [needMoreInfo, setNeedMoreInfo] = useState(false);

	const gotoMoreInfoScreen = useCallback(
		(updatedTripData) => {
			// if (!panelMaximized) {
			// 	setPanelMaximized(true);
			// }

			setNeedMoreInfo(true);

			const undoData = { ...currentTrip };
			const updatedTrip = {
				...currentTrip,
				...updatedTripData,
				// Don't move to quoted
				stayDrafted: true,
			};
			console.log(
				`gotoMoreInfoScreen: Need more info, refreshing trip on server`,
				updatedTrip,
			);
			createOrModifyTrip(updatedTrip, undoData);
		},
		[createOrModifyTrip, currentTrip],
	);

	const requestQuote = useCallback(
		async (tripData) => {
			// setPanelMaxedAtQuoteRequest(panelMaximized);
			// setPanelMaximized(false);

			// updatePanelParams({ requestQuote: 'true' });

			// Reset list and places handlers
			setTripFieldSelection('dropoff');

			const undoData = { ...currentTrip };

			const updatedTrip = {
				...currentTrip,
				...tripData,
				status: TripStatus.Quoted,
				stayDrafted: false,
			};

			// console.log(`[requestQuote] trip at time of request:`, {
			// 	...updatedTrip,
			// });

			setPlaceResultsFromFieldName('dropoff');

			return createOrModifyTrip(updatedTrip, undoData);
		},
		[createOrModifyTrip, currentTrip, setTripFieldSelection],
	);

	const updateScheduledConfig = useCallback(
		async ({ isScheduled, scheduledPickupAt } = {}) => {
			const undoData = { ...currentTrip };

			// Convert locally if we have a tripPickupTimezone from previous update,
			// so that we don't get flashes of the wrong time in the UI
			const { tripPickupTimezone } = currentTrip;
			const timezoneEntered = getTimezone();

			if (
				scheduledPickupAt &&
				tripPickupTimezone &&
				tripPickupTimezone !== timezoneEntered
			) {
				// eslint-disable-next-line no-param-reassign
				scheduledPickupAt = convertUTCfromTimezoneEnteredToTimezoneIntended({
					timezoneEntered,
					timezoneIntended: tripPickupTimezone,
					utcDateTimeEntered: zonedTimeToUtc(
						scheduledPickupAt,
						timezoneEntered,
					),
				});
			}

			const updatedTrip = {
				...currentTrip,
				isScheduled,
				scheduledPickupAt,

				// Don't move to quoted
				stayDrafted: true,

				// Set this flag ONLY when sending user-local times down to the server
				// for scheduled pickups DIRECTLY FROM THE UI.
				// E.g. don't pass this thru for every save, but only immediately
				// when a user edits/changes the date/time of the pickup.
				// Otherwise, the time will continually be shifted every time we
				// modify the quote.
				// Note that we only are asking the server to convert the time
				// if we don't have a tripPickupTimezone available client-side
				// to do the conversion locally
				convertScheduledTimeToUtc: !tripPickupTimezone,

				// If giving convertScheduledTimeToUtc, SHOULD provide timezone
				// from the user's machine, otherwise server will have to default to user.timezone,
				// which could possibly be wrong or not up-to-date at this moment
				// Note: Not stored in database, just used at runtime to convert times
				timezoneEntered,
			};

			return createOrModifyTrip(updatedTrip, undoData);
		},
		[createOrModifyTrip, currentTrip],
	);

	const updateCryptoFlag = useCallback(
		async ({ isCryptoTrip, preferredCurrency = undefined } = {}) => {
			const undoData = { ...currentTrip };
			const newProps = {
				isCryptoTrip,
				preferredCurrency: preferredCurrency || currentTrip.preferredCurrency,
			};

			const updatedTrip = {
				...currentTrip,
				...newProps,

				// Don't move to quoted
				stayDrafted: true,
			};

			console.log(`updateCryptoFlag new props`, newProps);

			return createOrModifyTrip(updatedTrip, undoData);
		},
		[createOrModifyTrip, currentTrip],
	);

	const setDriverId = useCallback(
		async ({ driverId } = {}) => {
			const undoData = { ...currentTrip };
			const updatedTrip = {
				...currentTrip,
				driverId,
				driver: { loading: true },
				isDriverManuallyAssigned: driverId && driverId !== '-1',
				// Don't move to quoted
				stayDrafted: true,
			};

			return createOrModifyTrip(updatedTrip, undoData);
		},
		[createOrModifyTrip, currentTrip],
	);

	const setAirline = useCallback(
		async ({ airline } = {}) => {
			const undoData = { ...currentTrip };
			const updatedTrip = {
				...currentTrip,
				airline,
				// Don't move to quoted
				stayDrafted: true,
			};

			return createOrModifyTrip(updatedTrip, undoData);
		},
		[createOrModifyTrip, currentTrip],
	);

	const setFlightNumber = useCallback(
		async ({ flightNumber } = {}) => {
			const undoData = { ...currentTrip };
			const updatedTrip = {
				...currentTrip,
				flightNumber,
				// Don't move to quoted
				stayDrafted: true,
			};

			return createOrModifyTrip(updatedTrip, undoData);
		},
		[createOrModifyTrip, currentTrip],
	);

	// const createNewTripLeg = async (afterLeg) => {
	// createInsertTripLegPatch
	// }

	const deleteTripLeg = useCallback(
		async (field) => {
			if (typeof field === 'string') {
				toastSubscriber(
					toast.error(
						"Can't delete the first or the last legs (pickup/dropoff)",
					),
				);
				return;
			}

			const { leg, placeType } = field;

			const tripPatch = createDeleteLegPatch(tripLegs, leg, placeType);

			const undoData = { ...currentTrip };
			const updatedCurrentTrip = {
				...currentTrip,
				...tripPatch,
			};

			// setCurrentTrip(updatedCurrentTrip, false);
			createOrModifyTrip(updatedCurrentTrip, undoData);
		},
		[createOrModifyTrip, currentTrip, toastSubscriber, tripLegs],
	);

	const swapDropoffPoints = useCallback(
		(fieldId) => {
			if (fieldId !== 'dropoff') {
				toastSubscriber(
					toast.error(
						"Can't swap anything other than the destination with previous location",
					),
				);
				return;
			}

			const { tripLegs: newTripLegs, tripDropoffProps } = createLegSwapPatch(
				currentTrip.tripLegs,
			);

			const undoData = { ...currentTrip };
			const updatedCurrentTrip = {
				...currentTrip,
				...tripDropoffProps,
				tripLegs: newTripLegs,
			};

			// setCurrentTrip(updatedCurrentTrip, false);
			createOrModifyTrip(updatedCurrentTrip, undoData);
		},
		[createOrModifyTrip, currentTrip, toastSubscriber],
	);

	const setLayoverMinutes = useCallback(
		(fieldId, layoverMinutes) => {
			if (typeof fieldId === 'string') {
				toastSubscriber(toast.error('Invalid fieldId'));
				return;
			}

			const { leg, leg: { isLayover } = {} } = fieldId;
			if (!isLayover) {
				toastSubscriber(toast.error(`Leg is not a layover, can't set minutes`));
				return;
			}

			if (Number.isNaN(layoverMinutes)) {
				toastSubscriber(
					toast.error(
						`Minutes must be specified in numbers, you gave me '${layoverMinutes}'`,
						{ toastId: BAD_LAYOVER_TIME_TOAST_ID },
					),
				);
				return;
			}
			if (layoverMinutes < 5) {
				toastSubscriber(
					toast.error(`Layover time must be greater than 5 minutes`, {
						toastId: BAD_LAYOVER_TIME_TOAST_ID,
					}),
				);
				return;
			}
			if (layoverMinutes > 60 * 23.95) {
				toastSubscriber(
					toast.error(`Layover time must be less than 24 hours`, {
						toastId: BAD_LAYOVER_TIME_TOAST_ID,
					}),
				);
				return;
			}

			const listItemRef = tripLegs.find(({ id }) => id === leg.id);

			// Assign by ref - note that the API will copy this value to layoverMinutes prop.
			// API will ignore it if we set .layoverMinutes directly
			listItemRef.userEstimatedLayoverMinutes = layoverMinutes;

			console.log(`Applied layover minutes to leg`, listItemRef);

			const undoData = { ...currentTrip };
			const updatedCurrentTrip = {
				...currentTrip,
				tripLegs: tripLegs.slice(),
			};

			// setCurrentTrip(updatedCurrentTrip, false);
			createOrModifyTrip(updatedCurrentTrip, undoData);
		},
		[createOrModifyTrip, currentTrip, toastSubscriber, tripLegs],
	);

	const createLayoverFromLeg = useCallback(
		async (fieldId) => {
			if (typeof fieldId === 'string') {
				toastSubscriber(
					toast.error(
						"Can't create layover from first or the last legs (pickup/dropoff)",
					),
				);
				return;
			}

			const { leg: fromLeg, placeType } = fieldId;

			const { tripLegs: newLegs } = createLayoverLegPatch(tripLegs, fromLeg);

			console.log(`createLayoverFromLeg: final tx`, newLegs);

			const undoData = { ...currentTrip };
			const updatedCurrentTrip = {
				...currentTrip,
				tripLegs: newLegs,
			};

			createOrModifyTrip(updatedCurrentTrip, undoData);
		},
		[createOrModifyTrip, currentTrip, toastSubscriber, tripLegs],
	);

	const setPlaceField = useCallback(
		async (field = 'start', props) => {
			deactivatePlaceListState();

			// console.log(` * setPlaceField: [field=${field}] props=`, props);
			let {
				currentLocation,
				// eslint-disable-next-line no-shadow
				dropoffTbd,
				lat: placeLat,
				lng: placeLng,
				addressText: placeAddress,
				name: placeName,

				searchResult,
				googlePlaceId,
				mainText,
				secondaryText,
				details,
			} = props || {};

			if (
				currentLocation === undefined &&
				dropoffTbd === undefined &&
				(!placeLat || !placeLng) &&
				!searchResult
			) {
				const patch = createPlaceFieldPatch(currentTrip, field);
				// GeoService.setDirections(undefined);
				setCurrentTrip(
					{
						...currentTrip,
						...patch,
					},
					false,
				);
				return;
			}

			if (searchResult && googlePlaceId) {
				// console.log(`Got details:`, details);
				const {
					location,
					name: resultName,
					// viewport,
					addressText,
					error,
				} = details;
				if (location && !error) {
					placeLat = location.lat();
					placeLng = location.lng();

					placeAddress = addressText || secondaryText;
					placeName = resultName || mainText;
				} else {
					console.error(
						`Error looking up result details for ${googlePlaceId}:`,
						error,
						details,
					);
					return;
				}
			}

			const updatedCurrentTrip = {
				...currentTrip,
				dropoffTbd:
					field === 'start' || field !== 'dropoff'
						? currentTrip.dropoffTbd
						: dropoffTbd,
				...createPlaceFieldPatch(currentTrip, field, {
					place: {
						lat: placeLat,
						lng: placeLng,
						googlePlaceId,
					},
					name: currentLocation ? 'Current Location' : placeName,
					addressText: placeAddress,
				}),
			};

			if (
				currentLocation ||
				(field !== 'start' &&
					currentTrip.pickupPlace &&
					currentTrip.pickupPlace.currentLocation)
			) {
				const { lat, lng } = GeoService.getCurrentGpsLocationValue() || {};
				if (lat && lng) {
					updatedCurrentTrip.pickupPlace = {
						lat,
						lng,
					};
				}
			}

			if (
				field === 'dropoff' &&
				(!updatedCurrentTrip.pickupPlace || !updatedCurrentTrip.pickupPlaceName)
			) {
				if (
					updatedCurrentTrip.pickupPlaceName === 'Current Location' &&
					!updatedCurrentTrip.pickupPlace
				) {
					// - update toast for set place pickup to detect if name
					// is current location then auto fix instead because disconcerting
					updatedCurrentTrip.pickupPlace = EMPTY_TRIP.pickupPlace;
					updatedCurrentTrip.pickupPlaceName = '';
					updatedCurrentTrip.pickupPlaceAddressText = '';
				} else {
					toastSubscriber(toast.warn('Select a pickup location'));
					setTripFieldSelection('start');
				}
			}

			if (
				field === 'start' &&
				(!updatedCurrentTrip.dropoffPlace ||
					!updatedCurrentTrip.dropoffPlaceName)
			) {
				toastSubscriber(toast.success('Now select a drop off location'));
				setTripFieldSelection('dropoff');
			}

			// console.log(`setPlaceField update:`, {
			// 	field,
			// 	props,
			// 	tripPlaceField,
			// 	tripPlaceFieldName,
			// 	updatedCurrentTrip,
			// });

			setCurrentTrip(updatedCurrentTrip, false);

			if (updatedCurrentTrip.pickupPlace && updatedCurrentTrip.dropoffPlace) {
				console.log(`setPlaceField has both places, requesting quote`, {
					field,
					props,
					updatedCurrentTrip: {
						...updatedCurrentTrip,
					},
					dropoffPlace: updatedCurrentTrip.dropoffPlace,
				});
				// requestQuote(updatedCurrentTrip);
				gotoMoreInfoScreen(updatedCurrentTrip);
			}
		},
		[
			currentTrip,
			deactivatePlaceListState,
			gotoMoreInfoScreen,
			setCurrentTrip,
			setTripFieldSelection,
			toastSubscriber,
		],
	);

	const confirmRideRequest = useCallback(async () => {
		const { isCryptoTrip, isScheduled, estimatedMemberCost, tripId } =
			currentTrip;

		// Allow UI to transition
		setCurrentTrip(
			{
				...currentTrip,
				status: isCryptoTrip
					? TripStatus.PaymentPending
					: isScheduled
					? TripStatus.ScheduledPendingDriverAcceptance
					: TripStatus.PendingDriverAcceptance,
			},
			false,
		);

		// setPanelMaximized(false);

		setNeedMoreInfo(false);

		// updatePanelParams({ confirmRideRequest: 'true' });

		const updatedTrip = await conditionallyHandleErrors(
			ServerStore.AcceptTripQuote(currentTrip.id),
			{ ...currentTrip },
		);
		if (updatedTrip) {
			setCurrentTrip(updatedTrip, false);
		}

		later(async () => {
			// Track the "PURCHASE" event for "Vaya Ride Confirm Quote / Request Ride" conversions
			linkedInInsights('track', {
				conversion_id: 6701801,
			});

			// Track purchase on FB
			fbq('track', 'Purchase', {
				value: estimatedMemberCost,
				currency: 'usd',
				content_ids: tripId,
			});
		});

		return updatedTrip;
	}, [conditionallyHandleErrors, currentTrip, setCurrentTrip]);

	const activateEditState = useCallback(async () => {
		setExplicitTripEditorState(TripEditorModes.EditState);
		setExplicitTripEditorMode(TripEditorModes.FullMode);

		if (tripIsPosted) {
			// When editing after posting the trip, we aren't going to "Confirm" the trip (e.g. re-post),
			// instead we make edits live, and only notify when the user confirms the *EDITS*.
			// If user rejects the edit, then we'll use this snapshot to restore it.
			// This can also be used for change detection to notify relevant parties (server-side)
			await conditionallyHandleErrors(
				ServerStore.StartEditingWhileActive(currentTrip.id),
				{ ...currentTrip },
			);
		} else {
			console.warn(`Trip is not posted, no editing backup cloned`, currentTrip);
		}
	}, [conditionallyHandleErrors, currentTrip, tripIsPosted]);

	const acceptActiveEditChanges = useCallback(async () => {
		const { editingRestoreBackup } = currentTrip;
		if (!editingRestoreBackup || !editingRestoreBackup.id) {
			console.error(
				`Local copy of editingRestoreBackup is invalid, not submitting to server`,
				{ editingRestoreBackup },
			);
			toastSubscriber(
				toast.error("Can't accept trip changes - invalid editing backup"),
			);
		}

		const updatedTrip = await conditionallyHandleErrors(
			ServerStore.AcceptActiveEditChanges(currentTrip.id),
			{ ...currentTrip },
		);

		if (updatedTrip) {
			setCurrentTrip(updatedTrip, false);
			deactivateExplicitTripEditorState();
		}
	}, [
		conditionallyHandleErrors,
		currentTrip,
		deactivateExplicitTripEditorState,
		setCurrentTrip,
		toastSubscriber,
	]);

	const undoActiveEditChanges = useCallback(async () => {
		const { editingRestoreBackup } = currentTrip;
		if (!editingRestoreBackup || !editingRestoreBackup.id) {
			console.error(
				`Local copy of editingRestoreBackup is invalid, not submitting to server`,
				{ editingRestoreBackup },
			);
			toastSubscriber(
				toast.error("Can't undo trip changes - invalid editing backup"),
			);
		}

		// Proactively undo...
		setCurrentTrip(editingRestoreBackup, false);

		const updatedTrip = await conditionallyHandleErrors(
			ServerStore.UndoActiveEditChanges(currentTrip.id),
			{ ...currentTrip },
		);

		if (updatedTrip) {
			setCurrentTrip(updatedTrip, false);
			deactivateExplicitTripEditorState();
		}
	}, [
		conditionallyHandleErrors,
		currentTrip,
		deactivateExplicitTripEditorState,
		setCurrentTrip,
		toastSubscriber,
	]);

	const addPlace = useCallback(
		async (
			placeType,
			{ name, addressText, place } = {
				name: undefined,
				addressText: undefined,
				place: {
					lat: 0,
					lng: 0,
					googlePlaceId: undefined,
				},
			},
		) => {
			const placeStructure = {
				...place,
				name,
				addressText,
			};

			let optimisticTrip;
			if (placeType === 'dropoff') {
				optimisticTrip = {
					...currentTrip,
					dropoffPlaceName: name,
					dropoffPlaceAddressText: addressText,
					dropoffPlace: place,
					dropoffTbd: false,
				};
			} else {
				optimisticTrip = {
					...currentTrip,
					stops: [...currentTrip.stops, placeStructure],
				};
			}

			// Allow UI to transition
			setCurrentTrip(optimisticTrip, false);

			// setPanelMaximized(false);

			const updatedTrip = await conditionallyHandleErrors(
				ServerStore.AddPlace(currentTrip.id, {
					placeType,
					place: placeStructure,
				}),
				{ ...currentTrip },
			);

			if (updatedTrip) {
				setCurrentTrip(updatedTrip, false);
			}
		},
		[conditionallyHandleErrors, currentTrip, setCurrentTrip],
	);

	const submitUserFeedback = useCallback(
		async ({ userDriverRating, userDriverComments, tipAmount }) => {
			// Allow UI to transition
			setCurrentTrip(
				{
					...contextEmptyTrip,
				},
				false,
			);
			// setPanelMaximized(false);
			await conditionallyHandleErrors(
				ServerStore.SubmitUserFeedback(currentTrip.id, {
					userDriverRating,
					userDriverComments,
					tipAmount,
				}),
			);
		},
		[
			conditionallyHandleErrors,
			contextEmptyTrip,
			currentTrip?.id,
			setCurrentTrip,
		],
	);

	const activatePlacesState = useCallback(
		(field) => {
			// Request explicit GPS whenever user hits a place field to ensure
			// (a) the server is updated with latest GPS
			// (b) the places query has latest gps for "nearby" locations
			GeoService.forceRequestSingleLocation();
			console.warn(`activatePlacesState: `, { field });
			if (field) {
				setTripFieldSelection(field);
			}
			setExplicitTripEditorState(TripEditorModes.PlacesState);
		},
		[setTripFieldSelection],
	);

	const nextTripLeg = useMemo(() => {
		if (!tripLegs || !tripLegs.length || !activeLegId) {
			return undefined;
		}

		const currentRef = tripLegs.find((x) => x.id === activeLegId);
		const currentIdx = tripLegs.indexOf(currentRef);
		const nextLeg =
			currentIdx < tripLegs.length ? tripLegs[currentIdx + 1] : undefined;
		return nextLeg;
	}, [activeLegId, tripLegs]);

	const changeDropoff = useCallback(() => {
		activatePlacesState(
			dropoffTbd || !nextTripLeg
				? 'dropoff'
				: {
						leg: nextTripLeg,
						placeType: 'start',
				  },
		);
	}, [activatePlacesState, dropoffTbd, nextTripLeg]);

	const currentPlaceTextFieldValue = useMemo(
		() =>
			currentTrip
				? getPlaceTextForField(currentTrip, placeResultsFromFieldName)
				: '',
		[currentTrip, placeResultsFromFieldName],
	);

	const dispatchError =
		currentTrip &&
		currentTrip.cannotDispatch &&
		currentTrip.cannotDispatchReason;

	const lastDispatchErrorShown = useRef();
	const lastDispatchErrorUpdatedAt = useRef(new Date());
	const dispatchErrorSyncTimer = useRef();
	const dispatchErrorToast = useRef();

	useEffect(() => {
		clearTimeout(dispatchErrorSyncTimer.current);
		dispatchErrorSyncTimer.current = setTimeout(() => {
			// console.warn(`dispatch error debug`, {
			// 	dispatchError,
			// 	updatedAt,
			// 	lastDispatchErrorShown,
			// 	lastDispatchErrorUpdatedAt,
			// });

			const { current: previousMessage } = lastDispatchErrorShown;
			const { current: previousUpdatedAt } = lastDispatchErrorUpdatedAt;
			if (
				dispatchError &&
				previousUpdatedAt !== updatedAt &&
				tripEditorMode === TripEditorModes.FullMode &&
				tripEditorState === TripEditorModes.EditState
			) {
				lastDispatchErrorShown.current = dispatchError;
				lastDispatchErrorUpdatedAt.current = updatedAt;

				const message = `Sorry, Vaya can't dispatch this trip (${dispatchError}) - please contact the Vaya Concierge team at ${formatPhone(
					PublicAppConfig.phoneNumbers.main,
				)} if you feel this message is incorrect or if you'd like further help.`;

				// console.warn(message, { currentTrip });
				dispatchErrorToast.current = toastSubscriber(
					toast.error(message, {
						toastId: `errorAt${updatedAt}`,
						autoClose: 15 * 1000,
					}),
				);

				// console.warn(` +++  Showing Dispatch Error`);
			} else if (
				(previousMessage && !dispatchError) ||
				tripEditorMode !== TripEditorModes.FullMode ||
				tripEditorState !== TripEditorModes.EditState
			) {
				// console.warn(` **** HIDING DISPATCH ERROR`);
				lastDispatchErrorShown.current = undefined;
				lastDispatchErrorUpdatedAt.current = undefined;
				// showToast(undefined);
				if (dispatchErrorToast.current) {
					toast.dismiss(dispatchErrorToast.current);
					dispatchErrorToast.current = undefined;
				}
			}
		}, 333);
	}, [
		currentTrip,
		dispatchError,
		toastSubscriber,
		tripEditorMode,
		tripEditorState,
		updatedAt,
	]);

	// console.log(`TripComposerContext activeTripLeg activeLegId`, {
	// 	activeLegId,
	// 	legStatus,
	// });

	const staticTripPath = useTripPath(
		currentTrip,
		isTripActive
			? // It's safe to not memoize these options since
			  // useTripPath deconstructs them in the function header,
			  // doesn't actually use this object as a discrete value
			  {
					focusLegId: [TripStatus.Riding, TripStatus.DriverAccepted].includes(
						legStatus,
					)
						? activeLegId
						: undefined,
					focusOnLegStart: legStatus === TripStatus.LegDriverArrived,
					hideFocusedLeg:
						// Hide static-rendered leg so we can render with
						// useInjectTrackingPath() instead
						legStatus === TripStatus.Riding,
					focusSizeMiles: 0.125,
					editingWhileActive,
			  }
			: {},
	);

	// Wrap our trip path with a tracking path based on
	// the driver's GPS stream from the useGpsStream() hook (used internally
	// by the tracking path hook)
	const { timeRemaining, ...tripPath } = useInjectTrackingPath(
		staticTripPath, // path to wrap
		currentTrip, // grabs active leg from this
		{
			gpsStreamSource: 'driver', // GPS stream source name to use
			// If editing while active, then show the "full view" of the trip
			useOriginalBounds: editingWhileActive,
		},
	);

	// NOTE: The tracking hook ONLY gives timeRemaining if the leg
	// state is active (e.g. TripStatus.Riding) - otherwise, the timeRemaining will
	// be undefined (if not started) or 0 (if completed)
	const { revisedArrivalTime, timeRemainingMinutes } = useMemo(() => {
		// eslint-disable-next-line no-shadow
		const revisedArrivalTime = new Date(Date.now() + timeRemaining * 1000);
		// eslint-disable-next-line no-shadow
		const timeRemainingMinutes = Math.round(timeRemaining / 60);
		return { revisedArrivalTime, timeRemainingMinutes };
	}, [timeRemaining]);

	// Used for change-detection in ComposerMapWidget
	const pathHasBounds = tripPath && !!tripPath.bounds;

	const rem1 = use1rem();

	const [explicitTopSafeArea, setExplicitTopSafeArea] = useState(undefined);

	const isQuotePanelVisible = useMemo(
		() =>
			tripEditorState === TripEditorModes.EditState &&
			[TripEditorModes.FullMode].includes(tripEditorMode) &&
			currentTrip &&
			(currentTrip.estimatedDriveDistance || currentTrip.dropoffTbd) &&
			(explicitTripEditorMode === TripEditorModes.FullMode ||
				[TripStatus.Draft, TripStatus.Quoted].includes(currentTrip.status)),
		[currentTrip, explicitTripEditorMode, tripEditorMode, tripEditorState],
	);

	const isQuickStartPanelVisible = useMemo(
		() =>
			tripEditorState === TripEditorModes.EditState &&
			[TripEditorModes.SimpleMode].includes(tripEditorMode) &&
			(!currentTrip ||
				!currentTrip?.id ||
				!currentTrip?.dropoffPlaceAddressText) &&
			[TripStatus.Draft, TripStatus.Quoted].includes(currentTrip?.status),
		[currentTrip, tripEditorMode, tripEditorState],
	);

	const isEditState = tripEditorState === TripEditorModes.EditState;
	const isPlacesState = tripEditorState === TripEditorModes.PlacesState;
	const isRidingState = tripEditorState === TripEditorModes.RidingState;

	const closedPanelSize = useMemo(
		() =>
			isRidingState
				? 25 * rem1
				: isQuotePanelVisible || isQuickStartPanelVisible
				? 10.25 * rem1
				: 0,
		[isQuickStartPanelVisible, isQuotePanelVisible, isRidingState, rem1],
	);

	// Used in padding for map
	const defaultTopSafeArea = rem1 * 8;
	const topSafeArea = isRidingState
		? 0
		: explicitTopSafeArea || defaultTopSafeArea;
	// console.log(`topSafeArea:`, {
	// 	topSafeArea,
	// 	explicitTopSafeArea,
	// 	defaultTopSafeArea,
	// 	isRidingState,
	// });

	const mapViewportPadding = useMemo(
		() => ({
			top: topSafeArea,
			bottom: closedPanelSize,
		}),
		[closedPanelSize, topSafeArea],
	);

	const isEditOrPlacesState = [
		TripEditorModes.EditState,
		TripEditorModes.PlacesState,
	].includes(tripEditorState);

	// useEffect(() => {
	// 	console.log(`tripEditorMode & state`, {
	// 		tripIsStarted,
	// 		editingWhileActive,
	// 		tripEditorState,
	// 		tripEditorMode,
	// 		baseEditorStateValidation,
	// 		derivedSimpleModeFlag,
	// 		derivedFullModeFlag,
	// 		explicitTripEditorMode,
	// 		explicitTripEditorState,
	// 		isEditState,
	// 		isPlacesState,
	// 		isRidingState,
	// 		isEditOrPlacesState,
	// 	});
	// }, [
	// 	baseEditorStateValidation,
	// 	derivedFullModeFlag,
	// 	derivedSimpleModeFlag,
	// 	editingWhileActive,
	// 	explicitTripEditorMode,
	// 	explicitTripEditorState,
	// 	isEditOrPlacesState,
	// 	isEditState,
	// 	isPlacesState,
	// 	isRidingState,
	// 	tripEditorMode,
	// 	tripEditorState,
	// 	tripIsStarted,
	// ]);

	// console.log(`tripcomcon loading flag:`, {
	// 	flag: currentTripLoading || !currentTrip || isValidating,
	// 	parts: {
	// 		currentTripLoading,
	// 		notcurrentTrip: !currentTrip,
	// 		isValidating,
	// 	},
	// });

	return (
		<PlaceSearchProvider
			userIdOverride={userIdOverride}
			defaultPlaceText={currentPlaceTextFieldValue}
			fieldType={placeResultsFromFieldName}
			allowDropoffTbd={!tripLegs || !tripLegs.length}
		>
			<MemberTripContext.Provider
				value={{
					isTripActive,

					isEditState,
					isPlacesState,
					isEditOrPlacesState,
					isRidingState,

					revisedArrivalTime,
					timeRemaining,
					timeRemainingMinutes,

					activateEditState,
					undoActiveEditChanges,
					acceptActiveEditChanges,

					currentTrip,
					tripPath,
					pathHasBounds,

					changeDropoff,

					isQuotePanelVisible,
					isQuickStartPanelVisible,
					closedPanelSize,
					mapViewportPadding,

					topSafeArea,
					setExplicitTopSafeArea,

					editingWhileActive,

					tripEditorMode,
					setExplicitTripEditorMode,
					tripEditorState,
					setExplicitTripEditorState,

					activatePlacesState,
					deactivatePlaceListState,

					deactivateExplicitTripEditorState,

					deleteTripLeg,
					createLayoverFromLeg,
					swapDropoffPoints,
					setLayoverMinutes,

					setTripFieldSelection,
					setPlaceField,
					currentPlaceTextFieldValue,
					placeResultsFromFieldName,

					updateCryptoFlag,

					updateScheduledConfig,
					thanksForScheduledRide,

					requestQuote,
					markTripDraft, // for returning from payment pending
					clearCurrentTrip,
					confirmRideRequest,

					cancelAction,

					addPlace,

					submitUserFeedback,

					userIdOverride,
					userOverrideName: !userIdOverride
						? undefined
						: contextUser
						? contextUser.name || contextUser.email
						: null, // `(${userIdOverride})`,
					contextUser,

					setDriverId,
					setAirline,
					setFlightNumber,

					needMoreInfo,
					setNeedMoreInfo,
					gotoMoreInfoScreen,

					currentTripLoading:
						currentTripLoading || !currentTrip || isValidating,

					explicitTripId,
					setExplicitTripId,

					explicitUserId,
					setExplicitUserId,
				}}
			>
				{children}
				<ActionPanel
					controlRef={actionPanelControlRef}
					actions={[
						{
							icon: <EditIcon />,
							label: 'Keep trip as-is',
							onClick: () => actionPanelControlRef.current.close(),
						},
						{
							icon: <CloseIcon />,
							label: 'Cancel Trip',
							onClick: () => {
								actionPanelControlRef.current.close();
								clearCurrentTrip();
							},
						},
					]}
				/>
			</MemberTripContext.Provider>
		</PlaceSearchProvider>
	);
}
