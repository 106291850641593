import { SystemApps } from './SystemApps';

export const MIN_GROUP_TIME = 60 * 30 * 1000;

export const SUPPORT_MESSAGE_ANON_PROPS = {
	anonymizeAs: 'Vaya Concierge Team',
	anonymizePhotoUrl: 'https://app.vayadriving.com/ios/logo76.png',
};

export const ConvoTypes = {
	// These "groups" are leftover from original app, not used AFAIK
	DriverGroup: 'driverGroup',
	MemberGroup: 'memberGroup',
	// Ride-specific "proxy" convo type to let drivers chat with members, and vice versa
	DriverMember: 'driverMember',

	// Broad catch-all conversation type for Organization<>"Public" conversations
	Support: 'support',

	// App-specific conversations
	// Added now that we have two "main" apps that use chat: Rides and Valet,
	// and likely will have more.
	// Rides will continue (for now) to fall into the "Support" type,
	// whereas will Valet, we'll set it to TenantApp and then add references to the convo.
	TenantApp: 'tenant_app',
};

export const isValidConvoType = (type) =>
	Object.values(ConvoTypes).includes(type);

export const ChannelTypes = {
	Auto: 'auto',
	Phone: 'phone',
	SMS: 'sms',
	Email: 'email',
	Web: 'web',
	Bot: 'bot', // TBD...
	// TBD others
};

export const MessageStatus = {
	Draft: 'draft',
	Queued: 'queued',
	Sending: 'sending',
	Sent: 'sent',
	Received: 'received',
	Read: 'read', // TBD if we use this?
	Completed: 'completed', // for bot messages...
	// These are from Twilio,
	// ref: https://support.twilio.com/hc/en-us/articles/223134347-What-are-the-Possible-SMS-and-MMS-Message-Statuses-and-What-do-They-Mean-
	Delivered: 'delivered',
	Undelivered: 'undelivered',
	Failed: 'failed',
};

export const ChatMessageTypes = {
	// TBD other message types...
	// - Should we have a bot type?
	// - Perhaps a "note" type?
	Text: 'text',
	Note: 'note',
};

// export const ChatSystemConfigs = {
// 	ShouldRespondConfig: {
// 		key: 'chat.should-respond-config',
// 		// Must be configured by admin
// 		defaultValue: '',
// 	},

// 	// Replaces/fills-in DEFAULT_TIP_AMOUNTS in ValetTipStartCard.js (client side),
// 	// but has to be provided by server
// 	CustomerTipAmounts: {
// 		key: 'valet.customer_tip_amounts',
// 		defaultValue: [5, 10, 15, 20, 'other'],
// 	},

// 	// For use with scheduling module
// 	WorkWeekStartDay: {
// 		key: 'valet.schedules.work_week_day_start',
// 		defaultValue: 0, // 0 = Sunday
// 	},
// };

// linkedId/linkedType for use with ChatBotDef model for upserting a system chatbot
// that can be used for followup and response suggesting
export const SYSTEM_CHATBOT_TYPE = SystemApps.Chats;
export const SYSTEM_CHATBOT_ID = 'system-chatbot';

export const TelnyxBrandVerticals = [
	'REAL_ESTATE',
	'HEALTHCARE',
	'ENERGY',
	'ENTERTAINMENT',
	'RETAIL',
	'AGRICULTURE',
	'INSURANCE',
	'EDUCATION',
	'HOSPITALITY',
	'FINANCIAL',
	'GAMBLING',
	'CONSTRUCTION',
	'NGO',
	'MANUFACTURING',
	'GOVERNMENT',
	'TECHNOLOGY',
	'COMMUNICATION',
];

export const DefaultTelnyxBrandVertical = 'COMMUNICATION';

export const TelnyxEntityTypes = [
	'PRIVATE_PROFIT',
	'PUBLIC_PROFIT',
	'NON_PROFIT',
	'SOLE_PROPRIETOR',
	'GOVERNMENT',
];

export const DefaultTelnyxEntityType = 'PRIVATE_PROFIT';

export const DefaultTelnyxCampaignUseCase = 'CUSTOMER_CARE';

// This enum generated with telnyx-client.js#getRegistryUseCaseList
export const TelnyxKnownUseCases = {
	'2FA': {
		description:
			'Two-Factor authentication, one-time use password, password reset',
		displayName: '2FA',
		classification: 'STANDARD',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: true,
	},
	M2M: {
		description:
			'Machine to machine is a process that implies wireless communication between two or more physical assets. There is no human interaction in the Machine to Machine campaign. Subscriber-facing campaigns are prohibited',
		displayName: 'Machine to Machine',
		classification: 'STANDARD',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: false,
	},
	MIXED: {
		description:
			'Mixed messaging reserved for specific consumer service industry',
		displayName: 'Mixed',
		classification: 'STANDARD',
		maxSubUsecases: 5,
		minSubUsecases: 2,
		validSubUsecase: false,
	},
	PROXY: {
		description:
			'Peer-to-peer app-based group messaging with proxy/pooled numbers (For example: GroupMe) \r\n' +
			'Supporting personalized services and non-exposure of personal numbers for enterprise or A2P communications.  (Examples include: Uber and AirBnb.)',
		displayName: 'Proxy',
		classification: 'SPECIAL',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: false,
	},
	TRIAL: {
		description:
			'Platform free trial offers with strict controls and MNO audit rights (MO opt in)',
		displayName: 'Platform Free Trial',
		classification: 'SPECIAL',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: false,
	},
	SOCIAL: {
		description:
			"Communication within or between closed communities (For example: influencers' alerts)",
		displayName: 'Social',
		classification: 'SPECIAL',
		maxSubUsecases: 5,
		minSubUsecases: 0,
		validSubUsecase: false,
	},
	CHARITY: {
		description:
			'Includes:  5013C Charity\nDoes not include: Religious organizations ',
		displayName: 'Charity',
		classification: 'SPECIAL',
		maxSubUsecases: 5,
		minSubUsecases: 0,
		validSubUsecase: false,
	},
	EMERGENCY: {
		description:
			'Notification services designed to support public safety / health during natural disasters, armed conflicts, pandemics and other national or regional emergencies',
		displayName: 'Emergency',
		classification: 'SPECIAL',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: false,
	},
	MARKETING: {
		description:
			'Any communication with marketing and/or  promotional content ',
		displayName: 'Marketing',
		classification: 'STANDARD',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: true,
	},
	POLITICAL: {
		description:
			'Part of organized effort to influence decision making of specific group. All campaigns to be verified',
		displayName: 'Political',
		classification: 'SPECIAL',
		maxSubUsecases: 5,
		minSubUsecases: 0,
		validSubUsecase: false,
	},
	UCAAS_LOW: {
		description:
			'UCaaS companies provide cloud delivered communication services for businesses. Each number assigned to a UCaaS campaign is typically assigned to a different employee of that business and the use cases are varied. This use case is not for any API/automated driven communication. This use case is only available to approved UCaaS businesses.',
		displayName: 'UCaaS Low Volume',
		classification: 'SPECIAL',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: false,
	},
	LOW_VOLUME: {
		description:
			'Low throughput, any combination of use-cases. Examples include:  test, demo accounts',
		displayName: 'Low Volume Mixed',
		classification: 'STANDARD',
		maxSubUsecases: 5,
		minSubUsecases: 1,
		validSubUsecase: false,
	},
	SWEEPSTAKE: {
		description: 'Sweepstake',
		displayName: 'Sweepstake',
		classification: 'SPECIAL',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: false,
	},
	UCAAS_HIGH: {
		description:
			'UCaaS companies provide cloud delivered communication services for businesses. Each number assigned to a UCaaS campaign is typically assigned to a different employee of that business and the use cases are varied. This use case is not for any API/automated driven communication.  This is for UCaaS campaigns that require higher volume. This use case is only available to approved UCaaS businesses.',
		displayName: 'UCaaS High Volume',
		classification: 'SPECIAL',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: false,
	},
	FRAUD_ALERT: {
		description: 'Fraud alert notification',
		displayName: 'Fraud Alert Messaging',
		classification: 'STANDARD',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: true,
	},
	CUSTOMER_CARE: {
		description:
			'All customer care messaging, including account management and support',
		displayName: 'Customer Care',
		classification: 'STANDARD',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: true,
	},
	K12_EDUCATION: {
		description:
			'Campaigns created for messaging platforms that support schools from grades K - 12 and distance learning centers. This is not for Post-Secondary schools. This use case requires approval from at least 1 MNO.',
		displayName: 'K-12 Education',
		classification: 'SPECIAL',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: false,
	},
	CARRIER_EXEMPT: {
		description: 'Carrier exempted campaigns',
		displayName: 'Carrier Exemptions',
		classification: 'SPECIAL',
		maxSubUsecases: 5,
		minSubUsecases: 1,
		validSubUsecase: false,
	},
	POLLING_VOTING: {
		description: 'Polling and voting',
		displayName: 'Polling and voting',
		classification: 'STANDARD',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: true,
	},
	SECURITY_ALERT: {
		description:
			'A notification that the security of a system, either software or hardware, has been compromised in some way and there is an action you need to take',
		displayName: 'Security Alert',
		classification: 'STANDARD',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: true,
	},
	SOLE_PROPRIETOR: {
		description: 'Sole Proprietor',
		displayName: 'Sole Proprietor',
		classification: 'SPECIAL',
		maxSubUsecases: 5,
		minSubUsecases: 1,
		validSubUsecase: false,
	},
	HIGHER_EDUCATION: {
		description:
			'For campaigns created on behalf of Colleges or Universities and will also include School Districts etc that fall outside of any "free to the consumer" messaging model',
		displayName: 'Higher Education',
		classification: 'STANDARD',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: true,
	},
	AGENTS_FRANCHISES: {
		description:
			'For brands that have multiple agents, franchises or offices in the same brand vertical, but require individual localised numbers per agent/location/office. Final fees are under review',
		displayName: 'Agents and Franchises',
		classification: 'SPECIAL',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: false,
	},
	ACCOUNT_NOTIFICATION: {
		description:
			'All reminders, alerts, and notifications.  (Examples include:  flight delayed, hotel booked, appointment reminders.)',
		displayName: 'Account Notification',
		classification: 'STANDARD',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: true,
	},
	DELIVERY_NOTIFICATION: {
		description:
			'Information about the status of the delivery of a product or service',
		displayName: 'Delivery Notification',
		classification: 'STANDARD',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: true,
	},
	PUBLIC_SERVICE_ANNOUNCEMENT: {
		description:
			"An informational message that is meant to raise the audience's awareness about an important issue",
		displayName: 'Public Service Announcement',
		classification: 'STANDARD',
		maxSubUsecases: 0,
		minSubUsecases: 0,
		validSubUsecase: true,
	},
};

export const InboundCallActionTypes = {
	AutoReply: 'auto_reply',
	ForwardTo: 'forward_to',
};

export const InboundCallActionOptions = [
	{
		id: InboundCallActionTypes.AutoReply,
		label: 'Auto Reply',
		description: 'Automatically reply to the caller with a message and hang up',
	},
	{
		id: InboundCallActionTypes.ForwardTo,
		label: 'Forward To',
		description:
			'Forward the call to another number, and automatically reply with a text message if no answer',
	},
];

// Inbox with this "type" is what's used / upserted for the tenant if no inbox for the tenant
export const CHAT_INBOX_TENANT_DEFAULT_TYPES = {
	systemApp: SystemApps.Chats,
	linkedType: 'TENANT',
	linkedId: 'DEFAULT',
};

export const ChatInboxTemplateSuggestionTypes = {
	AutoReply: 'auto_reply',
	OffHoursMsg: 'off_hours_msg',
};

export const ConversationListUsageTypes = {
	// This is the "Default" style (inbox for an internal org facing public)
	OrgInternalUsage: 'OrgInternalUsage',
	// This is a "new" type of usage, e.g. for users to chat with other users or a bot, etc
	PersonalInboxUsage: 'PersonalInboxUsage',
	// For admins to "view" into personal inboxes, e.g.
	// viewing a bot inbox - admins can have tabs showing "me" / "others", but non-admins just see "me"
	AdminSpoofUsage: 'AdminSpoofUsage',
};

export const ChatListCategoryFilterTypes = {
	// All chats (no filter at all)
	NoFilter: 'all',
	// Chats where followup is required
	NeedFollowupFilter: 'need_followup',
	// Only chats where I'm the user
	MyChatsFilter: 'my_user',
	// Chats for other people (not current user)
	OtherChatsFilter: 'other_users',
	// Chats where I'm the assigned user
	AssignedToMeFilter: 'assigned_to_me',
	// Chats where I'm not the assigned user
	AssignedToOthersFilter: 'assigned_to_others',
	// Chats where no one is assigned at all (unlikely)
	NotAssignedFilter: 'not_assigned',
};
