/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
// The messages pane and infinite scroll was based on the example
// given in this article: https://medium.com/@tiagohorta1995/dynamic-list-virtualization-using-react-window-ab6fbf10bfb2
import ReactMarkdown from 'react-markdown';
// import AppButton from 'shared/components/AppButton';
import {
	// eslint-disable-next-line camelcase
	unstable_batchedUpdates,
} from 'react-dom';
import { toast } from 'react-toastify';

import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	useMemo,
} from 'react';
import { marked } from 'marked';

import { InView } from 'react-intersection-observer';

import Measure from 'react-measure';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import InputBase from '@material-ui/core/InputBase';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ButtonBase from '@material-ui/core/ButtonBase';
// import Tooltip from '@material-ui/core/Tooltip';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { useAuthorization } from 'shared/services/AuthService';

import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';
import formatTimestamp from 'shared/utils/formatTimestamp';
import { toTitleCase } from 'shared/utils/toTitleCase';
import MoreMenu from 'shared/components/MoreMenu';
import UserAvatar from 'shared/components/UserAvatar';
import normalizePhone from 'shared/utils/normalizePhone';
import styles from './MessageList.module.scss';

import InjectAttachmentUrl, {
	PREVIEW_SOURCE_TYPE,
	scaledSize,
} from '../InjectAttachmentUrl';

const AppButton = (...props) => <ButtonBase {...props} />;

// // Rows will "never" be smaller than this.
// // This value is used to filter out heights we don't consider
// // valid and don't want to keep cached.
// // By filtering out "invalid" row heights, we prevent page jumping
// // when user scrolls immediately all the way to the bottom or
// // just scrolls really fast down the page.
// const MIN_ROW_HEIGHT = 42;

// Remove a row from the DOM if it is off-screen
// for this many milliseconds (~10 sec)
const OUT_OF_VIEW_TIMER = 10000;

const authorChanged = ({ user } = {}, current = {}) => {
	if (!user || !current) {
		return true;
	}
	const { id: otherUserId } = user || {};
	const { id: currentUserId } = current || {};
	return !otherUserId || currentUserId !== otherUserId;
};

const authorChangedDebug = ({ user } = {}, current = {}) => {
	if (!user || !current) {
		return `${!user} || ${!current}`;
	}
	const { id: otherUserId } = user || {};
	const { id: currentUserId } = current || {};
	return `${!otherUserId} || ${currentUserId} !== ${otherUserId}`;
};

const subjectChanged = (
	{ channelData: { subject: previousSubject } = {} } = {},
	subject,
) =>
	!previousSubject ||
	(subject && `${subject}`.replace(/re:\s*/gi, '') !== previousSubject);

// Show timestamp if diff is more than X minutes (15 min right now...)
const TIMESTAMP_CHANGE_VALUE = 1000 * 60 * 15;
const showTimestamp = (
	{ timestamp: previousTimestamp } = {},
	timestamp,
	timeStampChangeValue = TIMESTAMP_CHANGE_VALUE,
) =>
	!previousTimestamp ||
	new Date(timestamp) - new Date(previousTimestamp) > timeStampChangeValue;

const MyLink = React.forwardRef(({ children, ...props }, ref) => (
	<a rel="noopener noreferrer" target="_blank" {...props} ref={ref}>
		{children}
	</a>
));

const Tooltip = ({ title, children }) => (
	<span title={title} data-watch-tooltip>
		{children}
	</span>
);

const RenderBotMessageBlocks = (props) => {
	const {
		message: {
			// id: messageId,
			inbound,
			// text,
			attachments,
			channelType,
			channelData: { subject, from: fromEmail } = {},
			user,
			status,
			// statusMessage,
			timestamp,
			loading,
			isBotMessage,
			messageBlocks,
			botResponseData = {}, // ...
			hasBotInteraction,
		},
		onSendMessage,
		className,
		blocks: inputBlocks,
		gatherBotInput: parentGather,
	} = props || {};

	const [botState, setBotState] = useState({});
	const gatherBotInput = useCallback(
		(args) => {
			if (parentGather) {
				parentGather(args);
				return;
			}

			let { type, inputType, actionId, inputId: field, value } = args;
			if (inputType === 'tel') {
				value = normalizePhone(value);
			}

			const newBotState = { ...botState, [field]: value };
			setBotState(newBotState);

			console.log(`gatherBotInput`, { args, newBotState });

			if (type === 'button') {
				onSendMessage({
					channelType: 'bot',
					text: Object.values(botState).filter(Boolean).join(' / '),
					subject: null,
					botResponseData: {
						...newBotState,
						actionId,
					},
				});
			}
		},
		[botState, onSendMessage, parentGather],
	);

	console.log(`>> rendering msg blocks`, inputBlocks || messageBlocks || [], {
		hasBotInteraction,
	});

	return (
		<div className={clsx(styles.botMessageBlocks, className)}>
			{(inputBlocks || messageBlocks || []).map(
				(
					{
						type,
						text,
						blocks,
						actionId,
						defaultValue,
						placeholder,
						multiline,
						inputType,
						inputId,
						buttonType,
					},
					idx,
				) => {
					const key = [idx, type, text, actionId, inputId].join('');
					if (type === 'section') {
						return (
							<RenderBotMessageBlocks
								{...props}
								key={key}
								className={styles.block}
								blocks={blocks}
							/>
						);
					}

					if (type === 'markdown') {
						return (
							<ReactMarkdown
								key={key}
								className={clsx(
									'break-all-safe',
									styles.block,
									styles.markdown,
								)}
							>
								{text}
							</ReactMarkdown>
						);
					}

					if (type === 'button' && !hasBotInteraction) {
						return (
							<ButtonBase
								key={key}
								className={clsx(
									styles.block,
									'MuiPaper-elevation1',
									buttonType === 'secondary'
										? styles.secondaryCta
										: styles.primaryCta,
								)}
								onClick={() => gatherBotInput({ type, actionId })}
							>
								{text}
								{/* <ReactMarkdown
									className={clsx(styles.buttonLabel, 'break-all-safe')}
								>
									{text || 'Ok'}
								</ReactMarkdown> */}
							</ButtonBase>
						);
					}

					if (type === 'input') {
						if (multiline) {
							return (
								<TextareaAutosize
									key={key}
									maxRows={3}
									placeholder={placeholder}
									defaultValue={
										hasBotInteraction ? botResponseData[inputId] : defaultValue
									}
									onChange={(e) =>
										gatherBotInput({ type, inputId, value: e.target.value })
									}
									disabled={hasBotInteraction}
									className={clsx(styles.block, styles.botInput)}
								/>
							);
						}

						return (
							<InputBase
								key={key}
								variant="filled"
								type={inputType || 'text'}
								placeholder={placeholder}
								defaultValue={
									hasBotInteraction ? botResponseData[inputId] : defaultValue
								}
								onChange={(e) =>
									gatherBotInput({
										type,
										inputType,
										inputId,
										value: e.target.value,
									})
								}
								disabled={hasBotInteraction}
								className={clsx(styles.block, styles.botInput)}
							/>
						);
					}

					return '';
				},
			)}
		</div>
	);
};

const Message = React.forwardRef(
	(
		{
			onSizeChanged = () => {},
			setLastSeenMessage = () => {},
			previousMessage,
			nextMessage,
			lastSeenMessageId,
			currentUserId,
			message,
			onSendMessage,
			message: {
				// id: messageId,
				inbound,
				text,
				attachments,
				channelType,
				channelData: { subject, from: fromEmail } = {},
				user,
				status,
				// statusMessage,
				timestamp,
				loading,
				isBotMessage,
				messageBlocks,
				botResponseData, // ...
			},
		},
		ref,
	) => {
		const { id: messageUserId, isAnonymous: isUserAnonymous } = user || {};

		// console.log(`message rendering debug:`, {
		// 	subject,
		// 	text,
		// 	status,
		// 	user,
		// 	channelType,
		// 	fromEmail,
		// });

		const isSameUser = currentUserId === messageUserId;

		const { name: userName } = user || {};

		const atts = useMemo(() => Array.from(attachments || []), [attachments]);

		const imageAttachments = useMemo(
			() => atts.filter(({ mimeType }) => `${mimeType}`.startsWith('image/')),
			[atts],
		);

		const videoAttachments = useMemo(
			() => atts.filter(({ mimeType }) => `${mimeType}`.startsWith('video/')),
			[atts],
		);

		const fileAttachments = useMemo(
			() =>
				atts.filter(
					({ mimeType }) =>
						!`${mimeType}`.startsWith('image/') &&
						!`${mimeType}`.startsWith('video/'),
				),
			[atts],
		);

		const isNewMessage =
			lastSeenMessageId &&
			previousMessage &&
			previousMessage.id === lastSeenMessageId;

		// const [menuVisible, setMenuVisible] = useState();
		// const toggleMoreMenu = () => {
		// 	setMenuVisible(!menuVisible);
		// };

		// const onMarkUnread = () => {
		// 	// console.log('previous:', previousMessage);
		// 	setLastSeenMessage(previousMessage);
		// };

		// const onDeleteMessage = () => {
		// 	// TODO
		// };

		// console.log({ isNewMessage, lastSeenMessageId, curPrevMsg: previousMessage.id })
		return (
			<div
				ref={ref}
				className={clsx(
					styles.message,
					inbound ? styles.inbound : styles.outbound,
					// TODO: other styles based on status?
				)}
			>
				<Measure bounds onResize={onSizeChanged}>
					{({ measureRef }) => (
						<div className={styles.bodyWrap} ref={measureRef}>
							{isNewMessage ? (
								<div className={styles.newMessage}>
									<div className={styles.label}>Unread</div>
									<div className={styles.line}></div>
								</div>
							) : (
								''
							)}

							{showTimestamp(previousMessage, timestamp) ? (
								<div className={styles.timestampHeader}>
									<div className={styles.label}>
										{/* Sunday, March 27th &middot; 2:30 PM */}
										{formatTimestamp(timestamp)}
									</div>
								</div>
							) : (
								''
							)}

							{loading ? (
								<div className={styles.loading}>
									<CircularProgress />
									<label>Loading more messages ...</label>
								</div>
							) : (
								<div
									className={clsx(
										styles.avatarWrap,
										authorChanged(previousMessage, user) && styles.newAuthor,
										authorChanged(nextMessage, user) && styles.newAuthorEnd,
										showTimestamp(previousMessage, timestamp) &&
											styles.timestampChanged,
									)}
									// onClick={toggleMoreMenu}
									// onMouseLeave={() =>
									// 	setMenuVisible(false)
									// }
									data-prev-msg-debug={`${authorChanged(
										previousMessage,
										user,
									)}=${authorChangedDebug(previousMessage, user)}`}
									data-next-msg-debug={`${authorChanged(
										nextMessage,
										user,
									)}=${authorChangedDebug(nextMessage, user)}`}
								>
									<Tooltip
										title={`${userName} at ${new Date(
											timestamp,
										).toLocaleString()} (${channelType}: ${status})`}
									>
										<div className={styles.avatar}>
											{status !== 'sending' &&
												(showTimestamp(previousMessage, timestamp) ||
													authorChanged(previousMessage, user)) && (
													<UserAvatar
														user={user}
														inbound={inbound}
														className={styles.UserAvatar}
													/>
												)}
										</div>
									</Tooltip>

									<div className={styles.contentWrap}>
										<div
											className={clsx(
												styles.whoAndWhen,
												status !== 'sending' &&
													(showTimestamp(previousMessage, timestamp) ||
														authorChanged(previousMessage, user)) &&
													styles.authorChanged,
											)}
										>
											{showTimestamp(previousMessage, timestamp) ||
											authorChanged(previousMessage, user) ? (
												<>
													{status !== 'sending' && (
														<div className={styles.who}>
															{isSameUser ? (
																'You'
															) : (
																<>
																	{`${userName}`.startsWith(`User `) ||
																	!userName
																		? 'Anonymous'
																		: userName}
																</>
															)}
														</div>
													)}
													<Tooltip
														title={
															timestamp
																? new Date(timestamp).toLocaleString()
																: 'New'
														}
													>
														<div className={styles.when}>
															{formatTimestamp(timestamp, {
																timeOnly: true,
															})}
														</div>
													</Tooltip>
												</>
											) : (
												''
											)}
											<Tooltip
												title={`${status || channelType || 'New'}${
													status === 'error' && status ? `: ${status}` : ''
												}`}
											>
												<div className={styles.channel}>
													{(channelType &&
														channelType !== 'auto' &&
														`(${channelType})`) ||
														''}
													{status === 'sending'
														? ' Sending...'
														: inbound && status === 'sent'
														? ''
														: ` ${toTitleCase(status)}`}
												</div>
											</Tooltip>
										</div>

										{subject &&
										(authorChanged(previousMessage, user) ||
											subjectChanged(previousMessage, subject)) ? (
											<div className={styles.subject}>Subject: {subject}</div>
										) : (
											''
										)}

										{channelType === 'email' && fromEmail ? (
											<div className={styles.fromEmail}>From: {fromEmail}</div>
										) : (
											''
										)}

										<Tooltip
											title={`${userName} at ${new Date(
												timestamp,
											).toLocaleString()} (${channelType}: ${status})`}
										>
											<div className={styles.textContentWrap}>
												<div className={styles.mediaAttachments}>
													{imageAttachments.map(
														({
															id,
															name,
															width,
															height,
															sources: {
																[PREVIEW_SOURCE_TYPE]: {
																	width: previewWidth,
																	height: previewHeight,
																} = {},
															} = {},
														}) => (
															<InjectAttachmentUrl
																key={id}
																id={id}
																name={name}
																render={({
																	url,
																	previewUrl,
																	onPreviewError,
																}) => (
																	<a
																		href={url}
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		<img
																			alt={name}
																			onError={onPreviewError}
																			src={previewUrl}
																			width={scaledSize(
																				previewWidth || width,
																				previewHeight || height,
																				'width',
																			)}
																			height={scaledSize(
																				previewWidth || width,
																				previewHeight || height,
																				'height',
																			)}
																		/>
																	</a>
																)}
															/>
														),
													)}
													{videoAttachments.map(({ id, name }) => (
														<InjectAttachmentUrl
															key={id}
															id={id}
															name={name}
															render={({ url, onError }) => (
																<video
																	alt={name}
																	src={url}
																	onError={onError}
																	// Not using scaledSize() helper with videos
																	// because we want to maintain landscape A/R
																	// so that the browser video
																	// controls render completely
																	width={240 * 1.7}
																	height={240}
																	controls={true}
																/>
															)}
														/>
													))}
												</div>
												{isBotMessage &&
												messageBlocks &&
												messageBlocks.length ? (
													<RenderBotMessageBlocks
														onSendMessage={onSendMessage}
														message={message}
													/>
												) : (
													''
												)}
												{(!isBotMessage ||
													!messageBlocks ||
													!messageBlocks.length) &&
												text ? (
													<div className={styles.textContent}>
														{channelType === 'email' && !inbound ? (
															<div
																className={styles.emailBodyHtml}
																dangerouslySetInnerHTML={{
																	__html: marked(text),
																}}
															/>
														) : (
															<div className={styles.emailBodyHtml}>
																<p>{text}</p>
															</div>
														)}
													</div>
												) : (
													''
												)}
											</div>
										</Tooltip>

										{fileAttachments.length ? (
											<div className={styles.fileAttachments}>
												{fileAttachments.map(({ id, name }) => (
													<InjectAttachmentUrl
														key={id}
														id={id}
														name={name}
														render={({ url }) => (
															<ButtonBase
																className={styles.attachment}
																key={id}
																alt={name}
																href={url}
																component={MyLink}
															>
																<div className={styles.icon}>
																	<AttachFileIcon />
																</div>
																<span className={styles.label}>{name}</span>
															</ButtonBase>
														)}
													/>
												))}
											</div>
										) : (
											''
										)}
									</div>

									{/* <MoreMenu
										className={clsx(
											styles.moreMenu,
											menuVisible &&
												styles.forceVisible,
										)}
										tooltip="Actions related to this message"
										actions={[
											{
												text: 'Mark unread from here',
												onClick: onMarkUnread,
											},
											{
												text: 'Delete Message',
												onClick: onDeleteMessage,
											},
										]}
									/> */}
								</div>
							)}
						</div>
					)}
				</Measure>
			</div>
		);
	},
);

/**
 * Component to contain all the logic for a single message.
 *
 * Responsible for it's own "in view" / "out of view" state which is set on a timer (see comments below).
 */
const MessageWrapper = React.forwardRef(
	(
		{ inView, ...props },
		// Ref is needed for the intersection observer
		ref,
	) => {
		// Using this `inViewState` to sort of "buffer" the inView prop.
		// Whereas the `inView` prop is the literal onscreen/offscreen flag
		// from the intersection observer, we want to be a bit more subtle in
		// when we show/hide the row.
		//
		// What we're doing here is as soon as the `inView` prop goes high
		// (the row comes on screen), we set the `inViewState` true to show the row.
		// I.e. the row gets shown immediately as soon as it comes on screen.
		// Sort of an obvious thing to do, but yeah...
		//
		// Where this really comes into play is a subtle value-add when the user
		// scrolls a row *OFF* screen. Instead of dropping the `inViewState` to false
		// as soon as the `inView` prop goes false, we wait a beat (technically,
		// we wait OUT_OF_VIEW_TIMER milliseconds - around 5 sec as of this writing.)
		// Once the timer expires, THEN we actually remove the row from the DOM (e.g.
		// set `inViewState` to false.)
		//
		// That's all well and good - but if the user decides to reverse course and
		// scroll the row BACK on screen before the timer expires - that's when this
		// really helps to make the experience better. In this case, if the user
		// scrolls the row back on screen before the timer goes off, the timer
		// is canceled and the row is never hidden - making the row appear instantly
		// without fade in. Of course, if the user scrolls it off screen again and
		// doesn't bring it back again after 5 sec-ish, the row really is removed
		// via `inViewState === false`.
		const [inViewState, setInViewState] = useState(inView);
		// Store the "was in view" flag so that we "never" remove the slides
		// because Flickity can't handle it - ref https://github.com/yaodingyd/react-flickity-component/issues/41
		const wasInViewRef = useRef(inView);
		const outOfViewTimer = useRef();
		useEffect(() => {
			if (inView && !inViewState) {
				setInViewState(inView);
				wasInViewRef.current = inView;
			}

			clearTimeout(outOfViewTimer.current);
			// Temporarily commenting this out. Something has changed
			// within the component library that causes this to throw
			// `DOMException: Failed to execute removeChild`
			if (!inView && inViewState) {
				outOfViewTimer.current = setTimeout(() => {
					setInViewState(inView);
				}, OUT_OF_VIEW_TIMER);
			}
			return () => {
				clearTimeout(outOfViewTimer.current);
			};
		}, [inView, inViewState]);

		// I discovered a bug in the global `rowHeight` state shared among all rows:
		// >>>> When someone clicks the `View All` in a single row, it causes that
		//		row's height to get bigger (obviously.) Well, when a single rowHeight
		//		is shared among all rows, then making one row suddenly ~3000px high (etc)
		//		causes ALL rows to grow to 3000px high - and that would then cause
		//		giant layout shift, which would push that row (the one that the user
		//		clicked "View All" on) out of view - which would remove it from the DOM,
		//		and therefore loosing the "View All" state, collapsing back down to the
		//		smaller row height, and therefore, causing the layout to shift back up,
		//		bringing the row BACK into view, but WITHOUT "View All" enabled.
		//		Talk about bad user experience...
		// Therefore, I've separated out the concept of the "global row height"
		// from a single row's height. This is based on some assumptions:
		//	* All rows will receive an `onResize` callback below immediately when they render
		//  * All rows will resize when the viewport/window resizes
		//  * No row really cares what another row's height is
		//  * All rows will change position automatically based on the height of rows above it
		// Therefore, we can safely "cache" the "correct" starting row height
		// in a local state variable (`selfRowHeight`) and mutate that at will without
		// affecting ALL other rows on the page. This basically establishes the "baseline"
		// row height when the page renders so all rows share the same starting height
		// (see useEffect below when selfRowHeight is undefined), and then when the viewport
		// resizes, we listen for that event below and set our OWN row height back to undefined,
		// so that we can re-establish the baseline row height when viewport is resized for all rows.
		// Based on this change (selfRowHeight vs global rowHeight), this does resolve the issue
		// above and properly allows clicking "View All" without the jump
		const [selfRowHeight, setSelfRowHeight] = useState(undefined);
		// const rowHeightRef = useRef(undefined);

		return (
			<div
				ref={ref}
				className={clsx(styles.messageWrap, inViewState && styles.fadeinAnim)}
				style={{
					'--row-height': `${selfRowHeight || 32}px`,
					// '--row-height': `${rowHeightRef.current || 64}px`,
				}}
				id={`message-${props.message.id}`}
			>
				{inViewState && (
					<Message
						{...props}
						onSizeChanged={(contentRect) => {
							const height = Math.ceil(contentRect.bounds.height);
							if (height === undefined) {
								return;
							}

							// Adjust our OWN row height (in case someone clicked View All)
							// See notes above.
							unstable_batchedUpdates(() => {
								setSelfRowHeight(height);
							});
							// rowHeightRef.current = height;
						}}
					/>
				)}
			</div>
		);
	},
);

const MessageList = ({
	lightMode,
	messages,
	onMoreMessagesNeeded,
	hasMoreMessages,
	// lastSeenMessageId,
	// just for testing
	// id: 'b80c87bd-2edf-4bae-8975-04f68f92d328'
	onLastSeenChanged,
	onSendMessage,
}) => {
	// References
	const listRef = useRef({});
	const innerListRef = useRef();
	const userHasScrolled = useRef(false);
	const autoScrollRef = useRef(false);

	// This guard basically means we only will mark the list read when mounted,
	// not on rerender. It will NEXT mark read ONLY if the component unmounts
	// completely (erasing the ref) and remounts
	const hasMarkedRead = useRef(false);
	useEffect(() => {
		const { current: flag } = hasMarkedRead;
		if (!flag && messages && messages.length) {
			const lastMessage = messages[messages.length - 1];
			const { temporaryMessage } = lastMessage || {};
			// It could be a temp message IF they JUST sent it,
			// and we haven't received the full reply back from the server.
			// Therefore, don't mark the temp message read (because the ID
			// we generate for these temp messages client side does not exist
			// on the server, so would only generate useless errors)
			if (!temporaryMessage) {
				onLastSeenChanged();
				hasMarkedRead.current = true;
			}
		}
	}, [messages, onLastSeenChanged]);

	const scrollToBottom = useCallback(() => {
		if (listRef.current && innerListRef.current) {
			autoScrollRef.current = true;
		}
	}, [innerListRef, listRef]);

	useEffect(() => {
		let tid;
		// TODO:   Maintain scroll position when new messages added
		if (messages.length > 0) {
			scrollToBottom();
		} else {
			// console.log(`MessageList effect: no messages`);
		}

		return () => clearTimeout(tid);
	}, [innerListRef, messages, scrollToBottom]);

	// const setLastSeenMessage = (message) => {
	// 	if (onLastSeenChanged) {
	// 		onLastSeenChanged(message, { manual: true });
	// 	}
	// };

	const previousScrollTop = useRef(0);
	const onScroll = useCallback(() =>
		// evt,
		// {
		// 	// scrollDirection,
		// 	// scrollOffset,
		// 	// scrollUpdateWasRequested,
		// } = {},
		{
			const scrollUpdateWasRequested = !autoScrollRef.current;
			autoScrollRef.current = false;
			const scrollOffset = listRef.current.scrollTop;
			const scrollDirection =
				scrollOffset - previousScrollTop.current < 0 ? 'backward' : 'forward';
			previousScrollTop.current = scrollOffset;

			const scrollHeight = innerListRef.current.scrollHeight || -1;
			const scrollProgress =
				scrollOffset / (scrollHeight - listRef.current.clientHeight);

			// console.log(`scroll event:`, {
			// 	scrollUpdateWasRequested,
			// 	scrollOffset,
			// 	scrollDirection,
			// 	scrollProgress,
			// });

			if (!userHasScrolled.current && scrollUpdateWasRequested) {
				userHasScrolled.current = true;
				// console.warn(`* User interaction, not forcing scroll to bottom now`);
				if (onLastSeenChanged) {
					onLastSeenChanged(messages[messages.length - 1]);
				}
			}

			if (
				scrollDirection === 'backward' &&
				Math.abs(scrollProgress) > 0.75 &&
				hasMoreMessages &&
				onMoreMessagesNeeded
			) {
				onMoreMessagesNeeded();
			}
		}, [
		hasMoreMessages,
		innerListRef,
		// listOuterHeight,
		messages,
		onLastSeenChanged,
		onMoreMessagesNeeded,
	]);

	const {
		user: { id: currentUserId },
	} = useAuthorization();

	// const onClick = useCallback((evt) => {
	// 	const { target } = evt;
	// 	console.log(`clicked:`, target);
	// }, []);

	// const onMouseEnter = useCallback((evt) => {
	// 	const { target } = evt;
	// 	console.log(`entered:`, target);
	// }, []);
	// const onMouseLeave = useCallback((evt) => {
	// 	const { target } = evt;
	// 	console.log(`left:`, target);
	// }, []);

	// const timerRef = useRef();
	// const lastTitle = useRef();
	// const toasterId = useRef();
	// const onMouseMove = useCallback((evt) => {
	// 	// const { target } = evt;
	// 	// clearTimeout(timerRef.current);
	// 	// timerRef.current = setTimeout(() => {
	// 	// 	const tooltipEl =
	// 	// 		target && target.closest('[data-watch-tooltip]');
	// 	// 	if (!tooltipEl) {
	// 	// 		toast.dismiss(toasterId.current);
	// 	// 		return;
	// 	// 	}
	// 	// 	const title = tooltipEl.getAttribute('title');
	// 	// 	if (lastTitle.current !== title) {
	// 	// 		toast.dismiss(toasterId.current);
	// 	// 		toasterId.current = toast.info(title, {
	// 	// 			autoClose: false,
	// 	// 			hideProgressBar: true,
	// 	// 		});
	// 	// 	}
	// 	// }, 100);
	// 	// console.log(`move:`, target);
	// }, []);

	// console.log(`[MessageList] Render:`, messages);

	return (
		<div
			className={clsx(styles.scrollContainer, 'MessageList-scrollContainer')}
			ref={listRef}
			onScroll={onScroll}
			// onClick={onClick}
			// onMouseEnter={onMouseEnter}
			// onMouseLeave={onMouseLeave}
			// onMouseMove={onMouseMove}
		>
			<div
				// className={clsx(styles.messagesList, styles.messengerStyle)}
				className={clsx(
					styles.messagesList,
					styles.slackStyle,
					lightMode && styles.lightMode,
				)}
				ref={innerListRef}
			>
				{Array.from(messages || []).map((message, index) => (
					<InView key={`${message.id}${index}`}>
						{({ inView, ref }) => (
							<MessageWrapper
								ref={ref}
								currentUserId={currentUserId}
								inView={inView}
								message={message}
								previousMessage={messages[index - 1]}
								nextMessage={messages[index + 1]}
								onSendMessage={onSendMessage}
								// lastSeenMessageId={lastSeenMessageId}
								// setLastSeenMessage={setLastSeenMessage}
							/>
						)}
					</InView>
				))}
			</div>
		</div>
	);
};

export default MessageList;
