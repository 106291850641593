/* eslint-disable no-unused-vars, no-console */
import FunctionalState from 'shared/components/FunctionalState';
import { useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { defer } from 'shared/utils/defer';
import normalizeLatLng from 'shared/utils/normalizeLatLng';
import TripStatus from 'shared/utils/TripStatus';
import AppConfig from 'shared/config-public';
import BackendService from 'shared/services/BackendService';
import { ServerStore, transactionPrefix } from 'shared/services/ServerStore';
import { Capacitor } from '@capacitor/core';
import use1rem from 'shared/utils/use1rem';
import { later } from 'shared/utils/later';
import { useAuthorization } from 'shared/services/AuthService';
import { datadogLogs } from './DatadogBrowserLogs';
import { getTrackingIcon, trackingIconProps } from '../utils/mapIconGenerators';
import { GpsLogger } from '../utils/GpsLogger';

import GpsStreamTransmitter from '../utils/GpsStreamTransmitter';
import { RideStatus } from '../utils/RideStatus';

// Assumes window.google has loaded
export const toLatLngRef = ({ lat, lng }) =>
	new window.google.maps.LatLng(lat, lng);

// When matching a given GPS reading to a path, if the nearest point matched
// is more than  SNAP_DISTANCE (meters) away from the path,
// render the tracking marker at the actual GPS reading - otherwise, the tracking
// marker will be rendered at a properly-interpolated position on the path
const SNAP_DISTANCE = 50; // meters

// Basic FPS time to update tracking based on last known speed and position
const ANIM_UPDATE_SPEED = 1000 / 5;

// Used when managing the viewport
const VIEWPORT_PADDING = 32; // px

// When tracking/animating GPS location on a path,
// only use the next X points instead of the whole (potentially VERY LONG) path
const PATH_VP_POINTS = 25;

// Since it takes 1-5 seconds for initial location to load from browser/native,
// we don't want to jump EVERY TIME back and forth between GeoIP (rough)
// and browser (specific) locations, so once we have a browser location,
// cache last location for next page page load and use that for initial pre-gps-bootup
// location instead of GeoIP. Then GeoIP will only be used for users pre-consent or
// pre-first-GPS reading, and last-known location will be used for all other users
const LOCATION_CACHE_KEY = '@rubber/location-cache';

// Used for adding spacing to either side of the single marker location
// in GeoService.updateSingleMarkerLocation()
const gridSize = 1 / 4; // 24; // miles
const radiusConversion = 69.0;

// Latitude-to-miles ratio is consistent, whereas
// longitude-to-miles depends on the latitude of the point
export const latGridSize = gridSize / radiusConversion;

const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;
export const getLngGridSize = (lat) =>
	gridSize / (radiusConversion * Math.cos(degreesToRadians(lat)));

const TIMESTAMP_ESTIMATE_CUTOFF = 10 * 1000;
export const estimateSpeed = (
	{ timestamp: ts1, lat: lat1, lng: lng1 },
	{ timestamp: ts2, lat: lat2, lng: lng2 },
) => {
	if (ts1 && ts2 && window.google) {
		const msDiff = ts2 - ts1;
		if (msDiff < TIMESTAMP_ESTIMATE_CUTOFF) {
			const metersTraveled =
				window.google.maps.geometry.spherical.computeDistanceBetween(
					toLatLngRef({ lat: lat1, lng: lng1 }),
					toLatLngRef({ lat: lat2, lng: lng2 }),
				);

			const metersPerSecond = metersTraveled / (msDiff / 1000);

			return metersPerSecond;
		}
	}

	return undefined;
};

export default class GeoService {
	static singleMarkerLocation = new FunctionalState();

	static trackingMapValues = new FunctionalState();

	static currentGpsLocation = new FunctionalState({
		lat: 0,
		lng: 0,
	});

	// Deprecated
	// static currentPlaceInfo = new FunctionalState();

	static currentViewport = new FunctionalState(null, (newValue) => {
		// console.warn(`currentViewport changed:`, newValue);
	});

	static currentHeatmap = new FunctionalState([], (newValue) => {
		// console.log(`currentHeatmap changed:`, newValue);
	});

	// GPS or Directions/Tracking
	static viewportSource = 'gps';

	// GpsLogger handles the interfacing with browser or native GPS plugins
	static gpsLogger = GpsLogger.instance;

	static permissionDialogPromiseNeededState = new FunctionalState(null);

	static gpsErrorState = new FunctionalState(null, (state) => {
		const { message, context } = state || {};
		const { error } = context || {};

		if (GeoService.verboseLogging) {
			console.warn(`GPS Error state:`, message, context);
		}

		if (
			message === undefined ||
			(error === 'synthetic-https-error' && AppConfig.buildEnv !== 'prod')
		) {
			// Don't show the alert on https errors outside of prod, just
			// console.warn for debugging
			if (AppConfig.buildEnv !== 'prod') {
				// console.warn(
				// 	`GPS Error, but not in prod, so not showing to user:`,
				// 	message,
				// );
			}
			return;
		}

		if (
			message ===
			'Unable to connect to GPS in your browser (Geolocation has been disabled in this document by permissions policy.)'
		) {
			// We encounter this when being iframed by Monday.com
			// Don't show the alert on https errors outside of prod, just
			// console.warn for debugging
			if (GeoService.verboseLogging) {
				console.warn(`Possible iframing, cannot read GPS:`, message);
			}
			return;
		}

		if (`${message}`.includes('denied')) {
			// Don't alert, since not walking thru users how to change denied right now
			return;
		}

		if (message === undefined || `${message}` === 'undefined') {
			// Not useful to alert with undefined messages
			return;
		}

		if (this.gpsLoggerExpectedActive) {
			if (this.alreadyAlertedOnGpsLoggerError) {
				return;
			}

			this.alreadyAlertedOnGpsLoggerError = true;

			// 			// eslint-disable-next-line no-alert
			// 			window.alert(`-----------------------------------------------------

			// Sorry, we can't read your GPS:
			//     ${message}

			// GPS is critical to the proper working of this app.

			// Please try uninstalling and reinstalling the app.

			// -----------------------------------------------------`);
		}
	});

	static gpsInteractionNeeded = new FunctionalState(null);

	static async start({ requireGoogle = false } = {}) {
		if (this.started) {
			// console.log(
			// 	`GeoService.start already started, returning init promise`,
			// 	this.initPromise,
			// );
			return this.initPromise;
		}

		// console.log(`>>> GeoService.start(): mark -1`);

		this.started = true;

		this.initPromise = defer();

		let google;
		if (requireGoogle) {
			google = await this.checkForGoogle();

			// console.log(`>>> GeoService.start(): mark -2`);

			// console.log(`Creating this.directions...`);
			this.geocoder = new google.maps.Geocoder();
			this.directions = new google.maps.DirectionsService();

			// PlacesService has to have a map...why?
			this.backgroundMap = new google.maps.Map(document.createElement('div'));
			this.places = new google.maps.places.PlacesService(this.backgroundMap);

			this.placesAutocompleteService =
				new google.maps.places.AutocompleteService();
		}

		// console.log(`>>> GeoService.start(): mark -3`);
		this.gpsLogger.ifPermissionNeeded(async () => {
			const promise = defer();
			// This state handled by GpsPermissionRequestDialog
			this.permissionDialogPromiseNeededState.setState(promise);
			const result = await promise;
			this.permissionDialogPromiseNeededState.setState(null);
			return result;
		});

		this.gpsLogger.onLoggerInvalidated((message, context) => {
			this.gpsErrorState.setState(message ? { message, context } : null);
		});

		this.gpsLogger.onLoggerRestored(() => {
			this.gpsErrorState.setState(null);
		});

		this.gpsLocationReceivedForErrorChecking = {};
		this.firstGpsLocation = true;
		this.gpsLogger.on(GpsLogger.LOCATION_CHANGED, (updatedLocation) => {
			// console.log(`** got new gps:`, {
			// 	gps: updatedLocation,
			// 	first: this.firstGpsLocation,
			// });
			this.gpsLocationReceivedForErrorChecking = {
				updatedLocation,
				timestamp: Date.now(),
			};

			// Log success for deadman
			if (this.triedRestartingForGpsErrors) {
				// datadogLogs.logger.debug(
				// 	`gps.timeout: Received a location reading after restarting logger`,
				// 	this.gpsLocationReceivedForErrorChecking,
				// );
				if (GeoService.verboseLogging) {
					console.log(
						`gps.timeout: Received a location reading after restarting logger`,
						this.gpsLocationReceivedForErrorChecking,
					);
				}
			}

			this.updateCurrentGpsLocation({
				...updatedLocation,
				// We want the first "accurate" location geocoded, but don't geocode every update
				noGeocode: !this.firstGpsLocation,
			});

			const { userGpsTrackingEnabled, updateDirectionsTrackingProgress } = this;
			// console.log(`[LOCATION_CHANGED]`, {
			// 	userGpsTrackingEnabled,
			// 	updateDirectionsTrackingProgress,
			// 	updatedLocation,
			// });

			if (!userGpsTrackingEnabled) {
				this.stopWatchingGpsResultsForErrors();
				// this.gpsLogger.stop();
			}
			// else if (updateDirectionsTrackingProgress) {
			// 	const { previousTrackingRef = {} } = this;
			// 	const currentLocation = {
			// 		timestamp: Date.now(),
			// 		...updatedLocation,
			// 	};

			// 	// /**
			// 	//  * By using an "understimation" factor, we keep the users's track from
			// 	//  * jumping backwards if we overestimate the speed and then receive a new
			// 	//  * update from the device
			// 	//  */
			// 	// const underEstimateFactor = 0.99;
			// 	// const speed =
			// 	// 	// .speed prop could come from native plugin, otherwise we must estimate from timestamps - speed from native plugin is in meters/sec as well, so compat with estimation code
			// 	// 	updatedLocation.speed ||
			// 	// 	estimateSpeed(currentLocation, previousTrackingRef) *
			// 	// 		underEstimateFactor;

			// 	// Disabling speed for now so we don't animate to see if that improves
			// 	// native update performance
			// 	const speed = 0;

			// 	this.previousTrackingRef = currentLocation;

			// 	if (
			// 		Capacitor.getPlatform() === 'web' ||
			// 		`${updatedLocation.sensor}`.endsWith('.native')
			// 	) {
			// 		this.updateTrackingProgressFromGps({
			// 			speed,
			// 			...updatedLocation,
			// 		});
			// 	}
			// }

			this.firstGpsLocation = false;
		});

		// Valid user gesture events according to https://html.spec.whatwg.org/multipage/interaction.html#user-activation-processing-model
		const userInteractionEvents = [
			'click',
			'contextmenu',
			'dblclick',
			'mouseup',
			// 'pointerup',
			'reset',
			'submit',
			// 'touchend',
		];

		// console.log(`>>> GeoService.start(): mark 1`);

		// Don't block this service start (GeoService) by waiting for the GpsLogger
		// because it could be waiting for browser permission, or it anything else
		const startCallback = () => {
			// console.log(`>>> GpsLogger start triggered ...`);
			this.startWatchingGpsResultsForErrors();
			this.gpsLogger.start().then((result) => {
				// console.log(`GpsLogger start result:`, result);
				if (result && result.error) {
					console.error(`GpsLogger start error:`, result);
				} else {
					this.gpsInteractionNeeded.setValue(null);
				}
			});

			// this.gpsLogger.startGpsReplay(1000);
			userInteractionEvents.forEach((eventType) =>
				document.body.removeEventListener(eventType, startCallback),
			);
		};

		// console.log(`>>> GeoService.start(): mark 2`);
		window.GpsLogger = this.gpsLogger;

		// Start GPS on click because otherwise we get an error from Chrome:
		// "[Violation] Only request geolocation information in response to a user gesture."
		if (this.gpsLogger.hasUserConsent()) {
			// console.log(`>>> GeoService.start(): mark 3`);
			startCallback();
		} else {
			if (GeoService.verboseLogging) {
				console.log(
					`GeoService: Attaching event listeners to start GPS on user interaction`,
				);
			}
			userInteractionEvents.forEach((eventType) =>
				// NOTE: This VERY LIKELY will trigger `[Violation]` warnings in the console, just FYI.
				// Something like "Added non-passive event listener to a scroll-blocking
				// 'mousewheel' event. Consider marking event handler as 'passive' to make
				// the page more responsive." Just ignore the warning.
				document.body.addEventListener(eventType, startCallback, true),
			);

			this.gpsInteractionNeeded.setValue({
				startCallback,
			});
		}

		// console.log(`>>> GeoService.start(): mark 4`);
		// if (Capacitor.getPlatform() === 'web') {
		// 	console.log(
		// 		`GeoService: Running on web, so attaching event listeners to start GPS on user interaction`,
		// 	);
		// 	userInteractionEvents.forEach((eventType) =>
		// 		// NOTE: This VERY LIKELY will trigger `[Violation]` warnings in the console, just FYI.
		// 		// Something like "Added non-passive event listener to a scroll-blocking
		// 		// 'mousewheel' event. Consider marking event handler as 'passive' to make
		// 		// the page more responsive." Just ignore the warning.
		// 		document.body.addEventListener(eventType, startCallback, true),
		// 	);

		// 	this.gpsInteractionNeeded.setValue({
		// 		startCallback,
		// 	});
		// } else if (this.gpsLogger.hasUserConsent()) {
		// 	startCallback();
		// } else {
		// 	this.gpsInteractionNeeded.setValue({
		// 		startCallback,
		// 	});
		// }

		const cachedLocation =
			window.localStorage.getItem(LOCATION_CACHE_KEY) || '';

		const [lat, lng, accuracy] = `${cachedLocation}`
			.split(',')
			.map((x) => parseFloat(x))
			.filter((x) => !Number.isNaN(x));

		if (lat && lng) {
			this.updateCurrentGpsLocation({
				lat,
				lng,
				accuracy,
			});
		} else if (AppConfig.buildEnv !== 'dev') {
			// While starting browser/native GPS,
			// use rough GeoIP location to populate location
			this.getGeoIpLocation();
		}

		// console.log(`>>> GeoService.start(): mark 5`);
		this.initPromise.resolve(google);
		return this.initPromise;
	}

	static async forceRequestSingleLocation() {
		this.firstGpsLocation = true;
		this.gpsLogger.setConsentFlag(true);
		// if (this.gpsLogger.hasUserConsent()) {
		this.startWatchingGpsResultsForErrors();
		if (!this.gpsLogger.isStarted()) {
			if (GeoService.verboseLogging) {
				console.log(
					`GeoService: forceRequestSingleLocation so calling .start()`,
				);
			}
			return this.gpsLogger.start();
		}

		if (GeoService.verboseLogging) {
			console.log(
				`GeoService: GPS already started, so forceRequestSingleLocation calling requestNewLocation`,
			);
		}
		return this.gpsLogger.requestNewLocationReading().catch((ex) => {
			console.warn(
				`[forceRequestSingleLocation] Error requesting new location reading:`,
				ex,
			);
		});

		// } else {
		// 	console.warn(
		// 		`GeoService: forceRequestSingleLocation so calling .upgradeToAlwaysAllow()`,
		// 	);
		// 	this.gpsLogger.upgradeToAlwaysAllow();
		// }
	}

	static async setUserGpsTrackingEnabled({
		trackingEnabled: flag,
		updateDirectionsTrackingProgress = false,
		updateSingleMarker = false,
	}) {
		const { userGpsTrackingEnabled } = this;

		this.userGpsTrackingEnabled = flag;
		this.updateDirectionsTrackingProgress =
			flag && updateDirectionsTrackingProgress;

		// this.updateSingleMarker = flag && updateSingleMarker;
		this.updateSingleMarker = updateSingleMarker;

		if (!updateDirectionsTrackingProgress) {
			this.stopAnimation();
		}

		// if (flag && updateSingleMarker) {

		// } else {

		// }

		if (userGpsTrackingEnabled === flag) {
			return;
		}

		await this.setGpsLoggerState(flag);

		if (flag) {
			// If we're enabling tracking, we also know we want to run in the background, so show the dialog
			this.gpsLogger.upgradeToAlwaysAllow();
		}
	}

	static setGpsLoggerState(flag) {
		if (flag) {
			if (!this.gpsInteractionNeeded.getValue()) {
				// Only start here if we haven't decided we need to wait for interaction.
				// console.log(
				// 	`GeoService: setUserGpsTrackingEnabled with flag is true, so calling .start()`,
				// );

				if (GeoService.verboseLogging) {
					console.log(
						`GeoService.setGpsLoggerState(true) - starting (no interaction needed)`,
					);
				}

				// Used to detect if we should display errors
				this.startWatchingGpsResultsForErrors();

				return this.gpsLogger.start();
			}
			if (GeoService.verboseLogging) {
				console.log(
					`GeoService.setGpsLoggerState(true) - not calling start, this.gpsInteractionNeeded=`,
					this.gpsInteractionNeeded.getValue(),
				);
			}
		} else {
			// Don't stop if waiting on first GPS location because
			// the live track will auto-stop if this.userGpsTrackingEnabled
			// is false after the first GPS point is received
			if (this.firstGpsLocation) {
				if (GeoService.verboseLogging) {
					console.log(
						`GeoService.setGpsLoggerState(false) - waiting on firstGpsLocation, not stopping`,
					);
				}
				return null;
			}

			// Used to detect if we should display errors
			this.stopWatchingGpsResultsForErrors();

			if (GeoService.verboseLogging) {
				console.warn(`setGpsLoggerState false, stopping`);
			}

			return this.gpsLogger.stop();
		}

		return null;
	}

	static startWatchingGpsResultsForErrors() {
		const GPS_ERROR_CHECK_POLL = 1000 * 5;
		const MAX_TIME_SINCE_LAST_READING = 1000 * 30;

		// First before restarting, try requesting new location reading.
		// Then, if time expires, we try restarting the logger
		// Only if this is true do we log an error
		let triedRestart = false;

		// Reset this flag (used to log success above)
		this.triedRestartingForGpsErrors = false;

		// This flag used by the dialog to decide to show/hide if not expected to work
		this.gpsLoggerExpectedActive = true;

		// Make sure we don't run more than one deadman instance
		clearTimeout(this.gpsDeadmanTid);

		const debugLog = (...args) => {
			console.warn(` ===> `, ...args);
			// datadogLogs.logger.debug(...args);
		};

		const errorLog = (...args) => {
			console.error(` ===> `, ...args);
			// datadogLogs.logger.error(...args);
		};

		this.gpsDeadmanTid = setInterval(
			() =>
				// Wrap with later to catch and log errors
				later(async () => {
					// Set by GpsLogger.LOCATION_CHANGED event handler, above
					// const { timestamp, requestedNewLocationReading } =
					// 	this.gpsLocationReceivedForErrorChecking || { };

					const { latestNormalLocation: requestedNewLocationReading } =
						this.gpsLogger || {};
					const { timestamp } = requestedNewLocationReading || {};

					if (!requestedNewLocationReading) {
						// console.log(
						// 	`No this.gpsLocationReceivedForErrorChecking, nothing to check`,
						// );
						return;
					}

					if (!timestamp) {
						console.warn(
							`No timestamp to use for measurements, ignoring`,
							requestedNewLocationReading,
						);
						return;
					}

					// Set via useManagedGpsTracking() and GeoService.setUserGpsTrackingEnabled()
					const { userGpsTrackingEnabled } = this;

					// GPS tracking is disabled for whatever reason by the App (business decision)
					// so don't trigger errors (but keep polling, since this could change)
					if (!userGpsTrackingEnabled) {
						return;
					}

					const timeSinceLastReading = Date.now() - timestamp;
					const secondsSinceLastReading = Math.round(
						timeSinceLastReading / 1000,
					);

					if (Number.isNaN(secondsSinceLastReading)) {
						console.warn(
							`NaN when converting timestamps`,
							this.gpsLocationReceivedForErrorChecking,
							{ timeSinceLastReading, secondsSinceLastReading },
						);
						return;
					}

					// GPS updating frequently, no errors
					if (timeSinceLastReading < MAX_TIME_SINCE_LAST_READING) {
						return;
					}

					const pendingConsent =
						this.permissionDialogPromiseNeededState.getValue();
					if (pendingConsent) {
						debugLog(`gps.timeout: Found pending promise, clearing`, {
							transactionPrefix,
							timeSinceLastReading,
						});
						pendingConsent.resolve(false);
					}

					const consent = this.gpsLogger.hasUserConsent();
					if (consent === 'no') {
						datadogLogs.logger.debug(
							`gps.timeout: Found NEGATIVE consent, clearing`,
							{
								transactionPrefix,
								timeSinceLastReading,
							},
						);
						this.gpsLogger.resetConsentValue();
					}

					if (!requestedNewLocationReading) {
						if (!this.gpsLocationReceivedForErrorChecking) {
							this.gpsLocationReceivedForErrorChecking = {};
						}
						this.gpsLocationReceivedForErrorChecking.requestedNewLocationReading = true;

						if (GeoService.verboseLogging) {
							console.log(
								`gps.timeout: Requested explicit new location reading`,
								this.gpsLocationReceivedForErrorChecking,
							);
						}
						this.gpsLoggerExpectedActive = true;
						this.gpsLogger.requestNewLocationReading().catch((ex) => {
							console.warn(
								`[startWatchingGpsResultsForErrors] Error requesting new location reading:`,
								ex,
							);
						});
						return;
					}

					if (!triedRestart) {
						triedRestart = true;

						debugLog(
							`gps.timeout: Haven't tried restarting GPS logger yet, will try that...`,
							{
								transactionPrefix,
								timeSinceLastReading,
							},
						);

						this.gpsLoggerExpectedActive = false;
						await this.gpsLogger.stop();

						// Set this so we log above
						this.triedRestartingForGpsErrors = true;
						this.gpsLogger.start();

						this.gpsLoggerExpectedActive = true;
					} else {
						errorLog(
							`gps.timeout: No gps for more than ${secondsSinceLastReading} seconds, tried clearing consent and restarting GPS, but no readings, showing error dialog on client.`,
							{
								transactionPrefix,
								timeSinceLastReading,
							},
						);

						const message = `No GPS received from your device after ${secondsSinceLastReading}`;
						this.gpsErrorState.setState({
							message,
							context: {
								timeSinceLastReading,
							},
						});

						ServerStore.AuditLog(
							`gps.logger.error`,
							`Client Encountered GPS Error`,
							// Including 'gps.timeout' for DataDog full-text indexing
							// so if we search for 'gps.timeout', we will get both
							// client logs (above) and this audit log from the server
							`${message} (\`gps.timeout\`)`,
							{
								timeSinceLastReading,
								logSeverity: 'ERROR',
							},
						);
					}
				}),
			GPS_ERROR_CHECK_POLL,
		);
	}

	static stopWatchingGpsResultsForErrors() {
		this.gpsLoggerExpectedActive = false;
		clearInterval(this.gpsDeadmanTid);
	}

	static getGpsAdapter() {
		return this.gpsLogger;
	}

	static async getGeoIpLocation({ returnOnly, debug = false } = {}) {
		// const { data } = await axios.get(
		// 	// `https://geolocation-db.com/json/${AppConfig.geolocationDbKey}`,
		// );

		if (debug) {
			console.log(`[getGeoIpLocation] calling getCf() ...`);
		}

		const data = await GpsStreamTransmitter.getCf();
		if (debug) {
			console.log(`[getGeoIpLocation] raw result from CF:`, data);
		}

		const {
			latitude: latString,
			longitude: lngString,
			city,
			region: state,
		} = data || {};

		const lat = parseFloat(latString);
		const lng = parseFloat(lngString);

		if (lat && lng) {
			if (GeoService.verboseLogging) {
				console.log(
					`Found rough location for user in ${city}, ${state} at ${lat}, ${lng}`,
					data,
				);
			}

			if (returnOnly) {
				return { city, state, lat, lng };
			}

			// Don't cache geoIp location because we want it fresh.
			// The cache is only used for pre-browser-GPS-bootup to cache last browser GPS
			// so we don't jump from geoIP->browser when browser finally boots on next boot
			// Assume a one mile accuracy
			this.updateCurrentGpsLocation({
				lat,
				lng,
				noCache: true,
				accuracy: 1609.34 / 4,
			});

			// Update server
			// ServerStore.StoreGpsLocation({
			// 	lat,
			// 	lng,
			// 	sensorType: 'ip',
			// }).catch((ex) => console.warn(ex));

			GpsStreamTransmitter.storeGpsLocation({
				lat,
				lng,
				sensorType: 'ip',
			}).catch((ex) => console.warn(ex));

			// Adjust the initial marker
			this.updateSingleMarkerLocation({
				lat,
				lng,
				icon: trackingIconProps,
			});

			return { city, state, lat, lng };
		}

		if (debug) {
			console.log(
				`[getGeoIpLocation] no lat/lng in CF data, not storing location`,
			);
		}

		return undefined;
	}

	static async updateCurrentGpsLocation(latLng) {
		this.currentGpsLocation.setState(latLng);

		const { updateSingleMarker } = this;

		// This flag is undefined on first page load...
		if (updateSingleMarker || updateSingleMarker === undefined) {
			this.updateSingleMarkerLocation({
				...latLng,
				icon: trackingIconProps,
			});
		} else {
			// console.warn(
			// 	`updateCurrentGpsLocation: not propogating to marker because updateSingleMarker=`,
			// 	updateSingleMarker,
			// );
		}

		const { lat, lng, noCache, accuracy } = latLng;
		if (!lat || !lng) {
			return;
		}

		if (!noCache && lat && lng) {
			const cache = `${lat},${lng},${accuracy}`;
			window.localStorage.setItem(LOCATION_CACHE_KEY, cache);
		}

		// Deprecated below ...

		// await this.start();
		// await this.waitForGoogle();

		// const { geocoder, viewportSource } = this;
		// if (viewportSource !== 'gps') {
		// 	return;
		// }

		// const { lat, lng, noCache, noGeocode } = latLng;
		// if (noGeocode || !lat || !lng) {
		// 	return;
		// }

		// const { cachedGeoResult, cachedGeoLat, cachedGeoLng } = this;

		// let res;
		// if (cachedGeoLat === latLng.lat && cachedGeoLng === latLng.lng) {
		// 	res = cachedGeoResult;
		// } else {
		// 	if (!noCache && lat && lng) {
		// 		const cache = `${lat},${lng}`;
		// 		window.localStorage.setItem(LOCATION_CACHE_KEY, cache);
		// 		// console.warn(`Geocode results cached:`, cache);
		// 	} else {
		// 		// console.log(`* Not caching:`, { noCache, lat, lng });
		// 	}

		// 	try {
		// 		res = await geocoder.geocode({
		// 			location: latLng,
		// 		});
		// 	} catch (ex) {
		// 		console.warn(`Could not geocode ${lat}, ${lng}:`, ex);
		// 		res = { results: [{}] };
		// 	}
		// 	// console.log(`Geocode results:`, res);

		// 	this.cachedGeoResult = res;
		// 	this.cachedGeoLat = lat;
		// 	this.cachedGeoLng = lng;
		// }

		// const { results: [firstResult] = [] } = res;

		// const {
		// 	geometry: { location, viewport } = {},
		// 	formatted_address: addressText,
		// 	place_id: placeId,
		// } = firstResult || {};

		// const firstPlace = { ...latLng, addressText, location, viewport, placeId };

		// // console.log(`First place:`, firstPlace);

		// this.currentPlaceInfo.setState(firstPlace);

		// this.currentViewport.setValue(viewport);
	}

	static async getPlaceDetails(googlePlaceId) {
		await this.start();
		await this.waitForGoogle();
		const { places } = this;
		const { google } = window;
		const sessionToken = this.stopAutocompleteSession();
		const request = {
			placeId: googlePlaceId,
			fields: ['name', 'geometry', 'formatted_address'],
			sessionToken,
		};

		return new Promise((resolve) =>
			places.getDetails(request, (place, status) => {
				if (status === google.maps.places.PlacesServiceStatus.OK) {
					const {
						geometry: { location, viewport },
						formatted_address: addressText,
						name,
					} = place;
					resolve({
						name,
						location,
						viewport,
						addressText,
						placeId: googlePlaceId,
					});
				} else {
					resolve({ error: status });
				}
			}),
		);
	}

	static async checkForGoogle() {
		const { google } = window;
		if (google) {
			this.initPromise.resolve(google);
			this.initPromise = null;
			return google;
		}

		this.checkForGoogle.tid = setTimeout(() => this.checkForGoogle(), 250);
		return this.initPromise;
	}

	static async waitForGoogle() {
		const { google } = window;
		if (google) {
			return google;
		}
		return this.initPromise;
	}

	/**
	 * Ref https://developers.google.com/maps/documentation/places/web-service/session-tokens
	 */
	static startAutocompleteSession() {
		this.sessionToken =
			new window.google.maps.places.AutocompleteSessionToken();

		return this.sessionToken;
	}

	static startOrGetAutocompleteSession() {
		if (!this.sessionToken) {
			this.startAutocompleteSession();
		}

		return this.sessionToken;
	}

	// Ref https://developers.google.com/maps/documentation/places/web-service/session-tokens
	static stopAutocompleteSession() {
		const { sessionToken } = this;
		this.sessionToken = null;
		return sessionToken;
	}

	static async placesAutocomplete(input) {
		/*
			For tokens, ref: https://developers.google.com/maps/documentation/javascript/places-autocomplete#session_tokens
		*/

		await this.start();
		await this.waitForGoogle();

		/*
		place_id: googlePlaceId,
					description,
					structured_formatting: {
						main_text: mainText,
						secondary_text: secondaryText,
					} = {},
					*/

		const { lat, lng } = this.getCurrentGpsLocationValue();
		const sessionToken = this.startOrGetAutocompleteSession();
		return new Promise((resolve) =>
			this.placesAutocompleteService.getQueryPredictions(
				{
					input,
					sessionToken,
					location: toLatLngRef({ lat, lng }),
					radius: 60 * 1000, // 60km
					// fields: ['place_id', 'description', 'structured_formatting'],
				},
				(predictions, status) => resolve({ predictions, status }),
			),
		);
	}

	static stop() {
		this.started = false;
		clearTimeout(this.checkForGoogle.tid);
		if (this.gpsLogger) {
			this.stopWatchingGpsResultsForErrors();
			if (GeoService.verboseLogging) {
				console.log(`GeoService stopping, so stopping GpsLogger`);
			}
			this.gpsLogger.stop();
		}
	}

	// h/t https://stackoverflow.com/a/16180970 for this logic
	static async directionsToPath(directions) {
		if (!directions) {
			return {};
		}

		await this.start();
		const google = await this.waitForGoogle();

		const polyline = new google.maps.Polyline({
			path: [],
		});
		const bounds = new google.maps.LatLngBounds();
		const points = [];

		let totalTime = 0;
		const { legs } = directions.routes[0];
		for (let i = 0; i < legs.length; i++) {
			const { steps } = legs[i];
			for (let j = 0; j < steps.length; j++) {
				const {
					path: nextSegment,
					duration: { value: seconds },
				} = steps[j];

				const avgSecondsPerSegment = seconds / (nextSegment.length || 1);
				for (let k = 0; k < nextSegment.length; k++) {
					const point = nextSegment[k];
					polyline.getPath().push(point);
					bounds.extend(point);

					totalTime += avgSecondsPerSegment;
					const [lat, lng] = [point.lat(), point.lng()];
					points.push({
						lat,
						lng,
						point,
						seconds: avgSecondsPerSegment,
						totalTime,
					});
				}
			}
		}

		return { path: polyline.getPath(), bounds, points };
	}

	static getCurrentGpsLocationValue() {
		return this.currentGpsLocation.getValue();
	}

	static async setDirections(directions) {
		if (!directions) {
			// console.log(
			// 	`setDirections has no directions, resetting internal service flags`,
			// );
			this.trackingMapValues.setValue(undefined);
			this.viewportSource = 'gps';
			this.updateCurrentGpsLocation(this.currentGpsLocation.getValue());
			this.hasDirections = false;
			this.currentDirections = undefined;
			return;
		}

		this.viewportSource = 'directions';
		this.currentDirections = directions;

		// Get the two markers for the directions (start and end)
		const {
			request: {
				origin: { location: currentMarker },
				destination: { location: endMarker },
			},
		} = directions;

		/**
		 * In order to be able to match a tracking GPS point
		 * or interpolate a distance reading into the path, we must
		 * pre-process the given direction path (a set of lat/lng points)
		 * and add some meta data to each point:
		 * - distance (total distance on the path, summed up as the distance between every point)
		 * - ref - a google LatLng object for the lat/lng of the point - makes later calcs easier
		 */

		// First, convert the directions object to a path and bounds objects
		const { path, bounds, points } = await this.directionsToPath(directions);

		// Next, add the distance and ref attributes to every point on the path
		const internalPath = [];
		let distanceSum = 0;
		let previousPoint = null;
		for (let i = 0; i < points.length; i++) {
			const { lat, lng, point: ref, seconds, totalTime } = points[i];
			if (i === 0) {
				previousPoint = ref; // toLatLngRef({ lat, lng });
				internalPath.push({
					lat,
					lng,
					seconds,
					totalTime,
					distance: 0,
					ref: previousPoint,
				});
			} else {
				const currentRef = ref; // toLatLngRef({ lat, lng });
				// in meters
				const distance =
					distanceSum +
					window.google.maps.geometry.spherical.computeDistanceBetween(
						previousPoint,
						currentRef,
					);
				previousPoint = currentRef;
				distanceSum = distance;
				internalPath.push({
					lat,
					lng,
					seconds,
					totalTime,
					distance,
					ref: currentRef,
				});
			}
		}

		// console.log(`setDirections:`, { points, internalPath });

		const tracking = {
			currentMarker,
			endMarker,
			path,
			internalPath,
			timeRemaining: internalPath[internalPath.length - 1].totalTime,
		};

		// Render the directions using the tracking state value (and viewport)
		this.currentViewport.setValue(bounds);
		this.trackingMapValues.setValue(tracking);
		this.hasDirections = true;

		// this.updateTrackingProgress(1000);
		// const testPoint = {
		// 	lat: 30.268685683507652,
		// 	lng: -97.74165547409896,
		// 	speed: 13.4112 * 8,
		// };
		// this.updateTrackingProgressFromGps(testPoint);
	}

	// Much of the logic of this updateTrackingProgress() routine is based on
	// the work shown here: https://dev.to/zerquix18/let-s-play-with-google-maps-and-react-making-a-car-move-through-the-road-like-on-uber-part-2-295e
	static updateTrackingProgressFromGps({
		lat,
		lng,
		speed = 0,
		heading = 0,
		onDistanceChanged,
		onInterpolatedPoint,
		onSnapFailed = 'updateDirections', // can also be a callback
	}) {
		this.stopAnimation();

		const data = this.trackingMapValues.getValue();
		const { internalPath: path } = data || {};
		if (!path) {
			console.warn(`Unable to updateTrackingProgress, no internalPath stored`);
			return;
		}

		const position = toLatLngRef({ lat, lng });

		/**
		 * Inorder to update the tracking based on a given lat/lng,
		 * we need to find the nearest point on our given "expected path"
		 * most closely matches the given lat/lng - then later,
		 * we'll use the distance of "how closely" it matches
		 * to interpolate a proper point along the path for rendering.
		 */
		let nearestNextPoint = null;
		let nearestDistance = 100000;
		let nearestIndex = path.length;

		path.forEach((point, index) => {
			const dist = window.google.maps.geometry.spherical.computeDistanceBetween(
				point.ref,
				position,
			);
			if (dist < nearestDistance) {
				nearestDistance = dist;
				nearestNextPoint = point;
				nearestIndex = index;
			}
		});

		// Get every point after the nearest point matched
		let pointsRemaining = path.slice(nearestIndex);

		// No points: Bieeeee
		if (!pointsRemaining.length) {
			this.trackingMapValues.setValue({
				...data,
				path: [],
				currentMarker: data.endMarker,
				timeRemaining: 0,
			});

			const bounds = new window.google.maps.LatLngBounds();
			bounds.extend(path[path.length - 2]);
			bounds.extend(path[path.length - 1]);
			bounds.extend(data.endMarker);
			this.currentViewport.setValue(bounds);

			return; // it's the end!
		}

		// Get the point JUST before the point matched so we can do interpolation
		const lastPoint = path[nearestIndex > 0 ? nearestIndex - 1 : 0];

		const { ref: lastLineLatLng } = lastPoint;
		const { ref: nextLineLatLng } = nearestNextPoint;

		// Interpolate a "good" point at the distance matched by our given lat/lng.
		const totalDistance = nearestNextPoint.distance - lastPoint.distance;
		const percentage = nearestDistance / totalDistance;

		const interpolatedPosition =
			window.google.maps.geometry.spherical.interpolate(
				lastLineLatLng,
				nextLineLatLng,
				percentage,
			);

		// Calculate a good angle based on the interpolated point (not tracked point)
		const angle = window.google.maps.geometry.spherical.computeHeading(
			interpolatedPosition,
			nextLineLatLng,
		);

		const timeRemaining = pointsRemaining.reduce(
			(total, { seconds }) => total + seconds,
			0,
		);

		// Put that interpolated point on the start of the line
		pointsRemaining = [].concat(interpolatedPosition, pointsRemaining);

		if (onInterpolatedPoint) {
			onInterpolatedPoint(interpolatedPosition, angle);
		}

		this.trackingMapValues.setValue({
			...data,
			path: pointsRemaining,
			timeRemaining,
			// If the nearest point matched is more than  SNAP_DISTANCE away from the path,
			// render the tracking marker at the actual GPS reading - otherwise, the tracking
			// marker will be rendered at a properly-interpolated position on the path
			currentMarker:
				nearestDistance > SNAP_DISTANCE ? position : interpolatedPosition,
			currentIcon: getTrackingIcon({
				rotation: heading || angle,
			}),
		});

		// Notify callback if point is "too far" off the existing path
		if (nearestDistance > SNAP_DISTANCE) {
			if (typeof onSnapFailed === 'function') {
				onSnapFailed(position);
			} else if (onSnapFailed === 'updateDirections') {
				const {
					cachedRequest: { destination },
				} = this.currentDirections || {};
				if (destination) {
					console.warn(
						`Snapping GPS to current path failed, requesting new directions from current point to original destination and updating path...`,
					);
					GeoService.getCachedDirections({ lat, lng }, destination).then(
						(directions) => {
							GeoService.setDirections(directions);
						},
					);
				}
			}
		}

		// console.log(
		// 	`updateTrackingProgressFromGps: `,
		// 	this.trackingMapValues.getValue(),
		// );

		// If speed given, estimate the tracking until a new update is given
		if (speed > 1) {
			const finalTotalDistance = nearestNextPoint.distance - nearestDistance;

			this.animTid = setTimeout(() => {
				this.updateTrackingProgress(finalTotalDistance, {
					animationVelocity: speed, // meters per second
					onDistanceChanged,
					onInterpolatedPoint,
				});
			}, ANIM_UPDATE_SPEED);
		} else {
			// Only update viewport if NOT animating so we don't "fight" with
			// the anim code on every GPS update

			// Recompute the viewport based on the updated path
			const bounds = new window.google.maps.LatLngBounds();
			// Only first X points for vp bounds
			pointsRemaining
				.slice(0, PATH_VP_POINTS)
				.forEach((point) => bounds.extend(point));
			this.currentViewport.setValue(bounds);
		}
	}

	static stopAnimation() {
		clearTimeout(this.animTid);
	}

	// Much of the logic of this updateTrackingProgress() routine is based on
	// the work shown here: https://dev.to/zerquix18/let-s-play-with-google-maps-and-react-making-a-car-move-through-the-road-like-on-uber-part-2-295e
	static updateTrackingProgress(
		distance,
		{
			animationVelocity = 0,
			onEnd = () => {},
			onDistanceChanged = () => {},
			onInterpolatedPoint = () => {},
		} = {},
	) {
		this.stopAnimation();

		const data = this.trackingMapValues.getValue();
		const { internalPath: path } = data || {};
		if (!path) {
			console.warn(`Unable to updateTrackingProgress, no internalPath stored`);
			return;
		}

		if (!distance) {
			console.warn(`Unable to updateTrackingProgress, no distance given`);
			return;
		}

		let pointsRemaining = path.filter(
			(coordinates) => coordinates.distance > distance,
		);

		// reverse because find() finds FIRST point less than distance,
		// so start from the end, otherwise it always will return path[0]
		const lastPoint =
			[]
				.concat(path)
				.reverse()
				.find((coordinates) => coordinates.distance < distance) || path[0];

		if (!pointsRemaining.length) {
			this.trackingMapValues.setValue({
				...data,
				path: [],
				currentMarker: data.endMarker,
				timeRemaining: 0,
			});

			const bounds = new window.google.maps.LatLngBounds();
			bounds.extend(path[path.length - 2]);
			bounds.extend(path[path.length - 1]);
			bounds.extend(data.endMarker);
			this.currentViewport.setValue(bounds);

			if (onInterpolatedPoint) {
				onInterpolatedPoint(data.endMarker, 0);
			}

			if (typeof onEnd === 'function') {
				onEnd();
			}

			return; // it's the end!
		}

		const nextPoint = pointsRemaining[0];

		const { ref: lastLineLatLng } = lastPoint;
		const { ref: nextLineLatLng } = nextPoint;

		// distance of this line
		const totalDistance = nextPoint.distance - lastPoint.distance;
		const percentage = (distance - lastPoint.distance) / totalDistance;

		// Get the point between lastPoint and nextPoint
		const position = window.google.maps.geometry.spherical.interpolate(
			lastLineLatLng,
			nextLineLatLng,
			percentage,
		);

		const angle = window.google.maps.geometry.spherical.computeHeading(
			position,
			nextLineLatLng,
		);

		const timeRemaining = pointsRemaining.reduce(
			(total, { seconds }) => total + seconds,
			0,
		);

		// Put that point on the start of the line
		pointsRemaining = [].concat(position, pointsRemaining);

		if (onInterpolatedPoint) {
			onInterpolatedPoint(position, angle);
		}

		this.trackingMapValues.setValue({
			...data,
			path: pointsRemaining,
			timeRemaining,
			currentMarker: position,
			currentIcon: getTrackingIcon({
				rotation: angle,
			}),
		});

		// console.log(
		// 	`updateTrackingProgress(${distance}): `,
		// 	this.trackingMapValues.getValue(),
		// );

		// Recompute the viewport based on the updated path
		const bounds = new window.google.maps.LatLngBounds();
		pointsRemaining
			.slice(0, PATH_VP_POINTS)
			.forEach((point) => bounds.extend(point));
		this.currentViewport.setValue(bounds);

		if (animationVelocity) {
			if (typeof onDistanceChanged === 'function') {
				onDistanceChanged({
					distance,
					speed: animationVelocity,
					timeRemaining,
				});
			}

			const date1 = Date.now();
			this.animTid = setTimeout(() => {
				const time = (Date.now() - date1) / 1000;
				const distanceTraveled = animationVelocity * time;
				const newDistance = distance + distanceTraveled;
				// console.log(`Anim update:`, {
				// 	time,
				// 	distanceTraveled,
				// 	animationVelocity,
				// 	newDistance,
				// });
				this.updateTrackingProgress(newDistance, {
					animationVelocity,
					onDistanceChanged,
					onInterpolatedPoint,
					onEnd,
				});
			}, ANIM_UPDATE_SPEED);
		}
	}

	static getCurrentViewport() {
		return this.currentViewport.getValue();
	}

	/**
	 * Return cached or get directions (and cache) between these two points
	 * @param {object} origin Object with { lat, lng } keys
	 * @param {object} destination Object with { lat, lng } keys
	 * @param {boolean} ignoreCache Default false, set to true to force to re-request for new directions
	 * @returns Google maps directions result (possibly cached)
	 */
	static async getCachedDirections(origin, destination, ignoreCache = false) {
		if (!origin || !destination) {
			return undefined;
		}

		const key = [
			...Object.values(normalizeLatLng(origin || {})),
			...Object.values(normalizeLatLng(destination || {})),
		].join(':');
		if (!this.cachedDirections) {
			this.cachedDirections = {};
		}

		if (!ignoreCache && this.cachedDirections[key]) {
			return this.cachedDirections[key];
		}

		await this.start(); // NOOP if started
		await this.waitForGoogle();

		return new Promise((resolve) => {
			// console.log(`USING this.directions...`);
			const { directions } = this;
			const { google } = window;
			const request = {
				origin: toLatLngRef(origin),
				destination: toLatLngRef(destination),
				travelMode: google.maps.TravelMode.DRIVING,
			};
			directions.route(request, (result, status) => {
				if (status === google.maps.DirectionsStatus.OK) {
					this.cachedDirections[key] = result;

					// Used for onSnapFailed when tracking
					// eslint-disable-next-line no-param-reassign
					result.cachedRequest = {
						origin,
						destination,
					};

					// console.log('Got directions:', result);

					resolve(result);
				} else {
					console.error(`error fetching directions ${result}`, status, {
						origin,
						destination,
						request,
					});
					resolve(undefined);
				}
			});
		});
	}

	static async getTripQuote({
		pickupPlace,
		dropoffPlace,
		dropoffTbd,
		estimatedPickupWaitTimeMins,
		estimatedPickupAt: incomingEstimatedPickup,
	}) {
		// TODO: Get estimatedPickupWaitTimeMins and estimatedPickupAt from backend
		// const { estimatedPickupWaitTimeMins, estimatedPickupAt } =
		// 	await (async () => {
		// 		// How far away is the driver...
		// 		const fakeWaitTimeMinutes = Math.ceil(3 + 15 * Math.random());

		// 		const pickup = new Date(Date.now() + fakeWaitTimeMinutes * 60 * 1000);

		// 		return {
		// 			estimatedPickupWaitTimeMins: fakeWaitTimeMinutes,
		// 			estimatedPickupAt: pickup,
		// 		};
		// 	})();

		const estimatedPickupAt = new Date(incomingEstimatedPickup);

		if (dropoffTbd) {
			const etaRef = {
				pickup: estimatedPickupAt,
				waitTime: estimatedPickupWaitTimeMins,
			};

			return etaRef;
		}

		const result = await this.getCachedDirections(pickupPlace, dropoffPlace);
		if (!result) {
			return undefined;
		}

		const {
			routes: [
				{
					legs: [
						{ distance, duration: { value: drivingTimeSeconds } = {} } = {},
					] = [],
				} = {},
			] = [],
		} = result;

		// Account for the time between driver arrival at pickup spot
		// and time the ride actually starts
		const fakePickupWaitMinutes = 1 + 3 * Math.random();
		const dropoff = new Date(
			estimatedPickupAt.getTime() +
				(fakePickupWaitMinutes * 60 + drivingTimeSeconds) * 1000,
		);

		const etaRef = {
			waitTime: estimatedPickupWaitTimeMins,
			pickup: estimatedPickupAt,
			driveTime: Math.round(drivingTimeSeconds / 60),
			directions: result,
			distance,
			dropoff,
		};

		console.log({ etaRef });

		return etaRef;
	}

	static async setHeatmap(data) {
		if (!data || !data.length) {
			this.currentHeatmap.setValue([]);
			this.hasHeatmap = false;
			return;
		}

		await this.start();
		await this.waitForGoogle();

		const processedData = data.map(({ lat, lng, weight }) => ({
			location: toLatLngRef({ lat, lng }),
			weight,
		}));

		this.currentHeatmap.setValue(processedData);
		this.hasHeatmap = true;
	}

	static async updateSingleMarkerLocation(location) {
		// THIS method is deprecated.
		return null;

		// this.singleMarkerLocation.setValue(location);

		// if (!location) {
		// 	// console.warn(
		// 	// 	`updateSingleMarkerLocation: no location given, not changing viewport`,
		// 	// );
		// 	return;
		// }

		// if (this.viewportSource !== 'gps') {
		// 	// Don't update if viewport is coming from directions
		// 	console.warn(
		// 		`updateSingleMarkerLocation: viewportSource is '${this.viewportSource}', not changing viewport`,
		// 	);
		// 	return;
		// }

		// const { lat, lng } = location;
		// const lngGridSize = getLngGridSize(lat);

		// // console.warn(`updateSingleMarkerLocation:`, location, {
		// // 	latGridSize,
		// // 	lngGridSize,
		// // });

		// await this.start();
		// await this.waitForGoogle();

		// const bounds = new window.google.maps.LatLngBounds();
		// bounds.extend(toLatLngRef(location));

		// // These two .extend() calls basically add `gridSize` miles to each side
		// // of the `location`. At time of writing, that means we have 0.25 miles
		// // add to each side of the point, resulting in a half-mile-wide/tall
		// // bounding rectangle
		// bounds.extend(
		// 	toLatLngRef({
		// 		lat: lat - latGridSize,
		// 		lng: lng - lngGridSize,
		// 	}),
		// );
		// bounds.extend(
		// 	toLatLngRef({
		// 		lat: lat + latGridSize,
		// 		lng: lng + lngGridSize,
		// 	}),
		// );

		// this.currentViewport.setValue(bounds);
	}
}

export function useSingleMarkerLocation() {
	return GeoService.singleMarkerLocation.useState();
}

export function useCurrentGpsLocation() {
	return GeoService.currentGpsLocation.useState();
}

export function useTrackingMapValues() {
	return GeoService.trackingMapValues.useState();
}

export function useCurrentViewport() {
	return GeoService.currentViewport.useState();
}

// Deprecated
// export function useCurrentPlaceInfo() {
// 	return GeoService.currentPlaceInfo.useState();
// }

export function useCurrentHeatmap() {
	return GeoService.currentHeatmap.useState();
}

/**
 * Given a ref for a map (or a map itself), this will automatically pan and fit
 * the viewport of the map as needed by the GeoService. We use this hook to manage
 * the viewport because react-google-maps doesn't provide a property to receive viewport
 * data from a state, so we have to directly update the underlying map ourselves.
 * @param {any} mapOrRef Ref (with .current set to a Google Map) or a Google Map object itself
 * @returns {function} Returns a function that can be used to force an update to the viewport (e.g. after get setting the map)
 */
export function useManagedViewport(
	mapOrRef,
	enabled,
	panelObscurationPadding = {},
) {
	// const enabled = false;
	const vpState = GeoService.currentViewport;
	// console.log(`useManagedViewport:`, { mapOrRef, enabled });

	const rem1 = use1rem();

	const updateViewport = useCallback(() => {
		if (!enabled) {
			return;
		}

		const map =
			mapOrRef && mapOrRef.current !== undefined ? mapOrRef.current : mapOrRef;
		const viewport = vpState.getValue();
		if (map && viewport) {
			// console.warn(
			// 	`* viewport changed: `,
			// 	viewport,
			// 	enabled,
			// 	panelObscurationPadding,
			// );

			const {
				top = 0,
				bottom = 0,
				left = 0,
				right = 0,
			} = panelObscurationPadding || {};
			const padding = {
				top:
					VIEWPORT_PADDING + top + Capacitor.getPlatform() !== 'web'
						? // Add appros padding to compensate for statusbar on native app
						  rem1 * 2.5
						: 0,
				right: VIEWPORT_PADDING + right,
				bottom: VIEWPORT_PADDING + bottom,
				left: VIEWPORT_PADDING + left,
			};
			map.panToBounds(viewport, padding);
			map.fitBounds(viewport, padding);
		} else {
			// console.warn(`* viewport NOT changed:`, { map, viewport });
		}
	}, [enabled, mapOrRef, panelObscurationPadding, rem1, vpState]);

	useEffect(() => {
		const cb = updateViewport;
		if (enabled) {
			vpState.on('changed', cb);
			cb(vpState.getValue());
		}

		return () => {
			vpState.off('changed', cb);
		};
	}, [enabled, updateViewport, vpState]);

	return updateViewport;
}

/**
 * Synchronizes the direction state in the GeoService with the
 * current state of the trip given, automatically reacting to changes
 * in the trip status, pickup/dropoff locations, and driver location.
 * NOTE: Does not handle "live" tracking from the websocket GPS feed - that is TBD/TODO.
 * @param {object} currentTrip The current trip being used in the UI
 */
export function useManagedDirections({
	status: currentStatus,
	driver = {},
	pickupPlace,
	dropoffPlace,
	dropoffTbd,
} = {}) {
	const { user = {} } = useAuthorization() || {};

	useEffect(() => {
		const directionStates = [
			TripStatus.Quoted,
			TripStatus.PendingDriverAcceptance,
			TripStatus.DriverAccepted,
			TripStatus.ScheduledPendingDriverAcceptance,
			TripStatus.ScheduledDriverAccepted,
			TripStatus.ScheduledDriverArriving,
			TripStatus.Riding,
			TripStatus.RidingStopped,
		];

		const { isDriver, isDriverOnline } = user || {};
		if (isDriver && isDriverOnline) {
			directionStates.push(TripStatus.PendingDriverAcceptance);
		}

		// console.log(`useManagedDirections:`, { currentStatus, directionStates });

		let origin;
		let destination;
		if (
			[
				TripStatus.Quoted,
				TripStatus.PendingDriverAcceptance,
				TripStatus.ScheduledPendingDriverAcceptance,
				TripStatus.ScheduledDriverAccepted,
			].includes(currentStatus)
		) {
			origin = pickupPlace;
			destination = dropoffTbd ? null : dropoffPlace;
		} else if (currentStatus === TripStatus.DriverAccepted) {
			origin = driver;
			destination = pickupPlace;
		} else {
			origin = driver;
			destination = dropoffTbd ? null : dropoffPlace;
		}

		// const { origin, destination } = [
		// 	TripStatus.PendingDriverAcceptance,
		// 	TripStatus.DriverAccepted,
		// ].includes(currentStatus)
		// 	? { origin: driver, destination: pickupPlace }
		// 	: { origin: driver, destination: dropoffTbd ? null : dropoffPlace };

		if (!destination || !directionStates.includes(currentStatus)) {
			GeoService.setDirections(undefined);
			return;
		}

		GeoService.getCachedDirections(origin, destination).then(
			async (directions) => {
				await GeoService.setDirections(directions);

				const isMovingStatus = [
					TripStatus.DriverAccepted,
					TripStatus.Riding,
				].includes(currentStatus);

				if (isMovingStatus) {
					GeoService.updateSingleMarkerLocation(undefined);
				}

				// // Fake our driver moving, broadcast to user - JUST FOR TESTING
				// if (driver && user && user.id === driver.id && isMovingStatus) {
				// 	GeoService.updateSingleMarkerLocation(undefined);
				// 	// GeoService.updateTrackingProgress(1, {
				// 	// 	animationVelocity: 13.4112 * 10, // 13.4112 m/s = ~30mph

				// 	// 	onInterpolatedPoint: ({ lat: latFn, lng: lngFn }) => {
				// 	// 		const [lat, lng] = [latFn(), lngFn()];
				// 	// 		// console.log(`Got new interpolated point:`, { lat, lng });
				// 	// 		ServerStore.StoreGpsLocation({ lat, lng });
				// 	// 	},
				// 	// });
				// }
			},
		);
	}, [user, dropoffTbd, currentStatus, driver, pickupPlace, dropoffPlace]);
}

export function useManagedGpsTracking({ status: currentStatus } = {}) {
	const { user } = useAuthorization() || {};

	useMemo(async () => {
		if (!currentStatus) {
			return;
		}

		// console.log(`Calling GeoService.start...`);
		await GeoService.start();
		// console.log(`DONE with GeoService.start, checking states...`);

		const trackingStates = [
			TripStatus.Quoted,
			TripStatus.PendingDriverAcceptance,
			TripStatus.ScheduledDriverArriving,
			TripStatus.DriverAccepted,
			TripStatus.DriverArrived,
			TripStatus.Riding,
			TripStatus.RidingStopped,

			// Adding Ride status values here is an intermediate "fix" to driver tracking
			// until we dig deeper into GPS for rides (vs trips)
			RideStatus.Quoted,
			RideStatus.QuoteAccepted,
			RideStatus.Dispatching,
			RideStatus.DriverAccepted,

			RideStatus.RidePending,
			RideStatus.Arriving,
			RideStatus.Arrived,
			RideStatus.Riding,
			RideStatus.Stopped,
		];

		const updateStates = [
			TripStatus.DriverAccepted,
			TripStatus.Riding,
			TripStatus.RidingStopped,

			// Adding Ride status values here is an intermediate "fix" to driver tracking
			// until we dig deeper into GPS for rides (vs trips)
			RideStatus.DriverAccepted,
			RideStatus.Arriving,
			RideStatus.Riding,
			RideStatus.Stopped,
		];

		const markerStates = [
			TripStatus.Draft,
			TripStatus.Quoted,
			// If we include this here, it competes with GPS updates from driver
			// TripStatus.DriverArrived,

			// Adding Ride status values here is an intermediate "fix" to driver tracking
			// until we dig deeper into GPS for rides (vs trips)
			RideStatus.Draft,
			RideStatus.DriverPreview,
			RideStatus.Quoted,
			RideStatus.QuoteAccepted,
		];

		const { isDriver, isDriverOnline } = user || {};

		const trackingEnabled =
			(isDriverOnline && isDriver) ||
			trackingStates.includes(currentStatus) ||
			(isDriver &&
				[TripStatus.ScheduledPendingDriverAcceptance].includes(currentStatus));

		const updateSingleMarker =
			!currentStatus || markerStates.includes(currentStatus);

		// By making this dependent on driver, we only update local directions when
		// we receive GPS from driver while riding...just FYI
		const updateDirectionsTrackingProgress =
			isDriver && updateStates.includes(currentStatus);

		const props = {
			trackingEnabled,
			updateDirectionsTrackingProgress,
			updateSingleMarker,
		};

		console.log(`useManagedGpsTracking is updating props:`, props);
		console.warn(`useManagedGpsTracking:`, {
			currentStatus,
			props,
			isDriver,
			isDriverOnline,
			statusValid: updateStates.includes(currentStatus),
			trackingStates,
			updateStates,
			user,
		});

		GeoService.setUserGpsTrackingEnabled(props);
	}, [currentStatus, user]);
}

GeoService.verboseLogging = AppConfig.buildEnv !== 'dev';

if (global.window) {
	global.window.GeoService = GeoService;
}
