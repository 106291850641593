import Fingerprint2 from 'fingerprintjs2';
import { Capacitor } from '@capacitor/core';
import nanoid from './nanoid';
import PublicAppConfig from '../config-public';

const DEVICE_ID_KEY = '@rubber/deviceId';
export default class DeviceInfo {
	static iOSVersion() {
		if (/iP(hone|od|ad)/.test(navigator.platform)) {
			// supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
			let v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
			return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
		}
		return null;
	}

	static getGeneratedDeviceId() {
		if (this.deviceId) return this.deviceId;

		this.deviceId = window.localStorage.getItem(DEVICE_ID_KEY);
		if (!this.deviceId) {
			this.deviceId = nanoid();
			window.localStorage.setItem(DEVICE_ID_KEY, this.deviceId);
		}

		return this.deviceId;
	}

	static _processFingerprintResult(components, resolve) {
		const values = components.map((component) => component.value);
		const result = Fingerprint2.x64hash128(values.join(''), 31);

		// console.log("[DeviceInfo._processFingerprintResult]", result, components) // an array of components: {key: ..., value: ...}
		if (!result) resolve(this.getGeneratedDeviceId());

		this.deviceId = result;
		resolve(result);
	}

	static getDeviceId() {
		return this.getGeneratedDeviceId();

		// Deprecated/removing Fingerprint2 from flow because I found collisions in production,
		// which were causing different people to get receipts that weren't theirs when we used
		// fingerprints as deviceId as guestIdent for anon user linking on the BE.
		// Example of user that collided: https://app.datadoghq.com/logs?query=-%40userId%3A95907c14-07d1-4b05-8ef1-f9582cdc6210%20-service%3A%2Adev%20%20-%22Script%20error%22%20-%22Received%20a%20location%20reading%20after%20restarting%20logger%22%20vtpay_y1va3626andrgnvr6brfdetga&cols=host%2Cservice%2C%40userName&index=&messageDisplay=inline&stream_sort=time%2Cdesc&viz=stream&from_ts=1675094150874&to_ts=1675698950874&live=true
		// Email to support that started the investigation:
		// - Receipt 1850-4306
		// - Email to concierge@vaya on 2023-02-05 11:55pm EST
		// - https://mail.google.com/mail/u/3/#inbox/FMfcgzGrcXhFbRDQCrXqzhmnLtcBqXvW
		// const _this = this;
		// return new Promise((resolve) => {
		// 	// Just for debugging
		// 	// // if(process.env.NODE_ENV === 'development') {
		// 	// 	console.warn("[getDeviceId] only using getGeneratedDeviceId()")
		// 	// 	resolve(this.getGeneratedDeviceId());
		// 	// // }

		// 	if (_this.deviceId) resolve(_this.deviceId);

		// 	if (window.requestAnimationFrame) {
		// 		window.requestAnimationFrame(async () => {
		// 			_this._processFingerprintResult(
		// 				await Fingerprint2.getPromise(),
		// 				resolve,
		// 			);
		// 		});
		// 	} else {
		// 		setTimeout(async () => {
		// 			_this._processFingerprintResult(
		// 				await Fingerprint2.getPromise(),
		// 				resolve,
		// 			);
		// 		}, 500);
		// 	}
		// });
	}

	static parseNavigatorAsDevice() {
		// From https://stackoverflow.com/questions/12489546/how-to-get-browsers-name-client-side
		/*eslint-disable */ // No lint for external code, from https://stackoverflow.com/questions/27732209/turning-off-eslint-rule-for-a-specific-line
		// var nVer = navigator.appVersion;
		var nAgt = navigator.userAgent;
		var browserName  = navigator.appName;
		var fullVersion  = ''+parseFloat(navigator.appVersion);
		var majorVersion = parseInt(navigator.appVersion,10);
		var nameOffset,verOffset,ix;

		// In Opera, the true version is after "Opera" or after "Version"
		if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
		   browserName = "Opera";
		   fullVersion = nAgt.substring(verOffset+6);
		   if ((verOffset=nAgt.indexOf("Version"))!=-1)
		     fullVersion = nAgt.substring(verOffset+8);
		}
		// In MSIE, the true version is after "MSIE" in userAgent
		else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
		   browserName = "Microsoft Internet Explorer";
		   fullVersion = nAgt.substring(verOffset+5);
		}
		// In Chrome, the true version is after "Chrome"
		else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
		   browserName = "Chrome";
		   fullVersion = nAgt.substring(verOffset+7);
		}
		// In Safari, the true version is after "Safari" or after "Version"
		else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
		   browserName = "Safari";
		   fullVersion = nAgt.substring(verOffset+7);
		   if ((verOffset=nAgt.indexOf("Version"))!=-1)
		     fullVersion = nAgt.substring(verOffset+8);
		}
		// In Firefox, the true version is after "Firefox"
		else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
		    browserName = "Firefox";
		    fullVersion = nAgt.substring(verOffset+8);
		}
		// In most other browsers, "name/version" is at the end of userAgent
		else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < (verOffset=nAgt.lastIndexOf('/')) ) {
		    browserName = nAgt.substring(nameOffset,verOffset);
		    fullVersion = nAgt.substring(verOffset+1);
		    if (browserName.toLowerCase()==browserName.toUpperCase()) {
		       browserName = navigator.appName;
		    }
		}
		// trim the fullVersion string at semicolon/space if present
		if ((ix=fullVersion.indexOf(";"))!=-1)
		    fullVersion=fullVersion.substring(0,ix);
		if ((ix=fullVersion.indexOf(" "))!=-1)
		    fullVersion=fullVersion.substring(0,ix);

		majorVersion = parseInt(''+fullVersion,10);
		if (isNaN(majorVersion)) {
		    fullVersion  = ''+parseFloat(navigator.appVersion);
		    majorVersion = parseInt(navigator.appVersion,10);
		}
		
		return {
			platform: browserName,
			model: '',
			version: majorVersion,
		};
	}

	static deviceClass() {
		const breakpointMinimums = {
			// desktop default
			desktop: 1200,
			tablet: 768,
			// phone default
		};

		for (let type in breakpointMinimums) {
			// eslint-disable-line no-unused-vars
			if (
				window.matchMedia(`(min-width: ${breakpointMinimums[type]}px)`).matches
			) {
				return type;
			}
		}
		return 'phone';
	}

	static appType() {
		return (Capacitor.getPlatform() !== 'web') ? 'native' : 'web';
	}

	static deviceProps() {
		// window.device will be present on PhoneGap to identify
		// the type of native device we are running on. If it's not present,
		// we will use the code in parseNavigatorAsDevice() to emulate the
		// same fields present on PhoneGap's .device object and fill
		// them with data from the web browser.
		return window.device || this.parseNavigatorAsDevice();
	}

	static deviceName() {
		const execDevice = this.deviceProps();

		return (
			[
				execDevice.manufacturer ? execDevice.manufacturer : execDevice.platform,
				execDevice.model,
				execDevice.version,
			]
				.join(' ')
				// On browsers, .model is null, so it leaves double spaces in the middle.
				// This regex just collapses those spaces into one
				.replace(/\s+/g, ' ')
		);
	}

	static getLang() {
		const browserLang = navigator.language;
		const i18nKey = 'i18nextLng';
		const i18nVal = window.localStorage.getItem(i18nKey);
		if (i18nVal) {
			return i18nVal;
		}
		return browserLang || undefined;
	}

	static async getDeviceInfo(clearCache = false) {
		if (this.cachedDeviceInfo && !clearCache) {
			return this.cachedDeviceInfo;
		}

		const { version, buildTime } = PublicAppConfig;

		const deviceId = await this.getDeviceId();
		const device = {
			deviceId,
			timestamp: new Date(),
			appType: this.appType(),
			deviceClass: this.deviceClass(),
			name: this.deviceName(),
			properties: this.deviceProps(),
			lang: this.getLang(),
			appVersion: version,
			appBuildTime: buildTime,
			url: window.location.href,
		};

		// Used as generic pivot point now
		device.brand = this.ua.Android
			? 'android'
				: this.ua.iOS
			? 'ios'
				: this.appType() === 'native'
			? 'other'
			: 'browser';

		if (this.ua.iOS) {
			device.iOSVersion = this.iOSVersion();
		}

		this.cachedDeviceInfo = device;

		return device;
	}
}

// UA matching
const nav = global && global.navigator || {};;
DeviceInfo.ua = {
	Android: /Android/gi.test(nav.userAgent),
	iOS:
		/AppleWebKit/.test(nav.userAgent) &&
		/Mobile\/\w+/.test(nav.userAgent),
};

