import { useState, useEffect } from 'react';

function get1rem({ includeLineHeight = false } = {}) {
	const div = document.createElement('div');
	// div.innerText = 'X';
	document.body.appendChild(div);

	const compStyles = window.getComputedStyle(div);
	const fontSize = parseFloat(
		compStyles.getPropertyValue('font-size').replace(/px$/, ''),
	);
	const lineHeight = parseFloat(
		compStyles.getPropertyValue('line-height').replace(/px$/, ''),
	);

	document.body.removeChild(div);

	return includeLineHeight ? { fontSize, lineHeight } : fontSize;
}

export function get1remCached() {
	if (get1rem.cached) {
		return get1rem.cached;
	}
	return get1rem();
}

/**
 * Exposes a React state variable containing an object like `{ fontSize: 17, lineHeight: 26 }` or `17` (depending on options) which is equal to the 1rem font size. This will automatically updates when the window changes shape
 * @params {boolean} includeLineHeight [default=false] If true, returns an object as shown above. By default, this is false, so it returns just a number indicating 1 rm font size in pixels
 * @returns {object} Object containing: `{ fontSize: number, lineHeight: number }` or a single `number` (depending on options) which is equal to the 1rem font size.
 */
export default function use1rem() {
	const [rem1, set1rem] = useState(get1rem());
	useEffect(() => {
		const changed = () => {
			const value = get1rem();
			get1rem.cached = value;
			set1rem(value);
		};
		window.addEventListener('resize', changed);
		return () => window.removeEventListener('resize', changed);
	});

	return rem1;
}
