/**
 * Remove state/zip/country removed if present
 *
 * @param {string} addressText Address text
 * @returns {string} Address with state/zip/country removed if present
 */
export function simplifyPlaceAddress(addressText) {
	return !addressText
		? addressText
		: `${addressText}`.replace(/(, \w{2})? (\d+), USA$/, '');
}
