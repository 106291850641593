/* eslint-disable no-unused-vars */
import React from 'react';
// import SimpleBadge from 'shared/components/SimpleBadge';
import clsx from 'clsx';
import { useTripInfoCollator } from 'shared/hooks/useTripInfoCollator';
// import styles from './TripStatusBadge.module.scss';

// simple polyfill
const ptPolyfill = (key, text) => text;

export default function TripStatusBadge({ status, pt = ptPolyfill }) {
	// const {
	// 	statusTitle,
	// 	isUpcoming,
	// 	isStarted,
	// 	isCompleted,
	// 	isCancelation,
	// 	isNoShow,
	// } = useTripInfoCollator({ status });

	// if (isNoShow) {
	// 	return (
	// 		<SimpleBadge className={clsx(styles.badge, styles.noCall)}>
	// 			{pt('no-call-no-show', 'No Call/No Show')}
	// 		</SimpleBadge>
	// 	);
	// }

	// if (isCancelation) {
	// 	return (
	// 		<SimpleBadge className={clsx(styles.badge, styles.userCanceled)}>
	// 			{pt('member-cancel', 'Canceled by Member')}
	// 		</SimpleBadge>
	// 	);
	// }

	// if (isStarted) {
	// 	return (
	// 		<SimpleBadge className={clsx(styles.badge, styles.isStarted)}>
	// 			{/* {pt('ride-started', 'Trip Active')} */}
	// 			{statusTitle}
	// 		</SimpleBadge>
	// 	);
	// }
	// if (isUpcoming) {
	// 	return (
	// 		<SimpleBadge className={clsx(styles.badge, styles.isUpcoming)}>
	// 			{pt('ride-upcoming', 'Upcoming')}
	// 		</SimpleBadge>
	// 	);
	// }
	// if (isCompleted) {
	// 	return (
	// 		<SimpleBadge className={clsx(styles.badge, styles.isCompleted)}>
	// 			{pt('ride-completed', 'Completed')}
	// 		</SimpleBadge>
	// 	);
	// }

	return <i>{status}</i>;
}
