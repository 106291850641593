import { useEffect } from 'react';
import {
	BrowserRouter,
	Routes,
	Route,
	useNavigate,
} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useIsAuthorized } from 'shared/services/AuthService';
import SimpleSpinner from 'shared/components/SimpleSpinner';
import HistoryHack from 'shared/utils/history';
import BackendService from 'shared/services/BackendService';
import { ToastContainer, toast } from 'shared/components/SharedToast';
import LoadingSpinnerProvider from 'shared/components/LoadingSpinnerProvider';
import { useRootHelper } from 'shared/hooks/useRootHelper';
import SupportChatPage from 'shared/components/SupportChat/SupportChatPage';
import LoginPage from './components/LoginPage';
import { DrawerNavContextProvider } from './components/DrawerNav';
import BookingPage from './components/BookingPage';
import TripsPage from './components/TripsPage';
import TripDetailsPage from './components/TripDetailsPage';

// Post-login route to land
BackendService.defaultRoute = '/book';

function Home() {
	const navigate = useNavigate();
	const authorized = useIsAuthorized(true);
	useEffect(() => {
		if (authorized === true) {
			navigate(BackendService.defaultRoute);
		} else {
			navigate('/login');
		}
	}, [authorized, navigate]);
	// console.log(`authorized`, authorized);

	return <SimpleSpinner fullPage={true} />;
}

function HistoryHackHelper() {
	const navigate = useNavigate();
	HistoryHack.ref = navigate;
	return <></>;
}

export default function App() {
	const theme = createTheme({
		palette: {
			type: 'light',
			primary: {
				main: '#D94AB7',
			},
			secondary: {
				main: '#ffffff', // #ac5ac5',
			},
		},
	});

	useRootHelper();

	return (
		<ThemeProvider theme={theme}>
			<DrawerNavContextProvider>
				<CssBaseline />
				<ToastContainer
					position={toast.POSITION.BOTTOM_CENTER}
					pauseOnFocusLoss={false}
					autoClose={2500}
				/>
				<LoadingSpinnerProvider>
					<BrowserRouter>
						<HistoryHackHelper />
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="login" element={<LoginPage />} />
							<Route path="book" element={<BookingPage />} />
							<Route path="trips" element={<TripsPage />} />
							<Route
								path="trip/:tripId"
								element={<TripDetailsPage />}
							/>
							<Route
								path="support-chat"
								element={<SupportChatPage />}
							/>
						</Routes>
					</BrowserRouter>
				</LoadingSpinnerProvider>
			</DrawerNavContextProvider>
		</ThemeProvider>
	);
}
