/* eslint-disable no-console, no-nested-ternary */
import AppConfig from '../config';
import MessageTypes from './MessageTypes';
import SocketClient from './SocketClient';
import Logger from './IsomorphicLogger';

export const gpsWorkerRoot =
	AppConfig.buildEnv === 'dev' &&
	!`${global.window && window.location.search}`.includes('prodGpsWorker')
		? AppConfig.gpsWorkerPublic.devWorkerRoot
		: AppConfig.gpsWorkerPublic.prodWorkerRoot;

// export const gpsWorkerRoot = AppConfig.gpsWorkerPublic.prodWorkerRoot;

export default class GpsWorkerClient {
	/**
	 * Construct a path like `/api/reflect/{someId}/websocket`,
	 * but easier.
	 * @param {string} opts.objectId [required] The id of the Durable Object to speak with. For example, when speaking to ReflectStore objects, this should be the UserID of the logged-in user (or relevant user)
	 * @param {string} opts.objectType [optional, default='reflect'] One of 'reflect' or 'reducer'
	 * @param {string} opts.resource [optional, default='websocket'] Resource to append to the path, typically 'websocket'
	 * @param {boolean} opts.absolute [optional, default=false] If true, prepends the host name for the worker. Leave as false to use with GpsWorkerClient's socket, but useful for speaking HTTPS to a worker, so can set this to true if using this path outside of the socket here in GpsWorkerClient
	 * @returns {string} A string like '/api/reflect/{userId}/websocket'
	 */
	static createWorkerPath({
		objectId = undefined,
		objectType = 'reflect',
		resource = 'websocket',
		absolute = false,
	}) {
		const path = `/api/${objectType}/${objectId}/${resource}`;
		if (!absolute) {
			return path;
		}
		return `${gpsWorkerRoot}${path}`;
	}

	// Example path: /api/reducer/vaya/websocket
	// Name is used for debug logging

	/**
	 * Create a websocket connection to our CloudFlare GPS worker instance. Automatically connects to dev/prod based on build config.
	 *
	 * @param {string} opts.path Required, Use {@link GpsWorkerClient#createWorkerPath} to get a path
	 * @param {string} opts.token Required, will throw `Error` if not given. Get it somewhere, up to you. Must be signed with the Vaya JWT key.
	 * @param {Function} opts.onConnected Optional. If given, when the socket is authenticated, this will be called with the authentication response message, notably containing an `ip` key and a `cf` key. Up to you what you do with that.
	 * @param {Function} opts.onMessage Optional. Use this to get all messages from the socket, EXCEPT the auth response. No messages will be rxd prior to auth. To get auth response, use `onConnected`
	 * @param {Function} opts.onDisconnected. Optional. Use this to get notified when our socket disconnects. We'll reconnect automatically, nothing for you to do. But you can use this to pause/buffer data you want to transmit and then transmit it in `onConnected` - up to you.
	 * @param {boolean} opts.debug [default=false] If true, prints some debugging to console
	 * @param {string} opts.name Optional, only relevant for debug messages. Does nothing if `debug` is false.
	 */
	constructor({
		path = '/',
		token,
		onConnected,
		onMessage,
		onDisconnected,
		debug = false,
		name = 'GpsWorkerClient',
	}) {
		if (!token) {
			throw new Error(
				`Must provide token to GpsWorkerClient otherwise socket will be useless`,
			);
		}

		this.debug = debug;
		this.name = name;

		this.token = token;
		this.onConnected = onConnected;
		this.onDisconnected = onDisconnected;
		this.onMessage = onMessage;

		this.onSocketMessage = this.onSocketMessage.bind(this);
		this.onSocketConnected = this.onSocketConnected.bind(this);
		this.onSocketClosed = this.onSocketClosed.bind(this);

		const socketPath = `${gpsWorkerRoot}`.replace(/^http/, 'ws') + path;
		this.socketPath = socketPath;

		this.started = true;
		this.socket = new SocketClient(socketPath);
		this.socket.on(SocketClient.MESSAGE, this.onSocketMessage);
		this.socket.on(SocketClient.CONNECTED, this.onSocketConnected);
		this.socket.on(SocketClient.CLOSED, this.onSocketClosed);

		if (debug) {
			Logger.info(`🕖 Connecting ${name} to ${socketPath}...`);
		}
	}

	onSocketClosed() {
		if (this.debug) {
			Logger.warn(
				`🛑  ${this.name} closed for ${this.socketPath}, will reconnect...`,
			);
		}

		if (typeof this.onDisconnected === 'function') {
			this.onDisconnected();
		}
	}

	stop() {
		if (!this.started) {
			return;
		}

		this.started = false;

		if (this.socket) {
			// console.log(`turning off socket`, this.socket);
			this.socket.off(SocketClient.MESSAGE, this.onSocketConnected);
			this.socket.off(SocketClient.CONNECTED, this.onSocketConnected);
			this.socket.off(SocketClient.CLOSED, this.onSocketClosed);
			this.socket.close();
			this.socket = null;
		}
	}

	async onSocketConnected() {
		const { token, socketPath, name, debug } = this;
		this.socket.send({
			type: MessageTypes.JWTAuthorizationMessage,
			token,
		});

		if (debug) {
			Logger.debug(`${name} connected to ${socketPath}, authorizing...`);
		}
	}

	async onSocketMessage(data) {
		if (typeof data === 'string') {
			try {
				// eslint-disable-next-line no-param-reassign
				data = JSON.parse(data);
			} catch (ex) {
				// ignore
			}
		}

		if (this.debug) {
			Logger.debug(`${this.name} received message:`, data);
		}

		if (data && data.ready) {
			const { socketPath, name, debug } = this;
			if (debug) {
				Logger.debug(`✅ ${name} to ${socketPath} online and authorized`);
			}

			if (typeof this.onConnected === 'function') {
				this.onConnected(data);
			}

			return;
		}

		if (typeof this.onMessage === 'function') {
			this.onMessage(data);
		}
	}

	sendMessage(message) {
		this.socket.send(message);
	}
}
