import clsx from 'clsx';
import styles from './PartnerLogoToolbarItem.module.scss';

export default function PartnerLogoToolbarItem({ url, size = 'small' }) {
	return (
		<div className={clsx(styles.root, styles[size])}>
			{url ? (
				<>
					<img
						src={url}
						alt={'Partner Logo'}
						className={styles.partnerBrand}
					/>
					<img
						src="/logo192.png"
						alt={'Vaya Logo'}
						className={styles.vayaBrand}
					/>
				</>
			) : (
				<img
					src="/logo192.png"
					alt={'Vaya Logo'}
					className={styles.vayaBrandSolo}
				/>
			)}
		</div>
	);
}
