/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import Logger, { isNode } from './IsomorphicLogger';

const getTextFieldName = (field) =>
	// eslint-disable-next-line no-nested-ternary
	field === 'start'
		? 'pickupPlaceName'
		: field === 'dropoff'
		? 'dropoffPlaceName'
		: field;

export const getPlaceTextForField = (currentTrip, field) => {
	const fieldId = getTextFieldName(field);
	if (typeof fieldId === 'string') {
		// start/dropoff
		return currentTrip[fieldId];
	}

	// This format is given to us (as of this writing) in WaypointRow / LayoverRow
	// in EditorWidget when clicking on a trip leg
	const { leg, placeType } = field;
	if (placeType === '_new' || !field || !leg) {
		// User clicked "add" button so show an empty place chooser text
		return '';
	}

	const key = `${placeType}Place`;
	const { [key]: { name, invalid } = { invalid: true } } = leg || {};

	if (invalid) {
		Logger.error(
			`getPlaceTextForField: Cannot get text for field ID, key '${key}' - no valid place on the leg or invalid leg`,
			{
				leg,
				placeType,
				currentTrip,
			},
		);

		return '';
	}

	return name;
};

const createStandardizedPlacePatch = (
	fieldKey,
	{ place, addressText, name, fromKey, ref = {} } = {},
) => {
	if (!fromKey) {
		// Add name to place object - really this is only
		// relevant when running transformations on uninflated Place objects,
		// e.g. when running in some form of debugging mode, like from trip-legs.js' main() routine,
		// for the purpose of manually testing out transformations.
		// This .name prop is really only used (when debugging) in the legChecklist routine
		if (typeof place === 'object' && !place.name && name) {
			// eslint-disable-next-line no-param-reassign
			place.name = name;
		}

		return {
			[fieldKey]: place || undefined,
			[`${fieldKey}Name`]: name || '',
			[`${fieldKey}AddressText`]: addressText || '',
		};
	}

	const {
		[fromKey]: fromPlace,
		[`${fromKey}Name`]: fromName,
		[`${fromKey}Name`]: fromAddressText,
	} = ref;

	return {
		[fieldKey]: fromPlace,
		[`${fieldKey}Name`]: fromName,
		[`${fieldKey}AddressText`]: fromAddressText,
	};
};

export const createDeleteLegPatch = (tripLegs, leg) => {
	const listItemRef = tripLegs.find(({ id }) => id === leg.id);

	const currentLegIndex = tripLegs.indexOf(listItemRef);

	// Should never be able to delete the first leg, but guard anyway
	const previousLeg = currentLegIndex > 0 ? tripLegs[currentLegIndex - 1] : {};

	// Write in the pickup place to ensure data consistency from the legs
	const tripPickup = createStandardizedPlacePatch('pickupPlace', {
		fromKey: 'startPlace',
		ref: tripLegs[0],
	});

	if (currentLegIndex === tripLegs.length - 1) {
		// Special case deleting last "leg" - really, the user clicked delete on the "start"
		// place or the last leg, because we only use the stop place in the UI for the dropoff,
		// and can't delete the dropoff.
		// So, if deleting start place of last leg, we will:
		// - Remove last leg (this leg)
		// - Change stop place of PREVIOUS leg to stop place of THIS leg
		const thisStop = createStandardizedPlacePatch('stopPlace', {
			fromKey: 'stopPlace',
			ref: leg,
		});

		const tripDropoff = createStandardizedPlacePatch('dropoffPlace', {
			fromKey: 'stopPlace',
			ref: leg,
		});

		// Logger.debug(
		// 	`createDeleteLegPatch // last leg // nextStartAsStop`,
		// 	nextStartAsStop,
		// 	nextLeg.startPlace,
		// 	nextLeg,
		// );

		const modifiedPreviousLeg = {
			...previousLeg,
			...thisStop,
		};

		const newLegs = [].concat(
			tripLegs.slice(0, currentLegIndex - 1),
			modifiedPreviousLeg,
			// `leg` was right here...
			// Nothing after 'leg' since we determined it was the last one
		);

		const finalPatch = { tripLegs: newLegs, ...tripPickup, ...tripDropoff };
		Logger.debug(`createDeleteLegPatch // last leg `, {
			tripLegs,
			leg,
			listItemRef,
			currentLegIndex,
			previousLeg,
			numLegs: tripLegs.length,
			thisStop,
			modifiedPreviousLeg,
			finalPatch,
		});

		return finalPatch;
	}

	// Already guarded against access for last leg, above
	const nextLeg = tripLegs[currentLegIndex + 1];

	// When deleting a leg, we have to mind the PREVIOUS legs stop place.
	// We need to "wire" the PREVIOUS stop place to the NEXT start place,
	// removing the current leg from the "linked list"
	const nextStartAsStop = createStandardizedPlacePatch('stopPlace', {
		fromKey: 'startPlace',
		ref: nextLeg,
	});

	// Logger.debug(`nextStartAsStop`, nextStartAsStop, nextLeg.startPlace, nextLeg);

	const modifiedPreviousLeg = {
		...previousLeg,
		...nextStartAsStop,
	};

	const newLegs = [].concat(
		tripLegs.slice(0, currentLegIndex - 1),
		modifiedPreviousLeg,
		// `leg` was right here...
		tripLegs.slice(currentLegIndex + 1),
	);

	const finalPatch = { tripLegs: newLegs, ...tripPickup };

	Logger.debug(`createDeleteLegPatch // mid-leg `, {
		tripLegs,
		leg,
		listItemRef,
		currentLegIndex,
		previousLeg,
		numLegs: tripLegs.length,
		nextLeg,
		nextStartAsStop,
		modifiedPreviousLeg,
		finalPatch,
	});

	return finalPatch;
};

/**
 * Swop the dropoff place with previous leg's location
 * @param {Array<TripLeg>} tripLegs Array of TripLeg-like json objects
 * @returns {object} Object with { tripLegs, tripDropoffProps }
 */
export const createLegSwapPatch = (tripLegs) => {
	const listItemRef = tripLegs[tripLegs.length - 1];

	const currentLegIndex = tripLegs.indexOf(listItemRef);

	const previousLeg =
		currentLegIndex > 0 ? tripLegs[currentLegIndex - 1] : undefined;

	// const startKey = `startPlace`;
	// const stopKey = 'stopPlace';
	// const dropoffKey = 'dropoffPlace';

	// const {
	// 	isLayover,
	// 	[`${startKey}`]: startPlace,
	// 	[`${startKey}Name`]: startPlaceName,
	// 	[`${startKey}AddressText`]: startPlaceAddressText,
	// 	[`${stopKey}`]: stopPlace,
	// 	[`${stopKey}Name`]: stopPlaceName,
	// 	[`${stopKey}AddressText`]: stopPlaceAddressText,
	// } = listItemRef;

	// const tripDropoffProps =  isLayover ? {
	// 	[`${dropoffKey}`]: stopPlace : startPlace,
	// 	[`${dropoffKey}Name`]: startPlaceName,
	// 	[`${dropoffKey}AddressText`]: startPlaceAddressText,
	// };

	// const modifiedLeg = {
	// 	...listItemRef,

	// 	[`${startKey}`]: stopPlace,
	// 	[`${startKey}Name`]: stopPlaceName,
	// 	[`${startKey}AddressText`]: stopPlaceAddressText,

	// 	[`${stopKey}`]: startPlace,
	// 	[`${stopKey}Name`]: startPlaceName,
	// 	[`${stopKey}AddressText`]: startPlaceAddressText,
	// };

	let tripDropoffProps = {
		...createStandardizedPlacePatch('dropoffPlace', {
			fromKey: 'startPlace',
			ref: listItemRef,
		}),
	};

	const newStartPlace = createStandardizedPlacePatch('startPlace', {
		fromKey: 'stopPlace',
		ref: listItemRef,
	});

	const newStopPlace = createStandardizedPlacePatch('stopPlace', {
		fromKey: 'startPlace',
		ref: listItemRef,
	});

	const legModificationPatch = {
		...newStartPlace,
		...newStopPlace,
	};

	const modifiedLeg = {
		...listItemRef,
		...legModificationPatch,
	};

	let newLegs;

	const { isLayover: previousLegIsLayover } = previousLeg || {};

	if (previousLegIsLayover) {
		let modifiedLayover = { ...previousLeg };

		// Layover is never the first leg, so -2 is logical
		let modifiedTertiaryLeg = tripLegs[currentLegIndex - 2];

		const { startPlace, stopPlace } = previousLeg;
		const { id: startPlaceId } = startPlace || {};
		const { id: stopPlaceId } = stopPlace || {};
		if (startPlaceId === stopPlaceId) {
			const sameStopPlace = createStandardizedPlacePatch('stopPlace', {
				fromKey: 'startPlace',
				ref: newStartPlace,
			});

			modifiedLayover = {
				...modifiedLayover,
				...newStartPlace,
				...sameStopPlace,
			};

			Logger.debug(
				`previous leg IS layover, created modified layover with BOTH matching places`,
				isNode ? '' : modifiedLayover,
			);

			// Since layovers are never the FIRST leg in a trip, we modify the STOP place
			// of the leg BEFORE the layover, just for data completeness. We never will use
			// that stop place (of the tertiary leg) for anything other than debugging,
			// but still good to keep tidy data
			modifiedTertiaryLeg = {
				...modifiedTertiaryLeg,
				...sameStopPlace,
			};
		} else {
			modifiedLayover = {
				...modifiedLayover,
				...newStopPlace,
			};

			Logger.debug(
				`previous leg IS layover, created modified layover with END place changing`,
				isNode ? '' : modifiedLayover,
			);
		}

		newLegs = [].concat(
			tripLegs.slice(0, currentLegIndex - 2),
			modifiedTertiaryLeg,
			modifiedLayover,
			modifiedLeg,
			tripLegs.slice(currentLegIndex + 1),
		);
	} else if (previousLeg) {
		// Logger.debug(`Previous leg is not a layover`, isNode ? '' : previousLeg);

		const modifiedPreviousLeg = {
			...previousLeg,
			...createStandardizedPlacePatch('stopPlace', {
				fromKey: 'startPlace',
				ref: newStartPlace,
			}),
		};

		newLegs = [].concat(
			tripLegs.slice(0, currentLegIndex - 1),
			modifiedPreviousLeg,
			modifiedLeg,
			tripLegs.slice(currentLegIndex + 1),
		);
	} else {
		tripDropoffProps = {
			...tripDropoffProps,
			...createStandardizedPlacePatch('pickupPlace', {
				fromKey: 'stopPlace',
				ref: listItemRef,
			}),
		};
		Logger.debug(
			`only one leg, modification:`,
			legModificationPatch,
			tripDropoffProps,
		);

		// if here, we only have the one leg
		newLegs = [].concat(
			tripLegs.slice(0, currentLegIndex),
			modifiedLeg,
			tripLegs.slice(currentLegIndex + 1),
		);
	}

	return { tripLegs: newLegs, tripDropoffProps };
};

const createNewLayoverLeg = ({
	placeProps,
	minutes = 60,
	variable = 20,
	driverStandby = false,
}) => {
	const tripLeg = {
		isLayover: true,
		driverStandby,

		userEstimatedLayoverMinutes: minutes,
		userEstimatedLayoverVariable: variable,
		endTime: undefined,

		isStartTimeScheduled: undefined,
		startTime: undefined,

		airline: undefined,
		flightNumber: undefined,

		...createStandardizedPlacePatch('startPlace', placeProps),
		...createStandardizedPlacePatch('stopPlace', placeProps),
	};

	Logger.debug(`createNewTripLegDefinition:`, { tripLeg });

	return tripLeg;
};

export const createLayoverLegPatch = (tripLegs, fromLeg) => {
	// always start with the start place since we don't use stop places for anything
	// other than layover END places (if different than start) and the destination
	const key = `startPlace`;
	const {
		[`${key}`]: place,
		[`${key}Name`]: name,
		[`${key}AddressText`]: addressText,
	} = fromLeg;

	const layoverLeg = createNewLayoverLeg({
		placeProps: { place, name, addressText },
	});

	const listItemRef = tripLegs.find(({ id }) => id === fromLeg.id);

	const currentLegIndex = tripLegs.indexOf(listItemRef);

	const newLegs = [].concat(
		tripLegs.slice(0, currentLegIndex),
		layoverLeg,
		tripLegs.slice(currentLegIndex),
	);

	return { tripLegs: newLegs };
};

const createNewTripLegDefinition = (sourceProps = {}) => {
	const tripLeg = {
		isLayover: false,
		driverStandby: false,

		userEstimatedLayoverMinutes: 60,
		userEstimatedLayoverVariable: 15,
		endTime: undefined,

		isStartTimeScheduled: undefined,
		startTime: undefined,

		airline: undefined,
		flightNumber: undefined,

		...createStandardizedPlacePatch('startPlace', sourceProps),
		...createStandardizedPlacePatch('stopPlace', sourceProps),
	};

	Logger.debug(`createNewTripLegDefinition:`, { tripLeg, sourceProps });

	return tripLeg;
};

const createInsertTripLegPatch = (
	currentTrip,
	sourceProps = { place: { id: undefined }, name: '', addressText: '' },
	afterField,
) => {
	// Logger.debug(`createInsertTripLegPatch: args:`, {
	// 	afterField,
	// 	sourceProps,
	// 	currentTrip,
	// });
	const { tripLegs } = currentTrip || {};

	let {
		fieldId,
		fieldId: { leg: afterLeg, placeType: originalFieldPlaceType } = {},
		placeType: addPlaceType,
	} = afterField || {};

	if (tripLegs.length === 1 || typeof fieldId === 'string') {
		// eslint-disable-next-line prefer-destructuring
		afterLeg = tripLegs[0];
	}

	if (addPlaceType !== '_new') {
		Logger.error(
			`Cannot createInsertTripLegPatch after placeType '${addPlaceType}' because that's not what we expected - should be '_new'`,
			{ afterField },
		);
		return {};
	}

	if (afterLeg) {
		const listItemRef = tripLegs.find(({ id }) => id === afterLeg.id);

		const currentLegIndex = tripLegs.indexOf(listItemRef);

		const nextLeg =
			currentLegIndex + 1 < tripLegs.length
				? tripLegs[currentLegIndex + 1]
				: undefined;

		let newLegs;
		if (tripLegs.length === 1) {
			// Special case: Start/end and no other legs

			const [
				{
					startPlace,
					startPlaceName,
					startPlaceAddressText,
					stopPlace,
					stopPlaceName,
					stopPlaceAddressText,
					isLayover, // consuming this just show it doesn't show up in copies via legProps
					...legProps
				},
			] = tripLegs;

			newLegs = [
				{
					...legProps,
					startPlace,
					startPlaceName,
					startPlaceAddressText,
					...createStandardizedPlacePatch('stopPlace', sourceProps),
				},
				{
					...legProps,
					id: undefined,
					stopPlace,
					stopPlaceName,
					stopPlaceAddressText,
					...createStandardizedPlacePatch('startPlace', sourceProps),
				},
			];
			// console.warn(
			// 	`Wanted to create new legs, but not going to`,
			// 	newLegs,
			// );

			// return { tripLegs };
		} else {
			const {
				isLayover: previousLegIsLayover,
				stopPlace: previousStopPlace,
				startPlace: previousStartPlace,
			} = listItemRef;

			const { id: previousStopId } = previousStopPlace;
			const { id: previousStartId } = previousStartPlace;

			// if layover, we have to check that places are NOT the same
			// if places are same, treat like NON layover, use start place

			const extractedStart = createStandardizedPlacePatch('startPlace', {
				fromKey: 'startPlace',
				ref: afterLeg,
			});
			const extractedStop = createStandardizedPlacePatch('stopPlace', {
				fromKey: 'stopPlace',
				ref: afterLeg,
			});

			const newStartPlace = createStandardizedPlacePatch(
				'startPlace',
				sourceProps,
			);
			const newStopPlace = createStandardizedPlacePatch(
				'stopPlace',
				sourceProps,
			);

			// Logger.debug({
			// 	afterLeg,
			// 	extractedStart,
			// 	extractedStop,
			// 	newStartPlace,
			// 	newStopPlace,
			// });

			if (previousLegIsLayover) {
				const extractedStopAsStart = createStandardizedPlacePatch(
					'startPlace',
					{
						fromKey: 'stopPlace',
						ref: afterLeg,
					},
				);

				// No way they SHOULD insert a leg AFTER the dropoff leg, so always SHOULD have next leg...
				// const nextStartPlaceAsStopPlace = nextLeg
				// 	? createStandardizedPlacePatch('stopPlace', {
				// 			fromKey: 'startPlace',
				// 			ref: nextLeg,
				// 	  })
				// 	: undefined;

				const nextStopPlace = nextLeg
					? createStandardizedPlacePatch('stopPlace', {
							fromKey: 'stopPlace',
							ref: nextLeg,
					  })
					: undefined;

				Logger.debug({
					// afterLeg,
					extractedStopAsStart,
					newStartPlace,
					// newStopPlace,
					nextStopPlace,
				});

				const afterLayoverTransportLeg = {
					...afterLeg,
					isLayover: false,
					id: sourceProps.dryRun
						? // The only reason we put an ID in dryRun mode is to make
						  // visual alignment of lists in the console line up better
						  // e.g. when printing new legs via legChecklist()
						  `${afterLeg.id.substring(0, afterLeg.id.length - 2)}.1`
						: // create new leg by removing current ID when not in dryRun mode
						  undefined,
					...extractedStopAsStart,
					...newStopPlace,
				};

				// const newLeg = {
				// 	...afterLeg,
				// 	isLayover: false,
				// 	id: sourceProps.dryRun
				// 		? // The only reason we put an ID in dryRun mode is to make
				// 		  // visual alignment of lists in the console line up better
				// 		  // e.g. when printing new legs via legChecklist()
				// 		  `${afterLeg.id.substring(0, afterLeg.id.length - 2)}.2`
				// 		: // create new leg by removing current ID when not in dryRun mode
				// 		  undefined,
				// 	...newStartPlace,
				// 	...nextStartPlaceAsStopPlace,
				// };

				const modifiedNextLeg = {
					...nextLeg,
					...newStartPlace,
					...nextStopPlace,
				};

				// const newLeg = {
				// 	...afterLeg,
				// 	id: sourceProps.dryRun
				// 		? // The only reason we put an ID in dryRun mode is to make
				// 		  // visual alignment of lists in the console line up better
				// 		  // e.g. when printing new legs via legChecklist()
				// 		  `${afterLeg.id.substring(0, afterLeg.id.length - 2)}.1`
				// 		: // create new leg by removing current ID when not in dryRun mode
				// 		  undefined,
				// 	isLayover: false,
				// 	...newStartPlace,
				// 	...extractedStop,
				// };

				newLegs = [].concat(
					tripLegs.slice(0, currentLegIndex + 1),
					afterLayoverTransportLeg,
					// newLeg,
					modifiedNextLeg,
					tripLegs.slice(currentLegIndex + 2),
				);
			} else {
				// Previous leg is NOT layover, so basically split the leg in half

				const modifiedAfterLeg = {
					...afterLeg,
					...extractedStart,
					...newStopPlace,
				};

				const newLeg = {
					...afterLeg,
					id: sourceProps.dryRun
						? // The only reason we put an ID in dryRun mode is to make
						  // visual alignment of lists in the console line up better
						  // e.g. when printing new legs via legChecklist()
						  `${afterLeg.id.substring(0, afterLeg.id.length - 2)}.1`
						: // create new leg by removing current ID when not in dryRun mode
						  undefined,
					isLayover: false,
					...newStartPlace,
					...extractedStop,
				};

				newLegs = [].concat(
					tripLegs.slice(0, currentLegIndex),
					modifiedAfterLeg,
					newLeg,
					tripLegs.slice(currentLegIndex + 1),
				);
			}

			// console.warn(
			// 	`Wanted to create AND modify legs, but not going to`,
			// 	newLegs,
			// );

			// return { tripLegs };
		}

		Logger.warn(
			`createInsertTripLegPatch: Inserting leg into trip legs afterLeg`,
			isNode ? '' : { tripLegs, afterLeg, nextLeg, newLegs },
		);

		return { tripLegs: newLegs };
	}

	const newTripLeg = createNewTripLegDefinition(sourceProps);

	tripLegs.push(newTripLeg);

	Logger.debug(
		`createInsertTripLegPatch: Appended new leg to end of trip legs`,
		{ newTripLeg, tripLegs },
	);

	return { tripLegs };
};

export const createPlaceFieldPatch = (currentTrip, field, sourceProps) => {
	const fieldName =
		field === 'start'
			? 'pickupPlace'
			: field === 'dropoff'
			? 'dropoffPlace'
			: typeof field === 'object'
			? `${field.placeType}Place`
			: undefined;

	if (field.placeType === '_new' && sourceProps) {
		// Logger.error(
		// 	`createPlaceFieldPatch: Trying to create a patch for a '_new' field using sourceProps, not supported!`,
		// 	{ sourceProps, field },
		// );
		// return {};

		Logger.debug(
			`createPlaceFieldPatch: Trying to create a patch for a '_new' field using sourceProps ...`,
			{ sourceProps, field },
		);

		const result = createInsertTripLegPatch(currentTrip, sourceProps, field);
		// Logger.debug(`createPlaceFieldPatch: result of insert:`, result);
		// return { tripLegs: currentTrip.tripLegs };

		return result;
	}

	if (fieldName === undefined || !fieldName) {
		Logger.error(
			`createPlaceFieldPatch: Cannot create effective patch because invalid 'field' type or no field.placeType`,
			{
				field,
			},
		);
		return {};
	}

	const patch = createStandardizedPlacePatch(fieldName, sourceProps);

	// This case handles the pickupPlace / dropoffPlace for the top-level
	// trip modifications. Everything below this deals with trip-leg modifications
	if (typeof field === 'string') {
		console.warn(
			`createPlaceFieldPatch: created patch for string field '${field}'`,
			{ patch, sourceProps, field },
		);
		return patch;
	}

	const {
		placeType,
		leg: fieldLeg,
		leg: {
			id: legId,
			legIndex,
			isLayover,
			startPlace: { id: startPlaceId } = {},
			stopPlace: { id: stopPlaceId } = {},
		} = {},
	} = field;

	console.warn(`createPlaceFieldPatch: STARTING logic with field data:`, {
		placeType,
		legAtStart: { ...fieldLeg },
		field,
		startPlaceId,
		stopPlaceId,
	});

	const { tripLegs } = currentTrip;

	let previousLegPatch;
	let nextLegPatch;
	let changedBoth = false;

	if (isLayover) {
		// If places WERE identical for layover, then the UI only shows one place,
		// user only chooses (and only expects to choose) one place, so we must keep
		// data accurate and match expectations by changing both fields at once
		if (startPlaceId === stopPlaceId) {
			changedBoth = true;

			const otherField = placeType === 'start' ? 'stopPlace' : 'startPlace';

			const otherPatch = createStandardizedPlacePatch(otherField, sourceProps);

			Object.assign(patch, otherPatch);

			Logger.debug(
				`createPlaceFieldPatch: isLayover: places matched, so changed the otherField`,
				{
					otherField,
					placeType,
					otherPatch,
					patchAtTimeOfLogging: { ...patch },
				},
			);
		} else {
			console.warn(
				`createPlaceFieldPatch: isLayover: place did NOT match, so NOT changed the otherField`,
				{
					startPlaceId,
					stopPlaceId,
					placeType,
					patchAtTimeOfLogging: { ...patch },
				},
			);
		}
	}

	// If changing the START place of a leg, the previous stop SHOULD
	// have been identical (no logical reason why it wouldn't be), so FORCE
	// the update of the previous leg's stop point as well
	if (placeType === 'start' || changedBoth) {
		const listItemRef = tripLegs.find(({ id }) => id === legId);

		const currentLegIndex = tripLegs.indexOf(listItemRef);

		Logger.debug(`createPlaceFieldPatch: 'start' placeType, got index:`, {
			currentLegIndex,
			listItemRef,
			tripLegs,
		});

		// Not logical to have a layover at index 0 anyway, and there's nothing before it to change even if it was
		if (currentLegIndex > 0) {
			const previousLeg = tripLegs[currentLegIndex - 1];

			previousLegPatch = createStandardizedPlacePatch('stopPlace', sourceProps);

			Object.assign(previousLeg, previousLegPatch);

			Logger.debug(`createPlaceFieldPatch: updated previous leg with patch`, {
				previousLeg,
				previousLegPatch,
			});
		} else if (currentLegIndex < 0) {
			Logger.error(
				`createPlaceFieldPatch: Got 'start' field change, but could not find the layover leg ${legId} # ${legIndex} in the list of current trip legs, so we cannot update the previous legs stopPlace`,
				{
					tripLegs,
				},
			);
		} else {
			console.warn(
				`createPlaceFieldPatch: Got 'start' field change, but leg index is unusable ${legId} # ${legIndex}, so we cannot update the previous legs stopPlace`,
				{
					currentLegIndex,
					tripLegs,
				},
			);
		}
	}
	// If changing the END place of a leg, the next *start* SHOULD
	// have been identical (no logical reason why it wouldn't be), so FORCE
	// the update of the next leg's start point as well
	if (placeType === 'stop' || changedBoth) {
		const listItemRef = tripLegs.find(({ id }) => id === legId);

		const currentLegIndex = tripLegs.indexOf(listItemRef);

		Logger.debug(`createPlaceFieldPatch: 'start' placeType, got index:`, {
			currentLegIndex,
			listItemRef,
			tripLegs,
		});

		if (currentLegIndex < tripLegs.length) {
			const nextLeg = tripLegs[currentLegIndex + 1];

			nextLegPatch = createStandardizedPlacePatch('startPlace', sourceProps);

			Object.assign(nextLeg, nextLegPatch);

			Logger.debug(`createPlaceFieldPatch: updated next leg with patch`, {
				nextLeg,
				nextLegPatch,
			});
		} else if (currentLegIndex < 0) {
			Logger.error(
				`createPlaceFieldPatch: Got 'stop' field change, but could not find the layover leg ${legId} # ${legIndex} in the list of current trip legs, so we cannot update the previous legs stopPlace`,
				{
					tripLegs,
				},
			);
		}
		// else {
		// 	console.warn(
		// 		`createPlaceFieldPatch: Got 'stop' field change, but leg index is unusable ${legId} # ${legIndex}, so we cannot update the next legs startPlace`,
		// 		{
		// 			currentLegIndex,
		// 			tripLegs,
		// 		},
		// 	);
		// }
	} else {
		Logger.debug(`createPlaceFieldPatch: placeType not start/stop, its:`, {
			placeType,
			fieldLeg,
		});
	}
	// } else {
	// 	Logger.debug(
	// 		`createPlaceFieldPatch: leg is not a layover`,
	// 		fieldLeg,
	// 	);
	// }

	// Load the patch onto the leg for upload to the server
	Object.assign(fieldLeg, patch);

	// tripLegs.forEach((tripLeg) => {
	// 	// Just to be safe, assign the data, even though this SHOULD
	// 	// be an exact reference
	// 	if (fieldLeg.id === tripLeg.id) {
	// 		Object.assign(tripLeg, fieldLeg);
	// 	}
	// });

	console.warn(`createPlaceFieldPatch: created patch for leg field`, {
		patch,
		updatedLeg: fieldLeg,
		previousLegPatch,
		sourceProps,
		field,
		tripLegs,
	});

	return {
		tripLegs: currentTrip.tripLegs,
	};
};
