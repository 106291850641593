import { ServerStore } from 'shared/services/ServerStore';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import formatPhone from 'shared/utils/formatPhone';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import GenericSelectDialog from './GenericSelectDialog';

export default function PersonSelectDialog({ open, onSelect, onCancel }) {
	return (
		<GenericSelectDialog
			isVisible={open}
			onSelect={onSelect}
			onCancel={onCancel}
			placeholder={'Select a person'}
			rowComponent={({ name, email, phoneNum, onClick }) => (
				<ListItem button onClick={onClick}>
					<ListItemIcon>
						<AccountCircleIcon />
					</ListItemIcon>
					<ListItemText
						primary={name}
						secondary={`${formatPhone(phoneNum)}${
							phoneNum ? ' · ' : ''
						}${email || ''}`}
					/>
				</ListItem>
			)}
			dataQueryFn={async (search) => {
				const { list } = await ServerStore.GetUsers({
					search,
					// Speeds up server response by not annotating
					// with tons of extra linked props
					disableInflate: true,
				});
				return list;
			}}
		/>
	);
}
