import clsx from 'clsx';
import { IconButton, ClickAwayListener } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useCallback, useRef, useEffect } from 'react';
import SupportChatWidget from './SupportChatWidget';
import styles from './SupportChatPopup.module.scss';

export default function SupportChatPopup({
	widgetProps = {
		lightMode: false,
	},
	position = styles.bottomRight,
	isOpen = true,
	className,
	onClose,
}) {
	const flagRef = useRef(false);
	const onClickAway = useCallback(() => {
		if (flagRef.current) {
			onClose();
		}
	}, [onClose]);

	const timerRef = useRef();
	useEffect(() => {
		timerRef.current = setTimeout(() => {
			flagRef.current = isOpen;
		});
		return () => {
			clearTimeout(timerRef.current);
		};
	}, [isOpen]);

	return (
		<ClickAwayListener onClickAway={onClickAway}>
			<div
				className={clsx(
					styles.root,
					position && styles[position],
					className,
					isOpen && styles.isOpen,
				)}
			>
				{isOpen && <SupportChatWidget {...widgetProps} />}
				<IconButton className={styles.closeButton} onClick={onClose}>
					<Close />
				</IconButton>
			</div>
		</ClickAwayListener>
	);
}

SupportChatPopup.BottomRight = styles.bottomRight;
SupportChatPopup.BottomLeft = styles.bottomLeft;
SupportChatPopup.TopLeft = styles.topLeft;
SupportChatPopup.TopRight = styles.topRight;
SupportChatPopup.Center = styles.center;
