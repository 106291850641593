/* eslint-disable no-param-reassign */

// Based on https://stackoverflow.com/a/16743805 - the MAX resolution we need is 6/7 decimal places
export const MAX_RESOLUTION = 6;

// Poor-mans rounding
const round = (num) => parseFloat(parseFloat(num).toFixed(MAX_RESOLUTION));

/**
 * Based on https://stackoverflow.com/a/16743805 - the MAX resolution we need is 7 decimal places
 * so we round lat/lng to 7 max decimals
 * @param {number} lat Decimal Latitude
 * @param {number} lng Decimal Longitude
 */
export default function normalizeLatLng(lat, lng) {
	if (!lat && !lng) {
		return [];
	}

	if (typeof lat === 'object') {
		const [newLat, newLng] = normalizeLatLng(lat.lat, lat.lng);
		lat.lat = newLat;
		lat.lng = newLng;
		return lat;
	}

	// Single arg? Return single value
	if (lat && !lng) {
		return round(lat);
	}

	const result = [round(lat), round(lng)];
	// Also support object destructuring
	result.lat = lat;
	result.lng = lng;

	return result;
}
