/* eslint-disable no-console */
import EventEmitter from 'eventemitter3';
import WebSocket from 'isomorphic-ws';
import exponentialDelayFactory from './exponentialDelay';
import localLogger, { isNode } from './IsomorphicLogger';

const delayFactory = exponentialDelayFactory({
	initialDelay: 1500,
	multiplier: 1.25,
	maxDelay: 10000,
});

export default class SocketClient extends EventEmitter {
	constructor(
		url,
		// Default to only accepting self signed certs in dev env
		// This also only works server-side - browsers enforce their own rules.
		socketOpts = { rejectUnauthorized: process.env.NODE_ENV !== 'development' },
	) {
		super();

		if (!url) {
			throw new Error(`No URL given for connection`);
		}

		this.url = url;
		this.socketOpts = socketOpts;
		this.pendingData = [];
		this._connect();
	}

	connect() {
		this._connect();
	}

	_connect(isReconnect) {
		const { url, socketOpts } = this;

		this.emit(SocketClient.CONNECTING);
		const socketArgs = isNode ? [socketOpts] : [];
		const ws = new WebSocket(url, ...socketArgs);

		// localLogger.debug(`SocketClient: Connecting to ${url}...`);

		this._dataMsg = (e) => this._data(e.data);
		ws.onmessage = this._dataMsg;
		ws.onerror = (err) => {
			ws.onmessage = null;
			// this.connected = false;
			if (!this.closed && isNode) {
				localLogger.warn(
					'Caught error from socket:',
					isNode ? err.message : err,
				);
			}
			// this._delayReconnect();
		};

		ws.onclose = () => {
			ws.onmessage = null;
			this.connected = false;

			if (!this.reconnectDisabled) {
				// localLogger.warn(`Socket closed to ${url}, reconnecting...`);
				this._delayReconnect();
			}

			this.emit(SocketClient.CLOSED);
			clearTimeout(this.delayResetTimer);
		};

		ws.onopen = () => {
			this.connected = true;
			this.closed = false;

			this.delayResetTimer = setTimeout(() => delayFactory.reset(), 500);

			if (this.pendingData.length) {
				this.pendingData.forEach((d) => this.ws.send(d));
				this.pendingData = [];
			}

			this.emit(SocketClient.CONNECTED);

			if (isReconnect) {
				this.emit(SocketClient.RECONNECTED);
			}
		};

		this.ws = ws;
	}

	send(ref) {
		// localLogger.info("[SocketClient] sending message:", ref);
		this.sendData(JSON.stringify(ref));
	}

	sendData(data) {
		if (!this.ws) {
			return;
		}
		if (this.ws.readyState === WebSocket.OPEN) {
			this.ws.send(data);
		} else {
			this.pendingData.push(data);
		}
	}

	disableReconnect() {
		this.reconnectDisabled = true;
	}

	_delayReconnect() {
		this.ws = null;
		this.cancelReconnect();

		if (this.reconnectDisabled) {
			return;
		}

		const delay = delayFactory();
		// localLogger.debug(`Delay: `, delay);

		this._reconnectTimer = setTimeout(() => {
			this._connect(true);
			this._reconnectTimer = null;
		}, delay);
	}

	cancelReconnect() {
		clearTimeout(this._reconnectTimer);
		this._reconnectTimer = null;
	}

	isReconnecting() {
		return !!this._reconnectTimer;
	}

	_data(buffer) {
		// TODO: Handle
		if (!buffer) {
			return;
		}
		try {
			const json = JSON.parse(buffer);
			this.emit(SocketClient.MESSAGE, json);
		} catch (ex) {
			localLogger.error(
				`Error parsing json message from server at ${this.url}:`,
				ex,
				{ buffer },
			);
		}
	}

	close() {
		this.connected = false;
		this.closed = true;
		if (this.ws) {
			this.ws.close();
		}

		this.disableReconnect();
	}
}

// CRA doesn't support class props when importing from node modules
SocketClient.RECONNECTED = 'RECONNECTED';

SocketClient.CONNECTED = 'CONNECTED';

SocketClient.MESSAGE = 'MESSAGE';

SocketClient.CLOSED = 'CLOSED';

SocketClient.CONNECTING = 'CONNECTING';
