/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { legsToPlaces } from 'shared/utils/tripLegPlaceHelpers';
import normalizePlaceName from 'shared/utils/normalizePlaceName';
import {
	MARKER_COLORS,
	markerTypeFromIndex,
	sampleWaypointGradient,
} from 'shared/hooks/useTripPath';

export function useTripPlaces(currentTrip) {
	const {
		id: tripId,
		// dropoffPlace,
		// dropoffPlaceAddressText,
		// dropoffPlaceName,
		// pickupPlace,
		// pickupPlaceAddressText,
		// pickupPlaceName,
		tripLegs,
		// dropoffTbd,
	} = currentTrip || {};

	const [places, waypoints] = useMemo(
		() => {
			if (!tripId) {
				// console.warn(`no tripId`);
				return [];
			}

			// eslint-disable-next-line no-shadow
			const places =
				tripLegs && tripLegs.length
					? legsToPlaces(tripLegs, {
							// debug: true,
					  })
					: [];

			places.forEach((place, index) => {
				const markerType = markerTypeFromIndex(index, places);
				const markerColor =
					markerType === 'waypoint'
						? sampleWaypointGradient(index, places.length - 1)
						: MARKER_COLORS[markerType];

				Object.assign(place, { markerType, markerColor });
			});

			// eslint-disable-next-line no-shadow
			const waypoints = places.slice(1, -1);
			return [places, waypoints];
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			// Manually specing deps because tripLegs can change reference value,
			// but data can stay the same, so only recalc legs for data changes we care about
			// eslint-disable-next-line react-hooks/exhaustive-deps
			(tripLegs || [])
				.map((x) =>
					[
						x.startPlace && x.startPlace.id,
						x.stopPlace && x.stopPlace.id,
						x.isLayover,
						x.userEstimatedLayoverMinutes,
						x.userEstimatedLayoverVariable,
						x.layoverMinutes,
						x.id,
					].join(),
				)
				.join(),
		],
	);

	// console.log(`useTripPlaces:`, { places, waypoints });

	return [places, waypoints];
}
