/* eslint-disable no-param-reassign */
import { v4 as uuid } from 'uuid';
import MessageTypes from './MessageTypes';
import { defer } from './defer';
import exponentialDelayFactory from './exponentialDelay';
import localLogger from './IsomorphicLogger';

const delayFactory = exponentialDelayFactory({
	initialDelay: 30000,
	multiplier: 1.33,
	maxDelay: 30000,
});

const MAX_RETRIES = 5;

const InterceptSymbol = Symbol('@WebSocketMessageWrapper:InterceptHook');
const PromiseMapSymbol = Symbol('@WebSocketMessageWrapper:RequestPromises');

// Utility to wrap API requests with a WebSocketMessageWrapper
// and send them down the socket to the backend and wait for the response.
// Don't use this directly in code, wrap it in a utility. See example
// usage in JobWorkerService.apiGet and .apiPost
export default async function WebSocketMessageWrapper(
	hookableService,
	method,
	endpoint,
	data = null,
	{
		autoRetry = true,
		retryCount = 0,
		clientTransactionId,
		sessionRecordingUrl,
	} = {},
) {
	// return server[method](`/api/v1/${endpoint}`, ...args);
	const requestId = uuid();

	let promises = hookableService[PromiseMapSymbol];
	if (!promises) {
		promises = {};
		hookableService[PromiseMapSymbol] = promises;
	}

	const promise = defer();
	promise.start = Date.now();

	// Store these for logging below, as the callback can't
	// capture them every time
	promise.method = method;
	promise.endpoint = endpoint;
	promise.data = data;

	promise.timeout = setTimeout(() => {
		if (autoRetry && retryCount < MAX_RETRIES) {
			localLogger.warn(
				`Request for ${method} ${endpoint} timed out, autoRetry # ${retryCount}/${MAX_RETRIES} ...`,
			);
			promise.timedout = true;
			promise.resolve(
				WebSocketMessageWrapper(hookableService, method, endpoint, data, {
					autoRetry,
					retryCount: retryCount + 1,
				}),
			);
		} else {
			promise.reject(new Error(`Request for ${method} ${endpoint} timed out`));
		}
	}, delayFactory());

	if (!hookableService[InterceptSymbol]) {
		const callback = ({ type, requestId: returnRequestId, ...result }) => {
			if (type === MessageTypes.WebSocketMessageWrapper) {
				const requestPromise = promises[returnRequestId];
				if (requestPromise) {
					const end = Date.now();
					const diff = end - requestPromise.start;
					if (WebSocketMessageWrapper.consoleLogRequests) {
						localLogger.debug(
							` * [${requestPromise.method.toUpperCase()}] /${
								requestPromise.endpoint
							} (${diff}ms):`,
							{ request: requestPromise.data, result },
						);
					}

					if (requestPromise.timedout) {
						delete promises[returnRequestId];
						localLogger.warn(`Request timed out, ignoring response`);
						return true;
					}

					delayFactory.reset();
					clearTimeout(requestPromise.timeout);

					requestPromise.resolve(result);
					delete promises[returnRequestId];

					// Truthy return stop BackendService from processing this socket msg further
					return true;
				}

				localLogger.warn(`No requestPromise for requestId ${returnRequestId}`);
			}

			return false;
		};

		hookableService.addMessageHook(WebSocketMessageWrapper, callback);
		hookableService[InterceptSymbol] = callback;
	}

	const msg = {
		type: MessageTypes.WebSocketMessageWrapper,
		method,
		path: `/api/v1/${endpoint}`,
		body: data,
		requestId,
		clientTransactionId,
		sessionRecordingUrl,
	};

	// localLogger.debug(`RPC:`, msg);
	promises[requestId] = promise;

	hookableService.sendMessage(msg);

	return promise;
}

global.WebSocketMessageWrapper = WebSocketMessageWrapper;

// WebSocketMessageWrapper.consoleLogRequests = true;
