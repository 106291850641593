// Access levels like security rings in linux:
// Ring 0 - Super User (ex josiah)
// Ring 1 - Administrator (ex account owner)
// Ring 2 - Full User (ex concierge/driver/valet manager/valet - what they can do is based on appRoles)
// --
// Ring 3 - Basic User (ex driver/member/valet manager/Client Account User)
// Ring 4 - Guest User (ex....valet?)
// ---------------------------------- ^ Access To Tenant
// Ring 5 - Public Untrusted

export const SecurityLevels = {
	SuperUser: 'SuperUser',
	Administrator: 'Administrator',
	User: 'User',
	// BasicUser: 'BasicUser',
	// GuestUser: 'GuestUser',
	Public: 'Public',
};

export const SecurityLevelIndex = [
	SecurityLevels.SuperUser,
	SecurityLevels.Administrator,
	SecurityLevels.User,
	// SecurityLevels.BasicUser,
	// SecurityLevels.GuestUser,
	SecurityLevels.Public,
];

export const SecurityLevelOptions = [
	{
		id: SecurityLevels.SuperUser,
		label: 'SuperUser',
		description:
			'Full access to the entire platform, can access data in any tenant and switch tenants',
	},
	{
		id: SecurityLevels.Administrator,
		label: 'Administrator',
		description:
			'Complete access to the account, can change account name/billing settings and add/remove users without any role restrictions',
	},
	{
		id: SecurityLevels.User,
		label: 'User',
		description:
			'Can access the internal versions of the apps for this tenant, based on the roles this user is assigned',
	},
	// {
	// 	id: SecurityLevels.BasicUser,
	// 	label: 'Basic User',
	// 	description:
	// 		'Requires explicit access to be granted to specific apps in this tenant, based on the labels applied to this user.',
	// },
	// {
	// 	id: SecurityLevels.GuestUser,
	// 	label: 'Guest User',
	// 	description:
	// 		'Explicit access to apps only enabled by adding relevant labels to this user',
	// },
	{
		id: SecurityLevels.Public,
		label: 'Public User',
		description:
			'No access to this tenant other than explicit public-facing pages for this tenant',
	},
];
