import { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BackendService from 'shared/services/BackendService';
import clsx from 'clsx';
import { useAuthorization } from 'shared/services/AuthService';
import DrawerNav from './DrawerNav';

import styles from './PartnerPageWrapper.module.scss';
import PartnerLogoToolbarItem from './PartnerLogoToolbarItem';

export default function PartnerPageWrapper({
	pageTitleText,
	children,
	ctaPanelContent,
	alwaysHideDrawer = true,
	className,
	partnerLogoUrl: partnerLogoUrlInput,
}) {
	useEffect(() => {
		BackendService.storePageAsLoginRedir();
	});

	const { account } = useAuthorization() || {};
	const {
		// id: accountId,
		isPartnerAccount,
		partnerLogoUrl,
	} = account || {};
	// console.log(`Account info`, account);

	const dark = false; // useMediaQuery('(prefers-color-scheme: dark)');

	return (
		<div
			className={clsx(
				styles.root,
				dark && styles.darkMode,
				dark && `is-dark-mode`,
				className,
			)}
		>
			<DrawerNav alwaysHideDrawer={alwaysHideDrawer} />
			<div className={styles.partnerLogo}>
				<PartnerLogoToolbarItem
					url={partnerLogoUrlInput || partnerLogoUrl}
					size="large"
				/>
			</div>

			<h1 className={styles.masthead}>{pageTitleText}</h1>

			<div className={styles.content}>{children}</div>
			{ctaPanelContent && (
				<div className={styles.ctaButtons}>{ctaPanelContent}</div>
			)}
		</div>
	);
}
