import { Avatar, CircularProgress } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import styles from './UserAvatar.module.scss';

export default function UserAvatar({ user, inbound = false, className }) {
	const { name, sending = false, photoUrl } = user || {};
	const initial = `${name || '?'}`.substring(0, 1);

	// Default for source.boringAvatar is circle, but we want full coverage of the rect
	// so we can shape as desired via CSS
	const photoUrlModified = `${photoUrl || ''}`.includes('source.boringavatar')
		? `${photoUrl}&square=true`
		: photoUrl;

	// console.log(`UserAvatar render debug:`, { name, sending, photoUrl });

	if (!sending && photoUrl) {
		return (
			<Avatar
				className={clsx(styles.root, inbound && styles.inbound, className)}
				alt={name}
				src={photoUrlModified}
			/>
		);
	}
	return (
		<Avatar className={clsx(styles.root, inbound && styles.inbound, className)}>
			{sending ? <CircularProgress /> : initial}
		</Avatar>
	);
}
