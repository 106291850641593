/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useEffect, useRef } from 'react';

// When releasing the lsat ref, how long do we wait before calling .destroy()
const DESTROY_ON_RELEASE_REF_0_DELAY = 10 * 1000;

class ManagedInstanceCache {
	static debug = false;

	static instanceCache = {};

	constructor(key, instance) {
		this.key = key;
		this.instance = instance;
		this.refCounter = 0;
	}

	use(options) {
		this.refCounter += 1;

		const { key, refCounter, instance } = this;
		if (ManagedInstanceCache.debug) {
			console.warn(`ManagedInstanceCache.use: ${key}/${refCounter}`);
		}

		if (typeof instance.updateOptions === 'function') {
			instance.updateOptions(options);
		}

		if (this.destroyTimer) {
			clearTimeout(this.destroyTimer);
			this.destroyTimer = undefined;
		}

		return instance;
	}

	inUse() {
		const { refCounter } = this;
		return !!(refCounter > 0);
	}

	release() {
		this.refCounter -= 1;

		const { key, instance, refCounter } = this;
		if (ManagedInstanceCache.debug) {
			console.warn(`ManagedInstanceCache.release: ${key}/${refCounter}`);
		}

		const inUse = this.inUse();
		if (!inUse && instance && typeof instance.destroy === 'function') {
			clearTimeout(this.destroyTimer);
			this.destroyTimer = setTimeout(() => {
				if (this.inUse()) {
					return;
				}

				if (ManagedInstanceCache.debug) {
					console.warn(`ManagedInstanceCache.DESTROY: ${key}/${refCounter}`);
				}

				instance.destroy();
				delete ManagedInstanceCache.instanceCache[key];
			}, DESTROY_ON_RELEASE_REF_0_DELAY);
		}

		return !!inUse;
	}

	static getInstance(Class, key, options) {
		if (key === undefined) {
			// No key given? Don't do anything
			if (ManagedInstanceCache.debug) {
				console.warn(
					`ManagedInstanceCache.CREATE: ${key} === undefined, not creating anything`,
				);
			}
			return undefined;
		}

		const instKey = `${Class.name}:${key}`;

		let ref = this.instanceCache[instKey];
		if (ref) {
			return ref.use(options);
		}

		ref = new ManagedInstanceCache(instKey, new Class(options));
		if (ManagedInstanceCache.debug) {
			console.warn(`ManagedInstanceCache.CREATE: ${key}`);
		}
		this.instanceCache[instKey] = ref;

		return ref.use();
	}

	static releaseInstance(Class, key) {
		const instKey = `${Class.name}:${key}`;
		const ref = this.instanceCache[instKey];
		if (!ref) {
			return false;
		}

		return ref.release();
	}
}

export function useInstanceCache({ Class, key, options }) {
	if (!key && key !== undefined) {
		throw new Error(
			`Cannot useInstanceCache without a 'key' (however, 'undefined' is okay - just won't create an instance if key === undefined)`,
		);
	}

	if (!Class) {
		throw new Error(`Cannot useInstanceCache without a 'Class'`);
	}

	if (!Class.name) {
		throw new Error(
			`Cannot useInstanceCache without a 'Class' that has a .name prop`,
		);
	}

	// Set up the initial key and instance we received from props (changes will
	// applied in useEffect - note that 'key' must change if options change)
	const keyRef = useRef();
	const instRef = useRef();

	const { current: lastKey } = keyRef;

	// If key changes, release previous instance and get a new instance
	if (lastKey !== key) {
		// if (lastKey) {
		// 	ManagedInstanceCache.releaseInstance(Class, lastKey);
		// }

		keyRef.current = key;
		instRef.current = ManagedInstanceCache.getInstance(Class, key, options);
	}

	useEffect(() => {
		// When unmounted, release the instance we used
		// const { current: currentKey } = keyRef;
		const currentKey = key;
		if (ManagedInstanceCache.debug) {
			console.warn(`ManagedInstanceCache.MOUNT: ${currentKey}`);
		}

		return () => {
			let unmountKey = currentKey || keyRef.current;
			if (ManagedInstanceCache.debug) {
				console.warn(`ManagedInstanceCache.UNMOUNT: ${unmountKey}`);
			}
			ManagedInstanceCache.releaseInstance(Class, unmountKey);
		};
		// return () => {
		// 	if (instRef.current) {
		// 		instRef.current.release();
		// 	}
		// };
	}, [Class, key]);

	return instRef.current;
}

// ManagedInstanceCache.debug = true;
