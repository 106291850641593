import React from 'react';
import clsx from 'clsx';
import styles from './SimpleBadge.module.scss';

export default function SimpleBadge({ children, className }) {
	return (
		<span className={clsx('SimpleBadge-root', styles.root, className)}>
			{children}
		</span>
	);
}
