import useSWR from 'swr';
import { useCallback, createContext, useContext, useMemo } from 'react';

import { useAuthorization } from 'shared/services/AuthService';
import { ServerStore } from 'shared/services/ServerStore';
import { MessageTypes } from 'shared/utils/MessageTypes';
import { useConditionallyHandleErrors } from 'shared/hooks/useConditionallyHandleErrors';
import {
	useMessageHook,
	usePoolSubscription,
} from 'shared/services/BackendService';
import { getStatusSummaryFlags } from 'shared/hooks/useTripInfoCollator';

const TripListContext = createContext();

export function useTripListContext() {
	const state = useContext(TripListContext);
	if (state === undefined) {
		throw new Error(
			`Must useTripListContext inside a TripListProvider`,
		);
	}
	return state;
}

export default function TripListProvider({ children }) {
	const { account } = useAuthorization() || {};
	const { id: accountId } = account || {};

	const catchErrors = useConditionallyHandleErrors();

	const {
		data: { trips: tripList, ...data } = {},
		isValidating,
		revalidate,
	} = useSWR(['partner-trips'], () =>
		catchErrors(
			ServerStore.GetTrips({
				partnerLevelTrips: true,
			}),
		),
	);

	useMessageHook(
		useCallback(
			// eslint-disable-next-line no-shadow
			({ type, ...data }) => {
				if (type === MessageTypes.TripStatusChanged) {
					// eslint-disable-next-line no-console
					console.log(
						`Trip updated over socket, refreshing API call`,
						{ data },
					);
					revalidate();
				}
			},
			[revalidate],
		),
	);

	// Get notified about all trips on the account.
	usePoolSubscription('account_trips', accountId);

	const counts = useMemo(() => {
		const buckets = {};

		(tripList || []).forEach(({ status }) => {
			const flags = getStatusSummaryFlags(status);
			Object.entries(flags).forEach(([flagName, isFlag]) => {
				if (!isFlag) {
					return;
				}

				if (!buckets[flagName]) {
					buckets[flagName] = 0;
				}

				buckets[flagName] += 1;
			});
		});

		return buckets;
	}, [tripList]);

	const contextState = {
		isLoading: !tripList || isValidating,
		metadata: data,
		reloadList: revalidate,
		trips: tripList,
		counts,
	};

	// console.log(`TripListProvider contextState`, contextState);

	return (
		<TripListContext.Provider value={contextState}>
			{children}
		</TripListContext.Provider>
	);
}
