import { toast } from 'react-toastify';

const lastToastRef = { current: undefined };
export function showToast(
	message,
	toastSeverity = 'info',
	hideAfter = 7000,
	toastOpts = {},
) {
	// clearTimeout(hideTimerRef.current);
	if (!message) {
		// setToastState(message);
		if (lastToastRef.current) {
			toast.dismiss(lastToastRef.current);
			lastToastRef.current = undefined;
		}
		return;
	}

	// if (hideAfter) {
	// 	hideTimerRef.current = setTimeout(
	// 		() => setToastState(null),
	// 		hideAfter,
	// 	);
	// }

	// setToastState(message);
	// setSeverity(toastSeverity === 'warn' ? 'warning' : toastSeverity);

	const typeFn = ['success', 'info', 'warn', 'error'].includes(toastSeverity)
		? toastSeverity
		: 'info';
	toast[typeFn](message, { ...toastOpts, autoClose: hideAfter });
}
