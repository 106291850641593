/* eslint-disable no-console */
import AppConfig from 'shared/config';
import { useCallback, forwardRef } from 'react';

import {
	// eslint-disable-next-line no-unused-vars
	ServerStore,
	createClientTransactionId,
} from 'shared/services/ServerStore';

import useSWR from 'swr';
import { useAuthorization } from 'shared/services/AuthService';

export const ATTACHMENT_HOST =
	process.env.NODE_ENV !== 'production'
		? `//${AppConfig.backendTunnelHost}`
		: `//${AppConfig.apiHost}`;

export const createSecureAttachmentUrl = (
	attachmentId,
	name,
	type = '',
	token,
	endpoint = 'attachments',
) =>
	attachmentId
		? `${ATTACHMENT_HOST}/${endpoint}/${attachmentId}/${name}?type=${type}&token=${token}&clientTransactionId=${createClientTransactionId()}`
		: '';

export const PREVIEW_SOURCE_TYPE = 'thumb:preview';

const InjectAttachmentUrl = forwardRef(
	(
		{ id, name, render, endpoint = 'attachments', debug = false, ...props },
		ref,
	) => {
		const { token } = useAuthorization();

		const { data: url, mutate: setUrl } = useSWR(
			[token, id, name, 'full-url'],
			// eslint-disable-next-line no-shadow
			(token, id, name) =>
				createSecureAttachmentUrl(id, name, undefined, token, endpoint),
		);
		const { data: previewUrl, mutate: setPreviewUrl } = useSWR(
			[token, id, name, 'preview-url'],
			// eslint-disable-next-line no-shadow
			(token, id, name) =>
				createSecureAttachmentUrl(
					id,
					name,
					PREVIEW_SOURCE_TYPE,
					token,
					endpoint,
				),
		);

		const onError = useCallback(
			(error) => {
				if (url) {
					console.log(`Error with attachment url ${url}`, error);
					setUrl(undefined, false);
				}
			},
			[setUrl, url],
		);

		const onPreviewError = useCallback(
			(error) => {
				if (previewUrl) {
					if (previewUrl !== url) {
						// console.log(
						// 	`Error with attachment previewUrl ${previewUrl}, will set to full url '${url}'`,
						// 	error,
						// );
						setPreviewUrl(url, false);
					} else {
						console.log(
							`Error with attachment previewUrl set to full Url '${previewUrl}', clearing url`,
							error,
						);
						setPreviewUrl(undefined, false);
					}
				}
			},
			[previewUrl, setPreviewUrl, url],
		);

		if (debug) {
			console.log(`InjectAttachmentUrl`, previewUrl);
		}

		return render({
			id,
			name,
			url,
			previewUrl,
			onError,
			onPreviewError,
			ref,
			...props,
		});
	},
);

export default InjectAttachmentUrl;

export const scaledSize = (width, height, propKey) => {
	if (!width || !height) {
		return undefined;
	}

	// Matches max width/height for <img> in the css
	const maxWidth = 320;
	const maxHeight = 240;

	const origAspect = width / height;
	const scaledWidth = origAspect >= 1 ? maxWidth : maxHeight * origAspect;
	const scaledHeight = origAspect >= 1 ? maxWidth / origAspect : maxHeight;
	const sizeProps = {
		width: scaledWidth,
		height: scaledHeight,
	};

	// console.log('scaledSize', {
	// 	origAspect,
	// 	scaledWidth,
	// 	scaledHeight,
	// 	width,
	// 	height,
	// });

	const prop = sizeProps[propKey || 'width'];
	if (Number.isNaN(prop)) {
		return undefined;
	}

	return prop;
};
