/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/**
 * Note that this file is used both in the browser and on the backend, so take care to only import
 * files/utils that are isomorphic
 */
import { ianaToFriendlyNames } from './ianaAbbvr';
import { convertUTCfromTimezoneEnteredToTimezoneIntended } from './shiftUtcDateTimezone';
import formatTimestamp from './formatTimestamp';
import normalizePlaceName from './normalizePlaceName';
import { toDurationString } from './toDurationString';
import Logger, { isNode } from './IsomorphicLogger';
import { getTimezone } from './getTimezone';
import TripStatus from './TripStatus';

export function calculateTripPriceSummary(
	trip,
	{ debug = false, logger = Logger } = {},
) {
	// // eslint-disable-next-line no-param-reassign
	// debug = true;
	const {
		id: tripId,
		status: tripStatus,
		// dropoffTbd,

		// dropoffPlaceName,
		// pickupPlaceName,
		// pickupPlaceAddressText,
		// dropoffPlaceAddressText,
		// pickupPlace,
		// dropoffPlace,

		longPickupDriverPay = 0,
		longDropoffDriverPay = 0,

		// driver: { id: driverId, name: driverName, photoUrl: driverPhotoUrl } = {},
		user: {
			id: userId,
			name: userName,
			photoUrl: userPhotoUrl,
			packageName,
			stripeCustomerId,
		} = {},

		// user,

		// isScheduled,
		// scheduledPickupAt,

		// estimatedDropoffAt,
		// estimatedDriveTimeMins,
		// estimatedPickupWaitTimeMins,

		// pickedupAt,
		// dropoffAt,

		// isGuestTrip,

		// driverWaitingMinutes,
		// ridingMinutes,
		// totalMemberMinutes,
		// userDriverRating,
		// userDriverComments,
		// tipAmount,

		tipAmount: actualTipAmount = 0.0, // must be a number, can't be undefined

		driverPayValue,
		driverMileageValue,
		// driverOnCallValue,
		// driverUserRating,
		// driverUserComments,
		// createdBy: { name: createdByName } = {},
		// driverOnCallMinutes,
		// totalMiles,

		// estimatedSubscriptionMinutes,
		estimatedDriverTimePay,
		estimatedDriverOnCallPay,
		estimatedDriverMileagePay,
		estimatedMemberCost,
		estimatedMemberTipPercent,
		// estimatedDriveDistance,
		// estimatedTotalMemberMinutes,

		specialPricingModifier,
		pricingModifierMessage,

		// conversation,

		// isAirportTrip,
		// isAirportPickup,
		// flightNumber,
		// flightInfo,

		isFlatRateTrip,
		// isFlatRateManuallyAssigned,
		// flatRateAssignedBy,
		// flatRateAssignedAt,

		flatRateTierLabel,
		// flatRateMinuteTier,
		// flatRateMileTier,
		flatRateDriverPay,
		flatRateSupportPay,
		flatRateMemberCharge,

		hasMemberCostOverride,
		memberCostOverride,
		memberCostOverrideReason,
		// memberCostOverrideBy,
		// memberCostOverrideAt,
		// memberCostPreOverride,
		memberCostValue,

		tripMarkup,
		// memberCostValue: tripResalePrice,

		estimatedSupportTimePay,
		supportPayValue,
	} = trip || {};

	const markup = 1 + (tripMarkup || 0.3);

	// const {
	// 	airline,
	// 	ident,
	// 	aircraftType,
	// 	flightTime,
	// 	fieldDeparture,
	// 	actualDeparture,
	// 	estimatedArrival,
	// 	actualArrival,
	// 	diverted: flightDiverted,
	// 	destination,
	// 	originName,
	// 	originCity,
	// 	destinationName,
	// 	destinationCity,
	// 	isOnTime,
	// 	isEarly,
	// 	isLate,
	// 	estimatedArrivalDifferenceSeconds,
	// } = flightInfo || {};

	const payoutSumPreTip = isFlatRateTrip
		? flatRateDriverPay // + flatRateSupportPay
		: longDropoffDriverPay +
		  longPickupDriverPay +
		  estimatedDriverTimePay +
		  estimatedDriverMileagePay +
		  estimatedSupportTimePay;
	//   +estimatedDriverOnCallPay;

	if (debug) {
		logger.debug(`payoutSumPreTip debug:`, {
			tripId,
			isFlatRateTrip,
			flatRateDriverPay,
			longDropoffDriverPay,
			longPickupDriverPay,
			estimatedDriverTimePay,
			estimatedDriverMileagePay,
			estimatedSupportTimePay,
			// estimatedDriverOnCallPay,
			// specialPricingModifier,
		});
	}

	const payoutSumPreTipWithModifier = payoutSumPreTip; //* (specialPricingModifier || 1);

	const estimatedTip =
		`${packageName}`.includes('Vaya Internal') && !stripeCustomerId
			? 0
			: `${Math.round(estimatedMemberTipPercent * 100)}%`;

	const estimatedMemberCostPreSubscription = parseFloat(
		Math.max(
			1,
			hasMemberCostOverride
				? memberCostOverride
				: isFlatRateTrip
				? flatRateMemberCharge
				: payoutSumPreTip * markup,
		).toFixed(2),
	);

	if (debug) {
		logger.debug(`estimatedMemberCostPreSubscription debug:`, {
			tripId,
			hasMemberCostOverride,
			memberCostOverride,
			isFlatRateTrip,
			flatRateMemberCharge,
			payoutSumPreTip,
			tripMarkup,
			markup,
		});
	}

	const tipAmount =
		`${packageName}`.includes('Vaya Internal') && !stripeCustomerId
			? 0
			: actualTipAmount;

	const actualPayoutSumPreTip = isFlatRateTrip
		? flatRateDriverPay // + flatRateSupportPay
		: longDropoffDriverPay +
		  longPickupDriverPay +
		  driverPayValue +
		  driverMileageValue;
	//    +
	//   driverOnCallValue;

	const actualPayoutSumPreTipWithModifier = actualPayoutSumPreTip; // * (specialPricingModifier || 1);

	const estimatedDriverPay = isFlatRateTrip
		? flatRateDriverPay
		: longDropoffDriverPay +
		  longPickupDriverPay +
		  estimatedDriverTimePay +
		  estimatedDriverMileagePay;

	const estimatedSupportPay = isFlatRateTrip
		? flatRateSupportPay
		: estimatedSupportTimePay;

	const estimatedResalePrice = estimatedMemberCostPreSubscription;

	const actualDriverPay = isFlatRateTrip
		? flatRateDriverPay
		: longDropoffDriverPay +
		  longPickupDriverPay +
		  driverPayValue +
		  driverMileageValue;
	const actualSupportPay = isFlatRateTrip
		? flatRateSupportPay
		: supportPayValue;

	const actualResalePrice = memberCostValue; // tripResalePrice;

	const actualResalePreSubscription = parseFloat(
		Math.max(
			1,
			hasMemberCostOverride
				? memberCostOverride
				: isFlatRateTrip
				? flatRateMemberCharge
				: actualPayoutSumPreTipWithModifier * markup,
		).toFixed(2),
	);

	const tripCompleted = [
		TripStatus.RideCompleted,
		TripStatus.UserCompleted,
		TripStatus.UserCanceled,
		TripStatus.DriverCanceled,
		TripStatus.NoShow,
	].includes(tripStatus);

	const driverPay = tripCompleted ? actualDriverPay : estimatedDriverPay;
	const supportPay = tripCompleted ? actualSupportPay : estimatedSupportPay;
	const resalePrice = tripCompleted ? actualResalePrice : estimatedResalePrice;

	if (debug) {
		logger.warn(`actualDriverPay debug:`, {
			tripId,
			actualDriverPay,
			flatRateDriverPay,
			longDropoffDriverPay,
			longPickupDriverPay,
			driverPayValue,
			driverMileageValue,
			tripCompleted,
			actualResalePreSubscription,
			driverPay,
			supportPay,
			resalePrice,
			markup,
		});
	}

	return {
		tripCompleted,
		payoutSumPreTip,
		payoutSumPreTipWithModifier,
		estimatedTip,
		estimatedMemberCostPreSubscription,
		estimatedMemberCost,
		tipAmount,
		actualPayoutSumPreTip,
		actualPayoutSumPreTipWithModifier,
		estimatedDriverPay,
		estimatedSupportPay,
		estimatedResalePrice,
		actualDriverPay,
		actualSupportPay,
		actualResalePrice,
		actualResalePreSubscription,
		driverPay,
		supportPay,
		resalePrice,

		estimateExplainer: {
			hasMemberCostOverride,
			memberCostOverride,
			memberCostOverrideReason,

			specialPricingModifier,
			pricingModifierMessage,

			flatRateTierLabel,
			isFlatRateTrip,
			flatRateDriverPay,
			flatRateSupportPay,
			flatRateMemberCharge,

			longDropoffDriverPay,
			longPickupDriverPay,
			estimatedDriverTimePay,
			estimatedDriverMileagePay,
			estimatedSupportTimePay,

			markup,
		},
	};
}

export function convertPickupDropoffTimezones(trip) {
	const {
		isScheduled,
		scheduledPickupAt,
		tripPickupTimezone,
		tripDropoffTimezone,

		estimatedPickupAt: pickup,
		estimatedDropoffAt: dropoff,
	} = trip || {};

	// Handle timezone conversion into a time that we can display locally
	// const localTimezone = 'America/Chicago';
	const localTimezone = getTimezone();
	let convertedPickupTime = isScheduled ? scheduledPickupAt : pickup;
	let pickupTimezoneDisplay = '';
	if (
		convertedPickupTime &&
		tripPickupTimezone &&
		tripPickupTimezone !== localTimezone
	) {
		convertedPickupTime = convertUTCfromTimezoneEnteredToTimezoneIntended({
			timezoneIntended: localTimezone,
			timezoneEntered: tripPickupTimezone,
			utcDateTimeEntered: convertedPickupTime,
		});

		pickupTimezoneDisplay = (
			ianaToFriendlyNames[tripPickupTimezone] || {
				shortName: tripPickupTimezone,
			}
		).shortName;

		// console.log(`convertPickupDropoffTimezones: [pickup]`, {
		// 	isScheduled,
		// 	scheduledPickupAt,
		// 	pickupTimezoneDisplay,
		// 	localTimezone,
		// 	tripPickupTimezone,
		// 	convertedPickupTime,
		// });
	}

	let convertedDropoffTime = dropoff;
	let dropoffTimezoneDisplay = '';
	if (dropoff && tripDropoffTimezone && tripDropoffTimezone !== localTimezone) {
		convertedDropoffTime = convertUTCfromTimezoneEnteredToTimezoneIntended({
			timezoneIntended: localTimezone,
			timezoneEntered: tripDropoffTimezone,
			utcDateTimeEntered: dropoff,
		});

		dropoffTimezoneDisplay = (
			ianaToFriendlyNames[tripDropoffTimezone] || {
				shortName: tripDropoffTimezone,
			}
		).shortName;
	}

	const scheduledPickup =
		convertedPickupTime &&
		formatTimestamp(convertedPickupTime || new Date(), {
			shortFormatNames: true,
			returnObject: true,
		});

	return {
		scheduledPickup,
		convertedPickupTime,
		convertedDropoffTime,
		pickupTimezoneDisplay,
		dropoffTimezoneDisplay,
	};
}
