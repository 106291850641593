// //////////////////////////////////////////////////////////////////////
// This file is generated in package.json under scripts/prebuild:config
// To change values here, don't edit this file. Edit /app.config.js
// and rerun 'npm run prebuild:config'
// //////////////////////////////////////////////////////////////////////

// Export config for the above env
const AppConfig = {
	    nlpWorkerUrl: 'http://nlp-worker:8097',
    nlpWorkerApiKey: 'sk_prod_8806cdecd8c35477dc13578b34ca9640945ec5b4',
    qdrantApiUrl: 'http://qdrant:6333',
    dbFlags: {
	"allowDisablePrimaryKey": true
},
    cypressTxIdent: 'Cypress_A509',
    repoUrl: 'github.com/josiahbryan/rubber',
    redisProdPublicUrl: 'rediss://default:d7al17k0u9eiy36m@chatbetter-redis-do-user-131382-0.b.db.ondigitalocean.com:25061',
    valetUrgentNotificationPhoneConfig: {
	"phoneNum": "+15128903157",
	"phoneProvider": "twilio"
},
    anthropic: {
	"apiKey": "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"
},
    clickhouse: {
	"user": "default",
	"password": "MQsjzSnAJkxg",
	"host": "gd3qd41c6b.us-west-2.aws.clickhouse.cloud",
	"nativeCommand": "\n\t\t\t\t\tclickhouse-client --host gd3qd41c6b.us-west-2.aws.clickhouse.cloud --secure --port 9440 --password MQsjzSnAJkxg\n\t\t\t\t",
	"curlCommand": "\n\t\t\t\t\tcurl --user 'default:MQsjzSnAJkxg' --data-binary 'SELECT 1' https://gd3qd41c6b.us-west-2.aws.clickhouse.cloud:8443\n\t\t\t\t"
},
    picovoice: {
	"accessKey": "LEe6vYn0rKQMDmv5LLEeqC4MyRSMCf8uQPlSmTTc4mlq7dnuyQQiZg=="
},
    chatGpt: {
	"secretKey": "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",
	"orgId": "org-Xfb4XYo3swf6TgWYzFvPPpx3",
	"heliconeKey": "sk-kkxbrkq-uqgugny-rkkytly-wwoifiy"
},
    assemblyAi: {
	"apiKey": "ca39dffa4fa04dfc8613519a96378109"
},
    langChainEnv: {
	"OPENAI_API_KEY": "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",
	"SERPAPI_API_KEY": "4757141105f14b7234c223d9f7f16e60d5783007a768ef99cb02ac81683754aa",
	"HUGGINGFACEHUB_API_KEY": "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",
	"PINECONE_API_KEY": "668bd0b9-2b35-47f9-b121-bd45cc229e65",
	"PINECONE_ENVIRONMENT": "asia-southeast1-gcp",
	"PINECONE_INDEX": "vaya-dev",
	"ANTHROPIC_API_KEY": "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"
},
    telnyx: {
	"externalPin": "7795",
	"apiKey": "KEY018B246AF96671B56E36460039055347_4sPoCJqVEN7pGUb8WbJUnN",
	"campaignId": "4b30018b-97fa-b7f5-c731-527924ee0167",
	"defaultNumbers": {
		"dev": "+12543232906",
		"staging": "+12543232906",
		"prod": "+12816995834"
	},
	"messaging": {
		"profiles": {
			"dev": "40018738-8be0-4ec6-afe7-6fea08ce38d0",
			"staging": "40018738-8be0-4ec6-afe7-6fea08ce38d0",
			"prod": "4001877c-c3b5-421c-9a5d-c222eecbee81"
		}
	},
	"voice": {
		"apps": {
			"dev": "2156683112916976847",
			"staging": "2156683112916976847",
			"prod": "2156683439560983824"
		},
		"outboundProfiles": {
			"default": "2132130541409928333"
		}
	},
	"billingGroups": {
		"driving": "0571f9c5-ac9e-48d9-98ae-929ba45aa0c2",
		"valet": "01201065-721c-4028-8a6b-efef8b9cff0b",
		"dev": "1b135449-1dc9-4aa1-be59-d4a9dd837195"
	}
},
    plateRecognizer: {
	"apiEndpoint": "https://api.platerecognizer.com/v1/plate-reader/",
	"authToken": "eadc454476270fd3e5ea2f42d7d1116a04bf01ff"
},
    mailchimp: {
	"apiKey": "087745fa45dea673a7a49c437adeec50-us20",
	"server": "us20",
	"vayaListId": "2c21b27d4b"
},
    planetScale: {
	"organization": "vaya",
	"mainBranch": "main",
	"primaryDatabase": "rubber",
	"serviceToken": {
		"id": "qpjt132y8zmh",
		"token": "pscale_tkn__Ore6W-E3VuzC0HY692uc9YJXVH6jGUyRjMWYk22Np0"
	}
},
    iceServers: [
	{
		"urls": "stun:stun.l.google.com:19302"
	},
	{
		"urls": "stun:turn.vegasfire.josiahbryan.com:3478"
	},
	{
		"url": "turn:turn.vegasfire.josiahbryan.com",
		"username": "vegasfire",
		"credential": "af52890992352818493be47021c44325649761de9e9a1a2a82569dceaf2899e"
	}
],
    fbAppId: '1437687873686616',
    fbBizLoginConfigId: '1189003865100280',
    fbAppSecret: 'a88f3cb36ce6cef0dedc3798455b614b',
    fbApiVersion: 'v18.0',
    adminSmsPhoneNum: '+17652150511',
    dataRoot: '/opt/rubber/backend/data/',
    externalServiceOverrides: {},
    lprWorkerUrl: 'http://localhost:8080/v1/plate-reader/',
    lprWorkerStreamUrl: 'http://localhost:8081',
    coinbase: {
	"apiKey": "ieOUkq6VL0uQdgV1",
	"apiSecret": "PpsmaWWACFdOmyMNHpfOz4afcMWylU5r",
	"apiVersion": "2022-02-12"
},
    salesforce: {
	"precina": {
		"orgId": "00DHp000003UrBeMAK"
	}
},
    gcal: {
	"oauth": {
		"redirectPath": "/webhook/gcal/oauth/callback",
		"clientId": "747446149493-9v5kvvb34e2ml3ki2l0jf5eocpn3cvt4.apps.googleusercontent.com",
		"clientSecret": "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"
	}
},
    cloudFlare: {
	"workersAccountId": "73f546e836913e988f79ca05abf60fd7",
	"workersSubdomain": "vaya.workers.dev",
	"datadogLoggerApiKey": "8ab2a3246a3932a86a74b472469a1136",
	"datadogLoggerEndpoint": "https://http-intake.logs.datadoghq.com/api/v2/logs",
	"inferenceApiKey": "qlXNLkgRl3Cz1oyP8iaqZatTaintkbdG_QA5M117",
	"inferenceApiRoot": "https://api.cloudflare.com/client/v4/accounts/73f546e836913e988f79ca05abf60fd7/"
},
    gpsWorker: {
	"deviceType": "cf-reducer-store",
	"reducerIngestPath": "/api/v1/reducer-store/deltas",
	"backendBroadcastDelay": 5000,
	"socketBroadcastDelay": 1000
},
    valetTicketListWorker: {
	"deviceType": "cf-valet-ticket-list-worker"
},
    appEventQueue: {
	"eventQueueName": "AppEventQueue"
},
    calendly: {},
    slack: {
	"notifyChatsNlvpUrl": "https://hooks.slack.com/services/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",
	"notifyTripsUrl": "https://hooks.slack.com/services/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",
	"notifyChatsUrl": "https://hooks.slack.com/services/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",
	"notifyOperationsUrl": "https://hooks.slack.com/services/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",
	"notifyCicdUrl": "https://hooks.slack.com/services/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",
	"alertDevopsUrl": "https://hooks.slack.com/services/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",
	"conciergeChannelUrl": "https://hooks.slack.com/services/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"
},
    monday: {
	"apiRoot": "https://api.monday.com/v2"
},
    datadogApiKey: '33f9f89b2e4dae517a5a8d995c575814',
    datadogAppKey: '36d7c3989629d95c59403793b176acb0ae55be7c',
    quickbooks: {
	"webhookVerifyToken": "78a97f7f-ead9-42be-8a09-a9c95468a408",
	"clientId": "AB3yUibhNhNQE0El81VxoGggWgV7CCF1oTASwvW3Zb5R4UMXi4",
	"clientSecret": "Py4kp0xy6YkqoSeZJiNinxGI5lVQ7jjtUg4DTvLK",
	"baseUrl": "https://quickbooks.api.intuit.com",
	"qbCompanyId": "9130351792460996"
},
    plaid: {
	"clientId": "628cf068dd829800191904c1",
	"secret": "170860c234067bb489a930c109b910"
},
    aeroApi: {
	"apiKey": "50d1059dee3050526e6360c5ef9ce63236fbddec",
	"apiRoot": "http://flightxml.flightaware.com/json/FlightXML2/",
	"username": "JosiahAtVaya"
},
    zapHooks: {
	"helloSignRequest": "https://hooks.zapier.com/hooks/catch/11098326/bhlpnuz/"
},
    coinbaseCommerce: {
	"apiKey": "26b77f8b-a01e-4380-a90f-947af3130e6c",
	"webhookSharedSecret": "a7db75b5-4a3b-4f9b-a725-f510c26b4d2f"
},
    evox: {
	"apiKey": "JUhkynZ4sGbhQtsm8NXh2NhhMdVnbt8E",
	"apiRoot": "https://api.evoximages.com/api/v1",
	"colorListUrl": "https://dbhdyzvm8lm25.cloudfront.net/_api_colors/ail_colors.csv",
	"vifListUrl": "https://d2ivfcfbdvj3sm.cloudfront.net/53dc7cae44ead0b6a03fa7e155f22d31b85a/_api_viflist/viflist.csv"
},
    doctl: {
	"token": "ba3740b8c5ad3ff8f73fe6b80522427b10775a892e855edb150a70eb7208de14"
},
    redis: {
	"url": "rediss://default:d7al17k0u9eiy36m@private-chatbetter-redis-do-user-131382-0.b.db.ondigitalocean.com:25061"
},
    doSpaces: {
	"accessKeyId": "3GNVVPVE6SV32WJE3T3W",
	"secretAccessKey": "BYhJVbyGxWfZcCNDHGYmbM9y0fklEBGynbCekaFoKoI",
	"endpoint": "sfo3.digitaloceanspaces.com",
	"urlBase": "https://chatbetter.sfo3.cdn.digitaloceanspaces.com",
	"spaceName": "chatbetter"
},
    gigwage: {
	"apiRoot": "https://app.gigwage.com",
	"apiVersion": "/api/v1",
	"apiKey": "f5ff983f85470e3aaf826cdec371a6bd",
	"apiSecret": "6d8702464a254006fd0a56598d62081792f78904189a04106ea7f4adf2861f1ea1484227f3b81e768349482ec8350baf0d99c312746bc655fc144148489029da",
	"username": "josiahbryan@gmail.com"
},
    adminStripeOverride: {
	"accountId": "acct_1E4HsYGHWajtT41L",
	"platformOverrideTransferAmount": 0.85
},
    stripe: {
	"secretKey": "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",
	"webhookSecret": "whsec_C7xU2SOpnzNgUS7CnhkhYo4jIZHLxCVU",
	"oauthClientId": "ca_Kob5BgEDrK0tWzcLc931E7oESBYxmFEk",
	"connectAppName": "Vaya",
	"connect": {
		"pershing": {
			"accountId": "acct_1JW34iKJ8wMu6m4a",
			"vayaAccountId": "c446d31e-69d0-11ec-b444-52caa6bd2514",
			"secretKey": "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",
			"publicKey": "pk_live_51JUg94IvXzDnL3SWmgW7EXLJHagteGQ7oyHojxppshI1zgQI1aTslEfKaIRoYFesv0djdEJ3tFEse2oMtZocLoTj00KemtKOvK",
			"webhookSecret": "whsec_U3eQEB1Iquv0MavFaqplGP3K0OcKDAk9"
		}
	}
},
    // Shared with PublicAppConfig, below
    ...({
	"fbAppId": "1437687873686616",
	"fbBizLoginConfigId": "1189003865100280",
	"fbApiVersion": "v18.0",
	"languages": {
		"en": {
			"nativeName": "English",
			"longCode": "en-US",
			"simpleCode": "en"
		},
		"es": {
			"nativeName": "Spanish",
			"longCode": "es-419",
			"simpleCode": "es"
		},
		"zh": {
			"nativeName": "Chinese",
			"longCode": "zh",
			"simpleCode": "zh"
		},
		"am": {
			"nativeName": "Amharic",
			"longCode": "am",
			"simpleCode": "am"
		}
	},
	"vehicleTiers": {
		"sedan": {
			"title": "Luxury Sedan",
			"exampleText": "Mercedes-Benz E-Class, BMW 5 Series, Cadillac XTS or similar",
			"tierIndex": 1,
			"maxPassenger": 3
		},
		"suv": {
			"title": "Luxury SUV",
			"exampleText": "Mercedes-Benz V-Class, Chevrolet Suburban, Cadillac Escalade, Toyota Alphard or similar",
			"tierIndex": 2,
			"maxPassenger": 5
		},
		"limo": {
			"title": "Limo",
			"tierIndex": 3,
			"exampleText": "Cadillac Escalade ESV Stretch Limo or similar",
			"maxPassenger": 14
		},
		"exotic": {
			"title": "Exotic",
			"description": "Ferrari F8, Lamborghini Huracán, McLaren 720S, or similar",
			"tierIndex": 4,
			"maxPassenger": 2
		}
	},
	"newYorkCompliance": {
		"tlcLicensedForHireVehicleBases": "\n\t\t\t\t\t\tB03338 A & T NYC LIMO LLC\n\t\t\t\t\t\tB03340 CEO CHAUFFEURED SERVICES CORP\n\t\t\t\t\t\tB03321 CRESCENT LUXURY CLS, INC.\n\t\t\t\t\t\tB02253 CRESTWOOD CAR & LIMO SVC,LTD\n\t\t\t\t\t\tB02424 FRIENDLY RIDE INC\n\t\t\t\t\t\tB03197 LIMO TRIBE INC\n\t\t\t\t\t\tB03320 LIMOLINK INCORPORATED\n\t\t\t\t\t\tB02655 LONG ISLAND CITY CAR AND LIMO INC.\n\t\t\t\t\t\tB02949 MEEM SERVICES INC\n\t\t\t\t\t\tB02298 NYG LIMOUSINE LLC\n\t\t\t\t\t\tB02727 OSCAR LIMOUSINE INC\n\t\t\t\t\t\tB02027 PARIS LIMOUSINE SERVICE, CORP\n\t\t\t\t\t\tB02611 SS LIMOUSINE SERVICE INC\n\t\t\t\t\t\tB03334 WTM 365 INC.\n\t\t\t\t\t\tB03317 V & C, INC\n\t\t\t\t\t"
	},
	"tcpPort": 8095,
	"apiHost": "api.vayadriving.com",
	"gpsWorkerPublic": {
		"devWorkerRoot": "https://vaya-dev-gps.ngrok.io",
		"prodWorkerRoot": "https://gps-worker.vaya.workers.dev"
	},
	"valetTicketListWorkerPublic": {
		"devWorkerRoot": "https://vaya-dev-valet-ticket-list.ngrok.io",
		"prodWorkerRoot": "https://valet-ticket-list.vaya.workers.dev",
		"defaultEnabled": {
			"dev": false,
			"prod": false
		}
	},
	"valetTicketEditWorkerPublic": {
		"devWorkerRoot": "https://vaya-dev-valet-ticket-edits.ngrok.io",
		"prodWorkerRoot": "https://valet-ticket-edits.vaya.workers.dev"
	},
	"envRootUrls": {
		"dev": {
			"frontend": "http://localhost:3000",
			"api": "localhost:8095",
			"partners": "http://localhost:3001",
			"marketing": "http://localhost:8000"
		},
		"staging": {
			"frontend": "https://staging.vayadriving.com",
			"api": "api-staging.vayadriving.com",
			"partners": "https://partner.vayadriving.com",
			"marketing": "http://localhost:8000"
		},
		"prod": {
			"frontend": "https://app.vayadriving.com",
			"api": "api.vayadriving.com",
			"partners": "https://partner.vayadriving.com",
			"marketing": "https://vayadriving.com"
		}
	},
	"gigWageClientRoot": "https://gigwage.com",
	"gaId": "UA-243284-47",
	"datadogClientToken": "pub26d2712cf99dbf5c547a771b587ac435",
	"datadogCFWorkersClientToken": "pub616351f86683bfbde777edf2081a0b00",
	"stripePubKey": "pk_live_51JW34iKJ8wMu6m4anGsfU7QZMvhIoAZSA2NnOjwNsGxZGOMIPGzMJn0toX0ECSYcSrIWhBmFzEr8HPgSG3SGwM0F00Ith8JJfx",
	"sentryDsn": "https://b44afb4cb55443ae89d6c520c2c921eb@o1050529.ingest.sentry.io/6031794",
	"googleMapsApiKey": "AIzaSyD3bPj5A2KwUi5_yw9CuFBvZcIhQi8wXbs",
	"geolocationDbKey": "fb363670-e22a-11eb-a464-59f706281067",
	"frontendRoot": "https://app.vayadriving.com",
	"frontendV2Root": "https://admin.vayadriving.com",
	"frontendTunnelRoot": "https://vaya-dev-client.ngrok.io",
	"frontendValetApp": "https://app.vayavalet.io",
	"partnerRoot": "https://partner.vayadriving.com",
	"partnerTunnelRoot": "https://vaya-dev-partners.ngrok.io",
	"marketingRoot": "https://vayadriving.com",
	"marketingTunnelRoot": "https://vaya-dev-marketing.ngrok.io",
	"betaAppProdRoot": "https://admin.vayadriving.com",
	"tipAppRoot": "https://tip.vayavalet.io",
	"tipAppDevTunnelRoot": "https://vaya-dev-tip.ngrok.io",
	"apiVayaValetIo": "api.vayavalet.io",
	"backendTunnelHost": "vaya-dev-api.ngrok.io",
	"pinExpiration": 3600000,
	"defaultStripeProductId": "prod_KkRtdu8whs2YeG",
	"stripePackages": {
		"prod_KkRxJlhMOgMnv1": {
			"name": "Introductory Membership",
			"priceId": "price_1K4x3oKJ8wMu6m4aZV67tPbP",
			"price": 0,
			"hours": 0,
			"markup": 1.33,
			"customDescription": "Experience all the benefits of a private luxury driver in our fleet of luxury sedans, suburbans, and limos with no monthly subscription for 60 days",
			"referralCodeId": "Tier-Intro"
		},
		"prod_Lg4V8UlnGi0gZK": {
			"name": "Basic Package",
			"priceId": "price_1KyiM6KJ8wMu6m4aUqV52dJf",
			"price": 19,
			"hours": 0.25,
			"markup": 1.25,
			"customDescription": "Get a complimentary ride each month and select dedicated private drivers your own dedicated concierge staff",
			"referralCodeId": "Tier-Basic"
		},
		"prod_Lg4XIpclXrFV9x": {
			"name": "Advanced Package",
			"priceId": "price_1KyiOYKJ8wMu6m4aNpkhFVtX",
			"price": 99,
			"hours": 1,
			"markup": 1.2,
			"customDescription": "Priority access to exclusive luxury transportation and lifestyle Concierge services with personal vehicle driving and access to limousine fleets",
			"referralCodeId": "Tier-Elite"
		},
		"prod_KkRtdu8whs2YeG": {
			"name": "Introductory Membership",
			"priceId": "price_1K6kyoKJ8wMu6m4aguRcbQHF",
			"price": 0,
			"hours": 0,
			"markup": 1.33,
			"customDescription": "Experience all the benefits of a private luxury driver in our fleet of luxury sedans, suburbans, and limos with no monthly subscription for 60 days",
			"referralCodeId": "Tier-Intro"
		},
		"prod_Lg6LhWkkPf6QLo": {
			"name": "Basic Package",
			"priceId": "price_1Kyk95KJ8wMu6m4a7Z8wdf88",
			"price": 19,
			"hours": 0.25,
			"markup": 1.25,
			"customDescription": "Get a complimentary ride each month and select dedicated private drivers your own dedicated concierge staff",
			"referralCodeId": "Tier-Basic"
		},
		"prod_Lg6L8KneyZHmpr": {
			"name": "Advanced Package",
			"priceId": "price_1Kyk9DKJ8wMu6m4aS59g4iWy",
			"price": 99,
			"hours": 1,
			"markup": 1.2,
			"customDescription": "Priority access to exclusive luxury transportation and lifestyle Concierge services with personal vehicle driving and access to limousine fleets",
			"referralCodeId": "Tier-Elite"
		}
	},
	"phoneNumbers": {
		"main": "+15127227795",
		"notifications": "+15127227796",
		"driverMember": "+15127227797",
		"otp": "+17372379502"
	},
	"firebase": {
		"apiKey": "AIzaSyCXqd6yRHsk8QjoUv0rsCuuRPKp3tpgvtY",
		"authDomain": "vaya-driving-app.firebaseapp.com",
		"projectId": "vaya-driving-app",
		"storageBucket": "vaya-driving-app.appspot.com",
		"messagingSenderId": "747446149493",
		"appId": "1:747446149493:web:ad86b9458929bdbf97333c",
		"measurementId": "G-5S2VE51YW9"
	},
	"firebaseVapidMessagingKey": "BIi6SEv4QrUl35sfg1PZe0TuFEgsD5RTjR-mMvN9PRO7G3UT3BDvZ5NuIpAGq5jYsnCh7uwETngvOV68cKGaHXo",
	"intercomAppId": "sxietg3v",
	"driverMaxPickupWaitMinutes": 60,
	"serviceAreas": [
		{
			"name": "Austin, TX",
			"timezone": "America/Chicago",
			"location": {
				"lat": 30.268064208179236,
				"lng": -97.74281181323757,
				"radius": 48280,
				"dispatchRadiusMeters": 48280.3
			},
			"eventPricingRules": [
				{
					"id": "late-night",
					"name": "Late Night",
					"description": "Late night trips on high-demand days",
					"specialPricingModifier": 2.5,
					"packageModifiers": {
						"silver": 1.6,
						"gold": 1.4,
						"platinum": 1.3,
						"diamond": 1.2
					},
					"times": [
						{
							"dayOfWeek": -1,
							"start": "19:45:00",
							"hours": 5
						},
						{
							"dayOfWeek": 5,
							"start": "20:00:00",
							"hours": 7
						},
						{
							"dayOfWeek": 6,
							"start": "20:00:00",
							"hours": 7
						},
						{
							"dayOfWeek": 0,
							"start": "20:00:00",
							"hours": 7
						}
					]
				}
			],
			"flatRateGeoRules": [
				{
					"id": "ride-25-downtown",
					"name": "$25 Rides",
					"description": "$25-One-Way Rides anywhere within the downtown radius",
					"flatRateCost": 20,
					"flatRatePrice": 25,
					"geofences": [
						{
							"lat": 30.263382454053133,
							"lng": -97.74454989455865,
							"meters": 2125
						},
						{
							"lat": 30.26326313645714,
							"lng": -97.72417986827942,
							"meters": 1820
						},
						{
							"lat": 30.268864673875296,
							"lng": -97.7243221368536,
							"meters": 1820
						}
					]
				}
			]
		}
	],
	"appStoreLinks": {
		"android": "https://play.google.com/store/apps/details?id=com.vayadriving",
		"ios": "https://apps.apple.com/us/app/vaya-concierge-driving/id1589326725"
	},
	"posthogCloudApiKey": "phc_7SelwAJSXVDNNSFp4kYlbiEe4bhkNL29PIicLTaurBj"
}),
    internalApiHost: 'localhost:8095',
    iosClientId: 'com.vayadriving',
    fcmServerKey: 'AAAArgdDIXU:APA91bF0xjJ28PqteOkFe3o5k-bpynVlopQ9CKeTfzQtrDK1_7WDmPqLwgTzpiANMabDty-2d-uc5df56Pz6XcmP0dQfsCOoeLS8_1eu9qhI8L38OiTAtSf7iHhCBqIEnXAY3p-WhWtJ',
    firebaseKey: 'resources/sakeys/rubber-ade5e-firebase-adminsdk-xiv20-0cdba74d94.json',
    firebaseWorkspaceId: 'rubber-ade5e',
    firebaseStorageUrl: 'gs://rubber-ade5e.appspot.com',
    mailgunKey: 'f36234a95326e67886b6ae4b594131fa-8ed21946-4d68c678',
    mailgunDomain: 'mail.vayadriving.com',
    twilioAccountSid: 'ACae6c5c8d900d648bba0115cc4003cd4e',
    twilioAuthToken: '7d5ee09a146b858791e74836e27b2d66',
    twilioPhoneNum: '+17372379444',
    twilioSync: {
	"serviceId": "IS16f5e6d84c4846baf8f1df71a24ee692",
	"apiKey": "SKdcc06bec1aa5a8ba45c7e64956dbe439",
	"apiSecret": "16iSkdx6TDJzBYQ80TeLzZ7jeWdW448X"
},
    twilioMessagingServiceId: 'MG2479e7ab35717c599297b246c2d7e737',
    twilioVoiceUrl: '/webhooks/twilio/voice/inbound',
    jsonPrettyPrint: 4,
    jwtSignKey: 'eimeilahquah6raigooNgaichahgh0queishoh2eiphaeneebu9ahzu9AhyeiNaefeu3TielaiLa6cahm7befiko7IheiXiv0Aethichaekai2taeb4ohnab9Ohkoid5',
    logRoot: '/opt/rubber/backend/data/logs/',
    wwwRoot: '/opt/rubber/backend/data/www/',
    dockerRoot: '/app',
    nonDockerRoot: '/opt/rubber/backend',

	
	version: '2.3.862-09e7f365-prod',

	gitVersionLong: '09e7f365f4a5c301f5f72cb926f23c8cc67e6e86',
	gitVersionShort: '09e7f365',

	buildEnv: 'prod',

	buildTime: '2024-02-11T21:03:06.760Z',

	buildArch: 'armv7l',

	buildPlatform: 'linux',

	// Generated as an MD5 of package-lock.json so we can tell if hotfix will work or not
	depHash: '622648ad5f6bdc6f5cedf7f419a648e1',

	// Set in Jenkins via the BUILD_NUMBER env var
	buildNumber: 'undefined',

}

// Setup the env to match what we built with
if (globalThis.process !== undefined) {
	const { langChainEnv } = AppConfig;
	Object.assign(process.env, {
		...langChainEnv,
		NODE_ENV: 'production',
		RUBBER_BUILD_ENV: 'prod',
	});
}
	
export default AppConfig;
