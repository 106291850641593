/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { utcToZonedTime, getTimezoneOffset } from 'date-fns-tz';
import { add as dateAdd, sub as dateSub } from 'date-fns';
import { ianaToFriendlyNames } from './ianaAbbvr';
import localLogger from './IsomorphicLogger';

/**
 * Utility for use when entering scheduled pickup times.
 * Converts a UTC time from the timezone entered to the intended time
 * @param {string} props.timezoneEntered IANA Timezone the user entered the time in
 * @param {string} props.timezoneIntended IANA Timezone derived from the trip's pickup lat/lng
 * @param {string} props.utcDateTimeEntered UTC Time entered by the user for the trip pickup
 * @returns {Date} New UTC date representing the entered time in the pickup timezone
 */
export const convertUTCfromTimezoneEnteredToTimezoneIntended = ({
	timezoneEntered,
	timezoneIntended,
	utcDateTimeEntered,
	debugOutput = false,
}) => {
	if (!utcDateTimeEntered) {
		return utcDateTimeEntered;
	}

	if (
		utcDateTimeEntered instanceof Date &&
		Number.isNaN(utcDateTimeEntered.getTime())
	) {
		// localLogger.warn(
		// 	`convertUTCfromTimezoneEnteredToTimezoneIntended (${debugOutput}) received invalid Date object for 'utcDateTimeEntered' prop, cannot convert`,
		// 	{ utcDateTimeEntered },
		// );
		return utcDateTimeEntered;
	}

	if (debugOutput) {
		localLogger.warn(
			`convertUTCfromTimezoneEnteredToTimezoneIntended debug input (${debugOutput}):`,
			{
				timezoneEntered,
				timezoneIntended,
				utcDateTimeEntered,
				typeOfUtc: typeof utcDateTimeEntered,
			},
		);
	}

	// Parse date for passing to Date.UTC()
	let parts;
	try {
		parts = (
			utcDateTimeEntered instanceof Date
				? utcDateTimeEntered.toISOString()
				: `${utcDateTimeEntered}`
		)
			.split(/[-\sT:.]/)
			.map(parseFloat);
		parts[1]--; // months are zero-indexed
	} catch (ex) {
		localLogger.warn(
			`convertUTCfromTimezoneEnteredToTimezoneIntended: Exception when trying to process input date '${utcDateTimeEntered}' (${debugOutput}):`,
			ex,
			{ timezoneEntered, timezoneIntended, utcDateTimeEntered, debugOutput },
		);

		return utcDateTimeEntered;
	}

	// Ensure date is recognized by Date() as UTC
	const originalTimeAsUTCDate = new Date(Date.UTC(...parts));

	if (debugOutput) {
		localLogger.log(
			`convertUTCfromTimezoneEnteredToTimezoneIntended: parts debug (${debugOutput}):`,
			{
				utcDateTimeEntered,
				parts,
				originalTimeAsUTCDate,
			},
		);
	}

	// Shortcut the logic if there's nothing to do
	if (timezoneEntered === timezoneIntended) {
		return originalTimeAsUTCDate;
	}

	//
	//
	// We have three things:
	// -  A UTC time that matches the time the user entered in their local timezone
	// - The local timezone the user entered the UTC time in
	// - The "other" timezone where the trip will actually take place in
	//
	// Goal: SHIFT the UTC time forward/back by the DIFFERENCE
	// between the entered timezone and the other timezone.
	//
	// This will cause the UTC time to match the time it SHOULD BE for the
	// trip location, instead of the timezone in which the user entered the trip.
	//
	//

	// To effect the shift, first we need to know how far off UTC (in seconds) the
	// user was when they entered the time
	const sourceOffset = Math.round(
		getTimezoneOffset(timezoneEntered, new Date(utcDateTimeEntered)) / 1000,
	);

	// ..and we also need to know how far off of UTC the Pickup timezone is
	const targetOffset = Math.round(
		getTimezoneOffset(timezoneIntended, new Date(utcDateTimeEntered)) / 1000,
	);

	// The amount to shift the UTC time we have is simply the difference between
	// the two UTC offsets. So for a time entered in Chicago but pickup
	// is in New York, the offset here would be 3600 seconds, or 1 hour
	const offset = targetOffset - sourceOffset;

	// Decide if we need to add/subtract time
	const ahead = offset < 0;
	const transformer = ahead ? dateAdd : dateSub;

	// Calculate the amount of time (hours and minutes) to add/subtract from the 'offset' seconds
	const hours = Math.abs(Math.floor(offset / 60 / 60));
	const minutes = (Math.abs(offset) - hours * 60 * 60) / 60;

	// Here's the actual math - use the add/sub function from date-fns
	// to shift the UTC time forward/back by the requisite hours/minutes
	const newTime = transformer(originalTimeAsUTCDate, {
		hours,
		minutes,
	});

	if (debugOutput) {
		// Now, just for display back to the user, put the time into the DESTINATION
		// time zone again
		const timeAsEntered = utcToZonedTime(newTime, timezoneIntended);

		// ... and lookup the short name (e.g. EST / CST / etc) for the pickup timezone
		const timezoneShortName = (
			ianaToFriendlyNames[timezoneIntended] || { shortName: timezoneIntended }
		).shortName;

		// Just for debugging
		localLogger.debug(
			`convertUTCfromTimezoneEnteredToTimezoneIntended final output debug (${debugOutput}): `,
			{
				targetOffset,
				sourceOffset,
				ahead,
				hours,
				minutes,
				offset,
				utcDateTimeEntered,
				timezoneIntended,
				originalTimeAsUTCDate,
				originalTimeAsUTCDateISO: originalTimeAsUTCDate.toISOString(),
				newTimeLocale: newTime.toLocaleTimeString(),
				newTimeUTC: newTime.toUTCString(),
				newTimeISO: newTime.toISOString(),
				newTime,
				timeAsEntered,
				timeAsEnteredLocale: timeAsEntered.toLocaleTimeString(),
				timezoneShortName,
			},
		);
	}

	return newTime;
};

async function main() {
	const utcTime = '2021-11-24 20:46:04';

	const fromZone = 'Etc/GMT+0';
	const toZone = 'America/Chicago';
	const newDate = convertUTCfromTimezoneEnteredToTimezoneIntended({
		utcDateTimeEntered: utcTime,
		timezoneEntered: toZone,
		timezoneIntended: fromZone,
		// timezoneEntered: fromZone,
		// timezoneIntended: toZone,
		debugOutput: true,
	});

	localLogger.debug(`debug from monday:`, {
		utcDateTimeEntered: utcTime,
		// timezone,
		result: newDate,
	});

	localLogger.log(`UTC debug:`, {
		hour: newDate.getUTCHours(),
		minute: newDate.getUTCMinutes(),
		string: newDate.toISOString(),
	});
}

if (global.process && global.process.argv[1] === __filename) {
	main()
		.then(() => global.process.exit())
		.catch((E) => localLogger.error(E));
}
