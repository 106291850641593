/* eslint-disable no-unused-vars */
/* eslint-disable no-console, camelcase, no-return-assign */
// import firebase from 'firebase/app';
// Import the functions you need from the SDKs you need
import firebase, { initializeApp } from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import { getAuth, signInWithCustomToken } from 'firebase/compat/auth';
// import { getFirestore } from 'firebase/compat/firestore';

import {
	getMessaging,
	getToken,
	isSupported,
	onMessage,
} from 'firebase/messaging';

import PublicAppConfig from '../../config-public';
import DeviceInfo from '../../utils/DeviceInfo';
import { iOS } from '../../utils/iOS';
import { ServerStore } from '../ServerStore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
// initializeApp(PublicAppConfig.firebase);
const firebaseApp = firebase.initializeApp(PublicAppConfig.firebase);
// console.log(`Loaded firebase app:`, app);

// const firebaseDb = firebase.firestore();
const firebaseAuth = firebase.auth();

export {
	firebase,
	// firebaseDb,
	firebaseAuth,
	firebaseApp,
};

// export { getAuth, signInWithCustomToken, firebaseDb };

class FirebaseManager {
	static handle = null;

	static async plugin(opts) {
		this.handle = new FirebaseManager(opts);
		this.handle.init();

		return this.handle;
	}

	// eslint-disable-next-line no-shadow
	constructor({
		onMessage: onMessageInput,
		onMessageClick,
		onPermissionNeeded,
	}) {
		this.onMessage = onMessageInput;
		this.onMessageClick = onMessageClick;
		this.onPermissionNeeded = onPermissionNeeded;

		console.log('Starting FirebasePushPlugin');

		// Check on boot to see if the service worker left a present for us in the URL...
		if (window.location.hash.match(/^#\/?alert:/)) {
			// Our service worker prefixes the data.click_action from alerts received
			// while our page is ***CLOSED***.
			// This means that the page was NOT active, NOT OPEN.
			// If our page WAS OPEN, we would have receive a message post (lower in this file) with a type == 'notificationclick'.
			// The ONLY time we will (should...) get here is if our service worker couldn't find an open page
			// so here we must extract the click action from the URL and then fire onMessageClick, indicating a background click.

			const click_action = window.location.hash.replace(/^#\/?alert:/, '');

			if (onMessageClick) {
				onMessageClick({
					// Note: this is the ONLY property of the alert we emulate here.
					// If we find we need more data from the alert (like the ID, etc)
					// we must modify our service worker to encode it in the URL accordingly and then we
					// must update this code above to extract it and put it here in this hash.
					click_action,

					// Used by PushNotifyService to handle properly
					clickedInBackground: true,

					// Add flag so our logging metrics know where this came from
					fromFirebaseServiceWorkerBackground: true,
				});
			}
		}
	}

	async init() {
		// Seeing errors in prod where Firebase's "isSupported" is not returning false
		// so we continue to get token, which throws errors - saying that firebase won't work in the browser.
		// This seems to happen on iOS Safari browsers - understandable, because we "know" as of right now,
		// iOS mobile Safari doesn't support push notifications. However, Firebase should know that, too.
		// Since the errors we see complain about missing "Notification" objects, just guard
		// that ourselves and shortcut the failure here so we don't throw uncaught exceptions.
		if (!window.Notification) {
			if (!iOS) {
				// Only log error if NOT on iOS because we "know" window.Notification doesn't exist on iOS,
				// we don't need to be reminded. We only want to hear errors if in an "unexpected" situation
				const device = await DeviceInfo.getDeviceInfo();
				const deviceJson = JSON.stringify(device);
				console.error(
					`Cannot start push notification service in browser - window.Notification does not exist (and not on iOS), running device info: ${deviceJson}`,
				);
			}
			return false;
		}

		const messaging = getMessaging();
		this.messaging = messaging;

		// if(this.onPermissionNeeded)
		// 	await this.onPermissionNeeded();

		if (!isSupported()) {
			console.warn(
				'[FirebaseManager] Firebase push messaging not supported on this browser',
			);
			return false;
		}

		const requestPermission = async () => {
			let error;
			await this.messaging.requestPermission().catch((e) => (error = e));
			if (error) {
				console.error(
					'[FirebasePushPlugin.requestPermission] error requesting permission:',
					error,
				);
				return false;
			}

			return true;
		};

		const getTokenInternal = async () => {
			// Get Instance ID token. Initially this makes a network call, once retrieved
			// subsequent calls to getToken will return from cache.
			let error;
			const token = await getToken(messaging, {
				vapidKey: PublicAppConfig.firebaseVapidMessagingKey,
			}).catch((e) => (error = e));
			if (error) {
				console.error(
					'[FirebasePushPlugin.getToken] error getting token:',
					error,
				);
				return false;
			}

			// if (!token) {
			// 	console.warn(
			// 		'[FirebasePushPlugin.getToken] no token available, requesting permission',
			// 	);

			// 	ServerStore.metric('app.firebase_push_plugin.need_token.defer');

			// 	// Allow our PushNotifyService to block permission request until it has informed the user
			// 	if (this.onPermissionNeeded) {
			// 		console.warn(
			// 			'[FirebasePushPlugin.getToken] Waiting onPermissionNeeded hook...',
			// 		);
			// 		await this.onPermissionNeeded();
			// 	}

			// 	ServerStore.metric('app.firebase_push_plugin.need_token.unlock');

			// 	console.warn(
			// 		'[FirebasePushPlugin.getToken] hooks cleared, prompting ....',
			// 	);

			// 	if (await requestPermission()) {
			// 		ServerStore.metric('app.firebase_push_plugin.need_token.accept');
			// 		return getToken();
			// 	}

			// 	ServerStore.metric('app.firebase_push_plugin.need_token.reject');

			// 	return false;
			// }

			ServerStore.metric('app.firebase_push_plugin.has_token');

			// // Now has to be done AFTER getting token
			// // See instructions at https://firebase.google.com/docs/cloud-messaging/js/client#configure_web_credentials_with_fcm
			// this.messaging.usePublicVapidKey(
			// 	PublicAppConfig.firebaseVapidMessagingKey,
			// );

			// console.warn("[FirebasePushPlugin.getToken] got token:", token);

			return token;
		};

		// // Callback fired if Instance ID token is updated.
		// this.messaging.onTokenRefresh(async () => {
		// 	let error;
		// 	const token = this.messaging.getToken().catch((e) => (error = e));
		// 	if (error) {
		// 		console.warn(
		// 			'[FirebasePushPlugin.onTokenRefresh] Unable to retrieve refreshed token ',
		// 			error,
		// 		);

		// 		ServerStore.metric(
		// 			'app.firebase_push_plugin.token_refresh.failure',
		// 			null,
		// 			{ error },
		// 		);
		// 		return;
		// 	}

		// 	ServerStore.metric('app.firebase_push_plugin.token_refresh.success');

		// 	console.log('[FirebasePushPlugin.onTokenRefresh] Token refreshed.');

		// 	// Send Instance ID token to app server.
		// 	ServerStore.StorePushToken(token);
		// });

		// Handle incoming messages. Called when:
		// - a message is received while the app has focus
		// - the user clicks on an app notification created by a service worker
		//   `messaging.setBackgroundMessageHandler` handler.
		onMessage(messaging, (payload) => {
			console.log(
				'[FirebasePushPlugin.onMessage] **TODO** Handle message received: ',
				payload,
			);

			if (this.onMessage) {
				this.onMessage(payload);
			}
		});

		// Handle messages clicked IN BACKGROUND - display of background messages is handled in public/firebase-messaging-sw.js
		if ('serviceWorker' in navigator) {
			// Handler for messages coming from the service worker
			navigator.serviceWorker.addEventListener('message', (event) => {
				// console.log("[FirebasePushPlugin] ServiceWorker Client Received Message: ", event.data);
				const msg = event.data;

				if (msg.type === 'notificationclick') {
					if (this.onMessageClick) {
						this.onMessageClick({
							...msg.payload,
							clickedInBackground: true,
						});
					}
				}
			});
		}

		const token = await getTokenInternal();
		if (token) {
			await ServerStore.metric('app.firebase_push_plugin.need_token.success');
			await ServerStore.StorePushToken(token);
			console.log(
				`[FirebasePushPlugin.initMessaging] received push token:`,
				token,
			);
			return true;
		}
		console.warn(
			"[FirebasePushPlugin.initMessaging] no token received, probably won't get push messages",
		);
		ServerStore.metric('app.firebase_push_plugin.null_token');
		return false;
	}
}

export function initFirebasePushPlugin(opts) {
	if (
		process.env.NODE_ENV === 'production' ||
		process.env.REACT_APP_STAGING === 'true' ||
		window.location.protocol === 'https:'
	) {
		FirebaseManager.plugin(opts);
	}
}
