/* eslint-disable no-console */
import { useJsApiLoader } from '@react-google-maps/api';

import PublicAppConfig from 'shared/config-public';
import { defer } from 'shared/utils/defer';
import { useState, useEffect, useRef, useCallback } from 'react';

const GOOGLE_MAPS_LIBRARIES = [
	'geometry',
	'drawing',
	'places',
	'directions',
	'visualization',
];

export const useGoogleJsApi = () => {
	const { googleMapsApiKey, googleMapId } = PublicAppConfig;

	const loadPromiseRef = useRef(defer());

	const { isLoaded, loadError } = useJsApiLoader({
		googleMapsApiKey,
		id: 'google-map-script',
		libraries: GOOGLE_MAPS_LIBRARIES,
		preventGoogleFontsLoading: true,
		mapIds: googleMapId ? [googleMapId] : undefined,
		version: googleMapId ? 'beta' : undefined,
	});

	const { window = {} } = global;

	const { google } = window;

	useEffect(() => {
		if (loadError) {
			console.error(`useGoogleJsApi: Google Maps load error:`, loadError);
		} else if (isLoaded) {
			const { current: promise } = loadPromiseRef;
			if (promise) {
				promise.resolve(window.google);
				loadPromiseRef.current = null;
			}
		}
	}, [isLoaded, loadError, window.google]);

	const assertLoaded = useCallback(async () => {
		if (!isLoaded) {
			if (loadPromiseRef.current) {
				await loadPromiseRef.current;
				return { google };
			}
		} else if (loadError) {
			console.error(
				`useGoogleJsApi.assertLoaded: Failed to load after waiting promise`,
				loadError,
			);
			return { error: loadError };
		}

		return { google };
	}, [isLoaded, loadError, google]);

	const toLatLngRef = useCallback(
		({ lat, lng }) => new google.maps.LatLng(lat, lng),
		[google],
	);

	return {
		google,
		isLoaded,
		loadError,
		loadPromise: loadPromiseRef.current,
		assertLoaded,
		toLatLngRef,
	};
};

export const useGoogleMaps = () => {
	const { google, isLoaded } = useGoogleJsApi();

	const [maps, setMaps] = useState(google && google.maps);

	useEffect(() => {
		if (!isLoaded || !maps) {
			setTimeout(() => setMaps(google && google.maps, false), 100);
		}
	}, [isLoaded, google, maps]);

	return maps;
};
