import OrderStatus from './OrderStatus';

const TripStatus = {
	// User has started a ride request but not finished filling in all details
	Draft: 'draft',
	// User has selected destination and quote has been provided to user
	Quoted: 'quoted',

	// New state introduced inorder to support Crypto payments.
	// Block dispatch by sitting in this state until crypto lands on the blockchain.
	// See coinbase-charge model and TripCryptoProcessor worker.
	// This state is auto-set by TripStateEngine when trying to transition into PendingDriverAcceptance
	// if the trip doesn't have a completed crypto charge.
	// As soon as the crypto charge clears, TripCryptoProcessor will kick off dispatch by
	// re-calling TripStateEngine to set PendingDriverAcceptance again.
	PaymentPending: 'payment_pending',

	// From quoted, user can either go to PendingDriverAcceptance (the "live" route),
	// or to ScheduledPendingDriverAcceptance where the driver has longer to accept
	// and is notified that this is for a future ride
	ScheduledPendingDriverAcceptance: 'scheduled_pending_driver_acceptance',
	// Driver has accepted the future ride, ride will sit at this
	// status, potentially for days, until it's time for the driver to pickup
	// the member, in which case we will push-notify the driver in advance of the
	// pickup and prompt them to transition to ScheduledDriverArriving
	ScheduledDriverAccepted: 'scheduled_driver_accepted',
	// Triggered by timer cron (Trip.startScheduledTripCronJob) when
	// it's "almost time" to leave to pickup a trip.
	// This status is necessary so that we can query for a "current trip" from the FE
	// without the driver having to go into the Upcoming Trips page and finding the trip
	// and starting it manually. The dashboard still will (should) prompt them to start it,
	// but with this status, the trip will automatically be "brought forward" on the dashboard
	// instead of requiring manually looking it up in the list.
	ScheduledTripPending: 'scheduled_trip_pending',
	// It is now time for the future ride and the driver has marked they
	// are heading to pickup - from here, trip enters normal flow (DriverArrived -> Riding -> ....)
	ScheduledDriverArriving: 'scheduled_driver_arriving',

	// User has requested the trip and waiting for a driver
	PendingDriverAcceptance: 'pending_driver_acceptance',
	// Driver has been assigned and is heading to the user
	DriverAccepted: 'driver_accepted',
	// Transient status - NOT set on the trip, used for carrying message info around the backend
	DriverRejected: 'driver_rejected',

	// Driver has arrived at pickup location
	DriverArrived: 'driver_arrived',
	// This status is ONLY used for legs, especially for layovers,
	// used to indicate that the leg is ready (e.g. driver arrived for trip) ...
	LegPending: 'leg_pending',
	// This status is ONLY used for legs, especially for layovers,
	// This status is set on the Next leg when RidingStopped is set on a leg.
	// Using this status with setTripStatus will have no effect on the trip's status
	// (it will be interpreted as RidingStopped for the sake of the trip)
	LegDriverArrived: 'leg_driver_arrived',
	// Driver has picked up the user
	Riding: 'riding',
	// Trip has stopped at a midpoint (trip-stops) - goes back to 'riding'
	RidingStopped: 'riding_stopped',
	// Driver has dropped off the user, pending post user interaction
	RideCompleted: 'ride_completed',
	// User finished providing feedback (rating + tip (optional))
	UserCompleted: 'user_completed',
	// Driver canceled the trip - TBD: When would we ever let drivers cancel...?
	DriverCanceled: 'driver_canceled',
	// User canceled the trip
	UserCanceled: 'user_canceled',
	// User never showed for their trip (different than user choosing to cancel - this
	// means user did not communicate with Concierge staff asking to cancel or communicate with driver)
	NoShow: 'user_no_show',
	// Quote sat for more than X hours with no user interaction
	// This could also happen if no drivers accept - VERY BAD.
	QuoteExpired: 'quote_expired',
	// User said "no thanks" (etc) to quote
	QuoteCanceled: 'quote_canceled',
};

export const TripStatusTransitions = {
	[TripStatus.Draft]: [
		TripStatus.Quoted,
		TripStatus.PaymentPending,
		TripStatus.PendingDriverAcceptance,
		TripStatus.UserCanceled,
		TripStatus.QuoteCanceled,
		TripStatus.QuoteExpired,
	],
	[TripStatus.Quoted]: [
		TripStatus.PaymentPending,
		TripStatus.PendingDriverAcceptance,
		TripStatus.ScheduledPendingDriverAcceptance,
		TripStatus.Draft,
		TripStatus.UserCanceled,
		TripStatus.QuoteCanceled,
		TripStatus.QuoteExpired,
	],
	[TripStatus.PaymentPending]: [
		TripStatus.Draft,
		TripStatus.Quoted,
		TripStatus.QuoteExpired,
		TripStatus.ScheduledPendingDriverAcceptance,
		TripStatus.PendingDriverAcceptance,
	],
	[TripStatus.ScheduledPendingDriverAcceptance]: [
		TripStatus.ScheduledDriverAccepted,
		TripStatus.PendingDriverAcceptance,
		TripStatus.DriverAccepted,
		TripStatus.DriverRejected,
		TripStatus.UserCanceled,
		TripStatus.Draft,
	],
	[TripStatus.ScheduledDriverAccepted]: [
		TripStatus.ScheduledTripPending,
		TripStatus.ScheduledDriverArriving,
		TripStatus.UserCanceled,
		TripStatus.DriverCanceled, // TBD if we allow this?
		TripStatus.Draft,
		TripStatus.NoShow,
	],
	[TripStatus.ScheduledTripPending]: [
		TripStatus.ScheduledDriverArriving,
		TripStatus.DriverArrived,
		TripStatus.Riding,
		TripStatus.UserCanceled,
		TripStatus.DriverCanceled, // TBD if we allow this?
		TripStatus.NoShow,
	],
	[TripStatus.ScheduledDriverArriving]: [
		TripStatus.DriverArrived,
		TripStatus.Riding,
		TripStatus.UserCanceled,
		TripStatus.DriverCanceled, // TBD if we allow this?
		TripStatus.NoShow,
	],
	[TripStatus.PendingDriverAcceptance]: [
		TripStatus.ScheduledPendingDriverAcceptance,
		TripStatus.DriverAccepted,
		TripStatus.DriverRejected,
		TripStatus.UserCanceled,
	],
	[TripStatus.DriverAccepted]: [
		TripStatus.Riding,
		TripStatus.DriverArrived,
		TripStatus.UserCanceled,
		TripStatus.DriverCanceled, // TBD if we allow this?
		TripStatus.NoShow,
	],
	[TripStatus.DriverArrived]: [
		TripStatus.Riding,
		TripStatus.UserCanceled,
		TripStatus.NoShow,
	],
	[TripStatus.Riding]: [TripStatus.RidingStopped, TripStatus.RideCompleted],
	[TripStatus.RidingStopped]: [TripStatus.Riding, TripStatus.RideCompleted],
	[TripStatus.RideCompleted]: [TripStatus.UserCompleted, TripStatus.Draft],
	[TripStatus.UserCanceled]: [TripStatus.Draft],
	[TripStatus.NoShow]: [TripStatus.Draft],
	[TripStatus.QuoteCanceled]: [TripStatus.Draft],
};

export const STARTED_STATES = [
	TripStatus.ScheduledDriverArriving,
	TripStatus.DriverAccepted,
	TripStatus.DriverArrived,
	TripStatus.Riding,
	TripStatus.RidingStopped,
];

export const RIDING_STATES = [TripStatus.Riding, TripStatus.RidingStopped];

export const RIDING_OR_ARRIVED_STATES = [
	...RIDING_STATES,
	TripStatus.DriverArrived,
];

export const COMPLETED_STATES = [
	TripStatus.DriverCanceled,
	TripStatus.UserCanceled,
	TripStatus.RideCompleted,
	TripStatus.UserCompleted,
	TripStatus.QuoteCanceled,
	TripStatus.QuoteExpired,
	TripStatus.NoShow,
];

export const QUOTE_DRAFT_STATES = [TripStatus.Draft, TripStatus.Quoted];

export const DRIVER_EMPTY_STATES = [
	TripStatus.Draft,
	TripStatus.Quoted,
	TripStatus.PaymentPending,
	// TripStatus.ScheduledDriverAccepted,
	TripStatus.DriverRejected,
	TripStatus.DriverCanceled,
	TripStatus.UserCanceled,
	// TripStatus.RideCompleted,
	TripStatus.QuoteCanceled,
	TripStatus.QuoteExpired,
	TripStatus.NoShow,
];

export const OrderToTripStatus = {
	[OrderStatus.Draft]: [TripStatus.Draft],
	[OrderStatus.Quoted]: [TripStatus.Quoted],
	[OrderStatus.PaymentPending]: [TripStatus.PaymentPending],
	[OrderStatus.FulfillmentProcessing]: [
		TripStatus.ScheduledPendingDriverAcceptance,
		TripStatus.PendingDriverAcceptance,
	],
	[OrderStatus.FulfillmentReady]: [TripStatus.ScheduledDriverAccepted],
	[OrderStatus.FulfillmentActive]: [...STARTED_STATES],
	[OrderStatus.FulfillmentCompleted]: [TripStatus.RideCompleted],
	[OrderStatus.InvoiceReady]: [TripStatus.UserCompleted],
	// No corresponding trip status for when trip is paid or failed...
	// could figure out based on flags on trip...?
	[OrderStatus.InvoicePaid]: [],
	[OrderStatus.InvoiceFailed]: [],

	// No equiv state on trip...when failure, trip sets QuoteExpired,
	// but order doesnt want that - quote expired should only be on orders
	// for true expiration - need to set this state on orders only if fulfillment really did fail
	[OrderStatus.FulfillmentFailed]: [],

	[OrderStatus.VendorCanceled]: [TripStatus.DriverCanceled],
	[OrderStatus.UserCanceled]: [TripStatus.UserCanceled],
	[OrderStatus.UserNoShow]: [TripStatus.NoShow],
	[OrderStatus.QuoteExpired]: [TripStatus.QuoteExpired],
	[OrderStatus.QuoteCanceled]: [TripStatus.QuoteCanceled],
};

// Build reverse lookup from OrderToTripStatus
export const TripToOrderStatus = {};
Object.entries(OrderToTripStatus).forEach(([orderStatus, tripStatusList]) => {
	tripStatusList.forEach((tripStatus) => {
		TripToOrderStatus[tripStatus] = orderStatus;
	});
});

export const isValidTripStatus = (status) =>
	Object.values(TripStatus).includes(status);

export const isValidTripStatusTransition = (fromStatus, toStatus) =>
	TripStatusTransitions[fromStatus] &&
	TripStatusTransitions[fromStatus].includes(toStatus);

export const isDriverEmptyStatus = (status) =>
	!status || DRIVER_EMPTY_STATES.includes(status);

export const isStartedStatus = (status) => STARTED_STATES.includes(status);

export const isRidingStatus = (status) => RIDING_STATES.includes(status);

export const isRidingOrArrivedStatus = (status) =>
	RIDING_OR_ARRIVED_STATES.includes(status);

export const isPostedStatus = (status) =>
	status && !QUOTE_DRAFT_STATES.includes(status);

export const isQuotedDraft = (status) => QUOTE_DRAFT_STATES.includes(status);

export const isCompletedStatus = (status) => COMPLETED_STATES.includes(status);
export default TripStatus;
