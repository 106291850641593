/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import { useDebounce } from 'use-debounce';
import React, { useEffect, useCallback } from 'react';
import useSWR from 'swr';
import useQuery from 'utils/useQuery';
import { TextField } from '@material-ui/core';
import SimpleSpinner from 'shared/components/SimpleSpinner';
import AuthService, { useIsAuthorized } from 'shared/services/AuthService';
import BackendService from 'shared/services/BackendService';
import AppButton from 'shared/components/AppButton';
import SupportChatButton from 'shared/components/SupportChat/SupportChatButton';
import PublicAppConfig from 'shared/config-public';
import formatPhone from 'shared/utils/formatPhone';
import { ServerStore } from 'shared/services/ServerStore';
import { Link } from 'react-router-dom';
import styles from './LoginPage.module.scss';
import PartnerPageWrapper from './PartnerPageWrapper';

export default function LoginPage() {
	let {
		token = AuthService.getPreLoginToken(),
		inviteId,
		inviteEmail,
	} = useQuery();

	const [email, setEmail] = React.useState();
	const [password, setPassword] = React.useState();
	const [passwordConfirm, setPasswordConfirm] = React.useState();
	const [loginPending, setLoginPending] = React.useState(token);
	const [loginError, setLoginError] = React.useState();
	const [queryEmail] = useDebounce(email, 500);

	// console.log(`LoginPage rendering:`, {
	// 	token,
	// 	email,
	// 	password,
	// 	loginPending,
	// });

	const { data: partnerMeta } = useSWR(
		// By using either inviteId OR email, we can show
		// the account logo even during regular login once user types email
		// (or even if browser auto-fills)
		[inviteId, queryEmail, 'account-partner-meta'],
		(userId, emailInput) =>
			(userId || emailInput) &&
			ServerStore.getAccountPartnerMeta({
				userId,
				email: emailInput,
			}),
	);

	const { partnerLogoUrl, partnerDisplayName } = partnerMeta || {};

	const loginSuccess = ({ account }) => {
		// eslint-disable-next-line no-shadow
		const { id: accountId, isPartnerAccount } = account || {
			id: 'unknown',
		};
		if (!isPartnerAccount) {
			setLoginError({
				message: `You must log into an account that is a Partner Account to use this app. You're logging into account ${accountId}, which is NOT authorized as a partner. Please contact Vaya at concierge@vayadriving.com or ${formatPhone(
					PublicAppConfig.phoneNumbers.main,
				)}`,
			});
			return;
		}
		console.log('+ SUCCESS');

		// history.push(AuthService.getPreLoginPath());

		BackendService.gotoLastPreLoginPage();

		// // alert("Success");
		// if (history.preLoginUrl) {
		// 	if (PushNotifyService.hashIsAlert(history.preLoginUrl)) {
		// 		console.log(
		// 			'Login success, found ALERT in preLoginUrl, passing to PushNotifyService ',
		// 			history.preLoginUrl,
		// 		);
		// 		PushNotifyService.instance.handleAlertHash(history.preLoginUrl);
		// 	} else {
		// 		console.log(
		// 			'Login success, found preLoginUrl, going to ',
		// 			history.preLoginUrl,
		// 		);
		// 		history.push(history.preLoginUrl);
		// 	}
		// } else {
		// 	console.log('Login success, no preLoginUrl, sending to /home');
		// 	history.push('/home');
		// }
	};

	useEffect(() => {
		if (token) {
			setLoginPending(true);
			setLoginError(false);

			AuthService.clearPreLoginToken();

			console.log(`Login page got token:`, token);

			AuthService.loginWithToken(token).then(
				({
					success,
					errorReason,
					status,
					error,
					error: { message } = {},
					...result
				}) => {
					// setLoading(false);

					setLoginPending(false);

					if (success) {
						console.log(
							`Login success with token, got result`,
							result,
						);
						loginSuccess(result);
						return;
					}

					console.error(`login error:`, {
						errorReason,
						error,
					});

					setLoginError({
						message: errorReason || message,
					});
				},
			);
		}
	}, [token]);

	const loginAction = useCallback(async () => {
		if (inviteId && password !== passwordConfirm) {
			setLoginError({
				message: `The two passwords you entered don't match - please try again!`,
			});
			return;
		}

		setLoginPending(true);
		setLoginError(false);

		if (inviteId) {
			AuthService.acceptInvite(inviteId, password).then(
				({
					success,
					errorReason,
					status,
					error,
					error: { message } = {},
					...result
				}) => {
					// setLoading(false);

					setLoginPending(false);

					if (success) {
						loginSuccess(result);
						return;
					}

					console.error(`accept invite error:`, {
						errorReason,
						error,
					});

					setLoginError({
						message: errorReason || message,
					});
				},
			);
			return;
		}

		AuthService.login(email, password).then(
			({
				success,
				errorReason,
				status,
				error,
				error: { message } = {},
				...result
			}) => {
				// setLoading(false);

				setLoginPending(false);

				if (success) {
					loginSuccess(result);
					return;
				}

				console.error(`login error:`, {
					errorReason,
					error,
				});

				setLoginError({
					message: errorReason || message,
				});
			},
		);
	}, [email, inviteId, password, passwordConfirm]);

	const onKeyPress = useCallback(
		(evt) => {
			const { key, shiftKey } = evt;
			if (!shiftKey && key === 'Enter') {
				loginAction();
				evt.preventDefault();
			}
		},
		[loginAction],
	);

	return (
		<div className={styles.root}>
			{loginPending && <SimpleSpinner fullPage={true} />}

			<PartnerPageWrapper
				pageTitleText="Partner Login"
				partnerLogoUrl={partnerLogoUrl}
				ctaPanelContent={
					<>
						<div className={styles.loginButton}>
							<AppButton onClick={loginAction}>
								{inviteId ? 'Create Account' : 'Login'}
							</AppButton>
							{inviteId ? (
								<Link to="/login">
									Already chose a password? Sign in here
								</Link>
							) : (
								''
							)}
						</div>
					</>
				}
			>
				<form className={styles.loginForm}>
					{inviteId && (
						<p className={styles.acceptInviteInfo}>
							✨ Welcome to the{' '}
							<b>
								{partnerDisplayName ? (
									<>{partnerDisplayName} × </>
								) : (
									''
								)}
								Vaya
							</b>{' '}
							<i>Partner App</i> - just choose a password and
							you're ready to go! 🎉
						</p>
					)}
					{loginError && (
						<div className={styles.loginError}>
							Sorry, that didn't work. Please try logging in.{' '}
							{loginError.message ? (
								<>({loginError.message})</>
							) : (
								''
							)}
						</div>
					)}

					<TextField
						variant="filled"
						label="Email"
						type="email"
						autoFocus={!inviteEmail && !email}
						disabled={!!inviteEmail}
						placeholder="E-mail"
						value={email || inviteEmail || ''}
						onChange={(evt) => setEmail(evt.target.value)}
						onKeyPress={onKeyPress}
						InputProps={{ disableUnderline: true }}
					/>
					<TextField
						variant="filled"
						label="Password"
						type="password"
						placeholder="Password"
						autoFocus={!!inviteEmail}
						value={password || ''}
						onChange={(evt) => setPassword(evt.target.value)}
						onKeyPress={onKeyPress}
						InputProps={{ disableUnderline: true }}
					/>

					{inviteId ? (
						<TextField
							variant="filled"
							label="Confirm your password"
							type="password"
							placeholder="Confirm password"
							value={passwordConfirm || ''}
							onChange={(evt) =>
								setPasswordConfirm(evt.target.value)
							}
							onKeyPress={onKeyPress}
							InputProps={{ disableUnderline: true }}
						/>
					) : (
						''
					)}

					<p className={styles.disclaimer}>
						By clicking this button, you are accepting Vaya
						Concierge Driving, LLC{' '}
						<a
							href="https://vayadriving.com/terms-of-use"
							target="_blank"
							rel="nofollow noopener noreferrer"
						>
							Terms and Conditions
						</a>{' '}
						and{' '}
						<a
							href="https://vayadriving.com/privacy-policy"
							target="_blank"
							rel="nofollow noopener noreferrer"
						>
							Privacy Policy
						</a>
					</p>
				</form>
				{/* <SupportChatButton lightMode={true} /> */}
			</PartnerPageWrapper>
		</div>
	);
}
