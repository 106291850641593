/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import createStore from 'zustand';
import { useEffect, useState, useRef, useMemo } from 'react';
import {
	// eslint-disable-next-line camelcase
	unstable_batchedUpdates,
} from 'react-dom';
import GpsWorkerClient from 'shared/utils/GpsWorkerClient';
import AuthService from 'shared/services/AuthService';
import { useInstanceCache } from 'shared/hooks/useInstanceCache';

class GpsStreamReceiver {
	constructor({ userId, defaultValue, debug = false }) {
		this.userId = userId;
		this.defaultValue = defaultValue;
		this.store = createStore(() => defaultValue);

		const { token } = AuthService.authorizationState.getValue();

		// const path = `/api/reflect/${userId}/websocket`;
		const path = GpsWorkerClient.createWorkerPath({
			objectId: userId,
		});
		this.socket = new GpsWorkerClient({
			path,
			token,
			name: 'GpsStreamReceiver',
			onMessage: this.onSocketMessage,
			debug,
		});

		// console.log(
		// 	`GpsStreamReceiver: Created socket for user ${userId}, API path:`,
		// 	path,
		// );
	}

	// Fat arrow to bind this for passing to addMessageHook
	onSocketMessage = (message) => {
		const { store } = this;
		const { gps } = message || {};
		// console.log(`* useGpsStreamReceiver: received message, checking filter`, {
		// 	message,
		// });

		if (!gps) {
			// console.log(
			// 	`* useGpsStreamReceiver: X message rejected, no gps in message`,
			// 	message,
			// 	typeof message,
			// );
			return false;
		}

		console.log(`* useGpsStreamReceiver: message passed, updating state`, gps);

		// Mark unstable per https://github.com/pmndrs/zustand#calling-actions-outside-a-react-event-handler
		unstable_batchedUpdates(() => {
			// TODO: Possible improvement - https://github.com/pmndrs/zustand#transient-updates-for-often-occuring-state-changes
			store.setState({ gpsTimestamp: new Date(), ...gps });
		});

		return true;
	};

	updateOptions(options) {
		// Reject undefined options so we don't unset values if
		// no options passed just because we defaulted to an empty object ({})
		if (!options) {
			return;
		}

		const { messageTypeFilter, defaultValue } = options || {};

		// Allow changing the filter via props, for example,
		// when the userId we're listening for changes.
		if (this.messageTypeFilter !== messageTypeFilter) {
			this.messageTypeFilter = messageTypeFilter;
		}

		// MUST memoize defaultValue externally for this to be stable
		// and not override stream every time updateOptions() is called
		if (this.defaultValue !== defaultValue) {
			this.defaultValue = defaultValue;
			unstable_batchedUpdates(() => {
				this.store.setState(defaultValue);
			});
		}
	}

	useStore(selector) {
		// Special-case the false value so external consumers
		// can get access to the store itself, for subscriptions, etc.
		// For example, this is used in useInjectTrackingPath() to optimize
		// rendering changes as documented here:
		// https://github.com/pmndrs/zustand#transient-updates-for-often-occuring-state-changes
		if (selector === false) {
			return this.store;
		}

		return this.store(selector);
	}

	destroy() {
		if (this.dead) {
			return;
		}
		this.dead = true;

		if (this.socket) {
			this.socket.stop();
			this.socket = null;
		}

		if (this.store) {
			this.store.destroy();
			this.store = undefined;
		}
	}
}

export function useGpsStreamReceiver({
	key = undefined,
	userId,
	defaultValue = { lat: undefined, lng: undefined },
	selector = (state) => state,
	debug = false,
}) {
	if (!key && key !== undefined) {
		throw new Error(
			`Cannot useGpsStreamReceiver without a 'key' (however, 'undefined' is okay - just won't create an instance if key === undefined)`,
		);
	}

	// Create the options object for passing to the cache
	const options = useMemo(
		() => ({ userId, defaultValue, debug }),
		[defaultValue, userId, debug],
	);

	const inst = useInstanceCache({
		Class: GpsStreamReceiver,
		key,
		options,
	});

	// if (key !== undefined) {
	// 	console.warn(`useGpsStreamReceiver debug:`, {
	// 		key,
	// 		options,
	// 		inst,
	// 	});
	// } else {
	// 	console.warn(`useGpsStreamReceiver NO KEY:`, {
	// 		key,
	// 		userId,
	// 		defaultValue,
	// 	});
	// }

	if (!inst) {
		// undefined keys ARE valid because external code can use
		// an undefined key to deactivate this hook by preventing
		// the instance cache from creating an instance of our receiver
		// until some external condition is met. Therefore, only error
		// if the key is NOT undefined, sine the instance cache should always
		// return an instance of GpsStreamReceiver in all other circumstances
		if (key !== undefined) {
			console.error(
				`useGpsStreamReceiver: No value (e.g. undefined) returned from useInstanceCache - this should never happen. Something broke!`,
				{
					instanceCacheKey: key,
					userId,
					stack: new Error().stack,
				},
			);
		}

		return undefined;
	}

	return inst.useStore(selector);
}

export function useTripGps(
	sourceProps = {},
	sourceType = 'driver',
	{ selector, keyCondition, debug = false } = {},
) {
	if (!['user', 'driver', 'rider'].includes(sourceType)) {
		throw new Error(
			`Invalid trip GPS source type '${sourceType}' - should be one of 'user' or 'driver' or 'rider'`,
		);
	}

	const {
		lat,
		lng,
		gpsTimestamp,
		id: userId,
		gpsInfo,
	} = sourceProps[sourceType] || {}; // (sourceType === 'driver' ? driver : user) || {};
	if (!userId) {
		// console.warn(
		// 	`useTripGps was called for source '${sourceType}' but that doesn't seem to be defined on the sourceProps given`,
		// 	sourceProps,
		// );
	}

	const defaultValue = useMemo(
		() => ({ lat, lng, gpsTimestamp, ...gpsInfo }),
		[gpsInfo, gpsTimestamp, lat, lng],
	);

	const key = useMemo(
		() =>
			userId !== undefined && sourceType !== undefined && !!keyCondition
				? `user_${userId}`
				: undefined,
		[keyCondition, sourceType, userId],
	);

	const remoteGps = useGpsStreamReceiver({
		// Special case key here - if the key is `undefined` then the useInstanceCache()
		// hook won't create an instance of our class. We use this to allow consumers
		// of our useTripGps hook to delay creating a stream receiver until some
		// external condition is met - for example, until a trip leg is in 'Riding' state,
		// which is how useInjectTrackingPath() uses this flag
		key,
		userId,
		defaultValue,
		selector: false,
		debug,
	});

	// Use two refs to detect changes in location so we don't recalc if nothing is changed
	const currentRemoteGpsRef = useRef(
		remoteGps
			? remoteGps.getState()
			: { lat: undefined, lng: undefined, heading: undefined },
	);

	// This is so we don't needlessly update our state
	const lastTrackedGpsRef = useRef({});

	const [remoteState, setRemoteState] = useState();

	// This ref will be set to false when the last useEffect exits, so we don't
	// perform async state updates after unmounting
	const mountedRef = useRef(true);

	// Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
	// This is the crucial piece the wires the remote GPS stream into the tracking path
	// by subscribing to the zustand store as well as starting our worker function, above,
	// to pickup the changes via the currentRemoteGpsRef
	useEffect(() => {
		mountedRef.current = true;

		// console.log(`useTripGps // onMount:`, {
		// 	// isLegLive,
		// 	// trackingPath,
		// 	key,
		// 	remoteGps,
		// });

		// We only want to run GPS tracking if the leg is ACTUALLY active
		if (!remoteGps) {
			return () => {
				mountedRef.current = false;
			};
		}

		// Start our sync worker - won't do anything if GPS doesn't change,
		// and locks as long as needed to recompute path, so safe to "poll"
		// for changes at a somewhat-fast pace, even if nothing changed.
		const timerId = setInterval(() => {
			const { current: { lat: previousLat, lng: previousLng } = {} } =
				lastTrackedGpsRef;

			// eslint-disable-next-line no-shadow
			const { current: { lat, lng } = {} } = currentRemoteGpsRef;

			// Update previous prop so we don't rerender if not needed
			lastTrackedGpsRef.current = currentRemoteGpsRef.current;

			// No change in location? Don't bother recalculating
			// Note: We really don't care if heading changes, since heading can't change
			// unless lat/lng physically changes. (In other words, for heading to change
			// independent of lat/lng, the GPS receiver would have to be spinning in place,
			// which is not relevant for the purposes of our tracking path here)
			if (previousLat === lat && previousLng === lng) {
				return;
			}

			setRemoteState(currentRemoteGpsRef.current);
		}, 1000);

		// Subscribe to our GPS stream and just write it into a ref,
		// which will be checked internally by syncWorker above
		const unsubscribe = remoteGps.subscribe((state) => {
			currentRemoteGpsRef.current = state;
			// console.log(
			// 	`useTripGps // internal subscription // got GPS update:`,
			// 	state,
			// );
		});

		// Load the current GPS into the ref because it probably wasn't available
		// when the ref was constructed
		currentRemoteGpsRef.current = remoteGps.getState();

		// Force update on change
		// Passing useOriginalBounds just to make it a dependent of this effect
		setRemoteState(currentRemoteGpsRef.current);

		// console.log(`useTripGps // subscribed to remoteGps`, {
		// 	currentValue: currentRemoteGpsRef.current,
		// 	remoteGps,
		// 	unsubscribe,
		// 	timerId,
		// });

		return () => {
			// Mark unmounted so we don't update the injectedTripPath state
			// above in our interval if we were caught waiting for async work
			// when we unmounted
			mountedRef.current = false;

			// Unsubscribe from the GPS stream and stop our worker
			unsubscribe();
			clearInterval(timerId);
		};
	}, [remoteGps]);

	if (selector === false) {
		// External caller wants to attach subscriber
		return remoteGps;
	}

	return remoteState;
}

export function useRiderGps(currentTrip, { enabled = true, debug } = {}) {
	// console.log(
	// 	`Using rider GPS for `,
	// 	(currentTrip && currentTrip.user && currentTrip.user.id) ||
	// 		'(Unknown)',
	// );
	return useTripGps(currentTrip, 'rider', { keyCondition: enabled, debug });
}

export function useDriverGps(currentTrip, { enabled = true, debug } = {}) {
	// console.log(
	// 	`Using driver GPS for `,
	// 	(currentTrip && currentTrip.driver && currentTrip.driver.id) ||
	// 		'(Unknown)',
	// );
	return useTripGps(currentTrip, 'driver', { keyCondition: enabled, debug });
}
