import useMediaQuery from '@material-ui/core/useMediaQuery';

// If you change these, you must also change _include-media.scss
export const Breakpoints = {
	phone: 320,
	small: 600,
	tablet: 768,
	desktop: 1024,
};

export const useBreakpoint = (id, breakUp = false) => {
	const breakPixels = Breakpoints[id];

	const query = `(${breakUp ? 'min' : 'max'}-width: ${breakPixels}px)`;

	const result = useMediaQuery(query);

	return breakPixels ? result : undefined;
};

// Object.entries(Breakpoints).forEach(([key, value]) => {
// 	document.body.style.setProperty(`--breakpoint-${key}`, `${value}px`);
// });
