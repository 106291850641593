/**
 * Simple utility to throw errors if a given set of values contain any untruthy values
 *
 * @param {object} values Object containing key/value pairs to assert truthy
 * @param {string} messagePrefix Optional prefix to add to error message if value fails predicate check, useful for context
 * @param {function} predicate Callback to check each value, defaults to `x => x !== undefined`
 */
export function assertRequired(
	values = {},
	messagePrefix = '',
	logger,
	predicate = (x) => x !== undefined && x !== null,
) {
	Object.entries(values).forEach(([key, value]) => {
		if (!predicate(value)) {
			const error = new Error(
				`${messagePrefix ? `${messagePrefix}: ` : ''}${key} required`,
			);
			if (logger) {
				logger.error(
					`[${messagePrefix}] '${key}' is marked as required but it doesn't appear to be present. Value was:`,
					value,
					`, called from:`,
					error.stack,
				);
			}
			throw error;
		}
	});
}

/**
 * Asserts that a value is an instance of a specified class.
 *
 * @param {Object} options - The options object.
 * @param {*} options.value - The value to be checked.
 * @param {Function} options.instance - The class or constructor function to check against.
 * @param {string} [options.message=''] - The optional error message to be included in the error.
 * @throws {Error} If the value is not an instance of the specified class.
 */
export function assertInstanceOf({ value, instance, messagePrefix, logger }) {
	const constructorName = value && value.constructor && value.constructor.name;

	if (Array.isArray(instance)) {
		const arrayElementType = instance[0];
		if (!Array.isArray(value)) {
			const error = new Error(
				`${
					messagePrefix ? `${messagePrefix}: ` : ''
				} Expected ${value} to be an array, but it is a ${constructorName} (${typeof value}) instead`,
			);

			if (logger) {
				logger.error(
					`[${messagePrefix}] Expected ${value} to be an array, but it is a ${constructorName} (${typeof value}), called from:`,
					error.stack,
				);
			}

			throw error;
		}

		value.forEach((v, idx) => {
			try {
				assertInstanceOf({
					value: v,
					instance: arrayElementType,
					messagePrefix,
				});
			} catch (e) {
				// eslint-disable-next-line no-console
				if (logger) {
					logger.error(
						`[${messagePrefix}] Error checking array element # ${idx} value '${v}' against '${arrayElementType}'`,
						e.message,
						e.stack,
					);
				}

				throw e;
			}
		});
		return;
	}

	if (!(value instanceof instance)) {
		const error = new Error(
			`${
				messagePrefix ? `${messagePrefix}: ` : ''
			} Expected ${value} to be instance of ${instance}, but it is a ${constructorName} (${typeof value}) instead`,
		);

		if (logger) {
			logger.error(
				`[${messagePrefix}] Expected ${value} to be instance of ${instance}, but it is a ${constructorName} (${typeof value}), called from:`,
				error.stack,
			);
		}

		throw error;
	}
}

/**
 * Asserts that the values in an object match the specified types.
 *
 * @param {Object} options - The options object.
 * @param {Object} options.values - The object containing the values to be checked.
 * @param {Object} options.types - The object containing the expected types for each value.
 * @param {string} [options.message=''] - The optional error message to be included in the thrown error.
 * @throws {Error} If any value does not match its expected type.
 */
export function assertTypes(values, types, messagePrefix, logger) {
	Object.entries(values).forEach(([key, value]) => {
		if (!types[key]) {
			return;
		}
		try {
			assertInstanceOf({ value, instance: types[key], messagePrefix, logger });
		} catch (e) {
			// eslint-disable-next-line no-console
			logger.error(
				`[${messagePrefix}] Error checking '${key}' prop against type '${types[key]}'`,
				e.message,
				e.stack,
			);

			throw e;
		}
	});
}
